import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useCreateBotPortfolio = () => {
  const queryClient = useQueryClient();

  const createBotPortfolio = useMutation(ApiService.createTradeBot, {
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['bots/portfolio'] });
    },
  });

  return { createBotPortfolio };
};
