import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { _login, signup, _verify, _auth, _forget, auth_pass, changeUser } from '../../actions/auth';
import { useLocalStorage } from './useLocalStorage';
import { useTranslation } from 'react-i18next';

const AuthContext = createContext();

export const AuthProvider = ({ children, userData }) => {
  const [user, setUser] = useLocalStorage('user', userData);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const login = async (data) => {
    const _data = (await _login(data.email, data.password, data.iniData, data.sendMethod)) || {};
    const { email, telegram, message, code, success } = _data;

    if (success || email) {
      if (code) {
        auth(_data);
        return;
      }
      navigate('/auth', { replace: true, state: { email: email, password: data.password, telegram: telegram, sendMessage: message } });
    } else {
      return message;
    }
  };

  const auth = async (data) => {
    let _data = {};

    if (data.email) {
      _data = await _auth(data.code, data.email);
    } else if (data.femail) {
      _data = await auth_pass(data.code, data.femail);
      if (_data.error) return _data.reason.message;
    }
    if (_data && _data.id) {
      setUser(_data);
      navigate('/dashboard/profile', { replace: true });
    } else {
      return _data.message;
    }
  };

  const register = async (data) => {
    return await signup(data.email, data.password, data.referrer, data.locale, data.telegramId);
  };

  const logout = async () => {
    setUser(null);
    localStorage.setItem('token', '');
    window.location.href = process.env.REACT_APP_FRONTEND_URL;
  };

  const verify = async (data) => {
    const _data = await _verify(data);
    if (_data.message === 'verified') {
      setTimeout(() => {
        navigate('/login', { replace: true });
      }, 3000);
      return t('auth.verification-success');
    } else {
      return t('auth.verification-error');
    }
  };

  const forget = async (data) => {
    const _data = await _forget(data.email);
    if (_data.message === 'send') {
      navigate('/auth', { replace: true, state: { femail: _data.email } });
      return t('auth.verification-success');
    } else {
      return t('auth.verification-error');
    }
  };

  const changePassword = async (data) => {
    const _data = await changeUser(data.password, data.newPassword);
    console.log(_data.message);
    return _data;
  };

  const isAdmin = () => user.role === 'admin';

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      register,
      verify,
      auth,
      forget,
      changePassword,
      isAdmin,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
