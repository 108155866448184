export const BOT_REBALANCER_TYPES = {
  RebalancerTop35: 'rebalancerTop35',
  RebalancerTop15: 'rebalancerTop15',
  RebalancerTop10: 'rebalancerTop10',
  // RebalancerTop5: 'rebalancerTop5',
};

export const BOT_REBALANCER_TYPE_VALUES = new Set(
  Object.values(BOT_REBALANCER_TYPES)
);

export const BOT_REBALANCER_TYPE_OPTIONS = [
  {
    value: BOT_REBALANCER_TYPES.RebalancerTop35,
    label: 'enums.bot-type.rebalancer.top35',
  },
  {
    value: BOT_REBALANCER_TYPES.RebalancerTop15,
    label: 'enums.bot-type.rebalancer.top15',
  },
  {
    value: BOT_REBALANCER_TYPES.RebalancerTop10,
    label: 'enums.bot-type.rebalancer.top10',
  },
  // {
  //   value: BOT_REBALANCER_TYPES.RebalancerTop5,
  //   label: 'enums.bot-type.rebalancer.top5',
  // },
];

export function getBotRebalancerMinFund(type) {
  switch (type) {
    case BOT_REBALANCER_TYPES.RebalancerTop35:
      return 3000;
    case BOT_REBALANCER_TYPES.RebalancerTop15:
      return 2500;
    case BOT_REBALANCER_TYPES.RebalancerTop10:
      return 1500;
    // case BOT_REBALANCER_TYPES.RebalancerTop5:
    //   return 450;
    default:
      return 0;
  }
}

export function isBotRebalancerTypeValid(value) {
  return BOT_REBALANCER_TYPE_VALUES.has(value);
}
