import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

export const OrdersList = ({ ordersList, url, type, currency }) => {
    const { t } = useTranslation();
    const { ref } = useInView();
 
    return (
      <Paper sx={{ width: '100%', mt: 2, pt: 3, pl: 3 }}>
          <Typography variant="body1">
            {t(`calculator.tableHeader.${type}`)} {currency}
          </Typography>

        <Link href={url} target='_blank'>Ссылка на страницу с ордерами</Link>
        <Table sx={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell>{t('calculator.table.bank')}</TableCell>
              <TableCell>{t('calculator.table.price')}</TableCell>
              <TableCell>{t('calculator.table.min')}</TableCell>
              <TableCell>{t('calculator.table.max')}</TableCell>
              <TableCell>{t('calculator.table.count')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersList?.map((o, i) => (
              <TableRow
                key={o.id}
                ref={i === ordersList?.length - 5 ? ref : undefined}
              >
                <TableCell>
                    {Object.keys(o.tradeMethods).map((ident)=>(o.tradeMethods[ident])).join(', ')}
                </TableCell>
                <TableCell>
                    {o.price}
                </TableCell>
                <TableCell>
                    {o.minSingleTransAmount}
                </TableCell>
                <TableCell>
                    {o.maxSingleTransAmount}
                </TableCell>
                <TableCell>
                    {o.tradableQuantity}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  };