import { useTranslation } from 'react-i18next';
import { AddBot } from '../../pages/bots/AddBot';
import CustomModal from '../CustomModal';

export const AddBotFormModal = ({ open, onClose, type, symbol, exchange }) => {
  const { t } = useTranslation();

  return (
    <CustomModal className="large" show={open} onClose={() => onClose()} header={t(`bots.add-bot.type.${type}.title`)}>
      <AddBot onClose={onClose} type={type} symbol={symbol} exchange={exchange} />
    </CustomModal>
  );
};
