import React from 'react';

const Icon = ({ icon, className = '', onClick }) => {
  return (
    <svg className={`icon ${className}`} onClick={onClick}>
      <use xlinkHref={`/img/icons.svg#${icon}`} />
    </svg>
  );
};

export default Icon;
