import { useTranslation } from 'react-i18next';
import { BOT_TYPES, BOT_TYPE_OPTIONS } from '../../constants/bot-types';
import Icon from '../Icons';
import './AddBotModal.scss';
import CustomModal from '../CustomModal';
import { useGetUser } from '../../hooks/http-requests/useGetUser';
import { useGetCountTradeBots } from '../../hooks/http-requests/useGetCountTradeBots';
import { useGetCountMultiBots } from '../../hooks/http-requests/useGetCountMultiBots';
import { useGetTariffs } from '../../hooks/http-requests/useGetTariffs';
import { useMemo, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

export const AddBotModal = ({ open, onClose, onOpen, showType = 'all' }) => {
  const { t } = useTranslation();
  const { tariffs } = useGetTariffs();
  const { result: countTradeBots } = useGetCountTradeBots();
  const { result: countMultiBots } = useGetCountMultiBots();
  const { user: userInfo } = useGetUser();
  const [error, setError] = useState();

  const userTariff = useMemo(() => {
    if (new Date(userInfo?.data?.tariff_end_at) < new Date()) {
      return tariffs?.data?.tariffs?.find((t) => t.price === 0);
    }
    return tariffs?.data?.tariffs?.find((t) => t.id === userInfo?.data?.tariff_id);
  }, [tariffs.data, userInfo.data]);
  const resellerCount = +countTradeBots?.data?.reseller || 0;
  const portfolioCount = +countTradeBots?.data?.portfolio || 0;
  const investmentCount = +countTradeBots?.data?.investment || 0;
  const rebalancerCount = +countMultiBots?.data || 0;

  const createBot = (type) => {
    switch (type) {
      case BOT_TYPES.reseller:
        if (resellerCount < userTariff?.reseller_count) onOpen(type);
        else setError(true);
        break;
      case BOT_TYPES.portfolio:
        if (portfolioCount < userTariff?.portfolio_count) onOpen(type);
        else setError(true);
        break;
      case BOT_TYPES.investment:
        if (investmentCount < userTariff?.investment_count) onOpen(type);
        else setError(true);
        break;
      case BOT_TYPES.rebalancer:
        if (rebalancerCount < userTariff?.rebalancer_count) onOpen(type);
        else setError(true);
        break;

      default:
        break;
    }
  };

  const botsType = useMemo(() => {
    if (showType === 'all') return BOT_TYPE_OPTIONS;
    else if (showType === 'trade') return BOT_TYPE_OPTIONS.filter((b) => b.type === 'trade');
    else if (showType === 'portfolio') return BOT_TYPE_OPTIONS.filter((b) => b.type === 'portfolio');
    else if (showType === 'rebalancer') return BOT_TYPE_OPTIONS.filter((b) => b.type === 'rebalancer');
  }, [showType]);

  return (
    <CustomModal show={open} onClose={() => onClose()} header={t('bots.add-bot.select-type')}>
      <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{t('bots.add-bot.tariff-error')}</Alert>
      </Snackbar>
      <div className="add-bot-modal">
        {botsType.map((item) => (
          <div className="item" key={item.label} onClick={() => createBot(item.value)}>
            <div className="item__title">
              <Icon icon={item.icon} className="item__icon" />
              {t(item.label)}
            </div>
            <div className="item__text">
              <div className="item__tags">
                {item.tags.map((tag, i) => (
                  <div key={i + 't'}>
                    <Icon icon={`check`} className="c-green" /> {t(`bots.add-bot.tag.${tag}`)}
                  </div>
                ))}
              </div>
              <div>{t(item.text)}</div>
            </div>
          </div>
        ))}
      </div>
    </CustomModal>
  );
};
