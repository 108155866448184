import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import { useCreatePayment } from '../hooks/http-requests/useCreatePayment';
import { useTranslation } from 'react-i18next';
import { useCreateBonusPayment } from '../hooks/http-requests/useCreateBonusPayment';
import { createSearchParams, useNavigate } from 'react-router-dom';
import CustomModal from './CustomModal';
import { useGetUser } from '../hooks/http-requests/useGetUser';
import { useGetCountTradeBots } from '../hooks/http-requests/useGetCountTradeBots';
import { useGetCountMultiBots } from '../hooks/http-requests/useGetCountMultiBots';

export const PaymentsModal = ({ open, onClose, bonusBalance, tariffs, tariffId, currencies }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [disablePaymentBtn, setDisablePaymentBtn] = useState(false);
  const [payUrl, setPayUrl] = useState('');
  const [orderId, setOrderId] = useState('');
  const [title, setTitle] = useState('');
  const [tariff, setTariff] = useState({});
  const [currencyId, setCurrencyId] = useState(0);
  const [currencyName, setCurrencyName] = useState(currencies ? currencies[0] : '');
  const { createPayment } = useCreatePayment();
  const { createBonusPayment } = useCreateBonusPayment();
  const [activeTab, setActiveTab] = useState('pay');
  const navigate = useNavigate();
  const { result: countTradeBots } = useGetCountTradeBots();
  const { result: countMultiBots } = useGetCountMultiBots();
  const { user: userInfo } = useGetUser();

  const resellerCount = +countTradeBots?.data?.reseller || 0;
  const portfolioCount = +countTradeBots?.data?.portfolio || 0;
  const investmentCount = +countTradeBots?.data?.investment || 0;
  const rebalancerCount = +countMultiBots?.data;

  useEffect(() => {
    const tariff = tariffs?.find((t) => t.id === tariffId);
    setTariff(tariff);
    setTitle(`${t('payments.dialog.title')} "${tariff?.title}"`);
  }, [step, t, tariffId, tariffs]);

  useEffect(() => {
    if (currencies && currencies.length > 0) setCurrencyName(currencies[0]);
  }, [currencies]);

  const price = useMemo(() => {
    return tariffs?.find((t) => t.id === tariffId)?.price || 0;
  }, [tariffId, tariffs]);

  const bonusPaymentDisabled = useMemo(() => {
    if (!bonusBalance || !tariffs?.length || !tariffId) return true;
    const tariff = tariffs.find((t) => t.id === tariffId);
    return bonusBalance < tariff.price || createPayment.isLoading || createBonusPayment.isLoading;
  }, [bonusBalance, tariffs, tariffId, createPayment.isLoading, createBonusPayment.isLoading]);

  const onCreatePayment = () => {
    // if (currencies && currencies.length > 1 && step === 1) {
    //   setStep(3);
    //   return;
    // }
    const currency_id = currencies.findIndex((item) => item === currencyName);
    setDisablePaymentBtn(true);
    createPayment.mutateAsync({ tariff_id: tariffId, currency_id }).then(({ pay_url, order_id }) => {
      if (pay_url && order_id) {
        setPayUrl(pay_url);
        setOrderId(order_id);
        if (currencies && currencies.length > 1) {
          window.location.href = pay_url;
        } else {
          // setStep(2);
          window.location.href = pay_url;
        }
      } else {
        setSnackbarOpen(true);
      }
    });
  };

  const onCreateBonusPayment = () => {
    createBonusPayment.mutateAsync({ tariff_id: tariffId }).then(() => {
      onClose();
      navigate(
        `/dashboard/payments?${createSearchParams({
          status: 'success',
        })}`
      );
    });
  };

  const redirect = () => {
    window.location.href = payUrl;
  };

  const navigateTabs = (_, value) => {
    setActiveTab(value);
  };

  const isNotChangeTariff =
    resellerCount > tariff?.reseller_count ||
    investmentCount > tariff?.investment_count ||
    rebalancerCount > tariff?.rebalancer_count ||
    portfolioCount > tariff?.portfolio_count;

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <div style={{ display: 'contents' }}>
            <Tabs value={activeTab} onChange={navigateTabs}>
              <Tab key="pay" label={t('payments.dialog.pay.title')} value="pay" sx={{ textTransform: 'inherit', fontSize: 16 }} />
              <Tab
                key="bonus"
                label={t('payments.dialog.bonus.title')}
                value="bonus"
                sx={{ textTransform: 'inherit', fontSize: 16 }}
                disabled={bonusPaymentDisabled}
              />
            </Tabs>
            {activeTab === 'pay' && currencies?.length > 1 && (
              <Autocomplete
                className="mt-24"
                value={currencyName || ''}
                onChange={(_, value) => setCurrencyName(value)}
                renderInput={(params) => <TextField {...params} name="currency" label={t('payments.currency.title')} />}
                options={currencies?.map((c) => c)}
              />
            )}
            {isNotChangeTariff ? (
              <Alert style={{ marginTop: 20 }} variant="filled" severity="error">
                {t('payments.change.error')}{' '}
                <a className="c-base-400" href="https://t.me/Crypto_bot_2023" target="_blank" rel="noreferrer">
                  @Crypto_bot_2023
                </a>
              </Alert>
            ) : (
              <>
                {userInfo?.data?.tariff_id > tariff?.id && (
                  <Alert style={{ marginTop: 20 }} variant="filled" severity="info">
                    {t('payments.change.warn')}
                  </Alert>
                )}
              </>
            )}

            <Box
              className="ai-center mt-24"
              sx={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              {activeTab === 'pay' && (
                <>
                  <Typography variant="caption">
                    {t('payments.dialog.forpay')} <Typography variant="h8">{price} USDT</Typography>
                  </Typography>
                  <Button variant="contained" onClick={onCreatePayment} disabled={createPayment.isLoading || createBonusPayment.isLoading || isNotChangeTariff}>
                    {t('payments.pay')}
                  </Button>
                </>
              )}
              {activeTab === 'bonus' && (
                <>
                  <Typography variant="caption">
                    {t('payments.dialog.forpay')} <Typography variant="h8">{price}</Typography>
                  </Typography>
                  <Button variant="contained" onClick={onCreateBonusPayment} disabled={bonusPaymentDisabled || isNotChangeTariff}>
                    {t('payments.pay-with-bonuses')}
                  </Button>
                </>
              )}
            </Box>
          </div>
        );
      case 2:
        return (
          <>
            <TextField label="order id" sx={{ width: '100%' }} value={orderId} disabled />
            <Button variant="contained" onClick={redirect} sx={{ mt: 2 }}>
              {t('payments.pay')}
            </Button>
          </>
        );
      case 3:
        return (
          <>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">{t('payments.currency.title')}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={currencyId}
                name="radio-buttons-group"
                onChange={(e) => {
                  setCurrencyId(e.currentTarget.value);
                }}
              >
                {currencies.map((item, index) => (
                  <FormControlLabel key={index} value={index} control={<Radio />} label={item} />
                ))}
              </RadioGroup>
            </FormControl>
            <Button variant="contained" onClick={onCreatePayment} sx={{ mt: 2 }} disabled={disablePaymentBtn}>
              {t('payments.pay')}
            </Button>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <CustomModal show={open} onClose={() => onClose()} header={title}>
        {renderContent()}
      </CustomModal>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{t('payments.error')}</Alert>
      </Snackbar>
    </>
  );
};
