import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useUserWallets = () => {
  const userWallets = useQuery({
    queryKey: ['userWallets'],
    queryFn: ApiService.getUserWallets,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
  });

  return { userWallets };
};
