import { Suspense } from 'react';
import { useLoaderData, useOutlet, Await } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import { AuthProvider } from '../../hooks/utility/useAuth';
import { Layout } from './Layout';
import { useTranslation } from 'react-i18next';

export const AuthLayout = () => {
  const outlet = useOutlet();

  const { userPromise } = useLoaderData();
  const { t } = useTranslation();

  return (
    <Suspense fallback={<LinearProgress />}>
      <Await
        resolve={userPromise}
        errorElement={<Alert severity="error">{t('common.something-went-wrong')}</Alert>}
        children={(user) => (
          <AuthProvider userData={user}>
            <Layout>{outlet}</Layout>
          </AuthProvider>
        )}
      />
    </Suspense>
  );
};
