import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik, setNestedObjectValues } from 'formik';
import { useCurrencyCalculator } from '../hooks/http-requests/useCurrencyCalculator';
import {
  useFromTo,
  useToFrom,
} from '../hooks/http-requests/useCalculatorFromTo';
import { OrdersList } from '../components/calculator/OrdersList';
import { calculatorValidation } from '../constants/validation';
import { Clear } from '@mui/icons-material';

export const СurrencyCalculator = () => {
  const { currencyList } = useCurrencyCalculator();
  const { orderList } = useFromTo();
  const { orderList2 } = useToFrom();
  const [fromBankListOptions, setFromBankListOptions] = useState([]);
  const [toBankListOptions, setToBankListOptions] = useState([]);
  const [fromBankListOptionsRight, setFromBankListOptionsRight] = useState([]);
  const [toBankListOptionsRight, setToBankListOptionsRight] = useState([]);
  const [erroreMessag, setErrorMessage] = useState('');
  const [leftSideText, setLeftSideText] = useState('');
  const [rightSideText, setRightSideText] = useState('');
  const [bestResultValue, setBestResultValue] = useState(0);
  const [middleResultRightValue, setMiddleResultRightValue] = useState(0);
  const [bestResultRightValue, setBestResultRightValue] = useState(0);
  const [middleResultValue, setMiddleResultValue] = useState(0);
  const [ordersListTo, setOrdersListTo] = useState([]);
  const [urlTo, setUrlTo] = useState('');
  const [ordersListFrom, setOrdersListFrom] = useState([]);
  const [urlFrom, setUrlFrom] = useState('');

  const [commissionLeft, setCommissionLeft] = useState(0);
  const [commissionRight, setCommissionRight] = useState(0);
  const [summLeft, setSummLeft] = useState(0);
  const [summRight, setSummRight] = useState(0);
  const [btnStateLeft, setBtnStateLeft] = useState(true);
  const [btnStateRight, setBtnStateRight] = useState(true);

  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      currencyTo: '',
      currencyFrom: '',
      summ: 0,
      bankFrom: '',
      bankTo: '',
    },
    onSubmit: () => {},
    validationSchema: calculatorValidation,
  });

  const formik2 = useFormik({
    initialValues: {
      currencyTo: '',
      currencyFrom: '',
      summ: 0,
      bankFrom: '',
      bankTo: '',
    },
    onSubmit: () => {},
    validationSchema: calculatorValidation,
  });

  const currencyListOptions = useMemo(() => {
    return currencyList.data?.map((w) => {
      const [key] = Object.keys(w);
      return {
        id: key.toUpperCase(),
        name: t(`calculator.currency.${key.toLowerCase()}`),
      };
    });
  }, [currencyList.data, t]);

  useEffect(() => {
    setLeftSideText('');
    if (formik.values.currencyFrom && formik.values.currencyFrom !== '') {
      const result = currencyList.data?.reduce((arr, cur) => {
        const [key] = Object.keys(cur);
        if (key.toUpperCase() === formik.values.currencyFrom) {
          const res = cur[key].map((bank) => ({
            id: bank.identifier,
            name: bank.tradeMethodName,
          }));
          return arr.concat(res);
        }
        return arr;
      }, []);
      setFromBankListOptions(result);
    }
  }, [currencyList.data, formik.values.currencyFrom, setFromBankListOptions]);

  useEffect(() => {
    setLeftSideText('');
    setRightSideText('');
    if (formik2.values.currencyFrom && formik2.values.currencyFrom !== '') {
      const result = currencyList.data?.reduce((arr, cur) => {
        const [key] = Object.keys(cur);
        if (key.toUpperCase() === formik2.values.currencyFrom) {
          const res = cur[key].map((bank) => ({
            id: bank.identifier,
            name: bank.tradeMethodName,
          }));
          return arr.concat(res);
        }
        return arr;
      }, []);
      setFromBankListOptionsRight(result);
    }
  }, [
    currencyList.data,
    formik2.values.currencyFrom,
    setFromBankListOptionsRight,
  ]);

  useEffect(() => {
    setLeftSideText('');
    if (
      (formik.values.currencyTo && formik.values.currencyTo !== '') ||
      (formik2.values.currencyTo && formik2.values.currencyTo !== '')
    ) {
      const result = currencyList.data?.reduce((arr, cur) => {
        const [key] = Object.keys(cur);
        if (key.toUpperCase() === formik.values.currencyTo) {
          const res = cur[key].map((bank) => ({
            id: bank.identifier,
            name: bank.tradeMethodName,
          }));
          return arr.concat(res);
        }
        return arr;
      }, []);
      setToBankListOptions(result);
    }
  }, [
    currencyList.data,
    formik.values.currencyTo,
    formik2.values.currencyTo,
    setToBankListOptions,
  ]);

  useEffect(() => {
    setLeftSideText('');
    if (formik2.values.currencyTo && formik2.values.currencyTo !== '') {
      const result = currencyList.data?.reduce((arr, cur) => {
        const [key] = Object.keys(cur);
        if (key.toUpperCase() === formik2.values.currencyTo) {
          const res = cur[key].map((bank) => ({
            id: bank.identifier,
            name: bank.tradeMethodName,
          }));
          return arr.concat(res);
        }
        return arr;
      }, []);
      setToBankListOptionsRight(result);
    }
  }, [currencyList.data, formik2.values.currencyTo, setToBankListOptionsRight]);

  useEffect(() => {
    setBtnStateLeft(
      formik.values.currencyFrom === '' ||
        formik.values.currencyTo === '' ||
        formik.values.summ < 100
    );
  }, [
    formik.values.currencyFrom,
    formik.values.currencyTo,
    formik.values.summ,
  ]);

  useEffect(() => {
    setBtnStateRight(
      formik2.values.currencyFrom === '' ||
        formik2.values.currencyTo === '' ||
        formik2.values.summ < 100
    );
  }, [
    formik2.values.currencyFrom,
    formik2.values.currencyTo,
    formik2.values.summ,
  ]);

  const submitGet = () => {
    setErrorMessage('');
    formik.validateForm().then((values) => {
      if (Object.keys(values).length > 0) {
        formik.setTouched(setNestedObjectValues(values, true));
        return;
      }
      const { summ, currencyFrom, currencyTo } = formik.values;
      const _summ =
        commissionLeft > 0 ? summ * (1 - commissionLeft / 100) : summ;
      const payload = {
        ...formik.values,
        summ: _summ,
      };

      setSummLeft(_summ);

      orderList
        .mutateAsync(payload)
        .then((result) => {
          const {
            bestResult,
            middleResult,
            ordersTo,
            urlOrdersTo,
            ordersFrom,
            urlOrdersFrom,
          } = result;
          setBestResultValue(bestResult);
          setMiddleResultValue(middleResult);
          setOrdersListTo(ordersTo);
          setUrlTo(urlOrdersTo);
          setOrdersListFrom(ordersFrom);
          setUrlFrom(urlOrdersFrom);
          setLeftSideText(`Обменять ${summ} ${currencyFrom} на ${currencyTo}`);
        })
        .catch((e) => {
          console.log(e);
          setErrorMessage(
            <Alert severity="error">{t('calculator.result.error')}</Alert>
          );
        });
    });
  };

  const submitGetTo = () => {
    setErrorMessage('');
    formik2.validateForm().then((values) => {
      if (Object.keys(values).length > 0) {
        formik2.setTouched(setNestedObjectValues(values, true));
        return;
      }

      const { summ, currencyFrom, currencyTo } = formik2.values;
      const _summ =
        commissionRight > 0 ? summ * (1 + commissionRight / 100) : summ;
      const payload = {
        ...formik2.values,
        summ: _summ,
      };

      setSummRight(_summ);
      orderList2
        .mutateAsync(payload)
        .then((result) => {
          const {
            bestResult,
            middleResult,
            ordersTo,
            urlOrdersTo,
            ordersFrom,
            urlOrdersFrom,
          } = result;
          setBestResultRightValue(bestResult);
          setMiddleResultRightValue(middleResult);
          setOrdersListTo(ordersTo);
          setUrlTo(urlOrdersTo);
          setOrdersListFrom(ordersFrom);
          setUrlFrom(urlOrdersFrom);
          setRightSideText(
            `Получить ${summ} ${currencyTo} отдав ${currencyFrom}`
          );
        })
        .catch((e) => {
          console.log(e);
          setErrorMessage(
            <Alert severity="error">{t('calculator.result.error')}</Alert>
          );
        });
    });
  };

  const selectBuild = (listOptions, label, formVar, sxStyle = {}, formLink) => {
    return (
      <FormControl
        fullWidth
        sx={{ mt: 3, ...sxStyle }}
        disabled={!listOptions || listOptions.length < 1}
      >
        <InputLabel id={label}>{t(label)}</InputLabel>
        <Select
          labelId={label}
          label={t(label)}
          sx={{ width: '100%' }}
          value={formLink.values[formVar]}
          onChange={(e) => formLink.setFieldValue(formVar, e.target.value)}
          disabled={!listOptions || listOptions.length < 1}
          endAdornment={
            <IconButton
              sx={{
                display: formLink.values[formVar] !== '' ? '' : 'none',
              }}
              onClick={() => formLink.setFieldValue(formVar, '')}
            >
              <Clear />
            </IconButton>
          }
        >
          {listOptions?.map((w) => (
            <MenuItem key={w.id} value={w.id}>
              {w.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      {erroreMessag}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '590px' }}>
            <CardContent>
              <Typography variant="string">
                {t('calculator.header.left')}
              </Typography>
              <Box component="form" onSubmit={formik.submitForm}>
                <TextField
                  select
                  label={t('calculator.currency')}
                  sx={{ width: '30%', mt: 3 }}
                  onChange={(e) =>
                    formik.setFieldValue('currencyFrom', e.target.value)
                  }
                  disabled={
                    !currencyListOptions || currencyListOptions.length < 1
                  }
                >
                  {currencyListOptions?.map((w) => (
                    <MenuItem key={w.id} value={w.id}>
                      {w.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  type="number"
                  label={t('calculator.summary')}
                  sx={{ width: '65%', mt: 3, ml: 2 }}
                  value={formik.values.summ}
                  onChange={(e) => formik.setFieldValue('summ', e.target.value)}
                  error={formik.touched.summ ? !!formik.errors.summ : false}
                  helperText={
                    formik.touched.summ ? formik.errors.summ : undefined
                  }
                />
                {selectBuild(
                  fromBankListOptions,
                  'calculator.bank',
                  'bankFrom',
                  {},
                  formik
                )}
                <TextField
                  select
                  label={t('calculator.currency.to')}
                  sx={{ width: '30%', mt: 3 }}
                  onChange={(e) =>
                    formik.setFieldValue('currencyTo', e.target.value)
                  }
                >
                  {currencyListOptions
                    ?.filter(
                      (item) =>
                        formik.values.currencyFrom === '' ||
                        formik.values.currencyFrom !== item.id
                    )
                    .map((w) => (
                      <MenuItem key={w.id} value={w.id}>
                        {w.name}
                      </MenuItem>
                    ))}
                </TextField>
                {selectBuild(
                  toBankListOptions,
                  'calculator.bank.to',
                  'bankTo',
                  { width: '65%', mt: 3, ml: 2 },
                  formik
                )}
                <TextField
                  sx={{ mt: 3 }}
                  label={t('calculator.commission')}
                  type="number"
                  value={commissionLeft}
                  onChange={(e) => setCommissionLeft(e.target.value)}
                ></TextField>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3 }}
                  onClick={submitGet}
                  disabled={btnStateLeft}
                >
                  {t('calculator.get')}
                </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 1,
                }}
              >
                <Typography variant="string">{leftSideText}</Typography>
              </Box>
              {bestResultValue > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 1,
                  }}
                >
                  <Typography variant="body1">
                    {t('calculator.bestresult.text')}
                  </Typography>
                  <Typography variant="body1">
                    {bestResultValue.toFixed(2)} {formik.values.currencyTo}
                  </Typography>
                  <Typography variant="body1">
                    ({(bestResultValue / (summLeft || 1)).toFixed(3)}) (
                    {((summLeft || 1) / bestResultValue).toFixed(3)})
                  </Typography>
                </Box>
              )}
              {middleResultValue > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 1,
                  }}
                >
                  <Typography variant="body1">
                    {t('calculator.middleresult.text')}
                  </Typography>
                  <Typography variant="body1">
                    {middleResultValue.toFixed(2)} {formik.values.currencyTo}
                  </Typography>
                  <Typography variant="body1">
                    ({(middleResultValue / (summLeft || 1)).toFixed(3)}) (
                    {((summLeft || 1) / middleResultValue).toFixed(3)})
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '590px' }}>
            <CardContent>
              <Typography variant="string">
                {t('calculator.header.right')}
              </Typography>
              <Box component="form" onSubmit={formik2.submitForm}>
                <TextField
                  select
                  label={t('calculator.form.currency.to')}
                  sx={{ width: '30%', mt: 3 }}
                  onChange={(e) =>
                    formik2.setFieldValue('currencyTo', e.target.value)
                  }
                  disabled={
                    !currencyListOptions || currencyListOptions.length < 1
                  }
                >
                  {currencyListOptions?.map((w) => (
                    <MenuItem key={w.id} value={w.id}>
                      {w.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  type="number"
                  label={t('calculator.summary')}
                  sx={{ width: '65%', mt: 3, ml: 2 }}
                  value={formik2.values.summ}
                  onChange={(e) =>
                    formik2.setFieldValue('summ', e.target.value)
                  }
                  error={formik2.touched.summ ? !!formik2.errors.summ : false}
                  helperText={
                    formik2.touched.summ ? formik2.errors.summ : undefined
                  }
                />
                {selectBuild(
                  toBankListOptionsRight,
                  'calculator.bank',
                  'bankTo',
                  {},
                  formik2
                )}
                <TextField
                  select
                  label={t('calculator.form.currency.from')}
                  sx={{ width: '30%', mt: 3 }}
                  onChange={(e) =>
                    formik2.setFieldValue('currencyFrom', e.target.value)
                  }
                  disabled={
                    !currencyListOptions || currencyListOptions.length < 1
                  }
                >
                  {currencyListOptions
                    ?.filter(
                      (item) =>
                        formik2.values.currencyTo === '' ||
                        formik2.values.currencyTo !== item.id
                    )
                    .map((w) => (
                      <MenuItem key={w.id} value={w.id}>
                        {w.name}
                      </MenuItem>
                    ))}
                </TextField>
                {selectBuild(
                  fromBankListOptionsRight,
                  'calculator.bank.to',
                  'bankFrom',
                  { width: '65%', mt: 3, ml: 2 },
                  formik2
                )}
                <TextField
                  sx={{ mt: 3 }}
                  label={t('calculator.commission')}
                  type="number"
                  value={commissionRight}
                  onChange={(e) => setCommissionRight(e.target.value)}
                ></TextField>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3 }}
                  onClick={submitGetTo}
                  disabled={btnStateRight}
                >
                  {t('calculator.get')}
                </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 1,
                }}
              >
                <Typography variant="string">{rightSideText}</Typography>
              </Box>
              {bestResultRightValue > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 1,
                  }}
                >
                  <Typography variant="body1">
                    {t('calculator.bestresult.text')}
                  </Typography>
                  <Typography variant="body1">
                    {bestResultRightValue.toFixed(2)}{' '}
                    {formik2.values.currencyFrom}
                  </Typography>
                  <Typography variant="body1">
                    ({(bestResultRightValue / (summRight || 1)).toFixed(3)}) (
                    {((summRight || 1) / bestResultRightValue).toFixed(3)})
                  </Typography>
                </Box>
              )}
              {middleResultRightValue > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 1,
                  }}
                >
                  <Typography variant="body1">
                    {t('calculator.middleresult.text')}
                  </Typography>
                  <Typography variant="body1">
                    {middleResultRightValue.toFixed(2)}{' '}
                    {formik2.values.currencyFrom}
                  </Typography>
                  <Typography variant="body1">
                    ({(middleResultRightValue / (summRight || 1)).toFixed(3)}) (
                    {((summRight || 1) / middleResultRightValue).toFixed(3)})
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {ordersListFrom.length > 0 && (
        <OrdersList
          ordersList={ordersListFrom}
          url={urlFrom}
          type="from"
          currency={formik.values.currencyFrom || formik2.values.currencyFrom}
        />
      )}
      {ordersListTo.length > 0 && (
        <OrdersList
          ordersList={ordersListTo}
          url={urlTo}
          type="to"
          currency={formik.values.currencyTo || formik2.values.currencyTo}
        />
      )}
    </>
  );
};
