import { useTranslation } from 'react-i18next';
import './ShareBotBlock.scss';
import { QRCodeSVG } from 'qrcode.react';
import { domToBlob, domToPng } from 'modern-screenshot';

export const ShareMobile = async () => {
  const shareBlock = document.getElementById('share');
  await domToBlob(shareBlock).then(async (dataUrl) => {
    const data = {
      files: [
        new File([dataUrl], 'SmartCryptoService.png', {
          type: dataUrl.type,
        }),
      ],
    };

    try {
      if (!navigator.canShare(data)) {
        SharePc();
      }
      await navigator.share(data);
    } catch (err) {
      SharePc();
    }
  });
};

export const SharePc = async () => {
  const shareBlock = document.getElementById('share');
  await domToPng(shareBlock).then((dataUrl) => {
    const link = document.createElement('a');
    link.download = 'SmartCryptoService.png';
    link.href = dataUrl;
    link.click();
  });
};

const random = Math.floor(Math.random() * (4 - 1 + 1) + 1);

export const ShareBotBlock = ({ title = '', primaryIndicator = '', secondaryIndicator = '', descriptions = [{ name: '', value: '' }], referralCode = '' }) => {
  const { t } = useTranslation();

  return (
    <div className="share-block__wrap">
      <div className="share-block" id="share">
        <div className="share-block__header">
          <div className="share-block__logo">
            <img src="/img/logo.svg" alt="" />
          </div>
          <div className="share-block__url">SmartCryptoService.com</div>
        </div>
        <div className="share-block__content" style={{ background: 'url(/img/share/bg.svg) no-repeat 114%' }}>
          <div className="share-block__info">
            <div className="share-block__title">{title}</div>
            <div className="share-block__indicator -big">{primaryIndicator}</div>

            <div className="share-block__indicator">{secondaryIndicator}</div>

            <div className="share-block__description">
              {descriptions.map((e) => (
                <div key={e.name} className="share-block__description-item">
                  <span>{e.name}</span>
                  <span>{e.value}</span>
                </div>
              ))}
            </div>
          </div>
          <div
            className="share-block__robo"
            style={{
              backgroundImage: `url(/img/share/robo${random}.png)`,
            }}
          ></div>
        </div>
        <div className="share-block__footer">
          <div className="share-block__qr">
            <QRCodeSVG
              style={{ border: '3px solid #fff' }}
              width={148}
              height={148}
              value={`https://app.smartcryptoservice.com/register?ref=${referralCode}`}
            />
          </div>
          <div className="share-block__footer-text">
            <div className="-big">
              {t('profile.referral')} {referralCode}
            </div>
            <div>{t('share.text')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
