import { useMemo } from 'react';
import Chart from 'react-apexcharts';

export const PieChart = ({ series, legend, colors }) => {
  const options = useMemo(
    () => ({
      series,
      legend: legend
        ? {
            position: 'bottom',
          }
        : false,
      labels: legend || [],
      tooltip: {
        enabled: true,
      },
      chart: {
        sparkline: {
          enabled: false,
        },
        parentHeightOffset: 0,
        width: '100%',
        height: '100%',
      },
      grid: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      colors,
    }),
    [colors, legend, series]
  );

  return (
    <Chart
      type="pie"
      options={options}
      series={series}
      height={legend ? '280px' : '250px'}
    />
  );
};
