import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useGetCountMultiBots = () => {
  const result = useQuery({
    queryKey: ['multibots/count'],
    queryFn: () => ApiService.getCountMultiBots(),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: 'always',
    staleTime: Infinity,
    cacheTime: 0,
    refetchInterval: 30000,
  });

  return { result };
};
