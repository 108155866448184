import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useAuth } from '../../hooks/utility/useAuth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

export const ForgottenPage = () => {
  const { forget } = useAuth();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.currentTarget);
    forget({
      email: data.get('email'),
    }).then((mes) => {
      setIsLoading(false);
      setMessage(mes);
    });
  };

  return (
    <form className="left" onSubmit={handleSubmit}>
      <span>{t('header.forgotten.text')}</span>

      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        autoFocus
      />
      {message && <div className="error-validator">{message}</div>}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        size="large"
        disabled={isLoading}
      >
        {isLoading && (
          <CircularProgress sx={{ mr: 1 }} color="primary" size={20} />
        )}
        {t('button.send')}
      </Button>
    </form>
  );
};
