import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useCoinsList = () => {
  const coinsList = useQuery({
    queryKey: ['coins'],
    queryFn: ApiService.getCoinsList,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
  });

  return { coinsList };
};
