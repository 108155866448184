import { Header } from './Header';
import { NavBar } from './NavBar';
import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/utility/useAuth';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Layout.scss';
import { useGetUser } from '../../hooks/http-requests/useGetUser';
import { useUpdateUser } from '../../hooks/http-requests/useUpdateUser';

export const Layout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const { user: userData } = useGetUser();
  const { updateUser } = useUpdateUser();

  //открытие TG web app на всю страницу
  window.Telegram?.WebApp?.expand();

  useEffect(() => {
    if (userData.data && !userData.data.locale) {
      const browserLanguage = navigator.language === 'ru-RU' || navigator.language === 'ru' ? 'ru' : 'en';
      updateUser
        .mutateAsync({
          locale: localStorage.getItem('application_language') || browserLanguage,
        })
        .then();
    }

    if (userData.data?.locale) {
      localStorage.setItem('application_language', userData.data?.locale);
      i18n.changeLanguage(userData.data?.locale).then();
    }
  }, [userData.data]);

  const menuItemsUser = [
    {
      title: t('nav.dashboard'),
      icon: 'dashboard',
      url: '/dashboard',
      end: true,
    },
    // {
    //   title: t('nav.operations'),
    //   icon: 'transactions',
    //   url: '/dashboard/operations',
    // },
    { title: t('nav.bots'), icon: 'bot', url: '/dashboard/bots/trade' },
    { title: t('profile.tariff'), icon: 'tarif', url: '/dashboard/payments' },
    { title: t('nav.analytics'), icon: 'chart', url: '/dashboard/analytics' },
    {
      title: t('nav.statistics'),
      icon: 'bot-statistics',
      url: '/dashboard/statistics',
    },
    // {
    //   title: t('nav.calculator'),
    //   icon: 'calculator',
    //   url: '/dashboard/calculator',
    // },
    {
      title: t('nav.add-wallet'),
      icon: 'wallet',
      url: '',
      sublinks: true,
    },
  ];

  const menuItemsAdmin = [
    {
      title: t('nav.dashboard'),
      icon: 'dashboard',
      url: '/admin',
      end: true,
    },
    {
      title: t('nav.users'),
      icon: 'avatar',
      url: '/admin/users',
    },
    { title: t('nav.referral'), icon: 'tarif', url: '/admin/referral' },
    { title: t('nav.analytics'), icon: 'chart', url: '/admin/analytics' },
  ];

  return (
    <>
      {!!user ? (
        <div>
          <NavBar
            menuItems={user.role === 'admin' ? menuItemsAdmin : menuItemsUser}
            rate={user.role === 'admin' ? false : true}
            navOpen={mobileOpen}
            toggleNavOpen={() => setMobileOpen(false)}
          />

          <Header toggleNavOpen={() => setMobileOpen(!mobileOpen)} navOpen={mobileOpen} />

          <div className="wrap-container">
            <div className="container">{children}</div>
          </div>
        </div>
      ) : (
        <div className="wrap-container-login">
          <div className="container-login">
            <RouterLink to="/" className="logo"></RouterLink>
            <div className="content-login">
              {children}
              <div className="right">
                <img src="/img/login/pc.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
