import React from 'react';
import Icon from '../Icons';
import './PaymentCard.scss';
import { t } from 'i18next';
import { Button } from '@mui/material';

const PaymentCard = ({ data, openModal }) => {
  const handleButtonClick = (value) => {
    openModal(value);
  };
  return (
    <div className={`card payment-card ${data?.active ? 'active' : ''}`}>
      <div className="payment-card__title">
        {data?.name}
        {data?.active && <Icon icon="check" className="small" />}
      </div>
      <div className="payment-card__body">
        {data?.list?.map((item) => (
          <div key={item.label} className="payment-card__label label">
            <div className="label__title">{item.label}</div>
            <div className="label__value">
              {item.value.text}

              {!!item.value.icon && <Icon className={`small ${item.value.icon === 'check' ? 'c-green' : 'c-red'}`} icon={item.value.icon} />}
            </div>
          </div>
        ))}
      </div>
      <div className="payment-card__price">{data?.price ? `$${data.price}` : t('payments.free')}</div>
      <div className="payment-card__buy c-green">
        {data?.price && (
          <Button variant="contained" onClick={() => handleButtonClick(data?.id)}>
            {data?.active ? t('payments.to-extend') : t('payments.buy')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PaymentCard;
