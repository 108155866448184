import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useBalanceInfo = (id) => {
  const balanceInfo = useQuery({
    queryKey: ['balance', id],
    queryFn: () => ApiService.getBalanceInfo(id),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
  });

  return { balanceInfo };
};
