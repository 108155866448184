import { Alert, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PaymentsModal } from '../components/PaymentsModal';
import { useGetUser } from '../hooks/http-requests/useGetUser';
import { useGetTariffs } from '../hooks/http-requests/useGetTariffs';
import './Payments.scss';
import PaymentCard from '../components/utility/PaymentCard';
import Preloader from '../components/Preloader';

export const Payments = () => {
  const { t } = useTranslation();
  const { user } = useGetUser();
  const { tariffs } = useGetTariffs();
  const [modalOpen, setModalOpen] = useState(false);
  const [tariffId, setTariffId] = useState();
  const [activeTab, setActiveTab] = useState(2);

  const tariffsCount = 4;
  const userTariffEndAt = user?.data?.tariff_end_at || false;

  const tariffsServer = tariffs?.data?.tariffs?.sort((a, b) => a.id - b.id);

  let tabs = [
    {
      name: t('payments.month'),
      title: t('payments.buy-month'),
      id: 1,
      tariffsData: [],
    },
    {
      name: t('payments.year'),
      title: t('payments.buy-year'),
      id: 2,
      tariffsData: [],
    },
  ];

  const userTariff = useMemo(() => {
    if (new Date(user?.data?.tariff_end_at) < new Date()) {
      return tariffs?.data?.tariffs?.find((t) => t.price === 0);
    }
    return tariffs?.data?.tariffs?.find((t) => t.id === user?.data?.tariff_id);
  }, [tariffs.data, user.data]);

  tariffsServer?.forEach((el, i) => {
    let item = {
      id: el.id,
      active: userTariff?.id === el.id,
      date: userTariff?.id === el.id ? userTariffEndAt : '',
      title: el.title,
      price: el.price || '',
      resaller: el.reseller_count,
      portfolio: el.portfolio_count,
      investment: el.investment_count,
      rebalancer: el.rebalancer_count,
      maxFund: el.max_fund,
      reinvest: el.reinvest,
    };

    const tariff = {
      id: el.id,
      name: item.title,
      active: item.active,
      price: item.price,
      date: item.date,
      list: [
        {
          label: t('payments.reseller-count'),
          value: { text: `${item.resaller}` },
        },
        {
          label: t('payments.portfolio-count'),
          value: { text: `${item.portfolio}` },
        },
        {
          label: t('payments.investment-count'),
          value: {
            icon: item.investment ? '' : 'close',
            text: item.investment ? `${item.investment}` : '',
          },
        },
        {
          label: t('payments.rebalancer-count'),
          value: {
            icon: item.rebalancer ? '' : 'close',
            text: item.rebalancer ? `${item.rebalancer}` : '',
          },
        },
        {
          label: t('bots.summary-widget.summary'),
          value: {
            icon: item.price > 0 ? 'check' : 'close',
          },
        },
        {
          label: t('bots.add-bot.emulate-operations'),
          value: { icon: 'check' },
        },
        {
          label: t('nav.analytics'),
          value: { icon: 'check' },
        },
        {
          label: t('payments.reinvest'),
          value: { icon: item.reinvest ? 'check' : 'close' },
        },
      ],
    };

    const addTarriff = (index, tariff) => {
      tabs[index].tariffsData.push(tariff);
    };

    if (i < tariffsCount) {
      addTarriff(0, tariff);
    } else if (i === tariffsCount) {
      addTarriff(1, tabs[0].tariffsData[0]);
      addTarriff(1, tariff);
    } else {
      addTarriff(1, tariff);
    }
  });
  const locationPath = useLocation().pathname;

  const statusMessage = locationPath.includes('/dashboard/payments/success') ? (
    <Alert variant="filled">{t('payments.result.success')}</Alert>
  ) : locationPath.includes('/dashboard/payments/fail') ? (
    <Alert variant="filled" severity="error">
      {t('payments.result.error')}
    </Alert>
  ) : null;

  const openModal = (tariffId) => {
    setTariffId(tariffId);
    setModalOpen(true);
  };

  const navigateTabs = (_, value) => {
    setActiveTab(value);
  };

  return (
    <>
      <PaymentsModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        bonusBalance={user.data?.bonus_balance}
        tariffs={tariffs.data?.tariffs}
        currencies={tariffs.data?.currencies}
        tariffId={tariffId}
      />
      {statusMessage}
      <div className="payments-page">
        <Tabs value={activeTab} onChange={navigateTabs}>
          {tabs.map((t) => (
            <Tab key={t.id} label={t.name} value={t.id} sx={{ textTransform: 'inherit', fontSize: 16 }} />
          ))}
        </Tabs>
        <div className="payments-page__header">
          <h3>{tabs[activeTab - 1].title}</h3>
        </div>
        {tabs[activeTab - 1]?.tariffsData?.length > 0 ? (
          <div className="payments-page__body">
            <div className="body__column">
              <PaymentCard data={tabs[activeTab - 1].tariffsData[0]} openModal={openModal} />
              <PaymentCard data={tabs[activeTab - 1].tariffsData[1]} openModal={openModal} />
            </div>
            <div className="body__column">
              <PaymentCard data={tabs[activeTab - 1].tariffsData[2]} openModal={openModal} />
              <PaymentCard data={tabs[activeTab - 1].tariffsData[3]} openModal={openModal} />
            </div>
          </div>
        ) : (
          <Preloader locading={true} />
        )}
      </div>
    </>
  );
};
