import React, { useState } from 'react';
import Icon from '../Icons';
import './MenuPopup.scss';
import { Badge, IconButton } from '@mui/material';

const MenuPopup = ({ icon = 'menu-dot', children, count = false }) => {
  const [show, setShow] = useState(false);

  const toggle = () => {
    setShow(!show);
    // if (!show) document.body.style.overflow = 'hidden';
    // else document.body.style.overflow = '';
  };
  // document.body.style.overflow = "hidden"

  return (
    <div className="menu-popup__wrap">
      <IconButton onClick={() => toggle()}>
        <Badge invisible={!count} variant="dot" color="primary">
          <Icon className="menu-popup__icon" icon={icon} />
        </Badge>
      </IconButton>

      {show && (
        <div
          className="menu-popup__click-outside"
          onClick={() => toggle()}
        ></div>
      )}
      <div className={`menu-popup ${show ? '' : 'hide'}`}>{children}</div>
    </div>
  );
};

export default MenuPopup;
