import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useToggleShowUnimportant = () => {
  const queryClient = useQueryClient();

  const toggleShowUnimportant = useMutation(ApiService.toggleShowUnimportant, {
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['user'] });
    },
  });

  return { toggleShowUnimportant };
};
