import { ReloadButton } from './utility/ReloadButton';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetBotsProfitData } from '../hooks/http-requests/useGetBotsProfitData';
import { ColumnChart } from './utility/ColumnChart';
import { IsEmptyObject } from './utility/IsEmptyObject';

export const ProfitWidget = ({ test, title, exchangeId, portfolio }) => {
  const { t } = useTranslation();
  const { getBotsProfitData } = useGetBotsProfitData(test, exchangeId, portfolio);
  const { data } = getBotsProfitData;

  let profit = 0;

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      for (let index = 0; index < data[key].length; index++) {
        const e = data[key][index];
        profit += +e.profit;
      }
    }
  }

  return (
    <>
      {!IsEmptyObject(data) && (
        <div className="card grid-fill">
          <div className="card__header">
            <div className="card__title">
              <span>
                {t(`profit.widget.${title || 'title'}`)} (<span className={profit > 0 ? `c-green` : 'c-red'}>${profit.toFixed(2)}</span>)
              </span>
              <ReloadButton loading={getBotsProfitData.isLoading || getBotsProfitData.isFetching} onClick={() => getBotsProfitData.refetch()} />
            </div>
          </div>
          <div>
            <ColumnChart data={data} withDetails={true} />
          </div>
        </div>
      )}
    </>
  );
};
