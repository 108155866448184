import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { PieChart } from '../utility/PieChart';
import { NativeCurrencyFormatWrapper } from '../utility/NativeCurrencyFormatWrapper';
import { BOT_REBALANCER_TYPE_OPTIONS } from '../../constants/bot-rebalancer.types';
import { NativeDateLocaleWrapper } from '../utility/NativeDateLocaleWrapper';
import { useAuth } from '../../hooks/utility/useAuth';
import './BotTradeWidget.scss';
import { Alert, CircularProgress, Snackbar } from '@mui/material';
import { ConfirmModal } from '../ConfirmModal';
import { DifferenceBetweenDates } from '../utility/DifferenceBetweenDates';

export const BotRebalancerWidget = ({ bot, lastPrices, withoutChart, changeBotStatus }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAdmin } = useAuth();
  const [startBalance, setStartBalance] = useState(0);
  const [modalConfirmDisableOpen, setModalConfirmDisableOpen] = useState(false);

  const series = useMemo(() => {
    const full = bot?.data?.data
      ?.map((s) => {
        return {
          value: s.ratio * 100,
          name: s.symbol,
          rating: s.rating,
        };
      })
      .sort((a, b) => a.rating - b.rating);
    if (!full) return [];
    if (full?.length > 5) {
      const first5 = full.slice(0, 5);
      const other = {
        name: t('balance.widget.other'),
        value: full.slice(5).reduce((acc, curr) => acc + curr.value, 0),
      };
      return first5.concat(other);
    }
    return full;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bot]);

  const botType = useMemo(() => BOT_REBALANCER_TYPE_OPTIONS.find((o) => o.value === bot?.bot_type), [bot]);

  const profit = useMemo(() => {
    if (bot.status !== 'ready') {
      return bot?.profit || 0;
    }

    const current = bot?.data?.data?.reduce((acc, s) => s.amount * lastPrices[s.symbol.toLowerCase()] + acc, 0) || 0;
    const previous = bot?.data?.data?.reduce((acc, s) => s.amount * (s.price || 0) + acc, 0) || 0;

    setStartBalance(previous);
    return current + (bot?.profit || 0) - previous;
  }, [bot, lastPrices]);

  const isLoadingPrice = useMemo(() => {
    if (bot?.status !== 'ready') return false;
    return !bot?.data?.data?.every((s) => lastPrices[s.symbol.toLowerCase()]);
  }, [bot, lastPrices]);

  const profitPercent = useMemo(() => (((startBalance || 0) + profit) / (startBalance || 0) - 1) * 100, [startBalance, profit]);

  const onChangeBotStatus = () => {
    // e.stopPropagation();
    changeBotStatus?.(bot.id, bot.status === 'ready' ? 'disabled' : 'active');
  };

  const gotoBot = (bot_id) => {
    if (isAdmin()) {
      navigate(`/admin/bots/rebalancer/${bot_id}`);
    } else {
      navigate(`/dashboard/bots/rebalancer/${bot_id}`);
    }
  };

  return (
    <div
      className={`${bot.emulate_operations ? '-test-bot' : ''} ${!withoutChart ? 'card bots-trade-widget' : ''} ${
        bot.status === 'ready' ? `-active` : `-deactive`
      }`}
    >
      {withoutChart && (
        <Snackbar open={isLoadingPrice} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert sx={{ boxShadow: '0 0 15px rgba(0,0,0,0.37)' }} icon={<CircularProgress color="primary" size={20} />} severity="info">
            {t('bots.loading-price')}
          </Alert>
        </Snackbar>
      )}
      {!withoutChart && (
        <div className="bots-trade-widget__title">
          <div className="flex">
            <div>
              <div className="link -link" onClick={() => gotoBot(bot?.id)}>{`${t(botType?.label)} ${bot.name ? ' "' + bot.name + '"' : ''}`}</div>

              <div className="bots-trade-widget__desc">
                {t(`bots.add-bot.type.rebalancer`)}
                {bot.emulate_operations ? ` (${t('bots.summary-widget.summary-test')})` : ''}
              </div>
            </div>
          </div>
          {bot?.status === 'ready' && (
            <div onClick={() => setModalConfirmDisableOpen(true)} className={`toggle ${bot.status === 'ready' ? '-active' : '-deactive'}`}></div>
          )}
        </div>
      )}
      {!withoutChart && (
        <div className="bots-trade-widget__pie">
          {bot?.status === 'configured' || bot?.status === 'cancelling' || bot?.status === 'new' ? (
            <div style={{ display: 'flex', margin: '50px auto', width: 20 }}>
              <CircularProgress sx={{ mt: 1 }} color="primary" size={18} />
            </div>
          ) : (
            <PieChart series={series.map((e) => +e.value.toFixed(2))} legend={series.map((e) => e.name)} />
          )}
        </div>
      )}
      <div className="bots-trade-widget__content">
        <div className="bots-trade-widget__label">
          <div className="-text">
            {t(`bots.summary-widget.${bot.status === 'ready' ? 'balance-bot' : 'profit'}`)}
            {bot.status === 'ready' && <div className="f-small c-base-600">{t('bots.summary-widget.assets-sum')}</div>}
          </div>
          <div className={`-value ${profitPercent > 0 ? 'c-green' : 'c-red'}`}>
            {isLoadingPrice ? (
              <CircularProgress sx={{ mt: 1 }} color="primary" size={18} />
            ) : (
              <>
                <div className="-price">
                  $
                  <NativeCurrencyFormatWrapper value={startBalance + profit || bot?.fund + profit} isUsd={true} />
                </div>
                <div className="f-small">
                  $
                  <NativeCurrencyFormatWrapper value={profit} isUsd={true} /> ({profit > 0 && '+'}
                  {((profit / (startBalance || bot?.fund)) * 100).toFixed(3)}%)
                </div>
              </>
            )}
          </div>
        </div>

        <div className="bots-trade-widget__label">
          <div className="-text">{t('bots.summary-widget.start-balance')}</div>
          <div className={`-value`}>
            $
            <NativeCurrencyFormatWrapper value={startBalance || bot?.fund} isUsd={true} />
          </div>
        </div>

        <div className="bots-trade-widget__label">
          <div className="-text">{t('bots.list.status')}</div>
          <div className={`-value`}>{t(`bots.list.status.${bot?.status}`)}</div>
        </div>

        <div className="bots-trade-widget__label">
          <div className="-text">
            <div>{t('bots.widget.rebalance-at')}</div>
          </div>
          <div className={`-value`}>
            <div>
              {bot.rebalanceAt ? (
                <NativeDateLocaleWrapper
                  date={bot.rebalanceAt}
                  options={{
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  }}
                />
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>

        <div className="bots-trade-widget__label">
          <div className="-text">
            <div>{t('bots.widget.created-bot')}</div>
            <div>{t('bots.widget.created-at')}</div>
          </div>
          <div className={`-value`}>
            <div>
              {!!bot.created_at ? <DifferenceBetweenDates date={bot.created_at} lastDate={bot.status !== 'ready' ? bot.updated_at : undefined} /> : '-'}
            </div>
            <div>
              <NativeDateLocaleWrapper
                date={bot.created_at}
                options={{
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                }}
              />
            </div>
          </div>
        </div>
        <div className="bots-trade-widget__label">
          <div className="-text">
            <div>{t('operations.filters.wallet')}</div>
          </div>
          <div className={`-value`}>
            <div>{`${t(`wallet.${bot.type.toUpperCase()}`)}`}</div>
          </div>
        </div>

        <ConfirmModal
          open={modalConfirmDisableOpen}
          onCancel={() => setModalConfirmDisableOpen(false)}
          title={t('confirm.bot.disable.title')}
          onOK={() => {
            onChangeBotStatus();
            setModalConfirmDisableOpen(false);
          }}
          text={t('confirm.bot.disable.text-rebalanser')}
        />
      </div>
    </div>
  );
};
