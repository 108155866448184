import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';
import { useState } from 'react';

export const useCreateEmulations = () => {
  const [data, setEmulationData] = useState();

  const emulation = useQuery({
    queryKey: ['emulation', data],
    queryFn: () => ApiService.createEmulations(data),
    enabled: !!data,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
  });

  return { emulation, setEmulationData };
};
