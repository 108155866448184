import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { setNestedObjectValues, useFormik } from 'formik';
import { editBotValidation } from '../../constants/validation';
import { usePatchBot } from '../../hooks/http-requests/usePatchBot';
import * as React from 'react';
import CustomModal from '../CustomModal';
import { BOT_TYPES } from '../../constants/bot-types';

export const EditBotModal = ({ open, onClose, refetch, bot }) => {
  const { t } = useTranslation();
  const { patchBot } = usePatchBot();
  const formik = useFormik({
    initialValues: {
      name: bot.name ?? '',
      drawdown: bot.drawdown,
      isStopLoss: bot.is_stop_loss,
      stopLoss: bot.stop_loss ?? undefined,
      profit: bot.profit ?? undefined,
      isExitAfterProfit: bot.is_exit_after_profit,
      isReinvest: bot.is_reinvest,
    },
    onSubmit: () => {},
    validationSchema: editBotValidation,
  });

  const isStopLossOptionAvailable = bot.bot_type !== BOT_TYPES.rebalancer && bot.bot_type !== BOT_TYPES.investment;

  const isExitAfterProfitOptionAvailable = bot.bot_type !== BOT_TYPES.rebalancer;

  const submit = () => {
    formik.validateForm().then((values) => {
      if (Object.keys(values).length > 0) {
        formik.setTouched(setNestedObjectValues(values, true));
        return;
      }
      const { drawdown, ...req } = formik.values;
      patchBot.mutateAsync({ ...req, id: bot.id }).then(() => {
        refetch();
        onClose();
      });
    });
  };

  const isPortfolio = bot.bot_type === BOT_TYPES.portfolio;

  return (
    <CustomModal
      show={open}
      onClose={onClose}
      header={t('edit-bot.title')}
      footer={
        <Button fullWidth variant="contained" onClick={submit} disabled={patchBot.isLoading}>
          {t('bots.info.change')}
        </Button>
      }
    >
      <Box component="form" onSubmit={formik.submitForm}>
        {/* имя бота */}
        <TextField
          name="name"
          label={t('bots.add-bot.name')}
          sx={{ width: '100%', mt: 3 }}
          value={formik.values.name}
          onChange={(e) => formik.setFieldValue('name', e.target.value)}
          error={formik.touched.name ? !!formik.errors.name : false}
          helperText={formik.touched.name ? formik.errors.name : undefined}
          onBlur={formik.handleBlur}
        />

        {/* стоп лосс */}
        {isStopLossOptionAvailable && (
          <FormControlLabel
            sx={{ display: 'flex', mt: 2 }}
            control={<Checkbox checked={formik.values.isStopLoss} onChange={(e) => formik.setFieldValue('isStopLoss', e.target.checked)} />}
            label={
              <>
                <Typography variant="body1">{t(`bots.add-bot.is-stop-loss${isPortfolio ? '.portfolio' : ''}`)}</Typography>
                <Typography variant="subtitle2">{t(`bots.add-bot.is-stop-loss.text`)}</Typography>
              </>
            }
          />
        )}
        {isStopLossOptionAvailable && formik.values.isStopLoss && (
          <>
            <TextField
              type="number"
              name="stopLoss"
              label={t('bots.add-bot.stop-loss')}
              sx={{ width: '100%', mt: 2 }}
              value={formik.values.stopLoss}
              onChange={(e) => formik.setFieldValue('stopLoss', e.target.value)}
              error={formik.touched.stopLoss ? !!formik.errors.stopLoss : false}
              helperText={formik.touched.stopLoss ? formik.errors.stopLoss : undefined}
              onBlur={formik.handleBlur}
            />
            <Typography variant="subtitle2">{t(`bots.add-bot.stop-loss.text${isPortfolio ? '.portfolio' : ''}`)}</Typography>
          </>
        )}

        {/* тейк профит */}
        {isExitAfterProfitOptionAvailable && (
          <FormControlLabel
            sx={{ display: 'flex', mt: 2 }}
            control={<Checkbox checked={formik.values.isExitAfterProfit} onChange={(e) => formik.setFieldValue('isExitAfterProfit', e.target.checked)} />}
            label={
              <>
                <Typography variant="body1">{t('bots.add-bot.is-exit-after-profit')}</Typography>
                <Typography variant="subtitle2">{t('bots.add-bot.is-exit-after-profit.text')}</Typography>
              </>
            }
          />
        )}
        {isExitAfterProfitOptionAvailable && formik.values.isExitAfterProfit && (
          <>
            <TextField
              type="number"
              name="profit"
              label={t('bots.add-bot.profit')}
              sx={{ width: '100%', mt: 2 }}
              value={formik.values.profit}
              onChange={(e) => formik.setFieldValue('profit', e.target.value)}
              error={formik.touched.profit ? !!formik.errors.profit : false}
              helperText={formik.touched.profit ? formik.errors.profit : undefined}
              onBlur={formik.handleBlur}
            />
            <Typography variant="subtitle2">{t('bots.add-bot.profit.text')}</Typography>
          </>
        )}
        {/* <FormControlLabel
            sx={{ mt: 3, display: 'flex' }}
            control={
              <Checkbox
                checked={formik.values.isReinvest}
                onChange={(e) =>
                  formik.setFieldValue('isReinvest', e.target.checked)
                }
              />
            }
            label={t('bots.add-bot.reinvest')}
          /> */}
      </Box>
    </CustomModal>
  );
};
