import { useTranslation } from 'react-i18next';
import { Alert, Button, Snackbar, TextField } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { setNestedObjectValues, useFormik } from 'formik';
import { withdrawBonusesValidation } from '../constants/validation';
import { useWithdrawBonuses } from '../hooks/http-requests/useWithdrawBonuses';
import './WithdrawBonusesModal.scss';
import CustomModal from './CustomModal';

export const WithdrawBonusesModal = ({ open, onClose, balance }) => {
  const { t } = useTranslation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const { withdrawBonuses } = useWithdrawBonuses();

  const formik = useFormik({
    initialValues: {
      amount: 15,
      wallet: '',
    },
    onSubmit: () => {},
    validationSchema: withdrawBonusesValidation,
  });

  const submit = () => {
    formik.validateForm().then((values) => {
      if (Object.keys(values).length > 0) {
        formik.setTouched(setNestedObjectValues(values, true));
        return;
      }

      withdrawBonuses
        .mutateAsync(formik.values)
        .then(() => {
          setSnackbarMessage(<Alert severity="success">{t('profile.withdraw-bonuses-success')}</Alert>);
          setSnackbarOpen(true);
          onClose();
        })
        .catch(() => {
          setSnackbarMessage(<Alert severity="error">{t('common.something-went-wrong')}</Alert>);
          setSnackbarOpen(true);
        });
    });
  };

  return (
    <>
      <CustomModal
        show={open}
        onClose={() => onClose()}
        header={t('profile.withdraw-bonuses.title')}
        footer={
          <Button variant="contained" onClick={submit} disabled={withdrawBonuses.isLoading}>
            {t('profile.withdraw-bonuses')}
          </Button>
        }
      >
        <div className="bonuses">
          <TextField
            name="amount"
            type="number"
            label={t('bots.operations.amount')}
            sx={{ width: '100%' }}
            value={formik.values.amount}
            onChange={(e) => formik.setFieldValue('amount', e.target.value)}
            error={formik.touched.amount ? !!formik.errors.amount : false}
            helperText={formik.touched.amount ? formik.errors.amount : undefined}
            onBlur={formik.handleBlur}
          />
          <TextField label={t('bots.summary-widget.current-balance')} sx={{ width: '100%' }} value={balance} disabled />
        </div>
        <TextField
          name="wallet"
          label={t('profile.web-wallet')}
          sx={{ width: '100%', mt: 2 }}
          value={formik.values.wallet}
          onChange={(e) => formik.setFieldValue('wallet', e.target.value)}
          error={formik.touched.wallet ? !!formik.errors.wallet : false}
          helperText={formik.touched.wallet ? formik.errors.wallet : undefined}
          onBlur={formik.handleBlur}
          placeholder="BEP20 - 1234567890abcdefghijklmnopqrstuvwxyz"
        />
        <div className="helper-text">{t('profile.withdraw-bonuses.text')}</div>
      </CustomModal>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        {snackbarMessage}
      </Snackbar>
    </>
  );
};
