import { Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useActiveTab } from '../../hooks/utility/useActiveTab';
import { AddBotModal } from '../../components/bots/AddBotModal';
import { Clear } from '@mui/icons-material';
import { useGetMultiBots } from '../../hooks/http-requests/useGetMultiBots';
import { BotRebalancerSummaryWidget } from '../../components/bots/BotRebalancerSummaryWidget';
import { BotRebalancerWidget } from '../../components/bots/BotRebalancerWidget';
import { useAuth } from '../../hooks/utility/useAuth';
import { useGetMultiBotsAdmin } from '../../hooks/http-requests/useGetMultiBotsAdmin';
import { useDisableAllBots } from '../../hooks/http-requests/useDisableAllBots';
import { useGetTradeBotsAdmin } from '../../hooks/http-requests/useGetTradeBotsAdmin';
import { useGetTradeBots } from '../../hooks/http-requests/useGetTradeBots';
import { useChangeRebalancerBotStatus } from '../../hooks/http-requests/useChangeRebalancerBotStatus';
import { useAvailableWallets } from '../../hooks/http-requests/useAvailableWallets';
import { useUserWallets } from '../../hooks/http-requests/useUserWallets';
import { AddBotFormModal } from '../../components/bots/AddBotFormModal';
import './BotsTrade.scss';
import MenuPopup from '../../components/utility/MenuPopup';
import Icon from '../../components/Icons';
import Preloader from '../../components/Preloader';
import { NotDataCard } from '../../components/utility/NotDataCard';
import { NoWalletsBlock } from '../../components/NoWalletsBlock';
import { BinanceCurrentData } from '../../components/utility/BinanceCurrentData';
import { BybitCurrentData } from '../../components/utility/BybitCurrentData';

export const BotsRebalancer = () => {
  const { t } = useTranslation();
  const { active } = useActiveTab();
  const navigate = useNavigate();
  const location = useLocation();
  const [binancePrices, setBinancePrices] = useState({});
  const [bybitPrices, setBybitPrices] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAddBotOpen, setModalAddBotOpen] = useState('');
  const [search, setSearch] = useState('');
  const { isAdmin } = useAuth();
  const [searchParams] = useSearchParams();
  const [isFiltersActive, setIsFiltersActive] = useState(false);
  /* eslint react-hooks/rules-of-hooks: 0 */
  const { bots } = isAdmin()
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetTradeBotsAdmin(searchParams.get('user_id'))
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetTradeBots();
  const botQuery = isAdmin()
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetMultiBotsAdmin(searchParams.get('user_id'))
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetMultiBots();
  /* eslint react-hooks/rules-of-hooks: 2 */
  const { changeBotStatus } = useChangeRebalancerBotStatus();
  const { disableAllBots } = useDisableAllBots();
  const { availableWallets } = useAvailableWallets();
  const { userWallets } = useUserWallets();

  const statusesList = [
    { value: 'active', label: t('bots.list.status.active') },
    { value: 'disabled', label: t('bots.list.status.disabled') },
  ];

  useEffect(() => {
    if (!active) {
      setBinancePrices({});
      setBybitPrices({});
    }
  }, [active]);

  useEffect(() => {
    setIsFiltersActive(!!search || !!botQuery.filter.status || !!botQuery.filter.wallet);
  }, [botQuery.filter.status, botQuery.filter.wallet, isFiltersActive, search, searchParams]);

  const walletOptions = useMemo(() => {
    return userWallets.data?.map((w) => {
      const wallet = availableWallets.data?.find((aw) => aw.id === w.exchange_id);
      return {
        ...w,
        name: wallet?.name,
      };
    });
  }, [availableWallets.data, userWallets.data]);

  const binanceBots = useMemo(() => botQuery.result?.data?.filter((b) => b.type === 'BINANCE'), [botQuery.result?.data]);

  const binanceSymbols = useMemo(
    () => [...new Set(binanceBots?.reduce((acc, curr) => acc.concat(curr?.data?.data?.map((s) => s.symbol.toLowerCase()) || []), []).sort())],
    [binanceBots]
  );

  const bybitBots = useMemo(() => botQuery.result?.data?.filter((b) => b.type === 'bybit'), [botQuery.result?.data]);

  const bybitSymbols = useMemo(
    () => [...new Set(bybitBots?.reduce((acc, curr) => acc.concat(curr?.data?.data?.map((s) => s.symbol.toLowerCase()) || []), []).sort())],
    [bybitBots]
  );

  const nonTestBots = useMemo(() => botQuery.result?.data?.filter((b) => !b.emulate_operations), [botQuery.result?.data]);

  const testBots = useMemo(() => botQuery.result?.data?.filter((b) => b.emulate_operations), [botQuery.result?.data]);

  const hasActiveBots = useMemo(
    () => bots?.data?.filter((b) => b.status === 'active')?.length || botQuery.result?.data?.filter((b) => b.status === 'ready')?.length,
    [bots?.data, botQuery.result?.data]
  );

  useEffect(() => {
    botQuery.setFilter(Object.fromEntries(searchParams.entries()));
    setSearch(searchParams.get('search') || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const setLastBinanceUpdate = (lastBinanceUpdate) => {
    if (!lastBinanceUpdate) return;
    const symbol = lastBinanceUpdate.stream.split('@')[0];
    setBinancePrices((price) => ({
      ...price,
      [symbol]: lastBinanceUpdate.data.k.c,
    }));
  };

  const setLastBybitUpdate = (lastBybitUpdate) => {
    if (!lastBybitUpdate) return;
    const symbol = lastBybitUpdate.data.s.toLowerCase();
    setBybitPrices((price) => ({
      ...price,
      [symbol]: lastBybitUpdate.data.c,
    }));
  };

  const mergedPrices = useMemo(() => {
    const merged = { ...binancePrices };
    Object.keys(bybitPrices).forEach((s) => {
      if (!merged[s]) {
        merged[s] = bybitPrices[s];
      }
    });
    return merged;
  }, [binancePrices, bybitPrices]);

  const handleModalClose = (type) => {
    setModalOpen(false);
    setModalAddBotOpen(type);
    // if (type) navigate(`/dashboard/add-bot?${createSearchParams({ type })}`);
  };

  const navigateTabs = (_, value) => {
    if (isAdmin()) {
      navigate(`/admin/bots/${value}?user_id=${searchParams.get('user_id')}`);
    } else {
      navigate(`/dashboard/bots/${value}`);
    }
  };

  const changeStatus = (botId, status) => {
    changeBotStatus.mutateAsync({ botId, status }).then(() => botQuery.result.refetch());
  };

  const onDisableAll = () => {
    disableAllBots.mutateAsync(undefined).then(() => {
      bots.refetch();
      botQuery.result.refetch();
    });
  };

  const setQueryParams = (newQuery) => {
    const currentQuery = Object.fromEntries(searchParams.entries());
    const query = { ...currentQuery, ...newQuery };
    const params = createSearchParams(query);
    navigate(`${location.pathname}?${params}`);
  };

  const clearSerach = () => {
    setSearch('');
    setQueryParams({ search: '' });
  };

  const userName = () => localStorage.getItem('showUserName');
  const userEmail = () => localStorage.getItem('showUserEmail');

  const countFilter = useMemo(() => !!botQuery.filter.wallet || !!botQuery.filter.status, [botQuery.filter]);

  return (
    <>
      <AddBotFormModal open={modalAddBotOpen !== ''} type={modalAddBotOpen} onClose={() => setModalAddBotOpen('')} />
      <NoWalletsBlock />
      <div className="bots-page__top">
        <Tabs value="rebalancer" onChange={navigateTabs}>
          <Tab label={t('bots.tabs.trade')} value="trade" sx={{ textTransform: 'inherit', fontSize: 16 }} />
          <Tab label={t('bots.tabs.portfolio')} value="portfolio" sx={{ textTransform: 'inherit', fontSize: 16 }} />
          <Tab label={t('bots.tabs.rebalancer')} value="rebalancer" sx={{ textTransform: 'inherit', fontSize: 16 }} />
        </Tabs>
        <div className="bots-page__buttons mobile-hidden">
          {/* <Button sx={{ mr: 2 }} variant="contained" color="warning" onClick={onDisableAll} disabled={!hasActiveBots} endIcon={<DoDisturbIcon />}>
            {t('bots.list.disable-all')}
          </Button> */}
          <Button variant="contained" onClick={() => setModalOpen(true)} endIcon={<AddIcon />}>
            {t('bots.add-bot')}
          </Button>
        </div>
      </div>
      <div className="bots-page__header">
        <h3 className="bots-page__title">
          {t('bots.rebalancer.title')}
          <div className="pc-hidden tablet-hidden">
            <MenuPopup icon="menu-dot">
              {/* <Button sx={{ mr: 2 }} variant="contained" color="warning" onClick={onDisableAll} disabled={!hasActiveBots} endIcon={<DoDisturbIcon />}>
                {t('bots.list.disable-all')}
              </Button> */}
              <Button variant="contained" onClick={() => setModalOpen(true)} endIcon={<AddIcon />}>
                {t('bots.add-bot')}
              </Button>
            </MenuPopup>
          </div>
        </h3>
        <div className="bots-page__filters">
          <div className="input__search w-fill">
            <TextField
              label={t('add-wallet.search')}
              fullWidth
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => e.keyCode === 13 && setQueryParams({ search })}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment style={{ opacity: search ? 1 : 0 }} position="end">
                      <Icon icon="close" className="medium pointer hover" onClick={() => clearSerach()} />
                    </InputAdornment>
                    <InputAdornment position="end">
                      <Icon icon="search" className="medium pointer hover" onClick={() => setQueryParams({ search })} />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </div>
          <MenuPopup icon="filter" count={countFilter}>
            <FormControl fullWidth>
              <InputLabel id="wallet-select-label">{t('operations.filters.wallet')}</InputLabel>
              <Select
                labelId="wallet-select-label"
                label={t('operations.filters.wallet')}
                fullWidth
                value={botQuery.filter.wallet || ''}
                onChange={(e) => setQueryParams({ wallet: e.target.value })}
                endAdornment={
                  <IconButton sx={{ display: botQuery.filter.wallet ? '' : 'none' }} onClick={() => setQueryParams({ wallet: '' })}>
                    <Clear />
                  </IconButton>
                }
              >
                {walletOptions?.map((w) => (
                  <MenuItem key={w.id} value={w.id}>
                    {w.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="status-select-label">{t('bots.list.status')}</InputLabel>
              <Select
                labelId="status-select-label"
                label={t('bots.list.status')}
                fullWidth
                value={botQuery.filter.status || ''}
                onChange={(e) => setQueryParams({ status: e.target.value })}
                endAdornment={
                  <IconButton sx={{ display: botQuery.filter.status ? '' : 'none' }} onClick={() => setQueryParams({ status: '' })}>
                    <Clear />
                  </IconButton>
                }
              >
                {statusesList.map((t) => (
                  <MenuItem key={t.value} value={t.value}>
                    {t.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MenuPopup>
        </div>
      </div>
      {!!botQuery.result.data?.length && (
        <div className="content bots-page__content">
          {!!nonTestBots.length && (
            <div className="card bots-trade-widget">
              <BotRebalancerSummaryWidget bots={nonTestBots} prices={mergedPrices} />
            </div>
          )}
          {!!testBots.length && (
            <div className="card bots-trade-widget -test-bot">
              <BotRebalancerSummaryWidget bots={testBots} prices={mergedPrices} test={true} />
            </div>
          )}

          {botQuery.result.data?.map((b) => (
            <BotRebalancerWidget bot={b} key={b.id} lastPrices={mergedPrices} changeBotStatus={changeStatus} />
          ))}
        </div>
      )}
      {botQuery.result.data?.length === 0 ? (
        <NotDataCard text={isFiltersActive ? undefined : t('bots.list.not-bot')} />
      ) : !botQuery.result.data?.length ? (
        <Preloader locading={true} />
      ) : (
        ''
      )}

      {isAdmin() && (
        <div className="admin-show-user">
          {userEmail()} {userName()}
        </div>
      )}

      <BinanceCurrentData symbols={binanceSymbols} binanceUpdate={setLastBinanceUpdate} active={active} />
      <BybitCurrentData symbols={bybitSymbols} bybitUpdate={setLastBybitUpdate} active={active} />
      <AddBotModal open={modalOpen} onClose={() => setModalOpen(false)} onOpen={handleModalClose} showType="rebalancer" />
    </>
  );
};
