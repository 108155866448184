import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useGetUserWithdrawRequests = (userId) => {
  const withdrawRequests = useQuery({
    queryKey: ['withdrawRequests', userId],
    queryFn: () => ApiService.getUserWithdrawRequests(userId),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
    enabled: !!userId,
  });

  return { withdrawRequests };
};
