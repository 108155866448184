import { useMutation } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useReloadOperations = (userdataid) => {
  const reloadOperations = useMutation(() =>
    ApiService.reloadOperations(userdataid)
  );

  return { reloadOperations };
};
