import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useGetUser = () => {
  const user = useQuery({
    queryKey: ['user'],
    queryFn: ApiService.getUser,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
  });

  return { user };
};
