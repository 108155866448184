import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useGetTariffs = () => {
  const tariffs = useQuery({
    queryKey: ['tariffs'],
    queryFn: ApiService.getTariffs,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
  });

  return { tariffs };
};
