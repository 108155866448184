import { Typography, IconButton, MenuItem, Menu, FormControl, InputLabel, Select } from '@mui/material';
import { BalanceMultiWidget } from '../components/BalanceMultiWidget';
import { WellcomeBlock } from '../components/WellcomeBlock';
import { MoreVert } from '@mui/icons-material';
import { useAvailableWallets } from '../hooks/http-requests/useAvailableWallets';
import { useUserWallets } from '../hooks/http-requests/useUserWallets';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfitWidget } from '../components/ProfitWidget';
import { DeleteWalletModal } from '../components/wallet/DeleteWalletModal';

export const Dashboard = () => {
  const { availableWallets } = useAvailableWallets();
  const { userWallets } = useUserWallets();
  const [walletId, setWalletId] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  const wallet = useMemo(() => {
    let userWallet = {};
    if (walletId) userWallet = userWallets.data?.find((uw) => uw.id === +walletId);
    else if (userWallets?.data) {
      userWallet = userWallets?.data[0];
      setWalletId(userWallets?.data[0]?.id);
    }

    const wallet = availableWallets.data?.find((aw) => aw.id === userWallet?.exchange_id);
    return {
      ...wallet,
      userId: +walletId,
    };
  }, [availableWallets.data, walletId, userWallets.data]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  const walletOptions = useMemo(() => {
    return userWallets.data?.map((w) => {
      const wallet = availableWallets.data?.find((aw) => aw.id === w.exchange_id);
      return {
        ...w,
        name: wallet?.name,
        type: wallet?.type,
      };
    });
  }, [availableWallets.data, userWallets.data]);

  return (
    <>
      <div className="grid-fill mt-16">
        <WellcomeBlock />
      </div>
      {walletId && (
        <>
          <div className="wallet-page__top">
            <img className="logo" src={wallet?.icon} alt="" />
            <h3>
              <FormControl fullWidth sx={{ minWidth: 200 }}>
                <InputLabel id="wallet-select-label">{t('operations.filters.wallet')}</InputLabel>
                <Select
                  disabled={!walletOptions?.length}
                  labelId="wallet-select-label"
                  label={t('operations.filters.wallet')}
                  value={walletId}
                  onChange={(e) => setWalletId(e.target.value)}
                >
                  {walletOptions?.map((w) => (
                    <MenuItem key={w.id} value={w.id}>
                      {w.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </h3>
            <div className="wallet-page__buttons">
              <IconButton onClick={handleOpenMenu} sx={{ p: 0, ml: 'auto' }}>
                <MoreVert sx={{ width: 32, height: 32 }} />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClick={handleCloseMenu}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={() => setModalOpen(true)}>
                  <Typography textAlign="center">{t('bots.info.delete')}</Typography>
                </MenuItem>
              </Menu>
            </div>
          </div>
          <div className="content wallet-page__content">
            {wallet?.id && (
              <>
                <BalanceMultiWidget wallet={wallet} current="true" />

                <div className="grid-fill">
                  <ProfitWidget exchangeId={wallet?.id} portfolio={true} />
                </div>
                <div className="grid-fill">
                  <ProfitWidget test={true} exchangeId={wallet?.id} title="title-demo" portfolio={true} />
                </div>
              </>
            )}
          </div>
          <DeleteWalletModal open={modalOpen} onClose={onModalClose} id={walletId} />
        </>
      )}
    </>
  );
};
