import { useEffect, useMemo, useState } from 'react';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { ApiService } from '../../utils/api-service';
import { Search } from '@mui/icons-material';
import { WithdrawRequestsModal } from '../../components/admin/WithdrawRequestsModal';
import './Admin.scss';
import MenuPopup from '../../components/utility/MenuPopup';
import { useIsMobile } from '../../components/utility/DetectDevice';

const limit = 50;

export const AdminReferral = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useState({
    email: '',
  });
  const { ref, inView } = useInView();
  const [search, setSearch] = useState('');
  const [requestsModalOpen, setRequestsModalOpen] = useState(false);
  const [userId, setUserId] = useState();

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['adminReferralInfinite', query],
    queryFn: ({ pageParam = 1 }) =>
      ApiService.getAdminReferralUsers({
        page: pageParam,
        limit,
        search,
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage?.length === limit ? allPages.length + 1 : undefined,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const userList = useMemo(
    () =>
      data?.pages?.reduce(
        (acc, current) => !!current && acc.concat(current),
        []
      ) || [],
    [data?.pages]
  );

  const changeQuery = (query) => {
    setQuery((prevState) => ({ ...prevState, ...query }));
  };

  const makeSearch = () => {
    changeQuery({ symbol: search });
  };

  const openWithdrawRequestsModal = (id) => {
    setUserId(id);
    setRequestsModalOpen(true);
  };

  const closeWithdrawRequestsModal = () => {
    setUserId(undefined);
    setRequestsModalOpen(false);
  };

  const isMobile = useIsMobile();

  return (
    <div className="admin-page">
      <div className="admin-page__header">
        <h3 className="admin-page__title">{t('nav.referral')}</h3>
        <div className="admin-page__filters">
          <div className="input__search">
            <TextField
              label={t('admin.users.email')}
              sx={{ width: '100%' }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => e.keyCode === 13 && makeSearch()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={makeSearch}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
      <div className="content admin-page__content">
        {userList.length > 0 && (
          <>
            {!isMobile ? (
              <table className="table-shadow mobile-hidden">
                <thead>
                  <tr>
                    <th>{t('admin.users.email')}</th>
                    <th>{t('admin.users.tariff')}</th>
                    <th align="right">{t('admin.referral.bonus-balance')}</th>
                    <th>{t('admin.referral.withdraw-requests')}</th>
                    <th>{t('admin.users.actions')}</th>
                  </tr>
                </thead>

                <tbody>
                  {userList?.map((s, i) => (
                    <tr
                      key={s.id}
                      ref={i === userList?.length - 5 ? ref : undefined}
                    >
                      <td>{s.email}</td>
                      <td>{s.title}</td>
                      <td>{s.bonus_balance}</td>
                      <td>
                        {+s.withdraw_requests > 0
                          ? t('payments.yes')
                          : t('payments.no')}
                      </td>
                      <td>
                        <Button onClick={() => openWithdrawRequestsModal(s.id)}>
                          {t('admin.referral.show-withdraw-requests')}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table className="pc-hidden tablet-hidden table-shadow">
                <thead>
                  <tr>
                    <th>{t('admin.users.email')}</th>
                    <th>{t('admin.users.tariff')}</th>
                    <th>{t('admin.referral.bonus-balance')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {userList?.map((s, i) => (
                    <tr
                      key={s.id}
                      ref={i === userList?.length - 5 ? ref : undefined}
                    >
                      <td>{s.email}</td>
                      <td>{s.title}</td>
                      <td>{s.bonus_balance}</td>
                      <td>
                        <MenuPopup>
                          <Button
                            onClick={() => openWithdrawRequestsModal(s.id)}
                          >
                            {t('admin.referral.show-withdraw-requests')}
                          </Button>
                        </MenuPopup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>

      <WithdrawRequestsModal
        open={requestsModalOpen}
        onClose={closeWithdrawRequestsModal}
        userId={userId}
      />
    </div>
  );
};
