import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useHasNewNotifications = () => {
  const hasNewNotifications = useQuery({
    queryKey: ['hasNewNotifications'],
    queryFn: ApiService.hasNewNotifications,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
    refetchInterval: 30000,
  });

  return { hasNewNotifications };
};
