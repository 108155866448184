import { t } from 'i18next';

export const DifferenceBetweenDates = ({ date, lastDate = new Date() }) => {
  const dif = ((new Date(lastDate) - new Date(date)) / 1000).toFixed(0);

  const days = Math.floor(dif / 60 / 60 / 24);
  const hours = Math.floor((dif / 60 / 60) % 24);
  const minuts = Math.floor((dif / 60) % 60);
  // const seconds = Math.floor(dif % 60);
  return (
    <>
      {days}
      {t('bots.widget.d')} {hours}
      {t('bots.widget.h')} {minuts}
      {t('bots.widget.m')}
    </>
  );
};
