import { useTranslation } from 'react-i18next';
import { Alert, Box, Button, Snackbar, TextField } from '@mui/material';
import * as React from 'react';
import { useAuth } from '../hooks/utility/useAuth';
import { useState, useEffect } from 'react';
import './ChangePasswordModal.scss';
import CustomModal from './CustomModal';

export const ChangePasswordModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { changePassword } = useAuth();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [buttonStatus, setButtonStatus] = useState(true);
  const [verifyPasswordError, setVerifyPasswordError] = useState(false);

  useEffect(() => {
    if (newPassword && newPassword !== '' && newPassword === verifyPassword) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
  }, [newPassword, verifyPassword]);

  React.useEffect(() => {
    if (verifyPassword && verifyPassword !== '' && verifyPassword === newPassword) {
      setVerifyPasswordError(false);
    } else if (verifyPassword && verifyPassword !== '' && verifyPassword !== newPassword) {
      setVerifyPasswordError(true);
    } else {
      setVerifyPasswordError(false);
    }
  }, [newPassword, verifyPassword]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    changePassword({
      newPassword: data.get('newPassword'),
      password: data.get('password'),
    }).then((mes) => {
      if (mes.success) {
        setSnackbarMessage(<Alert severity="success">{t(mes.message)}</Alert>);
        setSnackbarOpen(true);
        onClose();
      } else setErrorMessage(t(mes.message));
    });
  };

  return (
    <>
      <CustomModal show={open} onClose={() => onClose()} header={t('profile.change-password')}>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('auth.password')}
            id="password"
            autoComplete="current-password"
            type="password"
            autoFocus
          />
          {errorMessage && <div className="error-validator">{errorMessage}</div>}
          <TextField
            margin="normal"
            required
            fullWidth
            id="newPassword"
            label={t('auth.password.new')}
            name="newPassword"
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder={t('auth.password.min')}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id="verifyPassword"
            label={t('auth.password.verify')}
            name="verifyPassword"
            type="password"
            onChange={(e) => setVerifyPassword(e.target.value)}
            error={verifyPasswordError}
            placeholder={t('auth.password.min')}
            helperText={verifyPasswordError ? t('auth.password.verify.error') : ''}
          />

          <div className="custom-modal__footer mt-24">
            <Button type="submit" variant="contained" disabled={buttonStatus}>
              {t('button.save')}
            </Button>
          </div>
        </Box>
      </CustomModal>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        {snackbarMessage}
      </Snackbar>
    </>
  );
};
