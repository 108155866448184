import { Alert, Button, IconButton } from '@mui/material';
import { useAvailableWallets } from '../../hooks/http-requests/useAvailableWallets';
import { useState } from 'react';
import { ChooseWalletStep } from './ChooseWalletStep';
import { InsertWalletDataStep } from './InsertWalletDataStep';
import { useAddWallet } from '../../hooks/http-requests/useAddWallet';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import './AddWalletModal.scss';
import CustomModal from '../CustomModal';
import Icon from '../Icons';
import { Link } from 'react-router-dom';

export const AddWalletModal = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { availableWallets } = useAvailableWallets();
  const [wallet, setWallet] = useState();
  const { addWallet } = useAddWallet();
  const [error, setError] = useState();
  const [warning, setWarning] = useState();

  const handleClose = () => {
    onClose();
    setError(undefined);
    setWarning(undefined);
    setWallet(undefined);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const request = { id: wallet.id };
    Array.from(data.keys()).forEach((key) => {
      request[key] = data.get(key);
    });
    addWallet
      .mutateAsync(request)
      .then((res) => {
        setError(undefined);
        res ? setWarning(res) : handleClose();
      })
      .catch((err) => setError(err.response?.data?.message || 'common-error'));
  };

  const handleBackClick = () => {
    setError(undefined);
    setWallet(undefined);
  };

  const renderStep = () => {
    return wallet ? (
      <InsertWalletDataStep inputs={wallet.inputs} onSubmit={handleSubmit} name={wallet.tag} disabled={addWallet.isLoading} />
    ) : (
      <ChooseWalletStep availableWallets={availableWallets} onChooseWallet={(w) => setWallet(w)} />
    );
  };

  const headerTitle = () => {
    return wallet ? (
      <>
        <IconButton type="button" aria-label="back" onClick={() => handleBackClick()}>
          <Icon icon="arrow-left" />
        </IconButton>
        {wallet.name}
      </>
    ) : (
      t('add-wallet.title')
    );
  };

  return (
    <CustomModal show={open} onClose={handleClose} header={headerTitle()}>
      {!!error && (
        <Alert variant="filled" severity="error">
          {t(`add-wallet.error.${error}`)}
          {(error === 'wrong-commission-asset' || error === 'insufficient-balance') && (
            <p>
              {t('wallet.add.instruction')}{' '}
              <Link className="c-base-0 underline" target="_blank" to={t(`link.wallets.how-disable-bnb`)}>
                {t('wallet.add.how-disable-bnb')}
              </Link>
            </p>
          )}
        </Alert>
      )}
      {wallet && (
        <Alert variant="filled" severity="info">
          {t('welcome-block.wallets-no-warn')}
        </Alert>
      )}

      {!!warning && (
        <>
          <Alert variant="filled" severity="warning">
            {t(`add-wallet.error.${warning}`)}
            {(warning === 'wrong-commission-asset' || warning === 'insufficient-balance') && (
              <p>
                {t('wallet.add.instruction')}{' '}
                <Link className="c-base-0 underline" target="_blank" to={t(`link.wallets.how-disable-bnb`)}>
                  {t('wallet.add.how-disable-bnb')}
                </Link>
              </p>
            )}
          </Alert>
          <Button fullWidth variant="contained" sx={{ my: 2 }} onClick={handleClose}>
            OK
          </Button>
        </>
      )}
      {!warning && renderStep()}
    </CustomModal>
  );
};
