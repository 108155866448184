import { Navigate, useOutlet } from 'react-router-dom';
import { useAuth } from '../../hooks/utility/useAuth';

export const AdminProtectedLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (!user || user.role !== 'admin') {
    return <Navigate to="/" />;
  }

  return <>{outlet}</>;
};
