export const COINS = [
  {
    id: '01coin',
    symbol: 'zoc',
    name: '01coin',
  },
  {
    id: '0chain',
    symbol: 'zcn',
    name: 'Zus',
  },
  {
    id: '0-knowledge-network',
    symbol: '0kn',
    name: '0 Knowledge Network',
  },
  {
    id: '0-mee',
    symbol: 'ome',
    name: 'O-MEE',
  },
  {
    id: '0vix-protocol',
    symbol: 'vix',
    name: '0VIX Protocol',
  },
  {
    id: '0vm',
    symbol: 'zerovm',
    name: '0VM',
  },
  {
    id: '0x',
    symbol: 'zrx',
    name: '0x Protocol',
  },
  {
    id: '0x0-ai-ai-smart-contract',
    symbol: '0x0',
    name: '0x0.ai: AI Smart Contract',
  },
  {
    id: '0x1-tools-ai-multi-tool',
    symbol: '0x1',
    name: '0x1.tools: AI Multi-tool',
  },
  {
    id: '0xaiswap',
    symbol: '0xaiswap',
    name: '0xAISwap',
  },
  {
    id: '0xanon',
    symbol: '0xanon',
    name: '0xAnon',
  },
  {
    id: '0xblack',
    symbol: '0xb',
    name: '0xBlack',
  },
  {
    id: '0xcoco',
    symbol: 'coco',
    name: '0xCoco',
  },
  {
    id: '0xconnect',
    symbol: '0xcon',
    name: '0xConnect',
  },
  {
    id: '0xdao',
    symbol: 'oxd',
    name: '0xDAO',
  },
  {
    id: '0xdefcafe',
    symbol: 'cafe',
    name: '0xDEFCAFE',
  },
  {
    id: '0xengage',
    symbol: 'engage',
    name: '0xEngage',
  },
  {
    id: '0xfreelance',
    symbol: '0xfree',
    name: '0xFreelance',
  },
  {
    id: '0xfriend',
    symbol: '0xf',
    name: '0xFriend',
  },
  {
    id: '0xgambit',
    symbol: '0xg',
    name: '0xgambit',
  },
  {
    id: '0xgasless',
    symbol: '0xgas',
    name: '0xGasless (OLD)',
  },
  {
    id: '0xgasless-2',
    symbol: '0xgas',
    name: '0xGasless',
  },
  {
    id: '0x-leverage',
    symbol: 'oxl',
    name: '0x Leverage',
  },
  {
    id: '0xlsd',
    symbol: '0xlsd',
    name: '0xLSD',
  },
  {
    id: '0xmonero',
    symbol: '0xmr',
    name: '0xMonero',
  },
  {
    id: '0xnude',
    symbol: 'nude',
    name: '0xNude',
  },
  {
    id: '0xnumber',
    symbol: 'oxn',
    name: '0xNumber',
  },
  {
    id: '0xos-ai',
    symbol: '0xos',
    name: '0xOS AI',
  },
  {
    id: '0xs',
    symbol: '$0xs',
    name: '0xS',
  },
  {
    id: '0xsnipeproai',
    symbol: '0xspai',
    name: '0xSnipeProAi',
  },
  {
    id: '0xtools',
    symbol: '0xt',
    name: '0xTools',
  },
  {
    id: '0xvault',
    symbol: 'vault',
    name: '0xVault',
  },
  {
    id: '1000bonk',
    symbol: '1000bonk',
    name: '1000BONK',
  },
  {
    id: '1000btt',
    symbol: '1000btt',
    name: '1000BTT',
  },
  {
    id: '1000rats',
    symbol: '1000rats',
    name: '1000RATS',
  },
  {
    id: '1000sats-ordinals',
    symbol: '1000sats',
    name: '1000SATS (Ordinals)',
  },
  {
    id: '1000shib',
    symbol: '1000shib',
    name: '1000SHIB',
  },
  {
    id: '1000troll',
    symbol: '1000troll',
    name: '1000TROLL',
  },
  {
    id: '12ships',
    symbol: 'tshp',
    name: '12Ships',
  },
  {
    id: '16dao',
    symbol: '16dao',
    name: '16DAO',
  },
  {
    id: '1art',
    symbol: '1art',
    name: 'OneArt',
  },
  {
    id: '1ex',
    symbol: '1ex',
    name: '1ex',
  },
  {
    id: '1hive-water',
    symbol: 'water',
    name: '1Hive Water',
  },
  {
    id: '1inch',
    symbol: '1inch',
    name: '1inch',
  },
  {
    id: '1inch-yvault',
    symbol: 'yv1inch',
    name: '1INCH yVault',
  },
  {
    id: '1long',
    symbol: '1long',
    name: '1LONG',
  },
  {
    id: '1million-nfts',
    symbol: '1mil',
    name: '1MillionNFTs',
  },
  {
    id: '1minbet',
    symbol: '1mb',
    name: '1minBET',
  },
  {
    id: '1move token',
    symbol: '1mt',
    name: '1Move Token',
  },
  {
    id: '1reward-token',
    symbol: '1rt',
    name: '1Reward Token',
  },
  {
    id: '1safu',
    symbol: 'safu',
    name: '1SAFU',
  },
  {
    id: '1sol',
    symbol: '1sol',
    name: '1Sol',
  },
  {
    id: '-2',
    symbol: '₿',
    name: '₿',
  },
  {
    id: '2024',
    symbol: '2024',
    name: '2024',
  },
  {
    id: '2024pump',
    symbol: 'pump',
    name: '2024PUMP',
  },
  {
    id: '2049',
    symbol: '2049',
    name: '2049',
  },
  {
    id: '2080',
    symbol: '2080',
    name: '2080',
  },
  {
    id: '20weth-80bal',
    symbol: '20weth-80bal',
    name: '20WETH-80BAL',
  },
  {
    id: '21million',
    symbol: '21m',
    name: '21Million',
  },
  {
    id: '28',
    symbol: '28',
    name: '28',
  },
  {
    id: '28vck',
    symbol: 'vck',
    name: '28VCK',
  },
  {
    id: '2acoin',
    symbol: 'arms',
    name: '2ACoin',
  },
  {
    id: '2dai-io',
    symbol: '2dai',
    name: '2DAI.io',
  },
  {
    id: '2g-carbon-coin',
    symbol: '2gcc',
    name: '2G Carbon Coin',
  },
  {
    id: '2moon',
    symbol: 'moon',
    name: '2MOON',
  },
  {
    id: '2omb-finance',
    symbol: '2omb',
    name: '2omb',
  },
  {
    id: '2share',
    symbol: '2shares',
    name: '2SHARE',
  },
  {
    id: '300fit',
    symbol: 'fit',
    name: '300FIT',
  },
  {
    id: '3a-lending-protocol',
    symbol: 'a3a',
    name: '3A',
  },
  {
    id: '3d3d',
    symbol: '3d3d',
    name: '3d3d',
  },
  {
    id: '3dpass',
    symbol: 'p3d',
    name: '3DPass',
  },
  {
    id: '3-kingdoms-multiverse',
    symbol: '3km',
    name: '3 Kingdoms Multiverse',
  },
  {
    id: '3space-art',
    symbol: 'pace',
    name: '3SPACE ART',
  },
  {
    id: '3wild',
    symbol: '3wild',
    name: '3WILD',
  },
  {
    id: '4096',
    symbol: '4096',
    name: '4096',
  },
  {
    id: '42-coin',
    symbol: '42',
    name: '42-coin',
  },
  {
    id: '4chan',
    symbol: '4chan',
    name: '4Chan',
  },
  {
    id: '4dcoin',
    symbol: '4dc',
    name: '4DCoin',
  },
  {
    id: '4d-twin-maps-2',
    symbol: '4dmaps',
    name: '4D Twin Maps',
  },
  {
    id: '4int',
    symbol: '4int',
    name: '4INT',
  },
  {
    id: '4jnet',
    symbol: '4jnet',
    name: '4JNET',
  },
  {
    id: '4-next-unicorn',
    symbol: 'nxtu',
    name: '4 Next Unicorn',
  },
  {
    id: '50cal',
    symbol: '50cal',
    name: '50cal',
  },
  {
    id: '50cent',
    symbol: '50c',
    name: '50Cent',
  },
  {
    id: '5g-cash',
    symbol: 'vgc',
    name: '5G-CASH',
  },
  {
    id: '5ire',
    symbol: '5ire',
    name: '5ire',
  },
  {
    id: '5mc',
    symbol: '5mc',
    name: '5mc',
  },
  {
    id: '69420',
    symbol: '69420',
    name: '69420',
  },
  {
    id: '777',
    symbol: '777',
    name: '777',
  },
  {
    id: '888tron',
    symbol: '888',
    name: '888tron',
  },
  {
    id: '88mph',
    symbol: 'mph',
    name: '88mph',
  },
  {
    id: '8bitearn',
    symbol: '8bit',
    name: '8BITEARN',
  },
  {
    id: '8pay',
    symbol: '8pay',
    name: '8Pay',
  },
  {
    id: '90s-crypto-exchange',
    symbol: '90s',
    name: '90s Crypto Exchange',
  },
  {
    id: '99starz',
    symbol: 'stz',
    name: '99Starz',
  },
  {
    id: '9inch',
    symbol: '9inch',
    name: '9inch',
  },
  {
    id: '9inch-eth',
    symbol: '9inch',
    name: '9inch ETH',
  },
  {
    id: 'a3s',
    symbol: 'aa',
    name: 'A3S',
  },
  {
    id: 'a4-finance',
    symbol: 'a4',
    name: 'A4 Finance',
  },
  {
    id: 'aada-finance',
    symbol: 'lenfi',
    name: 'Lenfi',
  },
  {
    id: 'aag-ventures',
    symbol: 'aag',
    name: 'AAG',
  },
  {
    id: 'aardvark',
    symbol: 'ardvrk',
    name: 'Aardvark [OLD]',
  },
  {
    id: 'aardvark-2',
    symbol: 'vark',
    name: 'Aardvark',
  },
  {
    id: 'aarma',
    symbol: 'arma',
    name: 'Aarma',
  },
  {
    id: 'aave',
    symbol: 'aave',
    name: 'Aave',
  },
  {
    id: 'aave-aave',
    symbol: 'aaave',
    name: 'Aave AAVE',
  },
  {
    id: 'aave-amm-bptbalweth',
    symbol: 'aammbptbalweth',
    name: 'Aave AMM BptBALWETH',
  },
  {
    id: 'aave-amm-bptwbtcweth',
    symbol: 'aammbptwbtcweth',
    name: 'Aave AMM BptWBTCWETH',
  },
  {
    id: 'aave-amm-dai',
    symbol: 'aammdai',
    name: 'Aave AMM DAI',
  },
  {
    id: 'aave-amm-uniaaveweth',
    symbol: 'aammuniaaveweth',
    name: 'Aave AMM UniAAVEWETH',
  },
  {
    id: 'aave-amm-unibatweth',
    symbol: 'aammunibatweth',
    name: 'Aave AMM UniBATWETH',
  },
  {
    id: 'aave-amm-unicrvweth',
    symbol: 'aammunicrvweth',
    name: 'Aave AMM UniCRVWETH',
  },
  {
    id: 'aave-amm-unidaiusdc',
    symbol: 'aammunidaiusdc',
    name: 'Aave AMM UniDAIUSDC',
  },
  {
    id: 'aave-amm-unidaiweth',
    symbol: 'aammunidaiweth',
    name: 'Aave AMM UniDAIWETH',
  },
  {
    id: 'aave-amm-unilinkweth',
    symbol: 'aammunilinkweth',
    name: 'Aave AMM UniLINKWETH',
  },
  {
    id: 'aave-amm-unimkrweth',
    symbol: 'aammunimkrweth',
    name: 'Aave AMM UniMKRWETH',
  },
  {
    id: 'aave-amm-unirenweth',
    symbol: 'aammunirenweth',
    name: 'Aave AMM UniRENWETH',
  },
  {
    id: 'aave-amm-unisnxweth',
    symbol: 'aammunisnxweth',
    name: 'Aave AMM UniSNXWETH',
  },
  {
    id: 'aave-amm-uniuniweth',
    symbol: 'aammuniuniweth',
    name: 'Aave AMM UniUNIWETH',
  },
  {
    id: 'aave-amm-uniusdcweth',
    symbol: 'aammuniusdcweth',
    name: 'Aave AMM UniUSDCWETH',
  },
  {
    id: 'aave-amm-uniwbtcusdc',
    symbol: 'aammuniwbtcusdc',
    name: 'Aave AMM UniWBTCUSDC',
  },
  {
    id: 'aave-amm-uniwbtcweth',
    symbol: 'aammuniwbtcweth',
    name: 'Aave AMM UniWBTCWETH',
  },
  {
    id: 'aave-amm-uniyfiweth',
    symbol: 'aammuniyfiweth',
    name: 'Aave AMM UniYFIWETH',
  },
  {
    id: 'aave-amm-usdc',
    symbol: 'aammusdc',
    name: 'Aave AMM USDC',
  },
  {
    id: 'aave-amm-usdt',
    symbol: 'aammusdt',
    name: 'Aave AMM USDT',
  },
  {
    id: 'aave-amm-wbtc',
    symbol: 'aammwbtc',
    name: 'Aave AMM WBTC',
  },
  {
    id: 'aave-amm-weth',
    symbol: 'aammweth',
    name: 'Aave AMM WETH',
  },
  {
    id: 'aave-bal',
    symbol: 'abal',
    name: 'Aave BAL',
  },
  {
    id: 'aave-balancer-pool-token',
    symbol: 'abpt',
    name: 'Aave Balancer Pool Token',
  },
  {
    id: 'aave-bat',
    symbol: 'abat',
    name: 'Aave BAT',
  },
  {
    id: 'aave-bat-v1',
    symbol: 'abat',
    name: 'Aave BAT v1',
  },
  {
    id: 'aave-busd',
    symbol: 'abusd',
    name: 'Aave BUSD',
  },
  {
    id: 'aave-busd-v1',
    symbol: 'abusd',
    name: 'Aave BUSD v1',
  },
  {
    id: 'aave-crv',
    symbol: 'acrv',
    name: 'Aave CRV',
  },
  {
    id: 'aave-dai',
    symbol: 'adai',
    name: 'Aave DAI',
  },
  {
    id: 'aave-dai-v1',
    symbol: 'adai',
    name: 'Aave DAI v1',
  },
  {
    id: 'aave-enj',
    symbol: 'aenj',
    name: 'Aave ENJ',
  },
  {
    id: 'aave-enj-v1',
    symbol: 'aenj',
    name: 'Aave ENJ v1',
  },
  {
    id: 'aave-eth-v1',
    symbol: 'aeth',
    name: 'Aave ETH v1',
  },
  {
    id: 'aavegotchi',
    symbol: 'ghst',
    name: 'Aavegotchi',
  },
  {
    id: 'aavegotchi-alpha',
    symbol: 'alpha',
    name: 'Aavegotchi ALPHA',
  },
  {
    id: 'aavegotchi-fomo',
    symbol: 'fomo',
    name: 'Aavegotchi FOMO',
  },
  {
    id: 'aavegotchi-fud',
    symbol: 'fud',
    name: 'Aavegotchi FUD',
  },
  {
    id: 'aavegotchi-kek',
    symbol: 'kek',
    name: 'Aavegotchi KEK',
  },
  {
    id: 'aave-gusd',
    symbol: 'agusd',
    name: 'Aave GUSD',
  },
  {
    id: 'aave-interest-bearing-steth',
    symbol: 'asteth',
    name: 'Aave Interest Bearing STETH',
  },
  {
    id: 'aave-knc',
    symbol: 'aknc',
    name: 'Aave KNC',
  },
  {
    id: 'aave-knc-v1',
    symbol: 'aknc',
    name: 'Aave KNC v1',
  },
  {
    id: 'aave-link',
    symbol: 'alink',
    name: 'Aave LINK',
  },
  {
    id: 'aave-link-v1',
    symbol: 'alink',
    name: 'Aave LINK v1',
  },
  {
    id: 'aave-mana',
    symbol: 'amana',
    name: 'Aave MANA',
  },
  {
    id: 'aave-mana-v1',
    symbol: 'amana',
    name: 'Aave MANA v1',
  },
  {
    id: 'aave-mkr',
    symbol: 'amkr',
    name: 'Aave MKR',
  },
  {
    id: 'aave-mkr-v1',
    symbol: 'amkr',
    name: 'Aave MKR v1',
  },
  {
    id: 'aave-polygon-aave',
    symbol: 'amaave',
    name: 'Aave Polygon AAVE',
  },
  {
    id: 'aave-polygon-dai',
    symbol: 'amdai',
    name: 'Aave Polygon DAI',
  },
  {
    id: 'aave-polygon-usdc',
    symbol: 'amusdc',
    name: 'Aave Polygon USDC',
  },
  {
    id: 'aave-polygon-usdt',
    symbol: 'amusdt',
    name: 'Aave Polygon USDT',
  },
  {
    id: 'aave-polygon-wbtc',
    symbol: 'amwbtc',
    name: 'Aave Polygon WBTC',
  },
  {
    id: 'aave-polygon-weth',
    symbol: 'amweth',
    name: 'Aave Polygon WETH',
  },
  {
    id: 'aave-polygon-wmatic',
    symbol: 'amwmatic',
    name: 'Aave Polygon WMATIC',
  },
  {
    id: 'aave-rai',
    symbol: 'arai',
    name: 'Aave RAI',
  },
  {
    id: 'aave-ren',
    symbol: 'aren',
    name: 'Aave REN',
  },
  {
    id: 'aave-ren-v1',
    symbol: 'aren',
    name: 'Aave REN v1',
  },
  {
    id: 'aave-snx',
    symbol: 'asnx',
    name: 'Aave SNX',
  },
  {
    id: 'aave-snx-v1',
    symbol: 'asnx',
    name: 'Aave SNX v1',
  },
  {
    id: 'aave-stkgho',
    symbol: 'stkgho',
    name: 'Aave stkGHO',
  },
  {
    id: 'aave-susd',
    symbol: 'asusd',
    name: 'Aave SUSD',
  },
  {
    id: 'aave-susd-v1',
    symbol: 'asusd',
    name: 'Aave SUSD v1',
  },
  {
    id: 'aave-tusd',
    symbol: 'atusd',
    name: 'Aave TUSD',
  },
  {
    id: 'aave-tusd-v1',
    symbol: 'atusd',
    name: 'Aave TUSD v1',
  },
  {
    id: 'aave-uni',
    symbol: 'auni',
    name: 'Aave UNI',
  },
  {
    id: 'aave-usdc',
    symbol: 'ausdc',
    name: 'Aave v2 USDC',
  },
  {
    id: 'aave-usdc-v1',
    symbol: 'ausdc',
    name: 'Aave USDC v1',
  },
  {
    id: 'aave-usdt',
    symbol: 'ausdt',
    name: 'Aave USDT',
  },
  {
    id: 'aave-usdt-v1',
    symbol: 'ausdt',
    name: 'Aave USDT v1',
  },
  {
    id: 'aave-v3-1inch',
    symbol: 'a1inch',
    name: 'Aave v3 1INCH',
  },
  {
    id: 'aave-v3-aave',
    symbol: 'aaave',
    name: 'Aave v3 AAVE',
  },
  {
    id: 'aave-v3-ageur',
    symbol: 'aageur',
    name: 'Aave v3 agEUR',
  },
  {
    id: 'aave-v3-arb',
    symbol: 'aarb',
    name: 'Aave v3 ARB',
  },
  {
    id: 'aave-v3-bal',
    symbol: 'abal',
    name: 'Aave v3 BAL',
  },
  {
    id: 'aave-v3-btc-b',
    symbol: 'abtc.b',
    name: 'Aave v3 BTC.b',
  },
  {
    id: 'aave-v3-cbeth',
    symbol: 'acbeth',
    name: 'Aave v3 cbETH',
  },
  {
    id: 'aave-v3-crv',
    symbol: 'acrv',
    name: 'Aave v3 CRV',
  },
  {
    id: 'aave-v3-dai',
    symbol: 'adai',
    name: 'Aave v3 DAI',
  },
  {
    id: 'aave-v3-dpi',
    symbol: 'adpi',
    name: 'Aave v3 DPI',
  },
  {
    id: 'aave-v3-ens',
    symbol: 'aens',
    name: 'Aave v3 ENS',
  },
  {
    id: 'aave-v3-eure',
    symbol: 'aeure',
    name: 'Aave v3 EURe',
  },
  {
    id: 'aave-v3-eurs',
    symbol: 'aeurs',
    name: 'Aave v3 EURS',
  },
  {
    id: 'aave-v3-frax',
    symbol: 'afrax',
    name: 'Aave v3 FRAX',
  },
  {
    id: 'aave-v3-ghst',
    symbol: 'aghst',
    name: 'Aave v3 GHST',
  },
  {
    id: 'aave-v3-gno',
    symbol: 'agno',
    name: 'Aave v3 GNO',
  },
  {
    id: 'aave-v3-knc',
    symbol: 'aknc',
    name: 'Aave v3 KNC',
  },
  {
    id: 'aave-v3-ldo',
    symbol: 'aldo',
    name: 'Aave v3 LDO',
  },
  {
    id: 'aave-v3-link',
    symbol: 'alink',
    name: 'Aave v3 LINK',
  },
  {
    id: 'aave-v3-lusd',
    symbol: 'alusd',
    name: 'Aave v3 LUSD',
  },
  {
    id: 'aave-v3-mai',
    symbol: 'amai',
    name: 'Aave v3 MAI',
  },
  {
    id: 'aave-v3-maticx',
    symbol: 'amaticx',
    name: 'Aave v3 MaticX',
  },
  {
    id: 'aave-v3-metis',
    symbol: 'ametis',
    name: 'Aave v3 Metis',
  },
  {
    id: 'aave-v3-mkr',
    symbol: 'amkr',
    name: 'Aave v3 MKR',
  },
  {
    id: 'aave-v3-op',
    symbol: 'aop',
    name: 'Aave v3 OP',
  },
  {
    id: 'aave-v3-reth',
    symbol: 'areth',
    name: 'Aave v3 rETH',
  },
  {
    id: 'aave-v3-rpl',
    symbol: 'arpl',
    name: 'Aave v3 RPL',
  },
  {
    id: 'aave-v3-savax',
    symbol: 'asavax',
    name: 'Aave v3 sAVAX',
  },
  {
    id: 'aave-v3-sdai',
    symbol: 'asdai',
    name: 'Aave v3 sDAI',
  },
  {
    id: 'aave-v3-snx',
    symbol: 'asnx',
    name: 'Aave v3 SNX',
  },
  {
    id: 'aave-v3-stg',
    symbol: 'astg',
    name: 'Aave v3 STG',
  },
  {
    id: 'aave-v3-stmatic',
    symbol: 'astmatic',
    name: 'Aave v3 stMATIC',
  },
  {
    id: 'aave-v3-susd',
    symbol: 'asusd',
    name: 'Aave v3 sUSD',
  },
  {
    id: 'aave-v3-sushi',
    symbol: 'asushi',
    name: 'Aave v3 SUSHI',
  },
  {
    id: 'aave-v3-uni',
    symbol: 'auni',
    name: 'Aave v3 UNI',
  },
  {
    id: 'aave-v3-usdbc',
    symbol: 'ausdbc',
    name: 'Aave v3 USDbC',
  },
  {
    id: 'aave-v3-usdc',
    symbol: 'ausdc',
    name: 'Aave v3 USDC',
  },
  {
    id: 'aave-v3-usdc-e',
    symbol: 'ausdc.e',
    name: 'Aave v3 USDC.e',
  },
  {
    id: 'aave-v3-usdt',
    symbol: 'ausdt',
    name: 'Aave v3 USDT',
  },
  {
    id: 'aave-v3-wavax',
    symbol: 'awavax',
    name: 'Aave v3 WAVAX',
  },
  {
    id: 'aave-v3-wbtc',
    symbol: 'awbtc',
    name: 'Aave v3 WBTC',
  },
  {
    id: 'aave-v3-weth',
    symbol: 'aweth',
    name: 'Aave v3 WETH',
  },
  {
    id: 'aave-v3-wmatic',
    symbol: 'awmatic',
    name: 'Aave v3 WMATIC',
  },
  {
    id: 'aave-v3-wsteth',
    symbol: 'awsteth',
    name: 'Aave v3 wstETH',
  },
  {
    id: 'aave-wbtc',
    symbol: 'awbtc',
    name: 'Aave WBTC',
  },
  {
    id: 'aave-wbtc-v1',
    symbol: 'awbtc',
    name: 'Aave WBTC v1',
  },
  {
    id: 'aave-weth',
    symbol: 'aweth',
    name: 'Aave WETH',
  },
  {
    id: 'aave-xsushi',
    symbol: 'axsushi',
    name: 'Aave XSUSHI',
  },
  {
    id: 'aave-yfi',
    symbol: 'ayfi',
    name: 'Aave YFI',
  },
  {
    id: 'aave-yvault',
    symbol: 'yvaave',
    name: 'Aave yVault',
  },
  {
    id: 'aave-zrx',
    symbol: 'azrx',
    name: 'Aave ZRX',
  },
  {
    id: 'aave-zrx-v1',
    symbol: 'azrx',
    name: 'Aave ZRX v1',
  },
  {
    id: 'abachi-2',
    symbol: 'abi',
    name: 'Abachi',
  },
  {
    id: 'abcmeta',
    symbol: 'meta',
    name: 'ABCMETA',
  },
  {
    id: 'abc-pos-pool',
    symbol: 'abc',
    name: 'ABC PoS Pool',
  },
  {
    id: 'abel-finance',
    symbol: 'abel',
    name: 'ABEL Finance',
  },
  {
    id: 'abelian',
    symbol: 'abel',
    name: 'Abelian',
  },
  {
    id: 'abey',
    symbol: 'abey',
    name: 'Abey',
  },
  {
    id: 'able-finance',
    symbol: 'able',
    name: 'Able Finance',
  },
  {
    id: 'aboat-token-2',
    symbol: 'aboat',
    name: 'Aboat Token',
  },
  {
    id: 'abond',
    symbol: 'abond',
    name: 'ApeBond',
  },
  {
    id: 'absolute-sync-token',
    symbol: 'ast',
    name: 'Absolute Sync',
  },
  {
    id: 'abyss-world',
    symbol: 'awt',
    name: 'Abyss World',
  },
  {
    id: 'acala',
    symbol: 'aca',
    name: 'Acala',
  },
  {
    id: 'acala-dollar-acala',
    symbol: 'ausd',
    name: 'Acala Dollar (Acala)',
  },
  {
    id: 'access-protocol',
    symbol: 'acs',
    name: 'Access Protocol',
  },
  {
    id: 'acent',
    symbol: 'ace',
    name: 'Acent',
  },
  {
    id: 'acetoken',
    symbol: 'ace',
    name: 'ACEToken',
  },
  {
    id: 'acet-token',
    symbol: 'act',
    name: 'Acet',
  },
  {
    id: 'achain',
    symbol: 'act',
    name: 'Achain',
  },
  {
    id: 'achi',
    symbol: 'achi',
    name: 'achi',
  },
  {
    id: 'acid',
    symbol: 'acid',
    name: 'Acid',
  },
  {
    id: 'acknoledger',
    symbol: 'ack',
    name: 'AcknoLedger',
  },
  {
    id: 'acmfinance',
    symbol: 'acm',
    name: 'acmFinance',
  },
  {
    id: 'ac-milan-fan-token',
    symbol: 'acm',
    name: 'AC Milan Fan Token',
  },
  {
    id: 'acoconut',
    symbol: 'ac',
    name: 'ACoconut',
  },
  {
    id: 'acorn-protocol',
    symbol: 'acn',
    name: 'Acorn Protocol',
  },
  {
    id: 'acquire-fi',
    symbol: 'acq',
    name: 'Acquire.Fi',
  },
  {
    id: 'acreage-coin',
    symbol: 'acr',
    name: 'Acreage Coin',
  },
  {
    id: 'acria',
    symbol: 'acria',
    name: 'Acria.AI',
  },
  {
    id: 'acria-ai-aimarket',
    symbol: 'aimarket',
    name: 'Acria.AI AIMARKET',
  },
  {
    id: 'across-protocol',
    symbol: 'acx',
    name: 'Across Protocol',
  },
  {
    id: 'acryptos',
    symbol: 'acs',
    name: 'ACryptoS [OLD]',
  },
  {
    id: 'acryptos-2',
    symbol: 'acs',
    name: 'ACryptoS',
  },
  {
    id: 'acryptosi',
    symbol: 'acsi',
    name: 'ACryptoSI',
  },
  {
    id: 'actinium',
    symbol: 'acm',
    name: 'Actinium',
  },
  {
    id: 'action-coin',
    symbol: 'actn',
    name: 'Action Coin',
  },
  {
    id: 'acurast',
    symbol: 'acu',
    name: 'Acurast',
  },
  {
    id: 'acute-angle-cloud',
    symbol: 'aac',
    name: 'Double-A Chain',
  },
  {
    id: 'adacash',
    symbol: 'adacash',
    name: 'ADAcash',
  },
  {
    id: 'adadao',
    symbol: 'adao',
    name: 'ADADao',
  },
  {
    id: 'adadex',
    symbol: 'adex',
    name: 'Adadex',
  },
  {
    id: 'adamant',
    symbol: 'addy',
    name: 'Adamant',
  },
  {
    id: 'adamant-messenger',
    symbol: 'adm',
    name: 'ADAMANT Messenger',
  },
  {
    id: 'adam-cochran-friends-tech',
    symbol: 'adam',
    name: 'Adam Cochran (Friend.tech)',
  },
  {
    id: 'adanaspor-fan-token',
    symbol: 'adana',
    name: 'Adanaspor Fan Token',
  },
  {
    id: 'adapad',
    symbol: 'adapad',
    name: 'ADAPad',
  },
  {
    id: 'ada-peepos',
    symbol: 'fren',
    name: 'FREN Token',
  },
  {
    id: 'adappter-token',
    symbol: 'adp',
    name: 'Adappter',
  },
  {
    id: 'adapt3r-digital-treasury-bill-fund',
    symbol: 'tfbill',
    name: 'Adapt3r Digital Treasury Bill Fund',
  },
  {
    id: 'adaswap',
    symbol: 'asw',
    name: 'AdaSwap',
  },
  {
    id: 'ada-the-dog',
    symbol: 'ada',
    name: 'ADA the Dog',
  },
  {
    id: 'adax',
    symbol: 'adax',
    name: 'ADAX',
  },
  {
    id: 'adazoo',
    symbol: 'zoo',
    name: 'ADAZOO',
  },
  {
    id: 'add-finance',
    symbol: 'add',
    name: 'Add Finance',
  },
  {
    id: 'addiction',
    symbol: 'add',
    name: 'Addiction',
  },
  {
    id: 'adex',
    symbol: 'adx',
    name: 'AdEx',
  },
  {
    id: 'ad-flex-token',
    symbol: 'adf',
    name: 'Ad Flex',
  },
  {
    id: 'ado-network',
    symbol: 'ado',
    name: 'ADO Protocol',
  },
  {
    id: 'adonis-2',
    symbol: 'adon',
    name: 'Adonis',
  },
  {
    id: 'adreward',
    symbol: 'ad',
    name: 'ADreward',
  },
  {
    id: 'adroverse',
    symbol: 'adr',
    name: 'Adroverse',
  },
  {
    id: 'adshares',
    symbol: 'ads',
    name: 'Adshares',
  },
  {
    id: 'adult-playground',
    symbol: 'adult',
    name: 'Adult Playground',
  },
  {
    id: 'adv3nture-xyz-gemstone',
    symbol: 'gem',
    name: 'Adv3nture.xyz Gemstone',
  },
  {
    id: 'adv3nture-xyz-gold',
    symbol: 'gold',
    name: 'Adv3nture.xyz Gold',
  },
  {
    id: 'advanced-united-continent',
    symbol: 'auc',
    name: 'Advanced United Continent',
  },
  {
    id: 'advantis',
    symbol: 'advt',
    name: 'Advantis',
  },
  {
    id: 'adventure-gold',
    symbol: 'agld',
    name: 'Adventure Gold',
  },
  {
    id: 'adventurer-gold',
    symbol: 'gold',
    name: 'Adventurer Gold',
  },
  {
    id: 'advertise-coin',
    symbol: 'adco',
    name: 'Advertise Coin',
  },
  {
    id: 'aeggs',
    symbol: 'aeggs',
    name: 'aEGGS',
  },
  {
    id: 'aegis',
    symbol: 'ags',
    name: 'Aegis',
  },
  {
    id: 'aegis-ai',
    symbol: 'aegis',
    name: 'Aegis Ai',
  },
  {
    id: 'aegis-token-f7934368-2fb3-4091-9edc-39283e87f55d',
    symbol: 'on',
    name: 'Onsen Token',
  },
  {
    id: 'aelf',
    symbol: 'elf',
    name: 'aelf',
  },
  {
    id: 'aelin',
    symbol: 'aelin',
    name: 'Aelin',
  },
  {
    id: 'aelysir',
    symbol: 'ael',
    name: 'Aelysir',
  },
  {
    id: 'aeon',
    symbol: 'aeon',
    name: 'Aeon',
  },
  {
    id: 'aeonodex',
    symbol: 'aeonodex',
    name: 'AEONODEX',
  },
  {
    id: 'aerarium-fi',
    symbol: 'aera',
    name: 'Aerarium Fi',
  },
  {
    id: 'aerdrop',
    symbol: 'aer',
    name: 'Aerdrop',
  },
  {
    id: 'aergo',
    symbol: 'aergo',
    name: 'Aergo',
  },
  {
    id: 'aerodrome-finance',
    symbol: 'aero',
    name: 'Aerodrome Finance',
  },
  {
    id: 'aeron',
    symbol: 'arnx',
    name: 'Aeron',
  },
  {
    id: 'aerovek-aviation',
    symbol: 'aero',
    name: 'Aerovek Aviation',
  },
  {
    id: 'aesirx',
    symbol: 'aesirx',
    name: 'AesirX',
  },
  {
    id: 'aet',
    symbol: 'aet',
    name: 'AET',
  },
  {
    id: 'aeternity',
    symbol: 'ae',
    name: 'Aeternity',
  },
  {
    id: 'aether-games',
    symbol: 'aeg',
    name: 'Aether Games',
  },
  {
    id: 'aeusd',
    symbol: 'aeusd',
    name: 'aeUSD',
  },
  {
    id: 'aevum-ore',
    symbol: 'aevum',
    name: 'Aevum',
  },
  {
    id: 'aezora',
    symbol: 'azr',
    name: 'Azzure',
  },
  {
    id: 'afen-blockchain',
    symbol: 'afen',
    name: 'AFEN Blockchain',
  },
  {
    id: 'affinity',
    symbol: 'afnty',
    name: 'Affinity',
  },
  {
    id: 'affyn',
    symbol: 'fyn',
    name: 'Affyn',
  },
  {
    id: 'aficionadao',
    symbol: 'adao',
    name: 'AficionaDAO',
  },
  {
    id: 'afin-coin',
    symbol: 'afin',
    name: 'Asian Fintech',
  },
  {
    id: 'afkdao',
    symbol: 'afk',
    name: 'AFKDAO',
  },
  {
    id: 'afk-trading-bot',
    symbol: 'afk',
    name: 'AFK Trading Bot',
  },
  {
    id: 'afreum',
    symbol: 'afr',
    name: 'Afreum',
  },
  {
    id: 'afrix',
    symbol: 'afx',
    name: 'Afrix',
  },
  {
    id: 'afrostar',
    symbol: 'afro',
    name: 'Afrostar',
  },
  {
    id: 'aftermath-staked-sui',
    symbol: 'afsui',
    name: 'Aftermath Staked SUI',
  },
  {
    id: 'a-fund-baby',
    symbol: 'fund',
    name: 'A Fund Baby',
  },
  {
    id: 'afyonspor-fan-token',
    symbol: 'afyon',
    name: 'Afyonspor Fan Token',
  },
  {
    id: 'aga-carbon-credit',
    symbol: 'agac',
    name: 'AGA Carbon Credit',
  },
  {
    id: 'aga-carbon-rewards',
    symbol: 'acar',
    name: 'AGA Carbon Rewards',
  },
  {
    id: 'aga-rewards',
    symbol: 'edc',
    name: 'Edcoin',
  },
  {
    id: 'aga-token',
    symbol: 'aga',
    name: 'AGA',
  },
  {
    id: 'agavecoin',
    symbol: 'agvc',
    name: 'AgaveCoin',
  },
  {
    id: 'agave-token',
    symbol: 'agve',
    name: 'Agave',
  },
  {
    id: 'agenor',
    symbol: 'age',
    name: 'Agenor',
  },
  {
    id: 'a-gently-used-2001-honda',
    symbol: 'usedcar',
    name: 'A Gently Used 2001 Honda',
  },
  {
    id: 'a-gently-used-nokia-3310',
    symbol: 'usedphone',
    name: 'A Gently Used Nokia 3310',
  },
  {
    id: 'age-of-apes',
    symbol: 'apes',
    name: 'AGE OF APES',
  },
  {
    id: 'ageofgods',
    symbol: 'aog',
    name: 'AgeOfGods',
  },
  {
    id: 'age-of-zalmoxis-koson',
    symbol: 'koson',
    name: 'Age of Zalmoxis KOSON',
  },
  {
    id: 'ageur',
    symbol: 'ageur',
    name: 'agEUR',
  },
  {
    id: 'ageur-plenty-bridge',
    symbol: 'egeur.e',
    name: 'agEUR (Plenty Bridge)',
  },
  {
    id: 'aggrx',
    symbol: 'aggrx',
    name: 'AggrX',
  },
  {
    id: 'agii',
    symbol: 'agii',
    name: 'AGII',
  },
  {
    id: 'agile',
    symbol: 'agl',
    name: 'Agile',
  },
  {
    id: 'agility',
    symbol: 'agi',
    name: 'Agility',
  },
  {
    id: 'agnus-ai',
    symbol: 'agn',
    name: 'Agnus AI',
  },
  {
    id: 'agoras-currency-of-tau',
    symbol: 'agrs',
    name: 'Agoras: Currency of Tau',
  },
  {
    id: 'agoric',
    symbol: 'bld',
    name: 'Agoric',
  },
  {
    id: 'agos',
    symbol: 'agos',
    name: 'AGOS',
  },
  {
    id: 'agrello',
    symbol: 'dlt',
    name: 'Agrello',
  },
  {
    id: 'agri',
    symbol: 'agri',
    name: 'Agri',
  },
  {
    id: 'agricoin',
    symbol: 'agn',
    name: 'Agricoin',
  },
  {
    id: 'agrinode',
    symbol: 'agn',
    name: 'AgriNode',
  },
  {
    id: 'agritech',
    symbol: 'agt',
    name: 'Agritech',
  },
  {
    id: 'agro-global',
    symbol: 'agro',
    name: 'Agro Global Token',
  },
  {
    id: 'ahatoken',
    symbol: 'aht',
    name: 'AhaToken',
  },
  {
    id: 'a-hunters-dream',
    symbol: 'caw',
    name: 'A Hunters Dream',
  },
  {
    id: 'aiakita',
    symbol: 'aia',
    name: 'AiAkita',
  },
  {
    id: 'ai-analysis-token',
    symbol: 'aiat',
    name: 'AI Analysis Token',
  },
  {
    id: 'aibot',
    symbol: 'aibot',
    name: 'Aibot',
  },
  {
    id: 'aibra',
    symbol: 'abr',
    name: 'AIBRA',
  },
  {
    id: 'aicb',
    symbol: 'aicb',
    name: 'AICB',
  },
  {
    id: 'aichain',
    symbol: 'ait',
    name: 'AICHAIN',
  },
  {
    id: 'ai-code',
    symbol: 'aicode',
    name: 'AI CODE',
  },
  {
    id: 'aicoin-2',
    symbol: 'ai',
    name: 'AICoin',
  },
  {
    id: 'ai-coinova',
    symbol: 'aicn',
    name: 'AI Coinova',
  },
  {
    id: 'ai-com',
    symbol: 'ai.com',
    name: 'AI.COM',
  },
  {
    id: 'aicore',
    symbol: 'aicore',
    name: 'AICORE',
  },
  {
    id: 'aicrew',
    symbol: 'aicr',
    name: 'AICrew',
  },
  {
    id: 'aidi-finance-2',
    symbol: 'aidi',
    name: 'Aidi Finance',
  },
  {
    id: 'ai-dogemini',
    symbol: 'aidogemini',
    name: 'AI DogeMini',
  },
  {
    id: 'aiearn',
    symbol: 'aie',
    name: 'AIEarn',
  },
  {
    id: 'aienglish',
    symbol: 'aien',
    name: 'AIENGLISH',
  },
  {
    id: 'aifi-protocol',
    symbol: 'aifi',
    name: 'AiFi Protocol',
  },
  {
    id: 'ai-floki',
    symbol: 'aifloki',
    name: 'AI Floki',
  },
  {
    id: 'aigc-ordinals',
    symbol: 'aigc',
    name: 'AIGC (Ordinals)',
  },
  {
    id: 'a-i-genesis',
    symbol: 'aig',
    name: 'A.I Genesis',
  },
  {
    id: 'aigentx',
    symbol: 'aix',
    name: 'AIgentX',
  },
  {
    id: 'aihub',
    symbol: 'aih',
    name: 'AIHub',
  },
  {
    id: 'ailayer',
    symbol: 'aly',
    name: 'AiLayer',
  },
  {
    id: 'aimage',
    symbol: 'mage',
    name: 'AIMAGE',
  },
  {
    id: 'aimage-tools',
    symbol: 'aimage',
    name: 'AiMage Tools',
  },
  {
    id: 'aimalls',
    symbol: 'ait',
    name: 'AiMalls',
  },
  {
    id: 'aimbot',
    symbol: 'aimbot',
    name: 'AimBot',
  },
  {
    id: 'aimedis-new',
    symbol: 'aimx',
    name: 'Aimedis (NEW)',
  },
  {
    id: 'ai-meta-club',
    symbol: 'amc',
    name: 'AI Meta Club',
  },
  {
    id: 'ai-network',
    symbol: 'ain',
    name: 'AI Network',
  },
  {
    id: 'ains-domains',
    symbol: 'ains',
    name: 'Ains domains',
  },
  {
    id: 'ainu-token',
    symbol: 'ainu',
    name: 'Ainu',
  },
  {
    id: 'aion',
    symbol: 'aion',
    name: 'Aion',
  },
  {
    id: 'aion-2',
    symbol: 'aion',
    name: 'AION',
  },
  {
    id: 'aioz-network',
    symbol: 'aioz',
    name: 'AIOZ Network',
  },
  {
    id: 'aipad',
    symbol: 'aipad',
    name: 'AIPad',
  },
  {
    id: 'aipeople',
    symbol: 'aipeople',
    name: 'AIPeople',
  },
  {
    id: 'ai-pin',
    symbol: 'ai',
    name: 'AI PIN',
  },
  {
    id: 'ai-power-grid',
    symbol: 'aipg',
    name: 'AI Power Grid',
  },
  {
    id: 'aiptp',
    symbol: 'atmt',
    name: 'AiPTP',
  },
  {
    id: 'airbloc-protocol',
    symbol: 'abl',
    name: 'Airbloc',
  },
  {
    id: 'airbot',
    symbol: 'airbot',
    name: 'AirBot',
  },
  {
    id: 'airight',
    symbol: 'airi',
    name: 'aiRight',
  },
  {
    id: 'airnft-token',
    symbol: 'airt',
    name: 'AirNFT',
  },
  {
    id: 'airswap',
    symbol: 'ast',
    name: 'AirSwap',
  },
  {
    id: 'airtnt',
    symbol: 'airtnt',
    name: 'AirTnT',
  },
  {
    id: 'airtor-protocol',
    symbol: 'ator',
    name: 'AirTor Protocol',
  },
  {
    id: 'aishiba',
    symbol: 'shibai',
    name: 'AiShiba',
  },
  {
    id: 'aisignal',
    symbol: 'aisig',
    name: 'AISignal',
  },
  {
    id: 'aisociety',
    symbol: 'ais',
    name: 'AISociety',
  },
  {
    id: 'ai-supreme',
    symbol: 'aisp',
    name: 'AI Supreme',
  },
  {
    id: 'ai-surf',
    symbol: 'aisc',
    name: 'AI Surf',
  },
  {
    id: 'aiswap',
    symbol: 'aiswap',
    name: 'AISwap',
  },
  {
    id: 'aitom',
    symbol: 'aitom',
    name: 'AITom',
  },
  {
    id: 'ait-protocol',
    symbol: 'ait',
    name: 'AIT Protocol',
  },
  {
    id: 'ai-trader',
    symbol: 'ait',
    name: 'AI Trader',
  },
  {
    id: 'aitravis',
    symbol: 'tai',
    name: 'AITravis',
  },
  {
    id: 'aiwork',
    symbol: 'awo',
    name: 'AiWork',
  },
  {
    id: 'ai-x',
    symbol: 'x',
    name: 'AI-X',
  },
  {
    id: 'ajna-protocol',
    symbol: 'ajna',
    name: 'Ajna Protocol',
  },
  {
    id: 'ajuna-network',
    symbol: 'baju',
    name: 'Ajuna Network',
  },
  {
    id: 'akamaru',
    symbol: 'aku',
    name: 'Akamaru',
  },
  {
    id: 'akash-network',
    symbol: 'akt',
    name: 'Akash Network',
  },
  {
    id: 'akino-inu',
    symbol: 'aki',
    name: 'Akino INU',
  },
  {
    id: 'aki-protocol',
    symbol: 'aki',
    name: 'Aki Network',
  },
  {
    id: 'akita-inu',
    symbol: 'akita',
    name: 'Akita Inu',
  },
  {
    id: 'akita-inu-asa',
    symbol: 'akta',
    name: 'Akita Inu ASA',
  },
  {
    id: 'akitavax',
    symbol: 'akitax',
    name: 'Akitavax',
  },
  {
    id: 'akiverse-governance-token',
    symbol: 'akv',
    name: 'Akiverse Governance Token',
  },
  {
    id: 'akropolis',
    symbol: 'akro',
    name: 'Akropolis',
  },
  {
    id: 'akropolis-delphi',
    symbol: 'adel',
    name: 'Delphi',
  },
  {
    id: 'aktio',
    symbol: 'aktio',
    name: 'Aktio',
  },
  {
    id: 'aktionariat-alan-frei-company-tokenized-shares',
    symbol: 'afs',
    name: 'Aktionariat Alan Frei Company Tokenized Shares',
  },
  {
    id: 'aktionariat-art-leasing-and-invest-ag-tokenized-shares',
    symbol: 'arts',
    name: 'Aktionariat Art Leasing And Invest AG Tokenized Shares',
  },
  {
    id: 'aktionariat-axelra-early-stage-ag-tokenized-shares',
    symbol: 'axras',
    name: 'Aktionariat Axelra Early Stage AG Tokenized Shares',
  },
  {
    id: 'aktionariat-ayurveda-ag-tokenized-shares',
    symbol: 'veda',
    name: 'Aktionariat AyurVeda AG Tokenized Shares',
  },
  {
    id: 'aktionariat-bee-digital-growth-ag-tokenized-shares',
    symbol: 'bees',
    name: 'Aktionariat BEE Digital Growth AG Tokenized Shares',
  },
  {
    id: 'aktionariat-boss-info-ag-tokenized-shares',
    symbol: 'boss',
    name: 'Aktionariat Boss Info AG Tokenized Shares',
  },
  {
    id: 'aktionariat-carnault-ag-tokenized-shares',
    symbol: 'cas',
    name: 'Aktionariat Carnault AG Tokenized Shares',
  },
  {
    id: 'aktionariat-clever-forever-education-ag-tokenized-shares',
    symbol: 'cfes',
    name: 'Aktionariat Clever Forever Education AG Tokenized Shares',
  },
  {
    id: 'aktionariat-ddc-schweiz-ag-tokenized-shares',
    symbol: 'ddcs',
    name: 'Aktionariat DDC Schweiz AG Tokenized Shares',
  },
  {
    id: 'aktionariat-ehc-kloten-sport-ag-tokenized-shares',
    symbol: 'ehck',
    name: 'Aktionariat EHC Kloten Sport AG Tokenized Shares',
  },
  {
    id: 'aktionariat-fieldoo-ag-tokenized-shares',
    symbol: 'fdos',
    name: 'Aktionariat Fieldoo AG Tokenized Shares',
  },
  {
    id: 'aktionariat-finelli-studios-ag-tokenized-shares',
    symbol: 'fnls',
    name: 'Aktionariat Finelli Studios AG Tokenized Shares',
  },
  {
    id: 'aktionariat-green-consensus-ag-tokenized-shares',
    symbol: 'dgcs',
    name: 'Aktionariat Green Consensus AG Tokenized Shares',
  },
  {
    id: 'aktionariat-green-monkey-club-ag-tokenized-shares',
    symbol: 'gmcs',
    name: 'Aktionariat Green Monkey Club AG Tokenized Shares',
  },
  {
    id: 'aktionariat-outlawz-food-ag-tokenized-shares',
    symbol: 'vegs',
    name: 'Aktionariat Outlawz Food AG Tokenized Shares',
  },
  {
    id: 'aktionariat-parknsleep-ag-tokenized-shares',
    symbol: 'pns',
    name: 'Aktionariat Parknsleep AG Tokenized Shares',
  },
  {
    id: 'aktionariat-pension-dynamics-ag-tokenized-shares',
    symbol: 'pds',
    name: 'Aktionariat Pension Dynamics AG Tokenized Shares',
  },
  {
    id: 'aktionariat-realunit-schweiz-ag-tokenized-shares',
    symbol: 'realu',
    name: 'Aktionariat RealUnit Schweiz AG Tokenized Shares',
  },
  {
    id: 'aktionariat-servicehunter-ag-tokenized-shares',
    symbol: 'dqts',
    name: 'Aktionariat ServiceHunter AG Tokenized Shares',
  },
  {
    id: 'aktionariat-sia-swiss-influencer-award-ag-tokenized-shares',
    symbol: 'sias',
    name: 'Aktionariat SIA Swiss Influencer Award AG Tokenized Shares',
  },
  {
    id: 'aktionariat-sportsparadise-switzerland-ag-tokenized-shares',
    symbol: 'spos',
    name: 'Aktionariat Sportsparadise Switzerland AG Tokenized Shares',
  },
  {
    id: 'aktionariat-tbo-co-comon-accelerator-holding-ag-tokenized-shares',
    symbol: 'tbos',
    name: 'Aktionariat TBo c/o Comon Accelerator Holding AG Tokenized Shares',
  },
  {
    id: 'aktionariat-technologies-of-understanding-ag-tokenized-shares',
    symbol: 'vids',
    name: 'Aktionariat Technologies of Understanding AG Tokenized Shares',
  },
  {
    id: 'aktionariat-tv-plus-ag-tokenized-shares',
    symbol: 'tvpls',
    name: 'Aktionariat TV PLUS AG Tokenized Shares',
  },
  {
    id: 'aktionariat-vereign-ag-tokenized-shares',
    symbol: 'vrgns',
    name: 'Aktionariat Vereign AG Tokenized Shares',
  },
  {
    id: 'aladdin-dao',
    symbol: 'ald',
    name: 'Aladdin DAO',
  },
  {
    id: 'aladdin-sdcrv',
    symbol: 'asdcrv',
    name: 'Aladdin sdCRV',
  },
  {
    id: 'alan-musk',
    symbol: 'musk',
    name: 'Alan Musk',
  },
  {
    id: 'alanyaspor-fan-token',
    symbol: 'ala',
    name: 'Alanyaspor Fan Token',
  },
  {
    id: 'alaska-gold-rush',
    symbol: 'carat',
    name: 'Alaska Gold Rush',
  },
  {
    id: 'alaya',
    symbol: 'atp',
    name: 'Alaya',
  },
  {
    id: 'albedo',
    symbol: 'albedo',
    name: 'ALBEDO',
  },
  {
    id: 'alcatraz',
    symbol: 'alcz',
    name: 'Alcatraz',
  },
  {
    id: 'alchemist',
    symbol: 'mist',
    name: 'Alchemist',
  },
  {
    id: 'alchemix',
    symbol: 'alcx',
    name: 'Alchemix',
  },
  {
    id: 'alchemix-eth',
    symbol: 'aleth',
    name: 'Alchemix ETH',
  },
  {
    id: 'alchemix-usd',
    symbol: 'alusd',
    name: 'Alchemix USD',
  },
  {
    id: 'alchemy-pay',
    symbol: 'ach',
    name: 'Alchemy Pay',
  },
  {
    id: 'aldrin',
    symbol: 'rin',
    name: 'Aldrin',
  },
  {
    id: 'aleph',
    symbol: 'aleph',
    name: 'Aleph.im',
  },
  {
    id: 'aleph-im-wormhole',
    symbol: 'aleph',
    name: 'Aleph.im (Wormhole)',
  },
  {
    id: 'alephium',
    symbol: 'alph',
    name: 'Alephium',
  },
  {
    id: 'aleph-zero',
    symbol: 'azero',
    name: 'Aleph Zero',
  },
  {
    id: 'alethea-artificial-liquid-intelligence-token',
    symbol: 'ali',
    name: 'Artificial Liquid Intelligence',
  },
  {
    id: 'alex-b20',
    symbol: '$b20',
    name: 'ALEX $B20',
  },
  {
    id: 'alexgo',
    symbol: 'alex',
    name: 'ALEX Lab',
  },
  {
    id: 'alex-wrapped-usdt',
    symbol: 'susdt',
    name: 'Bridged Tether (Alex Bridge)',
  },
  {
    id: 'alfa-romeo-racing-orlen-fan-token',
    symbol: 'sauber',
    name: 'Alfa Romeo Racing ORLEN Fan Token',
  },
  {
    id: 'alfa-society',
    symbol: 'alfa',
    name: 'alfa.society',
  },
  {
    id: 'alfprotocol',
    symbol: 'alf',
    name: 'AlfProtocol',
  },
  {
    id: 'algebra',
    symbol: 'algb',
    name: 'Algebra',
  },
  {
    id: 'algo-casino-chips',
    symbol: 'chip',
    name: 'Algo-Casino Chips',
  },
  {
    id: 'algofund',
    symbol: 'algf',
    name: 'AlgoFund',
  },
  {
    id: 'algomint',
    symbol: 'gomint',
    name: 'Algomint',
  },
  {
    id: 'algorand',
    symbol: 'algo',
    name: 'Algorand',
  },
  {
    id: 'algory',
    symbol: 'alg',
    name: 'Algory',
  },
  {
    id: 'algostable',
    symbol: 'stbl',
    name: 'AlgoStable',
  },
  {
    id: 'algostake',
    symbol: 'stke',
    name: 'AlgoStake',
  },
  {
    id: 'alibabacoin',
    symbol: 'abbc',
    name: 'ABBC',
  },
  {
    id: 'alibaba-tokenized-stock-defichain',
    symbol: 'dbaba',
    name: 'Alibaba Tokenized Stock Defichain',
  },
  {
    id: 'alicenet',
    symbol: 'alca',
    name: 'AliceNet',
  },
  {
    id: 'alienb',
    symbol: 'alienb',
    name: 'AlienB',
  },
  {
    id: 'alienbase',
    symbol: 'alb',
    name: 'AlienBase',
  },
  {
    id: 'alien-chain',
    symbol: 'alien',
    name: 'Alien Chain',
  },
  {
    id: 'alien-chicken-farm',
    symbol: 'acf',
    name: 'Alien Chicken Farm',
  },
  {
    id: 'alienfi',
    symbol: 'alien',
    name: 'AlienFi',
  },
  {
    id: 'alienform',
    symbol: 'a4m',
    name: 'AlienForm',
  },
  {
    id: 'alien-milady-fumo',
    symbol: 'fumo',
    name: 'Alien Milady Fumo',
  },
  {
    id: 'alienswap',
    symbol: 'alien',
    name: 'AlienSwap',
  },
  {
    id: 'alien-worlds',
    symbol: 'tlm',
    name: 'Alien Worlds',
  },
  {
    id: 'alif-coin',
    symbol: 'alif',
    name: 'AliF Coin',
  },
  {
    id: 'alink-ai',
    symbol: 'alink',
    name: 'ALINK AI',
  },
  {
    id: 'alita',
    symbol: 'ali',
    name: 'Alita',
  },
  {
    id: 'alitaai',
    symbol: 'alita',
    name: 'AlitaAI',
  },
  {
    id: 'alitas',
    symbol: 'alt',
    name: 'Alitas',
  },
  {
    id: 'alium-finance',
    symbol: 'alm',
    name: 'Alium Finance',
  },
  {
    id: 'alkimi',
    symbol: '$ads',
    name: 'Alkimi',
  },
  {
    id: 'all-art',
    symbol: 'aart',
    name: 'ALL.ART',
  },
  {
    id: 'all-best-ico-satoshi',
    symbol: 'allbi',
    name: 'ALL BEST ICO',
  },
  {
    id: 'allbridge',
    symbol: 'abr',
    name: 'Allbridge',
  },
  {
    id: 'all-coins-yield-capital',
    symbol: 'acyc',
    name: 'All Coins Yield Capital',
  },
  {
    id: 'alldomains',
    symbol: 'all',
    name: 'AllDomains',
  },
  {
    id: 'allianceblock-nexera',
    symbol: 'nxra',
    name: 'AllianceBlock Nexera',
  },
  {
    id: 'alliance-fan-token',
    symbol: 'all',
    name: 'Alliance Fan Token',
  },
  {
    id: 'alliance-x-trading',
    symbol: 'axt',
    name: 'Alliance X Trading',
  },
  {
    id: 'all-in',
    symbol: 'allin',
    name: 'All In',
  },
  {
    id: 'all-in-coin',
    symbol: 'allin',
    name: 'All In Coin',
  },
  {
    id: 'all-in-gpt',
    symbol: 'aigpt',
    name: 'All In GPT',
  },
  {
    id: 'all-in-one-wallet',
    symbol: 'aio',
    name: 'All In One Wallet',
  },
  {
    id: 'all-in-pepe',
    symbol: 'pepea',
    name: 'ALL IN PEPE',
  },
  {
    id: 'allinxswap',
    symbol: 'x',
    name: 'AllInXSwap',
  },
  {
    id: 'allium-finance',
    symbol: 'alm',
    name: 'Allium Finance',
  },
  {
    id: 'allpaycoin',
    symbol: 'apcg',
    name: 'ALLPAYCOIN',
  },
  {
    id: 'allsafe',
    symbol: 'asafe',
    name: 'AllSafe',
  },
  {
    id: 'ally',
    symbol: 'aly',
    name: 'Ally',
  },
  {
    id: 'all-your-base',
    symbol: 'yobase',
    name: 'All Your Base',
  },
  {
    id: 'almira-wallet',
    symbol: 'almr',
    name: 'Almira Wallet',
  },
  {
    id: 'alongside-crypto-market-index',
    symbol: 'amkt',
    name: 'Alongside Crypto Market Index',
  },
  {
    id: 'alon-mars',
    symbol: 'alonmars',
    name: 'Alon Mars',
  },
  {
    id: 'alpaca',
    symbol: 'alpa',
    name: 'Alpaca City',
  },
  {
    id: 'alpaca-finance',
    symbol: 'alpaca',
    name: 'Alpaca Finance',
  },
  {
    id: 'alpha5',
    symbol: 'a5t',
    name: 'Alpha5',
  },
  {
    id: 'alphabet',
    symbol: 'alt',
    name: 'Alphabet',
  },
  {
    id: 'alpha-bot-calls',
    symbol: 'abc',
    name: 'Alpha Bot Calls',
  },
  {
    id: 'alphacoin',
    symbol: 'alpha',
    name: 'Alpha Coin',
  },
  {
    id: 'alpha-finance',
    symbol: 'alpha',
    name: 'Stella',
  },
  {
    id: 'alpha-gardeners',
    symbol: 'ag',
    name: 'Alpha Gardeners',
  },
  {
    id: 'alphai',
    symbol: 'αai',
    name: 'alphAI',
  },
  {
    id: 'alpha-quark-token',
    symbol: 'aqt',
    name: 'Alpha Quark',
  },
  {
    id: 'alpha-radar-bot',
    symbol: 'arbot',
    name: 'Alpha Radar Bot',
  },
  {
    id: 'alpharushai',
    symbol: 'rushai',
    name: 'AlphaRushAI',
  },
  {
    id: 'alphascan',
    symbol: 'ascn',
    name: 'AlphaScan',
  },
  {
    id: 'alpha-shards',
    symbol: 'alpha',
    name: 'Alpha Shards',
  },
  {
    id: 'alpha-shares-v2',
    symbol: '$alpha',
    name: 'Alpha Shares V2',
  },
  {
    id: 'alphr',
    symbol: 'alphr',
    name: 'Alphr',
  },
  {
    id: 'alpine',
    symbol: 'alp',
    name: 'Alpine',
  },
  {
    id: 'alpine-f1-team-fan-token',
    symbol: 'alpine',
    name: 'Alpine F1 Team Fan Token',
  },
  {
    id: 'altair',
    symbol: 'air',
    name: 'Altair',
  },
  {
    id: 'altava',
    symbol: 'tava',
    name: 'ALTAVA',
  },
  {
    id: 'altbase',
    symbol: 'altb',
    name: 'Altbase',
  },
  {
    id: 'alter',
    symbol: 'alter',
    name: 'Alter',
  },
  {
    id: 'altered-state-token',
    symbol: 'asto',
    name: 'Altered State Machine',
  },
  {
    id: 'alterna-network',
    symbol: 'altn',
    name: 'Alterna Network',
  },
  {
    id: 'alternity-cny',
    symbol: 'lcny',
    name: 'Alternity CNY',
  },
  {
    id: 'altfi',
    symbol: 'alt',
    name: 'AltFi',
  },
  {
    id: 'altfins',
    symbol: 'afins',
    name: 'altFINS',
  },
  {
    id: 'altitude',
    symbol: 'altd',
    name: 'Altitude',
  },
  {
    id: 'altlayer',
    symbol: 'alt',
    name: 'AltLayer',
  },
  {
    id: 'alt-markets',
    symbol: 'amx',
    name: 'Alt Markets',
  },
  {
    id: 'altura',
    symbol: 'alu',
    name: 'Altura',
  },
  {
    id: 'aluna',
    symbol: 'aln',
    name: 'Aluna',
  },
  {
    id: 'alva',
    symbol: 'aa',
    name: 'ALVA',
  },
  {
    id: 'alvey-chain',
    symbol: 'walv',
    name: 'Alvey Chain',
  },
  {
    id: 'amasa',
    symbol: 'amas',
    name: 'Amasa',
  },
  {
    id: 'amateras',
    symbol: 'amt',
    name: 'Amateras',
  },
  {
    id: 'amaterasufi-izanagi',
    symbol: 'iza',
    name: 'AmaterasuFi Izanagi',
  },
  {
    id: 'amaterasu-omikami',
    symbol: 'omikami',
    name: 'AMATERASU OMIKAMI',
  },
  {
    id: 'amaurot',
    symbol: 'ama',
    name: 'AMAUROT',
  },
  {
    id: 'amax-network',
    symbol: 'amax',
    name: 'AMAX Network',
  },
  {
    id: 'amazewallet',
    symbol: 'amt',
    name: 'AmazeToken',
  },
  {
    id: 'amazingteamdao',
    symbol: 'amazingteam',
    name: 'AmazingTeamDAO',
  },
  {
    id: 'amazon-tokenized-stock-defichain',
    symbol: 'damzn',
    name: 'Amazon Tokenized Stock Defichain',
  },
  {
    id: 'amazy',
    symbol: 'azy',
    name: 'Amazy',
  },
  {
    id: 'amber',
    symbol: 'amb',
    name: 'AirDAO',
  },
  {
    id: 'amber-phantom-butterfly',
    symbol: 'apb',
    name: 'Amber Phantom Butterfly',
  },
  {
    id: 'ambire-wallet',
    symbol: 'wallet',
    name: 'Ambire Wallet',
  },
  {
    id: 'ambra',
    symbol: 'ambr',
    name: 'Ambra',
  },
  {
    id: 'amepay',
    symbol: 'ame',
    name: 'AME Chain',
  },
  {
    id: 'american-shiba',
    symbol: 'ushiba',
    name: 'American Shiba',
  },
  {
    id: 'amino',
    symbol: '$amo',
    name: 'Amino',
  },
  {
    id: 'ammx',
    symbol: 'ammx',
    name: 'AMMX',
  },
  {
    id: 'ammyi-coin',
    symbol: 'ami',
    name: 'AMMYI Coin',
  },
  {
    id: 'amnis-aptos',
    symbol: 'amapt',
    name: 'Amnis Aptos',
  },
  {
    id: 'amnis-staked-aptos-coin',
    symbol: 'stapt',
    name: 'Amnis Staked Aptos Coin',
  },
  {
    id: 'amo',
    symbol: 'amo',
    name: 'AMO Coin',
  },
  {
    id: 'amond',
    symbol: 'amon',
    name: 'AmonD',
  },
  {
    id: 'amperechain',
    symbol: 'ampere',
    name: 'AmpereChain',
  },
  {
    id: 'ampleforth',
    symbol: 'ampl',
    name: 'Ampleforth',
  },
  {
    id: 'ampleforth-governance-token',
    symbol: 'forth',
    name: 'Ampleforth Governance',
  },
  {
    id: 'amplifi-dao',
    symbol: 'agg',
    name: 'AmpliFi DAO',
  },
  {
    id: 'amp-token',
    symbol: 'amp',
    name: 'Amp',
  },
  {
    id: 'amulet-protocol',
    symbol: 'amu',
    name: 'Amulet Protocol',
  },
  {
    id: 'amulet-staked-sol',
    symbol: 'amtsol',
    name: 'Amulet Staked SOL',
  },
  {
    id: 'anagata',
    symbol: 'aha',
    name: 'Anagata',
  },
  {
    id: 'analos',
    symbol: 'analos',
    name: 'analoS',
  },
  {
    id: 'analysoor',
    symbol: 'zero',
    name: 'Analysoor',
  },
  {
    id: 'anarchy',
    symbol: 'anarchy',
    name: 'Anarchy',
  },
  {
    id: 'anchored-coins-chf',
    symbol: 'achf',
    name: 'Anchored Coins ACHF',
  },
  {
    id: 'anchored-coins-eur',
    symbol: 'aeur',
    name: 'Anchored Coins AEUR',
  },
  {
    id: 'anchor-protocol',
    symbol: 'anc',
    name: 'Anchor Protocol',
  },
  {
    id: 'anchorswap',
    symbol: 'anchor',
    name: 'AnchorSwap',
  },
  {
    id: 'andrea-von-speed',
    symbol: 'vonspeed',
    name: 'Andrea Von Speed',
  },
  {
    id: 'andromeda-2',
    symbol: 'andr',
    name: 'Andromeda',
  },
  {
    id: 'andronodes',
    symbol: 'andro',
    name: 'AndroNodes',
  },
  {
    id: 'anduschain',
    symbol: 'deb',
    name: 'Anduschain',
  },
  {
    id: 'andy',
    symbol: 'andy',
    name: 'Andy',
  },
  {
    id: 'angle-protocol',
    symbol: 'angle',
    name: 'ANGLE',
  },
  {
    id: 'angle-staked-agusd',
    symbol: 'stusd',
    name: 'Angle Staked agUSD',
  },
  {
    id: 'angle-usd',
    symbol: 'agusd',
    name: 'Angle USD',
  },
  {
    id: 'angola',
    symbol: 'agla',
    name: 'Angola',
  },
  {
    id: 'angryb',
    symbol: 'anb',
    name: 'Angryb',
  },
  {
    id: 'angry-birds',
    symbol: 'brd',
    name: 'Angry Birds',
  },
  {
    id: 'angry-bulls-club',
    symbol: 'abc',
    name: 'Angry Bulls Club',
  },
  {
    id: 'angry-doge',
    symbol: 'anfd',
    name: 'Angry Doge',
  },
  {
    id: 'anima',
    symbol: 'anima',
    name: 'ANIMA',
  },
  {
    id: 'animal-army',
    symbol: 'animal',
    name: 'Animal army',
  },
  {
    id: 'animal-concerts-token',
    symbol: 'anml',
    name: 'Animal Concerts',
  },
  {
    id: 'animalfam',
    symbol: 'totofo',
    name: 'AnimalFam',
  },
  {
    id: 'animeswap',
    symbol: 'ani',
    name: 'AnimeSwap',
  },
  {
    id: 'anime-token',
    symbol: 'ani',
    name: 'Anime',
  },
  {
    id: 'anita-max-wynn',
    symbol: 'wynn',
    name: 'Anita Max Wynn',
  },
  {
    id: 'aniverse',
    symbol: 'anv',
    name: 'Aniverse',
  },
  {
    id: 'aniverse-metaverse',
    symbol: 'aniv',
    name: 'Aniverse Metaverse',
  },
  {
    id: 'ankaa-exchange',
    symbol: 'ankaa',
    name: 'Ankaa Exchange',
  },
  {
    id: 'ankaragucu-fan-token',
    symbol: 'anka',
    name: 'Ankaragücü Fan Token',
  },
  {
    id: 'ankr',
    symbol: 'ankr',
    name: 'Ankr Network',
  },
  {
    id: 'ankreth',
    symbol: 'ankreth',
    name: 'Ankr Staked ETH',
  },
  {
    id: 'ankr-reward-bearing-ftm',
    symbol: 'ankrftm',
    name: 'Ankr Staked FTM',
  },
  {
    id: 'ankr-reward-earning-matic',
    symbol: 'ankrmatic',
    name: 'Ankr Staked MATIC',
  },
  {
    id: 'ankr-staked-bnb',
    symbol: 'ankrbnb',
    name: 'Ankr Staked BNB',
  },
  {
    id: 'anomus-coin',
    symbol: 'anom',
    name: 'Anomus Coin',
  },
  {
    id: 'anon',
    symbol: 'anon',
    name: 'ANON',
  },
  {
    id: 'anonbot',
    symbol: 'anonbot',
    name: 'Anonbot',
  },
  {
    id: 'anon-inu',
    symbol: 'ainu',
    name: 'Anon Inu',
  },
  {
    id: 'anon-web3',
    symbol: 'aw3',
    name: 'Anon Web3',
  },
  {
    id: 'anonym-chain',
    symbol: 'anc',
    name: 'Anonym Chain',
  },
  {
    id: 'anonzk',
    symbol: 'azk',
    name: 'AnonZK',
  },
  {
    id: 'another-world',
    symbol: 'awm',
    name: 'Another World',
  },
  {
    id: 'anrkey-x',
    symbol: '$anrx',
    name: 'AnRKey X',
  },
  {
    id: 'answer-governance',
    symbol: 'agov',
    name: 'Answer Governance',
  },
  {
    id: 'ante-casino',
    symbol: 'chance',
    name: 'Ante Casino',
  },
  {
    id: 'antfarm-governance-token',
    symbol: 'agt',
    name: 'Antfarm Governance Token',
  },
  {
    id: 'antibot',
    symbol: 'atb',
    name: 'AntiBot',
  },
  {
    id: 'anti-global-warming-token',
    symbol: '$agw',
    name: 'ANTI GLOBAL WARMING TOKEN',
  },
  {
    id: 'antimatter',
    symbol: 'tune',
    name: 'Bitune',
  },
  {
    id: 'antis-inu',
    symbol: 'antis',
    name: 'Antis Inu',
  },
  {
    id: 'antmons',
    symbol: 'ams',
    name: 'Antmons',
  },
  {
    id: 'antnetworx',
    symbol: 'antx',
    name: 'AntNetworX (OLD)',
  },
  {
    id: 'antnetworx-2',
    symbol: 'antx',
    name: 'AntNetworX',
  },
  {
    id: 'antofy',
    symbol: 'abn',
    name: 'Antofy',
  },
  {
    id: 'antspace',
    symbol: 'ant',
    name: 'Antspace',
  },
  {
    id: 'anubit',
    symbol: 'anb',
    name: 'Anubit',
  },
  {
    id: 'any-inu',
    symbol: 'ai',
    name: 'Any Inu',
  },
  {
    id: 'anypad',
    symbol: 'apad',
    name: 'Anypad',
  },
  {
    id: 'anyswap',
    symbol: 'any',
    name: 'Anyswap',
  },
  {
    id: 'aok',
    symbol: 'aok',
    name: 'AOK',
  },
  {
    id: 'apass-coin',
    symbol: 'apc',
    name: 'APass Coin',
  },
  {
    id: 'apch',
    symbol: 'apch',
    name: 'APCH',
  },
  {
    id: 'ape-2',
    symbol: 'ape',
    name: 'APE',
  },
  {
    id: 'apecoin',
    symbol: 'ape',
    name: 'ApeCoin',
  },
  {
    id: 'aped',
    symbol: 'aped',
    name: 'Aped',
  },
  {
    id: 'apedao',
    symbol: 'apein',
    name: 'ApeDAO',
  },
  {
    id: 'apedoge',
    symbol: 'aped',
    name: 'Apedoge',
  },
  {
    id: 'ape-finance',
    symbol: 'apefi',
    name: 'Ape Finance',
  },
  {
    id: 'apegpt',
    symbol: 'apegpt',
    name: 'ApeGPT',
  },
  {
    id: 'ape-in',
    symbol: 'apein',
    name: 'Ape In',
  },
  {
    id: 'ape_in_records',
    symbol: 'air',
    name: 'Ape In Records',
  },
  {
    id: 'apenft',
    symbol: 'nft',
    name: 'APENFT',
  },
  {
    id: 'apepudgyclonexazukimilady',
    symbol: 'nft',
    name: 'ApePudgyCloneXAzukiMilady',
  },
  {
    id: 'apes-go-bananas',
    symbol: 'agb',
    name: 'Apes Go Bananas',
  },
  {
    id: 'apeswap-finance',
    symbol: 'banana',
    name: 'ApeSwap',
  },
  {
    id: 'apexcoin-global',
    symbol: 'acx',
    name: 'Apex Coin',
  },
  {
    id: 'apexit-finance',
    symbol: 'apex',
    name: 'ApeXit Finance',
  },
  {
    id: 'apex-token-2',
    symbol: 'apex',
    name: 'ApeX',
  },
  {
    id: 'apf-coin',
    symbol: 'apfc',
    name: 'APF coin',
  },
  {
    id: 'api3',
    symbol: 'api3',
    name: 'API3',
  },
  {
    id: 'apidae',
    symbol: 'apt',
    name: 'Apidae',
  },
  {
    id: 'aping',
    symbol: 'aping',
    name: 'aping',
  },
  {
    id: 'apin-pulse',
    symbol: 'apc',
    name: 'Apin Pulse',
  },
  {
    id: 'apis-finance',
    symbol: 'bep-20',
    name: 'Apis finance',
  },
  {
    id: 'apm-coin',
    symbol: 'apm',
    name: 'apM Coin',
  },
  {
    id: 'apollo',
    symbol: 'apl',
    name: 'Apollo',
  },
  {
    id: 'apollo-2',
    symbol: 'apollo',
    name: 'Apollo',
  },
  {
    id: 'apollo-crypto',
    symbol: 'apollo',
    name: 'Apollo Crypto',
  },
  {
    id: 'apollon-limassol',
    symbol: 'apl',
    name: 'Apollon Limassol Fan Token',
  },
  {
    id: 'apollo-token',
    symbol: 'apollo',
    name: 'Apollo Token',
  },
  {
    id: 'apollox-2',
    symbol: 'apx',
    name: 'APX',
  },
  {
    id: 'appcoins',
    symbol: 'appc',
    name: 'AppCoins',
  },
  {
    id: 'appics',
    symbol: 'apx',
    name: 'Appics',
  },
  {
    id: 'appifysite',
    symbol: 'app',
    name: 'AppifySite',
  },
  {
    id: 'apple-pie',
    symbol: 'pie',
    name: 'Apple Pie',
  },
  {
    id: 'apple-tokenized-stock-defichain',
    symbol: 'daapl',
    name: 'Apple Tokenized Stock Defichain',
  },
  {
    id: 'apricot',
    symbol: 'aprt',
    name: 'Apricot',
  },
  {
    id: 'april',
    symbol: 'april',
    name: 'April',
  },
  {
    id: 'apron',
    symbol: 'apn',
    name: 'Apron',
  },
  {
    id: 'apsis',
    symbol: 'aps',
    name: 'Apsis',
  },
  {
    id: 'aptopad',
    symbol: 'apd',
    name: 'Aptopad',
  },
  {
    id: 'aptos',
    symbol: 'apt',
    name: 'Aptos',
  },
  {
    id: 'aptos-launch-token',
    symbol: 'alt',
    name: 'AptosLaunch Token',
  },
  {
    id: 'apwine',
    symbol: 'apw',
    name: 'Spectra',
  },
  {
    id: 'apy-finance',
    symbol: 'apy',
    name: 'APY.Finance',
  },
  {
    id: 'apyswap',
    symbol: 'apys',
    name: 'APYSwap',
  },
  {
    id: 'apy-vision',
    symbol: 'vision',
    name: 'APY.vision',
  },
  {
    id: 'aqtis',
    symbol: 'aqtis',
    name: 'AQTIS',
  },
  {
    id: 'aquadao',
    symbol: '$aqua',
    name: 'AquaDAO',
  },
  {
    id: 'aqua-goat',
    symbol: 'aquagoat',
    name: 'Aqua Goat',
  },
  {
    id: 'aquanee',
    symbol: 'aqdc',
    name: 'Aquanee',
  },
  {
    id: 'aquarius',
    symbol: 'aqua',
    name: 'Aquarius',
  },
  {
    id: 'aquariuscoin',
    symbol: 'arco',
    name: 'AquariusCoin',
  },
  {
    id: 'aquarius-loan',
    symbol: 'ars',
    name: 'Aquarius Loan',
  },
  {
    id: 'arab-cat',
    symbol: 'arab',
    name: 'Arab cat',
  },
  {
    id: 'arabic',
    symbol: 'abic',
    name: 'Arabic',
  },
  {
    id: 'arable-protocol',
    symbol: 'acre',
    name: 'Arable Protocol',
  },
  {
    id: 'aradenean-gold',
    symbol: 'ag',
    name: 'Aradenean Gold',
  },
  {
    id: 'aragon',
    symbol: 'ant',
    name: 'Aragon',
  },
  {
    id: 'ara-token',
    symbol: 'ara',
    name: 'Ara',
  },
  {
    id: 'arbdoge-ai',
    symbol: 'aidoge',
    name: 'ArbDoge AI',
  },
  {
    id: 'arbidoge',
    symbol: 'adoge',
    name: 'ArbiDoge',
  },
  {
    id: 'arbinu',
    symbol: 'arbinu',
    name: 'Arbinu',
  },
  {
    id: 'arbinyan',
    symbol: 'nyan',
    name: 'ArbiNYAN',
  },
  {
    id: 'arbipad',
    symbol: 'arbi',
    name: 'ArbiPad',
  },
  {
    id: 'arbismart-token',
    symbol: 'rbis',
    name: 'ArbiSmart',
  },
  {
    id: 'arbiswap-42983059-37e1-4a8f-b46e-0d908c0d4cc0',
    symbol: 'arbi',
    name: 'ArbiSwap',
  },
  {
    id: 'arbiten',
    symbol: 'arbiten',
    name: 'ArbiTen',
  },
  {
    id: 'arbiten-10share',
    symbol: '10share',
    name: 'ArbiTen 10SHARE',
  },
  {
    id: 'arbitrove-alp',
    symbol: 'alp',
    name: 'Arbitrove ALP',
  },
  {
    id: 'arbitrum',
    symbol: 'arb',
    name: 'Arbitrum',
  },
  {
    id: 'arbitrum-charts',
    symbol: 'arcs',
    name: 'Arbitrum Charts',
  },
  {
    id: 'arbitrum-ecosystem-index',
    symbol: 'arbi',
    name: 'Arbitrum Ecosystem Index',
  },
  {
    id: 'arbitrum-exchange',
    symbol: 'arx',
    name: 'Arbidex',
  },
  {
    id: 'arbitrumpad',
    symbol: 'arbpad',
    name: 'ArbitrumPad',
  },
  {
    id: 'arb-protocol',
    symbol: 'arb',
    name: 'ARB Protocol',
  },
  {
    id: 'arbshib',
    symbol: 'aishib',
    name: 'ArbShib',
  },
  {
    id: 'arbswap',
    symbol: 'arbs',
    name: 'Arbswap',
  },
  {
    id: 'arc',
    symbol: 'arc',
    name: 'Arc',
  },
  {
    id: 'arcade-arcoin',
    symbol: 'arcn',
    name: 'Arcade Arcoin',
  },
  {
    id: 'arcade-protocol',
    symbol: 'arcd',
    name: 'Arcade DAO',
  },
  {
    id: 'arcadeum',
    symbol: 'arc',
    name: 'Arcadeum',
  },
  {
    id: 'arcadium',
    symbol: 'arcadium',
    name: 'Arcadium',
  },
  {
    id: 'arcana-token',
    symbol: 'xar',
    name: 'Arcana Network',
  },
  {
    id: 'arcanedex',
    symbol: 'arc',
    name: 'ArcaneDEX',
  },
  {
    id: 'arcblock',
    symbol: 'abt',
    name: 'Arcblock',
  },
  {
    id: 'arcc',
    symbol: 'arcc',
    name: 'ARCC',
  },
  {
    id: 'arch-aggressive-portfolio',
    symbol: 'aagg',
    name: 'Arch Aggressive Portfolio',
  },
  {
    id: 'archangel-token',
    symbol: 'archa',
    name: 'ArchAngel',
  },
  {
    id: 'arch-balanced-portfolio',
    symbol: 'abal',
    name: 'Arch Balanced Portfolio',
  },
  {
    id: 'arch-blockchains',
    symbol: 'chain',
    name: 'Arch Blockchains',
  },
  {
    id: 'archerswap-bow',
    symbol: 'bow',
    name: 'Archerswap BOW',
  },
  {
    id: 'archerswap-hunter',
    symbol: 'hunt',
    name: 'ArcherSwap Hunter',
  },
  {
    id: 'arch-ethereum-div-yield',
    symbol: 'aedy',
    name: 'Arch Ethereum Div. Yield',
  },
  {
    id: 'arch-ethereum-web3',
    symbol: 'web3',
    name: 'Arch Ethereum Web3',
  },
  {
    id: 'archethic',
    symbol: 'uco',
    name: 'Archethic',
  },
  {
    id: 'archimedes',
    symbol: 'arch',
    name: 'Archimedes Finance',
  },
  {
    id: 'archi-token',
    symbol: 'archi',
    name: 'Archi Token',
  },
  {
    id: 'archive-ai',
    symbol: 'arcai',
    name: 'Archive AI',
  },
  {
    id: 'archloot',
    symbol: 'alt',
    name: 'ArchLoot',
  },
  {
    id: 'arch-usd-div-yield',
    symbol: 'addy',
    name: 'Arch USD Div. Yield',
  },
  {
    id: 'archway',
    symbol: 'arch',
    name: 'Archway',
  },
  {
    id: 'arcona',
    symbol: 'arcona',
    name: 'Arcona',
  },
  {
    id: 'arcs',
    symbol: 'arx',
    name: 'ARCS',
  },
  {
    id: 'ardana',
    symbol: 'dana',
    name: 'Ardana',
  },
  {
    id: 'ardor',
    symbol: 'ardr',
    name: 'Ardor',
  },
  {
    id: 'aree-shards',
    symbol: 'aes',
    name: 'Aree Shards',
  },
  {
    id: 'arena-deathmatch',
    symbol: 'arena',
    name: 'Arena Deathmatch',
  },
  {
    id: 'arena-gg',
    symbol: 'arena',
    name: 'Arena.GG',
  },
  {
    id: 'arena-token',
    symbol: 'arena',
    name: 'ArenaSwap',
  },
  {
    id: 'areon-network',
    symbol: 'area',
    name: 'Areon Network',
  },
  {
    id: 'ares3-network',
    symbol: 'ares',
    name: 'Ares3 Network',
  },
  {
    id: 'ares-protocol',
    symbol: 'ares',
    name: 'Ares Protocol',
  },
  {
    id: 'argentine-football-association-fan-token',
    symbol: 'arg',
    name: 'Argentine Football Association Fan Token',
  },
  {
    id: 'argo',
    symbol: 'argo',
    name: 'ArGoApp',
  },
  {
    id: 'argocoin',
    symbol: 'agc',
    name: 'Argocoin',
  },
  {
    id: 'argo-finance',
    symbol: 'argo',
    name: 'Argo Finance',
  },
  {
    id: 'argon',
    symbol: 'argon',
    name: 'Argon',
  },
  {
    id: 'argonon-helium',
    symbol: 'arg',
    name: 'Argonon Helium',
  },
  {
    id: 'ari10',
    symbol: 'ari10',
    name: 'Ari10',
  },
  {
    id: 'aria-currency',
    symbol: 'ria',
    name: 'aRIA Currency',
  },
  {
    id: 'arianee',
    symbol: 'aria20',
    name: 'Arianee',
  },
  {
    id: 'arion',
    symbol: 'arion',
    name: 'Arion',
  },
  {
    id: 'arise-chikun',
    symbol: 'chikun',
    name: 'Arise Chikun',
  },
  {
    id: 'ari-swap',
    symbol: 'ari',
    name: 'Ari Swap',
  },
  {
    id: 'arithfi',
    symbol: 'atf',
    name: 'ArithFi',
  },
  {
    id: 'ariva',
    symbol: 'arv',
    name: 'Ariva',
  },
  {
    id: 'arix',
    symbol: 'arix',
    name: 'Arix',
  },
  {
    id: 'ark',
    symbol: 'ark',
    name: 'ARK',
  },
  {
    id: 'arkadiko-protocol',
    symbol: 'diko',
    name: 'Arkadiko',
  },
  {
    id: 'arkadiko-usda',
    symbol: 'usda',
    name: 'Arkadiko USDA',
  },
  {
    id: 'arken-finance',
    symbol: '$arken',
    name: 'Arken Finance',
  },
  {
    id: 'arker-2',
    symbol: 'arker',
    name: 'Arker',
  },
  {
    id: 'arkham',
    symbol: 'arkm',
    name: 'Arkham',
  },
  {
    id: 'ark-innovation-etf-defichain',
    symbol: 'darkk',
    name: 'ARK Innovation ETF Defichain',
  },
  {
    id: 'arkitech',
    symbol: 'arki',
    name: 'ArkiTech',
  },
  {
    id: 'ark-rivals',
    symbol: 'arkn',
    name: 'Ark Rivals',
  },
  {
    id: 'arkstart',
    symbol: 'arks',
    name: 'ArkStart',
  },
  {
    id: 'armor',
    symbol: 'armor',
    name: 'ARMOR',
  },
  {
    id: 'armour-wallet',
    symbol: 'armour',
    name: 'Armour Wallet',
  },
  {
    id: 'army-of-fortune-metaverse',
    symbol: 'afc',
    name: 'Army of Fortune Metaverse',
  },
  {
    id: 'arowana-token',
    symbol: 'arw',
    name: 'Arowana',
  },
  {
    id: 'arpa',
    symbol: 'arpa',
    name: 'ARPA',
  },
  {
    id: 'arqma',
    symbol: 'arq',
    name: 'ArQmA',
  },
  {
    id: 'arrland-arrc',
    symbol: 'arrc',
    name: 'Arrland ARRC',
  },
  {
    id: 'arrland-rum',
    symbol: 'rum',
    name: 'Arrland RUM',
  },
  {
    id: 'arsenal-fan-token',
    symbol: 'afc',
    name: 'Arsenal Fan Token',
  },
  {
    id: 'artbyte',
    symbol: 'aby',
    name: 'ArtByte',
  },
  {
    id: 'art-can-die',
    symbol: 'die',
    name: 'Aera',
  },
  {
    id: 'artcoin',
    symbol: 'ac',
    name: 'ArtCoin',
  },
  {
    id: 'artcpaclub',
    symbol: 'cpa-97530',
    name: 'ArtCPAclub',
  },
  {
    id: 'art-de-finance',
    symbol: 'adf',
    name: 'Art de Finance',
  },
  {
    id: 'artem',
    symbol: 'artem',
    name: 'Artem',
  },
  {
    id: 'artemis',
    symbol: 'mis',
    name: 'Artemis',
  },
  {
    id: 'artemisai',
    symbol: 'atai',
    name: 'ArtemisAI',
  },
  {
    id: 'artemis-vision',
    symbol: 'arv',
    name: 'Artemis Vision',
  },
  {
    id: 'artery',
    symbol: 'artr',
    name: 'Artery',
  },
  {
    id: 'artgpt',
    symbol: 'agpt',
    name: 'artGPT',
  },
  {
    id: 'arth',
    symbol: 'arth',
    name: 'ARTH',
  },
  {
    id: 'arthswap',
    symbol: 'arsw',
    name: 'ArthSwap',
  },
  {
    id: 'artichoke',
    symbol: 'choke',
    name: 'Artichoke',
  },
  {
    id: 'artificial-intelligence',
    symbol: 'ai',
    name: 'Artificial Intelligence',
  },
  {
    id: 'artificial-intelligence-2',
    symbol: 'aid',
    name: 'Artificial Intelligence',
  },
  {
    id: 'artificial-neural-network-ordinals',
    symbol: 'ainn',
    name: 'Artificial Neural Network (Ordinals)',
  },
  {
    id: 'arti-project',
    symbol: 'arti',
    name: 'Arti Project',
  },
  {
    id: 'artizen',
    symbol: 'atnt',
    name: 'Artizen',
  },
  {
    id: 'artl',
    symbol: 'artl',
    name: 'ARTL',
  },
  {
    id: 'artmeta',
    symbol: '$mart',
    name: 'ArtMeta',
  },
  {
    id: 'artrade',
    symbol: 'atr',
    name: 'Artrade',
  },
  {
    id: 'artube',
    symbol: 'att',
    name: 'Artube',
  },
  {
    id: 'artx',
    symbol: 'artx',
    name: 'ARTX',
  },
  {
    id: 'artyfact',
    symbol: 'arty',
    name: 'Artyfact',
  },
  {
    id: 'arweave',
    symbol: 'ar',
    name: 'Arweave',
  },
  {
    id: 'aryacoin',
    symbol: 'aya',
    name: 'Aryacoin',
  },
  {
    id: 'aryze-eeur',
    symbol: 'eeur',
    name: 'ARYZE eEUR',
  },
  {
    id: 'aryze-egbp',
    symbol: 'egbp',
    name: 'ARYZE eGBP',
  },
  {
    id: 'aryze-eusd',
    symbol: 'eusd',
    name: 'ARYZE eUSD',
  },
  {
    id: 'asan-verse',
    symbol: 'asan',
    name: 'ASAN VERSE',
  },
  {
    id: 'asap-sniper-bot',
    symbol: 'asap',
    name: 'Asap Sniper Bot',
  },
  {
    id: 'ascend-2',
    symbol: 'asc',
    name: 'Ascend',
  },
  {
    id: 'ascension-protocol',
    symbol: 'ascend',
    name: 'Ascension Protocol',
  },
  {
    id: 'asct-avax-inscription',
    symbol: 'asct',
    name: 'ASCT AVAX INSCRIPTION',
  },
  {
    id: 'asd',
    symbol: 'asd',
    name: 'AscendEx',
  },
  {
    id: 'asdi',
    symbol: 'asdi',
    name: 'ASDI',
  },
  {
    id: 'asdi-reward',
    symbol: 'asdir',
    name: 'ASDI Reward',
  },
  {
    id: 'asenix',
    symbol: 'enix',
    name: 'ASENIX',
  },
  {
    id: 'asgardx',
    symbol: 'odin',
    name: 'AsgardX',
  },
  {
    id: 'ash',
    symbol: 'ash',
    name: 'ASH',
  },
  {
    id: 'ashswap',
    symbol: 'ash',
    name: 'AshSwap',
  },
  {
    id: 'ash-token',
    symbol: 'ash',
    name: 'Ash Token',
  },
  {
    id: 'asia-coin',
    symbol: 'asia',
    name: 'Asia Coin',
  },
  {
    id: 'asic-token',
    symbol: 'asic',
    name: 'ASIC Token',
  },
  {
    id: 'asic-token-pulsechain',
    symbol: 'asic',
    name: 'ASIC Token (Pulsechain)',
  },
  {
    id: 'asix',
    symbol: 'asix',
    name: 'ASIX',
  },
  {
    id: 'askobar-network',
    symbol: 'asko',
    name: 'Asko',
  },
  {
    id: 'asmatch',
    symbol: 'asm',
    name: 'AsMatch',
  },
  {
    id: 'as-monaco-fan-token',
    symbol: 'asm',
    name: 'AS Monaco Fan Token',
  },
  {
    id: 'aspo-world',
    symbol: 'aspo',
    name: 'ASPO World',
  },
  {
    id: 'asr-coin',
    symbol: 'asr',
    name: 'ASR Coin',
  },
  {
    id: 'as-roma-fan-token',
    symbol: 'asr',
    name: 'AS Roma Fan Token',
  },
  {
    id: 'assangedao',
    symbol: 'justice',
    name: 'AssangeDAO',
  },
  {
    id: 'assaplay',
    symbol: 'assa',
    name: 'AssaPlay',
  },
  {
    id: 'assemble-protocol',
    symbol: 'asm',
    name: 'Assemble Protocol',
  },
  {
    id: 'assent-protocol',
    symbol: 'asnt',
    name: 'Assent Protocol',
  },
  {
    id: 'assetlink',
    symbol: 'aset',
    name: 'AssetLink',
  },
  {
    id: 'assetmantle',
    symbol: 'mntl',
    name: 'AssetMantle',
  },
  {
    id: 'asta',
    symbol: 'asta',
    name: 'ASTA',
  },
  {
    id: 'astar',
    symbol: 'astr',
    name: 'Astar',
  },
  {
    id: 'astar-moonbeam',
    symbol: '$xcastr',
    name: 'Astar (Moonbeam)',
  },
  {
    id: 'astarter',
    symbol: 'aa',
    name: 'Astarter',
  },
  {
    id: 'aster',
    symbol: 'atc',
    name: 'Aster',
  },
  {
    id: 'aston-martin-cognizant-fan-token',
    symbol: 'am',
    name: 'Aston Martin Cognizant Fan Token',
  },
  {
    id: 'aston-villa-fan-token',
    symbol: 'avl',
    name: 'Aston Villa Fan Token',
  },
  {
    id: 'astraai',
    symbol: 'astra',
    name: 'AstraAI',
  },
  {
    id: 'astra-dao-2',
    symbol: 'astradao',
    name: 'Astra DAO',
  },
  {
    id: 'astrafer',
    symbol: 'astrafer',
    name: 'Astrafer',
  },
  {
    id: 'astra-guild-ventures',
    symbol: 'agv',
    name: 'Astra Guild Ventures',
  },
  {
    id: 'astral-credits',
    symbol: 'xac',
    name: 'Astral Credits',
  },
  {
    id: 'astrals-glxy',
    symbol: 'glxy',
    name: 'Astrals GLXY',
  },
  {
    id: 'astra-nova',
    symbol: '$rvv',
    name: 'Astra Nova',
  },
  {
    id: 'astra-protocol-2',
    symbol: 'astra',
    name: 'Astra Protocol',
  },
  {
    id: 'astrava',
    symbol: 'ast',
    name: 'Astrava',
  },
  {
    id: 'astrazion',
    symbol: 'aznt',
    name: 'AstraZion',
  },
  {
    id: 'astriddao-token',
    symbol: 'atid',
    name: 'AstridDAO',
  },
  {
    id: 'astrid-restaked-cbeth',
    symbol: 'rcbeth',
    name: 'Astrid Restaked cbETH',
  },
  {
    id: 'astrid-restaked-reth',
    symbol: 'rreth',
    name: 'Astrid Restaked rETH',
  },
  {
    id: 'astrid-restaked-steth',
    symbol: 'rsteth',
    name: 'Astrid Restaked stETH',
  },
  {
    id: 'astro-2',
    symbol: 'astro',
    name: 'Astro',
  },
  {
    id: 'astroai',
    symbol: 'astroai',
    name: 'AstroAI',
  },
  {
    id: 'astro-babies',
    symbol: 'abb',
    name: 'Astro Babies',
  },
  {
    id: 'astrobits',
    symbol: 'astrb',
    name: 'ASTROBITS',
  },
  {
    id: 'astro-cash',
    symbol: 'astro',
    name: 'Astro Cash',
  },
  {
    id: 'astroelon',
    symbol: 'elonone',
    name: 'AstroElon',
  },
  {
    id: 'astro-pepe',
    symbol: 'astropepe',
    name: 'Astro Pepe',
  },
  {
    id: 'astropepex',
    symbol: 'apx',
    name: 'AstroPepeX',
  },
  {
    id: 'astroport',
    symbol: 'astroc',
    name: 'Astroport Classic',
  },
  {
    id: 'astroport-fi',
    symbol: 'astro',
    name: 'Astroport',
  },
  {
    id: 'astropup-coin',
    symbol: 'aspc',
    name: 'Astropup Coin',
  },
  {
    id: 'astrospaces-io',
    symbol: 'spaces',
    name: 'AstroSpaces.io',
  },
  {
    id: 'astroswap',
    symbol: 'astro',
    name: 'AstroSwap',
  },
  {
    id: 'astrotools',
    symbol: 'astro',
    name: 'AstroTools',
  },
  {
    id: 'astrovault-xarch',
    symbol: 'xarch',
    name: 'xARCH_Astrovault',
  },
  {
    id: 'astrovault-xatom',
    symbol: 'xatom',
    name: 'xATOM_Astrovault',
  },
  {
    id: 'astrovault-xjkl',
    symbol: 'xjkl',
    name: 'xJKL_Astrovault',
  },
  {
    id: 'astro-x',
    symbol: 'astrox',
    name: 'Astro-X',
  },
  {
    id: 'asva',
    symbol: 'asva',
    name: 'Asva Labs',
  },
  {
    id: 'asyagro',
    symbol: 'asy',
    name: 'ASYAGRO',
  },
  {
    id: 'asymetrix',
    symbol: 'asx',
    name: 'Asymetrix',
  },
  {
    id: 'atalexv2',
    symbol: 'atalexv2',
    name: 'atALEXv2',
  },
  {
    id: 'atari',
    symbol: 'atri',
    name: 'Atari',
  },
  {
    id: 'atem-network',
    symbol: 'atem',
    name: 'Atem Network',
  },
  {
    id: 'aternos-chain',
    symbol: 'atr',
    name: 'Aternos Chain',
  },
  {
    id: 'athenadao-token',
    symbol: 'ath',
    name: 'AthenaDAO',
  },
  {
    id: 'athena-finance',
    symbol: 'ath',
    name: 'Athena Finance',
  },
  {
    id: 'athena-returns-olea',
    symbol: 'olea',
    name: 'Olea Token',
  },
  {
    id: 'athenas',
    symbol: 'athenasv2',
    name: 'Athenas',
  },
  {
    id: 'atheneum',
    symbol: 'aem',
    name: 'Atheneum',
  },
  {
    id: 'athens',
    symbol: 'ath',
    name: 'Athens',
  },
  {
    id: 'athos-finance',
    symbol: 'ath',
    name: 'Athos Finance',
  },
  {
    id: 'athos-finance-usd',
    symbol: 'athusd',
    name: 'Athos Finance USD',
  },
  {
    id: 'atlantis-loans',
    symbol: 'atl',
    name: 'Atlantis Loans',
  },
  {
    id: 'atlas-aggregator',
    symbol: 'ata',
    name: 'Atlas Aggregator',
  },
  {
    id: 'atlas-dex',
    symbol: 'ats',
    name: 'Atlas DEX',
  },
  {
    id: 'atlas-fc-fan-token',
    symbol: 'atlas',
    name: 'Atlas FC Fan Token',
  },
  {
    id: 'atlas-navi',
    symbol: 'navi',
    name: 'Atlas Navi',
  },
  {
    id: 'atlas-protocol',
    symbol: 'atp',
    name: 'Atlas Protocol',
  },
  {
    id: 'atlas-usv',
    symbol: 'usv',
    name: 'Atlas USV',
  },
  {
    id: 'atletico-madrid',
    symbol: 'atm',
    name: 'Atletico Madrid Fan Token',
  },
  {
    id: 'atomicals',
    symbol: 'atomarc20',
    name: 'Atomicals',
  },
  {
    id: 'atomic-wallet-coin',
    symbol: 'awc',
    name: 'Atomic Wallet Coin',
  },
  {
    id: 'atomone',
    symbol: 'atom1',
    name: 'AtomOne',
  },
  {
    id: 'atpay',
    symbol: 'atpay',
    name: 'AtPay',
  },
  {
    id: 'atrno',
    symbol: 'atrno',
    name: 'ATRNO',
  },
  {
    id: 'atrofarm',
    symbol: 'atrofa',
    name: 'Atrofarm',
  },
  {
    id: 'atromg8',
    symbol: 'ag8',
    name: 'ATROMG8',
  },
  {
    id: 'atropine',
    symbol: 'pine',
    name: 'Atropine',
  },
  {
    id: 'attack-wagon',
    symbol: 'atk',
    name: 'Attack Wagon',
  },
  {
    id: 'attila',
    symbol: 'att',
    name: 'Attila',
  },
  {
    id: 'auction',
    symbol: 'auction',
    name: 'Bounce',
  },
  {
    id: 'auctus',
    symbol: 'auc',
    name: 'Auctus',
  },
  {
    id: 'audify',
    symbol: 'audi',
    name: 'Audify',
  },
  {
    id: 'audius',
    symbol: 'audio',
    name: 'Audius',
  },
  {
    id: 'audius-wormhole',
    symbol: 'audio',
    name: 'Audius (Wormhole)',
  },
  {
    id: 'augur',
    symbol: 'rep',
    name: 'Augur',
  },
  {
    id: 'augury-finance',
    symbol: 'omen',
    name: 'Augury Finance',
  },
  {
    id: 'aura',
    symbol: '$aura',
    name: '$AURA',
  },
  {
    id: 'aura-bal',
    symbol: 'aurabal',
    name: 'Aura BAL',
  },
  {
    id: 'auradx',
    symbol: 'dalle2',
    name: 'AuradX',
  },
  {
    id: 'aura-finance',
    symbol: 'aura',
    name: 'Aura Finance',
  },
  {
    id: 'aura-network',
    symbol: 'aura',
    name: 'Aura Network',
  },
  {
    id: 'aura-network-old',
    symbol: 'aura',
    name: 'Aura Network [OLD]',
  },
  {
    id: 'aureo',
    symbol: 'aur',
    name: 'AUREO',
  },
  {
    id: 'aureus',
    symbol: 'aur',
    name: 'Aureus',
  },
  {
    id: 'aureus-nummus-gold',
    symbol: 'ang',
    name: 'Aureus Nummus Gold',
  },
  {
    id: 'aurigami',
    symbol: 'ply',
    name: 'Aurigami',
  },
  {
    id: 'aurinko-network',
    symbol: 'ark',
    name: 'Aurinko Network',
  },
  {
    id: 'aurix',
    symbol: 'aur',
    name: 'Aurix',
  },
  {
    id: 'aurora',
    symbol: 'aoa',
    name: 'Aurora Chain',
  },
  {
    id: 'auroracoin',
    symbol: 'aur',
    name: 'Auroracoin',
  },
  {
    id: 'aurora-dao',
    symbol: 'idex',
    name: 'IDEX',
  },
  {
    id: 'aurora-near',
    symbol: 'aurora',
    name: 'Aurora',
  },
  {
    id: 'auroratoken',
    symbol: 'aurora',
    name: 'AuroraToken',
  },
  {
    id: 'aurora-token',
    symbol: '$adtx',
    name: 'Aurora Dimension',
  },
  {
    id: 'aurory',
    symbol: 'aury',
    name: 'Aurory',
  },
  {
    id: 'aurum-gold',
    symbol: 'acg',
    name: 'Aurum Crypto Gold',
  },
  {
    id: 'aurusx',
    symbol: 'ax',
    name: 'AurusX',
  },
  {
    id: 'ausdc',
    symbol: 'ausdc',
    name: 'SpaceShipX aUSDC',
  },
  {
    id: 'ausd-seed-acala',
    symbol: 'aseed',
    name: 'aUSD SEED (Acala)',
  },
  {
    id: 'ausd-seed-karura',
    symbol: 'aseed',
    name: 'aUSD SEED (Karura)',
  },
  {
    id: 'australian-safe-shepherd',
    symbol: 'ass',
    name: 'Australian Safe Shepherd',
  },
  {
    id: 'authencity',
    symbol: 'auth',
    name: 'Authencity',
  },
  {
    id: 'autism',
    symbol: 'autism',
    name: 'Autism',
  },
  {
    id: 'auto',
    symbol: 'auto',
    name: 'Auto',
  },
  {
    id: 'autobahn-network',
    symbol: 'txl',
    name: 'Autobahn Network',
  },
  {
    id: 'autocrypto',
    symbol: 'au',
    name: 'AutoCrypto',
  },
  {
    id: 'autoearn-token',
    symbol: 'ate',
    name: 'AutoEarn Token',
  },
  {
    id: 'automata',
    symbol: 'ata',
    name: 'Automata',
  },
  {
    id: 'autominingtoken',
    symbol: 'amt',
    name: 'AutoMiningToken',
  },
  {
    id: 'auton',
    symbol: 'atn',
    name: 'Auton',
  },
  {
    id: 'autonio',
    symbol: 'niox',
    name: 'Autonio',
  },
  {
    id: 'autonolas',
    symbol: 'olas',
    name: 'Autonolas',
  },
  {
    id: 'autoshark',
    symbol: 'jaws',
    name: 'AutoShark',
  },
  {
    id: 'autosingle',
    symbol: 'autos',
    name: 'AutoSingle',
  },
  {
    id: 'autumn',
    symbol: 'autumn',
    name: 'Autumn',
  },
  {
    id: 'aux-coin',
    symbol: 'aux',
    name: 'AUX Coin',
  },
  {
    id: 'auxilium',
    symbol: 'aux',
    name: 'Auxilium',
  },
  {
    id: 'avabot',
    symbol: 'avb',
    name: 'Avabot',
  },
  {
    id: 'avadex-token',
    symbol: 'avex',
    name: 'AvaDex Token',
  },
  {
    id: 'avalanche-2',
    symbol: 'avax',
    name: 'Avalanche',
  },
  {
    id: 'avalanche-wormhole',
    symbol: 'avax',
    name: 'Avalanche (Wormhole)',
  },
  {
    id: 'avalaunch',
    symbol: 'xava',
    name: 'Avalaunch',
  },
  {
    id: 'avalox',
    symbol: 'avalox',
    name: 'Avalox',
  },
  {
    id: 'avante',
    symbol: 'axt',
    name: 'Avante',
  },
  {
    id: 'avaocado-dao',
    symbol: 'avg',
    name: 'Avocado DAO',
  },
  {
    id: 'avatago',
    symbol: 'agt',
    name: 'AVATAGO',
  },
  {
    id: 'avata-network',
    symbol: 'avat',
    name: 'AVATA Network',
  },
  {
    id: 'avatara-nox',
    symbol: 'nox',
    name: 'AVATARA NOX',
  },
  {
    id: 'avatar-musk-verse',
    symbol: 'amv',
    name: 'Avatar Musk Verse',
  },
  {
    id: 'avatly-2',
    symbol: 'avatly',
    name: 'Avatly',
  },
  {
    id: 'avav-asc-20',
    symbol: 'avav',
    name: 'AVAV (ASC-20)',
  },
  {
    id: 'avax-has-no-chill',
    symbol: 'nochill',
    name: 'AVAX HAS NO CHILL',
  },
  {
    id: 'avaxtars',
    symbol: 'avxt',
    name: 'Avaxtars',
  },
  {
    id: 'avaxtech',
    symbol: 'atech',
    name: 'AvaxTech',
  },
  {
    id: 'avbot',
    symbol: 'avbot',
    name: 'AVBOT',
  },
  {
    id: 'aventis-metaverse',
    symbol: 'avtm',
    name: 'Aventis Metaverse',
  },
  {
    id: 'aventus',
    symbol: 'avt',
    name: 'Aventus',
  },
  {
    id: 'avenue-hamilton-token',
    symbol: 'aht',
    name: 'Avenue Hamilton Token',
  },
  {
    id: 'aves',
    symbol: 'avs',
    name: 'AVES',
  },
  {
    id: 'avian-network',
    symbol: 'avn',
    name: 'AVIAN',
  },
  {
    id: 'aviator',
    symbol: 'avi',
    name: 'Aviator',
  },
  {
    id: 'avinoc',
    symbol: 'avinoc',
    name: 'AVINOC',
  },
  {
    id: 'avive',
    symbol: 'avive',
    name: 'Avive',
  },
  {
    id: 'avme',
    symbol: 'avme',
    name: 'AVME',
  },
  {
    id: 'avnrich',
    symbol: 'avn',
    name: 'AVNRich',
  },
  {
    id: 'avocado-bg',
    symbol: 'avo',
    name: 'AVOCADO BG',
  },
  {
    id: 'avoteo',
    symbol: 'avo',
    name: 'Avoteo',
  },
  {
    id: 'axe',
    symbol: 'axe',
    name: 'Axe',
  },
  {
    id: 'axe-2',
    symbol: 'axe',
    name: 'AXE',
  },
  {
    id: 'axe-cap',
    symbol: 'axe',
    name: 'Axe Cap',
  },
  {
    id: 'axel',
    symbol: 'axel',
    name: 'AXEL',
  },
  {
    id: 'axelar',
    symbol: 'axl',
    name: 'Axelar',
  },
  {
    id: 'axelar-usdt',
    symbol: 'axlusdt',
    name: 'Bridged Tether (Axelar)',
  },
  {
    id: 'axia',
    symbol: 'axiav3',
    name: 'Axia',
  },
  {
    id: 'axial-token',
    symbol: 'axial',
    name: 'Axial Token',
  },
  {
    id: 'axie-infinity',
    symbol: 'axs',
    name: 'Axie Infinity',
  },
  {
    id: 'axie-infinity-shard-wormhole',
    symbol: 'axset',
    name: 'Axie Infinity Shard (Wormhole)',
  },
  {
    id: 'axiodex',
    symbol: 'axn',
    name: 'AxioDex',
  },
  {
    id: 'axion',
    symbol: 'axn',
    name: 'Axion',
  },
  {
    id: 'axis-defi',
    symbol: 'axis',
    name: 'Axis DeFi',
  },
  {
    id: 'axis-token',
    symbol: 'axis',
    name: 'AXIS',
  },
  {
    id: 'axle-games',
    symbol: 'axle',
    name: 'Axle Games',
  },
  {
    id: 'axl-inu',
    symbol: 'axl',
    name: 'AXL INU',
  },
  {
    id: 'axlusdc',
    symbol: 'axlusdc',
    name: 'Bridged USD Coin (Axelar)',
  },
  {
    id: 'axlwbtc',
    symbol: 'axlwbtc',
    name: 'axlWBTC',
  },
  {
    id: 'axlweth',
    symbol: 'axleth',
    name: 'Axelar Wrapped Ether',
  },
  {
    id: 'ayin',
    symbol: 'ayin',
    name: 'Ayin',
  },
  {
    id: 'azbit',
    symbol: 'az',
    name: 'Azbit',
  },
  {
    id: 'azcoiner',
    symbol: 'azc',
    name: 'AZCoiner',
  },
  {
    id: 'azit',
    symbol: 'azit',
    name: 'azit',
  },
  {
    id: 'azmask',
    symbol: 'azm',
    name: 'AZMASK',
  },
  {
    id: 'azuki',
    symbol: 'azuki',
    name: 'Azuki',
  },
  {
    id: 'azuma-coin',
    symbol: 'azum',
    name: 'Azuma Coin',
  },
  {
    id: 'azure',
    symbol: 'azr',
    name: 'Azure',
  },
  {
    id: 'b20',
    symbol: 'b20',
    name: 'B20',
  },
  {
    id: 'b2b-token',
    symbol: 'b2b',
    name: 'B2B Token',
  },
  {
    id: 'baanx',
    symbol: 'bxx',
    name: 'Baanx',
  },
  {
    id: 'baasid',
    symbol: 'baas',
    name: 'BaaSid',
  },
  {
    id: 'babacoin',
    symbol: 'bbc',
    name: 'Babacoin',
  },
  {
    id: 'babb',
    symbol: 'bax',
    name: 'BABB',
  },
  {
    id: 'babyakita',
    symbol: 'babyakita',
    name: 'BabyAkita',
  },
  {
    id: 'baby-alienb',
    symbol: 'baby',
    name: 'Baby AlienB',
  },
  {
    id: 'baby-alvey',
    symbol: 'balvey',
    name: 'Baby Alvey',
  },
  {
    id: 'baby-aptos',
    symbol: 'baptos',
    name: 'Baby Aptos',
  },
  {
    id: 'baby-arbitrum',
    symbol: 'barb',
    name: 'Baby Arbitrum',
  },
  {
    id: 'baby-arof',
    symbol: 'baby arof',
    name: 'BABY AROF',
  },
  {
    id: 'baby-bali',
    symbol: 'bb',
    name: 'Baby Bali',
  },
  {
    id: 'babybnbtiger',
    symbol: 'babybnbtig',
    name: 'BabyBNBTiger',
  },
  {
    id: 'babybonk',
    symbol: 'babybonk',
    name: 'BabyBonk',
  },
  {
    id: 'babybonk-2',
    symbol: 'babybonk',
    name: 'BabyBonk',
  },
  {
    id: 'babyboo',
    symbol: 'babyboo',
    name: 'BabyBoo',
  },
  {
    id: 'babybtc-token',
    symbol: 'babybtc',
    name: 'BABYBTC TOKEN',
  },
  {
    id: 'baby-coq-inu',
    symbol: 'bcoq',
    name: 'Baby Coq Inu',
  },
  {
    id: 'babydoge2-0',
    symbol: 'babydoge2.0',
    name: 'Babydoge2.0',
  },
  {
    id: 'babydogearmy',
    symbol: 'army',
    name: 'BabyDogeARMY',
  },
  {
    id: 'baby-doge-cash',
    symbol: 'babydogecash',
    name: 'Baby Doge Cash',
  },
  {
    id: 'baby-doge-ceo',
    symbol: 'babyceo',
    name: 'Baby Doge CEO',
  },
  {
    id: 'babydoge-ceo',
    symbol: 'bceo',
    name: 'BabyDoge CEO',
  },
  {
    id: 'baby-doge-coin',
    symbol: 'babydoge',
    name: 'Baby Doge Coin',
  },
  {
    id: 'babydoge-coin-eth',
    symbol: 'babydoge',
    name: 'BabyDoge ETH',
  },
  {
    id: 'baby-doge-inu',
    symbol: '$babydogeinu',
    name: 'Baby Doge Inu',
  },
  {
    id: 'baby-dragon',
    symbol: 'babydragon',
    name: 'Baby Dragon',
  },
  {
    id: 'baby-dragon-2',
    symbol: 'babydragon',
    name: 'Baby Dragon',
  },
  {
    id: 'baby-elon',
    symbol: 'babyelon',
    name: 'Baby Elon',
  },
  {
    id: 'babyfloki',
    symbol: 'babyfloki',
    name: 'BabyFloki',
  },
  {
    id: 'baby-floki',
    symbol: 'babyfloki',
    name: 'Baby Floki',
  },
  {
    id: 'baby-floki-coin',
    symbol: 'babyflokicoin',
    name: 'Baby Floki Coin',
  },
  {
    id: 'baby-floki-erc',
    symbol: 'babyfloki',
    name: 'Baby Floki',
  },
  {
    id: 'baby-floki-inu',
    symbol: 'bfloki',
    name: 'Baby Floki Inu',
  },
  {
    id: 'baby-g',
    symbol: 'babyg',
    name: 'Baby G',
  },
  {
    id: 'baby-gemini',
    symbol: 'babygemini',
    name: 'Baby Gemini',
  },
  {
    id: 'baby-grok',
    symbol: 'babygrok',
    name: 'Baby Grok',
  },
  {
    id: 'baby-grok-2',
    symbol: 'brok',
    name: 'Baby GROK',
  },
  {
    id: 'baby-grok-3',
    symbol: 'babygrok',
    name: 'Baby Grok',
  },
  {
    id: 'babygrokceo',
    symbol: 'babygrokce',
    name: 'BabyGrokCEO',
  },
  {
    id: 'babygrok-x',
    symbol: 'babygrok x',
    name: 'BabyGrok X',
  },
  {
    id: 'babykitty',
    symbol: 'babykitty',
    name: 'BabyKitty',
  },
  {
    id: 'baby-lambo-inu',
    symbol: 'blinu',
    name: 'Baby Lambo Inu',
  },
  {
    id: 'babylong',
    symbol: '$babylong',
    name: 'BABYLONG',
  },
  {
    id: 'baby-long',
    symbol: 'babylong',
    name: 'Baby Long',
  },
  {
    id: 'babylons',
    symbol: 'babi',
    name: 'Babylons',
  },
  {
    id: 'baby-lovely-inu',
    symbol: 'blovely',
    name: 'Baby Lovely Inu',
  },
  {
    id: 'baby-luffy',
    symbol: 'blf',
    name: 'Baby Luffy',
  },
  {
    id: 'baby-memecoin',
    symbol: 'babymeme',
    name: 'Baby Memecoin',
  },
  {
    id: 'baby-meme-coin',
    symbol: 'babymeme',
    name: 'Baby Meme Coin',
  },
  {
    id: 'baby-musk',
    symbol: 'babymusk',
    name: 'Baby Musk',
  },
  {
    id: 'babymyro',
    symbol: 'babymyro',
    name: 'Babymyro',
  },
  {
    id: 'baby-myro',
    symbol: 'babymyro',
    name: 'Baby Myro',
  },
  {
    id: 'babypepe',
    symbol: 'babypepe',
    name: 'BabyPepe',
  },
  {
    id: 'baby-pepe',
    symbol: 'baby pepe',
    name: 'Baby Pepe',
  },
  {
    id: 'babypepe-2',
    symbol: 'babypepe',
    name: 'Babypepe',
  },
  {
    id: 'baby-pepe-erc20',
    symbol: 'babypepe',
    name: 'Baby Pepe',
  },
  {
    id: 'babypepefi',
    symbol: 'babypepe',
    name: 'Babypepefi',
  },
  {
    id: 'babyrabbit',
    symbol: 'babyrabbit',
    name: 'Babyrabbit',
  },
  {
    id: 'baby-richard-heart',
    symbol: '$brich',
    name: 'Baby Richard Heart',
  },
  {
    id: 'baby-samo-coin',
    symbol: 'baby',
    name: 'Baby Samo Coin',
  },
  {
    id: 'baby-shark',
    symbol: 'shark',
    name: 'Baby Shark',
  },
  {
    id: 'baby-shark-2',
    symbol: 'babyshark',
    name: 'Baby Shark',
  },
  {
    id: 'baby-shark-tank',
    symbol: 'bashtank',
    name: 'Baby Shark Tank',
  },
  {
    id: 'baby-shiba-inu',
    symbol: 'babyshibainu',
    name: 'Baby Shiba Inu',
  },
  {
    id: 'baby-shiba-inu-erc',
    symbol: 'babyshib',
    name: 'Baby Shiba Inu',
  },
  {
    id: 'babysmurf9000',
    symbol: 'bs9000',
    name: 'BabySmurf9000',
  },
  {
    id: 'babysol',
    symbol: 'babysol',
    name: 'BabySOL',
  },
  {
    id: 'baby-squid-game',
    symbol: 'bsg',
    name: 'Baby Squid Game',
  },
  {
    id: 'babyswap',
    symbol: 'baby',
    name: 'BabySwap',
  },
  {
    id: 'baby-tomcat',
    symbol: 'babytomcat',
    name: 'Baby Tomcat',
  },
  {
    id: 'babytrump',
    symbol: 'babytrump',
    name: 'BABYTRUMP',
  },
  {
    id: 'baby-wall-street-memes',
    symbol: 'bwsm',
    name: 'BABY WALL STREET MEMES',
  },
  {
    id: 'babywhale',
    symbol: 'bbw',
    name: 'BabyWhale',
  },
  {
    id: 'baby-x',
    symbol: 'babyx',
    name: 'Baby X',
  },
  {
    id: 'babyxrp',
    symbol: 'bbyxrp',
    name: 'BabyXrp',
  },
  {
    id: 'babyx-swap',
    symbol: 'babyx',
    name: 'BabyX Swap',
  },
  {
    id: 'baby-yooshiape',
    symbol: 'byooshiape',
    name: 'Baby YooshiApe',
  },
  {
    id: 'backbone-labs-staked-huahua',
    symbol: 'bhuahua',
    name: 'Backbone Labs Staked HUAHUA',
  },
  {
    id: 'backbone-labs-staked-juno',
    symbol: 'bjuno',
    name: 'Backbone Labs Staked JUNO',
  },
  {
    id: 'backbone-labs-staked-luna',
    symbol: 'bluna',
    name: 'Backbone Labs Staked LUNA',
  },
  {
    id: 'backbone-labs-staked-whale',
    symbol: 'bwhale',
    name: 'Backbone Labs Staked WHALE',
  },
  {
    id: 'backed-coinbase-global',
    symbol: 'bcoin',
    name: 'Backed Coinbase Global',
  },
  {
    id: 'backed-cspx-core-s-p-500',
    symbol: 'bcspx',
    name: 'Backed CSPX Core S&P 500',
  },
  {
    id: 'backed-govies-0-6-months-euro',
    symbol: 'bc3m',
    name: 'Backed GOVIES 0-6 months EURO',
  },
  {
    id: 'backed-high-high-yield-corp-bond',
    symbol: 'bhigh',
    name: 'Backed HIGH € High Yield Corp Bond',
  },
  {
    id: 'backed-ib01-treasury-bond-0-1yr',
    symbol: 'bib01',
    name: 'Backed IB01 $ Treasury Bond 0-1yr',
  },
  {
    id: 'backed-ibta-treasury-bond-1-3yr',
    symbol: 'bibta',
    name: 'Backed IBTA $ Treasury Bond 1-3yr',
  },
  {
    id: 'backed-niu-technologies',
    symbol: 'bniu',
    name: 'Backed NIU Technologies',
  },
  {
    id: 'backed-zpr1-1-3-month-t-bill',
    symbol: 'bzpr1',
    name: 'Backed ZPR1 $ 1-3 Month T-Bill',
  },
  {
    id: 'bacondao',
    symbol: 'bacon',
    name: 'BaconDAO',
  },
  {
    id: 'badger-dao',
    symbol: 'badger',
    name: 'Badger',
  },
  {
    id: 'badger-sett-badger',
    symbol: 'bbadger',
    name: 'Badger Sett Badger',
  },
  {
    id: 'bad-idea-ai',
    symbol: 'bad',
    name: 'Bad Idea AI',
  },
  {
    id: 'bad-santa',
    symbol: 'bad',
    name: 'Bad Santa',
  },
  {
    id: 'bae',
    symbol: 'bae',
    name: 'Bae',
  },
  {
    id: 'bafi-finance-token',
    symbol: 'bafi',
    name: 'Bafi Finance',
  },
  {
    id: 'bagholder',
    symbol: 'bag',
    name: 'Bagholder',
  },
  {
    id: 'bahamas',
    symbol: 'bahamas',
    name: 'Bahamas',
  },
  {
    id: 'bahamas-network',
    symbol: 'bn',
    name: 'Bahamas Network',
  },
  {
    id: 'bai-stablecoin',
    symbol: 'bai',
    name: 'BAI Stablecoin',
  },
  {
    id: 'baka-casino',
    symbol: 'bakac',
    name: 'Baka Casino',
  },
  {
    id: 'baked-token',
    symbol: 'baked',
    name: 'Baked',
  },
  {
    id: 'bakerytoken',
    symbol: 'bake',
    name: 'BakerySwap',
  },
  {
    id: 'bakerytools',
    symbol: 'tbake',
    name: 'BakeryTools',
  },
  {
    id: 'baklava',
    symbol: 'bava',
    name: 'Baklava',
  },
  {
    id: 'balance-ai',
    symbol: 'bai',
    name: 'Balance AI',
  },
  {
    id: 'balanced-dollars',
    symbol: 'bnusd',
    name: 'Balanced Dollars',
  },
  {
    id: 'balance-network-finance',
    symbol: 'balance',
    name: 'Balance Network Finance',
  },
  {
    id: 'balancer',
    symbol: 'bal',
    name: 'Balancer',
  },
  {
    id: 'balancer-80-bal-20-weth',
    symbol: 'b-80bal-20weth',
    name: 'Balancer 80 BAL 20 WETH',
  },
  {
    id: 'balancer-80-rdnt-20-weth',
    symbol: 'dlp',
    name: 'Balancer 80 RDNT 20 WETH',
  },
  {
    id: 'balancer-boosted-aave-weth-2',
    symbol: 'bb-a-weth',
    name: 'Balancer Boosted Aave WETH',
  },
  {
    id: 'balancer-savings-dai-boosted-pool',
    symbol: 'bb-s-dai',
    name: 'Balancer Savings DAI Boosted Pool',
  },
  {
    id: 'balancer-stable-usd',
    symbol: 'stabal3',
    name: 'Balancer Stable USD',
  },
  {
    id: 'balancer-usdc-usdbc-axlusdc',
    symbol: 'usdc-usdbc-axlusdc',
    name: 'Balancer USDC/USDbC/axlUSDC',
  },
  {
    id: 'balance-tokens',
    symbol: 'baln',
    name: 'Balanced',
  },
  {
    id: 'bald',
    symbol: 'bald',
    name: 'Bald',
  },
  {
    id: 'bali-token',
    symbol: 'bli',
    name: 'Bali Token',
  },
  {
    id: 'bali-united-fc-fan-token',
    symbol: 'bufc',
    name: 'Bali United FC Fan Token',
  },
  {
    id: 'ball-coin',
    symbol: 'ball',
    name: 'BALL Coin',
  },
  {
    id: 'ballswap',
    symbol: 'bsp',
    name: 'BallSwap',
  },
  {
    id: 'ball-token',
    symbol: 'ball',
    name: 'Ball',
  },
  {
    id: 'balpha',
    symbol: 'balpha',
    name: 'bAlpha',
  },
  {
    id: 'bambi',
    symbol: 'bam',
    name: 'Bambi',
  },
  {
    id: 'bamboo-coin',
    symbol: 'bmbo',
    name: 'Bamboo Coin',
  },
  {
    id: 'bamboo-defi',
    symbol: 'bamboo',
    name: 'Bamboo DeFi',
  },
  {
    id: 'bamboo-token-c90b31ff-8355-41d6-a495-2b16418524c2',
    symbol: 'bbo',
    name: 'PandaFarm (BBO)',
  },
  {
    id: 'banana',
    symbol: 'banana',
    name: 'Banana',
  },
  {
    id: 'bananacat',
    symbol: 'bcat',
    name: 'BananaCat',
  },
  {
    id: 'bananacat-sol',
    symbol: 'bcat',
    name: 'BananaCat (Sol)',
  },
  {
    id: 'bananace',
    symbol: 'nana',
    name: 'Bananace',
  },
  {
    id: 'banana-coin',
    symbol: 'banana',
    name: 'BananaCoin',
  },
  {
    id: 'banana-gun',
    symbol: 'banana',
    name: 'Banana Gun',
  },
  {
    id: 'banana-market-ordinals',
    symbol: 'bnan',
    name: 'Banana Market (Ordinals)',
  },
  {
    id: 'bananatok',
    symbol: 'bna',
    name: 'BananaTok',
  },
  {
    id: 'banana-token',
    symbol: 'bnana',
    name: 'Chimpion',
  },
  {
    id: 'banano',
    symbol: 'ban',
    name: 'Banano',
  },
  {
    id: 'bancor',
    symbol: 'bnt',
    name: 'Bancor Network',
  },
  {
    id: 'bancor-governance-token',
    symbol: 'vbnt',
    name: 'Bancor Governance',
  },
  {
    id: 'band-protocol',
    symbol: 'band',
    name: 'Band Protocol',
  },
  {
    id: 'bandzai-token',
    symbol: 'bzai',
    name: 'BandZai Token',
  },
  {
    id: 'banger',
    symbol: 'banger',
    name: 'BANGER',
  },
  {
    id: 'bank',
    symbol: '$bank',
    name: 'Bank',
  },
  {
    id: 'bankbrc',
    symbol: 'bank',
    name: 'BANK (Ordinals)',
  },
  {
    id: 'bank-btc',
    symbol: 'bankbtc',
    name: 'Bank BTC',
  },
  {
    id: 'bank-btc-2',
    symbol: 'bankbtc',
    name: 'Bank BTC',
  },
  {
    id: 'bankera',
    symbol: 'bnk',
    name: 'Bankera',
  },
  {
    id: 'bankercoin',
    symbol: '$bank',
    name: 'Bankercoin',
  },
  {
    id: 'bankers-dream',
    symbol: 'bank$',
    name: 'Bankers Dream',
  },
  {
    id: 'bankless-bed-index',
    symbol: 'bed',
    name: 'Bankless BED Index',
  },
  {
    id: 'bankless-dao',
    symbol: 'bank',
    name: 'Bankless DAO',
  },
  {
    id: 'bankroll-extended-token',
    symbol: 'bnkrx',
    name: 'Bankroll Extended',
  },
  {
    id: 'bankroll-vault',
    symbol: 'vlt',
    name: 'Bankroll Vault',
  },
  {
    id: 'banksocial',
    symbol: 'bsl',
    name: 'BankSocial',
  },
  {
    id: 'banque-universal',
    symbol: 'cbu',
    name: 'Banque Universal',
  },
  {
    id: 'bantu',
    symbol: 'xbn',
    name: 'Bantu',
  },
  {
    id: 'banus-finance',
    symbol: 'banus',
    name: 'Banus Finance',
  },
  {
    id: 'baoeth-eth-stablepool',
    symbol: 'b-baoeth-eth-bpt',
    name: 'baoETH-ETH StablePool',
  },
  {
    id: 'bao-finance',
    symbol: 'bao',
    name: 'Bao Finance',
  },
  {
    id: 'bao-finance-v2',
    symbol: 'bao',
    name: 'Bao Finance V2',
  },
  {
    id: 'baousd-lusd-stablepool',
    symbol: 'baousd-lusd-stablepool',
    name: 'baoUSD-LUSD StablePool',
  },
  {
    id: 'barbiecrashbandicootrfk88',
    symbol: 'solana',
    name: 'BarbieCrashBandicootRFK88',
  },
  {
    id: 'bark',
    symbol: 'bark',
    name: 'Bark',
  },
  {
    id: 'bark-ai',
    symbol: 'bark',
    name: 'Bark AI',
  },
  {
    id: 'bark-gas-token',
    symbol: 'bark',
    name: 'Bark Gas Token',
  },
  {
    id: 'barking',
    symbol: 'bark',
    name: 'Barking',
  },
  {
    id: 'barley-finance',
    symbol: 'barl',
    name: 'Barley Finance',
  },
  {
    id: 'barnbridge',
    symbol: 'bond',
    name: 'BarnBridge',
  },
  {
    id: 'barsik',
    symbol: 'barsik',
    name: 'BARSIK',
  },
  {
    id: 'barter',
    symbol: 'brtr',
    name: 'Barter',
  },
  {
    id: 'bart-simpson-coin',
    symbol: 'bart',
    name: 'Bart Simpson Coin',
  },
  {
    id: 'base',
    symbol: 'base',
    name: 'Base',
  },
  {
    id: 'baseape',
    symbol: 'bape',
    name: 'Baseape',
  },
  {
    id: 'basebank',
    symbol: 'bbank',
    name: 'BaseBank',
  },
  {
    id: 'based-ai',
    symbol: 'bai',
    name: 'Based AI',
  },
  {
    id: 'basedbets',
    symbol: 'bet',
    name: 'BasedBets',
  },
  {
    id: 'based-farm',
    symbol: 'based',
    name: 'Based Farm',
  },
  {
    id: 'based-finance',
    symbol: 'based',
    name: 'Based Finance',
  },
  {
    id: 'based-markets',
    symbol: 'based',
    name: 'based.markets',
  },
  {
    id: 'based-money-finance',
    symbol: 'based',
    name: 'Based Money Finance',
  },
  {
    id: 'based-rate',
    symbol: 'brate',
    name: 'Based Rate',
  },
  {
    id: 'based-rate-share',
    symbol: 'bshare',
    name: 'Based Rate Share',
  },
  {
    id: 'based-shares',
    symbol: 'bshare',
    name: 'BASED Shares',
  },
  {
    id: 'base-god',
    symbol: 'tybg',
    name: 'Base God',
  },
  {
    id: 'baseinu',
    symbol: 'binu',
    name: 'BaseInu',
  },
  {
    id: 'base-name-service',
    symbol: 'bns',
    name: 'Base Name Service',
  },
  {
    id: 'basepal',
    symbol: 'basepal',
    name: 'BasePal',
  },
  {
    id: 'base-protocol',
    symbol: 'base',
    name: 'Base Protocol',
  },
  {
    id: 'baseswap',
    symbol: 'bswap',
    name: 'BaseSwap',
  },
  {
    id: 'base-velocimeter',
    symbol: 'bvm',
    name: 'Base Velocimeter',
  },
  {
    id: 'basex',
    symbol: 'bsx',
    name: 'BaseX',
  },
  {
    id: 'basexchange',
    symbol: 'bex',
    name: 'BaseXchange',
  },
  {
    id: 'baseyield',
    symbol: 'bay',
    name: 'BaseYield',
  },
  {
    id: 'basic-attention-token',
    symbol: 'bat',
    name: 'Basic Attention',
  },
  {
    id: 'basic-dog-meme',
    symbol: 'dog',
    name: 'Basic Dog Meme',
  },
  {
    id: 'basilisk',
    symbol: 'bsx',
    name: 'Basilisk',
  },
  {
    id: 'basin-finance',
    symbol: 'basin',
    name: 'Basin Finance',
  },
  {
    id: 'basis-cash',
    symbol: 'bac',
    name: 'Basis Cash',
  },
  {
    id: 'basis-gold-share-heco',
    symbol: 'bags',
    name: 'Basis Gold Share (Heco)',
  },
  {
    id: 'basis-markets',
    symbol: 'basis',
    name: 'basis.markets',
  },
  {
    id: 'basis-share',
    symbol: 'bas',
    name: 'Basis Share',
  },
  {
    id: 'basket',
    symbol: 'bskt',
    name: 'Basket',
  },
  {
    id: 'basketball-legends',
    symbol: 'bbl',
    name: 'Basketball Legends',
  },
  {
    id: 'basketcoin',
    symbol: 'bskt',
    name: 'BasketCoin',
  },
  {
    id: 'baskonia-fan-token',
    symbol: 'bkn',
    name: 'Baskonia Fan Token',
  },
  {
    id: 'baso-finance',
    symbol: 'baso',
    name: 'Baso Finance',
  },
  {
    id: 'bass-exchange',
    symbol: '$bass',
    name: 'Bass Exchange',
  },
  {
    id: 'bata',
    symbol: 'bta',
    name: 'Bata',
  },
  {
    id: 'battlefly',
    symbol: 'gfly',
    name: 'BattleFly',
  },
  {
    id: 'battle-for-giostone',
    symbol: 'bfg',
    name: 'Battle For Giostone',
  },
  {
    id: 'battleforten',
    symbol: 'bft',
    name: 'BattleForTEN',
  },
  {
    id: 'battleground',
    symbol: 'battle',
    name: 'Battleground',
  },
  {
    id: 'battle-infinity',
    symbol: 'ibat',
    name: 'Battle Infinity',
  },
  {
    id: 'battle-of-guardians-share',
    symbol: 'bgs',
    name: 'Battle of Guardians Share',
  },
  {
    id: 'battle-pets',
    symbol: 'pet',
    name: 'Hello Pets',
  },
  {
    id: 'battle-saga',
    symbol: 'btl',
    name: 'Battle Saga',
  },
  {
    id: 'battleverse',
    symbol: 'bvc',
    name: 'BattleVerse',
  },
  {
    id: 'battle-world',
    symbol: 'bwo',
    name: 'Battle World',
  },
  {
    id: 'bawls-onu',
    symbol: '$bawls',
    name: 'Bawls onu',
  },
  {
    id: 'bayc-fraction-token',
    symbol: '',
    name: 'BAYC Fraction Token',
  },
  {
    id: 'bayesian',
    symbol: 'baye',
    name: 'Bayesian',
  },
  {
    id: 'baymax-finance',
    symbol: 'bay',
    name: 'BayMax Finance',
  },
  {
    id: 'bazaars',
    symbol: 'bzr',
    name: 'Bazaars',
  },
  {
    id: 'bazed-games',
    symbol: 'bazed',
    name: 'Bazed Games',
  },
  {
    id: 'bb-gaming',
    symbol: 'bb',
    name: 'BB Gaming',
  },
  {
    id: 'bbs-network',
    symbol: 'bbs',
    name: 'BBS Network',
  },
  {
    id: 'bch-club-cash',
    symbol: 'cash',
    name: 'BCH Club CASH',
  },
  {
    id: 'bcpay-fintech',
    symbol: 'bcpay',
    name: 'BCPAY FinTech',
  },
  {
    id: 'b-cube-ai',
    symbol: 'bcube',
    name: 'B-cube.ai',
  },
  {
    id: 'bdollar',
    symbol: 'bdo',
    name: 'bDollar',
  },
  {
    id: 'beacon',
    symbol: 'becn',
    name: 'Beacon',
  },
  {
    id: 'beam',
    symbol: 'beam',
    name: 'BEAM',
  },
  {
    id: 'beam-2',
    symbol: 'beam',
    name: 'Beam',
  },
  {
    id: 'beam-bridged-avax-beam',
    symbol: 'avax',
    name: 'Beam Bridged AVAX (Beam)',
  },
  {
    id: 'beam-bridged-usdc-beam',
    symbol: 'usdc',
    name: 'Beam Bridged USDC (Beam)',
  },
  {
    id: 'beamcat',
    symbol: 'bcat',
    name: 'BEAMCAT',
  },
  {
    id: 'beamswap',
    symbol: 'glint',
    name: 'BeamSwap',
  },
  {
    id: 'bean',
    symbol: 'bean',
    name: 'Bean',
  },
  {
    id: 'bean-2',
    symbol: 'bean',
    name: 'Bean',
  },
  {
    id: 'bean-cash',
    symbol: 'bitb',
    name: 'Bean Cash',
  },
  {
    id: 'bear',
    symbol: 'bear',
    name: 'Bear',
  },
  {
    id: 'bear-2',
    symbol: 'bear',
    name: 'Bear',
  },
  {
    id: 'bearai',
    symbol: 'bai',
    name: 'BearAI',
  },
  {
    id: 'beardy-dragon',
    symbol: 'beardy',
    name: 'Beardy Dragon',
  },
  {
    id: 'bear-inu',
    symbol: 'bear',
    name: 'Bear Inu',
  },
  {
    id: 'bear-scrub-money',
    symbol: 'bear',
    name: 'Bear Scrub Money',
  },
  {
    id: 'beat-2',
    symbol: 'beat',
    name: 'Beat',
  },
  {
    id: 'beatgen-nft',
    symbol: 'bgn',
    name: 'BeatGen NFT',
  },
  {
    id: 'beautifulprincessdisorder',
    symbol: 'bpd',
    name: 'BeautifulPrincessDisorder',
  },
  {
    id: 'beauty-bakery-linked-operation-transaction-technology',
    symbol: 'lott',
    name: 'Beauty Bakery Linked Operation Transaction Technology',
  },
  {
    id: 'bebe',
    symbol: 'bebe',
    name: 'BEBE',
  },
  {
    id: 'becoswap-token',
    symbol: 'beco',
    name: 'BecoSwap',
  },
  {
    id: 'beebox',
    symbol: 'xbbc',
    name: 'Beebox',
  },
  {
    id: 'beechat',
    symbol: 'chat',
    name: 'BeeChat',
  },
  {
    id: 'beeco',
    symbol: 'bgc',
    name: 'Bee Token',
  },
  {
    id: 'beef',
    symbol: 'beef',
    name: 'BEEF',
  },
  {
    id: 'beefy-escrowed-fantom',
    symbol: 'beftm',
    name: 'Beefy Escrowed Fantom',
  },
  {
    id: 'beefy-finance',
    symbol: 'bifi',
    name: 'Beefy',
  },
  {
    id: 'beenode',
    symbol: 'bnode',
    name: 'Beenode',
  },
  {
    id: 'beep-coin',
    symbol: 'beep',
    name: 'BEEP Coin',
  },
  {
    id: 'beer-money',
    symbol: 'beer',
    name: 'Beer Money',
  },
  {
    id: 'beethoven-x',
    symbol: 'beets',
    name: 'Beethoven X',
  },
  {
    id: 'bee-tools',
    symbol: 'buzz',
    name: 'Bee Tools',
  },
  {
    id: 'beetroot',
    symbol: 'beet',
    name: 'BEETroot',
  },
  {
    id: 'befasterholdertoken',
    symbol: 'bfht',
    name: 'BeFaster Holder Token',
  },
  {
    id: 'befe',
    symbol: 'befe',
    name: 'BEFE',
  },
  {
    id: 'befi-labs',
    symbol: 'befi',
    name: 'BeFi Labs',
  },
  {
    id: 'befitter',
    symbol: 'fiu',
    name: 'beFITTER',
  },
  {
    id: 'befitter-health',
    symbol: 'hee',
    name: 'beFITTER Health',
  },
  {
    id: 'beg',
    symbol: 'beg',
    name: 'Beg',
  },
  {
    id: 'beholder',
    symbol: 'eye',
    name: 'Behodler',
  },
  {
    id: 'bela',
    symbol: 'aqua',
    name: 'Bela Aqua',
  },
  {
    id: 'beldex',
    symbol: 'bdx',
    name: 'Beldex',
  },
  {
    id: 'belifex',
    symbol: 'befx',
    name: 'Belifex',
  },
  {
    id: 'bella-protocol',
    symbol: 'bel',
    name: 'Bella Protocol',
  },
  {
    id: 'bellcoin',
    symbol: 'bell',
    name: 'Bellcoin',
  },
  {
    id: 'bellscoin',
    symbol: 'bel',
    name: 'Bellscoin',
  },
  {
    id: 'belt',
    symbol: 'belt',
    name: 'Belt',
  },
  {
    id: 'beluga-fi',
    symbol: 'beluga',
    name: 'Beluga.fi',
  },
  {
    id: 'beluga-protocol',
    symbol: 'bela',
    name: 'Beluga Protocol',
  },
  {
    id: 'bemchain',
    symbol: 'bcn',
    name: 'Bemchain',
  },
  {
    id: 'ben-2',
    symbol: 'ben',
    name: 'Ben',
  },
  {
    id: 'bencoin',
    symbol: '$ben',
    name: 'BENCOIN',
  },
  {
    id: 'benddao',
    symbol: 'bend',
    name: 'BendDAO',
  },
  {
    id: 'benddao-bdin-ordinals',
    symbol: 'bdin',
    name: 'BendDAO BDIN (Ordinals)',
  },
  {
    id: 'benft-solutions',
    symbol: 'beai',
    name: 'BeNFT Solutions',
  },
  {
    id: 'benji-bananas',
    symbol: 'benji',
    name: 'Benji Bananas',
  },
  {
    id: 'benqi',
    symbol: 'qi',
    name: 'BENQI',
  },
  {
    id: 'benqi-liquid-staked-avax',
    symbol: 'savax',
    name: 'BENQI Liquid Staked AVAX',
  },
  {
    id: 'ben-s-finale',
    symbol: 'finale',
    name: "Ben's Finale",
  },
  {
    id: 'bent-finance',
    symbol: 'bent',
    name: 'Bent Finance',
  },
  {
    id: 'benyke-finance',
    symbol: 'benyke',
    name: 'Benyke Finance',
  },
  {
    id: 'benzene',
    symbol: 'bzn',
    name: 'Benzene',
  },
  {
    id: 'bep20-leo',
    symbol: 'bleo',
    name: 'BEP20 LEO',
  },
  {
    id: 'bepay',
    symbol: 'becoin',
    name: 'bePAY Finance',
  },
  {
    id: 'bepro-network',
    symbol: 'bepro',
    name: 'Bepro',
  },
  {
    id: 'berachain-bera',
    symbol: 'bera',
    name: 'Berachain BERA',
  },
  {
    id: 'beradex',
    symbol: 'brdx',
    name: 'Beradex',
  },
  {
    id: 'bergerdoge',
    symbol: 'bergerdoge',
    name: 'BergerDoge',
  },
  {
    id: 'bermuda',
    symbol: 'bmda',
    name: 'Bermuda',
  },
  {
    id: 'berry',
    symbol: 'berry',
    name: 'Berry',
  },
  {
    id: 'berry-data',
    symbol: 'bry',
    name: 'Berry Data',
  },
  {
    id: 'berry-pixels',
    symbol: 'berry',
    name: 'Berry',
  },
  {
    id: 'berryswap',
    symbol: 'berry',
    name: 'BerrySwap',
  },
  {
    id: 'besa-gaming-company',
    symbol: 'besa',
    name: 'Besa Gaming Company',
  },
  {
    id: 'besiktas',
    symbol: 'bjk',
    name: 'Beşiktaş',
  },
  {
    id: 'beskar',
    symbol: 'bsk-baa025',
    name: 'Beskar',
  },
  {
    id: 'betacarbon',
    symbol: 'bcau',
    name: 'BetaCarbon',
  },
  {
    id: 'beta-finance',
    symbol: 'beta',
    name: 'Beta Finance',
  },
  {
    id: 'beta-token',
    symbol: 'beta',
    name: 'Beta',
  },
  {
    id: 'betbot',
    symbol: 'bbot',
    name: 'BetBot',
  },
  {
    id: 'betbuinu',
    symbol: 'crypto',
    name: 'BetbuInu',
  },
  {
    id: 'betero',
    symbol: 'bte',
    name: 'Betero',
  },
  {
    id: 'betit',
    symbol: 'betit',
    name: 'BetIT',
  },
  {
    id: 'betrock',
    symbol: 'betrock',
    name: 'Betrock',
  },
  {
    id: 'betswap-gg',
    symbol: 'bsgg',
    name: 'Betswap.gg',
  },
  {
    id: 'betswirl',
    symbol: 'bets',
    name: 'BetSwirl',
  },
  {
    id: 'betted',
    symbol: 'bets',
    name: 'Betted',
  },
  {
    id: 'betterfan',
    symbol: 'bff',
    name: 'BetterFan',
  },
  {
    id: 'betterment-digital',
    symbol: 'bemd',
    name: 'Betterment Digital',
  },
  {
    id: 'beyond-finance',
    symbol: 'byn',
    name: 'NBX',
  },
  {
    id: 'beyond-protocol',
    symbol: 'bp',
    name: 'Beyond Protocol',
  },
  {
    id: 'bezoge-earth',
    symbol: 'bezoge',
    name: 'Bezoge Earth',
  },
  {
    id: 'bfg-token',
    symbol: 'bfg',
    name: 'BetFury',
  },
  {
    id: 'bficoin',
    symbol: 'bfic',
    name: 'BFICoin',
  },
  {
    id: 'bfk-warzone',
    symbol: 'bfk',
    name: 'BFK WARZONE',
  },
  {
    id: 'bg-trade',
    symbol: 'bgt',
    name: 'BG Trade',
  },
  {
    id: 'bhbd',
    symbol: 'bhbd',
    name: 'bHBD',
  },
  {
    id: 'bhd1',
    symbol: 'bhd1',
    name: 'BHD1',
  },
  {
    id: 'bhive',
    symbol: 'bhive',
    name: 'bHIVE',
  },
  {
    id: 'bhnetwork',
    symbol: 'bhat',
    name: 'BHNetwork',
  },
  {
    id: 'bho-network',
    symbol: 'bho',
    name: 'BHO Network',
  },
  {
    id: 'biaocoin',
    symbol: 'biao',
    name: 'Biaocoin',
  },
  {
    id: 'bibi',
    symbol: 'bibi',
    name: 'BIBI',
  },
  {
    id: 'bibi2-0',
    symbol: 'bibi2.0',
    name: 'BIBI2.0',
  },
  {
    id: 'biblecoin',
    symbol: 'bibl',
    name: 'Biblecoin',
  },
  {
    id: 'biblepay',
    symbol: 'bbp',
    name: 'BiblePay',
  },
  {
    id: 'bibox-token',
    symbol: 'bix',
    name: 'Bibox',
  },
  {
    id: 'biconomy',
    symbol: 'bico',
    name: 'Biconomy',
  },
  {
    id: 'biconomy-exchange-token',
    symbol: 'bit',
    name: 'Biconomy Exchange Token',
  },
  {
    id: 'bictory',
    symbol: 'bt',
    name: 'Bictory',
  },
  {
    id: 'bidao',
    symbol: 'bid',
    name: 'Bidao',
  },
  {
    id: 'bidao-smart-chain',
    symbol: 'bisc',
    name: 'Bidao Smart Chain',
  },
  {
    id: 'bidipass',
    symbol: 'bdp',
    name: 'BidiPass',
  },
  {
    id: 'bid-protocol',
    symbol: 'bidp',
    name: 'BID Protocol',
  },
  {
    id: 'bidz-coin',
    symbol: 'bidz',
    name: 'BIDZ Coin',
  },
  {
    id: 'bifi',
    symbol: 'bifi',
    name: 'BiFi',
  },
  {
    id: 'bifinance-exchange',
    symbol: 'bft',
    name: 'BiFinance Exchange',
  },
  {
    id: 'bifrost',
    symbol: 'bfc',
    name: 'Bifrost',
  },
  {
    id: 'bifrost-native-coin',
    symbol: 'bnc',
    name: 'Bifrost Native Coin',
  },
  {
    id: 'bifrost-voucher-astr',
    symbol: 'vastr',
    name: 'Bifrost Voucher ASTR',
  },
  {
    id: 'bifrost-voucher-manta',
    symbol: 'vmanta',
    name: 'Bifrost Voucher MANTA',
  },
  {
    id: 'big-bonus-coin',
    symbol: 'bbc',
    name: 'Big Bonus Coin',
  },
  {
    id: 'big-bonus-coin-2',
    symbol: 'bbc',
    name: 'Big Bonus Coin [ETH]',
  },
  {
    id: 'bigcap',
    symbol: 'bigcap',
    name: 'BIGCAP',
  },
  {
    id: 'big-crypto-game',
    symbol: 'crypto',
    name: 'Big Crypto Game',
  },
  {
    id: 'big-data-protocol',
    symbol: 'bdp',
    name: 'Big Data Protocol',
  },
  {
    id: 'big-defi-energy',
    symbol: 'bde',
    name: 'Big Defi Energy',
  },
  {
    id: 'big-eyes',
    symbol: 'big',
    name: 'Big Eyes',
  },
  {
    id: 'big-floppa',
    symbol: '$floppa',
    name: 'Big Floppa',
  },
  {
    id: 'bigfoot-monster',
    symbol: 'bigf',
    name: 'Bigfoot Monster',
  },
  {
    id: 'bigpanda',
    symbol: 'bigpanda',
    name: 'BIGPANDA',
  },
  {
    id: 'big-panda',
    symbol: 'panda',
    name: 'Big Panda',
  },
  {
    id: 'big-roo',
    symbol: 'bigroo',
    name: 'BIG ROO',
  },
  {
    id: 'bigshortbets',
    symbol: 'bigsb',
    name: 'BigShortBets',
  },
  {
    id: 'big-time',
    symbol: 'bigtime',
    name: 'Big Time',
  },
  {
    id: 'biis-ordinals',
    symbol: 'biis',
    name: 'Biis (Ordinals)',
  },
  {
    id: 'bikerush',
    symbol: 'brt',
    name: 'Bikerush',
  },
  {
    id: 'bilira',
    symbol: 'tryb',
    name: 'BiLira',
  },
  {
    id: 'billiard-crypto',
    symbol: 'bic',
    name: 'Billiard Crypto',
  },
  {
    id: 'billicat',
    symbol: 'bcat',
    name: 'BilliCat',
  },
  {
    id: 'billionaires-pixel-club',
    symbol: 'bpc',
    name: 'Billionaires Pixel Club',
  },
  {
    id: 'billionhappiness',
    symbol: 'bhc',
    name: 'BillionHappiness',
  },
  {
    id: 'billionview',
    symbol: 'bvt',
    name: 'Billionview',
  },
  {
    id: 'billy-token',
    symbol: 'billy',
    name: 'Billy Token',
  },
  {
    id: 'bim',
    symbol: 'bim',
    name: 'BIM',
  },
  {
    id: 'bimbo-the-dog',
    symbol: 'bimbo',
    name: 'Bimbo The Dog',
  },
  {
    id: 'binamon',
    symbol: 'bmon',
    name: 'Binamon',
  },
  {
    id: 'binance-bitcoin',
    symbol: 'btcb',
    name: 'Binance Bitcoin',
  },
  {
    id: 'binancecoin',
    symbol: 'bnb',
    name: 'BNB',
  },
  {
    id: 'binance-coin-wormhole',
    symbol: 'bnb',
    name: 'Binance Coin (Wormhole)',
  },
  {
    id: 'binance-eth',
    symbol: 'beth',
    name: 'Binance ETH staking',
  },
  {
    id: 'binanceidr',
    symbol: 'bidr',
    name: 'BIDR',
  },
  {
    id: 'binance-peg-avalanche',
    symbol: 'avax',
    name: 'Binance-Peg Avalanche',
  },
  {
    id: 'binance-peg-bitcoin-cash',
    symbol: 'bch',
    name: 'Binance-Peg Bitcoin Cash',
  },
  {
    id: 'binance-peg-busd',
    symbol: 'busd',
    name: 'Binance-Peg BUSD',
  },
  {
    id: 'binance-peg-cardano',
    symbol: 'ada',
    name: 'Binance-Peg Cardano',
  },
  {
    id: 'binance-peg-dogecoin',
    symbol: 'doge',
    name: 'Binance-Peg Dogecoin',
  },
  {
    id: 'binance-peg-eos',
    symbol: 'eos',
    name: 'Binance-Peg EOS',
  },
  {
    id: 'binance-peg-filecoin',
    symbol: 'fil',
    name: 'Binance-Peg Filecoin',
  },
  {
    id: 'binance-peg-firo',
    symbol: 'firo',
    name: 'Binance-Peg Firo',
  },
  {
    id: 'binance-peg-iotex',
    symbol: 'iotx',
    name: 'Binance-Peg IoTeX',
  },
  {
    id: 'binance-peg-litecoin',
    symbol: 'ltc',
    name: 'Binance-Peg Litecoin',
  },
  {
    id: 'binance-peg-ontology',
    symbol: 'ont',
    name: 'Binance-Peg Ontology',
  },
  {
    id: 'binance-peg-polkadot',
    symbol: 'dot',
    name: 'Binance-Peg Polkadot',
  },
  {
    id: 'binance-peg-xrp',
    symbol: 'xrp',
    name: 'Binance-Peg XRP',
  },
  {
    id: 'binance-usd',
    symbol: 'busd',
    name: 'BUSD',
  },
  {
    id: 'binance-usd-linea',
    symbol: 'busd',
    name: 'Binance USD (Linea)',
  },
  {
    id: 'binance-wrapped-btc',
    symbol: 'bbtc',
    name: 'Binance Wrapped BTC',
  },
  {
    id: 'binarydao',
    symbol: 'byte',
    name: 'BinaryDAO',
  },
  {
    id: 'binary-swap',
    symbol: '0101',
    name: 'Binary Swap',
  },
  {
    id: 'binaryx',
    symbol: 'bnx',
    name: 'BinaryX [OLD]',
  },
  {
    id: 'binaryx-2',
    symbol: 'bnx',
    name: 'BinaryX',
  },
  {
    id: 'bincentive',
    symbol: 'bcnt',
    name: 'Bincentive',
  },
  {
    id: 'binemon',
    symbol: 'bin',
    name: 'Binemon',
  },
  {
    id: 'bingo-2',
    symbol: 'catbingolo',
    name: 'Bingo',
  },
  {
    id: 'bingo-3',
    symbol: 'bingo',
    name: 'Bingo',
  },
  {
    id: 'bingus-the-cat',
    symbol: 'bingus',
    name: 'Bingus The Cat',
  },
  {
    id: 'binstarter',
    symbol: 'bsr',
    name: 'BinStarter',
  },
  {
    id: 'biokript',
    symbol: 'bkpt',
    name: 'Biokript',
  },
  {
    id: 'biometric-financial',
    symbol: 'biofi',
    name: 'BiometricFinancial',
  },
  {
    id: 'bionic-protocol',
    symbol: 'bionic',
    name: 'Bionic Protocol',
  },
  {
    id: 'biop',
    symbol: '$biop',
    name: 'BIOP',
  },
  {
    id: 'biopassport',
    symbol: 'biot',
    name: 'Bio Passport',
  },
  {
    id: 'bios',
    symbol: 'bios',
    name: '0x_nodes',
  },
  {
    id: 'bip1',
    symbol: 'bip1',
    name: 'BIP1',
  },
  {
    id: 'birake',
    symbol: 'bir',
    name: 'Birake',
  },
  {
    id: 'birb-2',
    symbol: 'birb',
    name: 'Birb',
  },
  {
    id: 'birdies',
    symbol: 'birds',
    name: 'BIRDIES',
  },
  {
    id: 'bird-money',
    symbol: 'bird',
    name: 'Bird.Money',
  },
  {
    id: 'birdtoken',
    symbol: 'birdtoken',
    name: 'birdToken',
  },
  {
    id: 'bishop',
    symbol: 'bishop',
    name: 'Bishop',
  },
  {
    id: 'biskit-protocol',
    symbol: 'biskit',
    name: 'Biskit Protocol',
  },
  {
    id: 'bismuth',
    symbol: 'bis',
    name: 'Bismuth',
  },
  {
    id: 'biso',
    symbol: 'biso',
    name: 'BISO',
  },
  {
    id: 'bistroo',
    symbol: 'bist',
    name: 'Bistroo',
  },
  {
    id: 'biswap',
    symbol: 'bsw',
    name: 'Biswap',
  },
  {
    id: 'bit2me',
    symbol: 'b2m',
    name: 'Bit2Me',
  },
  {
    id: 'bitago',
    symbol: 'xbit',
    name: 'Bitago',
  },
  {
    id: 'bitazza',
    symbol: 'btz',
    name: 'Bitazza',
  },
  {
    id: 'bitball',
    symbol: 'btb',
    name: 'Bitball',
  },
  {
    id: 'bitball-treasure',
    symbol: 'btrs',
    name: 'Bitball Treasure',
  },
  {
    id: 'bitbar',
    symbol: 'btb',
    name: 'Bitbar',
  },
  {
    id: 'bitbase-token',
    symbol: 'btbs',
    name: 'BitBase Token',
  },
  {
    id: 'bitbook-token',
    symbol: 'bbt',
    name: 'BitBook',
  },
  {
    id: 'bitboost',
    symbol: 'bbt',
    name: 'BitBoost',
  },
  {
    id: 'bitbullbot',
    symbol: 'bbb',
    name: 'BitBullBot',
  },
  {
    id: 'bitcanna',
    symbol: 'bcna',
    name: 'BitCanna',
  },
  {
    id: 'bitcash',
    symbol: 'bitc',
    name: 'BitCash',
  },
  {
    id: 'bitcastle',
    symbol: 'castle',
    name: 'bitcastle',
  },
  {
    id: 'bitci-blok',
    symbol: 'blok',
    name: 'Blok Token',
  },
  {
    id: 'bitcicoin',
    symbol: 'bitci',
    name: 'Bitcicoin',
  },
  {
    id: 'bitci-edu',
    symbol: 'bedu',
    name: 'Bitci EDU',
  },
  {
    id: 'bitci-racing-token',
    symbol: 'brace',
    name: 'Bitci Racing Token',
  },
  {
    id: 'bitclave',
    symbol: 'cat',
    name: 'BitClave',
  },
  {
    id: 'bitcoin',
    symbol: 'btc',
    name: 'Bitcoin',
  },
  {
    id: 'bitcoin-2',
    symbol: 'btc2',
    name: 'Bitcoin 2',
  },
  {
    id: 'bitcoin20',
    symbol: 'btc20',
    name: 'Bitcoin20',
  },
  {
    id: 'bitcoin-2-0',
    symbol: 'btc2.0',
    name: 'Bitcoin 2.0',
  },
  {
    id: 'bitcoin-ai',
    symbol: 'bitcoinai',
    name: 'Bitcoin AI',
  },
  {
    id: 'bitcoin-asia',
    symbol: 'btca',
    name: 'Bitcoin Asia',
  },
  {
    id: 'bitcoin-avalanche-bridged-btc-b',
    symbol: 'btc.b',
    name: 'Bitcoin Avalanche Bridged (BTC.b)',
  },
  {
    id: 'bitcoinbam',
    symbol: 'btcbam',
    name: 'BitcoinBam',
  },
  {
    id: 'bitcoin-battle',
    symbol: 'bbc',
    name: 'BitCoin Battle',
  },
  {
    id: 'bitcoin-bep2',
    symbol: 'btcb',
    name: 'Bitcoin BEP2',
  },
  {
    id: 'bitcoin-br',
    symbol: 'btcbr',
    name: 'Bitcoin BR',
  },
  {
    id: 'bitcoin-candy',
    symbol: 'cdy',
    name: 'Bitcoin Candy',
  },
  {
    id: 'bitcoin-cash',
    symbol: 'bch',
    name: 'Bitcoin Cash',
  },
  {
    id: 'bitcoin-cash-sv',
    symbol: 'bsv',
    name: 'Bitcoin SV',
  },
  {
    id: 'bitcoin-cats',
    symbol: '1cat',
    name: 'Bitcoin Cats',
  },
  {
    id: 'bitcoin-diamond',
    symbol: 'bcd',
    name: 'Bitcoin Diamond',
  },
  {
    id: 'bitcoin-e-wallet',
    symbol: 'bitwallet',
    name: 'Bitcoin E-wallet',
  },
  {
    id: 'bitcoin-fast',
    symbol: 'bcf',
    name: 'Bitcoin Fast',
  },
  {
    id: 'bitcoin-free-cash',
    symbol: 'bfc',
    name: 'Bitcoin Free Cash',
  },
  {
    id: 'bitcoin-god',
    symbol: 'god',
    name: 'Bitcoin God',
  },
  {
    id: 'bitcoin-gold',
    symbol: 'btg',
    name: 'Bitcoin Gold',
  },
  {
    id: 'bitcoin-inu',
    symbol: 'btcinu',
    name: 'Bitcoin Inu',
  },
  {
    id: 'bitcoinmono',
    symbol: 'btcmz',
    name: 'BitcoinMono',
  },
  {
    id: 'bitcoin-name-service-system',
    symbol: 'bnsx',
    name: 'Bitcoin Name Service System',
  },
  {
    id: 'bitcoin-pay',
    symbol: 'btcpay',
    name: 'Bitcoin Pay',
  },
  {
    id: 'bitcoinpepe',
    symbol: 'btcpep',
    name: 'BitcoinPepe',
  },
  {
    id: 'bitcoin-plus',
    symbol: 'xbc',
    name: 'Bitcoin Plus',
  },
  {
    id: 'bitcoinpos',
    symbol: 'btcs',
    name: 'BitcoinPoS',
  },
  {
    id: 'bitcoin-private',
    symbol: 'btcp',
    name: 'Bitcoin Private',
  },
  {
    id: 'bitcoin-pro',
    symbol: 'btcp',
    name: 'Bitcoin Pro',
  },
  {
    id: 'bitcoin-puppets-solona',
    symbol: 'puppet',
    name: 'bitcoin puppets solona',
  },
  {
    id: 'bitcoin-scrypt',
    symbol: 'btcs',
    name: 'Bitcoin Scrypt',
  },
  {
    id: 'bitcoin-subsidium',
    symbol: 'xbtx',
    name: 'Bitcoin Subsidium',
  },
  {
    id: 'bitcoin-trc20',
    symbol: 'btct',
    name: 'Bitcoin TRC20',
  },
  {
    id: 'bitcointry-token',
    symbol: 'btty',
    name: 'Bitcointry Token',
  },
  {
    id: 'bitcoinv',
    symbol: 'btcv',
    name: 'BitcoinV',
  },
  {
    id: 'bitcoin-vault',
    symbol: 'btcv',
    name: 'Bitcoin Vault',
  },
  {
    id: 'bitcoinvb',
    symbol: 'btcvb',
    name: 'BitcoinVB',
  },
  {
    id: 'bitcoin-wizards',
    symbol: 'wzrd',
    name: 'Bitcoin Wizards',
  },
  {
    id: 'bitcoinx',
    symbol: 'bcx',
    name: 'BitcoinX',
  },
  {
    id: 'bitcoinz',
    symbol: 'btcz',
    name: 'BitcoinZ',
  },
  {
    id: 'bitcoiva',
    symbol: 'bca',
    name: 'Bitcoiva',
  },
  {
    id: 'bitcone',
    symbol: 'cone',
    name: 'BitCone',
  },
  {
    id: 'bitconey',
    symbol: 'bitconey',
    name: 'BitConey',
  },
  {
    id: 'bitcore',
    symbol: 'btx',
    name: 'BitCore',
  },
  {
    id: 'bitdao',
    symbol: 'bit',
    name: 'BitDAO',
  },
  {
    id: 'bitenium-token',
    symbol: 'bt',
    name: 'Bitenium',
  },
  {
    id: 'bitflowers',
    symbol: 'petal',
    name: 'bitFlowers',
  },
  {
    id: 'bitforex',
    symbol: 'bf',
    name: 'Bitforex',
  },
  {
    id: 'bitgain',
    symbol: 'bgn',
    name: 'Bitgain',
  },
  {
    id: 'bit-game-verse-token',
    symbol: 'bgvt',
    name: 'Bit Game Verse Token',
  },
  {
    id: 'bitget-token',
    symbol: 'bgb',
    name: 'Bitget Token',
  },
  {
    id: 'bithachi',
    symbol: 'bith',
    name: 'Bithachi',
  },
  {
    id: 'bithash-token',
    symbol: 'bt',
    name: 'BitHash',
  },
  {
    id: 'bit-hotel',
    symbol: 'bth',
    name: 'Bit Hotel',
  },
  {
    id: 'bitica-coin',
    symbol: 'bdcc',
    name: 'BITICA COIN',
  },
  {
    id: 'bitkub-coin',
    symbol: 'kub',
    name: 'Bitkub Coin',
  },
  {
    id: 'bitlocus',
    symbol: 'btl',
    name: 'Bitlocus',
  },
  {
    id: 'bitmark',
    symbol: 'marks',
    name: 'Bitmark',
  },
  {
    id: 'bitmarkets-token',
    symbol: 'btmt',
    name: 'BITmarkets Token',
  },
  {
    id: 'bitmart-token',
    symbol: 'bmx',
    name: 'BitMart',
  },
  {
    id: 'bitmeme',
    symbol: 'btm',
    name: 'Bitmeme',
  },
  {
    id: 'bitmex-token',
    symbol: 'bmex',
    name: 'BitMEX',
  },
  {
    id: 'bitnet',
    symbol: 'btn',
    name: 'Bitnet',
  },
  {
    id: 'bitnet-io',
    symbol: 'bit',
    name: 'Bitnet IO',
  },
  {
    id: 'bitnex-ai',
    symbol: 'btx',
    name: 'Bitnex AI',
  },
  {
    id: 'bito-coin',
    symbol: 'bito',
    name: 'BITO Coin',
  },
  {
    id: 'bitone',
    symbol: 'bio',
    name: 'BITONE',
  },
  {
    id: 'bitorbit',
    symbol: 'bitorb',
    name: 'BitOrbit',
  },
  {
    id: 'bitoreum',
    symbol: 'btrm',
    name: 'Bitoreum',
  },
  {
    id: 'bitpanda-ecosystem-token',
    symbol: 'best',
    name: 'Bitpanda Ecosystem',
  },
  {
    id: 'bitpro',
    symbol: 'bpro',
    name: 'BitPRO',
  },
  {
    id: 'bitrise-token',
    symbol: 'brise',
    name: 'Bitgert',
  },
  {
    id: 'bitrock',
    symbol: 'brock',
    name: 'Bitrock',
  },
  {
    id: 'bitrue-token',
    symbol: 'btr',
    name: 'Bitrue Coin',
  },
  {
    id: 'bitscrow',
    symbol: 'btscrw',
    name: 'Bitscrow',
  },
  {
    id: 'bitscrunch-token',
    symbol: 'bcut',
    name: 'bitsCrunch Token',
  },
  {
    id: 'bitshares',
    symbol: 'bts',
    name: 'BitShares',
  },
  {
    id: 'bitshiba',
    symbol: 'shiba',
    name: 'BitShiba',
  },
  {
    id: 'bitsong',
    symbol: 'btsg',
    name: 'BitSong',
  },
  {
    id: 'bitspawn',
    symbol: 'spwn',
    name: 'Bitspawn',
  },
  {
    id: 'bitstable-finance',
    symbol: '$bssb',
    name: 'BitStable Finance',
  },
  {
    id: 'bitstarters',
    symbol: 'bits',
    name: 'BitStarters',
  },
  {
    id: 'bit-store-coin',
    symbol: 'store',
    name: 'Bit Store',
  },
  {
    id: 'bitsum',
    symbol: 'mat',
    name: 'Matka',
  },
  {
    id: 'bitswift',
    symbol: 'bits',
    name: 'Bitswift',
  },
  {
    id: 'bittensor',
    symbol: 'tao',
    name: 'Bittensor',
  },
  {
    id: 'bittoken',
    symbol: 'bitt',
    name: 'BITT',
  },
  {
    id: 'bittorrent',
    symbol: 'btt',
    name: 'BitTorrent',
  },
  {
    id: 'bittorrent-old',
    symbol: 'bttold',
    name: 'BitTorrent [OLD]',
  },
  {
    id: 'bittube',
    symbol: 'tube',
    name: 'BitTube',
  },
  {
    id: 'bittwatt',
    symbol: 'bwt',
    name: 'Bittwatt',
  },
  {
    id: 'bitvalley',
    symbol: 'bitv',
    name: 'BitValley',
  },
  {
    id: 'bitx',
    symbol: 'bitx',
    name: 'BitX',
  },
  {
    id: 'bitx-dex-ordinals',
    symbol: 'bxdx',
    name: 'BitX DEX (Ordinals)',
  },
  {
    id: 'bitxor',
    symbol: 'bxr',
    name: 'Bitxor',
  },
  {
    id: 'bitzipp',
    symbol: 'bzp',
    name: 'BitZipp',
  },
  {
    id: 'biu-coin',
    symbol: 'biu',
    name: 'BIU COIN',
  },
  {
    id: 'bivreost',
    symbol: 'bi',
    name: 'Bivreost',
  },
  {
    id: 'bizauto',
    symbol: 'biza',
    name: 'BizAuto',
  },
  {
    id: 'black',
    symbol: 'black',
    name: 'Black',
  },
  {
    id: 'blackcoin',
    symbol: 'blk',
    name: 'BlackCoin',
  },
  {
    id: 'blackdragon-token',
    symbol: 'bdt',
    name: 'BlackDragon',
  },
  {
    id: 'blackhat-coin',
    symbol: 'blkc',
    name: 'BlackHat Coin',
  },
  {
    id: 'black-hole',
    symbol: 'blh',
    name: 'Black Hole',
  },
  {
    id: 'blackhole-protocol',
    symbol: 'black',
    name: 'BlackHole Protocol',
  },
  {
    id: 'blackjack-fun',
    symbol: 'jack',
    name: 'Blackjack.fun',
  },
  {
    id: 'blacklatexfist',
    symbol: 'blf',
    name: 'BlackLatexFist',
  },
  {
    id: 'blackpearl-chain',
    symbol: 'bplc',
    name: 'BlackPearl',
  },
  {
    id: 'black-phoenix',
    symbol: 'bpx',
    name: 'Black Phoenix',
  },
  {
    id: 'blackpool-token',
    symbol: 'bpt',
    name: 'BlackPool',
  },
  {
    id: 'black-rabbit-ai',
    symbol: 'brain',
    name: 'Black Rabbit AI',
  },
  {
    id: 'blackrocktradingcurrency',
    symbol: 'btc',
    name: 'BlackrockTradingCurrency',
  },
  {
    id: 'black-sats-ordinals',
    symbol: 'bsat',
    name: 'Black Sats (Ordinals)',
  },
  {
    id: 'blacksmith-token',
    symbol: 'bs',
    name: 'Blacksmith Token',
  },
  {
    id: 'black-stallion',
    symbol: 'bs',
    name: 'Black Stallion',
  },
  {
    id: 'black-token',
    symbol: 'black',
    name: 'Black Token',
  },
  {
    id: 'blackwater-labs',
    symbol: 'bwl',
    name: 'Blackwater Labs',
  },
  {
    id: 'black-whale-2',
    symbol: 'xxx',
    name: 'Black Whale',
  },
  {
    id: 'blacky',
    symbol: 'blacky',
    name: 'Blacky',
  },
  {
    id: 'blank',
    symbol: 'blank',
    name: 'BlockWallet',
  },
  {
    id: 'blastar',
    symbol: 'blast',
    name: 'Blastar',
  },
  {
    id: 'blast-frontiers',
    symbol: 'blast',
    name: 'Blast Frontiers',
  },
  {
    id: 'blast-inu',
    symbol: 'blast',
    name: 'Blast Inu',
  },
  {
    id: 'blazebot',
    symbol: 'blaze',
    name: 'BlazeBot',
  },
  {
    id: 'blaze-network',
    symbol: 'blzn',
    name: 'Blaze Network',
  },
  {
    id: 'blazestake-staked-sol',
    symbol: 'bsol',
    name: 'BlazeStake Staked SOL',
  },
  {
    id: 'blazex',
    symbol: 'blazex',
    name: 'BlazeX',
  },
  {
    id: 'blend-protocol',
    symbol: 'blend',
    name: 'Blend Protocol',
  },
  {
    id: 'bless-global-credit',
    symbol: 'blec',
    name: 'Bless Global Credit',
  },
  {
    id: 'blind-boxes',
    symbol: 'bles',
    name: 'Blind Boxes',
  },
  {
    id: 'blin-metaverse',
    symbol: 'blin',
    name: 'Blin Metaverse',
  },
  {
    id: 'blinq-network',
    symbol: 'blinq',
    name: 'Blinq Network',
  },
  {
    id: 'blithe',
    symbol: 'blt',
    name: 'Blithe',
  },
  {
    id: 'blitz-bots',
    symbol: 'blitz',
    name: 'Blitz Bots',
  },
  {
    id: 'blitz-labs',
    symbol: 'blitz',
    name: 'Blitz Labs',
  },
  {
    id: 'blitzpredict',
    symbol: 'xbp',
    name: 'BlitzPick',
  },
  {
    id: 'blizzard-network',
    symbol: 'blizz',
    name: 'Blizzard Network',
  },
  {
    id: 'blob',
    symbol: 'blob',
    name: 'Blob Protocol',
  },
  {
    id: 'blobcoin',
    symbol: 'blob',
    name: 'BLOBCOIN',
  },
  {
    id: 'blocery',
    symbol: 'bly',
    name: 'Blocery',
  },
  {
    id: 'block',
    symbol: 'block',
    name: 'Block',
  },
  {
    id: 'block-0',
    symbol: 'block-0',
    name: 'Block-0',
  },
  {
    id: 'block-ape-scissors',
    symbol: 'arcas',
    name: 'Arcas',
  },
  {
    id: 'blockasset',
    symbol: 'block',
    name: 'Blockasset',
  },
  {
    id: 'blockbank',
    symbol: 'bbank',
    name: 'blockbank',
  },
  {
    id: 'blockbase',
    symbol: 'bbt',
    name: 'BlockBase',
  },
  {
    id: 'block-beats-network',
    symbol: 'bbdc',
    name: 'Block Beats Network',
  },
  {
    id: 'blockblend',
    symbol: 'bbl',
    name: 'BlockBlend [OLD]',
  },
  {
    id: 'blockblend-2',
    symbol: 'bbl',
    name: 'BlockBlend',
  },
  {
    id: 'blockbox',
    symbol: 'bbox',
    name: 'BlockBox',
  },
  {
    id: 'block-browser',
    symbol: 'block',
    name: 'Block Browser',
  },
  {
    id: 'blockbyblock',
    symbol: 'bxb',
    name: 'BlockbyBlock',
  },
  {
    id: 'blockcdn',
    symbol: 'bcdn',
    name: 'BlockCDN',
  },
  {
    id: 'blockchain-bets',
    symbol: 'bcb',
    name: 'Blockchain Bets',
  },
  {
    id: 'blockchain-brawlers',
    symbol: 'brwl',
    name: 'Blockchain Brawlers',
  },
  {
    id: 'blockchain-certified-data-token',
    symbol: 'bcdt',
    name: 'EvidenZ',
  },
  {
    id: 'blockchaincoinx',
    symbol: 'xccx',
    name: 'BlockChainCoinX',
  },
  {
    id: 'blockchain-cuties-universe-governance',
    symbol: 'bcug',
    name: 'Blockchain Cuties Universe Governance',
  },
  {
    id: 'blockchain-island',
    symbol: 'bcl',
    name: 'Blockchain Island',
  },
  {
    id: 'blockchain-monster-hunt',
    symbol: 'bcmc',
    name: 'Blockchain Monster Hunt',
  },
  {
    id: 'blockchainpoland',
    symbol: 'bcp',
    name: 'BlockchainPoland',
  },
  {
    id: 'blockchainspace',
    symbol: 'guild',
    name: 'BlockchainSpace',
  },
  {
    id: 'blockcreate',
    symbol: 'block',
    name: 'BlockCreate',
  },
  {
    id: 'blockescrow',
    symbol: 'bet',
    name: 'BlockEscrow',
  },
  {
    id: 'blockgpt',
    symbol: 'bgpt',
    name: 'BlockGPT',
  },
  {
    id: 'blockjack',
    symbol: 'jack',
    name: 'BlockJack',
  },
  {
    id: 'blockless',
    symbol: 'bls',
    name: 'Blockless',
  },
  {
    id: 'blocklords',
    symbol: 'lrds',
    name: 'BLOCKLORDS',
  },
  {
    id: 'blocknet',
    symbol: 'block',
    name: 'Blocknet',
  },
  {
    id: 'blockport',
    symbol: 'bux',
    name: 'BUX',
  },
  {
    id: 'blockremit',
    symbol: 'remit',
    name: 'BlockRemit',
  },
  {
    id: 'blockrock',
    symbol: 'bro$',
    name: 'BlockRock',
  },
  {
    id: 'blockrock-2',
    symbol: 'fed',
    name: 'BlockRock',
  },
  {
    id: 'blocks',
    symbol: 'blocks',
    name: 'BLOCKS',
  },
  {
    id: 'blockscape',
    symbol: 'blc',
    name: 'Blockscape',
  },
  {
    id: 'blocksmith-labs-forge',
    symbol: '$forge',
    name: '$FORGE',
  },
  {
    id: 'blocksport',
    symbol: 'bspt',
    name: 'Blocksport',
  },
  {
    id: 'blockspot-network',
    symbol: 'spot',
    name: 'BlockSpot Network',
  },
  {
    id: 'blocksquare',
    symbol: 'bst',
    name: 'Blocksquare',
  },
  {
    id: 'blockstack',
    symbol: 'stx',
    name: 'Stacks',
  },
  {
    id: 'blockstar',
    symbol: 'bst',
    name: 'BlockStar',
  },
  {
    id: 'blockster',
    symbol: 'bxr',
    name: 'Blockster',
  },
  {
    id: 'blocksworkz',
    symbol: 'blkz',
    name: 'BlocksWorkz',
  },
  {
    id: 'blockton',
    symbol: 'bton',
    name: 'Blockton',
  },
  {
    id: 'blocktools',
    symbol: 'tools',
    name: 'Blocktools',
  },
  {
    id: 'blocktrade-exchange',
    symbol: 'btex',
    name: 'BTEX',
  },
  {
    id: 'blockv',
    symbol: 'vee',
    name: 'BLOCKv',
  },
  {
    id: 'blockx',
    symbol: 'bcx',
    name: 'BlockX',
  },
  {
    id: 'bloc-money',
    symbol: 'bloc',
    name: 'Bloc.Money',
  },
  {
    id: 'blocsport-one',
    symbol: 'bls',
    name: 'Metacourt',
  },
  {
    id: 'blocto-token',
    symbol: 'blt',
    name: 'Blocto',
  },
  {
    id: 'blocx',
    symbol: 'blx',
    name: 'BlocX [OLD]',
  },
  {
    id: 'blocx-2',
    symbol: 'blocx',
    name: 'BLOCX.',
  },
  {
    id: 'blocx-3',
    symbol: 'blx',
    name: 'BlocX',
  },
  {
    id: 'blokpad',
    symbol: 'bpad',
    name: 'BlokPad',
  },
  {
    id: 'bloktopia',
    symbol: 'blok',
    name: 'Bloktopia',
  },
  {
    id: 'bloody-bunny',
    symbol: 'bony',
    name: 'Bloody Bunny',
  },
  {
    id: 'bloom',
    symbol: 'blt',
    name: 'Bloom',
  },
  {
    id: 'blorp',
    symbol: 'blorp',
    name: 'BLORP',
  },
  {
    id: 'blox',
    symbol: 'cdt',
    name: 'Blox',
  },
  {
    id: 'blox-2',
    symbol: 'blox',
    name: 'BLOX',
  },
  {
    id: 'bloxies-coin',
    symbol: 'bxc',
    name: 'BitcoinX',
  },
  {
    id: 'bloxmove-erc20',
    symbol: 'blxm',
    name: 'bloXmove',
  },
  {
    id: 'blox-token',
    symbol: 'blox',
    name: 'Blox SDK',
  },
  {
    id: 'blu',
    symbol: 'blu',
    name: 'BLU',
  },
  {
    id: 'blubird',
    symbol: 'blu',
    name: 'Blubird',
  },
  {
    id: 'blueart',
    symbol: 'bla',
    name: 'BLUEART TOKEN',
  },
  {
    id: 'blue-baikal',
    symbol: 'bbc',
    name: 'Blue Baikal',
  },
  {
    id: 'bluebenx-2',
    symbol: 'benx',
    name: 'BlueBenx',
  },
  {
    id: 'bluefin',
    symbol: 'blue',
    name: 'Bluefin',
  },
  {
    id: 'blue-frog',
    symbol: 'bluefrog',
    name: 'Blue Frog',
  },
  {
    id: 'bluejay',
    symbol: 'blu',
    name: 'Bluejay',
  },
  {
    id: 'blue-kirby',
    symbol: 'kirby',
    name: 'Blue Kirby',
  },
  {
    id: 'bluelight',
    symbol: 'kale',
    name: 'Bluelight',
  },
  {
    id: 'bluemove',
    symbol: 'move',
    name: 'BlueMove',
  },
  {
    id: 'blue-pill',
    symbol: 'bpill',
    name: 'BLUE PILL',
  },
  {
    id: 'bluesale',
    symbol: 'bls',
    name: 'BlueSale',
  },
  {
    id: 'blueshift',
    symbol: 'blues',
    name: 'Blueshift',
  },
  {
    id: 'bluesparrow',
    symbol: 'bluesparrow',
    name: 'BlueSparrow',
  },
  {
    id: 'bluesparrow-token',
    symbol: 'bluesparrow',
    name: 'BlueSparrow [OLD]',
  },
  {
    id: 'blue-team',
    symbol: 'blue',
    name: 'Blue Team',
  },
  {
    id: 'blui',
    symbol: 'blui',
    name: 'Blui',
  },
  {
    id: 'blur',
    symbol: 'blur',
    name: 'Blur',
  },
  {
    id: 'blurt',
    symbol: 'blurt',
    name: 'Blurt',
  },
  {
    id: 'bluzelle',
    symbol: 'blz',
    name: 'Bluzelle',
  },
  {
    id: 'bm2k',
    symbol: 'bm2k',
    name: 'bm2k',
  },
  {
    id: 'bmax',
    symbol: 'bmax',
    name: 'BMAX',
  },
  {
    id: 'bmchain-token',
    symbol: 'bmt',
    name: 'BMCHAIN',
  },
  {
    id: 'bmp',
    symbol: '$bmp',
    name: 'BMP',
  },
  {
    id: 'bmx',
    symbol: 'bmx',
    name: 'BMX',
  },
  {
    id: 'bnb48-club-token',
    symbol: 'koge',
    name: 'KOGE',
  },
  {
    id: 'bnb-bank',
    symbol: 'bbk',
    name: 'BNB Bank',
  },
  {
    id: 'bnb-diamond',
    symbol: 'bnbd',
    name: 'BNB Diamond',
  },
  {
    id: 'bnbee',
    symbol: 'bee',
    name: 'BNBEE',
  },
  {
    id: 'bnbking',
    symbol: 'bnbking',
    name: 'BNBKinG',
  },
  {
    id: 'bnb-pets',
    symbol: 'pets',
    name: 'BNB Pets',
  },
  {
    id: 'bnbtiger',
    symbol: 'bnbtiger',
    name: 'BNB Tiger Inu',
  },
  {
    id: 'bnb-tiger',
    symbol: '$bnbtiger',
    name: 'BNB Tiger',
  },
  {
    id: 'bnb-whales',
    symbol: 'bnb whales',
    name: 'BNB Whales',
  },
  {
    id: 'bnext-b3x',
    symbol: 'b3x',
    name: 'Bnext B3X',
  },
  {
    id: 'bnktothefuture',
    symbol: 'bft',
    name: 'BnkToTheFuture',
  },
  {
    id: 'bnsd-finance',
    symbol: 'bnsd',
    name: 'BNSD Finance',
  },
  {
    id: 'bns-token',
    symbol: 'bns',
    name: 'BNS',
  },
  {
    id: 'bob',
    symbol: 'bob',
    name: 'BOB',
  },
  {
    id: 'bobacat',
    symbol: 'psps',
    name: 'BobaCat',
  },
  {
    id: 'boba-finance',
    symbol: 'bfi',
    name: 'Boba Finance',
  },
  {
    id: 'boba-network',
    symbol: 'boba',
    name: 'Boba Network',
  },
  {
    id: 'bobcoin',
    symbol: 'bobc',
    name: 'Bobcoin',
  },
  {
    id: 'bobi',
    symbol: 'bobi',
    name: 'Bobi',
  },
  {
    id: 'bobo',
    symbol: 'bobo',
    name: 'Bobo',
  },
  {
    id: 'bobo-coin',
    symbol: 'bobo',
    name: 'BOBO Coin',
  },
  {
    id: 'bobs',
    symbol: 'bobs',
    name: 'BOBS',
  },
  {
    id: 'bobs_repair',
    symbol: 'bob',
    name: "Bob's Repair",
  },
  {
    id: 'bob-token',
    symbol: 'bob',
    name: 'BOB Token',
  },
  {
    id: 'bocachica',
    symbol: 'chica',
    name: 'BocaChica',
  },
  {
    id: 'boda-token',
    symbol: 'bodav2',
    name: 'BODA',
  },
  {
    id: 'bodrumspor-fan-token',
    symbol: 'bdrm',
    name: 'Bodrumspor Fan Token',
  },
  {
    id: 'body-ai',
    symbol: 'bait',
    name: 'Body Ai',
  },
  {
    id: 'bogdanoff',
    symbol: 'bog',
    name: 'Bogdanoff',
  },
  {
    id: 'bogged-finance',
    symbol: 'bog',
    name: 'Bogged Finance',
  },
  {
    id: 'bojack',
    symbol: '$bojack',
    name: 'BOJACK',
  },
  {
    id: 'boku',
    symbol: 'boku',
    name: 'Boryoku Dragonz',
  },
  {
    id: 'bolic-ai',
    symbol: 'boai',
    name: 'Bolic AI',
  },
  {
    id: 'bolide',
    symbol: 'blid',
    name: 'Bolide',
  },
  {
    id: 'bolivarcoin',
    symbol: 'boli',
    name: 'Bolivarcoin',
  },
  {
    id: 'bollycoin',
    symbol: 'bolly',
    name: 'BollyCoin',
  },
  {
    id: 'bologna-fc-fan-token',
    symbol: 'bfc',
    name: 'Bologna FC Fan Token',
  },
  {
    id: 'bolt',
    symbol: 'bolt',
    name: 'Bolt',
  },
  {
    id: 'boltbot',
    symbol: 'bolt',
    name: 'BoltBot',
  },
  {
    id: 'bolt-token-023ba86e-eb38-41a1-8d32-8b48ecfcb2c7',
    symbol: '$bolt',
    name: 'Bolt Token',
  },
  {
    id: 'bomb',
    symbol: 'bomb',
    name: 'BOMB',
  },
  {
    id: 'bombcrypto-coin',
    symbol: 'bomb',
    name: 'Bombcrypto Coin',
  },
  {
    id: 'bomber-coin',
    symbol: 'bcoin',
    name: 'BombCrypto',
  },
  {
    id: 'bomb-money',
    symbol: 'bomb',
    name: 'Bomb Money',
  },
  {
    id: 'bonded-cronos',
    symbol: 'bcro',
    name: 'Bonded Cronos',
  },
  {
    id: 'bondly',
    symbol: 'bondly',
    name: 'Forj',
  },
  {
    id: 'bone-2',
    symbol: 'bone',
    name: 'Bone',
  },
  {
    id: 'boner',
    symbol: '$boner',
    name: 'BONER',
  },
  {
    id: 'bonerium-boneswap',
    symbol: 'bswp',
    name: 'Bonerium BoneSwap',
  },
  {
    id: 'bones',
    symbol: 'bones',
    name: 'Bones',
  },
  {
    id: 'bone-shibaswap',
    symbol: 'bone',
    name: 'Bone ShibaSwap',
  },
  {
    id: 'boneswap',
    symbol: 'bone',
    name: 'BoneSwap',
  },
  {
    id: 'bone-token',
    symbol: 'bone',
    name: 'PolyPup Bone',
  },
  {
    id: 'bonex',
    symbol: 'bonex',
    name: 'BONEX',
  },
  {
    id: 'bonfida',
    symbol: 'fida',
    name: 'Bonfida',
  },
  {
    id: 'bonfire',
    symbol: 'bonfire',
    name: 'Bonfire',
  },
  {
    id: 'bong-bonk-s-brother',
    symbol: 'bong',
    name: "BONG BONK'S BROTHER",
  },
  {
    id: 'bongo-cat',
    symbol: 'bongo',
    name: 'BONGO CAT',
  },
  {
    id: 'bongweedcoin',
    symbol: 'bwc',
    name: 'BongWeedCoin',
  },
  {
    id: 'bonk',
    symbol: 'bonk',
    name: 'Bonk',
  },
  {
    id: 'bonk-2-0',
    symbol: 'bonk 2.0',
    name: 'Bonk 2.0',
  },
  {
    id: 'bonk2-0',
    symbol: 'bonk2.0',
    name: 'Bonk2.0',
  },
  {
    id: 'bonkbaby',
    symbol: 'boby',
    name: 'BonkBaby',
  },
  {
    id: 'bonkbest',
    symbol: 'bonkbest',
    name: 'BONKBEST',
  },
  {
    id: 'bonk-bitcoin',
    symbol: 'bonk',
    name: 'BONK BITCOIN (Ordinals)',
  },
  {
    id: 'bonkcola',
    symbol: 'bonkcola',
    name: 'BonkCola',
  },
  {
    id: 'bonkearn',
    symbol: 'bern',
    name: 'BonkEarn',
  },
  {
    id: 'bonk-grok',
    symbol: 'bonkgrok',
    name: 'Bonk Grok',
  },
  {
    id: 'bonkinu',
    symbol: 'bonkinu',
    name: 'Bonkinu',
  },
  {
    id: 'bonk-inu',
    symbol: 'bonki',
    name: 'BONK Inu',
  },
  {
    id: 'bonklana',
    symbol: 'bok',
    name: 'BONKLANA',
  },
  {
    id: 'bonsai3',
    symbol: 'seed',
    name: 'Bonsai3',
  },
  {
    id: 'bontecoin',
    symbol: 'bonte',
    name: 'Bontecoin',
  },
  {
    id: 'bonyta',
    symbol: 'bnyta',
    name: 'Bonyta',
  },
  {
    id: 'boo-2',
    symbol: '$boo',
    name: 'BOO',
  },
  {
    id: 'boo-finance',
    symbol: 'boofi',
    name: 'Boo Finance',
  },
  {
    id: 'book-2',
    symbol: 'book',
    name: 'BOOK',
  },
  {
    id: 'book-3',
    symbol: 'book',
    name: 'BOOK',
  },
  {
    id: 'bookiebot',
    symbol: 'bb',
    name: 'BookieBot',
  },
  {
    id: 'boolran',
    symbol: 'bool',
    name: 'BoolRan',
  },
  {
    id: 'boo-mirrorworld',
    symbol: 'xboo',
    name: 'Boo MirrorWorld',
  },
  {
    id: 'boop',
    symbol: 'boop',
    name: 'Boop',
  },
  {
    id: 'boop-2',
    symbol: 'boop',
    name: 'Boop',
  },
  {
    id: 'boost',
    symbol: 'boost',
    name: 'Boost',
  },
  {
    id: 'boosted-lusd',
    symbol: 'blusd',
    name: 'Boosted LUSD',
  },
  {
    id: 'booster',
    symbol: 'boo',
    name: 'Booster',
  },
  {
    id: 'booty',
    symbol: 'booty',
    name: 'BOOTY',
  },
  {
    id: 'bora',
    symbol: 'bora',
    name: 'BORA',
  },
  {
    id: 'borderless-money',
    symbol: 'bom',
    name: 'Borderless Money',
  },
  {
    id: 'borealis',
    symbol: 'brl',
    name: 'Borealis',
  },
  {
    id: 'bored',
    symbol: '$bored',
    name: 'Bored Token',
  },
  {
    id: 'bored-ape-social-club',
    symbol: 'bape',
    name: 'Bored Ape Social Club',
  },
  {
    id: 'bored-candy-city',
    symbol: 'candy',
    name: 'Bored Candy City',
  },
  {
    id: 'boringdao',
    symbol: 'boring',
    name: 'BoringDAO',
  },
  {
    id: 'boringdao-[old]',
    symbol: 'bor',
    name: 'BoringDAO [OLD]',
  },
  {
    id: 'boring-protocol',
    symbol: 'bop',
    name: 'Boring Protocol',
  },
  {
    id: 'bork-2',
    symbol: 'bork',
    name: 'Bork',
  },
  {
    id: 'bork-coin',
    symbol: 'bork',
    name: 'Bork Coin',
  },
  {
    id: 'borzoi-coin',
    symbol: 'borzoi',
    name: 'Borzoi Coin',
  },
  {
    id: 'bosagora',
    symbol: 'boa',
    name: 'BOSagora',
  },
  {
    id: 'boson-protocol',
    symbol: 'boson',
    name: 'Boson Protocol',
  },
  {
    id: 'boss',
    symbol: 'boss',
    name: 'Boss',
  },
  {
    id: 'boss-blockchain',
    symbol: 'bbc',
    name: 'Boss Blockchain',
  },
  {
    id: 'bossswap',
    symbol: 'boss',
    name: 'Boss Swap',
  },
  {
    id: 'bostrom',
    symbol: 'boot',
    name: 'Bostrom',
  },
  {
    id: 'botccoin-chain',
    symbol: 'botc',
    name: 'Botccoin Chain',
  },
  {
    id: 'bot-compiler',
    symbol: 'botc',
    name: 'Bot Compiler',
  },
  {
    id: 'botopiafinance',
    symbol: 'btop',
    name: 'BotopiaFinance',
  },
  {
    id: 'bot-planet',
    symbol: 'bot',
    name: 'Bot Planet',
  },
  {
    id: 'botto',
    symbol: 'botto',
    name: 'Botto',
  },
  {
    id: 'bottos',
    symbol: 'bto',
    name: 'Bottos',
  },
  {
    id: 'botx',
    symbol: 'botx',
    name: 'BotX',
  },
  {
    id: 'botxcoin',
    symbol: 'botx',
    name: 'BOTXCOIN',
  },
  {
    id: 'bouncing-dvd',
    symbol: 'dvd',
    name: 'Bouncing DVD',
  },
  {
    id: 'bouncing-seals',
    symbol: 'seals',
    name: 'Bouncing Seals',
  },
  {
    id: 'bountie-hunter',
    symbol: 'bountie',
    name: 'Bountie Hunter',
  },
  {
    id: 'bounty0x',
    symbol: 'bnty',
    name: 'Bounty0x',
  },
  {
    id: 'bountykinds-yu',
    symbol: 'yu',
    name: 'BountyKinds YU',
  },
  {
    id: 'bountymarketcap',
    symbol: 'bmc',
    name: 'BountyMarketCap',
  },
  {
    id: 'bovineverse-bvt',
    symbol: 'bvt',
    name: 'Bovineverse BVT',
  },
  {
    id: 'bowie',
    symbol: 'bowie',
    name: 'Bowie',
  },
  {
    id: 'boxbet',
    symbol: 'bxbt',
    name: 'BoxBet',
  },
  {
    id: 'box-dao',
    symbol: 'b-dao',
    name: 'Box-DAO',
  },
  {
    id: 'boxydude',
    symbol: 'box',
    name: 'BoxyDude',
  },
  {
    id: 'bozo-collective',
    symbol: 'bozo',
    name: 'Bozo Collective',
  },
  {
    id: 'bozo-hybrid',
    symbol: 'bozo',
    name: 'bozo Hybrid',
  },
  {
    id: 'b-protocol',
    symbol: 'bpro',
    name: 'B.Protocol',
  },
  {
    id: 'bracelet',
    symbol: 'brc',
    name: 'Bracelet',
  },
  {
    id: 'brain-sync',
    symbol: 'syncbrain',
    name: 'Brain Sync',
  },
  {
    id: 'braintrust',
    symbol: 'btrst',
    name: 'Braintrust',
  },
  {
    id: 'brandpad-finance',
    symbol: 'brand',
    name: 'BrandPad Finance',
  },
  {
    id: 'brave-power-crystal',
    symbol: 'bpc',
    name: 'Brave Power Crystal',
  },
  {
    id: 'brazil-fan-token',
    symbol: 'bft',
    name: 'Brazil National Football Team Fan Token',
  },
  {
    id: 'brc20-bot',
    symbol: 'brcbot',
    name: 'BRC20 BOT',
  },
  {
    id: 'brc-app',
    symbol: 'brct',
    name: 'BRC App',
  },
  {
    id: 'brcexchange',
    symbol: 'bex',
    name: 'BrcExchange',
  },
  {
    id: 'brc-on-the-erc',
    symbol: 'brc20',
    name: 'BRC on the ERC',
  },
  {
    id: 'brcp-token',
    symbol: 'brcp',
    name: 'BRCP',
  },
  {
    id: 'brd',
    symbol: 'brd',
    name: 'Board',
  },
  {
    id: 'bread',
    symbol: 'brd',
    name: 'Bread',
  },
  {
    id: 'breederdao',
    symbol: 'breed',
    name: 'BreederDAO',
  },
  {
    id: 'brepe',
    symbol: 'brepe',
    name: 'BREPE',
  },
  {
    id: 'brett',
    symbol: 'brett',
    name: 'Brett',
  },
  {
    id: 'brett-injective',
    symbol: 'brett',
    name: 'BRETT (Injective)',
  },
  {
    id: 'brewlabs',
    symbol: 'brewlabs',
    name: 'Brewlabs',
  },
  {
    id: 'brianarmstrongtrumpyellen',
    symbol: 'coin',
    name: 'BrianArmstrongTrumpYellenGTA6',
  },
  {
    id: 'brick',
    symbol: 'brick',
    name: 'r/FortNiteBR Bricks',
  },
  {
    id: 'brick-by-brick',
    symbol: 'brick',
    name: 'Brick by Brick',
  },
  {
    id: 'brickken',
    symbol: 'bkn',
    name: 'Brickken',
  },
  {
    id: 'brick-token',
    symbol: 'brick',
    name: 'Brick',
  },
  {
    id: 'brics-chain',
    symbol: 'brics',
    name: 'BRICS Chain',
  },
  {
    id: 'bridgador',
    symbol: 'gador',
    name: 'Bridgador',
  },
  {
    id: 'bridge-bot',
    symbol: 'bridge',
    name: 'Bridge Bot',
  },
  {
    id: 'bridged-arbitrum-lightlink',
    symbol: 'arb.e',
    name: 'Bridged Arbitrum (Lightlink)',
  },
  {
    id: 'bridged-axelar-wrapped-usd-coin-scroll',
    symbol: 'axlusdc',
    name: 'Bridged Axelar Wrapped USD Coin (Scroll)',
  },
  {
    id: 'bridged-binance-peg-ethereum-opbnb',
    symbol: 'eth',
    name: 'Bridged Binance-Peg Ethereum (opBNB)',
  },
  {
    id: 'bridged-chainlink-lightlink',
    symbol: 'link.e',
    name: 'Bridged Chainlink (Lightlink)',
  },
  {
    id: 'bridged-curve-dao-token-stargate',
    symbol: 'crv',
    name: 'Bridged Curve DAO Token (Stargate)',
  },
  {
    id: 'bridged-dai-lightlink',
    symbol: 'dai.e',
    name: 'Bridged Dai (Lightlink)',
  },
  {
    id: 'bridged-dai-stablecoin-hashport',
    symbol: 'dai[hts]',
    name: 'Bridged Dai Stablecoin (Hashport)',
  },
  {
    id: 'bridged-dai-stablecoin-linea',
    symbol: 'dai',
    name: 'Bridged Dai Stablecoin (Linea)',
  },
  {
    id: 'bridged-dai-stablecoin-manta-pacific',
    symbol: 'dai',
    name: 'Bridged Dai Stablecoin (Manta Pacific)',
  },
  {
    id: 'bridged-dai-stablecoin-stargate',
    symbol: 'dai',
    name: 'Bridged Dai Stablecoin (Stargate)',
  },
  {
    id: 'bridged-dai-stablecoin-ton-bridge',
    symbol: 'jdai',
    name: 'Bridged Dai Stablecoin (TON Bridge)',
  },
  {
    id: 'bridged-dai-starkgate',
    symbol: 'dai',
    name: 'Bridged Dai Stablecoin (StarkGate)',
  },
  {
    id: 'bridged-dovu-hashport',
    symbol: 'dov[hts]',
    name: 'Bridged Dovu (Hashport)',
  },
  {
    id: 'bridged-kyber-network-crystal-bsc',
    symbol: 'knc_b',
    name: 'Bridged Kyber Network Crystal (BSC)',
  },
  {
    id: 'bridged-kyber-network-crystal-ethereum',
    symbol: 'knc_e',
    name: 'Bridged Kyber Network Crystal (Ethereum)',
  },
  {
    id: 'bridged-mantra-hashport',
    symbol: 'om[hts]',
    name: 'Bridged MANTRA (Hashport)',
  },
  {
    id: 'bridged-pepe-hashport',
    symbol: 'pepe[hts]',
    name: 'Bridged Pepe (Hashport)',
  },
  {
    id: 'bridged-polygon-lightlink',
    symbol: 'matic.e',
    name: 'Bridged Polygon (Lightlink)',
  },
  {
    id: 'bridged-rocket-pool-eth-manta-pacific',
    symbol: 'reth',
    name: 'Bridged Rocket Pool ETH (Manta Pacific)',
  },
  {
    id: 'bridged-sommelier-axelar',
    symbol: 'axlsomm',
    name: 'Bridged Sommelier (Axelar)',
  },
  {
    id: 'bridged-tether-fuse',
    symbol: 'usdt',
    name: 'Bridged Tether (Fuse)',
  },
  {
    id: 'bridged-tether-hashport',
    symbol: 'usdt[hts]',
    name: 'Bridged Tether (Hashport)',
  },
  {
    id: 'bridged-tether-lightlink',
    symbol: 'usdt.e',
    name: 'Bridged Tether (Lightlink)',
  },
  {
    id: 'bridged-tether-linea',
    symbol: 'usdt',
    name: 'Bridged Tether (Linea)',
  },
  {
    id: 'bridged-tether-manta-pacific',
    symbol: 'usdt',
    name: 'Bridged Tether (Manta Pacific)',
  },
  {
    id: 'bridged-tether-opbnb',
    symbol: 'usdt',
    name: 'Bridged Tether (opBNB)',
  },
  {
    id: 'bridged-tether-scroll',
    symbol: 'usdt',
    name: 'Bridged Tether (Scroll)',
  },
  {
    id: 'bridged-tether-stargate',
    symbol: 'usdt',
    name: 'Bridged Tether (Stargate)',
  },
  {
    id: 'bridged-tether-starkgate',
    symbol: 'usdt',
    name: 'Bridged Tether (StarkGate)',
  },
  {
    id: 'bridged-tether-ton-bridge',
    symbol: 'jusdt',
    name: 'Bridged Tether (TON Bridge)',
  },
  {
    id: 'bridged-tia-hyperlane',
    symbol: 'tia.n',
    name: 'Bridged TIA (Hyperlane)',
  },
  {
    id: 'bridged-trueusd',
    symbol: 'tusd',
    name: 'Bridged TrueUSD',
  },
  {
    id: 'bridged-uniswap-lightlink',
    symbol: 'uni.e',
    name: 'Bridged Uniswap (Lightlink)',
  },
  {
    id: 'bridged-usdc-chainport',
    symbol: 'usdc',
    name: 'Bridged USDC (Chainport)',
  },
  {
    id: 'bridged-usdc-core',
    symbol: 'usdc',
    name: 'Bridged USDC (Core)',
  },
  {
    id: 'bridged-usdc-fuse',
    symbol: 'usdc',
    name: 'Bridged USDC (Fuse)',
  },
  {
    id: 'bridged-usdc-lightlink',
    symbol: 'usdc.e',
    name: 'Bridged USDC (Lightlink)',
  },
  {
    id: 'bridged-usd-coin-base',
    symbol: 'usdbc',
    name: 'Bridged USD Coin (Base)',
  },
  {
    id: 'bridged-usd-coin-linea',
    symbol: 'usdc',
    name: 'Bridged USD Coin (Linea)',
  },
  {
    id: 'bridged-usd-coin-manta-pacific',
    symbol: 'usdc',
    name: 'Bridged USD Coin (Manta Pacific)',
  },
  {
    id: 'bridged-usd-coin-optimism',
    symbol: 'usdc.e',
    name: 'Bridged USDC (Optimism)',
  },
  {
    id: 'bridged-usd-coin-scroll',
    symbol: 'usdc',
    name: 'Bridged USD Coin (Scroll)',
  },
  {
    id: 'bridged-usd-coin-starkgate',
    symbol: 'usdc',
    name: 'Bridged USD Coin (StarkGate)',
  },
  {
    id: 'bridged-usd-coin-ton-bridge',
    symbol: 'jusdc',
    name: 'Bridged USD Coin (TON Bridge)',
  },
  {
    id: 'bridged-usdc-polygon-pos-bridge',
    symbol: 'usdc.e',
    name: 'Bridged USDC (Polygon PoS Bridge)',
  },
  {
    id: 'bridged-usdt-core',
    symbol: 'usdt',
    name: 'Bridged USDT (Core)',
  },
  {
    id: 'bridged-wrapped-agora-genesis-bridge',
    symbol: 'wagora',
    name: 'Bridged Wrapped AGORA (Genesis Bridge)',
  },
  {
    id: 'bridged-wrapped-bitcoin-hashport',
    symbol: 'wbtc[hts]',
    name: 'Bridged Wrapped Bitcoin (Hashport)',
  },
  {
    id: 'bridged-wrapped-bitcoin-manta-pacific',
    symbol: 'wbtc',
    name: 'Bridged Wrapped Bitcoin (Manta Pacific)',
  },
  {
    id: 'bridged-wrapped-bitcoin-scroll',
    symbol: 'wbtc',
    name: 'Bridged Wrapped Bitcoin (Scroll)',
  },
  {
    id: 'bridged-wrapped-bitcoin-stargate',
    symbol: 'wbtc',
    name: 'Bridged Wrapped Bitcoin (Stargate)',
  },
  {
    id: 'bridged-wrapped-bitcoin-starkgate',
    symbol: 'wbtc',
    name: 'Bridged Wrapped Bitcoin (StarkGate)',
  },
  {
    id: 'bridged-wrapped-bitcoin-ton-bridge',
    symbol: 'jwbtc',
    name: 'Bridged Wrapped Bitcoin (TON Bridge)',
  },
  {
    id: 'bridged-wrapped-btc-lightlink',
    symbol: 'wbtc.e',
    name: 'Bridged Wrapped BTC (Lightlink)',
  },
  {
    id: 'bridged-wrapped-ether-fuse',
    symbol: 'weth',
    name: 'Bridged Wrapped Ether (Fuse)',
  },
  {
    id: 'bridged-wrapped-ether-hashport',
    symbol: 'weth[hts]',
    name: 'Bridged Wrapped Ether (Hashport)',
  },
  {
    id: 'bridged-wrapped-ether-im-bridge',
    symbol: 'seth',
    name: 'Bridged Wrapped Ether (IM Bridge)',
  },
  {
    id: 'bridged-wrapped-ether-manta-pacific',
    symbol: 'weth',
    name: 'Bridged Wrapped Ether (Manta Pacific)',
  },
  {
    id: 'bridged-wrapped-ether-scroll',
    symbol: 'weth',
    name: 'Bridged Wrapped Ether (Scroll)',
  },
  {
    id: 'bridged-wrapped-ether-stargate',
    symbol: 'weth',
    name: 'Bridged Wrapped Ether (Stargate)',
  },
  {
    id: 'bridged-wrapped-ether-starkgate',
    symbol: 'eth',
    name: 'Bridged Ether (StarkGate)',
  },
  {
    id: 'bridged-wrapped-ether-voltage-finance',
    symbol: 'weth',
    name: 'Bridged Wrapped Ether (Voltage Finance)',
  },
  {
    id: 'bridged-wrapped-hbar-heliswap',
    symbol: 'whbar',
    name: 'Wrapped HBAR (HeliSwap)',
  },
  {
    id: 'bridged-wrapped-lido-staked-ether-scroll',
    symbol: 'wsteth',
    name: 'Bridged Wrapped Lido Staked Ether (Scroll)',
  },
  {
    id: 'bridged-wrapped-matic-hashport',
    symbol: 'wmatic[hts]',
    name: 'Bridged Wrapped MATIC (Hashport)',
  },
  {
    id: 'bridged-wrapped-steth-axelar',
    symbol: 'axl-wsteth',
    name: 'Bridged Wrapped stETH (Axelar)',
  },
  {
    id: 'bridged-wrapped-steth-gnosis',
    symbol: 'wsteth',
    name: 'Bridged Wrapped stETH (Gnosis)',
  },
  {
    id: 'bridged-wrapped-steth-manta-pacific',
    symbol: 'wsteth',
    name: 'Bridged Wrapped stETH (Manta Pacific)',
  },
  {
    id: 'bridge-mutual',
    symbol: 'bmi',
    name: 'Bridge Mutual',
  },
  {
    id: 'bridge-oracle',
    symbol: 'brg',
    name: 'Bridge Oracle',
  },
  {
    id: 'bright-token',
    symbol: 'bright',
    name: 'BrightID',
  },
  {
    id: 'bright-union',
    symbol: 'bright',
    name: 'Bright Union',
  },
  {
    id: 'brise-paradise',
    symbol: 'prds',
    name: 'Brise Paradise',
  },
  {
    id: 'britto',
    symbol: 'brt',
    name: 'Britto',
  },
  {
    id: 'brix-gaming',
    symbol: 'brix',
    name: 'Brix Gaming',
  },
  {
    id: 'brmv-token',
    symbol: 'brmv',
    name: 'BRMV',
  },
  {
    id: 'brn-metaverse',
    symbol: 'brn',
    name: 'BRN Metaverse',
  },
  {
    id: 'brokkr',
    symbol: 'bro',
    name: 'Brokkr',
  },
  {
    id: 'brokoli',
    symbol: 'brkl',
    name: 'Brokoli',
  },
  {
    id: 'brolana',
    symbol: 'bros',
    name: 'Brolana',
  },
  {
    id: 'broovs-projects',
    symbol: 'brs',
    name: 'Broovs Projects',
  },
  {
    id: 'brother-music-platform',
    symbol: 'bmp',
    name: 'Brother Music Platform',
  },
  {
    id: 'brr-protocol',
    symbol: 'brr',
    name: 'Brr Protocol',
  },
  {
    id: 'brrrrr',
    symbol: 'brrrrr',
    name: 'BRRRRR',
  },
  {
    id: 'bruh',
    symbol: 'bruh',
    name: 'BRUH',
  },
  {
    id: 'bruv',
    symbol: 'bruv',
    name: 'Bruv',
  },
  {
    id: 'brz',
    symbol: 'brz',
    name: 'Brazilian Digital',
  },
  {
    id: 'bscex',
    symbol: 'bscx',
    name: 'BSCEX',
  },
  {
    id: 'bsclaunch',
    symbol: 'bsl',
    name: 'BSClaunch',
  },
  {
    id: 'bscpad',
    symbol: 'bscpad',
    name: 'BSCPAD',
  },
  {
    id: 'bscstarter',
    symbol: 'start',
    name: 'Starter.xyz',
  },
  {
    id: 'bsc-station',
    symbol: 'bscs',
    name: 'BSCS',
  },
  {
    id: 'bsocial-2',
    symbol: 'bscl',
    name: 'BSOCIAL',
  },
  {
    id: 'bsv',
    symbol: 'bsv',
    name: '$BSV',
  },
  {
    id: 'btaf-token',
    symbol: 'btaf',
    name: 'BTAF token',
  },
  {
    id: 'btc-2x-flexible-leverage-index',
    symbol: 'btc2x-fli',
    name: 'BTC 2x Flexible Leverage Index',
  },
  {
    id: 'btchero',
    symbol: 'btchero',
    name: 'BTCHero',
  },
  {
    id: 'btcmeme',
    symbol: 'btcmeme',
    name: 'BTCMEME',
  },
  {
    id: 'btc-proxy',
    symbol: 'btcpx',
    name: 'BTC Proxy',
  },
  {
    id: 'btcrewards',
    symbol: 'btcr',
    name: 'BTCrewards',
  },
  {
    id: 'btcs',
    symbol: 'btcs',
    name: 'BTCs',
  },
  {
    id: 'btc-standard-hashrate-token',
    symbol: 'btcst',
    name: 'BTC Standard Hashrate Token',
  },
  {
    id: 'btf',
    symbol: 'btf',
    name: 'Bitcoin Faith',
  },
  {
    id: 'btour-chain',
    symbol: 'msot',
    name: 'BTour Chain',
  },
  {
    id: 'btrips',
    symbol: 'btr',
    name: 'BTRIPS',
  },
  {
    id: 'bts-chain',
    symbol: 'btsc',
    name: 'BTS Chain',
  },
  {
    id: 'btse-token',
    symbol: 'btse',
    name: 'BTSE Token',
  },
  {
    id: 'btu-protocol',
    symbol: 'btu',
    name: 'BTU Protocol',
  },
  {
    id: 'bubblefong',
    symbol: 'bbf',
    name: 'Bubblefong',
  },
  {
    id: 'bubu',
    symbol: 'bubu',
    name: 'Bubu',
  },
  {
    id: 'bucket-protocol-buck-stablecoin',
    symbol: 'buck',
    name: 'Bucket Protocol BUCK Stablecoin',
  },
  {
    id: 'buckhath-coin',
    symbol: 'bhig',
    name: 'BuckHath Coin',
  },
  {
    id: 'buddha',
    symbol: 'buddha',
    name: 'Buddha',
  },
  {
    id: 'buddyai',
    symbol: 'buddy',
    name: 'BuddyAI',
  },
  {
    id: 'buddy-dao',
    symbol: 'bdy',
    name: 'Buddy DAO',
  },
  {
    id: 'buff-coin',
    symbol: 'buff',
    name: 'Buff Coin',
  },
  {
    id: 'buff-doge-coin',
    symbol: 'dogecoin',
    name: 'Buff Doge Coin',
  },
  {
    id: 'buffswap',
    symbol: 'buffs',
    name: 'BuffSwap',
  },
  {
    id: 'bugs-bunny',
    symbol: 'bugs',
    name: 'Bugs Bunny',
  },
  {
    id: 'build',
    symbol: 'build',
    name: 'BUILD',
  },
  {
    id: 'buildup',
    symbol: 'bup',
    name: 'BuildUp',
  },
  {
    id: 'bullbar',
    symbol: 'bull',
    name: 'BullBar',
  },
  {
    id: 'bullbear-ai',
    symbol: 'aibb',
    name: 'BullBear AI',
  },
  {
    id: 'bull-btc-club',
    symbol: 'bbc',
    name: 'Bull BTC Club',
  },
  {
    id: 'bull-coin',
    symbol: 'bull',
    name: 'Bull Coin',
  },
  {
    id: 'bullet-2',
    symbol: 'blt',
    name: 'Bullet',
  },
  {
    id: 'bullet-game',
    symbol: 'bullet',
    name: 'Bullet Gate Betting Token',
  },
  {
    id: 'bullets',
    symbol: 'blt',
    name: 'Bullets',
  },
  {
    id: 'bull-frog',
    symbol: 'bull',
    name: 'Bull Frog',
  },
  {
    id: 'bull-game',
    symbol: 'bgt',
    name: 'Bull Game ToKens',
  },
  {
    id: 'bullieverse',
    symbol: 'bull',
    name: 'Bullieverse',
  },
  {
    id: 'bulllauncher',
    symbol: 'bul',
    name: 'BullLauncher',
  },
  {
    id: 'bull-market',
    symbol: '$bull',
    name: 'Bull Market',
  },
  {
    id: 'bull-moon',
    symbol: 'bullmoon',
    name: 'Bull Moon',
  },
  {
    id: 'bullperks',
    symbol: 'blp',
    name: 'BullPerks',
  },
  {
    id: 'bull-run-today',
    symbol: 'bull',
    name: 'Bull Run',
  },
  {
    id: 'bull-token',
    symbol: '$bull',
    name: 'BULL Token',
  },
  {
    id: 'bull-token-2',
    symbol: 'bull',
    name: 'Bull Token',
  },
  {
    id: 'bumblebot',
    symbol: 'bumble',
    name: 'Bumblebot',
  },
  {
    id: 'bumoon',
    symbol: 'bumn',
    name: 'BUMooN',
  },
  {
    id: 'bumper',
    symbol: 'bump',
    name: 'Bumper',
  },
  {
    id: 'bundles',
    symbol: 'bund',
    name: 'Bund V2',
  },
  {
    id: 'bundl-tools',
    symbol: 'bundl',
    name: 'Bundl Tools',
  },
  {
    id: 'bunicorn',
    symbol: 'buni',
    name: 'Bunicorn',
  },
  {
    id: 'bunnypark',
    symbol: 'bp',
    name: 'BunnyPark',
  },
  {
    id: 'bunnypark-game',
    symbol: 'bg',
    name: 'BunnyPark Game',
  },
  {
    id: 'bunny-token-polygon',
    symbol: 'polybunny',
    name: 'Pancake Bunny Polygon',
  },
  {
    id: 'bunscake',
    symbol: 'bscake',
    name: 'Bunscake',
  },
  {
    id: 'burency',
    symbol: 'buy',
    name: 'Burency',
  },
  {
    id: 'burger-swap',
    symbol: 'burger',
    name: 'BurgerCities',
  },
  {
    id: 'burn',
    symbol: 'burn',
    name: 'BURN',
  },
  {
    id: 'burnedfi',
    symbol: 'burn',
    name: 'BurnedFi',
  },
  {
    id: 'burners',
    symbol: 'brnr',
    name: 'Burners',
  },
  {
    id: 'burnify',
    symbol: 'bfy',
    name: 'Burnify',
  },
  {
    id: 'burp',
    symbol: 'burp',
    name: 'Burp',
  },
  {
    id: 'burrial',
    symbol: 'burry',
    name: 'Burrial',
  },
  {
    id: 'burrow',
    symbol: 'brrr',
    name: 'Burrow',
  },
  {
    id: 'burrrd',
    symbol: 'burrrd',
    name: 'BURRRD',
  },
  {
    id: 'bursaspor-fan-token',
    symbol: 'tmsh',
    name: 'Bursaspor Fan Token',
  },
  {
    id: 'busdx',
    symbol: 'rspn',
    name: 'Respan',
  },
  {
    id: 'business',
    symbol: 'business',
    name: 'BUSINESS',
  },
  {
    id: 'busy-dao',
    symbol: 'busy',
    name: 'Busy',
  },
  {
    id: 'butter',
    symbol: 'butter',
    name: 'Butter',
  },
  {
    id: 'butter-2',
    symbol: 'butter',
    name: 'Butter',
  },
  {
    id: 'butterfly-protocol-2',
    symbol: 'bfly',
    name: 'Butterfly Protocol',
  },
  {
    id: 'buying',
    symbol: 'buy',
    name: 'Buying.com',
  },
  {
    id: 'buzz-the-bellboy',
    symbol: 'buzz',
    name: 'Buzz The Bellboy',
  },
  {
    id: 'bware-infra',
    symbol: 'infra',
    name: 'Bware',
  },
  {
    id: 'bxh',
    symbol: 'bxh',
    name: 'BXH',
  },
  {
    id: 'byepix',
    symbol: 'epix',
    name: 'Byepix',
  },
  {
    id: 'bypass',
    symbol: 'bypass',
    name: 'Bypass',
  },
  {
    id: 'byte',
    symbol: 'byte',
    name: 'Byte',
  },
  {
    id: 'byteball',
    symbol: 'gbyte',
    name: 'Obyte',
  },
  {
    id: 'bytecoin',
    symbol: 'bcn',
    name: 'Bytecoin',
  },
  {
    id: 'bytenext',
    symbol: 'bnu',
    name: 'ByteNext',
  },
  {
    id: 'bytom',
    symbol: 'btm',
    name: 'Bytom',
  },
  {
    id: 'bzedge',
    symbol: 'bze',
    name: 'BeeZee',
  },
  {
    id: 'bzetcoin',
    symbol: 'bzet',
    name: 'BzetCoin',
  },
  {
    id: 'bzx-protocol',
    symbol: 'bzrx',
    name: 'bZx Protocol',
  },
  {
    id: 'caacon',
    symbol: 'cc',
    name: 'Caacon',
  },
  {
    id: 'caave',
    symbol: 'caave',
    name: 'cAAVE',
  },
  {
    id: 'cabal',
    symbol: 'cabal',
    name: 'Cabal',
  },
  {
    id: 'cacao',
    symbol: 'cacao',
    name: 'Maya Protocol',
  },
  {
    id: 'cacom',
    symbol: 'cacom',
    name: 'Cacom',
  },
  {
    id: 'cadabra-finance',
    symbol: 'abra',
    name: 'Cadabra Finance',
  },
  {
    id: 'cad-coin',
    symbol: 'cadc',
    name: 'CAD Coin',
  },
  {
    id: 'cadence-protocol',
    symbol: 'cad',
    name: 'Cadence Protocol',
  },
  {
    id: 'caduceus',
    symbol: 'cmp',
    name: 'Caduceus',
  },
  {
    id: 'caesar-s-arena',
    symbol: 'caesar',
    name: "Caesar's Arena",
  },
  {
    id: 'cagdas-bodrumspor-fan-token',
    symbol: 'cbs',
    name: 'Çağdaş Bodrumspor Fan Token',
  },
  {
    id: 'ca-htb',
    symbol: 'ca',
    name: 'Coupon Assets',
  },
  {
    id: 'caica-coin',
    symbol: 'cicc',
    name: 'CAICA Coin',
  },
  {
    id: 'cairo-finance-cairo-bank',
    symbol: 'cbank',
    name: 'Cairo Bank',
  },
  {
    id: 'cajutel',
    symbol: 'caj',
    name: 'Cajutel',
  },
  {
    id: 'cakebot',
    symbol: 'cakebot',
    name: 'Cakebot',
  },
  {
    id: 'cakebot-2',
    symbol: 'cakebot',
    name: 'CakeBot',
  },
  {
    id: 'cake-monster',
    symbol: 'monsta',
    name: 'Cake Monster',
  },
  {
    id: 'cakepie-xyz',
    symbol: 'ckp',
    name: 'Cakepie',
  },
  {
    id: 'cakeswap',
    symbol: 'cakeswap',
    name: 'CakeSwap',
  },
  {
    id: 'caketools',
    symbol: 'ckt',
    name: 'Caketools',
  },
  {
    id: 'calamari-network',
    symbol: 'kma',
    name: 'Calamari Network',
  },
  {
    id: 'calaxy',
    symbol: 'clxy',
    name: 'Calaxy',
  },
  {
    id: 'calcium',
    symbol: 'cal',
    name: 'Calcium',
  },
  {
    id: 'calcium-bsc',
    symbol: 'cal',
    name: 'Calcium (BSC)',
  },
  {
    id: 'calico-cat',
    symbol: 'calic',
    name: 'Calico Cat',
  },
  {
    id: 'calicoin',
    symbol: 'cali',
    name: 'CaliCoin',
  },
  {
    id: 'callhub',
    symbol: 'chub',
    name: 'CallHub',
  },
  {
    id: 'callisto',
    symbol: 'clo',
    name: 'Callisto Network',
  },
  {
    id: 'calorie',
    symbol: 'cal',
    name: 'FitBurn',
  },
  {
    id: 'calvaria-doe',
    symbol: 'ria',
    name: 'Calvaria: DoE',
  },
  {
    id: 'camelcoin',
    symbol: 'cml',
    name: 'Camelcoin',
  },
  {
    id: 'camelot-token',
    symbol: 'grail',
    name: 'Camelot Token',
  },
  {
    id: 'canada-ecoin',
    symbol: 'cdn',
    name: 'Canada eCoin',
  },
  {
    id: 'canadian-inuit-dog-2',
    symbol: 'cadinu',
    name: 'Canadian Inuit Dog',
  },
  {
    id: 'canary',
    symbol: 'cnr',
    name: 'Canary',
  },
  {
    id: 'canary-dollar',
    symbol: 'cand',
    name: 'Canary Dollar',
  },
  {
    id: 'canaryx',
    symbol: 'cnyx',
    name: 'CanaryX',
  },
  {
    id: 'candy-pocket',
    symbol: 'candy',
    name: 'Candy Pocket',
  },
  {
    id: 'candy-token',
    symbol: 'candy',
    name: 'CANDY Token',
  },
  {
    id: 'cantina-royale',
    symbol: 'crt',
    name: 'Cantina Royale',
  },
  {
    id: 'canto',
    symbol: 'canto',
    name: 'CANTO',
  },
  {
    id: 'canto-crabs-chip',
    symbol: 'crab',
    name: 'Canto Crabs Chip',
  },
  {
    id: 'cantohm',
    symbol: 'cohm',
    name: 'CantOHM',
  },
  {
    id: 'canto-inu',
    symbol: 'cinu',
    name: 'Canto Inu',
  },
  {
    id: 'cantosino-com-profit-pass',
    symbol: 'cpp',
    name: 'Cantosino.com Profit Pass',
  },
  {
    id: 'canvas-n-glr',
    symbol: 'glr',
    name: 'GalleryCoin',
  },
  {
    id: 'canxium',
    symbol: 'cau',
    name: 'Canxium',
  },
  {
    id: 'cap',
    symbol: 'cap',
    name: 'Cap',
  },
  {
    id: 'capapult',
    symbol: 'capa',
    name: 'Solid',
  },
  {
    id: 'capital-dao-starter-token',
    symbol: 'cds',
    name: 'Capital DAO Starter',
  },
  {
    id: 'capital-rock',
    symbol: 'cr',
    name: 'CAPITAL ROCK',
  },
  {
    id: 'capone',
    symbol: 'capone',
    name: 'Capone',
  },
  {
    id: 'cappasity',
    symbol: 'capp',
    name: 'Cappasity',
  },
  {
    id: 'capshort-token',
    symbol: 'caps',
    name: 'Capshort token',
  },
  {
    id: 'captain-planet',
    symbol: 'ctp',
    name: 'Captain Planet',
  },
  {
    id: 'captain-tsubasa',
    symbol: 'tsugt',
    name: 'Captain Tsubasa',
  },
  {
    id: 'capybara',
    symbol: 'capy',
    name: 'Capybara',
  },
  {
    id: 'capybara-bsc',
    symbol: 'capy',
    name: 'Capybara BSC',
  },
  {
    id: 'capybara-memecoin',
    symbol: 'bara',
    name: 'Capybara Memecoin',
  },
  {
    id: 'capybara-token',
    symbol: 'capy',
    name: 'Capybara Token',
  },
  {
    id: 'carbify',
    symbol: 'cby',
    name: 'Carbify',
  },
  {
    id: 'carbon',
    symbol: 'carbon',
    name: 'Carbon',
  },
  {
    id: 'carbon21',
    symbol: 'c21',
    name: 'Carbon21',
  },
  {
    id: 'carbon-browser',
    symbol: 'csix',
    name: 'Carbon Browser',
  },
  {
    id: 'carbon-credit',
    symbol: 'cct',
    name: 'Carbon Credit',
  },
  {
    id: 'carbon-labs',
    symbol: 'carb',
    name: 'Carbon Labs',
  },
  {
    id: 'cardano',
    symbol: 'ada',
    name: 'Cardano',
  },
  {
    id: 'cardano-crocs-club',
    symbol: 'c4',
    name: 'Cardano Crocs Club',
  },
  {
    id: 'cardanogpt',
    symbol: 'cgi',
    name: 'CardanoGPT',
  },
  {
    id: 'cardanum',
    symbol: 'carda',
    name: 'Cardanum',
  },
  {
    id: 'cardence',
    symbol: '$crdn',
    name: 'Cardence',
  },
  {
    id: 'cardinals',
    symbol: 'cardi',
    name: 'Cardinals (DRC-20)',
  },
  {
    id: 'cardiocoin',
    symbol: 'crdc',
    name: 'Cardiocoin',
  },
  {
    id: 'cardstack',
    symbol: 'card',
    name: 'Cardstack',
  },
  {
    id: 'cardstarter',
    symbol: 'cards',
    name: 'Cardstarter',
  },
  {
    id: 'carecoin',
    symbol: 'care',
    name: 'CareCoin',
  },
  {
    id: 'cargox',
    symbol: 'cxo',
    name: 'CargoX',
  },
  {
    id: 'carmin',
    symbol: 'carmin',
    name: 'Carmin',
  },
  {
    id: 'carnomaly',
    symbol: 'carr',
    name: 'Carnomaly',
  },
  {
    id: 'caroline',
    symbol: 'her',
    name: 'Caroline',
  },
  {
    id: 'caroltoken',
    symbol: 'carol',
    name: 'CAROLToken',
  },
  {
    id: 'carrieverse',
    symbol: 'cvtx',
    name: 'CarrieVerse',
  },
  {
    id: 'carry',
    symbol: 'cre',
    name: 'Carry',
  },
  {
    id: 'cartel-coin-2',
    symbol: 'cartel',
    name: 'Cartel Coin',
  },
  {
    id: 'cartesi',
    symbol: 'ctsi',
    name: 'Cartesi',
  },
  {
    id: 'cartman',
    symbol: '$cartman',
    name: 'Cartman',
  },
  {
    id: 'carvertical',
    symbol: 'cv',
    name: 'carVertical',
  },
  {
    id: 'cascadia',
    symbol: 'cc',
    name: 'Cascadia',
  },
  {
    id: 'cashaa',
    symbol: 'cas',
    name: 'Cashaa',
  },
  {
    id: 'cashback',
    symbol: 'cbk',
    name: 'Cashback',
  },
  {
    id: 'cashbackpro',
    symbol: 'cbp',
    name: 'CashBackPro',
  },
  {
    id: 'cashcats',
    symbol: '$cats',
    name: 'CashCats',
  },
  {
    id: 'cashcow',
    symbol: 'cow',
    name: 'CashCow',
  },
  {
    id: 'cash-driver',
    symbol: 'cd',
    name: 'Cash Driver',
  },
  {
    id: 'cash-flash',
    symbol: 'cft',
    name: 'Cash Flash',
  },
  {
    id: 'cash-token',
    symbol: 'cash',
    name: 'Cash Token',
  },
  {
    id: 'cashtree-token',
    symbol: 'ctt',
    name: 'Cashtree Token',
  },
  {
    id: 'casinocoin',
    symbol: 'csc',
    name: 'Casinocoin',
  },
  {
    id: 'casper-network',
    symbol: 'cspr',
    name: 'Casper Network',
  },
  {
    id: 'casperpad',
    symbol: 'cspd',
    name: 'CasperPad',
  },
  {
    id: 'castello-coin',
    symbol: 'cast',
    name: 'Castello Coin',
  },
  {
    id: 'catalina-whales-index',
    symbol: 'whales',
    name: 'Catalina Whales Index',
  },
  {
    id: 'catapult',
    symbol: 'atd',
    name: 'A2DAO',
  },
  {
    id: 'catbonk',
    symbol: 'cabo',
    name: 'Catbonk',
  },
  {
    id: 'catboy-3',
    symbol: 'catboy',
    name: 'Catboy',
  },
  {
    id: 'cat-cat-token',
    symbol: 'cat',
    name: 'Cat',
  },
  {
    id: 'catceo',
    symbol: 'catceo',
    name: 'CATCEO',
  },
  {
    id: 'catchy',
    symbol: 'catchy',
    name: 'Catchy',
  },
  {
    id: 'catcoin-bsc',
    symbol: 'cat',
    name: 'Catcoin BSC',
  },
  {
    id: 'catcoin-cash',
    symbol: 'cat',
    name: 'Catcoin',
  },
  {
    id: 'catcoin-token',
    symbol: 'cats',
    name: 'CatCoin Token',
  },
  {
    id: 'catdog',
    symbol: 'catdog',
    name: 'CATDOG',
  },
  {
    id: 'catecoin',
    symbol: 'cate',
    name: 'CateCoin',
  },
  {
    id: 'catex-token',
    symbol: 'catt',
    name: 'Catex',
  },
  {
    id: 'catge-coin',
    symbol: 'catge',
    name: 'Catge Coin',
  },
  {
    id: 'catgirl',
    symbol: 'catgirl',
    name: 'Catgirl',
  },
  {
    id: 'catgirl-optimus',
    symbol: 'optig',
    name: 'Catgirl Optimus',
  },
  {
    id: 'cathena-gold',
    symbol: 'cgo',
    name: 'Cathena Gold',
  },
  {
    id: 'catheon-gaming',
    symbol: 'catheon',
    name: 'Catheon Gaming',
  },
  {
    id: 'cat-in-a-box-ether',
    symbol: 'boxeth',
    name: 'Cat-in-a-Box Ether',
  },
  {
    id: 'cat-in-a-box-fee-token',
    symbol: 'boxfee',
    name: 'Cat-in-a-Box Fee Token',
  },
  {
    id: 'cat-inu',
    symbol: 'cat',
    name: 'CAT INU',
  },
  {
    id: 'catking',
    symbol: 'cking',
    name: 'CatKing',
  },
  {
    id: 'catman',
    symbol: 'catman',
    name: 'Catman',
  },
  {
    id: 'cat-mouse',
    symbol: 'catmouse',
    name: 'Cat & Mouse',
  },
  {
    id: 'cato',
    symbol: 'cato',
    name: 'CATO',
  },
  {
    id: 'catocoin',
    symbol: 'cato',
    name: 'CatoCoin',
  },
  {
    id: 'catpay',
    symbol: 'catpay',
    name: 'CATpay',
  },
  {
    id: 'catsapes',
    symbol: 'cats',
    name: 'CatsApes',
  },
  {
    id: 'catscoin',
    symbol: 'cats',
    name: 'Catscoin',
  },
  {
    id: 'cats-coin-1722f9f2-68f8-4ad8-a123-2835ea18abc5',
    symbol: 'cts',
    name: 'Cats Coin (BSC)',
  },
  {
    id: 'cat-token',
    symbol: 'cat',
    name: 'Mooncat CAT',
  },
  {
    id: 'catwifhat',
    symbol: 'cif',
    name: 'CatwifHat',
  },
  {
    id: 'catwifmelon',
    symbol: 'melon',
    name: 'CATWIFMELON',
  },
  {
    id: 'catzcoin',
    symbol: 'catz',
    name: 'CatzCoin',
  },
  {
    id: 'cavachon',
    symbol: 'cava',
    name: 'Cavachon',
  },
  {
    id: 'cavatar',
    symbol: 'cavat',
    name: 'Cavatar',
  },
  {
    id: 'cave',
    symbol: 'cave',
    name: 'CaveWorld',
  },
  {
    id: 'caviar',
    symbol: 'cvr',
    name: 'Caviar',
  },
  {
    id: 'caviarnine-lsu-pool-lp',
    symbol: 'lsulp',
    name: 'CaviarNine LSU Pool LP',
  },
  {
    id: 'caw-ceo',
    symbol: 'cawceo',
    name: 'Caw CEO',
  },
  {
    id: 'cbdc',
    symbol: 'cbdc',
    name: 'CBDC',
  },
  {
    id: 'cbdx',
    symbol: 'cbdx',
    name: 'CBDX (Ordinals)',
  },
  {
    id: 'cbyte-network',
    symbol: 'cbyte',
    name: 'CBYTE Network',
  },
  {
    id: 'cca',
    symbol: 'cca',
    name: 'CCA',
  },
  {
    id: 'c-cash',
    symbol: 'ccash',
    name: 'C-Cash',
  },
  {
    id: 'ccb',
    symbol: '鸡鸡币 (ccb)',
    name: '$CCB 鸡鸡币',
  },
  {
    id: 'ccc-protocol',
    symbol: 'ccc',
    name: 'CCC Protocol',
  },
  {
    id: 'ccgds',
    symbol: 'ccgds',
    name: 'CCGDS',
  },
  {
    id: 'c-charge',
    symbol: 'cchg',
    name: 'C+Charge',
  },
  {
    id: 'ccomp',
    symbol: 'ccomp',
    name: 'cCOMP',
  },
  {
    id: 'ccore',
    symbol: 'cco',
    name: 'Ccore',
  },
  {
    id: 'ccqkl',
    symbol: 'cc',
    name: 'CCQKL',
  },
  {
    id: 'cdai',
    symbol: 'cdai',
    name: 'cDAI',
  },
  {
    id: 'cdao',
    symbol: 'cdao',
    name: 'cDAO',
  },
  {
    id: 'cdbio',
    symbol: 'mcd',
    name: 'CDbio',
  },
  {
    id: 'ceasports',
    symbol: 'cspt',
    name: 'CEASports',
  },
  {
    id: 'cebiolabs',
    symbol: 'cbsl',
    name: 'CeBioLabs',
  },
  {
    id: 'ceek',
    symbol: 'ceek',
    name: 'CEEK Smart VR',
  },
  {
    id: 'ceji',
    symbol: 'ceji',
    name: 'Ceji',
  },
  {
    id: 'cekke-cronje',
    symbol: 'cekke',
    name: 'Cekke Cronje',
  },
  {
    id: 'celer-network',
    symbol: 'celr',
    name: 'Celer Network',
  },
  {
    id: 'celestia',
    symbol: 'tia',
    name: 'Celestia',
  },
  {
    id: 'celestial',
    symbol: 'celt',
    name: 'Celestial',
  },
  {
    id: 'cellframe',
    symbol: 'cell',
    name: 'Cellframe',
  },
  {
    id: 'cells-token',
    symbol: 'cells',
    name: 'Cells Token',
  },
  {
    id: 'celo',
    symbol: 'celo',
    name: 'Celo',
  },
  {
    id: 'celo-dollar',
    symbol: 'cusd',
    name: 'Celo Dollar',
  },
  {
    id: 'celo-euro',
    symbol: 'ceur',
    name: 'Celo Euro',
  },
  {
    id: 'celo-real-creal',
    symbol: 'creal',
    name: 'Celo Real (cREAL)',
  },
  {
    id: 'celo-wormhole',
    symbol: 'celo',
    name: 'Celo (Wormhole)',
  },
  {
    id: 'celsius-degree-token',
    symbol: 'cel',
    name: 'Celsius Network',
  },
  {
    id: 'celsiusx-wrapped-eth',
    symbol: 'cxeth',
    name: 'CelsiusX Wrapped ETH',
  },
  {
    id: 'centaur',
    symbol: 'cntr',
    name: 'Centaur',
  },
  {
    id: 'centaurify',
    symbol: 'cent',
    name: 'Centaurify',
  },
  {
    id: 'centbit',
    symbol: 'cbit',
    name: 'CentBit',
  },
  {
    id: 'centcex',
    symbol: 'cenx',
    name: 'Centcex',
  },
  {
    id: 'centrality',
    symbol: 'cennz',
    name: 'CENNZnet',
  },
  {
    id: 'centric-cash',
    symbol: 'cns',
    name: 'Centric Swap',
  },
  {
    id: 'centrifuge',
    symbol: 'cfg',
    name: 'Centrifuge',
  },
  {
    id: 'centrofi',
    symbol: 'centro',
    name: 'CentroFi',
  },
  {
    id: 'centurion-invest',
    symbol: 'cix',
    name: 'Centurion Invest',
  },
  {
    id: 'ceo',
    symbol: 'ceo',
    name: 'CEO',
  },
  {
    id: 'cerberus-2',
    symbol: 'crbrus',
    name: 'Cerberus',
  },
  {
    id: 'cere-network',
    symbol: 'cere',
    name: 'Cere Network',
  },
  {
    id: 'ceres',
    symbol: 'ceres',
    name: 'Ceres',
  },
  {
    id: 'cerra',
    symbol: 'cerra',
    name: 'Cerra',
  },
  {
    id: 'certik',
    symbol: 'ctk',
    name: 'Shentu',
  },
  {
    id: 'cetus-protocol',
    symbol: 'cetus',
    name: 'Cetus Protocol',
  },
  {
    id: 'cex-ai',
    symbol: 'cex-ai',
    name: 'CEX AI',
  },
  {
    id: 'cex-index',
    symbol: 'cex',
    name: 'CEX Index',
  },
  {
    id: 'cfl365-finance',
    symbol: 'cfl365',
    name: 'CFL365 Finance',
  },
  {
    id: 'cfx-quantum',
    symbol: 'cfxq',
    name: 'CFX Quantum',
  },
  {
    id: 'chabit',
    symbol: 'cb8',
    name: 'chabit',
  },
  {
    id: 'chad-coin',
    symbol: 'chad',
    name: 'Chad Coin',
  },
  {
    id: 'chad-index',
    symbol: 'chad',
    name: 'Chad Index',
  },
  {
    id: 'chad-on-solana',
    symbol: 'chad',
    name: 'Chad On Solana',
  },
  {
    id: 'chai',
    symbol: 'chai',
    name: 'Chai',
  },
  {
    id: 'chain-2',
    symbol: 'xcn',
    name: 'Onyxcoin',
  },
  {
    id: 'chainback',
    symbol: 'archive',
    name: 'Chainback',
  },
  {
    id: 'chainbing',
    symbol: 'cbg',
    name: 'Chainbing',
  },
  {
    id: 'chaincade',
    symbol: 'chaincade',
    name: 'ChainCade',
  },
  {
    id: 'chainers',
    symbol: 'chu',
    name: 'Chainers',
  },
  {
    id: 'chainex',
    symbol: 'cex',
    name: 'ChainEx',
  },
  {
    id: 'chainfactory',
    symbol: 'factory',
    name: 'ChainFactory',
  },
  {
    id: 'chainflip',
    symbol: 'flip',
    name: 'Chainflip',
  },
  {
    id: 'chain-games',
    symbol: 'chain',
    name: 'Chain Games',
  },
  {
    id: 'chainge-finance',
    symbol: 'chng',
    name: 'Chainge',
  },
  {
    id: 'chaingpt',
    symbol: 'cgpt',
    name: 'ChainGPT',
  },
  {
    id: 'chain-guardians',
    symbol: 'cgg',
    name: 'Chain Guardians',
  },
  {
    id: 'chain-key-bitcoin',
    symbol: 'ckbtc',
    name: 'Chain-key Bitcoin',
  },
  {
    id: 'chain-key-ethereum',
    symbol: 'cketh',
    name: 'Chain-key Ethereum',
  },
  {
    id: 'chainlink',
    symbol: 'link',
    name: 'Chainlink',
  },
  {
    id: 'chainlink-plenty-bridge',
    symbol: 'link.e',
    name: 'Chainlink (Plenty Bridge)',
  },
  {
    id: 'chainmail',
    symbol: 'mail',
    name: 'CHAINMAIL',
  },
  {
    id: 'chain-of-legends',
    symbol: 'cleg',
    name: 'Chain of Legends',
  },
  {
    id: 'chainpay',
    symbol: 'cpay',
    name: 'Chainpay',
  },
  {
    id: 'chainport',
    symbol: 'portx',
    name: 'ChainPort',
  },
  {
    id: 'chains-of-war',
    symbol: 'mira',
    name: 'Chains of War',
  },
  {
    id: 'chainswap-2',
    symbol: 'chains',
    name: 'ChainSwap',
  },
  {
    id: 'chaintools',
    symbol: 'ctls',
    name: 'Chaintools',
  },
  {
    id: 'chainx',
    symbol: 'pcx',
    name: 'ChainX',
  },
  {
    id: 'challenge-coin',
    symbol: 'hero',
    name: 'Challenge Coin',
  },
  {
    id: 'champignons-of-arborethia',
    symbol: 'champz',
    name: 'Champignons of Arborethia',
  },
  {
    id: 'chanalog',
    symbol: 'chan',
    name: 'Chanalog',
  },
  {
    id: 'change',
    symbol: 'cag',
    name: 'Change',
  },
  {
    id: 'changenow',
    symbol: 'now',
    name: 'ChangeNOW',
  },
  {
    id: 'changer',
    symbol: 'cng',
    name: 'Changer',
  },
  {
    id: 'changex',
    symbol: 'change',
    name: 'Changex',
  },
  {
    id: 'changpeng-zhao',
    symbol: 'cz',
    name: 'Changpeng Zhao',
  },
  {
    id: 'channels',
    symbol: 'can',
    name: 'Channels',
  },
  {
    id: 'chaotic-finance',
    symbol: 'chaos',
    name: 'Chaotic Finance',
  },
  {
    id: 'chappie',
    symbol: 'chap',
    name: 'Chappie',
  },
  {
    id: 'chappyz',
    symbol: 'chapz',
    name: 'Chappyz',
  },
  {
    id: 'charactbit',
    symbol: 'chb',
    name: 'Charactbit',
  },
  {
    id: 'characterai',
    symbol: 'chai',
    name: 'CharacterAI',
  },
  {
    id: 'chargedefi-static',
    symbol: 'static',
    name: 'ChargeDeFi Static',
  },
  {
    id: 'charged-particles',
    symbol: 'ionx',
    name: 'Charged Particles',
  },
  {
    id: 'charity-alfa',
    symbol: 'mich',
    name: 'Charity Alfa',
  },
  {
    id: 'charity-dao-token',
    symbol: 'chdao',
    name: 'Charity DAO Token',
  },
  {
    id: 'charli3',
    symbol: 'c3',
    name: 'Charli3',
  },
  {
    id: 'charm',
    symbol: 'charm',
    name: 'Charm',
  },
  {
    id: 'chartai',
    symbol: 'cx',
    name: 'ChartAI',
  },
  {
    id: 'charthub',
    symbol: 'cht',
    name: 'ChartHub',
  },
  {
    id: 'chart-roulette',
    symbol: 'cr',
    name: 'Chart Roulette',
  },
  {
    id: 'chaseto',
    symbol: 'ccn',
    name: 'Chaseto',
  },
  {
    id: 'chat-ai',
    symbol: 'ai',
    name: 'Chat AI',
  },
  {
    id: 'chatter-shield',
    symbol: 'shield',
    name: 'Chatter Shield',
  },
  {
    id: 'chavo',
    symbol: 'cha',
    name: 'CHAVO',
  },
  {
    id: 'chax',
    symbol: 'chax',
    name: 'CHAX',
  },
  {
    id: 'check-dm-ser',
    symbol: 'checkdm',
    name: 'CHECK DM SER',
  },
  {
    id: 'checkdot',
    symbol: 'cdt',
    name: 'CheckDot',
  },
  {
    id: 'checkerchain',
    symbol: 'checkr',
    name: 'CheckerChain',
  },
  {
    id: 'checkmate',
    symbol: 'cmbot',
    name: 'CHECKMATE',
  },
  {
    id: 'checks-token',
    symbol: 'checks',
    name: 'Checks Token',
  },
  {
    id: 'checoin',
    symbol: 'checoin',
    name: 'CheCoin',
  },
  {
    id: 'chedda',
    symbol: 'chedda',
    name: 'Chedda',
  },
  {
    id: 'cheelee',
    symbol: 'cheel',
    name: 'Cheelee',
  },
  {
    id: 'cheems',
    symbol: 'cheems',
    name: 'Cheems',
  },
  {
    id: 'cheems-inu-new',
    symbol: 'cinu',
    name: 'Cheems Inu [NEW]',
  },
  {
    id: 'cheems-token',
    symbol: 'cheems',
    name: 'Cheems Token',
  },
  {
    id: 'cheersland',
    symbol: 'cheers',
    name: 'CheersLand',
  },
  {
    id: 'cheesecakeswap',
    symbol: 'ccake',
    name: 'CheesecakeSwap',
  },
  {
    id: 'cheese-swap',
    symbol: 'cheese',
    name: 'Cheese Swap',
  },
  {
    id: 'cheezburger',
    symbol: 'chz',
    name: 'Cheezburger',
  },
  {
    id: 'cheezburger-2',
    symbol: 'cheez',
    name: 'Cheezburger',
  },
  {
    id: 'cheqd-network',
    symbol: 'cheq',
    name: 'CHEQD Network',
  },
  {
    id: 'cherish',
    symbol: 'chc',
    name: 'Cherish',
  },
  {
    id: 'cherrylend',
    symbol: 'chry',
    name: 'CherryLend',
  },
  {
    id: 'cherry-network',
    symbol: 'cher',
    name: 'Cherry Network',
  },
  {
    id: 'cherryswap',
    symbol: 'che',
    name: 'CherrySwap',
  },
  {
    id: 'chesscoin-0-32',
    symbol: 'chess',
    name: 'ChessCoin 0.32%',
  },
  {
    id: 'chessfish',
    symbol: 'cfsh',
    name: 'ChessFish',
  },
  {
    id: 'chew',
    symbol: 'chew',
    name: 'CHEW',
  },
  {
    id: 'chewyswap',
    symbol: 'chewy',
    name: 'Chewyswap',
  },
  {
    id: 'chex-token',
    symbol: 'chex',
    name: 'CHEX Token',
  },
  {
    id: 'chia',
    symbol: 'xch',
    name: 'Chia',
  },
  {
    id: 'chiba-neko',
    symbol: 'chiba',
    name: 'Chiba Neko',
  },
  {
    id: 'chibi-inu-2',
    symbol: 'chibi',
    name: 'Chibi Inu [OLD]',
  },
  {
    id: 'chibi-inu-3',
    symbol: 'chibi',
    name: 'Chibi Inu',
  },
  {
    id: 'chica-chain',
    symbol: 'chica',
    name: 'Chica Chain',
  },
  {
    id: 'chicken',
    symbol: 'kfc',
    name: 'Chicken',
  },
  {
    id: 'chicken-town',
    symbol: 'chickentown',
    name: 'Chicken Town',
  },
  {
    id: 'chief-troll-officer',
    symbol: 'cto',
    name: 'Chief Troll Officer',
  },
  {
    id: 'chief-troll-officer-2',
    symbol: 'cto',
    name: 'Chief Troll Officer',
  },
  {
    id: 'chief-troll-officer-3',
    symbol: 'cto',
    name: 'Chief Troll Officer',
  },
  {
    id: 'chihiro-inu',
    symbol: 'chiro',
    name: 'Chihiro Inu',
  },
  {
    id: 'chihuahua',
    symbol: 'hua',
    name: 'Chihuahua',
  },
  {
    id: 'chihuahuasol',
    symbol: 'chih',
    name: 'ChihuahuaSol',
  },
  {
    id: 'chihuahua-token',
    symbol: 'huahua',
    name: 'Chihuahua Chain',
  },
  {
    id: 'chikincoin',
    symbol: 'ckc',
    name: 'ChikinCoin',
  },
  {
    id: 'chikn-egg',
    symbol: 'egg',
    name: 'Chikn Egg',
  },
  {
    id: 'chikn-feed',
    symbol: 'feed',
    name: 'chikn feed',
  },
  {
    id: 'chikn-fert',
    symbol: 'fert',
    name: 'Chikn Fert',
  },
  {
    id: 'chikn-worm',
    symbol: 'worm',
    name: 'Chikn Worm',
  },
  {
    id: 'childhoods-end',
    symbol: 'o',
    name: 'Childhoods End',
  },
  {
    id: 'child-support',
    symbol: '$cs',
    name: 'Child Support',
  },
  {
    id: 'chili',
    symbol: 'chili',
    name: 'CHILI',
  },
  {
    id: 'chiliz',
    symbol: 'chz',
    name: 'Chiliz',
  },
  {
    id: 'chillpill',
    symbol: '$chill',
    name: 'ChillPill',
  },
  {
    id: 'chimaera',
    symbol: 'wchi',
    name: 'XAYA',
  },
  {
    id: 'chimp-fight',
    symbol: 'nana',
    name: 'Nana',
  },
  {
    id: 'chinu-2',
    symbol: 'chinu',
    name: 'Chinu',
  },
  {
    id: 'chipi',
    symbol: 'chipi',
    name: 'CHIPI',
  },
  {
    id: 'chi-protocol',
    symbol: 'chi',
    name: 'Chi Protocol',
  },
  {
    id: 'chirp-finance',
    symbol: 'chirp',
    name: 'Chirp Finance',
  },
  {
    id: 'chirpley',
    symbol: 'chrp',
    name: 'Chirpley',
  },
  {
    id: 'chitaverse',
    symbol: 'bct',
    name: 'BabyChita',
  },
  {
    id: 'chives-coin',
    symbol: 'xcc',
    name: 'Chives Coin',
  },
  {
    id: 'choccyswap',
    symbol: 'ccy',
    name: 'ChoccySwap',
  },
  {
    id: 'chocobase',
    symbol: 'choco',
    name: 'ChocoBase',
  },
  {
    id: 'chocolate-like-butterfly',
    symbol: 'clb',
    name: 'Chocolate Like Butterfly',
  },
  {
    id: 'choice-coin',
    symbol: 'choice',
    name: 'Choice Coin',
  },
  {
    id: 'choise',
    symbol: 'cho',
    name: 'Choise.com',
  },
  {
    id: 'chonky',
    symbol: 'chonky',
    name: 'CHONKY',
  },
  {
    id: 'chooky',
    symbol: '$choo',
    name: 'Chooky',
  },
  {
    id: 'chopbot',
    symbol: 'chop',
    name: 'Chopbot',
  },
  {
    id: 'chow-chow',
    symbol: 'chow',
    name: 'CHOW CHOW',
  },
  {
    id: 'christmas-floki',
    symbol: 'floc',
    name: 'Christmas Floki',
  },
  {
    id: 'christmaspump',
    symbol: 'chrispump',
    name: 'christmaspump',
  },
  {
    id: 'christmas-shiba',
    symbol: 'xshib',
    name: 'Christmas Shiba',
  },
  {
    id: 'chromaway',
    symbol: 'chr',
    name: 'Chromia',
  },
  {
    id: 'chromium-dollar',
    symbol: 'cr',
    name: 'Chromium Dollar',
  },
  {
    id: 'chronicle',
    symbol: 'xnl',
    name: 'Chronicle',
  },
  {
    id: 'chronicum',
    symbol: 'chro',
    name: 'Chronicum',
  },
  {
    id: 'chronobank',
    symbol: 'time',
    name: 'chrono.tech',
  },
  {
    id: 'chronos-finance',
    symbol: 'chr',
    name: 'Chronos Finance',
  },
  {
    id: 'chubbyakita',
    symbol: 'cakita',
    name: 'ChubbyAkita',
  },
  {
    id: 'chumbai-valley',
    symbol: 'chmb',
    name: 'Chumbi Valley',
  },
  {
    id: 'chunks',
    symbol: 'chunks',
    name: 'Chunks',
  },
  {
    id: 'church-of-the-machina',
    symbol: 'machina',
    name: 'Church of the Machina',
  },
  {
    id: 'churro',
    symbol: 'churro',
    name: 'Churro',
  },
  {
    id: 'cia',
    symbol: 'cia',
    name: 'CIA',
  },
  {
    id: 'cicca-network',
    symbol: 'cicca',
    name: 'Cicca Network',
  },
  {
    id: 'ciento-exchange',
    symbol: 'cnto',
    name: 'Ciento Exchange',
  },
  {
    id: 'cifdaq',
    symbol: 'cifd',
    name: 'CIFDAQ',
  },
  {
    id: 'cigarette-token',
    symbol: 'cig',
    name: 'Cigarette',
  },
  {
    id: 'cindicator',
    symbol: 'cnd',
    name: 'Cindicator',
  },
  {
    id: 'cindrum',
    symbol: 'cind',
    name: 'Cindrum',
  },
  {
    id: 'cipher-2',
    symbol: 'cpr',
    name: 'CIPHER',
  },
  {
    id: 'ciphercore',
    symbol: 'cipher',
    name: 'Ciphercore',
  },
  {
    id: 'circlepacific',
    symbol: 'circle',
    name: 'CirclePacific',
  },
  {
    id: 'circleswap',
    symbol: 'cir',
    name: 'CircleSwap',
  },
  {
    id: 'circuits-of-value',
    symbol: 'coval',
    name: 'Circuits of Value',
  },
  {
    id: 'circularity-finance',
    symbol: 'cifi',
    name: 'Circularity Finance',
  },
  {
    id: 'ciri-coin',
    symbol: 'ciri',
    name: 'CIRI Coin',
  },
  {
    id: 'cirquity',
    symbol: 'cirq',
    name: 'Cirquity',
  },
  {
    id: 'cirus',
    symbol: 'cirus',
    name: 'Cirus',
  },
  {
    id: 'citadao',
    symbol: 'knight',
    name: 'CitaDAO',
  },
  {
    id: 'citadel',
    symbol: 'ctl',
    name: 'Citadel',
  },
  {
    id: 'citadel-one',
    symbol: 'xct',
    name: 'Citadel.one',
  },
  {
    id: 'citadel-swap',
    symbol: 'fort',
    name: 'Citadel',
  },
  {
    id: 'city-boys',
    symbol: 'toons',
    name: 'City Boys',
  },
  {
    id: 'city-tycoon-games',
    symbol: 'ctg',
    name: 'City Tycoon Games',
  },
  {
    id: 'civfund-stone',
    symbol: '0ne',
    name: 'Civfund Stone',
  },
  {
    id: 'civic',
    symbol: 'cvc',
    name: 'Civic',
  },
  {
    id: 'civilization',
    symbol: 'civ',
    name: 'Civilization',
  },
  {
    id: 'civilization-network',
    symbol: 'cvl',
    name: 'Civilization Network',
  },
  {
    id: 'cjournal',
    symbol: 'ucjl',
    name: 'Utility Cjournal',
  },
  {
    id: 'claimswap',
    symbol: 'cla',
    name: 'ClaimSwap',
  },
  {
    id: 'clams',
    symbol: 'clam',
    name: 'Clams',
  },
  {
    id: 'clashmon-ignition-torch',
    symbol: 'torch',
    name: 'Torch',
  },
  {
    id: 'clash-of-lilliput',
    symbol: 'col',
    name: 'Clash of Lilliput',
  },
  {
    id: 'classicbitcoin',
    symbol: 'cbtc',
    name: 'ClassicBitcoin',
  },
  {
    id: 'classzz',
    symbol: 'czz',
    name: 'ClassZZ',
  },
  {
    id: 'claw-2',
    symbol: 'claw',
    name: 'Claw',
  },
  {
    id: 'clay-nation',
    symbol: 'clay',
    name: 'Clay Nation',
  },
  {
    id: 'claystack-staked-eth',
    symbol: 'cseth',
    name: 'ClayStack Staked ETH',
  },
  {
    id: 'claystack-staked-matic',
    symbol: 'csmatic',
    name: 'ClayStack Staked MATIC',
  },
  {
    id: 'clearcryptos',
    symbol: 'ccx',
    name: 'ClearCryptos',
  },
  {
    id: 'cleardao',
    symbol: 'clh',
    name: 'ClearDAO',
  },
  {
    id: 'clearpool',
    symbol: 'cpool',
    name: 'Clearpool',
  },
  {
    id: 'clecoin',
    symbol: 'cle',
    name: 'CLECOIN',
  },
  {
    id: 'cleopatra',
    symbol: 'cleo',
    name: 'Cleopatra',
  },
  {
    id: 'cleo-tech',
    symbol: '$cleo',
    name: 'Cleo Tech',
  },
  {
    id: 'clever-token',
    symbol: 'clev',
    name: 'CLever',
  },
  {
    id: 'clexy',
    symbol: 'clexy',
    name: 'Clexy',
  },
  {
    id: 'clfi',
    symbol: 'clfi',
    name: 'cLFi',
  },
  {
    id: 'clickart-ai',
    symbol: 'clickart',
    name: 'Clickart.ai',
  },
  {
    id: 'clintex-cti',
    symbol: 'cti',
    name: 'ClinTex CTi',
  },
  {
    id: 'clippy',
    symbol: 'clippy',
    name: 'Clippy',
  },
  {
    id: 'clippy-ai',
    symbol: '$clippy',
    name: 'Clippy AI',
  },
  {
    id: 'clips',
    symbol: 'clips',
    name: 'Clips',
  },
  {
    id: 'cliq',
    symbol: 'ct',
    name: 'CLIQ',
  },
  {
    id: 'cloakcoin',
    symbol: 'cloak',
    name: 'Cloakcoin',
  },
  {
    id: 'cloak-protocol',
    symbol: 'cloak',
    name: 'Cloak Protocol',
  },
  {
    id: 'clore-ai',
    symbol: 'clore',
    name: 'Clore.ai',
  },
  {
    id: 'cloudbase',
    symbol: 'cloud',
    name: 'CloudBase',
  },
  {
    id: 'cloudbric',
    symbol: 'clbk',
    name: 'Cloudbric',
  },
  {
    id: 'cloudcoin-finance',
    symbol: 'ccfi',
    name: 'CloudCoin Finance',
  },
  {
    id: 'cloud-mining-technologies',
    symbol: 'cxm',
    name: 'Cloud Mining Technologies',
  },
  {
    id: 'cloudname',
    symbol: 'cname',
    name: 'Cloudname',
  },
  {
    id: 'cloud-pet',
    symbol: 'cpet',
    name: 'Cloud Pet',
  },
  {
    id: 'cloudtx',
    symbol: 'cloud',
    name: 'CloudTx',
  },
  {
    id: 'cloutcontracts',
    symbol: 'ccs',
    name: 'CloutContracts',
  },
  {
    id: 'clover-finance',
    symbol: 'clv',
    name: 'Clover Finance',
  },
  {
    id: 'clown-pepe',
    symbol: 'honk',
    name: 'Clown Pepe',
  },
  {
    id: 'club-atletico-independiente',
    symbol: 'cai',
    name: 'Club Atletico Independiente Fan Token',
  },
  {
    id: 'club-deportivo-fan-token',
    symbol: 'chvs',
    name: 'Club Deportivo Guadalajara Fan Token',
  },
  {
    id: 'clube-atletico-mineiro-fan-token',
    symbol: 'galo',
    name: 'Clube Atlético Mineiro Fan Token',
  },
  {
    id: 'clubrare-empower',
    symbol: 'mpwr',
    name: 'Empower',
  },
  {
    id: 'club-santos-laguna-fan-token',
    symbol: 'san',
    name: 'Club Santos Laguna Fan Token',
  },
  {
    id: 'clucoin',
    symbol: 'clu',
    name: 'CluCoin',
  },
  {
    id: 'cncl',
    symbol: 'cncl',
    name: 'The Ordinals Council',
  },
  {
    id: 'cneta',
    symbol: 'cneta',
    name: 'cNETA',
  },
  {
    id: 'cnh-tether',
    symbol: 'cnht',
    name: 'CNH Tether',
  },
  {
    id: 'cnns',
    symbol: 'cnns',
    name: 'CNNS',
  },
  {
    id: 'co2dao',
    symbol: 'co2',
    name: 'Co2DAO',
  },
  {
    id: 'coalculus',
    symbol: 'coal',
    name: 'Coalculus',
  },
  {
    id: 'coast-cst',
    symbol: 'cst',
    name: 'Coast CST',
  },
  {
    id: 'cobak-token',
    symbol: 'cbk',
    name: 'Cobak',
  },
  {
    id: 'coban',
    symbol: 'coban',
    name: 'COBAN',
  },
  {
    id: 'cobra-king',
    symbol: 'cob',
    name: 'Cobra king',
  },
  {
    id: 'cobra-swap',
    symbol: 'cobra',
    name: 'Cobra Swap',
  },
  {
    id: 'cockapoo',
    symbol: 'cpoo',
    name: 'Cockapoo',
  },
  {
    id: 'cockboxing',
    symbol: 'cocks',
    name: 'CockBoxing',
  },
  {
    id: 'cockroach-coin',
    symbol: 'roachcoin',
    name: 'COCKROACH-COIN',
  },
  {
    id: 'cocktailbar',
    symbol: 'coc',
    name: 'The Cocktailbar',
  },
  {
    id: 'coco',
    symbol: 'coco',
    name: 'Coco',
  },
  {
    id: 'coconut-chicken',
    symbol: '$ccc',
    name: 'Coconut Chicken',
  },
  {
    id: 'cocos-bcx',
    symbol: 'combo',
    name: 'COMBO',
  },
  {
    id: 'coco-theheist',
    symbol: 'coco',
    name: 'Coco',
  },
  {
    id: 'coda',
    symbol: 'coda',
    name: 'CODA',
  },
  {
    id: 'codai',
    symbol: 'codai',
    name: 'CODAI',
  },
  {
    id: 'codex',
    symbol: 'cdex',
    name: 'Codex',
  },
  {
    id: 'codexchain',
    symbol: 'cdx',
    name: 'CodeXChain',
  },
  {
    id: 'codex-multichain',
    symbol: 'codex',
    name: 'Codex Multichain',
  },
  {
    id: 'cofix',
    symbol: 'cofi',
    name: 'CoFiX',
  },
  {
    id: 'cogecoin',
    symbol: 'coge',
    name: 'Cogecoin',
  },
  {
    id: 'cogent-sol',
    symbol: 'cgntsol',
    name: 'Cogent SOL',
  },
  {
    id: 'cogito-protocol',
    symbol: 'cgv',
    name: 'Cogito Protocol',
  },
  {
    id: 'coin-2',
    symbol: 'coin',
    name: 'COIN',
  },
  {
    id: 'coin98',
    symbol: 'c98',
    name: 'Coin98',
  },
  {
    id: 'coin98-dollar',
    symbol: 'cusd',
    name: 'Coin98 Dollar',
  },
  {
    id: 'coinary-token',
    symbol: 'cyt',
    name: 'Coinary',
  },
  {
    id: 'coinback',
    symbol: 'cbk',
    name: 'Coinback',
  },
  {
    id: 'coinbase-tokenized-stock-defichain',
    symbol: 'dcoin',
    name: 'Coinbase Tokenized Stock Defichain',
  },
  {
    id: 'coinbase-wrapped-staked-eth',
    symbol: 'cbeth',
    name: 'Coinbase Wrapped Staked ETH',
  },
  {
    id: 'coinbet-finance',
    symbol: 'cfi',
    name: 'Coinbet Finance',
  },
  {
    id: 'coinbidex',
    symbol: 'cbe',
    name: 'Coinbidex',
  },
  {
    id: 'coinbot',
    symbol: 'coinbt',
    name: 'CoinBot',
  },
  {
    id: 'coinbuck',
    symbol: 'buck',
    name: 'CoinBuck',
  },
  {
    id: 'coin-capsule',
    symbol: 'caps',
    name: 'Ternoa',
  },
  {
    id: 'coinclaim',
    symbol: 'clm',
    name: 'CoinClaim',
  },
  {
    id: 'coindom',
    symbol: 'scc',
    name: 'Stem Cell Coin',
  },
  {
    id: 'coinecta',
    symbol: 'cnct',
    name: 'Coinecta',
  },
  {
    id: 'coin-edelweis',
    symbol: 'edel',
    name: 'Coin Edelweis',
  },
  {
    id: 'coinex-token',
    symbol: 'cet',
    name: 'CoinEx',
  },
  {
    id: 'coinfi',
    symbol: 'cofi',
    name: 'CoinFi',
  },
  {
    id: 'coinfirm-amlt',
    symbol: 'amlt',
    name: 'AMLT Network',
  },
  {
    id: 'coingrab',
    symbol: 'grab',
    name: 'CoinGrab',
  },
  {
    id: 'coinhiba',
    symbol: 'hiba',
    name: 'Coinhiba',
  },
  {
    id: 'coinhound',
    symbol: 'cnd',
    name: 'Coinhound',
  },
  {
    id: 'coinhub',
    symbol: 'chb',
    name: 'COINHUB',
  },
  {
    id: 'coinloan',
    symbol: 'clt',
    name: 'CoinLoan',
  },
  {
    id: 'coinlocally',
    symbol: 'clyc',
    name: 'Coinlocally',
  },
  {
    id: 'coinmarketprime',
    symbol: 'cmp',
    name: 'COINMARKETPRIME',
  },
  {
    id: 'coinmart-finance',
    symbol: 'cex',
    name: 'Coinmart Finance',
  },
  {
    id: 'coinmatch-ai',
    symbol: 'cmai',
    name: 'CoinMatch AI',
  },
  {
    id: 'coinmerge-os',
    symbol: 'cmos',
    name: 'CoinMerge OS',
  },
  {
    id: 'coinmetro',
    symbol: 'xcm',
    name: 'Coinmetro',
  },
  {
    id: 'coinmix',
    symbol: 'cm',
    name: 'Coinmix',
  },
  {
    id: 'coinmooner',
    symbol: 'mooner',
    name: 'CoinMooner',
  },
  {
    id: 'coinnavigator',
    symbol: 'cng',
    name: 'CoinNavigator',
  },
  {
    id: 'coin-of-nature',
    symbol: 'con',
    name: 'Coin of Nature',
  },
  {
    id: 'coin-of-the-champions',
    symbol: 'coc',
    name: 'Coin of the champions',
  },
  {
    id: 'coinopy',
    symbol: 'coy',
    name: 'Coinopy',
  },
  {
    id: 'coinpoker',
    symbol: 'chp',
    name: 'CoinPoker',
  },
  {
    id: 'coinracer',
    symbol: 'crace',
    name: 'Coinracer',
  },
  {
    id: 'coinsale-token',
    symbol: 'coinsale',
    name: 'CoinSale Token',
  },
  {
    id: 'coinsbit-token',
    symbol: 'cnb',
    name: 'Coinsbit Token',
  },
  {
    id: 'coinscope',
    symbol: 'coinscope',
    name: 'Coinscope',
  },
  {
    id: 'coinspaid',
    symbol: 'cpd',
    name: 'CoinsPaid',
  },
  {
    id: 'coinw',
    symbol: 'cwt',
    name: 'CoinW',
  },
  {
    id: 'coinwealth',
    symbol: 'cnw',
    name: 'CoinWealth',
  },
  {
    id: 'coinweb',
    symbol: 'cweb',
    name: 'Coinweb',
  },
  {
    id: 'coinwind',
    symbol: 'cow',
    name: 'CoinWind',
  },
  {
    id: 'coinxpad',
    symbol: 'cxpad',
    name: 'CoinxPad',
  },
  {
    id: 'coinzix-token',
    symbol: 'zix',
    name: 'Coinzix Token',
  },
  {
    id: 'cola-token-2',
    symbol: 'cola',
    name: 'Cola Token',
  },
  {
    id: 'colb-usd-stablecolb',
    symbol: 'scb',
    name: 'USD Stable Colb',
  },
  {
    id: 'coldstack',
    symbol: 'cls',
    name: 'Coldstack',
  },
  {
    id: 'colizeum',
    symbol: 'zeum',
    name: 'Colizeum',
  },
  {
    id: 'collab-land',
    symbol: 'collab',
    name: 'Collab.Land',
  },
  {
    id: 'collateralized-debt-token',
    symbol: 'cdt',
    name: 'Collateralized Debt Token',
  },
  {
    id: 'collateral-network',
    symbol: 'colt',
    name: 'Collateral Network',
  },
  {
    id: 'collective-care',
    symbol: 'cct',
    name: 'Collective Care',
  },
  {
    id: 'collector-coin',
    symbol: 'ags',
    name: 'Collector Coin',
  },
  {
    id: 'collie-inu',
    symbol: 'collie',
    name: 'COLLIE INU',
  },
  {
    id: 'colony',
    symbol: 'cly',
    name: 'Colony',
  },
  {
    id: 'colony-avalanche-index',
    symbol: 'cai',
    name: 'Colony Avalanche Index',
  },
  {
    id: 'colony-network-token',
    symbol: 'clny',
    name: 'Colony Network',
  },
  {
    id: 'colossuscoinxt',
    symbol: 'colx',
    name: 'ColossusXT',
  },
  {
    id: 'colr-coin',
    symbol: '$colr',
    name: 'colR Coin',
  },
  {
    id: 'coma-online',
    symbol: 'coma',
    name: 'Coma Online',
  },
  {
    id: 'comb-finance',
    symbol: 'comb',
    name: 'Comb Finance',
  },
  {
    id: 'combustion',
    symbol: 'fire',
    name: 'Combustion',
  },
  {
    id: 'comdex',
    symbol: 'cmdx',
    name: 'COMDEX',
  },
  {
    id: 'comet-token',
    symbol: 'comet',
    name: 'Comet Token',
  },
  {
    id: 'common',
    symbol: 'cmn',
    name: 'Common',
  },
  {
    id: 'community-business-token',
    symbol: 'cbt',
    name: 'Community Business Token',
  },
  {
    id: 'community-inu',
    symbol: 'cti',
    name: 'Community Inu',
  },
  {
    id: 'communitytoken',
    symbol: 'ct',
    name: 'Cojam',
  },
  {
    id: 'com-ordinals',
    symbol: '.com',
    name: '.com (Ordinals)',
  },
  {
    id: 'companionbot',
    symbol: 'cbot',
    name: 'CompanionBot',
  },
  {
    id: 'companion-pet-coin',
    symbol: 'cpc',
    name: 'Companion Pet Coin',
  },
  {
    id: 'compendium-fi',
    symbol: 'cmfi',
    name: 'Compendium',
  },
  {
    id: 'composite',
    symbol: 'cmst',
    name: 'Composite',
  },
  {
    id: 'compound-0x',
    symbol: 'czrx',
    name: 'c0x',
  },
  {
    id: 'compound-basic-attention-token',
    symbol: 'cbat',
    name: 'cBAT',
  },
  {
    id: 'compound-chainlink-token',
    symbol: 'clink',
    name: 'cLINK',
  },
  {
    id: 'compounded-marinated-umami',
    symbol: 'cmumami',
    name: 'Compounded Marinated UMAMI',
  },
  {
    id: 'compound-ether',
    symbol: 'ceth',
    name: 'cETH',
  },
  {
    id: 'compound-governance-token',
    symbol: 'comp',
    name: 'Compound',
  },
  {
    id: 'compound-maker',
    symbol: 'cmkr',
    name: 'cMKR',
  },
  {
    id: 'compound-meta',
    symbol: 'coma',
    name: 'Compound Meta',
  },
  {
    id: 'compound-sushi',
    symbol: 'csushi',
    name: 'cSUSHI',
  },
  {
    id: 'compound-uniswap',
    symbol: 'cuni',
    name: 'cUNI',
  },
  {
    id: 'compound-usd-coin',
    symbol: 'cusdc',
    name: 'cUSDC',
  },
  {
    id: 'compound-wrapped-btc',
    symbol: 'cwbtc',
    name: 'cWBTC',
  },
  {
    id: 'compound-yearn-finance',
    symbol: 'cyfi',
    name: 'cYFI',
  },
  {
    id: 'comp-yvault',
    symbol: 'yvcomp',
    name: 'COMP yVault',
  },
  {
    id: 'comsats',
    symbol: 'csas',
    name: 'Comsats',
  },
  {
    id: 'comtech-gold',
    symbol: 'cgo',
    name: 'Comtech Gold',
  },
  {
    id: 'conan',
    symbol: 'conan',
    name: 'CONAN',
  },
  {
    id: 'concave',
    symbol: 'cnv',
    name: 'Concave',
  },
  {
    id: 'conceal',
    symbol: 'ccx',
    name: 'Conceal',
  },
  {
    id: 'concentrated-voting-power',
    symbol: 'cvp',
    name: 'PowerPool Concentrated Voting Power',
  },
  {
    id: 'concentrator',
    symbol: 'ctr',
    name: 'Concentrator',
  },
  {
    id: 'concentric-fi',
    symbol: 'cone',
    name: 'Concentric.fi',
  },
  {
    id: 'concertvr',
    symbol: 'cvt',
    name: 'concertVR',
  },
  {
    id: 'concierge-io',
    symbol: 'ava',
    name: 'AVA',
  },
  {
    id: 'concordium',
    symbol: 'ccd',
    name: 'Concordium',
  },
  {
    id: 'conflux-token',
    symbol: 'cfx',
    name: 'Conflux',
  },
  {
    id: 'conic-finance',
    symbol: 'cnc',
    name: 'Conic',
  },
  {
    id: 'coniun',
    symbol: 'coni',
    name: 'Coniun',
  },
  {
    id: 'connect-financial',
    symbol: 'cnfi',
    name: 'Connect Financial',
  },
  {
    id: 'connectome',
    symbol: 'cntm',
    name: 'Connectome',
  },
  {
    id: 'connex',
    symbol: 'conx',
    name: 'Connex',
  },
  {
    id: 'connext',
    symbol: 'next',
    name: 'Connext',
  },
  {
    id: 'consciousdao',
    symbol: 'cvn',
    name: 'ConsciousDao',
  },
  {
    id: 'constellation-labs',
    symbol: 'dag',
    name: 'Constellation',
  },
  {
    id: 'constitutiondao',
    symbol: 'people',
    name: 'ConstitutionDAO',
  },
  {
    id: 'constitutiondao-wormhole',
    symbol: 'people',
    name: 'ConstitutionDAO (Wormhole)',
  },
  {
    id: 'contentbox',
    symbol: 'box',
    name: 'ContentBox',
  },
  {
    id: 'contentos',
    symbol: 'cos',
    name: 'Contentos',
  },
  {
    id: 'content-value-network',
    symbol: 'cvnt',
    name: 'Conscious Value Network',
  },
  {
    id: 'continuum-finance',
    symbol: 'ctn',
    name: 'Continuum Finance',
  },
  {
    id: 'continuum-world',
    symbol: 'um',
    name: 'Continuum World',
  },
  {
    id: 'contracoin',
    symbol: 'ctcn',
    name: 'Contracoin',
  },
  {
    id: 'contract-address-meme',
    symbol: 'ca',
    name: 'contract address (Meme)',
  },
  {
    id: 'contract-dev-ai',
    symbol: '0xdev',
    name: 'DEVAI',
  },
  {
    id: 'contractus',
    symbol: 'ctus',
    name: 'Contractus',
  },
  {
    id: 'contrax',
    symbol: 'contrax',
    name: 'Contrax',
  },
  {
    id: 'conun',
    symbol: 'con',
    name: 'CONUN',
  },
  {
    id: 'converge-bot',
    symbol: 'converge',
    name: 'Converge Bot',
  },
  {
    id: 'convergence',
    symbol: 'conv',
    name: 'Convergence',
  },
  {
    id: 'converter-finance',
    symbol: 'con',
    name: 'Converter Finance',
  },
  {
    id: 'convertible-jpy-token',
    symbol: 'cjpy',
    name: 'Convertible JPY Token',
  },
  {
    id: 'convex-crv',
    symbol: 'cvxcrv',
    name: 'Convex CRV',
  },
  {
    id: 'convex-finance',
    symbol: 'cvx',
    name: 'Convex Finance',
  },
  {
    id: 'convex-fpis',
    symbol: 'cvxfpis',
    name: 'Convex FPIS',
  },
  {
    id: 'convex-fxn',
    symbol: 'cvxfxn',
    name: 'Convex FXN',
  },
  {
    id: 'convex-fxs',
    symbol: 'cvxfxs',
    name: 'Convex FXS',
  },
  {
    id: 'convex-prisma',
    symbol: 'cvxprisma',
    name: 'Convex Prisma',
  },
  {
    id: 'cook',
    symbol: 'cook',
    name: 'Cook',
  },
  {
    id: 'cook-2',
    symbol: 'cook',
    name: '$COOK',
  },
  {
    id: 'cook-3',
    symbol: 'cook',
    name: 'Cook',
  },
  {
    id: 'cookiebase',
    symbol: 'cookie',
    name: 'CookieBase',
  },
  {
    id: 'cookies-protocol',
    symbol: 'cp',
    name: 'Cookies Protocol',
  },
  {
    id: 'coop-coin',
    symbol: 'coop',
    name: 'Coop Coin',
  },
  {
    id: 'cooper',
    symbol: 'cooper',
    name: 'Cooper',
  },
  {
    id: 'cope',
    symbol: 'cope',
    name: 'Cope',
  },
  {
    id: 'cope-coin',
    symbol: 'cope',
    name: 'Cope Coin',
  },
  {
    id: 'cope-token',
    symbol: 'cope',
    name: 'Cope Token',
  },
  {
    id: 'copiosa',
    symbol: 'cop',
    name: 'Copiosa',
  },
  {
    id: 'copybot',
    symbol: 'copybot',
    name: 'Copybot',
  },
  {
    id: 'copycat-dao',
    symbol: 'ccd',
    name: 'Copycat DAO',
  },
  {
    id: 'copycat-finance',
    symbol: 'copycat',
    name: 'Copycat Finance',
  },
  {
    id: 'coq-inu',
    symbol: 'coq',
    name: 'Coq Inu',
  },
  {
    id: 'coral-swap',
    symbol: 'coral',
    name: 'Coral Swap',
  },
  {
    id: 'core',
    symbol: 'cmcx',
    name: 'CORE MultiChain',
  },
  {
    id: 'coredao',
    symbol: 'coredao',
    name: 'coreDAO',
  },
  {
    id: 'coredaoorg',
    symbol: 'core',
    name: 'Core',
  },
  {
    id: 'coredaoswap',
    symbol: 'cdao',
    name: 'CoreDaoSwap',
  },
  {
    id: 'core-id',
    symbol: 'cid',
    name: 'CORE ID',
  },
  {
    id: 'core-ordinals',
    symbol: 'core',
    name: 'CORE (Ordinals)',
  },
  {
    id: 'corestarter',
    symbol: 'cstr',
    name: 'CoreStarter',
  },
  {
    id: 'coreto',
    symbol: 'cor',
    name: 'COR Token',
  },
  {
    id: 'coreum',
    symbol: 'coreum',
    name: 'Coreum',
  },
  {
    id: 'corgiai',
    symbol: 'corgiai',
    name: 'CorgiAI',
  },
  {
    id: 'corgi-ceo',
    symbol: 'corgiceo',
    name: 'CORGI CEO',
  },
  {
    id: 'corgicoin',
    symbol: 'corgi',
    name: 'CorgiCoin',
  },
  {
    id: 'corgidoge',
    symbol: 'corgi',
    name: 'Corgidoge',
  },
  {
    id: 'corionx',
    symbol: 'corx',
    name: 'CorionX',
  },
  {
    id: 'corite',
    symbol: 'co',
    name: 'Corite',
  },
  {
    id: 'coritiba-f-c-fan-token',
    symbol: 'crtb',
    name: 'Coritiba F.C. Fan Token',
  },
  {
    id: 'corn',
    symbol: 'corn',
    name: 'CORN',
  },
  {
    id: 'cornatto',
    symbol: 'cnc',
    name: 'Cornatto',
  },
  {
    id: 'cornermarket',
    symbol: 'cmt',
    name: 'CornerMarket',
  },
  {
    id: 'cornucopias',
    symbol: 'copi',
    name: 'Cornucopias',
  },
  {
    id: 'cortex',
    symbol: 'ctxc',
    name: 'Cortex',
  },
  {
    id: 'cosanta',
    symbol: 'cosa',
    name: 'Cosanta',
  },
  {
    id: 'coshi-inu',
    symbol: 'coshi',
    name: 'CoShi Inu',
  },
  {
    id: 'cosmic-chain',
    symbol: 'cosmic',
    name: 'Cosmic Chain',
  },
  {
    id: 'cosmic-champs',
    symbol: 'cosg',
    name: 'Cosmic Champs',
  },
  {
    id: 'cosmic-fomo',
    symbol: 'cosmic',
    name: 'Cosmic FOMO',
  },
  {
    id: 'cosmicswap',
    symbol: 'cosmic',
    name: 'CosmicSwap',
  },
  {
    id: 'cosmic-universe-magic-token',
    symbol: 'magick',
    name: 'Cosmic Universe Magick',
  },
  {
    id: 'cosmos',
    symbol: 'atom',
    name: 'Cosmos Hub',
  },
  {
    id: 'cosplay-token-2',
    symbol: 'cot',
    name: 'Cosplay Token',
  },
  {
    id: 'coti',
    symbol: 'coti',
    name: 'COTI',
  },
  {
    id: 'coti-governance-token',
    symbol: 'gcoti',
    name: 'COTI Governance Token',
  },
  {
    id: 'cotrader',
    symbol: 'cot',
    name: 'CoTrader',
  },
  {
    id: 'cougar-token',
    symbol: 'cgs',
    name: 'CougarSwap',
  },
  {
    id: 'coughing-cat',
    symbol: 'cct',
    name: 'Coughing Cat',
  },
  {
    id: 'could-be-the-move',
    symbol: 'cbtm',
    name: 'Could Be The Move',
  },
  {
    id: 'counosx',
    symbol: 'ccxx',
    name: 'CounosX',
  },
  {
    id: 'counterparty',
    symbol: 'xcp',
    name: 'Counterparty',
  },
  {
    id: 'couponbay',
    symbol: 'cup',
    name: 'CouponBay',
  },
  {
    id: 'covalent',
    symbol: 'cqt',
    name: 'Covalent',
  },
  {
    id: 'covenant-child',
    symbol: 'covn',
    name: 'Covenant',
  },
  {
    id: 'cover-protocol',
    symbol: 'cover',
    name: 'Cover Protocol',
  },
  {
    id: 'covesting',
    symbol: 'cov',
    name: 'Covesting',
  },
  {
    id: 'cow-protocol',
    symbol: 'cow',
    name: 'CoW Protocol',
  },
  {
    id: 'cowrie',
    symbol: 'cowrie',
    name: 'Cowrie',
  },
  {
    id: 'cpchain',
    symbol: 'cpc',
    name: 'CPChain',
  },
  {
    id: 'cpiggy-bank-token',
    symbol: 'cpiggy',
    name: 'cPIGGY Bank Token',
  },
  {
    id: 'cpos-cloud-payment',
    symbol: 'cpos',
    name: 'CPOS Cloud Payment',
  },
  {
    id: 'cpucoin',
    symbol: 'cpu',
    name: 'CPUcoin',
  },
  {
    id: 'crabada',
    symbol: 'cra',
    name: 'Crabada',
  },
  {
    id: 'crab-market',
    symbol: 'crab',
    name: 'Crab Market',
  },
  {
    id: 'crab-rave-token',
    symbol: 'crabs',
    name: 'Crab Rave Token',
  },
  {
    id: 'cracle',
    symbol: 'cra',
    name: 'Cracle',
  },
  {
    id: 'cradle-of-sins',
    symbol: 'cos',
    name: 'Cradle of Sins',
  },
  {
    id: 'crafting-finance',
    symbol: 'crf',
    name: 'Crafting Finance',
  },
  {
    id: 'craft-network',
    symbol: 'cft',
    name: 'Craft network',
  },
  {
    id: 'cramer-coin',
    symbol: '$cramer',
    name: 'Cramer Coin',
  },
  {
    id: 'cratos',
    symbol: 'crts',
    name: 'Cratos',
  },
  {
    id: 'crazy-bunny',
    symbol: 'crazybunny',
    name: 'Crazy Bunny',
  },
  {
    id: 'crazy-bunny-equity-token',
    symbol: 'cbunny',
    name: 'Crazy Bunny Equity',
  },
  {
    id: 'crazy-frog',
    symbol: 'crazy',
    name: 'Crazy Frog',
  },
  {
    id: 'crazy-internet-coin',
    symbol: 'cic',
    name: 'Crazy Internet Coin',
  },
  {
    id: 'crazypepe',
    symbol: 'crazypepe',
    name: 'CRAZYPEPE',
  },
  {
    id: 'crazyrabbit',
    symbol: 'crc',
    name: 'CrazyRabbit',
  },
  {
    id: 'crazy-tiger',
    symbol: 'crazytiger',
    name: 'Crazy Tiger',
  },
  {
    id: 'crb-coin',
    symbol: 'crb',
    name: 'CRB Coin',
  },
  {
    id: 'crds',
    symbol: 'crds',
    name: 'Cradles',
  },
  {
    id: 'cre8r-dao',
    symbol: 'cre8r',
    name: 'CRE8R DAO',
  },
  {
    id: 'cream',
    symbol: 'crm',
    name: 'Creamcoin',
  },
  {
    id: 'cream-2',
    symbol: 'cream',
    name: 'Cream',
  },
  {
    id: 'creamlands',
    symbol: 'cream',
    name: 'Creamlands',
  },
  {
    id: 'creamy',
    symbol: 'creamy',
    name: 'Creamy',
  },
  {
    id: 'creaticles',
    symbol: 'cre8',
    name: 'Creaticles',
  },
  {
    id: 'creator-platform',
    symbol: 'ctr',
    name: 'Creator Platform',
  },
  {
    id: 'creda',
    symbol: 'creda',
    name: 'CreDA',
  },
  {
    id: 'credefi',
    symbol: 'credi',
    name: 'Credefi',
  },
  {
    id: 'credit-2',
    symbol: 'credit',
    name: 'PROXI DeFi',
  },
  {
    id: 'creditcoin-2',
    symbol: 'ctc',
    name: 'Creditcoin',
  },
  {
    id: 'credits',
    symbol: 'cs',
    name: 'CREDITS',
  },
  {
    id: 'cremate',
    symbol: 'crmt',
    name: 'Cremate',
  },
  {
    id: 'cremation-coin',
    symbol: 'cremat',
    name: 'Cremation Coin',
  },
  {
    id: 'creo-engine',
    symbol: 'creo',
    name: 'Creo Engine',
  },
  {
    id: 'crescent-network',
    symbol: 'cre',
    name: 'Crescent Network',
  },
  {
    id: 'crescentswap-moonlight',
    symbol: 'mnlt',
    name: 'Crescentswap Moonlight',
  },
  {
    id: 'crescite',
    symbol: 'crescite',
    name: 'Crescite',
  },
  {
    id: 'cresio',
    symbol: 'xcre',
    name: 'Cresio',
  },
  {
    id: 'creso',
    symbol: 'cre',
    name: 'Creso [OLD]',
  },
  {
    id: 'creso-2',
    symbol: 'cre',
    name: 'Creso',
  },
  {
    id: 'creta-world',
    symbol: 'creta',
    name: 'Creta World',
  },
  {
    id: 'cri3x',
    symbol: 'cri3x',
    name: 'Cri3x',
  },
  {
    id: 'cricket-foundation',
    symbol: 'cric',
    name: 'Cricket Foundation',
  },
  {
    id: 'cricket-star-manager',
    symbol: 'csm',
    name: 'Cricket Star Manager',
  },
  {
    id: 'crimson',
    symbol: 'crm',
    name: 'Crimson',
  },
  {
    id: 'crimson-network',
    symbol: 'crimson',
    name: 'Crimson Network',
  },
  {
    id: 'criptoville-coins-2',
    symbol: 'cvlc2',
    name: 'CriptoVille Coins 2',
  },
  {
    id: 'crisp-scored-cookstoves',
    symbol: 'crisp-c',
    name: 'CRISP Scored Cookstoves',
  },
  {
    id: 'crisp-scored-mangroves',
    symbol: 'crisp-m',
    name: 'CRISP Scored Mangroves',
  },
  {
    id: 'croatian-ff-fan-token',
    symbol: 'vatreni',
    name: 'Croatian FF Fan Token',
  },
  {
    id: 'crocbot',
    symbol: 'croc',
    name: 'CrocBot',
  },
  {
    id: 'croc-cat',
    symbol: 'croc',
    name: 'Croc CAT',
  },
  {
    id: 'crodex',
    symbol: 'crx',
    name: 'Crodex',
  },
  {
    id: 'crodie',
    symbol: 'crodie',
    name: 'Crodie',
  },
  {
    id: 'crogecoin',
    symbol: 'croge',
    name: 'Crogecoin',
  },
  {
    id: 'croissant-games',
    symbol: 'croissant',
    name: 'Croissant Games',
  },
  {
    id: 'croking',
    symbol: 'crk',
    name: 'Croking',
  },
  {
    id: 'crolon-mars',
    symbol: 'clmrs',
    name: 'Crolon Mars',
  },
  {
    id: 'cronaswap',
    symbol: 'crona',
    name: 'CronaSwap',
  },
  {
    id: 'cronos-id',
    symbol: 'croid',
    name: 'Cronos ID',
  },
  {
    id: 'cronosverse',
    symbol: 'vrse',
    name: 'CronosVerse',
  },
  {
    id: 'cronus',
    symbol: 'cronus',
    name: 'CRONUS',
  },
  {
    id: 'cropbytes',
    symbol: 'cbx',
    name: 'CropBytes',
  },
  {
    id: 'cropperfinance',
    symbol: 'crp',
    name: 'CropperFinance',
  },
  {
    id: 'cross-chain-bridge',
    symbol: 'bridge',
    name: 'Cross-Chain Bridge',
  },
  {
    id: 'crosschain-iotx',
    symbol: 'ciotx',
    name: 'Crosschain IOTX',
  },
  {
    id: 'crossdex',
    symbol: 'cdx',
    name: 'CrossDEX',
  },
  {
    id: 'crossfi',
    symbol: 'crfi',
    name: 'CrossFi',
  },
  {
    id: 'crossfi-2',
    symbol: 'xfi',
    name: 'CrossFi',
  },
  {
    id: 'crossswap',
    symbol: 'cswap',
    name: 'CrossSwap',
  },
  {
    id: 'crosswallet',
    symbol: 'cwt',
    name: 'CrossWallet',
  },
  {
    id: 'crowdswap',
    symbol: 'crowd',
    name: 'CrowdSwap',
  },
  {
    id: 'crown',
    symbol: 'crw',
    name: 'Crown',
  },
  {
    id: 'crown-by-third-time-games',
    symbol: 'crown',
    name: 'Crown by Third Time Games',
  },
  {
    id: 'crowns',
    symbol: 'cws',
    name: 'Seascape Crowns',
  },
  {
    id: 'crown-sovereign',
    symbol: 'csov',
    name: 'Crown Sovereign',
  },
  {
    id: 'crown-token-77469f91-69f6-44dd-b356-152e2c39c0cc',
    symbol: 'crown',
    name: 'Crown Token',
  },
  {
    id: 'crowny-token',
    symbol: 'crwny',
    name: 'Crowny',
  },
  {
    id: 'crunchcat',
    symbol: 'crunch',
    name: 'Crunchcat',
  },
  {
    id: 'crunchy-dao',
    symbol: 'crdao',
    name: 'Crunchy DAO',
  },
  {
    id: 'crunchy-network',
    symbol: 'crnchy',
    name: 'Crunchy Network',
  },
  {
    id: 'crusaders-of-crypto',
    symbol: 'crusader',
    name: 'Crusaders of Crypto',
  },
  {
    id: 'crust-exchange',
    symbol: 'crust',
    name: 'Crust Exchange',
  },
  {
    id: 'crust-network',
    symbol: 'cru',
    name: 'Crust Network',
  },
  {
    id: 'crust-storage-market',
    symbol: 'csm',
    name: 'Crust Shadow',
  },
  {
    id: 'crvusd',
    symbol: 'crvusd',
    name: 'crvUSD',
  },
  {
    id: 'cryn',
    symbol: 'cryn',
    name: 'CRYN',
  },
  {
    id: 'cryodao',
    symbol: 'cryo',
    name: 'CryoDAO',
  },
  {
    id: 'cryowar-token',
    symbol: 'cwar',
    name: 'Cryowar',
  },
  {
    id: 'cryptaur',
    symbol: 'cpt',
    name: 'Cryptaur',
  },
  {
    id: 'cryptegrity-dao',
    symbol: 'escrow',
    name: 'Cryptegrity Dao',
  },
  {
    id: 'crypterium',
    symbol: 'crpt',
    name: 'Crypterium',
  },
  {
    id: 'cryptex-finance',
    symbol: 'ctx',
    name: 'Cryptex Finance',
  },
  {
    id: 'cryptiq-web3',
    symbol: 'cryptq',
    name: 'Cryptiq WEB3',
  },
  {
    id: 'cryptmi',
    symbol: 'cymi',
    name: 'CryptMi',
  },
  {
    id: 'cryptoai',
    symbol: 'cai',
    name: 'CryptoAI',
  },
  {
    id: 'crypto-ai',
    symbol: 'cai',
    name: 'Crypto AI',
  },
  {
    id: 'cryptoai-2',
    symbol: 'cryptoai',
    name: 'CryptoAI',
  },
  {
    id: 'crypto-ai-robo',
    symbol: 'cair',
    name: 'Crypto-AI-Robo',
  },
  {
    id: 'cryptoart-ai',
    symbol: 'cart',
    name: 'CryptoArt.Ai',
  },
  {
    id: 'crypto-asset-governance-alliance',
    symbol: 'caga',
    name: 'Crypto Asset Governance Alliance',
  },
  {
    id: 'cryptobank',
    symbol: 'cbex',
    name: 'CryptoBank',
  },
  {
    id: 'crypto-bet',
    symbol: '$cbet',
    name: 'Crypto Bet',
  },
  {
    id: 'crypto-birds',
    symbol: 'xcb',
    name: 'Crypto Birds',
  },
  {
    id: 'cryptoblades',
    symbol: 'skill',
    name: 'CryptoBlades',
  },
  {
    id: 'cryptoblades-kingdoms',
    symbol: 'king',
    name: 'CryptoBlades Kingdoms',
  },
  {
    id: 'cryptobonusmiles',
    symbol: 'cbm',
    name: 'CryptoBonusMiles',
  },
  {
    id: 'crypto-bros',
    symbol: 'bros',
    name: 'Crypto Bros',
  },
  {
    id: 'crypto-carbon-energy-2',
    symbol: 'cyce',
    name: 'Crypto Carbon Energy',
  },
  {
    id: 'cryptocarsreborn',
    symbol: 'ccr',
    name: 'CryptoCarsReborn',
  },
  {
    id: 'cryptocart',
    symbol: 'ccv2',
    name: 'CryptoCart V2',
  },
  {
    id: 'crypto-chests',
    symbol: 'cht',
    name: 'Crypto Chests',
  },
  {
    id: 'crypto-chicks',
    symbol: 'chicks',
    name: 'CRYPTO CHICKS',
  },
  {
    id: 'crypto-classic',
    symbol: 'crc',
    name: 'Crypto Classic',
  },
  {
    id: 'cryptoclicker-game-token',
    symbol: 'clicker',
    name: 'CryptoClicker Game Token',
  },
  {
    id: 'cryptoclicker-supper-token',
    symbol: 'supper',
    name: 'CryptoClicker SUPPER Token',
  },
  {
    id: 'crypto-clouds',
    symbol: 'cloud',
    name: 'CRYPTO CLOUDS',
  },
  {
    id: 'crypto-clubs-app',
    symbol: 'cc',
    name: 'Crypto Clubs App',
  },
  {
    id: 'cryptocoinhash',
    symbol: 'cch',
    name: 'CryptoCoinHash',
  },
  {
    id: 'crypto-com-chain',
    symbol: 'cro',
    name: 'Cronos',
  },
  {
    id: 'crypto-com-staked-eth',
    symbol: 'cdceth',
    name: 'Crypto.com Staked ETH',
  },
  {
    id: 'crypto-development-services',
    symbol: 'cds',
    name: 'Crypto Development Services',
  },
  {
    id: 'crypto-emergency',
    symbol: 'cem',
    name: 'Crypto Emergency',
  },
  {
    id: 'cryptoexpress',
    symbol: 'xpress',
    name: 'CryptoXpress',
  },
  {
    id: 'cryptoflow',
    symbol: 'cfl',
    name: 'Cryptoflow',
  },
  {
    id: 'cryptoforce',
    symbol: 'cof',
    name: 'Cryptoforce',
  },
  {
    id: 'cryptofranc',
    symbol: 'xchf',
    name: 'CryptoFranc',
  },
  {
    id: 'cryptogcoin',
    symbol: 'crg',
    name: 'Cryptogcoin',
  },
  {
    id: 'crypto-gladiator-shards',
    symbol: 'cgl',
    name: 'Crypto Gladiator League',
  },
  {
    id: 'crypto-global-united',
    symbol: 'cgu',
    name: 'Crypto Global United',
  },
  {
    id: 'cryptogpt-token',
    symbol: 'lai',
    name: 'LayerAI',
  },
  {
    id: 'crypto-holding-frank-token',
    symbol: 'chft',
    name: 'Crypto Holding Frank',
  },
  {
    id: 'crypto-hub',
    symbol: 'hub',
    name: 'Crypto Hub',
  },
  {
    id: 'crypto-index-pool',
    symbol: 'cip',
    name: 'Crypto Index Pool',
  },
  {
    id: 'crypto-island',
    symbol: 'cisla',
    name: 'Crypto Island',
  },
  {
    id: 'crypto-kart-racing',
    symbol: 'ckracing',
    name: 'Crypto Kart Racing',
  },
  {
    id: 'cryptokki',
    symbol: 'tokki',
    name: 'CRYPTOKKI',
  },
  {
    id: 'cryptoku',
    symbol: 'cku',
    name: 'Cryptoku',
  },
  {
    id: 'cryptomeda',
    symbol: 'tech',
    name: 'Cryptomeda',
  },
  {
    id: 'cryptomines-eternal',
    symbol: 'eternal',
    name: 'CryptoMines Eternal',
  },
  {
    id: 'cryptomines-reborn',
    symbol: 'crux',
    name: 'CryptoMines Reborn',
  },
  {
    id: 'cryptoneur-network-foundation',
    symbol: 'cnf',
    name: 'CryptoNeur Network foundation',
  },
  {
    id: 'crypto-news-flash-ai',
    symbol: 'cnf',
    name: 'Crypto News Flash AI',
  },
  {
    id: 'cryptonovae',
    symbol: 'yae',
    name: 'Cryptonovae',
  },
  {
    id: 'cryptopawcoin',
    symbol: 'cprc',
    name: 'CryptoPawCoin',
  },
  {
    id: 'cryptopay',
    symbol: 'cpay',
    name: 'Cryptopay',
  },
  {
    id: 'cryptoperformance-coin',
    symbol: 'cpc',
    name: 'CryptoPerformance Coin',
  },
  {
    id: 'crypto-perx',
    symbol: 'cprx',
    name: 'Crypto Perx',
  },
  {
    id: 'cryptopirates',
    symbol: 'ogmf',
    name: 'CryptoPirates',
  },
  {
    id: 'cryptopolis',
    symbol: 'cpo',
    name: 'Cryptopolis',
  },
  {
    id: 'crypto-puffs',
    symbol: 'puffs',
    name: 'Crypto Puffs',
  },
  {
    id: 'cryptopunk-7171-hoodie',
    symbol: 'hoodie',
    name: 'CryptoPunk #7171',
  },
  {
    id: 'cryptopunks-fraction-toke',
    symbol: 'ipunks',
    name: 'CryptoPunks Fraction Token',
  },
  {
    id: 'crypto-raiders',
    symbol: 'raider',
    name: 'Crypto Raiders',
  },
  {
    id: 'crypto-real-estate',
    symbol: 'cre',
    name: 'Crypto Real Estate',
  },
  {
    id: 'cryptorg-token',
    symbol: 'ctg',
    name: 'Cryptorg',
  },
  {
    id: 'crypto-royale',
    symbol: 'roy',
    name: 'Crypto Royale',
  },
  {
    id: 'cryptosaga',
    symbol: 'saga',
    name: 'CryptoSaga',
  },
  {
    id: 'crypto-sdg',
    symbol: 'sdg',
    name: 'Crypto SDG',
  },
  {
    id: 'cryptoshares',
    symbol: 'shares',
    name: 'Cryptoshares',
  },
  {
    id: 'cryptosnack',
    symbol: 'snack',
    name: 'Crypto Snack',
  },
  {
    id: 'cryptostone',
    symbol: 'cps',
    name: 'Cryptostone',
  },
  {
    id: 'crypto-street',
    symbol: 'cst',
    name: 'Crypto Street',
  },
  {
    id: 'crypto-street-v2',
    symbol: 'cstv2',
    name: 'CRYPTO STREET V2',
  },
  {
    id: 'cryptotanks',
    symbol: 'tank',
    name: 'CryptoTanks',
  },
  {
    id: 'cryptotask-2',
    symbol: 'ctask',
    name: 'CryptoTask',
  },
  {
    id: 'cryptotem',
    symbol: 'totem',
    name: 'Cryptotem',
  },
  {
    id: 'crypto-tex',
    symbol: 'ctex',
    name: 'CRYPTO TEX',
  },
  {
    id: 'crypto-threads',
    symbol: 'ct',
    name: 'Crypto Threads',
  },
  {
    id: 'cryptotwitter',
    symbol: 'ct',
    name: 'CryptoTwitter',
  },
  {
    id: 'cryptotycoon',
    symbol: 'ctt',
    name: 'CryptoTycoon',
  },
  {
    id: 'cryptounity',
    symbol: 'cut',
    name: 'CryptoUnity',
  },
  {
    id: 'crypto-village-accelerator-cvag',
    symbol: 'cvag',
    name: 'Crypto Village Accelerator CVAG',
  },
  {
    id: 'crypto-x',
    symbol: 'cx',
    name: 'Crypto X',
  },
  {
    id: 'cryptozoo',
    symbol: 'zoo',
    name: 'CryptoZoo',
  },
  {
    id: 'cryptozoon',
    symbol: 'zoon',
    name: 'CryptoZoon',
  },
  {
    id: 'cryptyk',
    symbol: 'ctk',
    name: 'Cryptyk',
  },
  {
    id: 'crystal-palace-fan-token',
    symbol: 'cpfc',
    name: 'Crystal Palace FC Fan Token',
  },
  {
    id: 'crystl-finance',
    symbol: 'crystl',
    name: 'Crystl Finance',
  },
  {
    id: 'csp-dao-network',
    symbol: 'nebo',
    name: 'CSP DAO Network',
  },
  {
    id: 'csr',
    symbol: 'csr',
    name: 'CSR',
  },
  {
    id: 'cswap',
    symbol: 'cswap',
    name: 'CSWAP',
  },
  {
    id: 'ctez',
    symbol: 'ctez',
    name: 'Ctez',
  },
  {
    id: 'cthulhu-finance',
    symbol: 'cth',
    name: 'Cthulhu Finance',
  },
  {
    id: 'ctomorrow-platform',
    symbol: 'ctp',
    name: 'Ctomorrow Platform',
  },
  {
    id: 'ctrl',
    symbol: 'ctrl',
    name: 'Ctrl',
  },
  {
    id: 'cubechain',
    symbol: 'qub',
    name: 'Cubechain',
  },
  {
    id: 'cube-intelligence',
    symbol: 'auto',
    name: 'Cube Intelligence',
  },
  {
    id: 'cuberium',
    symbol: 'iland',
    name: 'Cuberium',
  },
  {
    id: 'cub-finance',
    symbol: 'cub',
    name: 'Cub Finance',
  },
  {
    id: 'cubiex-power',
    symbol: 'cbix-p',
    name: 'Cubiex Power',
  },
  {
    id: 'cubiswap',
    symbol: 'cubi',
    name: 'CUBISWAP',
  },
  {
    id: 'cubtoken',
    symbol: 'cubt',
    name: 'CubToken',
  },
  {
    id: 'cuckadoodledoo',
    symbol: 'cuck',
    name: 'Cuckadoodledoo',
  },
  {
    id: 'cudos',
    symbol: 'cudos',
    name: 'Cudos',
  },
  {
    id: 'culo',
    symbol: 'culo',
    name: 'CULO',
  },
  {
    id: 'cult-dao',
    symbol: 'cult',
    name: 'Cult DAO',
  },
  {
    id: 'cuminu',
    symbol: 'cuminu',
    name: 'Cuminu',
  },
  {
    id: 'cumrocket',
    symbol: 'cummies',
    name: 'CumRocket',
  },
  {
    id: 'cumulus-encrypted-storage-system',
    symbol: 'cess',
    name: 'Cumulus Encrypted Storage System',
  },
  {
    id: 'curate',
    symbol: 'xcur',
    name: 'Curate',
  },
  {
    id: 'curecoin',
    symbol: 'cure',
    name: 'Curecoin',
  },
  {
    id: 'cure-token-v2',
    symbol: 'cure',
    name: 'CURE V2',
  },
  {
    id: 'curio-governance',
    symbol: 'cgt',
    name: 'Curio Governance',
  },
  {
    id: 'curvance',
    symbol: 'cve',
    name: 'Curvance',
  },
  {
    id: 'curve-dao-token',
    symbol: 'crv',
    name: 'Curve DAO',
  },
  {
    id: 'curve-fi-amdai-amusdc-amusdt',
    symbol: 'am3crv',
    name: 'Curve.fi amDAI/amUSDC/amUSDT',
  },
  {
    id: 'curve-fi-frax-usdc',
    symbol: 'crvfrax',
    name: 'Curve.fi FRAX/USDC',
  },
  {
    id: 'curve-fi-renbtc-wbtc-sbtc',
    symbol: 'crvrenwsbtc',
    name: 'Curve.fi renBTC/wBTC/sBTC',
  },
  {
    id: 'curve-fi-usdc-usdt',
    symbol: '2crv',
    name: 'Curve.fi USDC/USDT',
  },
  {
    id: 'curve-fi-usd-stablecoin-stargate',
    symbol: 'crvusd',
    name: 'Bridged Curve.Fi USD Stablecoin (Stargate)',
  },
  {
    id: 'curve-fi-wbtc-sbtc',
    symbol: 'crvwsbtc',
    name: 'Curve.fi wBTC/sBTC',
  },
  {
    id: 'curve-inu',
    symbol: 'crvy',
    name: 'Curve Inu',
  },
  {
    id: 'curve-network',
    symbol: 'curve',
    name: 'Curve Network',
  },
  {
    id: 'curveswap',
    symbol: 'cvs',
    name: 'Curveswap',
  },
  {
    id: 'custodiy',
    symbol: 'cty',
    name: 'CUSTODIY',
  },
  {
    id: 'cut-it-off',
    symbol: 'cut',
    name: 'Cut It Off',
  },
  {
    id: 'cvault-finance',
    symbol: 'core',
    name: 'cVault.finance',
  },
  {
    id: 'cvnx',
    symbol: 'cvnx',
    name: 'CVNX',
  },
  {
    id: 'cvshots',
    symbol: 'cvshot',
    name: 'CVSHOTS',
  },
  {
    id: 'cyb3rgam3r420',
    symbol: 'gamer',
    name: 'cyb3rgam3r420',
  },
  {
    id: 'cyber-arena',
    symbol: 'cat',
    name: 'Cyber Arena',
  },
  {
    id: 'cyberconnect',
    symbol: 'cyber',
    name: 'CyberConnect',
  },
  {
    id: 'cyber-dao',
    symbol: 'c-dao',
    name: 'Cyber-DAO',
  },
  {
    id: 'cyberdoge-2',
    symbol: 'cydoge',
    name: 'Cyberdoge',
  },
  {
    id: 'cyberdragon-gold',
    symbol: 'gold',
    name: 'CyberDragon Gold',
  },
  {
    id: 'cyberfi',
    symbol: 'cfi',
    name: 'CyberFi',
  },
  {
    id: 'cyberfm',
    symbol: 'cyfm',
    name: 'CyberFM',
  },
  {
    id: 'cyberharbor',
    symbol: 'cht',
    name: 'CyberHarbor',
  },
  {
    id: 'cyberpunk-city',
    symbol: 'cyber',
    name: 'Cyberpunk City',
  },
  {
    id: 'cyber-tesla-ai',
    symbol: 'cta',
    name: 'Cyber Tesla AI',
  },
  {
    id: 'cybertronchain',
    symbol: 'ctc',
    name: 'CyberTronchain',
  },
  {
    id: 'cybertruck',
    symbol: 'truck',
    name: 'Cybertruck',
  },
  {
    id: 'cybertruck-2',
    symbol: 'cybertruck',
    name: 'CYBERTRUCK',
  },
  {
    id: 'cybervein',
    symbol: 'cvt',
    name: 'CyberVein',
  },
  {
    id: 'cyberyen',
    symbol: 'cy',
    name: 'Cyberyen',
  },
  {
    id: 'cy-bord-cbrc-20',
    symbol: 'bord',
    name: 'Cy[bord] (CBRC-20)',
  },
  {
    id: 'cyborg-apes',
    symbol: 'borg',
    name: 'Cyborg Apes',
  },
  {
    id: 'cybria',
    symbol: 'cyba',
    name: 'Cybria',
  },
  {
    id: 'cyclone-protocol',
    symbol: 'cyc',
    name: 'Cyclone Protocol',
  },
  {
    id: 'cyclos',
    symbol: 'cys',
    name: 'Cykura',
  },
  {
    id: 'cygnusdao',
    symbol: 'cyg',
    name: 'CygnusDAO',
  },
  {
    id: 'cyop-2',
    symbol: 'cyop',
    name: 'CyOp',
  },
  {
    id: 'cypherium',
    symbol: 'cph',
    name: 'Cypherium',
  },
  {
    id: 'd2-token',
    symbol: 'd2',
    name: 'D2 Token',
  },
  {
    id: 'd3d-social',
    symbol: 'd3d',
    name: 'D3D Social',
  },
  {
    id: 'dacat',
    symbol: 'dacat',
    name: 'daCat',
  },
  {
    id: 'd-acc',
    symbol: 'd/acc',
    name: 'd/acc',
  },
  {
    id: 'dackieswap',
    symbol: 'dackie',
    name: 'DackieSwap',
  },
  {
    id: 'dacxi',
    symbol: 'dacxi',
    name: 'Dacxi',
  },
  {
    id: 'daddy-doge',
    symbol: 'daddydoge',
    name: 'Daddy Doge',
  },
  {
    id: 'daex',
    symbol: 'dax',
    name: 'DAEX',
  },
  {
    id: 'dafi-protocol',
    symbol: 'dafi',
    name: 'Dafi Protocol',
  },
  {
    id: 'dagcoin',
    symbol: 'dags',
    name: 'DAGCOIN',
  },
  {
    id: 'dagger',
    symbol: 'xdag',
    name: 'Dagger',
  },
  {
    id: 'dai',
    symbol: 'dai',
    name: 'Dai',
  },
  {
    id: 'daii',
    symbol: 'daii',
    name: 'DAII',
  },
  {
    id: 'daikicoin',
    symbol: 'dic',
    name: 'DAIKICOIN',
  },
  {
    id: 'daily-finance',
    symbol: 'dly',
    name: 'Daily Finance',
  },
  {
    id: 'dai-pulsechain',
    symbol: 'dai',
    name: 'DAI (PulseChain)',
  },
  {
    id: 'dai-reflections',
    symbol: 'drs',
    name: 'DAI Reflections',
  },
  {
    id: 'daisy',
    symbol: 'daisy',
    name: 'Daisy Protocol',
  },
  {
    id: 'dall-doginals',
    symbol: 'dall',
    name: 'Dall (DRC-20)',
  },
  {
    id: 'dalma-inu',
    symbol: 'dalma',
    name: 'Dalma Inu',
  },
  {
    id: 'damex-token',
    symbol: 'damex',
    name: 'Damex Token',
  },
  {
    id: 'dam-finance',
    symbol: 'd2o',
    name: 'Deuterium',
  },
  {
    id: 'damm',
    symbol: 'damm',
    name: 'dAMM',
  },
  {
    id: 'dancing-baby',
    symbol: 'baby',
    name: 'Dancing Baby',
  },
  {
    id: 'dancing-toothless',
    symbol: 'toothless',
    name: 'Dancing Toothless',
  },
  {
    id: 'dancing-triangle',
    symbol: 'triangle',
    name: 'dancing triangle',
  },
  {
    id: 'danjuan-scroll-cat',
    symbol: 'cat',
    name: 'Danjuan Scroll Cat',
  },
  {
    id: 'danketsu',
    symbol: 'ninjaz',
    name: 'Danketsu',
  },
  {
    id: 'dao-glas',
    symbol: 'dgs',
    name: 'Dao Glas',
  },
  {
    id: 'daohaus',
    symbol: 'haus',
    name: 'DAOhaus',
  },
  {
    id: 'dao-invest',
    symbol: 'vest',
    name: 'DAO Invest',
  },
  {
    id: 'daolaunch',
    symbol: 'dal',
    name: 'DAOLaunch',
  },
  {
    id: 'dao-maker',
    symbol: 'dao',
    name: 'DAO Maker',
  },
  {
    id: 'daomatian',
    symbol: 'dao',
    name: 'Daomatian',
  },
  {
    id: 'daosol',
    symbol: 'daosol',
    name: 'daoSOL',
  },
  {
    id: 'dao-space',
    symbol: 'daop',
    name: 'Dao Space',
  },
  {
    id: 'daosquare',
    symbol: 'rice',
    name: 'DAOSquare',
  },
  {
    id: 'daostack',
    symbol: 'gen',
    name: 'DAOstack',
  },
  {
    id: 'daoverse',
    symbol: 'dvrs',
    name: 'DaoVerse',
  },
  {
    id: 'da-pinchi',
    symbol: '$pinchi',
    name: 'Da Pinchi',
  },
  {
    id: 'dapp',
    symbol: 'dapp',
    name: 'LiquidApps',
  },
  {
    id: 'dappradar',
    symbol: 'radar',
    name: 'DappRadar',
  },
  {
    id: 'dappstore',
    symbol: 'dappx',
    name: 'dAppstore',
  },
  {
    id: 'darcmatter-coin',
    symbol: 'darc',
    name: 'Konstellation',
  },
  {
    id: 'darkcrypto',
    symbol: 'dark',
    name: 'DarkCrypto',
  },
  {
    id: 'darkcrypto-share',
    symbol: 'sky',
    name: 'DarkCrypto Share',
  },
  {
    id: 'dark-energy-crystals',
    symbol: 'dec',
    name: 'Dark Energy Crystals',
  },
  {
    id: 'dark-forest',
    symbol: 'dark',
    name: 'Dark Forest',
  },
  {
    id: 'dark-frontiers',
    symbol: 'dark',
    name: 'Dark Frontiers',
  },
  {
    id: 'darkknight',
    symbol: 'dknight',
    name: 'Dark Knight',
  },
  {
    id: 'dark-magic',
    symbol: 'dmagic',
    name: 'Dark Magic',
  },
  {
    id: 'darkmatter',
    symbol: 'dmt',
    name: 'DarkMatter',
  },
  {
    id: 'dark-matter',
    symbol: 'dmt',
    name: 'Dark Matter',
  },
  {
    id: 'dark-matter-defi',
    symbol: 'dmd',
    name: 'Dark Matter Defi',
  },
  {
    id: 'darkmeta',
    symbol: 'dmt',
    name: 'DarkMeta',
  },
  {
    id: 'darkness-dollar',
    symbol: 'dusd',
    name: 'Darkness Dollar',
  },
  {
    id: 'dark-protocol',
    symbol: 'dark',
    name: 'Dark Protocol',
  },
  {
    id: 'dark-queen-duck',
    symbol: 'dqd',
    name: 'Dark Queen Duck',
  },
  {
    id: 'darkshield',
    symbol: 'dks',
    name: 'DarkShield',
  },
  {
    id: 'daruma',
    symbol: 'daruma',
    name: 'Daruma',
  },
  {
    id: 'darwinia-commitment-token',
    symbol: 'kton',
    name: 'Darwinia Commitment',
  },
  {
    id: 'darwinia-network-native-token',
    symbol: 'ring',
    name: 'Darwinia Network',
  },
  {
    id: 'dash',
    symbol: 'dash',
    name: 'Dash',
  },
  {
    id: 'dash-2-trade',
    symbol: 'd2t',
    name: 'Dash 2 Trade',
  },
  {
    id: 'dash-diamond',
    symbol: 'dashd',
    name: 'Dash Diamond',
  },
  {
    id: 'dashsports',
    symbol: 'dass',
    name: 'DashSports',
  },
  {
    id: 'dastra-network',
    symbol: 'dan',
    name: 'Dastra Network',
  },
  {
    id: 'data-bot',
    symbol: 'data',
    name: 'DataBot',
  },
  {
    id: 'databroker-dao',
    symbol: 'dtx',
    name: 'DaTa eXchange DTX',
  },
  {
    id: 'datahighway',
    symbol: 'dhx',
    name: 'DataHighway',
  },
  {
    id: 'data-lake',
    symbol: 'lake',
    name: 'Data Lake',
  },
  {
    id: 'datamall-coin',
    symbol: 'dmc',
    name: 'Datamall Coin',
  },
  {
    id: 'datamine',
    symbol: 'dam',
    name: 'Datamine',
  },
  {
    id: 'data-vital',
    symbol: 'dav',
    name: 'Data Vital',
  },
  {
    id: 'datsbotai',
    symbol: 'dba',
    name: 'Datsbotai',
  },
  {
    id: 'dave-coin',
    symbol: '$dave',
    name: 'Dave Coin',
  },
  {
    id: 'davidcoin',
    symbol: 'dc',
    name: 'DavidCoin',
  },
  {
    id: 'davis-cup-fan-token',
    symbol: 'davis',
    name: 'Davis Cup Fan Token',
  },
  {
    id: 'davos-protocol',
    symbol: 'dusd',
    name: 'Davos Protocol',
  },
  {
    id: 'dawae',
    symbol: 'dawae',
    name: 'DaWae',
  },
  {
    id: 'daw-currency',
    symbol: 'daw',
    name: 'Daw Currency',
  },
  {
    id: 'dawg',
    symbol: 'dawg',
    name: 'DAWG',
  },
  {
    id: 'dawg-coin',
    symbol: 'dawg',
    name: 'Dawg Coin',
  },
  {
    id: 'dawn-protocol',
    symbol: 'dawn',
    name: 'Dawn Protocol',
  },
  {
    id: 'day-by-day',
    symbol: 'dbd',
    name: 'Day By Day',
  },
  {
    id: 'daylight-protocol',
    symbol: 'dayl',
    name: 'Daylight Protocol',
  },
  {
    id: 'day-of-defeat',
    symbol: 'dod',
    name: 'Day of Defeat 2.0',
  },
  {
    id: 'day-of-defeat-mini-100x',
    symbol: 'dod100',
    name: 'Day of Defeat Mini 100x',
  },
  {
    id: 'daystarter',
    symbol: 'dst',
    name: 'DAYSTARTER',
  },
  {
    id: 'daytona-finance',
    symbol: 'toni',
    name: 'Daytona Finance',
  },
  {
    id: 'dbk',
    symbol: 'dbk',
    name: 'DBK',
  },
  {
    id: 'dbx-2',
    symbol: 'dbx',
    name: 'DBX',
  },
  {
    id: 'dbxen',
    symbol: 'dxn',
    name: 'DBXen',
  },
  {
    id: 'dcntrl-network',
    symbol: 'dcnx',
    name: 'DCNTRL Network',
  },
  {
    id: 'dcomm',
    symbol: 'dcm',
    name: 'DComm',
  },
  {
    id: 'd-community',
    symbol: 'dili',
    name: 'D Community',
  },
  {
    id: 'dcomy',
    symbol: 'dco',
    name: 'DCOMY',
  },
  {
    id: 'dcoreum',
    symbol: 'dco',
    name: 'DCOREUM',
  },
  {
    id: 'dcxen',
    symbol: 'dcxen',
    name: 'dcXEN',
  },
  {
    id: 'd-drops',
    symbol: 'dop',
    name: 'D-Drops',
  },
  {
    id: 'deadpxlz',
    symbol: 'ding',
    name: 'DEADPXLZ',
  },
  {
    id: 'deapcoin',
    symbol: 'dep',
    name: 'DEAPCOIN',
  },
  {
    id: 'deathroad',
    symbol: 'drace',
    name: 'DeathRoad',
  },
  {
    id: 'death-token',
    symbol: 'death',
    name: 'Death',
  },
  {
    id: 'decanect',
    symbol: 'dcnt',
    name: 'Decanect',
  },
  {
    id: 'decats',
    symbol: 'decats',
    name: 'DeCats',
  },
  {
    id: 'decenfi',
    symbol: 'dfi',
    name: 'DecenFi',
  },
  {
    id: 'decentr',
    symbol: 'dec',
    name: 'Decentr',
  },
  {
    id: 'decentrabnb',
    symbol: 'dbnb',
    name: 'DecentraBNB',
  },
  {
    id: 'decentra-box',
    symbol: 'dbox',
    name: 'Decentra Box',
  },
  {
    id: 'decentracard',
    symbol: 'dcard',
    name: 'DECENTRACARD',
  },
  {
    id: 'decentraland',
    symbol: 'mana',
    name: 'Decentraland',
  },
  {
    id: 'decentraland-wormhole',
    symbol: 'mana',
    name: 'Decentraland (Wormhole)',
  },
  {
    id: 'decentral-art',
    symbol: 'art',
    name: 'Decentral ART',
  },
  {
    id: 'decentralfree',
    symbol: 'freela',
    name: 'DecentralFree',
  },
  {
    id: 'decentral-games',
    symbol: 'dg',
    name: 'Decentral Games',
  },
  {
    id: 'decentral-games-governance',
    symbol: 'xdg',
    name: 'Decentral Games Governance',
  },
  {
    id: 'decentral-games-ice',
    symbol: 'ice',
    name: 'Decentral Games ICE',
  },
  {
    id: 'decentral-games-old',
    symbol: 'dg',
    name: 'Decentral Games (Old)',
  },
  {
    id: 'decentralized-activism',
    symbol: 'dact',
    name: 'Decentralized Activism',
  },
  {
    id: 'decentralized-advertising',
    symbol: 'dad',
    name: 'DAD',
  },
  {
    id: 'decentralized-community-investment-protocol',
    symbol: 'dcip',
    name: 'Decentralized Community Investment Protocol',
  },
  {
    id: 'decentralized-etf',
    symbol: 'detf',
    name: 'Decentralized ETF',
  },
  {
    id: 'decentralized-intelligence-agency-2',
    symbol: 'dia',
    name: 'Decentralized Intelligence Agency',
  },
  {
    id: 'decentralized-liquidity-program',
    symbol: 'dlp',
    name: 'Decentralized Liquidity Program',
  },
  {
    id: 'decentralized-mining-exchange',
    symbol: 'dmc',
    name: 'Decentralized Mining Exchange',
  },
  {
    id: 'decentralized-music-chain',
    symbol: 'dmcc',
    name: 'Decentralized Music Chain',
  },
  {
    id: 'decentralized-nations',
    symbol: 'dena',
    name: 'Decentralized Nations',
  },
  {
    id: 'decentralized-universal-basic-income',
    symbol: 'dubi',
    name: 'Decentralized Universal Basic Income',
  },
  {
    id: 'decentralized-usd',
    symbol: 'dusd',
    name: 'Decentralized USD',
  },
  {
    id: 'decentralized-vulnerability-platform',
    symbol: 'dvp',
    name: 'Decentralized Vulnerability Platform',
  },
  {
    id: 'decentramind',
    symbol: 'dmind',
    name: 'DecentraMind',
  },
  {
    id: 'decentraweb',
    symbol: 'dweb',
    name: 'DecentraWeb',
  },
  {
    id: 'decentrawood',
    symbol: 'deod',
    name: 'Decentrawood',
  },
  {
    id: 'decetralized-minting-atomicals',
    symbol: 'dmint',
    name: 'Decentralized Minting',
  },
  {
    id: 'dechat',
    symbol: 'chat',
    name: 'Dechat',
  },
  {
    id: 'decimal',
    symbol: 'del',
    name: 'Decimal',
  },
  {
    id: 'decimated',
    symbol: 'dio',
    name: 'Decimated',
  },
  {
    id: 'd-ecosystem',
    symbol: 'dcx',
    name: 'D-Ecosystem',
  },
  {
    id: 'decred',
    symbol: 'dcr',
    name: 'Decred',
  },
  {
    id: 'decred-next',
    symbol: 'dcrn',
    name: 'Decred-Next',
  },
  {
    id: 'decubate',
    symbol: 'dcb',
    name: 'Decubate',
  },
  {
    id: 'dede',
    symbol: 'dede',
    name: 'Dede',
  },
  {
    id: 'dedprz',
    symbol: 'usa',
    name: 'DEDPRZ',
  },
  {
    id: 'deelance',
    symbol: '$dlance',
    name: 'DeeLance',
  },
  {
    id: 'deepbrain-chain',
    symbol: 'dbc',
    name: 'DeepBrain Chain',
  },
  {
    id: 'deeper-network',
    symbol: 'dpr',
    name: 'Deeper Network',
  },
  {
    id: 'deepfakeai',
    symbol: 'fakeai',
    name: 'DeepFakeAI',
  },
  {
    id: 'deep-fucking-value',
    symbol: 'deep',
    name: 'Deep Fucking Value',
  },
  {
    id: 'deeponion',
    symbol: 'onion',
    name: 'DeepOnion',
  },
  {
    id: 'deepr',
    symbol: 'deepr',
    name: 'DEEPR',
  },
  {
    id: 'deepsouth-ai',
    symbol: 'south',
    name: 'DeepSouth AI',
  },
  {
    id: 'deepspace',
    symbol: 'dps',
    name: 'DEEPSPACE',
  },
  {
    id: 'deepwaters',
    symbol: 'wtr',
    name: 'Deepwaters',
  },
  {
    id: 'deesse',
    symbol: 'love',
    name: 'Deesse',
  },
  {
    id: 'defactor',
    symbol: 'factr',
    name: 'Defactor',
  },
  {
    id: 'defender-bot',
    symbol: 'dfndr',
    name: 'Defender Bot',
  },
  {
    id: 'de-fi',
    symbol: 'defi',
    name: 'DeFi',
  },
  {
    id: 'defiai',
    symbol: 'dfai',
    name: 'DeFiAI',
  },
  {
    id: 'defi-all-odds-daogame',
    symbol: 'daog',
    name: 'DAOGAME',
  },
  {
    id: 'defiato',
    symbol: 'dfiat',
    name: 'DeFiato',
  },
  {
    id: 'defibox',
    symbol: 'box',
    name: 'DefiBox',
  },
  {
    id: 'defichain',
    symbol: 'dfi',
    name: 'DeFiChain',
  },
  {
    id: 'defi-coin',
    symbol: 'defc',
    name: 'DeFi Coin',
  },
  {
    id: 'deficonnect-v2',
    symbol: 'dfc',
    name: 'DefiConnect V2',
  },
  {
    id: 'defidollar-dao',
    symbol: 'dfd',
    name: 'DefiDollar DAO',
  },
  {
    id: 'defi-for-you',
    symbol: 'dfy',
    name: 'Defi For You',
  },
  {
    id: 'defi-franc',
    symbol: 'dchf',
    name: 'DeFi Franc',
  },
  {
    id: 'defi-franc-moneta',
    symbol: 'mon',
    name: 'Moneta DAO',
  },
  {
    id: 'defigram',
    symbol: 'dfg',
    name: 'Defigram',
  },
  {
    id: 'defihorse',
    symbol: 'dfh',
    name: 'DeFiHorse',
  },
  {
    id: 'defi-hunters-dao',
    symbol: 'ddao',
    name: 'DDAO Hunters',
  },
  {
    id: 'defi-kingdoms',
    symbol: 'jewel',
    name: 'DeFi Kingdoms',
  },
  {
    id: 'defi-kingdoms-crystal',
    symbol: 'crystal',
    name: 'DeFi Kingdoms Crystal',
  },
  {
    id: 'defil',
    symbol: 'dfl',
    name: 'DeFIL',
  },
  {
    id: 'defi-land',
    symbol: 'dfl',
    name: 'DeFi Land',
  },
  {
    id: 'defi-land-gold',
    symbol: 'goldy',
    name: 'DeFi Land Gold',
  },
  {
    id: 'defily',
    symbol: 'dfl',
    name: 'Defily',
  },
  {
    id: 'defina-finance',
    symbol: 'fina',
    name: 'Defina Finance',
  },
  {
    id: 'definder-capital',
    symbol: 'dfc',
    name: 'DeFinder Capital',
  },
  {
    id: 'define',
    symbol: 'dfa',
    name: 'DeFine',
  },
  {
    id: 'definer',
    symbol: 'fin',
    name: 'DeFiner',
  },
  {
    id: 'definity',
    symbol: 'defx',
    name: 'DeFinity',
  },
  {
    id: 'defipal',
    symbol: 'pal',
    name: 'DefiPal',
  },
  {
    id: 'defiplaza',
    symbol: 'dfp2',
    name: 'DefiPlaza',
  },
  {
    id: 'defi-pool-share',
    symbol: 'dpst',
    name: 'DeFi Pool Share',
  },
  {
    id: 'defipulse-index',
    symbol: 'dpi',
    name: 'DeFi Pulse Index',
  },
  {
    id: 'defi-radar',
    symbol: 'dradar',
    name: 'Defi Radar',
  },
  {
    id: 'defi-robot',
    symbol: 'drbt',
    name: 'DeFi-Robot',
  },
  {
    id: 'defi-shopping-stake',
    symbol: 'dss',
    name: 'Defi Shopping Stake',
  },
  {
    id: 'defis-network',
    symbol: 'dfs',
    name: 'Defis Network',
  },
  {
    id: 'defistarter',
    symbol: 'dfi',
    name: 'DfiStarter',
  },
  {
    id: 'defi-stoa',
    symbol: 'sta',
    name: 'STOA Network',
  },
  {
    id: 'defit',
    symbol: 'defit',
    name: 'Digital Fitness',
  },
  {
    id: 'defitankland',
    symbol: 'dftl',
    name: 'DefiTankLand',
  },
  {
    id: 'defi-warrior',
    symbol: 'fiwa',
    name: 'Defi Warrior',
  },
  {
    id: 'defi-world',
    symbol: 'dwc',
    name: 'Defi World',
  },
  {
    id: 'defi-yield-protocol',
    symbol: 'dyp',
    name: 'Dypius [OLD]',
  },
  {
    id: 'defly',
    symbol: 'defly',
    name: 'Defly',
  },
  {
    id: 'defy',
    symbol: 'defy',
    name: 'DEFY',
  },
  {
    id: 'dega',
    symbol: 'dega',
    name: 'Dega',
  },
  {
    id: 'degate',
    symbol: 'dg',
    name: 'DeGate',
  },
  {
    id: 'degen-2',
    symbol: 'd三g三n',
    name: 'Degen',
  },
  {
    id: 'degen-base',
    symbol: 'degen',
    name: 'Degen (Base)',
  },
  {
    id: 'degen-fighting-championship',
    symbol: 'dfc',
    name: 'Degen Fighting Championship',
  },
  {
    id: 'degeninsure',
    symbol: 'dgns',
    name: 'DegenInsure',
  },
  {
    id: 'de-genius-casino',
    symbol: 'degen',
    name: 'De-Genius Casino',
  },
  {
    id: 'degen-knightsofdegen',
    symbol: 'dgen',
    name: 'DGEN',
  },
  {
    id: 'degenreborn',
    symbol: 'degen',
    name: 'DegenReborn',
  },
  {
    id: 'degenstogether',
    symbol: 'degen',
    name: 'DegensTogether',
  },
  {
    id: 'degen-token',
    symbol: 'dgn',
    name: 'Degen Token',
  },
  {
    id: 'degen-traded-fund',
    symbol: 'dtf',
    name: 'Degen Traded Fund',
  },
  {
    id: 'degenx',
    symbol: 'dgnx',
    name: 'DegenX',
  },
  {
    id: 'degen-zoo',
    symbol: 'dzoo',
    name: 'Degen Zoo',
  },
  {
    id: 'degis',
    symbol: 'deg',
    name: 'Degis',
  },
  {
    id: 'dego-finance',
    symbol: 'dego',
    name: 'Dego Finance',
  },
  {
    id: 'degree-crypto-token',
    symbol: 'dct',
    name: 'Degree Crypto',
  },
  {
    id: 'dehealth',
    symbol: 'dhlt',
    name: 'DeHealth',
  },
  {
    id: 'dehero-community-token',
    symbol: 'heroes',
    name: 'Dehero Community',
  },
  {
    id: 'deherogame-amazing-token',
    symbol: 'amg',
    name: 'DeHeroGame Amazing Token',
  },
  {
    id: 'dehive',
    symbol: 'dhv',
    name: 'DeHive',
  },
  {
    id: 'dehorizon',
    symbol: 'devt',
    name: 'DeHorizon',
  },
  {
    id: 'dejitaru-hoshi',
    symbol: 'hoshi',
    name: 'Dejitaru Hoshi',
  },
  {
    id: 'dejitaru-shirudo',
    symbol: 'shield',
    name: 'Dejitaru Shirudo',
  },
  {
    id: 'dejitaru-tsuka',
    symbol: 'tsuka',
    name: 'Dejitaru Tsuka',
  },
  {
    id: 'dekbox',
    symbol: 'dek',
    name: 'DekBox',
  },
  {
    id: 'deliq',
    symbol: 'dlq',
    name: 'Deliq',
  },
  {
    id: 'delphy',
    symbol: 'dpy',
    name: 'Delphy',
  },
  {
    id: 'delrey-inu',
    symbol: 'delrey',
    name: 'Delrey Inu',
  },
  {
    id: 'delta-exchange-token',
    symbol: 'deto',
    name: 'Delta Exchange',
  },
  {
    id: 'deltafi',
    symbol: 'delfi',
    name: 'DeltaFi',
  },
  {
    id: 'delta-financial',
    symbol: 'delta',
    name: 'Delta Financial',
  },
  {
    id: 'deltaflare',
    symbol: 'honr',
    name: 'DeltaFlare',
  },
  {
    id: 'deltahub-community',
    symbol: 'dhc',
    name: 'DeltaHub Community',
  },
  {
    id: 'delta-theta',
    symbol: 'dlta',
    name: 'delta.theta',
  },
  {
    id: 'delysium',
    symbol: 'agi',
    name: 'Delysium',
  },
  {
    id: 'demeter',
    symbol: 'deo',
    name: 'Demeter',
  },
  {
    id: 'demeter-usd',
    symbol: 'dusd',
    name: 'Demeter USD',
  },
  {
    id: 'demi',
    symbol: 'demi',
    name: 'DeMi',
  },
  {
    id: 'demole',
    symbol: 'dmlg',
    name: 'Demole',
  },
  {
    id: 'demx',
    symbol: 'demx',
    name: 'DemX',
  },
  {
    id: 'denarius',
    symbol: 'd',
    name: 'Denarius',
  },
  {
    id: 'denet-file-token',
    symbol: 'de',
    name: 'Denet File Token',
  },
  {
    id: 'denizlispor-fan-token',
    symbol: 'dnz',
    name: 'Denizlispor Fan Token',
  },
  {
    id: 'dent',
    symbol: 'dent',
    name: 'Dent',
  },
  {
    id: 'dentacoin',
    symbol: 'dcn',
    name: 'Dentacoin',
  },
  {
    id: 'depay',
    symbol: 'depay',
    name: 'DePay',
  },
  {
    id: 'depin-dao',
    symbol: 'depindao',
    name: 'DePIN DAO',
  },
  {
    id: 'deportivo-alaves-fan-token',
    symbol: 'daft',
    name: 'Deportivo Alavés Fan Token',
  },
  {
    id: 'dequant',
    symbol: 'deq',
    name: 'Dequant',
  },
  {
    id: 'derace',
    symbol: 'derc',
    name: 'DeRace',
  },
  {
    id: 'deracoin',
    symbol: 'drc',
    name: 'Deracoin',
  },
  {
    id: 'derby-stars-run',
    symbol: 'dsrun',
    name: 'Derby Stars RUN',
  },
  {
    id: 'derify-protocol',
    symbol: 'drf',
    name: 'Derify Protocol',
  },
  {
    id: 'deri-protocol',
    symbol: 'deri',
    name: 'Deri Protocol',
  },
  {
    id: 'derivadao',
    symbol: 'ddx',
    name: 'DerivaDAO',
  },
  {
    id: 'dero',
    symbol: 'dero',
    name: 'Dero',
  },
  {
    id: 'derp',
    symbol: 'derp',
    name: 'Derp',
  },
  {
    id: 'derpcat',
    symbol: 'derpcat',
    name: 'DERPCAT',
  },
  {
    id: 'derp-coin',
    symbol: 'derp',
    name: 'Derp Coin',
  },
  {
    id: 'desme',
    symbol: 'desme',
    name: 'DeSME',
  },
  {
    id: 'desmos',
    symbol: 'dsm',
    name: 'Desmos',
  },
  {
    id: 'deso',
    symbol: 'deso',
    name: 'Decentralized Social',
  },
  {
    id: 'despace-protocol',
    symbol: 'des',
    name: 'DeSpace Protocol',
  },
  {
    id: 'destiny-world',
    symbol: 'deco',
    name: 'Destiny World',
  },
  {
    id: 'destorage',
    symbol: 'ds',
    name: 'DeStorage',
  },
  {
    id: 'detto-finance',
    symbol: 'deto',
    name: 'Detto Finance',
  },
  {
    id: 'deus-finance-2',
    symbol: 'deus',
    name: 'DEUS Finance',
  },
  {
    id: 'deutsche-emark',
    symbol: 'dem',
    name: 'Deutsche eMark',
  },
  {
    id: 'devault',
    symbol: 'dvt',
    name: 'DeVault',
  },
  {
    id: 'develocity',
    symbol: 'deve',
    name: 'Develocity',
  },
  {
    id: 'developer-dao',
    symbol: 'code',
    name: 'Developer DAO',
  },
  {
    id: 'deviantsfactions',
    symbol: 'dev',
    name: 'DeviantsFactions',
  },
  {
    id: 'devikins',
    symbol: 'dvk',
    name: 'Devikins',
  },
  {
    id: 'devil-finance',
    symbol: 'devil',
    name: 'Devil Finance',
  },
  {
    id: 'devita-global',
    symbol: 'life',
    name: 'DEVITA',
  },
  {
    id: 'devomon',
    symbol: 'evo',
    name: 'Devomon',
  },
  {
    id: 'devops',
    symbol: 'dev',
    name: 'DevOps',
  },
  {
    id: 'devour-2',
    symbol: 'dpay',
    name: 'Devour',
  },
  {
    id: 'dev-protocol',
    symbol: 'dev',
    name: 'Dev Protocol',
  },
  {
    id: 'dev-smashed-his-keyboard',
    symbol: 'hixokdkekjcjdksicndnaiaihsbznnxnxnduje',
    name: 'DEV SMASHED HIS KEYBOARD',
  },
  {
    id: 'devvio',
    symbol: 'devve',
    name: 'Devvio',
  },
  {
    id: 'dewae',
    symbol: 'dewae',
    name: 'Dewae',
  },
  {
    id: 'dexa-coin',
    symbol: 'dexa',
    name: 'DEXA COIN',
  },
  {
    id: 'dexagon',
    symbol: 'dxc',
    name: 'DeXagon',
  },
  {
    id: 'dexalot',
    symbol: 'alot',
    name: 'Dexalot',
  },
  {
    id: 'dexbet',
    symbol: 'dxb',
    name: 'Dexbet',
  },
  {
    id: 'dexbrowser',
    symbol: 'bro',
    name: 'DexBrowser',
  },
  {
    id: 'dexcheck',
    symbol: 'dck',
    name: 'DexCheck',
  },
  {
    id: 'dexe',
    symbol: 'dexe',
    name: 'DeXe',
  },
  {
    id: 'dexfi-governance',
    symbol: 'gdex',
    name: 'DexFi Governance',
  },
  {
    id: 'dex-game',
    symbol: 'dxgm',
    name: 'DexGame',
  },
  {
    id: 'dexhunter',
    symbol: 'hunt',
    name: 'Dexhunter',
  },
  {
    id: 'dexioprotocol-v2',
    symbol: 'dexio',
    name: 'Dexioprotocol',
  },
  {
    id: 'dexit-finance',
    symbol: 'dxt',
    name: 'Dexit Network',
  },
  {
    id: 'dexkit',
    symbol: 'kit',
    name: 'DexKit',
  },
  {
    id: 'dexlab',
    symbol: 'dxl',
    name: 'Dexlab',
  },
  {
    id: 'dexo',
    symbol: 'dexo',
    name: 'DEXO',
  },
  {
    id: 'dex-on-crypto',
    symbol: 'docswap',
    name: 'Dex on Crypto',
  },
  {
    id: 'dexpad',
    symbol: 'dxp',
    name: 'DexPad',
  },
  {
    id: 'dexpools',
    symbol: 'dxp',
    name: 'Dexpools',
  },
  {
    id: 'dexshare',
    symbol: 'dexshare',
    name: 'dexSHARE',
  },
  {
    id: 'dex-sniffer',
    symbol: 'ds',
    name: 'Dex Sniffer [OLD]',
  },
  {
    id: 'dex-sniffer-2',
    symbol: 'ds',
    name: 'Dex Sniffer',
  },
  {
    id: 'dexsport',
    symbol: 'desu',
    name: 'Dexsport',
  },
  {
    id: 'dextf',
    symbol: 'dextf',
    name: 'Domani Protocol',
  },
  {
    id: 'dextools',
    symbol: 'dext',
    name: 'DexTools',
  },
  {
    id: 'dextoro',
    symbol: 'dtoro',
    name: 'DexToro',
  },
  {
    id: 'dex-trade-coin',
    symbol: 'dxc',
    name: 'Dex-Trade Coin',
  },
  {
    id: 'dextro',
    symbol: 'dxo',
    name: 'Dextro',
  },
  {
    id: 'dexwallet',
    symbol: 'dwt',
    name: 'DexWallet',
  },
  {
    id: 'dfohub',
    symbol: 'buidl',
    name: 'dfohub',
  },
  {
    id: 'dforce-token',
    symbol: 'df',
    name: 'dForce',
  },
  {
    id: 'dfs-mafia',
    symbol: 'dfsm',
    name: 'DFS Mafia V2',
  },
  {
    id: 'dfuk',
    symbol: 'dfuk',
    name: 'DFUK',
  },
  {
    id: 'dfund',
    symbol: 'dfnd',
    name: 'dFund',
  },
  {
    id: 'dfx-finance',
    symbol: 'dfx',
    name: 'DFX Finance',
  },
  {
    id: 'dfyn-network',
    symbol: 'dfyn',
    name: 'Dfyn Network',
  },
  {
    id: 'dgi-game',
    symbol: 'dgi',
    name: 'DGI Game',
  },
  {
    id: 'dgnapp-ai',
    symbol: 'degai',
    name: 'DGNAPP.AI',
  },
  {
    id: 'dhabicoin',
    symbol: 'dbc',
    name: 'Dhabicoin',
  },
  {
    id: 'dhd-coin-2',
    symbol: 'dhd',
    name: 'DHD Coin',
  },
  {
    id: 'dhealth',
    symbol: 'dhp',
    name: 'dHealth',
  },
  {
    id: 'dhedge-dao',
    symbol: 'dht',
    name: 'dHEDGE DAO',
  },
  {
    id: 'diabase',
    symbol: 'diac',
    name: 'Diabase',
  },
  {
    id: 'dia-data',
    symbol: 'dia',
    name: 'DIA',
  },
  {
    id: 'diamault',
    symbol: 'dvt',
    name: 'Diamault',
  },
  {
    id: 'diamond',
    symbol: 'dmd',
    name: 'Diamond',
  },
  {
    id: 'diamond-boyz-coin',
    symbol: 'dbz',
    name: 'Diamond Boyz Coin',
  },
  {
    id: 'diamond-coin',
    symbol: 'diamond',
    name: 'Diamond Coin',
  },
  {
    id: 'diamond-launch',
    symbol: 'dlc',
    name: 'Diamond Launch',
  },
  {
    id: 'dibbles',
    symbol: 'dibble',
    name: 'Dibbles',
  },
  {
    id: 'dibs-share',
    symbol: 'dshare',
    name: 'Dibs Share',
  },
  {
    id: 'dice-bot',
    symbol: 'dice',
    name: 'Dice Bot',
  },
  {
    id: 'dice-kingdom',
    symbol: 'dk',
    name: 'Dice Kingdom',
  },
  {
    id: 'die-protocol',
    symbol: 'die',
    name: 'Die Protocol',
  },
  {
    id: 'diffusion',
    symbol: 'diff',
    name: 'Diffusion',
  },
  {
    id: 'dig-chain',
    symbol: 'dig',
    name: 'Dig Chain',
  },
  {
    id: 'digg',
    symbol: 'digg',
    name: 'DIGG',
  },
  {
    id: 'digibunnies',
    symbol: 'dgbn',
    name: 'DigiBunnies',
  },
  {
    id: 'digibyte',
    symbol: 'dgb',
    name: 'DigiByte',
  },
  {
    id: 'digifinextoken',
    symbol: 'dft',
    name: 'DigiFinex',
  },
  {
    id: 'digifund',
    symbol: 'dfund',
    name: 'DigiFund V1',
  },
  {
    id: 'digifund-capital-v2',
    symbol: 'dfund',
    name: 'DigiFund Capital V2',
  },
  {
    id: 'digihealth',
    symbol: 'dgh',
    name: 'Digihealth',
  },
  {
    id: 'digimetaverse',
    symbol: 'dgmv',
    name: 'DigiMetaverse',
  },
  {
    id: 'digital-bank-of-africa',
    symbol: 'dba',
    name: 'Digital Bank of Africa',
  },
  {
    id: 'digitalbay',
    symbol: 'dbc',
    name: 'DigitalBay',
  },
  {
    id: 'digitalbits',
    symbol: 'xdb',
    name: 'XDB CHAIN',
  },
  {
    id: 'digitalcoin',
    symbol: 'dgc',
    name: 'Digitalcoin',
  },
  {
    id: 'digital-files',
    symbol: 'difi',
    name: 'Digital Files',
  },
  {
    id: 'digital-financial-exchange',
    symbol: 'difx',
    name: 'Digital Financial Exchange',
  },
  {
    id: 'digitalnote',
    symbol: 'xdn',
    name: 'DigitalNote',
  },
  {
    id: 'digitalprice',
    symbol: 'dp',
    name: 'DigitalPrice',
  },
  {
    id: 'digital-rand',
    symbol: 'dzar',
    name: 'Digital Rand',
  },
  {
    id: 'digital-reserve-currency',
    symbol: 'drc',
    name: 'Digital Reserve Currency',
  },
  {
    id: 'digital-trip-advisor',
    symbol: 'dta',
    name: 'Digital Trip Advisor',
  },
  {
    id: 'digitex-futures-exchange',
    symbol: 'dgtx',
    name: 'Digitex',
  },
  {
    id: 'digits-dao',
    symbol: 'digits',
    name: 'Digits DAO',
  },
  {
    id: 'digiverse-2',
    symbol: 'digi',
    name: 'DIGIVERSE',
  },
  {
    id: 'digix-gold',
    symbol: 'dgx',
    name: 'Digix Gold',
  },
  {
    id: 'dimecoin',
    symbol: 'dime',
    name: 'Dimecoin',
  },
  {
    id: 'diminutive-coin',
    symbol: 'dimi',
    name: 'Diminutive Coin',
  },
  {
    id: 'dimitra',
    symbol: 'dmtr',
    name: 'Dimitra',
  },
  {
    id: 'dimo',
    symbol: 'dimo',
    name: 'DIMO',
  },
  {
    id: 'dinamo-zagreb-fan-token',
    symbol: 'dzg',
    name: 'Dinamo Zagreb Fan Token',
  },
  {
    id: 'dinari-aapl-dshares',
    symbol: 'aapl.d',
    name: 'Dinari AAPL',
  },
  {
    id: 'dinari-amd',
    symbol: 'amd.d',
    name: 'Dinari AMD',
  },
  {
    id: 'dinari-amzn-dshares',
    symbol: 'amzn.d',
    name: 'Dinari AMZN',
  },
  {
    id: 'dinari-arm',
    symbol: 'arm.d',
    name: 'Dinari ARM',
  },
  {
    id: 'dinari-brk-a-d',
    symbol: 'brk.a.d',
    name: 'Dinari BRK.A',
  },
  {
    id: 'dinari-coin',
    symbol: 'coin.d',
    name: 'Dinari COIN',
  },
  {
    id: 'dinari-dis-dshares',
    symbol: 'dis.d',
    name: 'Dinari DIS',
  },
  {
    id: 'dinari-googl-dshares',
    symbol: 'googl.d',
    name: 'Dinari GOOGL',
  },
  {
    id: 'dinari-meta-dshare',
    symbol: 'meta.d',
    name: 'Dinari META',
  },
  {
    id: 'dinari-msft-dshares',
    symbol: 'msft.d',
    name: 'Dinari MSFT',
  },
  {
    id: 'dinari-nflx-dshares',
    symbol: 'nflx.d',
    name: 'Dinari NFLX',
  },
  {
    id: 'dinari-nvda-dshares',
    symbol: 'nvda.d',
    name: 'Dinari NVDA',
  },
  {
    id: 'dinari-pfe-dshares',
    symbol: 'pfe.d',
    name: 'Dinari PFE',
  },
  {
    id: 'dinari-pld',
    symbol: 'pld.d',
    name: 'Dinari PLD',
  },
  {
    id: 'dinari-pypl-dshares',
    symbol: 'pypl.d',
    name: 'Dinari PYPL',
  },
  {
    id: 'dinari-spy-dshares',
    symbol: 'spy.d',
    name: 'Dinari SPY',
  },
  {
    id: 'dinari-tsla-dshares',
    symbol: 'tsla.d',
    name: 'Dinari TSLA',
  },
  {
    id: 'dinari-usfr-dshares',
    symbol: 'usfr.d',
    name: 'Dinari USFR',
  },
  {
    id: 'dinartether',
    symbol: 'dint',
    name: 'DinarTether',
  },
  {
    id: 'dinero',
    symbol: 'din',
    name: 'Dinero',
  },
  {
    id: 'dinero-apxeth',
    symbol: 'apxeth',
    name: 'Dinero apxETH',
  },
  {
    id: 'dinerobet',
    symbol: 'dinero',
    name: 'Dinerobet',
  },
  {
    id: 'dinero-staked-eth',
    symbol: 'pxeth',
    name: 'Dinero Staked ETH',
  },
  {
    id: 'dinger-token',
    symbol: 'dinger',
    name: 'Dinger',
  },
  {
    id: 'dingocoin',
    symbol: 'dingo',
    name: 'Dingocoin',
  },
  {
    id: 'dinj',
    symbol: 'dinj',
    name: 'dINJ',
  },
  {
    id: 'dino',
    symbol: 'dino',
    name: 'Dino',
  },
  {
    id: 'dinoegg',
    symbol: 'dinoegg',
    name: 'DINOEGG',
  },
  {
    id: 'dinolfg',
    symbol: 'dino',
    name: 'DinoLFG',
  },
  {
    id: 'dinosaur-inu',
    symbol: 'dino',
    name: 'Dinosaur Inu',
  },
  {
    id: 'dinoswap',
    symbol: 'dino',
    name: 'DinoSwap',
  },
  {
    id: 'dinox',
    symbol: 'dnxc',
    name: 'DinoX',
  },
  {
    id: 'dione',
    symbol: 'dione',
    name: 'Dione',
  },
  {
    id: 'dionpay',
    symbol: 'dion',
    name: 'Dionpay',
  },
  {
    id: 'dip-exchange',
    symbol: 'dip',
    name: 'DIP Exchange',
  },
  {
    id: 'disbalancer',
    symbol: 'ddos',
    name: 'disBalancer',
  },
  {
    id: 'disney',
    symbol: 'dis',
    name: 'Disney',
  },
  {
    id: 'district0x',
    symbol: 'dnt',
    name: 'district0x',
  },
  {
    id: 'ditto-staked-aptos',
    symbol: 'stapt',
    name: 'Ditto Staked Aptos',
  },
  {
    id: 'diva-protocol',
    symbol: 'diva',
    name: 'DIVA Protocol',
  },
  {
    id: 'diva-staking',
    symbol: 'diva',
    name: 'Diva Staking',
  },
  {
    id: 'divergence-protocol',
    symbol: 'diver',
    name: 'Divergence Protocol',
  },
  {
    id: 'diversified-staked-eth',
    symbol: 'dseth',
    name: 'Diversified Staked ETH',
  },
  {
    id: 'diversityequity-inclusion',
    symbol: 'dei',
    name: 'DiversityEquity&Inclusion',
  },
  {
    id: 'divi',
    symbol: 'divi',
    name: 'Divi',
  },
  {
    id: 'dividoge',
    symbol: 'dvdoge',
    name: 'DiviDoge',
  },
  {
    id: 'divincipay',
    symbol: 'dvnci',
    name: 'DiVinciPay',
  },
  {
    id: 'djbonk',
    symbol: 'djbonk',
    name: 'DJBONK',
  },
  {
    id: 'djed',
    symbol: 'djed',
    name: 'Djed',
  },
  {
    id: 'dkargo',
    symbol: 'dka',
    name: 'dKargo',
  },
  {
    id: 'dkey-bank',
    symbol: 'dkey',
    name: 'DKEY Bank',
  },
  {
    id: 'dlp-duck-token',
    symbol: 'duck',
    name: 'DLP Duck',
  },
  {
    id: 'dmail-network',
    symbol: 'dmail',
    name: 'Dmail Network',
  },
  {
    id: 'dmd',
    symbol: 'dmd',
    name: 'DMD',
  },
  {
    id: 'dmx',
    symbol: 'dmx',
    name: 'DMX',
  },
  {
    id: 'dmz-token',
    symbol: 'dmz',
    name: 'DMZ',
  },
  {
    id: 'dnaxcat',
    symbol: 'dxct',
    name: 'DNAxCAT',
  },
  {
    id: 'dobi',
    symbol: 'dobi',
    name: 'DOBI',
  },
  {
    id: 'dock',
    symbol: 'dock',
    name: 'Dock',
  },
  {
    id: 'doctor-evil',
    symbol: 'evil',
    name: 'Doctor Evil',
  },
  {
    id: 'docuchain',
    symbol: 'dcct',
    name: 'DocuChain',
  },
  {
    id: 'documentchain',
    symbol: 'dms',
    name: 'Documentchain',
  },
  {
    id: 'dodo',
    symbol: 'dodo',
    name: 'DODO',
  },
  {
    id: 'dodreamchain',
    symbol: 'cep',
    name: 'CEREAL',
  },
  {
    id: 'dog-3',
    symbol: 'dog',
    name: 'dog',
  },
  {
    id: 'dogai',
    symbol: 'dogai',
    name: 'Dogai',
  },
  {
    id: 'dogami',
    symbol: 'doga',
    name: 'Dogami',
  },
  {
    id: 'dogcoin',
    symbol: 'dogs',
    name: 'Dogcoin',
  },
  {
    id: 'dog-collar',
    symbol: 'collar',
    name: 'Dog Collar',
  },
  {
    id: 'dog-coq',
    symbol: 'dogcoq',
    name: 'DOG COQ',
  },
  {
    id: 'doge-1',
    symbol: 'doge1',
    name: 'DOGE-1',
  },
  {
    id: 'doge-1-mission-to-the-moon',
    symbol: 'doge-1',
    name: 'Doge-1 Mission to the moon',
  },
  {
    id: 'doge-1-moon-mission',
    symbol: 'doge-1',
    name: 'DOGE-1 Moon Mission',
  },
  {
    id: 'doge-1satellite',
    symbol: 'doge-1sat',
    name: 'DOGE-1SATELLITE',
  },
  {
    id: 'doge-2-0',
    symbol: 'doge2.0',
    name: 'Doge 2.0',
  },
  {
    id: 'doge2-0',
    symbol: 'doge2.0',
    name: 'DOGE2.0',
  },
  {
    id: 'doge69',
    symbol: 'doge69',
    name: 'Doge69',
  },
  {
    id: 'dogeai',
    symbol: 'dogeai',
    name: 'DogeAi',
  },
  {
    id: 'dogearmy',
    symbol: 'dogrmy',
    name: 'DogeArmy',
  },
  {
    id: 'dogebonk',
    symbol: 'dobo',
    name: 'DogeBonk',
  },
  {
    id: 'dogebonk-eth',
    symbol: 'dobo',
    name: 'DogeBonk',
  },
  {
    id: 'dogebonk-on-sol',
    symbol: 'dobo',
    name: 'dogebonk on sol',
  },
  {
    id: 'dogeboy',
    symbol: 'dogb',
    name: 'DogeBoy',
  },
  {
    id: 'dogecash',
    symbol: 'dogec',
    name: 'DogeCash',
  },
  {
    id: 'doge-ceo',
    symbol: 'dogeceo',
    name: 'Doge CEO',
  },
  {
    id: 'dogeceomeme',
    symbol: 'dogeceo',
    name: 'DOGE CEO AI',
  },
  {
    id: 'dogechain',
    symbol: 'dc',
    name: 'Dogechain',
  },
  {
    id: 'dogeclub',
    symbol: 'dogc',
    name: 'DogeClub',
  },
  {
    id: 'dogecoin',
    symbol: 'doge',
    name: 'Dogecoin',
  },
  {
    id: 'dogecoin-2',
    symbol: 'doge2',
    name: 'Dogecoin 2.0',
  },
  {
    id: 'dogecola',
    symbol: 'colana',
    name: 'COLANA',
  },
  {
    id: 'dogecube',
    symbol: 'dogecube',
    name: 'DogeCube',
  },
  {
    id: 'dogedi',
    symbol: 'dogedi',
    name: 'DOGEDI',
  },
  {
    id: 'dogedragon',
    symbol: 'dd',
    name: 'DogeDragon',
  },
  {
    id: 'doge-eat-doge',
    symbol: 'omnom',
    name: 'Doge Eat Doge',
  },
  {
    id: 'doge-floki-2-0',
    symbol: '(dofi20',
    name: 'DOGE FLOKI 2.0',
  },
  {
    id: 'doge-floki-coin',
    symbol: 'dofi',
    name: 'Doge Floki Coin [OLD]',
  },
  {
    id: 'doge-floki-coin-2',
    symbol: 'dofi',
    name: 'Doge Floki Coin',
  },
  {
    id: 'dogefood',
    symbol: 'dogefood',
    name: 'DogeFood',
  },
  {
    id: 'dogegayson',
    symbol: 'goge',
    name: 'Goge DAO',
  },
  {
    id: 'dogegf',
    symbol: 'dogegf',
    name: 'DogeGF',
  },
  {
    id: 'doge-grok',
    symbol: 'dogegrok',
    name: 'Doge Grok',
  },
  {
    id: 'dogegrow',
    symbol: 'dgr',
    name: 'DogeGrow',
  },
  {
    id: 'doge-inu',
    symbol: 'dinu',
    name: 'Doge Inu',
  },
  {
    id: 'doge-kaki',
    symbol: 'kaki',
    name: 'Doge KaKi',
  },
  {
    id: 'dogeking',
    symbol: 'dogeking',
    name: 'DogeKing',
  },
  {
    id: 'dogelana',
    symbol: 'dgln',
    name: 'Dogelana',
  },
  {
    id: 'dogelogy',
    symbol: 'doge 0.1',
    name: 'Dogelogy',
  },
  {
    id: 'dogelon-classic',
    symbol: 'elonc',
    name: 'Dogelon Classic',
  },
  {
    id: 'dogelon-mars',
    symbol: 'elon',
    name: 'Dogelon Mars',
  },
  {
    id: 'dogelon-mars-2-0',
    symbol: 'elon2.0',
    name: 'Dogelon Mars 2.0',
  },
  {
    id: 'dogelon-mars-wormhole',
    symbol: 'elon',
    name: 'Dogelon Mars (Wormhole)',
  },
  {
    id: 'doge-lumens',
    symbol: 'dxlm',
    name: 'DogeLumens',
  },
  {
    id: 'dogemeta',
    symbol: 'dogemeta',
    name: 'DOGEMETA',
  },
  {
    id: 'dogemon-go',
    symbol: 'dogo',
    name: 'DogemonGo',
  },
  {
    id: 'dogemoon',
    symbol: 'dogemoon',
    name: 'Dogemoon',
  },
  {
    id: 'dogemoon-2',
    symbol: 'dogemoon',
    name: 'Dogemoon',
  },
  {
    id: 'doge-of-grok-ai',
    symbol: 'dogegrokai',
    name: 'Doge Of Grok AI',
  },
  {
    id: 'dogeon',
    symbol: 'don',
    name: 'Dogeon',
  },
  {
    id: 'doge-on-pulsechain',
    symbol: 'doge',
    name: 'Doge on Pulsechain',
  },
  {
    id: 'dogepad-finance',
    symbol: 'dpf',
    name: 'Dogepad Finance',
  },
  {
    id: 'doge-protocol',
    symbol: 'dogep',
    name: 'Doge Protocol',
  },
  {
    id: 'doge-pup-token',
    symbol: 'dogepup',
    name: 'Doge Pup',
  },
  {
    id: 'dogeshrek',
    symbol: 'dogeshrek',
    name: 'DogeShrek',
  },
  {
    id: 'dogeswap',
    symbol: 'doges',
    name: 'Dogeswap',
  },
  {
    id: 'dogether',
    symbol: 'dogether',
    name: 'Dogether',
  },
  {
    id: 'doge-token',
    symbol: 'doget',
    name: 'Doge Token',
  },
  {
    id: 'doge-tv',
    symbol: '$dgtv',
    name: 'Doge-TV',
  },
  {
    id: 'dogey-inu',
    symbol: 'dinu',
    name: 'Dogey-Inu',
  },
  {
    id: 'dogezilla-2',
    symbol: 'zilla',
    name: 'DogeZilla',
  },
  {
    id: 'dogezilla-ai',
    symbol: 'dai',
    name: 'DogeZilla Ai',
  },
  {
    id: 'dogfinity',
    symbol: 'dogmi',
    name: 'DOGMI',
  },
  {
    id: 'dogggo',
    symbol: 'dogggo',
    name: 'Dogggo',
  },
  {
    id: 'doggo',
    symbol: 'doggo',
    name: 'DOGGO',
  },
  {
    id: 'doggy',
    symbol: 'doggy',
    name: 'Doggy',
  },
  {
    id: 'dogi',
    symbol: 'dogi',
    name: 'dogi',
  },
  {
    id: 'dogihub-doginals',
    symbol: '$hub',
    name: 'Dogihub (DRC-20)',
  },
  {
    id: 'doginal-kabosu-drc-20',
    symbol: 'dosu',
    name: 'Doginal Kabosu (DRC-20)',
  },
  {
    id: 'doginals-club-exclusive-doginals',
    symbol: 'dcex',
    name: 'Doginals Club Exclusive (DRC-20)',
  },
  {
    id: 'doginphire',
    symbol: 'fire',
    name: 'doginphire',
  },
  {
    id: 'dogira',
    symbol: 'dogira',
    name: 'Dogira',
  },
  {
    id: 'dog-of-wisdom',
    symbol: 'wisdm',
    name: 'Dog Of Wisdom',
  },
  {
    id: 'dog-ordinals',
    symbol: '$dog',
    name: '$DOG (Ordinals)',
  },
  {
    id: 'dogpad-finance',
    symbol: 'dogpad',
    name: 'DogPad Finance',
  },
  {
    id: 'dogsofelon',
    symbol: 'doe',
    name: 'Dogs Of Elon',
  },
  {
    id: 'dogs-rock',
    symbol: 'dogsrock',
    name: 'Dogs Rock',
  },
  {
    id: 'dogswap-token',
    symbol: 'dog',
    name: 'Dogeswap (HECO)',
  },
  {
    id: 'dogu-inu',
    symbol: 'dogu',
    name: 'Dogu Inu',
  },
  {
    id: 'dogwifcoin',
    symbol: 'wif',
    name: 'dogwifhat',
  },
  {
    id: 'dogwifcrocs',
    symbol: 'dwc',
    name: 'DOGwifCROCS',
  },
  {
    id: 'dogwifhat-bsc',
    symbol: 'wif',
    name: 'Dogwifhat BSC',
  },
  {
    id: 'dogwifkatana',
    symbol: 'katana',
    name: 'dogwifkatana',
  },
  {
    id: 'dogwifleg',
    symbol: 'leg',
    name: 'dogwifleg',
  },
  {
    id: 'dog-wif-nuchucks',
    symbol: 'ninja',
    name: 'Dog Wif Nunchucks',
  },
  {
    id: 'dogwifouthat',
    symbol: 'wifout',
    name: 'dogwifouthat',
  },
  {
    id: 'dogwifpants',
    symbol: 'pants',
    name: 'dogwifpants',
  },
  {
    id: 'dogyrace',
    symbol: 'dor',
    name: 'DogyRace',
  },
  {
    id: 'dogz',
    symbol: 'dogz',
    name: 'Dogz',
  },
  {
    id: 'dohrnii',
    symbol: 'dhn',
    name: 'Dohrnii',
  },
  {
    id: 'doichain',
    symbol: 'doi',
    name: 'Doichain',
  },
  {
    id: 'dojo',
    symbol: 'dojo',
    name: 'DOJO',
  },
  {
    id: 'dojo-supercomputer',
    symbol: '$dojo',
    name: 'Dojo Supercomputer',
  },
  {
    id: 'dojo-token',
    symbol: 'dojo',
    name: 'Dojo token',
  },
  {
    id: 'doke-inu',
    symbol: 'doke',
    name: 'Doke Inu',
  },
  {
    id: 'doki-doki-finance',
    symbol: 'doki',
    name: 'Doki Doki',
  },
  {
    id: 'dola-borrowing-right',
    symbol: 'dbr',
    name: 'DOLA Borrowing Right',
  },
  {
    id: 'dola-usd',
    symbol: 'dola',
    name: 'DOLA',
  },
  {
    id: 'dollarback',
    symbol: 'back',
    name: 'DollarBack',
  },
  {
    id: 'dollarmoon',
    symbol: 'dmoon',
    name: 'DollarMoon',
  },
  {
    id: 'dollar-on-chain',
    symbol: 'doc',
    name: 'Dollar On Chain',
  },
  {
    id: 'dollarsqueeze',
    symbol: 'dsq',
    name: 'DollarSqueeze',
  },
  {
    id: 'dolz-io',
    symbol: 'dolz',
    name: 'DOLZ.io',
  },
  {
    id: 'domi',
    symbol: 'domi',
    name: 'Domi',
  },
  {
    id: 'dominator-domains',
    symbol: 'domdom',
    name: 'Dominator Domains',
  },
  {
    id: 'dominica-coin',
    symbol: 'dmc',
    name: 'Dominica Coin',
  },
  {
    id: 'dominium-2',
    symbol: 'dom',
    name: 'Dominium',
  },
  {
    id: 'domo',
    symbol: 'domo',
    name: 'DOMO',
  },
  {
    id: 'donald-trump',
    symbol: 'trump2024',
    name: 'Donald Trump',
  },
  {
    id: 'donaswap',
    symbol: 'dona',
    name: 'Donaswap',
  },
  {
    id: 'dongcoin',
    symbol: 'dong',
    name: 'DongCoin',
  },
  {
    id: 'donk',
    symbol: 'donk',
    name: 'DONK',
  },
  {
    id: 'donkey',
    symbol: 'donk',
    name: 'Donkey',
  },
  {
    id: 'don-key',
    symbol: 'don',
    name: 'Don-key',
  },
  {
    id: 'dons',
    symbol: 'dons',
    name: 'The Dons',
  },
  {
    id: 'don-t-buy-inu',
    symbol: 'dbi',
    name: "Don't Buy Inu",
  },
  {
    id: 'donut',
    symbol: 'donut',
    name: 'Donut',
  },
  {
    id: 'doom-hero-dao',
    symbol: 'dhd',
    name: 'Doom Hero Dao',
  },
  {
    id: 'doont-buy',
    symbol: 'dbuy',
    name: 'Doont Buy',
  },
  {
    id: 'dope-wars-paper',
    symbol: 'paper',
    name: 'Dope Wars Paper',
  },
  {
    id: 'dopewarz',
    symbol: 'dwz',
    name: 'DopeWarz',
  },
  {
    id: 'dopex',
    symbol: 'dpx',
    name: 'Dopex',
  },
  {
    id: 'dopex-rebate-token',
    symbol: 'rdpx',
    name: 'Dopex Rebate',
  },
  {
    id: 'dopex-receipt-token-eth',
    symbol: 'rteth',
    name: 'Dopex Receipt Token ETH',
  },
  {
    id: 'dor',
    symbol: 'dor',
    name: 'Dor',
  },
  {
    id: 'dora-factory',
    symbol: 'dora',
    name: 'Dora Factory [OLD]',
  },
  {
    id: 'dora-factory-2',
    symbol: 'dora',
    name: 'Dora Factory',
  },
  {
    id: 'doric-network',
    symbol: 'drc',
    name: 'Doric Network',
  },
  {
    id: 'dork',
    symbol: 'dork',
    name: 'DORK',
  },
  {
    id: 'dork-lord',
    symbol: 'dorkl',
    name: 'DORK LORD',
  },
  {
    id: 'dorkordinalbitcoinbinance',
    symbol: 'dobby',
    name: 'DorkOrdinalBitcoinBinance',
  },
  {
    id: 'dos-chain',
    symbol: 'dos',
    name: 'DOS Chain',
  },
  {
    id: 'dose-token',
    symbol: 'dose',
    name: 'DOSE',
  },
  {
    id: 'dos-network',
    symbol: 'dos',
    name: 'DOS Network',
  },
  {
    id: 'dot-dot-finance',
    symbol: 'ddd',
    name: 'Dot Dot Finance',
  },
  {
    id: 'dot-finance',
    symbol: 'pink',
    name: 'Dot Finance',
  },
  {
    id: 'dotlab',
    symbol: 'dtl',
    name: 'Dotlab',
  },
  {
    id: 'dotmoovs',
    symbol: 'moov',
    name: 'dotmoovs',
  },
  {
    id: 'dotori',
    symbol: 'dtr',
    name: 'Dotori',
  },
  {
    id: 'doubloon',
    symbol: 'dbl',
    name: 'Doubloon',
  },
  {
    id: 'doug',
    symbol: 'doug',
    name: 'Doug',
  },
  {
    id: 'dough',
    symbol: 'dough',
    name: 'Dough',
  },
  {
    id: 'douglas-adams',
    symbol: 'hhgttg',
    name: 'Douglas Adams',
  },
  {
    id: 'doveswap',
    symbol: 'dov',
    name: 'DoveSwap',
  },
  {
    id: 'dovi',
    symbol: 'dovi',
    name: 'DOVI',
  },
  {
    id: 'dovu',
    symbol: 'dov',
    name: 'Dovu [OLD]',
  },
  {
    id: 'dovu-2',
    symbol: 'dovu',
    name: 'DOVU',
  },
  {
    id: 'dozy-ordinals',
    symbol: 'dozy',
    name: 'Dozy (Ordinals)',
  },
  {
    id: 'dpad-finance',
    symbol: 'dpad',
    name: 'Dpad Finance',
  },
  {
    id: 'dparrot',
    symbol: 'parrot',
    name: 'dPARROT',
  },
  {
    id: 'dpex',
    symbol: 'dpex',
    name: 'DPEX',
  },
  {
    id: 'dprating',
    symbol: 'rating',
    name: 'DPRating',
  },
  {
    id: 'dprk-coin',
    symbol: 'dprk',
    name: 'DPRK Coin',
  },
  {
    id: 'dps-doubloon',
    symbol: 'dbl',
    name: 'DPS Doubloon [OLD]',
  },
  {
    id: 'dps-doubloon-2',
    symbol: 'dbl',
    name: 'DPS Doubloon',
  },
  {
    id: 'dps-rum-2',
    symbol: 'rum',
    name: 'DPS Rum',
  },
  {
    id: 'dps-treasuremaps-2',
    symbol: 'tmap',
    name: 'DPS TreasureMaps',
  },
  {
    id: 'dracarys-token',
    symbol: 'dra',
    name: 'Dracarys Token',
  },
  {
    id: 'drac-network',
    symbol: 'drac',
    name: 'DRAC Network',
  },
  {
    id: 'dracoo-point',
    symbol: 'dra',
    name: 'Dracoo Point',
  },
  {
    id: 'drac-ordinals',
    symbol: 'drac',
    name: 'DRAC (Ordinals)',
  },
  {
    id: 'dracula-fi',
    symbol: 'fang',
    name: 'Dracula Fi',
  },
  {
    id: 'draggable-aktionariat-ag',
    symbol: 'daks',
    name: 'Draggable Aktionariat AG',
  },
  {
    id: 'drago',
    symbol: 'drago',
    name: 'Drago',
  },
  {
    id: 'dragoma',
    symbol: 'dma',
    name: 'Dragoma',
  },
  {
    id: 'dragon-2',
    symbol: 'dragon',
    name: 'Dragon',
  },
  {
    id: 'dragon-3',
    symbol: 'dragon',
    name: 'Dragon',
  },
  {
    id: 'dragon-arena',
    symbol: 'dra',
    name: 'Dragon Arena',
  },
  {
    id: 'dragonchain',
    symbol: 'drgn',
    name: 'Dragonchain',
  },
  {
    id: 'dragoncoin',
    symbol: 'dragon',
    name: 'DragonCoin',
  },
  {
    id: 'dragon-crypto-argenti',
    symbol: 'dcar',
    name: 'Dragon Crypto Argenti',
  },
  {
    id: 'dragon-crypto-aurum',
    symbol: 'dcau',
    name: 'Dragon Crypto Aurum',
  },
  {
    id: 'dragonking',
    symbol: 'dragonking',
    name: 'DragonKing',
  },
  {
    id: 'dragon-mainland-shards',
    symbol: 'dms',
    name: 'Dragon Mainland Shards',
  },
  {
    id: 'dragonmaster-token',
    symbol: 'dmt',
    name: 'DragonMaster',
  },
  {
    id: 'dragonmaster-totem',
    symbol: 'totem',
    name: 'DragonMaster Totem',
  },
  {
    id: 'dragon-ordinals',
    symbol: 'drag',
    name: 'DRAGON (Ordinals)',
  },
  {
    id: 'dragon-soul-token',
    symbol: 'dst',
    name: 'Dragon Soul Token',
  },
  {
    id: 'dragon-s-quick',
    symbol: 'dquick',
    name: "Dragon's Quick",
  },
  {
    id: 'dragons-quick',
    symbol: 'dquick',
    name: "Dragon's Quick",
  },
  {
    id: 'dragon-war',
    symbol: 'draw',
    name: 'Dragon War',
  },
  {
    id: 'dragonx',
    symbol: 'dragon',
    name: 'DragonX',
  },
  {
    id: 'dragonx-win',
    symbol: 'dragonx',
    name: 'DragonX.win',
  },
  {
    id: 'draken',
    symbol: 'drk',
    name: 'Draken',
  },
  {
    id: 'drake-s-dog',
    symbol: 'diamond',
    name: "Drake's Dog",
  },
  {
    id: 'drako',
    symbol: 'drako',
    name: 'Drako',
  },
  {
    id: 'drawshop-kingdom-reverse-joystick',
    symbol: 'joy',
    name: 'Drawshop Kingdom Reverse Joystick',
  },
  {
    id: 'drc-mobility',
    symbol: 'drc',
    name: 'DRC Mobility',
  },
  {
    id: 'dream-machine-token',
    symbol: 'dmt',
    name: 'Dream Machine Token',
  },
  {
    id: 'dream-marketplace',
    symbol: 'dream',
    name: 'Dream',
  },
  {
    id: 'dreampad-capital',
    symbol: 'dreampad',
    name: 'DreamPad Capital',
  },
  {
    id: 'dreamscoin',
    symbol: 'dream',
    name: 'DreamsCoin',
  },
  {
    id: 'dreams-quest',
    symbol: 'dreams',
    name: 'Dreams Quest',
  },
  {
    id: 'dream-token',
    symbol: 'dream',
    name: 'Dream',
  },
  {
    id: 'dreamverse',
    symbol: 'dv',
    name: 'Dreamverse',
  },
  {
    id: 'drep-new',
    symbol: 'drep',
    name: 'Drep',
  },
  {
    id: 'drife',
    symbol: 'drf',
    name: 'Drife',
  },
  {
    id: 'dripdropz',
    symbol: 'drip',
    name: 'DripDropz',
  },
  {
    id: 'drip-network',
    symbol: 'drip',
    name: 'Drip Network',
  },
  {
    id: 'drive-to-earn',
    symbol: 'dte',
    name: 'Drive To Earn',
  },
  {
    id: 'droggy',
    symbol: 'droggy',
    name: 'Droggy',
  },
  {
    id: 'droparb',
    symbol: 'drop',
    name: 'DropArb',
  },
  {
    id: 'dropcoin-club',
    symbol: 'drop',
    name: 'DropCoin',
  },
  {
    id: 'drops-ownership-power',
    symbol: 'dop',
    name: 'Drops Ownership Power',
  },
  {
    id: 'drop-wireless-infrastructure',
    symbol: 'dwin',
    name: 'Drop Wireless Infrastructure',
  },
  {
    id: 'drunk-robots',
    symbol: 'metal',
    name: 'Badmad Robots',
  },
  {
    id: 'drunk-skunks-drinking-club',
    symbol: 'stink',
    name: 'Drunk Skunks Drinking Club',
  },
  {
    id: 'dsc-mix',
    symbol: 'mix',
    name: 'DSC Mix',
  },
  {
    id: 'dshares',
    symbol: 'dshare',
    name: 'DShares',
  },
  {
    id: 'd-shop',
    symbol: 'dp',
    name: 'D-SHOP',
  },
  {
    id: 'dsquared-finance',
    symbol: 'dsq',
    name: 'Dsquared.finance',
  },
  {
    id: 'dsun-token',
    symbol: 'dsun',
    name: 'Dsun Token',
  },
  {
    id: 'dtng',
    symbol: 'dtng',
    name: 'DTNG',
  },
  {
    id: 'dtools',
    symbol: 'dtools',
    name: 'DogeTools',
  },
  {
    id: 'dtravel',
    symbol: 'trvl',
    name: 'TRVL',
  },
  {
    id: 'dtsla',
    symbol: 'dtsla',
    name: 'Tesla Tokenized Stock Defichain',
  },
  {
    id: 'dtube-coin',
    symbol: 'dtube',
    name: 'Dtube Coin',
  },
  {
    id: 'dual-finance',
    symbol: 'dual',
    name: 'Dual Finance',
  },
  {
    id: 'dua-token',
    symbol: 'dua',
    name: 'Brillion',
  },
  {
    id: 'dubbz',
    symbol: 'dubbz',
    name: 'Dubbz',
  },
  {
    id: 'dubx',
    symbol: 'dub',
    name: 'DUBX',
  },
  {
    id: 'ducatus',
    symbol: 'ducx',
    name: 'DucatusX',
  },
  {
    id: 'duckdao',
    symbol: 'dd',
    name: 'DuckDAO',
  },
  {
    id: 'duckdaodime',
    symbol: 'ddim',
    name: 'DuckDaoDime',
  },
  {
    id: 'duckduck-token',
    symbol: 'duck',
    name: 'DuckDuck',
  },
  {
    id: 'ducker',
    symbol: 'ducker',
    name: 'Ducker',
  },
  {
    id: 'duckereum',
    symbol: 'ducker',
    name: 'Duckereum',
  },
  {
    id: 'duckie-land-multi-metaverse',
    symbol: 'mmeta',
    name: 'Duckie Land Multi Metaverse',
  },
  {
    id: 'duckies',
    symbol: 'duckies',
    name: 'Yellow Duckies',
  },
  {
    id: 'ducks',
    symbol: 'ducks',
    name: 'Ducks',
  },
  {
    id: 'duckydefi',
    symbol: 'degg',
    name: 'DuckyDefi',
  },
  {
    id: 'dude',
    symbol: 'dude',
    name: 'DuDe',
  },
  {
    id: 'dude-injective',
    symbol: 'dude',
    name: 'DUDE (Injective)',
  },
  {
    id: 'duel',
    symbol: 'duel',
    name: 'DUEL',
  },
  {
    id: 'duel-network-2',
    symbol: 'duel',
    name: 'Duel Network',
  },
  {
    id: 'duet-protocol',
    symbol: 'duet',
    name: 'Duet Protocol',
  },
  {
    id: 'duke-inu-token',
    symbol: 'duke',
    name: 'Duke Inu',
  },
  {
    id: 'dumbmoney',
    symbol: 'gme',
    name: 'DumbMoney',
  },
  {
    id: 'dumbmoney-2',
    symbol: 'gme',
    name: 'DumbMoney',
  },
  {
    id: 'dummy',
    symbol: 'dummy',
    name: 'DUMMY',
  },
  {
    id: 'dungeonswap',
    symbol: 'dnd',
    name: 'DungeonSwap',
  },
  {
    id: 'dungeon-token',
    symbol: 'geon',
    name: 'Triathon',
  },
  {
    id: 'dusd',
    symbol: 'dusd',
    name: 'DUSD',
  },
  {
    id: 'dusk-network',
    symbol: 'dusk',
    name: 'Dusk',
  },
  {
    id: 'dust-city-nectar',
    symbol: 'nctr',
    name: 'Nectar',
  },
  {
    id: 'dust-protocol',
    symbol: 'dust',
    name: 'Dust Protocol',
  },
  {
    id: 'dux',
    symbol: 'dux',
    name: 'DUX',
  },
  {
    id: 'duzu-world',
    symbol: 'duzu',
    name: 'Duzu World',
  },
  {
    id: 'dvision-network',
    symbol: 'dvi',
    name: 'Dvision Network',
  },
  {
    id: 'dxchain',
    symbol: 'dx',
    name: 'DxChain',
  },
  {
    id: 'dxdao',
    symbol: 'dxd',
    name: 'DXdao',
  },
  {
    id: 'dxsale-network',
    symbol: 'sale',
    name: 'DxSale Network',
  },
  {
    id: 'dx-spot',
    symbol: 'dxs',
    name: 'Dx Spot',
  },
  {
    id: 'dydx',
    symbol: 'ethdydx',
    name: 'dYdX',
  },
  {
    id: 'dydx-chain',
    symbol: 'dydx',
    name: 'dYdX',
  },
  {
    id: 'dydx-wethdydx',
    symbol: 'wethdydx',
    name: 'dYdX',
  },
  {
    id: 'dydx-wormhole',
    symbol: 'dydx',
    name: 'dYdX (Wormhole)',
  },
  {
    id: 'dykan',
    symbol: 'dkn',
    name: 'DYKAN',
  },
  {
    id: 'dyl',
    symbol: 'dyl',
    name: 'Dyl',
  },
  {
    id: 'dymension',
    symbol: 'dym',
    name: 'Dymension',
  },
  {
    id: 'dymmax',
    symbol: 'dmx',
    name: 'Dymmax',
  },
  {
    id: 'dynamic-finance',
    symbol: 'dyna',
    name: 'Dynamic Finance',
  },
  {
    id: 'dynamite-token',
    symbol: 'dynmt',
    name: 'Dynamite',
  },
  {
    id: 'dynamix',
    symbol: 'dyna',
    name: 'Dynamix',
  },
  {
    id: 'dynamo-coin',
    symbol: 'dynamo',
    name: 'Dynamo Coin',
  },
  {
    id: 'dynex',
    symbol: 'dnx',
    name: 'Dynex',
  },
  {
    id: 'dyor-token-2',
    symbol: 'dyor',
    name: 'DYOR',
  },
  {
    id: 'dypius',
    symbol: 'dyp',
    name: 'Dypius',
  },
  {
    id: 'dyson-sphere',
    symbol: 'dysn',
    name: 'Dyson Sphere',
  },
  {
    id: 'dystopia',
    symbol: 'dyst',
    name: 'Dystopia',
  },
  {
    id: 'dyzilla',
    symbol: 'dyzilla',
    name: 'DYZilla',
  },
  {
    id: 'earnbet',
    symbol: 'ebet',
    name: 'EarnBet',
  },
  {
    id: 'earndefi',
    symbol: 'edc',
    name: 'EarnDeFi',
  },
  {
    id: 'earn-network-2',
    symbol: 'earn',
    name: 'Earn Network',
  },
  {
    id: 'earntv',
    symbol: 'etv',
    name: 'EarnTV',
  },
  {
    id: 'earthbyt',
    symbol: 'ebyt',
    name: 'EarthByt',
  },
  {
    id: 'earthfund',
    symbol: '1earth',
    name: 'EarthFund',
  },
  {
    id: 'eastgate-pharmaceuticals',
    symbol: 'egp',
    name: 'EastGate Pharmaceuticals',
  },
  {
    id: 'easyfi',
    symbol: 'ez',
    name: 'EasyFi V2',
  },
  {
    id: 'easy-swap-bot',
    symbol: 'ezswap',
    name: 'Easy Swap Bot',
  },
  {
    id: 'easytoken',
    symbol: 'eyt',
    name: 'EasyToken',
  },
  {
    id: 'eazyswap-token',
    symbol: 'eazy',
    name: 'EazySwap Token',
  },
  {
    id: 'ebase',
    symbol: 'ebase',
    name: 'eBASE',
  },
  {
    id: 'ebisusbay-fortune',
    symbol: 'frtn',
    name: 'Fortune Token',
  },
  {
    id: 'eblockstock',
    symbol: 'ebso',
    name: 'eBlockStock',
  },
  {
    id: 'ebox',
    symbol: 'ebox',
    name: 'Ebox',
  },
  {
    id: 'ecash',
    symbol: 'xec',
    name: 'eCash',
  },
  {
    id: 'echain-network',
    symbol: 'ect',
    name: 'Echain Network',
  },
  {
    id: 'echelon-prime',
    symbol: 'prime',
    name: 'Echelon Prime',
  },
  {
    id: 'echidna',
    symbol: 'ecd',
    name: 'Echidna',
  },
  {
    id: 'echo-bot',
    symbol: 'echo',
    name: 'Echo Bot',
  },
  {
    id: 'echodex-community-portion',
    symbol: 'ecp',
    name: 'EchoDEX Community Portion',
  },
  {
    id: 'echoes',
    symbol: 'echoes',
    name: 'Echoes',
  },
  {
    id: 'echolink',
    symbol: 'eko',
    name: 'EchoLink',
  },
  {
    id: 'echo-of-the-horizon',
    symbol: 'eoth',
    name: 'Echo Of The Horizon',
  },
  {
    id: 'echosoracoin',
    symbol: 'esrc',
    name: 'EchoSoraCoin',
  },
  {
    id: 'eclat',
    symbol: 'elt',
    name: 'ECLAT',
  },
  {
    id: 'eclipse-fi',
    symbol: 'eclip',
    name: 'Eclipse Fi',
  },
  {
    id: 'eco',
    symbol: 'eco',
    name: 'ECO',
  },
  {
    id: 'ecochain-2',
    symbol: 'eco',
    name: 'Ecochain',
  },
  {
    id: 'ecochain-token',
    symbol: 'ect',
    name: 'Ecochain Finance',
  },
  {
    id: 'ecocredit',
    symbol: 'eco',
    name: 'EcoCREDIT',
  },
  {
    id: 'ecog9coin',
    symbol: 'egc',
    name: 'EcoG9coin',
  },
  {
    id: 'ecoin-2',
    symbol: 'ecoin',
    name: 'Ecoin',
  },
  {
    id: 'ecoin-finance',
    symbol: 'ecoin',
    name: 'Ecoin Finance',
  },
  {
    id: 'ecomi',
    symbol: 'omi',
    name: 'ECOMI',
  },
  {
    id: 'ecoreal-estate',
    symbol: 'ecoreal',
    name: 'Ecoreal Estate',
  },
  {
    id: 'ecoscu',
    symbol: 'ecu',
    name: 'ECOSC',
  },
  {
    id: 'ecoterra',
    symbol: 'ecoterra',
    name: 'Ecoterra',
  },
  {
    id: 'ecowatt',
    symbol: 'ewt',
    name: 'Ecowatt',
  },
  {
    id: 'ecox',
    symbol: 'ecox',
    name: 'ECOx',
  },
  {
    id: 'ecredits',
    symbol: 'ecs',
    name: 'eCredits',
  },
  {
    id: 'ecs-gold',
    symbol: 'ecg',
    name: 'ECS Gold',
  },
  {
    id: 'e-c-vitoria-fan-token',
    symbol: 'vtra',
    name: 'E.C. Vitoria Fan Token',
  },
  {
    id: 'edain',
    symbol: 'eai',
    name: 'Edain',
  },
  {
    id: 'eddaswap',
    symbol: 'edda',
    name: 'EDDASwap',
  },
  {
    id: 'edelcoin',
    symbol: 'edlc',
    name: 'Edelcoin',
  },
  {
    id: 'eden',
    symbol: 'eden',
    name: 'EDEN',
  },
  {
    id: 'edfi',
    symbol: 'edfi',
    name: 'EdFi',
  },
  {
    id: 'edge',
    symbol: 'edge',
    name: 'Edge',
  },
  {
    id: 'edgecoin-2',
    symbol: 'edgt',
    name: 'Edgecoin',
  },
  {
    id: 'edgefolio',
    symbol: 'efolio',
    name: 'Edgefolio',
  },
  {
    id: 'edgeless',
    symbol: 'edg',
    name: 'Edgeless',
  },
  {
    id: 'edge-matrix-computing',
    symbol: 'emc',
    name: 'Edge Matrix Computing',
  },
  {
    id: 'edgeswap',
    symbol: 'egs',
    name: 'EdgeSwap',
  },
  {
    id: 'edgeware',
    symbol: 'edg',
    name: 'Edgeware',
  },
  {
    id: 'edns-domains',
    symbol: 'edns',
    name: 'EDNS Domains',
  },
  {
    id: 'edoverse-zeni',
    symbol: 'zeni',
    name: 'Edoverse Zeni',
  },
  {
    id: 'edrivetoken',
    symbol: 'edt',
    name: 'EDriveToken',
  },
  {
    id: 'edu-coin',
    symbol: 'edu',
    name: 'Open Campus',
  },
  {
    id: 'edufex',
    symbol: 'edux',
    name: 'Edufex',
  },
  {
    id: 'edum',
    symbol: 'edum',
    name: 'EDUM',
  },
  {
    id: 'eeg',
    symbol: 'eeg',
    name: 'EEG',
  },
  {
    id: 'eeyor',
    symbol: 'eeyor',
    name: 'Eeyor',
  },
  {
    id: 'effect-network',
    symbol: 'efx',
    name: 'Effect Network',
  },
  {
    id: 'efin-decentralized',
    symbol: 'wefin',
    name: 'eFin Decentralized',
  },
  {
    id: 'efinity',
    symbol: 'efi',
    name: 'Efinity',
  },
  {
    id: 'efk-token',
    symbol: 'efk',
    name: 'EFK Token',
  },
  {
    id: 'eflancer',
    symbol: 'efcr',
    name: 'EFLANCER',
  },
  {
    id: 'efun',
    symbol: 'efun',
    name: 'EFUN',
  },
  {
    id: 'egaz',
    symbol: 'egaz',
    name: 'EGAZ',
  },
  {
    id: 'egg',
    symbol: 'egg',
    name: 'EGG',
  },
  {
    id: 'eggdog',
    symbol: 'egg',
    name: 'Eggdog',
  },
  {
    id: 'egg-n-partners',
    symbol: 'eggt',
    name: 'Egg N Partners',
  },
  {
    id: 'eggplant-finance',
    symbol: 'eggp',
    name: 'Eggplant Finance',
  },
  {
    id: 'eggs',
    symbol: 'eggs',
    name: 'Eggs',
  },
  {
    id: 'eggzomania',
    symbol: 'egg',
    name: 'EggZomania',
  },
  {
    id: 'egold-project',
    symbol: 'egold',
    name: 'EGold Project (OLD)',
  },
  {
    id: 'egold-project-2',
    symbol: 'egold',
    name: 'EGold Project',
  },
  {
    id: 'egoras-credit',
    symbol: 'egc',
    name: 'Egoras Credit',
  },
  {
    id: 'eg-token',
    symbol: 'eg',
    name: 'EG Token',
  },
  {
    id: 'ehash',
    symbol: 'ehash',
    name: 'EHash',
  },
  {
    id: 'einsteinium',
    symbol: 'emc2',
    name: 'Einsteinium',
  },
  {
    id: 'ekta-2',
    symbol: 'ekta',
    name: 'Ekta',
  },
  {
    id: 'elan',
    symbol: 'elan',
    name: 'Elan',
  },
  {
    id: 'elastos',
    symbol: 'ela',
    name: 'Elastos',
  },
  {
    id: 'eldarune',
    symbol: 'elda',
    name: 'Eldarune',
  },
  {
    id: 'el-dorado-exchange-arb',
    symbol: 'ede',
    name: 'El Dorado Exchange (Arb)',
  },
  {
    id: 'el-dorado-exchange-base',
    symbol: 'ede',
    name: 'El Dorado Exchange (Base)',
  },
  {
    id: 'electra',
    symbol: 'eca',
    name: 'Electra',
  },
  {
    id: 'electra-protocol',
    symbol: 'xep',
    name: 'Electra Protocol',
  },
  {
    id: 'electric-cash',
    symbol: 'elcash',
    name: 'Electric Cash',
  },
  {
    id: 'electric-vehicle-direct-currency',
    symbol: 'evdc',
    name: 'Electric Vehicle Direct Currency',
  },
  {
    id: 'electric-vehicle-zone',
    symbol: 'evz',
    name: 'Electric Vehicle Zone',
  },
  {
    id: 'electrify-asia',
    symbol: 'elec',
    name: 'Electrify.Asia',
  },
  {
    id: 'electroneum',
    symbol: 'etn',
    name: 'Electroneum',
  },
  {
    id: 'electronicgulden',
    symbol: 'efl',
    name: 'Electronic Gulden',
  },
  {
    id: 'electronic-usd',
    symbol: 'eusd',
    name: 'Electronic USD',
  },
  {
    id: 'elefant',
    symbol: 'ele',
    name: 'Elefant',
  },
  {
    id: 'element',
    symbol: 'elmt',
    name: 'Element',
  },
  {
    id: 'elemental-story',
    symbol: 'pgt',
    name: 'Elemental Story',
  },
  {
    id: 'element-black',
    symbol: 'elt',
    name: 'Element Black',
  },
  {
    id: 'elementum',
    symbol: 'ele',
    name: 'Elementum',
  },
  {
    id: 'elephant-money',
    symbol: 'elephant',
    name: 'Elephant Money',
  },
  {
    id: 'elevate-token',
    symbol: '$elev',
    name: 'Elevate Token',
  },
  {
    id: 'el-gato',
    symbol: 'elgato',
    name: 'el gato',
  },
  {
    id: 'el-hippo',
    symbol: 'hipp',
    name: 'El Hippo',
  },
  {
    id: 'eligma',
    symbol: 'goc',
    name: 'GoCrypto',
  },
  {
    id: 'elis',
    symbol: 'xls',
    name: 'ELIS',
  },
  {
    id: 'elixir-finance',
    symbol: 'elxr',
    name: 'Elixir Finance',
  },
  {
    id: 'elk-finance',
    symbol: 'elk',
    name: 'Elk Finance',
  },
  {
    id: 'ellerium',
    symbol: 'elm',
    name: 'ELLERIUM',
  },
  {
    id: 'ellipsis',
    symbol: 'eps',
    name: 'Ellipsis [OLD]',
  },
  {
    id: 'ellipsis-x',
    symbol: 'epx',
    name: 'Ellipsis X',
  },
  {
    id: 'elmoerc',
    symbol: 'elmo',
    name: 'ElmoERC',
  },
  {
    id: 'eloin',
    symbol: 'eloin',
    name: 'Eloin',
  },
  {
    id: 'elo-inu',
    symbol: 'elo inu',
    name: 'Elo Inu',
  },
  {
    id: 'elon-2024',
    symbol: 'elon2024',
    name: 'ELON 2024',
  },
  {
    id: 'elon-cat',
    symbol: 'schrodinge',
    name: 'Elon Cat',
  },
  {
    id: 'elon-cat-2',
    symbol: 'eloncat',
    name: 'Elon Cat',
  },
  {
    id: 'elondoge-dao',
    symbol: 'edao',
    name: 'ElonDoge DAO',
  },
  {
    id: 'elon-doge-token',
    symbol: 'edoge',
    name: 'ElonDoge.io',
  },
  {
    id: 'elon-dragon',
    symbol: 'elondragon',
    name: 'ELON DRAGON',
  },
  {
    id: 'elongate-2',
    symbol: 'elongate',
    name: 'ELONGATE',
  },
  {
    id: 'elon-goat',
    symbol: 'egt',
    name: 'Elon GOAT',
  },
  {
    id: 'elonium',
    symbol: 'elon',
    name: 'Elonium',
  },
  {
    id: 'elon-mars',
    symbol: 'elonmars',
    name: 'Elon Mars',
  },
  {
    id: 'elon-musk-ceo',
    symbol: 'elonmuskce',
    name: 'Elon Musk CEO',
  },
  {
    id: 'elonx',
    symbol: 'elonx',
    name: 'ELONX',
  },
  {
    id: 'elonxaidogemessi69pepeinu',
    symbol: 'bitcoin',
    name: 'ElonXAIDogeMessi69PepeInu',
  },
  {
    id: 'elon-xmas',
    symbol: 'xmas',
    name: 'Elon Xmas',
  },
  {
    id: 'elosys',
    symbol: 'elo',
    name: 'Elosys',
  },
  {
    id: 'elpis-battle',
    symbol: 'eba',
    name: 'Elpis Battle',
  },
  {
    id: 'el-risitas',
    symbol: 'kek',
    name: 'El Risitas',
  },
  {
    id: 'elrond-erd-2',
    symbol: 'egld',
    name: 'MultiversX',
  },
  {
    id: 'elsd-coin',
    symbol: 'elsd',
    name: 'ELSD Coin',
  },
  {
    id: 'elseverse-world',
    symbol: 'ells',
    name: 'ElseVerse World',
  },
  {
    id: 'elumia',
    symbol: 'elu',
    name: 'Elumia',
  },
  {
    id: 'elvishmagic',
    symbol: 'emagic',
    name: 'ElvishMagic',
  },
  {
    id: 'elya',
    symbol: 'elya',
    name: 'Elya',
  },
  {
    id: 'elyfi',
    symbol: 'elfi',
    name: 'ELYFI',
  },
  {
    id: 'elysia',
    symbol: 'el',
    name: 'ELYSIA',
  },
  {
    id: 'elysiant-token',
    symbol: 'els',
    name: 'Elysian ELS',
  },
  {
    id: 'elysiumg',
    symbol: 'lcmg',
    name: 'ElysiumG',
  },
  {
    id: 'elysium-token',
    symbol: 'elys',
    name: 'Elysium Token',
  },
  {
    id: 'elyssa',
    symbol: 'ely',
    name: 'Elyssa',
  },
  {
    id: 'emartzon',
    symbol: 'emz',
    name: 'Emartzon',
  },
  {
    id: 'ember',
    symbol: 'ember',
    name: 'Ember',
  },
  {
    id: 'ember-2',
    symbol: 'embr',
    name: 'Ember',
  },
  {
    id: 'embr',
    symbol: 'embr',
    name: 'Embr',
  },
  {
    id: 'emdx',
    symbol: 'emdx',
    name: 'EMDX',
  },
  {
    id: 'emerald-crypto',
    symbol: 'emd',
    name: 'Emerald Crypto',
  },
  {
    id: 'emercoin',
    symbol: 'emc',
    name: 'EmerCoin',
  },
  {
    id: 'emerging-assets-group',
    symbol: 'eag',
    name: 'Emerging Assets Group',
  },
  {
    id: 'emg-coin',
    symbol: 'emgs',
    name: 'EMG SuperApp',
  },
  {
    id: 'eminer',
    symbol: 'em',
    name: 'Eminer',
  },
  {
    id: 'emingunsirer',
    symbol: 'egs',
    name: 'EminGunSirer',
  },
  {
    id: 'eml-protocol',
    symbol: 'eml',
    name: 'EML Protocol',
  },
  {
    id: 'emmi-gg',
    symbol: 'emmi',
    name: 'EMMI GG',
  },
  {
    id: 'emmy',
    symbol: 'emmy',
    name: 'Emmy',
  },
  {
    id: 'emoji-erc20',
    symbol: '$emoji',
    name: 'emoji ERC20',
  },
  {
    id: 'e-money',
    symbol: 'ngm',
    name: 'e-Money',
  },
  {
    id: 'e-money-eur',
    symbol: 'eeur',
    name: 'e-Money EUR',
  },
  {
    id: 'emorya-finance',
    symbol: 'emr',
    name: 'Emorya Finance',
  },
  {
    id: 'emotech',
    symbol: 'emt',
    name: 'EmoTech',
  },
  {
    id: 'emoticoin',
    symbol: 'emoti',
    name: 'EmotiCoin',
  },
  {
    id: 'empire-token',
    symbol: 'empire',
    name: 'Empire',
  },
  {
    id: 'empowa',
    symbol: 'emp',
    name: 'Empowa',
  },
  {
    id: 'emp-shares-v2',
    symbol: 'eshare v2',
    name: 'EMP Shares',
  },
  {
    id: 'empyreal',
    symbol: 'emp',
    name: 'Empyreal',
  },
  {
    id: 'encoins',
    symbol: 'encs',
    name: 'Encoins',
  },
  {
    id: 'encrypgen',
    symbol: 'dna',
    name: 'EncrypGen',
  },
  {
    id: 'encrypt',
    symbol: '$encr',
    name: 'ENCRYPT',
  },
  {
    id: 'endblock',
    symbol: 'end',
    name: 'Endblock',
  },
  {
    id: 'endless-battlefield',
    symbol: 'eng',
    name: 'Endless Board Game',
  },
  {
    id: 'endlesswebworlds',
    symbol: 'eww',
    name: 'EndlessWebWorlds',
  },
  {
    id: 'endor',
    symbol: 'edr',
    name: 'Endor Protocol',
  },
  {
    id: 'endpoint-cex-fan-token',
    symbol: 'endcex',
    name: 'Endpoint Cex Fan Token',
  },
  {
    id: 'endurance',
    symbol: 'ace',
    name: 'Fusionist',
  },
  {
    id: 'eneftor',
    symbol: 'eftr',
    name: 'Eneftor',
  },
  {
    id: 'enegra',
    symbol: 'egx',
    name: 'Enegra',
  },
  {
    id: 'energi',
    symbol: 'nrg',
    name: 'Energi',
  },
  {
    id: 'energi-dollar',
    symbol: 'usde',
    name: 'Energi Dollar',
  },
  {
    id: 'energo',
    symbol: 'tsl',
    name: 'Tesla TSL',
  },
  {
    id: 'energreen',
    symbol: 'egrn',
    name: 'Energreen',
  },
  {
    id: 'energy8',
    symbol: 'e8',
    name: 'Energy8',
  },
  {
    id: 'energy-efficient-mortgage-tokenized-stock-defichain',
    symbol: 'deem',
    name: 'iShares MSCI Emerging Markets ETF Defichain',
  },
  {
    id: 'energyfi',
    symbol: 'eft',
    name: 'Energyfi',
  },
  {
    id: 'energytrade-token',
    symbol: 'ett',
    name: 'EnergyTrade Token',
  },
  {
    id: 'energy-web-token',
    symbol: 'ewt',
    name: 'Energy Web',
  },
  {
    id: 'eng-crypto',
    symbol: 'eng',
    name: 'Eng Crypto',
  },
  {
    id: 'enigma',
    symbol: 'eng',
    name: 'Enigma',
  },
  {
    id: 'enigma-gaming',
    symbol: 'eng',
    name: 'Enigma Gaming',
  },
  {
    id: 'enjincoin',
    symbol: 'enj',
    name: 'Enjin Coin',
  },
  {
    id: 'enjinstarter',
    symbol: 'ejs',
    name: 'Enjinstarter',
  },
  {
    id: 'enno-cash',
    symbol: 'enno',
    name: 'ENNO Cash',
  },
  {
    id: 'eno',
    symbol: 'eno',
    name: 'ENO',
  },
  {
    id: 'enoch',
    symbol: 'enoch',
    name: 'Enoch',
  },
  {
    id: 'enosys',
    symbol: 'hln',
    name: 'Ēnosys',
  },
  {
    id: 'enosys-usdt',
    symbol: 'eusdt',
    name: 'Enosys USDT',
  },
  {
    id: 'enq-enecuum',
    symbol: 'enq',
    name: 'Enecuum',
  },
  {
    id: 'enreachdao',
    symbol: 'nrch',
    name: 'Enreach',
  },
  {
    id: 'enrex',
    symbol: 'enrx',
    name: 'Enrex',
  },
  {
    id: 'ensue',
    symbol: 'ensue',
    name: 'Ensue',
  },
  {
    id: 'entangle',
    symbol: 'ngl',
    name: 'Entangle',
  },
  {
    id: 'enter',
    symbol: 'enter',
    name: 'ENTER',
  },
  {
    id: 'enterbutton',
    symbol: 'entc',
    name: 'EnterButton',
  },
  {
    id: 'enterdao',
    symbol: 'entr',
    name: 'EnterDAO',
  },
  {
    id: 'entropy',
    symbol: 'ent',
    name: 'Entropy',
  },
  {
    id: 'envida',
    symbol: 'edat',
    name: 'EnviDa',
  },
  {
    id: 'envi-foundation',
    symbol: 'envi',
    name: 'Envi Coin',
  },
  {
    id: 'envision',
    symbol: 'vis',
    name: 'Envision',
  },
  {
    id: 'envoy-network',
    symbol: 'env',
    name: 'Envoy',
  },
  {
    id: 'eos',
    symbol: 'eos',
    name: 'EOS',
  },
  {
    id: 'eosdac',
    symbol: 'eosdac',
    name: 'eosDAC',
  },
  {
    id: 'eosforce',
    symbol: 'eosc',
    name: 'EOSForce',
  },
  {
    id: 'eos-pow-coin',
    symbol: 'pow',
    name: 'EOS PoW Coin',
  },
  {
    id: 'ephiat',
    symbol: 'ephiat',
    name: 'ePhiat',
  },
  {
    id: 'epic-cash',
    symbol: 'epic',
    name: 'Epic Cash',
  },
  {
    id: 'epic-league',
    symbol: 'epl',
    name: 'Epic League',
  },
  {
    id: 'epics-token',
    symbol: 'epct',
    name: 'Epics Token',
  },
  {
    id: 'epiko',
    symbol: 'epiko',
    name: 'Epiko',
  },
  {
    id: 'epik-prime',
    symbol: 'epik',
    name: 'Epik Prime',
  },
  {
    id: 'epik-protocol',
    symbol: 'aiepk',
    name: 'EpiK Protocol',
  },
  {
    id: 'epillo',
    symbol: 'epillo',
    name: 'Epillo',
  },
  {
    id: 'epoch-island',
    symbol: 'epoch',
    name: 'Epoch Island',
  },
  {
    id: 'eq9',
    symbol: 'eq9',
    name: 'Equals9',
  },
  {
    id: 'eqifi',
    symbol: 'eqx',
    name: 'EQIFi',
  },
  {
    id: 'equalizer',
    symbol: 'eqz',
    name: 'Equalizer',
  },
  {
    id: 'equalizer-base',
    symbol: 'scale',
    name: 'Equalizer (BASE)',
  },
  {
    id: 'equalizer-dex',
    symbol: 'equal',
    name: 'Equalizer DEX',
  },
  {
    id: 'equation',
    symbol: 'equ',
    name: 'Equation',
  },
  {
    id: 'equilibre',
    symbol: 'vara',
    name: 'Equilibre',
  },
  {
    id: 'equilibria-finance',
    symbol: 'eqb',
    name: 'Equilibria Finance',
  },
  {
    id: 'equilibria-finance-ependle',
    symbol: 'ependle',
    name: 'Equilibria Finance ePENDLE',
  },
  {
    id: 'equilibrium',
    symbol: 'eq',
    name: 'Equilibrium Games',
  },
  {
    id: 'equilibrium-eosdt',
    symbol: 'eosdt',
    name: 'Equilibrium EOSDT',
  },
  {
    id: 'equilibrium-exchange',
    symbol: 'edx',
    name: 'Equilibrium Exchange',
  },
  {
    id: 'equilibrium-token',
    symbol: 'eq',
    name: 'Equilibrium',
  },
  {
    id: 'equinox',
    symbol: 'enx',
    name: 'Equinox',
  },
  {
    id: 'equitypay',
    symbol: 'eqpay',
    name: 'EquityPay',
  },
  {
    id: 'era7',
    symbol: 'era',
    name: 'Era7',
  },
  {
    id: 'eraape',
    symbol: 'eape',
    name: 'EraApe',
  },
  {
    id: 'e-radix',
    symbol: 'exrd',
    name: 'e-Radix',
  },
  {
    id: 'era-name-service',
    symbol: 'era',
    name: 'Era Name Service',
  },
  {
    id: 'era-swap-token',
    symbol: 'es',
    name: 'Era Swap',
  },
  {
    id: 'ergo',
    symbol: 'erg',
    name: 'Ergo',
  },
  {
    id: 'ergopad',
    symbol: 'ergopad',
    name: 'Ergopad',
  },
  {
    id: 'eris-amplified-huahua',
    symbol: 'amphuahua',
    name: 'Eris Amplified HUAHUA',
  },
  {
    id: 'eris-amplified-juno',
    symbol: 'ampjuno',
    name: 'Eris Amplified JUNO',
  },
  {
    id: 'eris-amplified-luna',
    symbol: 'ampluna',
    name: 'Eris Amplified Luna',
  },
  {
    id: 'eris-amplified-mnta',
    symbol: 'ampmnta',
    name: 'Eris Amplified MNTA',
  },
  {
    id: 'eris-amplified-whale',
    symbol: 'ampwhale',
    name: 'Eris Amplified WHALE',
  },
  {
    id: 'eris-staked-kuji',
    symbol: 'ampkuji',
    name: 'Eris Staked Kuji',
  },
  {
    id: 'eris-staked-mnta',
    symbol: 'ampmnta',
    name: 'Eris Staked Mnta',
  },
  {
    id: 'ertha',
    symbol: 'ertha',
    name: 'Ertha',
  },
  {
    id: 'erth-point',
    symbol: 'erth',
    name: 'Erth Point',
  },
  {
    id: 'erugo-world-coin',
    symbol: 'ewc',
    name: 'Erugo World Coin',
  },
  {
    id: 'esco-coin',
    symbol: 'esco',
    name: 'Esco Coin',
  },
  {
    id: 'escoin-token',
    symbol: 'elg',
    name: 'Escoin',
  },
  {
    id: 'escrowed-illuvium-2',
    symbol: 'silv2',
    name: 'Escrowed Illuvium 2',
  },
  {
    id: 'escrowed-lbr',
    symbol: 'eslbr',
    name: 'Escrowed LBR',
  },
  {
    id: 'esg',
    symbol: 'esg',
    name: 'ESG',
  },
  {
    id: 'esg-chain',
    symbol: 'esgc',
    name: 'ESG Chain',
  },
  {
    id: 'eska',
    symbol: 'esk',
    name: 'Eska',
  },
  {
    id: 'eskisehir-fan-token',
    symbol: 'eses',
    name: 'Eskişehir Fan Token',
  },
  {
    id: 'espento',
    symbol: 'spent',
    name: 'Espento',
  },
  {
    id: 'espento-usd',
    symbol: 'eusd',
    name: 'Espento USD',
  },
  {
    id: 'espl-arena',
    symbol: 'arena',
    name: 'ESPL Arena',
  },
  {
    id: 'esport',
    symbol: 'espt',
    name: 'Esport',
  },
  {
    id: 'esporte-clube-bahia-fan-token',
    symbol: 'bahia',
    name: 'Esporte Clube Bahia Fan Token',
  },
  {
    id: 'esportspro',
    symbol: 'espro',
    name: 'EsportsPro',
  },
  {
    id: 'espresso-bot',
    symbol: 'espr',
    name: 'Espresso Bot',
  },
  {
    id: 'essentia',
    symbol: 'ess',
    name: 'Essentia',
  },
  {
    id: 'estar-games',
    symbol: 'estar',
    name: 'ESTAR.GAMES',
  },
  {
    id: 'etcpow',
    symbol: 'etcpow',
    name: 'ETCPOW',
  },
  {
    id: 'etermon',
    symbol: 'etm',
    name: 'Etermon',
  },
  {
    id: 'eternal-ai',
    symbol: 'mind',
    name: 'Eternal AI',
  },
  {
    id: 'eternal-finance',
    symbol: 'etern',
    name: 'Eternal Finance',
  },
  {
    id: 'eternalflow',
    symbol: 'eft',
    name: 'EternalFlow',
  },
  {
    id: 'eternity-glory-token',
    symbol: '$glory',
    name: 'Eternity GLORY Token',
  },
  {
    id: 'etf-rocks',
    symbol: 'etf',
    name: 'ETF Rocks',
  },
  {
    id: 'etfsol2024',
    symbol: 'etf',
    name: 'ETFSOL2024',
  },
  {
    id: 'etf-the-token',
    symbol: 'etf',
    name: 'ETF The Token',
  },
  {
    id: 'etgm-ordinals',
    symbol: 'etgm',
    name: 'ETGM (Ordinals)',
  },
  {
    id: 'eth-2-0',
    symbol: 'eth 2.0',
    name: 'ETH 2.0',
  },
  {
    id: 'eth2-staking-by-poolx',
    symbol: 'eth2',
    name: 'Eth 2.0 Staking by Pool-X',
  },
  {
    id: 'eth-2x-flexible-leverage-index',
    symbol: 'eth2x-fli',
    name: 'Index Coop - ETH 2x Flexible Leverage Index',
  },
  {
    id: 'eth3s',
    symbol: 'eth3s',
    name: 'ETH3S',
  },
  {
    id: 'etha-lend',
    symbol: 'etha',
    name: 'ETHA Lend',
  },
  {
    id: 'ethane',
    symbol: 'c2h6',
    name: 'Ethane',
  },
  {
    id: 'ethax',
    symbol: 'ethax',
    name: 'ETHAX',
  },
  {
    id: 'eth-coin-mori-finance',
    symbol: 'ethc',
    name: 'ETH Coin',
  },
  {
    id: 'ethdown',
    symbol: 'ethdown',
    name: 'ETHDOWN',
  },
  {
    id: 'ethena-staked-usde',
    symbol: 'susde',
    name: 'Ethena Staked USDe',
  },
  {
    id: 'ethena-usde',
    symbol: 'usde',
    name: 'Ethena USDe',
  },
  {
    id: 'ether-1',
    symbol: 'etho',
    name: 'Etho Protocol',
  },
  {
    id: 'etheraid',
    symbol: 'etra',
    name: 'Etheraid',
  },
  {
    id: 'etherdoge',
    symbol: 'edoge',
    name: 'EtherDoge',
  },
  {
    id: 'ethereans',
    symbol: 'os',
    name: 'Ethereans',
  },
  {
    id: 'etherempires',
    symbol: 'ete',
    name: 'Etherempires',
  },
  {
    id: 'ethereum',
    symbol: 'eth',
    name: 'Ethereum',
  },
  {
    id: 'ethereum-classic',
    symbol: 'etc',
    name: 'Ethereum Classic',
  },
  {
    id: 'ethereum-express',
    symbol: 'ete',
    name: 'Ethereum Express',
  },
  {
    id: 'ethereumfair',
    symbol: 'ethf',
    name: 'EthereumFair',
  },
  {
    id: 'ethereum-gold-2',
    symbol: 'ethg',
    name: 'Ethereum Gold',
  },
  {
    id: 'ethereum-gold-mining-comp',
    symbol: 'egmc',
    name: 'Ethereum Gold Mining Comp',
  },
  {
    id: 'ethereum-inu',
    symbol: 'ethinu',
    name: 'Ethereum Inu',
  },
  {
    id: 'ethereummax',
    symbol: 'emax',
    name: 'EthereumMax',
  },
  {
    id: 'ethereum-message-service',
    symbol: 'ems',
    name: 'Ethereum Message Service',
  },
  {
    id: 'ethereum-meta',
    symbol: 'ethm',
    name: 'Ethereum Meta',
  },
  {
    id: 'ethereum-name-service',
    symbol: 'ens',
    name: 'Ethereum Name Service',
  },
  {
    id: 'ethereum-overnight',
    symbol: 'eth+',
    name: 'Ethereum+ (Overnight)',
  },
  {
    id: 'ethereum-pow-iou',
    symbol: 'ethw',
    name: 'EthereumPoW',
  },
  {
    id: 'ethereum-push-notification-service',
    symbol: 'push',
    name: 'Push Protocol',
  },
  {
    id: 'ethereum-reserve-dollar-usde',
    symbol: 'usde',
    name: 'Ethereum Reserve Dollar USDE',
  },
  {
    id: 'ethereum-volatility-index-token',
    symbol: 'ethv',
    name: 'Ethereum Volatility Index Token',
  },
  {
    id: 'ethereum-wormhole',
    symbol: 'eth',
    name: 'Ethereum (Wormhole)',
  },
  {
    id: 'ethereumx',
    symbol: 'etx',
    name: 'EthereumX',
  },
  {
    id: 'ether-fi-staked-eth',
    symbol: 'eeth',
    name: 'ether.fi Staked ETH',
  },
  {
    id: 'ether-futures',
    symbol: 'ethf',
    name: 'Ether Futures',
  },
  {
    id: 'ethergem',
    symbol: 'egem',
    name: 'EtherGem',
  },
  {
    id: 'etherisc',
    symbol: 'dip',
    name: 'Etherisc DIP',
  },
  {
    id: 'etherland',
    symbol: 'eland',
    name: 'Etherland',
  },
  {
    id: 'etherlite-2',
    symbol: 'etl',
    name: 'EtherLite',
  },
  {
    id: 'ethermon',
    symbol: 'emon',
    name: 'Ethermon',
  },
  {
    id: 'ethernal-finance',
    symbol: 'ethfin',
    name: 'Ethernal Finance',
  },
  {
    id: 'ethernexus',
    symbol: 'enxs',
    name: 'EtherNexus',
  },
  {
    id: 'ethernity-chain',
    symbol: 'ern',
    name: 'Ethernity Chain',
  },
  {
    id: 'ethernity-cloud',
    symbol: 'ecld',
    name: 'Ethernity Cloud',
  },
  {
    id: 'etherparty',
    symbol: 'fuel',
    name: 'Etherparty',
  },
  {
    id: 'etherpets',
    symbol: 'epets',
    name: 'Etherpets',
  },
  {
    id: 'etherpos',
    symbol: 'etpos',
    name: 'EtherPoS',
  },
  {
    id: 'ether-wars',
    symbol: 'war',
    name: 'Ether Wars',
  },
  {
    id: 'ethetf',
    symbol: 'ethetf',
    name: 'ETHETF',
  },
  {
    id: 'ethfan-burn',
    symbol: '$efb',
    name: 'ETHFan Burn',
  },
  {
    id: 'eth-fan-token',
    symbol: 'eft',
    name: 'ETH Fan Token Ecosystem',
  },
  {
    id: 'ethforestai',
    symbol: 'ethfai',
    name: 'ETHforestAI',
  },
  {
    id: 'ethichub',
    symbol: 'ethix',
    name: 'Ethix',
  },
  {
    id: 'ethlas',
    symbol: 'els',
    name: 'Ethlas',
  },
  {
    id: 'ethlend',
    symbol: 'lend',
    name: 'Aave [OLD]',
  },
  {
    id: 'ethlinq-tech',
    symbol: 'ethlinq',
    name: 'Ethlinq Tech',
  },
  {
    id: 'ethos',
    symbol: 'vgx',
    name: 'Voyager VGX',
  },
  {
    id: 'ethos-2',
    symbol: '3th',
    name: 'Ethos',
  },
  {
    id: 'ethos-reserve-note',
    symbol: 'ern',
    name: 'Ethos Reserve Note',
  },
  {
    id: 'ethpad',
    symbol: 'ethpad',
    name: 'ETHPad',
  },
  {
    id: 'ethrix',
    symbol: 'etx',
    name: 'Ethrix',
  },
  {
    id: 'ethscriptions',
    symbol: 'eths',
    name: 'Ethscriptions',
  },
  {
    id: 'eth-stable-mori-finance',
    symbol: 'eths',
    name: 'ETH Stable',
  },
  {
    id: 'ethtez',
    symbol: 'ethtz',
    name: 'ETHtez',
  },
  {
    id: 'ethup',
    symbol: 'ethup',
    name: 'ETHUP',
  },
  {
    id: 'etica',
    symbol: 'eti',
    name: 'Etica',
  },
  {
    id: 'etuktuk',
    symbol: 'tuk',
    name: 'eTukTuk',
  },
  {
    id: 'etwinfinity',
    symbol: 'etw',
    name: 'ETWInfinity',
  },
  {
    id: 'euler',
    symbol: 'eul',
    name: 'Euler',
  },
  {
    id: 'euno',
    symbol: 'euno',
    name: 'EUNO',
  },
  {
    id: 'eurc-wormhole',
    symbol: 'eurc',
    name: 'EURC (Wormhole)',
  },
  {
    id: 'eurk',
    symbol: 'eurk',
    name: 'EURK',
  },
  {
    id: 'euro3',
    symbol: 'euro3',
    name: 'EURO3',
  },
  {
    id: 'euro-coin',
    symbol: 'eurc',
    name: 'EURC',
  },
  {
    id: 'eurocoinpay',
    symbol: 'ecte',
    name: 'EurocoinToken',
  },
  {
    id: 'euro-coinvertible',
    symbol: 'eur-c',
    name: 'Euro Coinvertible',
  },
  {
    id: 'euroe-stablecoin',
    symbol: 'euroe',
    name: 'EUROe Stablecoin',
  },
  {
    id: 'eusd-27a558b0-8b5b-4225-a614-63539da936f4',
    symbol: 'eusd',
    name: 'eUSD (OLD)',
  },
  {
    id: 'eusd-new',
    symbol: 'eusd',
    name: 'eUSD',
  },
  {
    id: 'evadore',
    symbol: 'eva',
    name: 'Evadore',
  },
  {
    id: 'evai-2',
    symbol: 'ev',
    name: 'Evai',
  },
  {
    id: 'evanesco-network',
    symbol: 'eva',
    name: 'Evanesco Network',
  },
  {
    id: 'evany',
    symbol: 'evy',
    name: 'EVANY',
  },
  {
    id: 'eve-ai',
    symbol: 'eveai',
    name: 'Eve AI',
  },
  {
    id: 'evedo',
    symbol: 'eved',
    name: 'Evedo',
  },
  {
    id: 'eve-exchange',
    symbol: 'eve',
    name: 'EVE',
  },
  {
    id: 'eventsx',
    symbol: 'evex',
    name: 'EventsX',
  },
  {
    id: 'everdome',
    symbol: 'dome',
    name: 'Everdome',
  },
  {
    id: 'evereth',
    symbol: 'evereth',
    name: 'EverETH Reflect',
  },
  {
    id: 'evereth-2',
    symbol: 'eeth',
    name: 'EverETH',
  },
  {
    id: 'everex',
    symbol: 'evx',
    name: 'Everex',
  },
  {
    id: 'evergrowcoin',
    symbol: 'egc',
    name: 'EverGrow Coin',
  },
  {
    id: 'everid',
    symbol: 'id',
    name: 'Everest',
  },
  {
    id: 'everipedia',
    symbol: 'iq',
    name: 'IQ',
  },
  {
    id: 'evermoon-erc',
    symbol: 'evermoon',
    name: 'EverMoon ERC',
  },
  {
    id: 'everreflect',
    symbol: 'evrf',
    name: 'EverReflect',
  },
  {
    id: 'everrise',
    symbol: 'rise',
    name: 'EverRise',
  },
  {
    id: 'everscale',
    symbol: 'ever',
    name: 'Everscale',
  },
  {
    id: 'everstart',
    symbol: 'start',
    name: 'EverStart',
  },
  {
    id: 'everton-fan-token',
    symbol: 'efc',
    name: 'Everton Fan Token',
  },
  {
    id: 'everybody',
    symbol: 'hold',
    name: 'Everybody',
  },
  {
    id: 'everycoin',
    symbol: 'evy',
    name: 'EveryCoin',
  },
  {
    id: 'every-game',
    symbol: 'egame',
    name: 'Every Game',
  },
  {
    id: 'evil-pepe',
    symbol: 'evilpepe',
    name: 'Evil Pepe',
  },
  {
    id: 'evmos',
    symbol: 'evmos',
    name: 'Evmos',
  },
  {
    id: 'evmos-domains',
    symbol: 'evd',
    name: 'Evmos Domains',
  },
  {
    id: 'evolva',
    symbol: 'eva',
    name: 'Evolva',
  },
  {
    id: 'evolve',
    symbol: '$evol',
    name: 'Evolve',
  },
  {
    id: 'evoverses',
    symbol: 'evo',
    name: 'EvoVerses',
  },
  {
    id: 'evrynet',
    symbol: 'evry',
    name: 'Evrynet',
  },
  {
    id: 'evulus',
    symbol: 'evu',
    name: 'Evulus',
  },
  {
    id: 'exa',
    symbol: 'exa',
    name: 'Exactly Token',
  },
  {
    id: 'exactly-op',
    symbol: 'exaop',
    name: 'Exactly Optimism',
  },
  {
    id: 'exactly-usdc',
    symbol: 'exausdc',
    name: 'Exactly USD Coin',
  },
  {
    id: 'exactly-wbtc',
    symbol: 'exawbtc',
    name: 'Exactly WBTC',
  },
  {
    id: 'exactly-weth',
    symbol: 'exaweth',
    name: 'Exactly Wrapped Ether',
  },
  {
    id: 'exactly-wsteth',
    symbol: 'exawsteth',
    name: 'Exactly Wrapped stETH',
  },
  {
    id: 'exatech',
    symbol: 'ext',
    name: 'Exatech',
  },
  {
    id: 'excalibur',
    symbol: 'exc',
    name: 'Excalibur',
  },
  {
    id: 'excelon',
    symbol: 'xlon',
    name: 'Excelon',
  },
  {
    id: 'exchangecoin',
    symbol: 'excc',
    name: 'ExchangeCoin',
  },
  {
    id: 'exchange-genesis-ethlas-medium',
    symbol: 'xgem',
    name: 'Exchange Genesis Ethlas Medium',
  },
  {
    id: 'exciting-japan-coin',
    symbol: 'xjp',
    name: 'eXciting Japan Coin',
  },
  {
    id: 'exeedme',
    symbol: 'xed',
    name: 'Exeedme',
  },
  {
    id: 'exmo-coin',
    symbol: 'exm',
    name: 'EXMO Coin',
  },
  {
    id: 'exnetwork-token',
    symbol: 'exnt',
    name: 'ExNetwork',
  },
  {
    id: 'exohood',
    symbol: 'exo',
    name: 'Exohood',
  },
  {
    id: 'exorde',
    symbol: 'exd',
    name: 'Exorde',
  },
  {
    id: 'exosama-network',
    symbol: 'sama',
    name: 'Moonsama',
  },
  {
    id: 'expanse',
    symbol: 'exp',
    name: 'Expanse',
  },
  {
    id: 'experience-chain',
    symbol: 'xpc',
    name: 'eXPerience Chain',
  },
  {
    id: 'experty-wisdom-token',
    symbol: 'wis',
    name: 'Experty Wisdom',
  },
  {
    id: 'exponential-capital-2',
    symbol: 'expo',
    name: 'Exponential Capital',
  },
  {
    id: 'export-mortos-platform',
    symbol: 'emp',
    name: 'Export Motors Platform',
  },
  {
    id: 'extradna',
    symbol: 'xdna',
    name: 'extraDNA',
  },
  {
    id: 'extra-finance',
    symbol: 'extra',
    name: 'Extra Finance',
  },
  {
    id: 'extreme',
    symbol: 'xtrm',
    name: 'Extreme',
  },
  {
    id: 'extropic-ai',
    symbol: 'extropic',
    name: 'Extropic AI',
  },
  {
    id: 'exynos-protocol',
    symbol: 'xyn',
    name: 'Exynos Protocol',
  },
  {
    id: 'eyebot',
    symbol: 'eyebot',
    name: 'Eyebot',
  },
  {
    id: 'eye-earn',
    symbol: 'eye',
    name: 'EYE-EARN',
  },
  {
    id: 'eye-labs',
    symbol: 'eye',
    name: 'Eye Labs',
  },
  {
    id: 'eyes-protocol',
    symbol: 'eyes',
    name: 'EYES Protocol',
  },
  {
    id: 'eyeverse',
    symbol: 'eye',
    name: 'Eyeverse',
  },
  {
    id: 'ezillion',
    symbol: 'ezi',
    name: 'Ezillion',
  },
  {
    id: 'ezkalibur',
    symbol: 'sword',
    name: 'eZKalibur',
  },
  {
    id: 'ezzy-game',
    symbol: 'ezy',
    name: 'EZZY Game',
  },
  {
    id: 'ezzy-game-2',
    symbol: 'gezy',
    name: 'EZZY Game',
  },
  {
    id: 'fable-of-the-dragon',
    symbol: 'tyrant',
    name: 'Fable Of The Dragon',
  },
  {
    id: 'fabric',
    symbol: 'fab',
    name: 'Fabric',
  },
  {
    id: 'fabwelt',
    symbol: 'welt',
    name: 'Fabwelt',
  },
  {
    id: 'facebook-tokenized-stock-defichain',
    symbol: 'dfb',
    name: 'Facebook Tokenized Stock Defichain',
  },
  {
    id: 'facedao',
    symbol: 'face',
    name: 'FaceDAO',
  },
  {
    id: 'facet',
    symbol: 'facet',
    name: 'FACET',
  },
  {
    id: 'fact0rn',
    symbol: 'fact',
    name: 'Fact0rn',
  },
  {
    id: 'factor',
    symbol: 'fctr',
    name: 'FactorDAO',
  },
  {
    id: 'facts',
    symbol: 'bkc',
    name: 'FACTS',
  },
  {
    id: 'fair-berc20',
    symbol: 'berc',
    name: 'Fair BERC20',
  },
  {
    id: 'fairerc20',
    symbol: 'ferc',
    name: 'FairERC20',
  },
  {
    id: 'fairlight',
    symbol: 'fcdp',
    name: 'FairLight',
  },
  {
    id: 'faith-tribe',
    symbol: 'ftrb',
    name: 'Faith Tribe',
  },
  {
    id: 'fake-market-cap',
    symbol: 'cap',
    name: 'Fake Market Cap',
  },
  {
    id: 'falcon-nine',
    symbol: 'f9',
    name: 'Falcon Nine',
  },
  {
    id: 'falcon-swaps',
    symbol: 'falcons',
    name: 'FALCONS',
  },
  {
    id: 'falcon-token',
    symbol: 'fnt',
    name: 'Falcon Project',
  },
  {
    id: 'fame-ai',
    symbol: '$fmc',
    name: 'FAME AI',
  },
  {
    id: 'fame-mma',
    symbol: 'fame',
    name: 'Fame MMA',
  },
  {
    id: 'fame-reward-plus',
    symbol: 'frp',
    name: 'Fame Reward Plus',
  },
  {
    id: 'family-guy',
    symbol: 'guy',
    name: 'Family Guy',
  },
  {
    id: 'family-over-everything',
    symbol: 'foe',
    name: 'Family Over Everything',
  },
  {
    id: 'famous-fox-federation',
    symbol: 'foxy',
    name: 'Famous Fox Federation',
  },
  {
    id: 'famous-fox-federation-floor-index',
    symbol: 'foxes',
    name: 'Famous Fox Federation Floor Index',
  },
  {
    id: 'fanadise',
    symbol: 'fan',
    name: 'Fanadise',
  },
  {
    id: 'fanbase',
    symbol: 'wfnb',
    name: 'Fanbase',
  },
  {
    id: 'fanc',
    symbol: 'fanc',
    name: 'fanC',
  },
  {
    id: 'fancy-games',
    symbol: 'fnc',
    name: 'Fancy Games',
  },
  {
    id: 'fanfury',
    symbol: 'fury',
    name: 'FURY',
  },
  {
    id: 'fang-token',
    symbol: 'fang',
    name: 'FANG',
  },
  {
    id: 'fanstime',
    symbol: 'fti',
    name: 'FansTime',
  },
  {
    id: 'fantacoin',
    symbol: 'ftc',
    name: 'FANTACOIN',
  },
  {
    id: 'fantaverse',
    symbol: 'ut',
    name: 'Fantaverse',
  },
  {
    id: 'fantohm',
    symbol: 'fhm',
    name: 'Fantohm',
  },
  {
    id: 'fantom',
    symbol: 'ftm',
    name: 'Fantom',
  },
  {
    id: 'fantom-doge',
    symbol: 'rip',
    name: 'Fantom Doge',
  },
  {
    id: 'fantomgo',
    symbol: 'ftg',
    name: 'OnGo',
  },
  {
    id: 'fantom-libero-financial',
    symbol: 'flibero',
    name: 'Fantom Libero Financial',
  },
  {
    id: 'fantom-maker',
    symbol: 'fame',
    name: 'Fantom Maker',
  },
  {
    id: 'fantom-oasis',
    symbol: 'ftmo',
    name: 'Fantom Oasis',
  },
  {
    id: 'fantomsonicinu',
    symbol: 'fsonic',
    name: 'Fantomsonicinu',
  },
  {
    id: 'fantomstarter',
    symbol: 'fs',
    name: 'FantomStarter',
  },
  {
    id: 'fantom-usd',
    symbol: 'fusd',
    name: 'Fantom USD',
  },
  {
    id: 'fantom-velocimeter',
    symbol: 'fvm',
    name: 'Fantom Velocimeter',
  },
  {
    id: 'fanzee-token',
    symbol: 'fnz',
    name: 'Fanzee Token',
  },
  {
    id: 'faraland',
    symbol: 'fara',
    name: 'FaraLand',
  },
  {
    id: 'farcana',
    symbol: 'far',
    name: 'FARCANA',
  },
  {
    id: 'farmbot',
    symbol: 'farm',
    name: 'FarmBot',
  },
  {
    id: 'farmerdoge',
    symbol: 'crop',
    name: 'FarmerDoge',
  },
  {
    id: 'farmer-friends',
    symbol: 'frens',
    name: 'Farmer Friends',
  },
  {
    id: 'farmers-only',
    symbol: 'fox',
    name: 'FoxSwap',
  },
  {
    id: 'farmers-world-wood',
    symbol: 'fww',
    name: 'Farmers World Wood',
  },
  {
    id: 'farmland-protocol',
    symbol: 'far',
    name: 'Farmland Protocol',
  },
  {
    id: 'farmsent',
    symbol: 'farms',
    name: 'Farmsent',
  },
  {
    id: 'farmstrong-seed',
    symbol: 'seed',
    name: 'Farmstrong Seed',
  },
  {
    id: 'fart-coin',
    symbol: 'frtc',
    name: 'FART COIN',
  },
  {
    id: 'fastswap-bsc-2',
    symbol: 'fast',
    name: 'Fastswap (BSC)',
  },
  {
    id: 'fasttoken',
    symbol: 'ftn',
    name: 'Fasttoken',
  },
  {
    id: 'fat-cat',
    symbol: 'fatcat',
    name: 'FAT CAT',
  },
  {
    id: 'fathom',
    symbol: '$fathom',
    name: 'Fathom',
  },
  {
    id: 'fathom-dollar',
    symbol: 'fxd',
    name: 'Fathom Dollar',
  },
  {
    id: 'fathom-protocol',
    symbol: 'fthm',
    name: 'Fathom Protocol',
  },
  {
    id: 'fatih-karagumruk-sk-fan-token',
    symbol: 'fksk',
    name: 'Fatih Karagümrük SK Fan Token',
  },
  {
    id: 'favor',
    symbol: 'favr',
    name: 'Favor',
  },
  {
    id: 'fayda-games',
    symbol: 'fayd',
    name: 'Fayda Games',
  },
  {
    id: 'fbomb',
    symbol: 'bomb',
    name: 'Fantom Bomb',
  },
  {
    id: 'fc-barcelona-fan-token',
    symbol: 'bar',
    name: 'FC Barcelona Fan Token',
  },
  {
    id: 'fc-porto',
    symbol: 'porto',
    name: 'FC Porto',
  },
  {
    id: 'fcr-coin',
    symbol: 'fcr',
    name: 'FCR Coin',
  },
  {
    id: 'fc-sion-fan-token',
    symbol: 'sion',
    name: 'FC Sion Fan Token',
  },
  {
    id: 'fcuk',
    symbol: 'fcuk',
    name: 'FCUK',
  },
  {
    id: 'fear',
    symbol: 'fear',
    name: 'FEAR',
  },
  {
    id: 'feathercoin',
    symbol: 'ftc',
    name: 'Feathercoin',
  },
  {
    id: 'federal-ai',
    symbol: 'fedai',
    name: 'Federal AI',
  },
  {
    id: 'federal-gold-coin',
    symbol: 'fgc',
    name: 'Federal Gold Coin',
  },
  {
    id: 'fedoracoin',
    symbol: 'tips',
    name: 'Fedoracoin',
  },
  {
    id: 'feeder-finance',
    symbol: 'feed',
    name: 'Feeder Finance',
  },
  {
    id: 'feed-on-acf-game',
    symbol: 'feed',
    name: 'FEED on ACF Game',
  },
  {
    id: 'feels-good-man',
    symbol: 'good',
    name: 'Feels Good Man',
  },
  {
    id: 'feg-bsc',
    symbol: 'feg',
    name: 'FEG BSC',
  },
  {
    id: 'feg-token',
    symbol: 'feg',
    name: 'FEG (OLD)',
  },
  {
    id: 'feg-token-2',
    symbol: 'feg',
    name: 'FEG ETH',
  },
  {
    id: 'feg-token-bsc',
    symbol: 'feg',
    name: 'FEG BSC (OLD)',
  },
  {
    id: 'feichang-niu',
    symbol: 'fcn',
    name: 'Feichang Niu',
  },
  {
    id: 'feisty-doge-nft',
    symbol: 'nfd',
    name: 'Feisty Doge NFT',
  },
  {
    id: 'fei-usd',
    symbol: 'fei',
    name: 'Fei USD',
  },
  {
    id: 'felix',
    symbol: 'flx',
    name: 'Felix',
  },
  {
    id: 'felix-2',
    symbol: 'felix',
    name: 'FELIX',
  },
  {
    id: 'fellaz',
    symbol: 'flz',
    name: 'Fellaz',
  },
  {
    id: 'fenerbahce-token',
    symbol: 'fb',
    name: 'Fenerbahçe',
  },
  {
    id: 'fenglvziv2',
    symbol: 'fenglvziv2',
    name: 'FengLvZiV2',
  },
  {
    id: 'fentanyl-dragon',
    symbol: 'fentanyl',
    name: 'Fentanyl Dragon',
  },
  {
    id: 'ferma',
    symbol: 'ferma',
    name: 'Ferma',
  },
  {
    id: 'ferret-ai',
    symbol: 'ferret',
    name: 'Ferret AI',
  },
  {
    id: 'ferro',
    symbol: 'fer',
    name: 'Ferro',
  },
  {
    id: 'ferrum-network',
    symbol: 'frm',
    name: 'Ferrum Network',
  },
  {
    id: 'ferscoin',
    symbol: 'fr',
    name: 'ferscoin',
  },
  {
    id: 'fetch-ai',
    symbol: 'fet',
    name: 'Fetch.ai',
  },
  {
    id: 'feyorra',
    symbol: 'fey',
    name: 'Feyorra',
  },
  {
    id: 'fgdswap',
    symbol: 'fgds',
    name: 'FGDSwap',
  },
  {
    id: 'fiat24-chf',
    symbol: 'chf24',
    name: 'Fiat24 CHF',
  },
  {
    id: 'fiat24-eur',
    symbol: 'eur24',
    name: 'Fiat24 EUR',
  },
  {
    id: 'fiat24-usd',
    symbol: 'usd24',
    name: 'Fiat24 USD',
  },
  {
    id: 'fibonacci',
    symbol: 'fibo',
    name: 'Fibonacci',
  },
  {
    id: 'fibos',
    symbol: 'fo',
    name: 'FIBOS',
  },
  {
    id: 'fibo-token',
    symbol: 'fibo',
    name: 'FibSwap DEX',
  },
  {
    id: 'fidance',
    symbol: 'fdc',
    name: 'Fidance',
  },
  {
    id: 'fidelis',
    symbol: 'fdls',
    name: 'FIDELIS',
  },
  {
    id: 'fideum',
    symbol: 'fi',
    name: 'Fideum',
  },
  {
    id: 'fidira',
    symbol: 'fid',
    name: 'Fidira',
  },
  {
    id: 'fido',
    symbol: 'fido',
    name: 'Fido',
  },
  {
    id: 'fidu',
    symbol: 'fidu',
    name: 'Fidu',
  },
  {
    id: 'fief',
    symbol: 'fief',
    name: 'Fief',
  },
  {
    id: 'fiero',
    symbol: 'fiero',
    name: 'Fiero',
  },
  {
    id: 'fifi',
    symbol: 'fifi',
    name: 'FIFI',
  },
  {
    id: 'fight-of-the-ages',
    symbol: 'fota',
    name: 'Fight Of The Ages',
  },
  {
    id: 'fight-win-ai',
    symbol: 'fwin-ai',
    name: 'Fight Win AI',
  },
  {
    id: 'figments-club',
    symbol: 'figma',
    name: 'Figments Club',
  },
  {
    id: 'figure-dao',
    symbol: 'fdao',
    name: 'Figure DAO',
  },
  {
    id: 'filda',
    symbol: 'filda',
    name: 'Filda',
  },
  {
    id: 'filecoin',
    symbol: 'fil',
    name: 'Filecoin',
  },
  {
    id: 'filecoin-standard-full-hashrate',
    symbol: 'sfil',
    name: 'Filecoin Standard Full Hashrate',
  },
  {
    id: 'fileshare-platform',
    symbol: 'fsc',
    name: 'Fileshare Platform',
  },
  {
    id: 'filestar',
    symbol: 'star',
    name: 'FileStar',
  },
  {
    id: 'filipcoin',
    symbol: 'fcp',
    name: 'Filipcoin',
  },
  {
    id: 'filmcredits',
    symbol: 'film',
    name: 'FILMCredits',
  },
  {
    id: 'fimarkcoin-com',
    symbol: 'fmc',
    name: 'Fimarkcoin.com',
  },
  {
    id: 'fina',
    symbol: 'fina',
    name: 'Fina.cash',
  },
  {
    id: 'final-frontier',
    symbol: 'frnt',
    name: 'Final Frontier',
  },
  {
    id: 'finance-ai',
    symbol: 'financeai',
    name: 'Finance AI',
  },
  {
    id: 'finance-blocks',
    symbol: 'fbx',
    name: 'Finance Blocks',
  },
  {
    id: 'finance-vote',
    symbol: 'fvt',
    name: 'Finance Vote',
  },
  {
    id: 'financie-token',
    symbol: 'fnct',
    name: 'Financie Token',
  },
  {
    id: 'finblox',
    symbol: 'fbx',
    name: 'Finblox',
  },
  {
    id: 'finceptor-token',
    symbol: 'finc',
    name: 'Finceptor',
  },
  {
    id: 'find-check',
    symbol: 'fck',
    name: 'Find & Check',
  },
  {
    id: 'findora',
    symbol: 'fra',
    name: 'Findora',
  },
  {
    id: 'fine',
    symbol: 'fine',
    name: 'FINE',
  },
  {
    id: 'fine-2-0',
    symbol: 'fine2.0',
    name: 'FINE 2.0',
  },
  {
    id: 'finebot',
    symbol: 'fbot',
    name: 'FineBot',
  },
  {
    id: 'fine-bsc',
    symbol: 'fine',
    name: 'FINE (BSC)',
  },
  {
    id: 'finedog',
    symbol: 'finedog',
    name: 'FineDog',
  },
  {
    id: 'finexbox-token',
    symbol: 'fnb',
    name: 'Finexbox',
  },
  {
    id: 'fingerprints',
    symbol: 'prints',
    name: 'FingerprintsDAO',
  },
  {
    id: 'fink',
    symbol: 'fink',
    name: 'FINK',
  },
  {
    id: 'finminity',
    symbol: 'fmt',
    name: 'Finminity',
  },
  {
    id: 'fins-token',
    symbol: 'fins',
    name: 'Fins',
  },
  {
    id: 'fintoken',
    symbol: 'ftc',
    name: 'FinToken',
  },
  {
    id: 'fintradao',
    symbol: 'fdc',
    name: 'FintraDao',
  },
  {
    id: 'fintrux',
    symbol: 'ftx',
    name: 'FintruX',
  },
  {
    id: 'finx',
    symbol: 'finx',
    name: 'FINX',
  },
  {
    id: 'finxflo',
    symbol: 'fxf',
    name: 'FINXFLO',
  },
  {
    id: 'fio-protocol',
    symbol: 'fio',
    name: 'FIO Protocol',
  },
  {
    id: 'fira',
    symbol: 'fira',
    name: 'FIRA',
  },
  {
    id: 'fira-cronos',
    symbol: 'fira',
    name: 'Defira (Cronos)',
  },
  {
    id: 'fireants',
    symbol: 'ants',
    name: 'FireAnts',
  },
  {
    id: 'fireball-2',
    symbol: 'fire',
    name: 'FireBall',
  },
  {
    id: 'firebot',
    symbol: 'fbx',
    name: 'FireBot',
  },
  {
    id: 'firebot-2',
    symbol: 'firebot',
    name: 'FireBot',
  },
  {
    id: 'firepot-finance',
    symbol: 'hott',
    name: 'Firepot Finance',
  },
  {
    id: 'fire-protocol',
    symbol: 'fire',
    name: 'Fire Protocol',
  },
  {
    id: 'firestarter',
    symbol: 'flame',
    name: 'FireStarter',
  },
  {
    id: 'firmachain',
    symbol: 'fct',
    name: 'Firmachain',
  },
  {
    id: 'first-digital-usd',
    symbol: 'fdusd',
    name: 'First Digital USD',
  },
  {
    id: 'first-grok-ai',
    symbol: 'grok',
    name: 'First GROK AI',
  },
  {
    id: 'firsthare',
    symbol: 'firsthare',
    name: 'FirstHare',
  },
  {
    id: 'firulais-wallet-token',
    symbol: 'fiwt',
    name: 'Firulais Wallet',
  },
  {
    id: 'fisco',
    symbol: 'fscc',
    name: 'FISCO Coin',
  },
  {
    id: 'fish-crypto',
    symbol: 'fico',
    name: 'Fish Crypto',
  },
  {
    id: 'fishing-tuna',
    symbol: 'tuna',
    name: 'Fishing Tuna',
  },
  {
    id: 'fishy',
    symbol: '$fishy',
    name: '$FISHY',
  },
  {
    id: 'fistbump',
    symbol: 'fist',
    name: 'Fistbump',
  },
  {
    id: 'fitmint',
    symbol: 'fitt',
    name: 'Fitmint',
  },
  {
    id: 'fitzen',
    symbol: 'fitz',
    name: 'FitZen',
  },
  {
    id: 'fiwb-doginals',
    symbol: 'fiwb',
    name: 'FIWB (DRC-20)',
  },
  {
    id: 'fix00',
    symbol: 'fix00',
    name: 'Fix00',
  },
  {
    id: 'fkuinu',
    symbol: 'fkuinu',
    name: 'FKUINU',
  },
  {
    id: 'flack-exchange',
    symbol: 'flack',
    name: 'Flack Exchange',
  },
  {
    id: 'flag-coin',
    symbol: 'flag',
    name: 'Flag Coin',
  },
  {
    id: 'flair-dex',
    symbol: 'fldx',
    name: 'Flair Dex',
  },
  {
    id: 'flame-2',
    symbol: 'flame',
    name: 'Flame',
  },
  {
    id: 'flamengo-fan-token',
    symbol: 'mengo',
    name: 'Flamengo Fan Token',
  },
  {
    id: 'flame-protocol',
    symbol: 'flame',
    name: 'Flame Protocol',
  },
  {
    id: 'flamingghost',
    symbol: 'fghst',
    name: 'FlamingGhost',
  },
  {
    id: 'flamingo-finance',
    symbol: 'flm',
    name: 'Flamingo Finance',
  },
  {
    id: 'flappybee',
    symbol: 'beet',
    name: 'Flappybee',
  },
  {
    id: 'flappy-bird-evolution',
    symbol: 'fevo',
    name: 'Flappy Bird Evolution',
  },
  {
    id: 'flappymoonbird',
    symbol: '$fmb',
    name: 'FlappyMoonbird',
  },
  {
    id: 'flare-finance',
    symbol: 'exfi',
    name: 'Flare Finance',
  },
  {
    id: 'flare-networks',
    symbol: 'flr',
    name: 'Flare',
  },
  {
    id: 'flare-token',
    symbol: '1flr',
    name: 'Flare Token',
  },
  {
    id: 'flash-3-0',
    symbol: 'flash',
    name: 'Flash 3.0',
  },
  {
    id: 'flash-analytics',
    symbol: 'flash',
    name: 'Flash Analytics',
  },
  {
    id: 'flash-bot',
    symbol: 'fbt',
    name: 'Flash Bot',
  },
  {
    id: 'flashdash',
    symbol: 'flashdash',
    name: 'Flashdash',
  },
  {
    id: 'flashpad-token',
    symbol: 'flash',
    name: 'Flashpad Token',
  },
  {
    id: 'flash-stake',
    symbol: 'flash',
    name: 'Flashstake',
  },
  {
    id: 'flash-technologies',
    symbol: 'ftt',
    name: 'FTT Token',
  },
  {
    id: 'flatqube',
    symbol: 'qube',
    name: 'FlatQube',
  },
  {
    id: 'fleamint',
    symbol: 'flmc',
    name: 'FleaMint',
  },
  {
    id: 'flex-coin',
    symbol: 'flex',
    name: 'FLEX Coin',
  },
  {
    id: 'flexmeme',
    symbol: 'flex',
    name: 'FlexMeme',
  },
  {
    id: 'flex-usd',
    symbol: 'flexusd',
    name: 'flexUSD',
  },
  {
    id: 'flightclupcoin',
    symbol: 'flight',
    name: 'FlightClupcoin',
  },
  {
    id: 'flits',
    symbol: 'fls',
    name: 'Flits',
  },
  {
    id: 'float-protocol',
    symbol: 'bank',
    name: 'Float Protocol',
  },
  {
    id: 'floki',
    symbol: 'floki',
    name: 'FLOKI',
  },
  {
    id: 'flokibonk',
    symbol: 'flobo',
    name: 'FlokiBonk',
  },
  {
    id: 'floki-cash',
    symbol: 'flokicash',
    name: 'Floki Cash',
  },
  {
    id: 'floki-ceo',
    symbol: 'flokiceo',
    name: 'FLOKI CEO',
  },
  {
    id: 'floki-ceo-coin',
    symbol: 'fcc',
    name: 'Floki CEO Coin',
  },
  {
    id: 'flokidash',
    symbol: 'flokidash',
    name: 'FlokiDash',
  },
  {
    id: 'flokifi',
    symbol: 'flokifi',
    name: 'FlokiFi',
  },
  {
    id: 'floki-musk',
    symbol: 'floki',
    name: 'Floki Musk',
  },
  {
    id: 'floki-rocket',
    symbol: 'rloki',
    name: 'Floki Rocket',
  },
  {
    id: 'flokis',
    symbol: 'flokis',
    name: 'Flokis',
  },
  {
    id: 'flokisanta',
    symbol: 'flokis',
    name: 'FlokiSanta',
  },
  {
    id: 'floki-santa',
    symbol: 'flokisanta',
    name: 'Floki Santa',
  },
  {
    id: 'floki-shiba-pepe-ceo',
    symbol: '3ceo',
    name: 'FLOKI SHIBA PEPE CEO',
  },
  {
    id: 'flokiter-ai',
    symbol: 'fai',
    name: 'FlokiTer',
  },
  {
    id: 'flonk',
    symbol: 'flonk',
    name: 'Flonk',
  },
  {
    id: 'floof',
    symbol: 'floof',
    name: 'FLOOF',
  },
  {
    id: 'floop',
    symbol: 'floop',
    name: 'Floop',
  },
  {
    id: 'floor-cheese-burger',
    symbol: 'flrbrg',
    name: 'Floor Cheese Burger',
  },
  {
    id: 'floordao',
    symbol: 'floor',
    name: 'FloorDAO',
  },
  {
    id: 'flooring-lab-credit',
    symbol: 'flc',
    name: 'Floor Protocol',
  },
  {
    id: 'flooring-protocol-azuki',
    symbol: 'uazuki',
    name: 'FP μAzuki',
  },
  {
    id: 'flooring-protocol-micro0n1force',
    symbol: 'u0n1',
    name: 'FP μ0N1Force',
  },
  {
    id: 'flooring-protocol-microbeanz',
    symbol: 'ubeanz',
    name: 'FP μBeanz',
  },
  {
    id: 'flooring-protocol-microboredapekennelclub',
    symbol: 'ubakc',
    name: 'FP μBoredApeKennelClub',
  },
  {
    id: 'flooring-protocol-microboredapeyachtclub',
    symbol: 'ubayc',
    name: 'FP μBoredApeYachtClub',
  },
  {
    id: 'flooring-protocol-microcaptainz',
    symbol: 'ucaptainz',
    name: 'FP μCaptainz',
  },
  {
    id: 'flooring-protocol-microclonex',
    symbol: 'uclonex',
    name: 'FP μCloneX',
  },
  {
    id: 'flooring-protocol-microcoolcats',
    symbol: 'ucool',
    name: 'FP μCoolCats',
  },
  {
    id: 'flooring-protocol-microdegods',
    symbol: 'udegods',
    name: 'FP μDeGods',
  },
  {
    id: 'flooring-protocol-microdoodle',
    symbol: 'udoodle',
    name: 'FP μDoodle',
  },
  {
    id: 'flooring-protocol-microelemental',
    symbol: 'uelem',
    name: 'FP μElemental',
  },
  {
    id: 'flooring-protocol-microjeergirl',
    symbol: 'μjeergirl',
    name: 'FP μJeerGirl',
  },
  {
    id: 'flooring-protocol-microlilpudgys',
    symbol: 'ulp',
    name: 'FP μLilPudgys',
  },
  {
    id: 'flooring-protocol-micromeebits',
    symbol: 'u⚇',
    name: 'FP μMeebits',
  },
  {
    id: 'flooring-protocol-micromfers',
    symbol: 'umfer',
    name: 'FP μMfers',
  },
  {
    id: 'flooring-protocol-micromilady',
    symbol: 'umil',
    name: 'FP μMilady',
  },
  {
    id: 'flooring-protocol-micromoonbirds',
    symbol: 'umoonbirds',
    name: 'FP μMoonBirds',
  },
  {
    id: 'flooring-protocol-micronakamigos',
    symbol: 'unkmgs',
    name: 'FP μNakamigos',
  },
  {
    id: 'flooring-protocol-microotatoz',
    symbol: 'upotatoz',
    name: 'FP μPotatoz',
  },
  {
    id: 'flooring-protocol-microotherdeed',
    symbol: 'uothr',
    name: 'FP μOtherdeed',
  },
  {
    id: 'flooring-protocol-micropudgypenguins',
    symbol: 'uppg',
    name: 'FP μPudgyPenguins',
  },
  {
    id: 'flooring-protocol-microsappyseals',
    symbol: 'usaps',
    name: 'FP μSappySeals',
  },
  {
    id: 'flooring-protocol-microworldofwomen',
    symbol: 'uwow',
    name: 'FP μWorldOfWomen',
  },
  {
    id: 'flooring-protocol-microy00ts',
    symbol: 'uy00ts',
    name: 'FP μY00ts',
  },
  {
    id: 'flooring-protocol-mutantapeyachtclub',
    symbol: 'umayc',
    name: 'FP μMutantApeYachtClub',
  },
  {
    id: 'florachain-yield-token',
    symbol: 'fyt',
    name: 'FloraChain',
  },
  {
    id: 'florence-finance-medici',
    symbol: 'ffm',
    name: 'Florence Finance Medici',
  },
  {
    id: 'florin',
    symbol: 'xfl',
    name: 'Florin',
  },
  {
    id: 'flourishing-ai-token',
    symbol: 'ai',
    name: 'Flourishing AI',
  },
  {
    id: 'flovatar-dust',
    symbol: 'fdust',
    name: 'Flovatar Dust',
  },
  {
    id: 'flow',
    symbol: 'flow',
    name: 'Flow',
  },
  {
    id: 'flowchaincoin',
    symbol: 'flc',
    name: 'Flowchain',
  },
  {
    id: 'flowmatic',
    symbol: 'fm',
    name: 'Flowmatic',
  },
  {
    id: 'flowx-finance',
    symbol: 'flx',
    name: 'FlowX Finance',
  },
  {
    id: 'floxypay',
    symbol: 'fxy',
    name: 'Floxypay',
  },
  {
    id: 'floyx-new',
    symbol: 'floyx',
    name: 'Floyx',
  },
  {
    id: 'fluffy-coin',
    symbol: 'fluf',
    name: 'Fluffy Coin',
  },
  {
    id: 'fluid',
    symbol: 'fld',
    name: 'FluidAI',
  },
  {
    id: 'fluid-2',
    symbol: 'fluid',
    name: 'Fluid',
  },
  {
    id: 'fluid-dai',
    symbol: 'fdai',
    name: 'Fluid DAI',
  },
  {
    id: 'fluid-frax',
    symbol: 'ffrax',
    name: 'Fluid FRAX',
  },
  {
    id: 'fluidtokens',
    symbol: 'fldt',
    name: 'FluidTokens',
  },
  {
    id: 'fluid-tusd',
    symbol: 'ftusd',
    name: 'Fluid TUSD',
  },
  {
    id: 'fluid-usdc',
    symbol: 'fusdc',
    name: 'Fluid USDC',
  },
  {
    id: 'fluid-usdt',
    symbol: 'fusdt',
    name: 'Fluid USDT',
  },
  {
    id: 'fluminense-fc-fan-token',
    symbol: 'flu',
    name: 'Fluminense FC Fan Token',
  },
  {
    id: 'flurry',
    symbol: 'flurry',
    name: 'Flurry Finance',
  },
  {
    id: 'flute',
    symbol: 'flut',
    name: 'Flute',
  },
  {
    id: 'flux',
    symbol: 'flux',
    name: 'Datamine FLUX',
  },
  {
    id: 'fluxbot',
    symbol: 'fluxb',
    name: 'Fluxbot',
  },
  {
    id: 'flux-dai',
    symbol: 'fdai',
    name: 'Flux DAI',
  },
  {
    id: 'flux-frax',
    symbol: 'ffrax',
    name: 'Flux FRAX',
  },
  {
    id: 'flux-point-studios-shards',
    symbol: 'shards',
    name: 'Flux Point Studios SHARDS',
  },
  {
    id: 'flux-protocol',
    symbol: 'flux',
    name: 'Flux Protocol',
  },
  {
    id: 'flux-token',
    symbol: 'flx',
    name: 'SEDA Protocol',
  },
  {
    id: 'flux-usdc',
    symbol: 'fusdc',
    name: 'Flux USDC',
  },
  {
    id: 'flux-usdt',
    symbol: 'fusdt',
    name: 'Flux USDT',
  },
  {
    id: 'flycoin-fly',
    symbol: 'fly',
    name: 'Flycoin FLY',
  },
  {
    id: 'flying-avocado-cat',
    symbol: 'fac',
    name: 'Flying Avocado Cat',
  },
  {
    id: 'flypme',
    symbol: 'fyp',
    name: 'FlypMe',
  },
  {
    id: 'fncy',
    symbol: 'fncy',
    name: 'FNCY',
  },
  {
    id: 'fnkcom',
    symbol: 'fnk',
    name: 'Fnk.com',
  },
  {
    id: 'foam-protocol',
    symbol: 'foam',
    name: 'FOAM',
  },
  {
    id: 'fodl-finance',
    symbol: 'fodl',
    name: 'Fodl Finance',
  },
  {
    id: 'fofo-token',
    symbol: 'fofo',
    name: 'FOFO Token',
  },
  {
    id: 'fognet',
    symbol: 'fog',
    name: 'FOGnet',
  },
  {
    id: 'foho-coin',
    symbol: 'foho',
    name: 'Foho Coin',
  },
  {
    id: 'fold',
    symbol: '$fld',
    name: 'Fold',
  },
  {
    id: 'follow-token',
    symbol: 'folo',
    name: 'Alpha Impact',
  },
  {
    id: 'fomo-2',
    symbol: 'fomo',
    name: 'FOMO',
  },
  {
    id: 'fomo-eth',
    symbol: 'fomo',
    name: 'Fomo Eth',
  },
  {
    id: 'fomofi',
    symbol: 'fomo',
    name: 'FomoFi',
  },
  {
    id: 'fomo-inu',
    symbol: 'finu',
    name: 'Fomo Inu',
  },
  {
    id: 'fone',
    symbol: 'fone',
    name: 'Fone',
  },
  {
    id: 'fonsmartchain',
    symbol: 'fon',
    name: 'FONSmartChain',
  },
  {
    id: 'fonzy',
    symbol: 'fonzy',
    name: 'Fonzy',
  },
  {
    id: 'foobar',
    symbol: 'foo',
    name: 'Foobar (Friend.tech)',
  },
  {
    id: 'food',
    symbol: 'food',
    name: 'Food',
  },
  {
    id: 'food-bank',
    symbol: 'food',
    name: 'Food Bank',
  },
  {
    id: 'foodchain-global',
    symbol: 'food',
    name: 'FoodChain Global',
  },
  {
    id: 'food-token-2',
    symbol: 'food',
    name: 'Food Token',
  },
  {
    id: 'foolbull',
    symbol: 'foolbull',
    name: 'FoolBull',
  },
  {
    id: 'foom',
    symbol: 'foom',
    name: 'Foom',
  },
  {
    id: 'football-at-alphaverse',
    symbol: 'fav',
    name: 'Football at AlphaVerse',
  },
  {
    id: 'football-coin',
    symbol: 'xfc',
    name: 'Football Coin',
  },
  {
    id: 'footballfanapp',
    symbol: 'fnc',
    name: 'FanCoin®',
  },
  {
    id: 'footballstars',
    symbol: 'fts',
    name: 'FootballStars',
  },
  {
    id: 'football-world-community',
    symbol: 'fwc',
    name: 'Football World Community',
  },
  {
    id: 'foox-ordinals',
    symbol: 'foox',
    name: 'Foox (Ordinals)',
  },
  {
    id: 'forbidden-fruit-energy',
    symbol: 'ffe',
    name: 'Forbidden Fruit Energy',
  },
  {
    id: 'force-2',
    symbol: 'frc',
    name: 'Force',
  },
  {
    id: 'force-bridge-usdc',
    symbol: 'usdc',
    name: 'Bridged USD Coin (Force Bridge)',
  },
  {
    id: 'forcefi',
    symbol: 'forc',
    name: 'Forcefi',
  },
  {
    id: 'force-protocol',
    symbol: 'for',
    name: 'ForTube',
  },
  {
    id: 'forefront',
    symbol: 'ff',
    name: 'Forefront',
  },
  {
    id: 'fore-protocol',
    symbol: 'fore',
    name: 'FORE Protocol',
  },
  {
    id: 'forest-knight',
    symbol: 'knight',
    name: 'Forest Knight',
  },
  {
    id: 'forestry',
    symbol: 'fry',
    name: 'Forestry',
  },
  {
    id: 'forever-aid-token',
    symbol: 'foat',
    name: 'Forever Aid Token',
  },
  {
    id: 'foreverbox',
    symbol: 'fbox',
    name: 'Foreverbox',
  },
  {
    id: 'forever-burn',
    symbol: 'fburn',
    name: 'Forever Burn',
  },
  {
    id: 'forever-shiba',
    symbol: '4shiba',
    name: 'FOREVER SHIBA',
  },
  {
    id: 'forge',
    symbol: 'forge',
    name: 'Forge',
  },
  {
    id: 'for-loot-and-glory',
    symbol: 'flag',
    name: 'For Loot And Glory',
  },
  {
    id: 'formation-fi',
    symbol: 'form',
    name: 'Formation FI',
  },
  {
    id: 'formula-inu',
    symbol: 'finu',
    name: 'FINU',
  },
  {
    id: 'forta',
    symbol: 'fort',
    name: 'Forta',
  },
  {
    id: 'fortress',
    symbol: 'fts',
    name: 'Fortress Loans',
  },
  {
    id: 'fortunafi-tokenized-short-term-u-s-treasury-bills-for-non-us-residents',
    symbol: 'ifbill',
    name: 'Fortunafi Tokenized Short-term U.S. Treasury Bills for Non US Residents',
  },
  {
    id: 'fortuna-sittard-fan-token',
    symbol: 'for',
    name: 'Fortuna Sittard Fan Token',
  },
  {
    id: 'fortunebets',
    symbol: 'frt',
    name: 'FortuneBets',
  },
  {
    id: 'fortune-bets',
    symbol: 'fortune',
    name: 'Fortune Bets',
  },
  {
    id: 'forus',
    symbol: 'fors',
    name: 'Forus',
  },
  {
    id: 'fountain-protocol',
    symbol: 'ftp',
    name: 'Fountain Protocol',
  },
  {
    id: 'fourcoin',
    symbol: 'four',
    name: 'FourCoin',
  },
  {
    id: 'foxe',
    symbol: 'foxe',
    name: 'FOXE',
  },
  {
    id: 'foxfunnies',
    symbol: 'fxn',
    name: 'FoxFunnies',
  },
  {
    id: 'foxgirl',
    symbol: 'foxgirl',
    name: 'FoxGirl',
  },
  {
    id: 'foxify',
    symbol: 'fox',
    name: 'Foxify',
  },
  {
    id: 'foxs',
    symbol: 'foxs',
    name: 'Foxs',
  },
  {
    id: 'fox-trading-token',
    symbol: 'foxt',
    name: 'Fox Trading',
  },
  {
    id: 'fractal',
    symbol: 'fcl',
    name: 'Fractal',
  },
  {
    id: 'fracton-protocol',
    symbol: 'ft',
    name: 'Fracton Protocol',
  },
  {
    id: 'fragments-of-arker',
    symbol: 'foa',
    name: 'Fragments of Arker',
  },
  {
    id: 'frakt-token',
    symbol: 'frkt',
    name: 'FRAKT',
  },
  {
    id: 'frame',
    symbol: 'frame',
    name: 'Frame',
  },
  {
    id: 'france-rev-finance',
    symbol: 'frf',
    name: 'FRANCE REV FINANCE',
  },
  {
    id: 'franklin',
    symbol: 'fly',
    name: 'Franklin',
  },
  {
    id: 'frapped-usdt',
    symbol: 'fusdt',
    name: 'Frapped USDT',
  },
  {
    id: 'frax',
    symbol: 'frax',
    name: 'Frax',
  },
  {
    id: 'frax-ether',
    symbol: 'frxeth',
    name: 'Frax Ether',
  },
  {
    id: 'frax-price-index',
    symbol: 'fpi',
    name: 'Frax Price Index',
  },
  {
    id: 'frax-price-index-share',
    symbol: 'fpis',
    name: 'Frax Price Index Share',
  },
  {
    id: 'frax-share',
    symbol: 'fxs',
    name: 'Frax Share',
  },
  {
    id: 'freco-coin',
    symbol: 'freco',
    name: 'Freco Coin',
  },
  {
    id: 'freddy-fazbear',
    symbol: '$fred',
    name: 'Freddy Fazbear',
  },
  {
    id: 'fredenergy',
    symbol: 'fred',
    name: 'FRED Energy',
  },
  {
    id: 'freebnk',
    symbol: 'frbk',
    name: 'FreeBnk',
  },
  {
    id: 'freedomcoin',
    symbol: 'freed',
    name: 'Freedomcoin',
  },
  {
    id: 'freedom-coin',
    symbol: 'free',
    name: 'FREEdom coin',
  },
  {
    id: 'freedom-jobs-business',
    symbol: '$fjb',
    name: 'Freedom. Jobs. Business',
  },
  {
    id: 'freedom-reserve',
    symbol: 'fr',
    name: 'Freedom Reserve',
  },
  {
    id: 'freela',
    symbol: 'frel',
    name: 'Freela',
  },
  {
    id: 'freerossdao',
    symbol: 'free',
    name: 'FreeRossDAO',
  },
  {
    id: 'freetrump',
    symbol: '$trump',
    name: 'FreeTrump',
  },
  {
    id: 'freeway',
    symbol: 'fwt',
    name: 'Freeway',
  },
  {
    id: 'freicoin',
    symbol: 'frc',
    name: 'Freicoin',
  },
  {
    id: 'frenbot',
    symbol: 'mef',
    name: 'FrenBot',
  },
  {
    id: 'french-connection-finance',
    symbol: 'fcf',
    name: 'French Connection Finance',
  },
  {
    id: 'frencoin-2',
    symbol: 'fren',
    name: 'Frencoin',
  },
  {
    id: 'frengate',
    symbol: '$fgate',
    name: 'FrenGate',
  },
  {
    id: 'fren-nation',
    symbol: 'fren',
    name: 'Fren Nation',
  },
  {
    id: 'frenpet',
    symbol: 'fp',
    name: 'Fren Pet',
  },
  {
    id: 'frens-coin',
    symbol: 'frens',
    name: 'Frens Coin',
  },
  {
    id: 'freqai',
    symbol: 'freqai',
    name: 'FREQAI',
  },
  {
    id: 'fresh-bot',
    symbol: 'fresh',
    name: 'FRESH Bot',
  },
  {
    id: 'freth',
    symbol: 'freth',
    name: 'frETH',
  },
  {
    id: 'freyala',
    symbol: 'xya',
    name: 'GameFi Crossing',
  },
  {
    id: 'freya-the-dog',
    symbol: 'freya',
    name: 'Freya',
  },
  {
    id: 'frgx-finance',
    symbol: 'frgx',
    name: 'FRGX Finance',
  },
  {
    id: 'friend',
    symbol: 'fren',
    name: 'Friend',
  },
  {
    id: 'friend3',
    symbol: 'f3',
    name: 'Friend3',
  },
  {
    id: 'friendfi',
    symbol: 'ffi',
    name: 'FriendFi',
  },
  {
    id: 'friend-room',
    symbol: 'friend',
    name: 'Friend Room',
  },
  {
    id: 'friendsniper',
    symbol: 'fsnipe',
    name: 'FriendSniper',
  },
  {
    id: 'friendspot',
    symbol: 'spot',
    name: 'FriendSpot',
  },
  {
    id: 'friends-with-benefits-network',
    symbol: 'fwb',
    name: 'Friends with Benefits Network',
  },
  {
    id: 'friends-with-benefits-pro',
    symbol: 'fwb',
    name: 'Friends With Benefits Pro',
  },
  {
    id: 'friendtech33',
    symbol: 'ftw',
    name: 'FriendTech33',
  },
  {
    id: 'friend-tech-farm',
    symbol: 'ftf',
    name: 'Friend Tech Farm',
  },
  {
    id: 'friendx',
    symbol: 'friendx',
    name: 'FriendX',
  },
  {
    id: 'friendz',
    symbol: 'fdz',
    name: 'Friendz',
  },
  {
    id: 'fringe-finance',
    symbol: 'frin',
    name: 'Fringe Finance',
  },
  {
    id: 'frog-ceo',
    symbol: 'frog ceo',
    name: 'FROG CEO',
  },
  {
    id: 'frog-chain',
    symbol: 'leap',
    name: 'Frog Chain LEAP',
  },
  {
    id: 'froge-finance',
    symbol: 'frogex',
    name: 'FrogeX',
  },
  {
    id: 'froggies-token-2',
    symbol: 'frgst',
    name: 'Froggies',
  },
  {
    id: 'froggy',
    symbol: 'froggy',
    name: 'Froggy',
  },
  {
    id: 'frogo',
    symbol: 'frogo',
    name: 'Frogo',
  },
  {
    id: 'frogolana',
    symbol: 'frogo',
    name: 'Frogolana',
  },
  {
    id: 'frogswap',
    symbol: 'frog',
    name: 'FrogSwap',
  },
  {
    id: 'frog-token',
    symbol: 'frog',
    name: 'Frog Token',
  },
  {
    id: 'frog-wif-peen',
    symbol: 'peen',
    name: 'Frog Wif Peen',
  },
  {
    id: 'fronk',
    symbol: 'fronk',
    name: 'Fronk',
  },
  {
    id: 'frontfanz-2',
    symbol: 'fanx',
    name: 'FrontFanz',
  },
  {
    id: 'frontier-token',
    symbol: 'front',
    name: 'Frontier',
  },
  {
    id: 'front-row',
    symbol: 'frr',
    name: 'Frontrow',
  },
  {
    id: 'froubot',
    symbol: 'frobot',
    name: 'FrouBot',
  },
  {
    id: 'froyo-games',
    symbol: 'froyo',
    name: 'Froyo Games',
  },
  {
    id: 'frozen-walrus-share',
    symbol: 'wshare',
    name: 'Frozen Walrus Share',
  },
  {
    id: 'fruits',
    symbol: 'frts',
    name: 'Fruits',
  },
  {
    id: 'frutti-dino',
    symbol: 'fdt',
    name: 'Frutti Dino',
  },
  {
    id: 'fryscrypto',
    symbol: 'fry',
    name: 'FrysCrypto',
  },
  {
    id: 'frz-solar-system',
    symbol: 'frzss',
    name: 'Frz Solar System',
  },
  {
    id: 'fsn',
    symbol: 'fsn',
    name: 'FUSION',
  },
  {
    id: 'fsociety',
    symbol: 'fsc',
    name: 'FSOCIETY',
  },
  {
    id: 'fsw-token',
    symbol: 'fsw',
    name: 'Falconswap',
  },
  {
    id: 'ftails',
    symbol: 'ftails',
    name: 'fTails',
  },
  {
    id: 'ftm-guru',
    symbol: 'elite',
    name: 'ftm.guru',
  },
  {
    id: 'ftribe-fighters',
    symbol: 'f2c',
    name: 'Ftribe Fighters',
  },
  {
    id: 'ftx-token',
    symbol: 'ftt',
    name: 'FTX',
  },
  {
    id: 'ftx-users-debt',
    symbol: 'fud',
    name: "FTX Users' Debt",
  },
  {
    id: 'fuck-pepe',
    symbol: 'fkpepe',
    name: 'Fuck Pepe',
  },
  {
    id: 'fud-the-pug',
    symbol: 'fud',
    name: 'Fud the Pug',
  },
  {
    id: 'fuel-network',
    symbol: 'fuel',
    name: 'Fuel Network',
  },
  {
    id: 'fufu',
    symbol: 'fufu',
    name: 'Fufu',
  },
  {
    id: 'fufu-token',
    symbol: 'fufu',
    name: 'Fufu Token',
  },
  {
    id: 'fujitoken',
    symbol: 'fjt',
    name: 'Fuji FJT',
  },
  {
    id: 'fulcrom',
    symbol: 'ful',
    name: 'Fulcrom',
  },
  {
    id: 'fumoney',
    symbol: 'fum',
    name: 'FUMoney',
  },
  {
    id: 'funarcade',
    symbol: 'fat',
    name: 'Funarcade',
  },
  {
    id: 'funded',
    symbol: 'funded',
    name: 'Funded',
  },
  {
    id: 'fund-of-yours',
    symbol: 'foy',
    name: 'Fund Of Yours',
  },
  {
    id: 'funex',
    symbol: 'funex',
    name: 'Funex',
  },
  {
    id: 'funfair',
    symbol: 'fun',
    name: 'FUNToken',
  },
  {
    id: 'funfi',
    symbol: 'fnf',
    name: 'FunFi',
  },
  {
    id: 'funny-coin',
    symbol: 'fuc',
    name: 'Funny Coin',
  },
  {
    id: 'funny-money',
    symbol: 'fny',
    name: 'Funny Money',
  },
  {
    id: 'furari',
    symbol: 'cia',
    name: 'Cat Intelligence Agency',
  },
  {
    id: 'furie',
    symbol: 'furie',
    name: 'FURIE',
  },
  {
    id: 'furio',
    symbol: '$fur',
    name: 'Furio',
  },
  {
    id: 'furucombo',
    symbol: 'combo',
    name: 'Furucombo',
  },
  {
    id: 'fuse-dollar',
    symbol: 'fusd',
    name: 'Fuse Dollar V3',
  },
  {
    id: 'fusefi',
    symbol: 'volt',
    name: 'Voltage Finance',
  },
  {
    id: 'fuse-network-token',
    symbol: 'fuse',
    name: 'Fuse',
  },
  {
    id: 'fusionbot',
    symbol: 'fusion',
    name: 'FusionBot',
  },
  {
    id: 'fusotao',
    symbol: 'tao',
    name: 'Fusotao',
  },
  {
    id: 'futurecoin',
    symbol: 'future',
    name: 'FutureCoin',
  },
  {
    id: 'futureswap',
    symbol: 'fst',
    name: 'Futureswap',
  },
  {
    id: 'futureswap-finance',
    symbol: 'fs',
    name: 'FutureSwap Finance',
  },
  {
    id: 'future-t-i-m-e-dividend',
    symbol: 'future',
    name: 'Future T.I.M.E Dividend',
  },
  {
    id: 'futurocoin',
    symbol: 'fto',
    name: 'FuturoCoin',
  },
  {
    id: 'fuze-token',
    symbol: 'fuze',
    name: 'FUZE',
  },
  {
    id: 'fuzion',
    symbol: 'fuzn',
    name: 'Fuzion',
  },
  {
    id: 'fuzz-finance',
    symbol: 'fuzz',
    name: 'Fuzz Finance',
  },
  {
    id: 'fx1sports',
    symbol: 'fxi',
    name: 'FX1Sports',
  },
  {
    id: 'fx-coin',
    symbol: 'fx',
    name: 'Function X',
  },
  {
    id: 'fxdx',
    symbol: 'fxdx',
    name: 'FXDX',
  },
  {
    id: 'fxn-token',
    symbol: 'fxn',
    name: 'f(x) Protocol',
  },
  {
    id: 'f-x-protocol-fractional-eth',
    symbol: 'feth',
    name: 'f(x) Protocol Fractional ETH',
  },
  {
    id: 'f-x-protocol-leveraged-eth',
    symbol: 'xeth',
    name: 'f(x) Protocol Leveraged ETH',
  },
  {
    id: 'fydcoin',
    symbol: 'fyd',
    name: 'FYDcoin',
  },
  {
    id: 'fyde-treasury',
    symbol: 'trsy',
    name: 'Fyde Treasury',
  },
  {
    id: 'g',
    symbol: 'g*',
    name: 'G*',
  },
  {
    id: 'g999',
    symbol: 'g999',
    name: 'G999',
  },
  {
    id: 'gagarin',
    symbol: 'ggr',
    name: 'GAGARIN',
  },
  {
    id: 'gaia-everworld',
    symbol: 'gaia',
    name: 'Gaia Everworld',
  },
  {
    id: 'gains',
    symbol: 'gains',
    name: 'Gains',
  },
  {
    id: 'gains-network',
    symbol: 'gns',
    name: 'Gains Network',
  },
  {
    id: 'gainspot',
    symbol: 'gain$',
    name: 'GainSpot',
  },
  {
    id: 'gaj',
    symbol: 'gaj',
    name: 'Gaj Finance',
  },
  {
    id: 'gala',
    symbol: 'gala',
    name: 'GALA',
  },
  {
    id: 'galactic-arena-the-nftverse',
    symbol: 'gan',
    name: 'Galactic Arena: The NFTverse',
  },
  {
    id: 'gala-music',
    symbol: 'music',
    name: 'Gala Music',
  },
  {
    id: 'galatasaray-fan-token',
    symbol: 'gal',
    name: 'Galatasaray Fan Token',
  },
  {
    id: 'galaxia',
    symbol: 'gxa',
    name: 'Galaxia',
  },
  {
    id: 'galaxiaverse',
    symbol: 'glxia',
    name: 'GalaxiaVerse',
  },
  {
    id: 'galaxy-arena',
    symbol: 'esnc',
    name: 'Galaxy Arena Metaverse',
  },
  {
    id: 'galaxycoin',
    symbol: 'galaxy',
    name: 'GalaxyCoin',
  },
  {
    id: 'galaxy-fight-club',
    symbol: 'gcoin',
    name: 'Galaxy Fight Club',
  },
  {
    id: 'galaxy-fox',
    symbol: 'gfox',
    name: 'Galaxy Fox',
  },
  {
    id: 'galaxy-token-injective',
    symbol: 'galaxy',
    name: 'Galaxy Token (Injective)',
  },
  {
    id: 'galaxy-villains',
    symbol: 'gvc',
    name: 'Galaxy Villains',
  },
  {
    id: 'galaxy-war',
    symbol: 'gwt',
    name: 'Galaxy War',
  },
  {
    id: 'galeon',
    symbol: 'galeon',
    name: 'Galeon',
  },
  {
    id: 'galvan',
    symbol: 'ize',
    name: 'Galvan',
  },
  {
    id: 'gambex',
    symbol: 'gbe',
    name: 'Gambex',
  },
  {
    id: 'game',
    symbol: 'gtc',
    name: 'Game',
  },
  {
    id: 'gameai',
    symbol: 'gat',
    name: 'GameAI',
  },
  {
    id: 'game-coin',
    symbol: 'gmex',
    name: 'Game Coin',
  },
  {
    id: 'gamecraft',
    symbol: 'gtc',
    name: 'GameCraft',
  },
  {
    id: 'gamecredits',
    symbol: 'game',
    name: 'GameCredits',
  },
  {
    id: 'gamee',
    symbol: 'gmee',
    name: 'GAMEE',
  },
  {
    id: 'gamefantasystar',
    symbol: 'gfs',
    name: 'GameFantasyStar',
  },
  {
    id: 'game-fantasy-token',
    symbol: 'gft',
    name: 'Game Fantasy',
  },
  {
    id: 'gamefi',
    symbol: 'gafi',
    name: 'GameFi.org',
  },
  {
    id: 'gameflip',
    symbol: 'flp',
    name: 'Gameflip',
  },
  {
    id: 'gamegpt',
    symbol: 'duel',
    name: 'GameGPT',
  },
  {
    id: 'gameluk',
    symbol: 'luk',
    name: 'GameLuk',
  },
  {
    id: 'gameology',
    symbol: 'gmy',
    name: 'Gameology',
  },
  {
    id: 'gamepass',
    symbol: 'gpn',
    name: 'Gamepass',
  },
  {
    id: 'gamepass-network',
    symbol: 'gpn',
    name: 'GamePass Network',
  },
  {
    id: 'gamer',
    symbol: 'gmr',
    name: 'GAMER',
  },
  {
    id: 'gamer-arena',
    symbol: 'gau',
    name: 'Gamer Arena',
  },
  {
    id: 'gamercoin',
    symbol: 'ghx',
    name: 'GamerCoin',
  },
  {
    id: 'gamerse',
    symbol: 'lfg',
    name: 'Gamerse',
  },
  {
    id: 'games-for-a-living',
    symbol: 'gfal',
    name: 'Games for a Living',
  },
  {
    id: 'gamespad',
    symbol: 'gmpd',
    name: 'GamesPad',
  },
  {
    id: 'gamestarter',
    symbol: 'game',
    name: 'Gamestarter',
  },
  {
    id: 'gamestation',
    symbol: 'gamer',
    name: 'GameStation',
  },
  {
    id: 'gamestop-tokenized-stock-defichain',
    symbol: 'dgme',
    name: 'GameStop Tokenized Stock Defichain',
  },
  {
    id: 'gameswap-org',
    symbol: 'gswap',
    name: 'Gameswap',
  },
  {
    id: 'gameswift',
    symbol: 'gswift',
    name: 'GameSwift',
  },
  {
    id: 'game-tournament-trophy',
    symbol: 'gtt',
    name: 'Game Tournament Trophy',
  },
  {
    id: 'game-tree',
    symbol: 'gtcoin',
    name: 'Game Tree',
  },
  {
    id: 'gamexchange',
    symbol: 'gamex',
    name: 'Gamexchange',
  },
  {
    id: 'gamezone',
    symbol: 'gzone',
    name: 'GameZone',
  },
  {
    id: 'gami',
    symbol: 'gami',
    name: 'Gami',
  },
  {
    id: 'gaming-stars',
    symbol: 'games',
    name: 'Gaming Stars',
  },
  {
    id: 'gamium',
    symbol: 'gmm',
    name: 'Gamium',
  },
  {
    id: 'gami-world',
    symbol: 'gami',
    name: 'GAMI World',
  },
  {
    id: 'gamma-strategies',
    symbol: 'gamma',
    name: 'Gamma Strategies',
  },
  {
    id: 'gammaswap',
    symbol: '',
    name: 'GammaSwap',
  },
  {
    id: 'gamma-wallet',
    symbol: 'gamma',
    name: 'Gamma Wallet',
  },
  {
    id: 'gamyfi-token',
    symbol: 'gfx',
    name: 'GamyFi',
  },
  {
    id: 'gangs-rabbit',
    symbol: 'rabbit',
    name: 'Gangs Rabbit',
  },
  {
    id: 'gapcoin',
    symbol: 'gap',
    name: 'Gapcoin',
  },
  {
    id: 'garbage',
    symbol: 'garbage',
    name: 'Garbage',
  },
  {
    id: 'garbi-protocol',
    symbol: 'grb',
    name: 'Garbi Protocol',
  },
  {
    id: 'gard',
    symbol: 'gard',
    name: 'GARD',
  },
  {
    id: 'garden-2',
    symbol: 'seed',
    name: 'Garden',
  },
  {
    id: 'garfield-bsc',
    symbol: '$garfield',
    name: 'Garfield (BSC)',
  },
  {
    id: 'gari-network',
    symbol: 'gari',
    name: 'Gari Network',
  },
  {
    id: 'garlicoin',
    symbol: 'grlc',
    name: 'Garlicoin',
  },
  {
    id: 'gary',
    symbol: 'gary',
    name: 'Gary',
  },
  {
    id: 'gas',
    symbol: 'gas',
    name: 'Gas',
  },
  {
    id: 'gaschameleon',
    symbol: 'gasc',
    name: 'GasChameleon',
  },
  {
    id: 'gascoin',
    symbol: 'gcn',
    name: 'Gascoin',
  },
  {
    id: 'gas-dao',
    symbol: 'gas',
    name: 'Gas DAO',
  },
  {
    id: 'gatechain-token',
    symbol: 'gt',
    name: 'Gate',
  },
  {
    id: 'gatenet',
    symbol: 'gate',
    name: 'GATENet',
  },
  {
    id: 'gateway-protocol',
    symbol: 'gwp',
    name: 'Gateway Protocol',
  },
  {
    id: 'gateway-to-mars',
    symbol: 'mars',
    name: 'GATEWAY TO MARS',
  },
  {
    id: 'gather',
    symbol: 'gth',
    name: 'Gather',
  },
  {
    id: 'gatsby-inu-2',
    symbol: 'gatsby',
    name: 'Gatsby Inu (OLD)',
  },
  {
    id: 'gatsby-inu-new',
    symbol: 'gatsby',
    name: 'Gatsby Inu',
  },
  {
    id: 'gauro',
    symbol: 'gauro',
    name: 'Gauro',
  },
  {
    id: 'gax-liquidity-token-reward',
    symbol: 'gltr',
    name: 'GAX Liquidity Token Reward',
  },
  {
    id: 'gay-pepe',
    symbol: 'gaypepe',
    name: 'Gay Pepe',
  },
  {
    id: 'gaziantep-fk-fan-token',
    symbol: 'gfk',
    name: 'Gaziantep FK Fan Token',
  },
  {
    id: 'gbot',
    symbol: 'gbot',
    name: 'GBOT',
  },
  {
    id: 'gcn-coin',
    symbol: 'gcn',
    name: 'GCN Coin',
  },
  {
    id: 'gdrt',
    symbol: 'gdrt',
    name: 'GDRT',
  },
  {
    id: 'gdx-token',
    symbol: 'gdx',
    name: 'Gridex',
  },
  {
    id: 'gearbox',
    symbol: 'gear',
    name: 'Gearbox',
  },
  {
    id: 'gecko-inu',
    symbol: 'gec',
    name: 'Gecko Inu',
  },
  {
    id: 'gecko-meme',
    symbol: 'gecko',
    name: 'Gecko (Meme)',
  },
  {
    id: 'gecoin',
    symbol: 'gec',
    name: 'Gecoin',
  },
  {
    id: 'geegoopuzzle',
    symbol: 'ggp',
    name: 'Geegoopuzzle',
  },
  {
    id: 'geeko-dex',
    symbol: 'geeko',
    name: 'Geeko Dex',
  },
  {
    id: 'geek-protocol',
    symbol: 'geek',
    name: 'Geek Protocol',
  },
  {
    id: 'geeq',
    symbol: 'geeq',
    name: 'GEEQ',
  },
  {
    id: 'geist-dai',
    symbol: 'gdai',
    name: 'Geist Dai',
  },
  {
    id: 'geist-eth',
    symbol: 'geth',
    name: 'Geist ETH',
  },
  {
    id: 'geist-ftm',
    symbol: 'gftm',
    name: 'Geist FTM',
  },
  {
    id: 'geist-fusdt',
    symbol: 'gfusdt',
    name: 'Geist fUSDT',
  },
  {
    id: 'geist-usdc',
    symbol: 'gusdc',
    name: 'Geist USDC',
  },
  {
    id: 'geist-wbtc',
    symbol: 'gwbtc',
    name: 'Geist WBTC',
  },
  {
    id: 'geke',
    symbol: 'geke',
    name: 'Geke',
  },
  {
    id: 'gekko',
    symbol: 'gekko',
    name: 'GEKKO',
  },
  {
    id: 'gelato',
    symbol: 'gel',
    name: 'Gelato',
  },
  {
    id: 'gemach',
    symbol: 'gmac',
    name: 'Gemach',
  },
  {
    id: 'gemdrop',
    symbol: 'gem',
    name: 'GemDrop',
  },
  {
    id: 'gem-exchange-and-trading',
    symbol: 'gxt',
    name: 'Gem Exchange and Trading',
  },
  {
    id: 'gemholic',
    symbol: 'gems',
    name: 'Gemholic',
  },
  {
    id: 'gemhub',
    symbol: 'ghub',
    name: 'GemHUB',
  },
  {
    id: 'gemie',
    symbol: 'gem',
    name: 'Gemie',
  },
  {
    id: 'gemini-ai',
    symbol: 'gemini',
    name: 'Gemini AI',
  },
  {
    id: 'gemini-dollar',
    symbol: 'gusd',
    name: 'Gemini Dollar',
  },
  {
    id: 'gemlink',
    symbol: 'glink',
    name: 'GemLink',
  },
  {
    id: 'gempad',
    symbol: 'gems',
    name: 'GemPad',
  },
  {
    id: 'gems-2',
    symbol: 'gem',
    name: 'Gems',
  },
  {
    id: 'gemston',
    symbol: 'gemston',
    name: 'GEMSTON',
  },
  {
    id: 'gemswap-2',
    symbol: 'zgem',
    name: 'GemSwap',
  },
  {
    id: 'genaro-network',
    symbol: 'gnx',
    name: 'Genaro Network',
  },
  {
    id: 'genclerbirligi-fan-token',
    symbol: 'gbsk',
    name: 'Gençlerbirliği Fan Token',
  },
  {
    id: 'gene',
    symbol: 'gene',
    name: 'Gene',
  },
  {
    id: 'generaitiv',
    symbol: 'gai',
    name: 'Generaitiv',
  },
  {
    id: 'generational-wealth',
    symbol: 'gen',
    name: 'Generational Wealth',
  },
  {
    id: 'generator',
    symbol: 'gen',
    name: 'Generator',
  },
  {
    id: 'genesislrt-restaked-eth',
    symbol: 'geneth',
    name: 'GenesisLRT Restaked ETH',
  },
  {
    id: 'genesis-shards',
    symbol: 'gs',
    name: 'Genesis Shards',
  },
  {
    id: 'genesis-wink',
    symbol: 'gwink',
    name: 'Genesis Wink',
  },
  {
    id: 'genesis-worlds',
    symbol: 'genesis',
    name: 'Genesis Worlds',
  },
  {
    id: 'genesys',
    symbol: 'gsys',
    name: 'Genesys',
  },
  {
    id: 'genesysgo-shadow',
    symbol: 'shdw',
    name: 'Shadow Token',
  },
  {
    id: 'geniebot',
    symbol: 'genie',
    name: 'GenieBot',
  },
  {
    id: 'genie-protocol',
    symbol: 'gnp',
    name: 'Genie Protocol',
  },
  {
    id: 'genit-chain',
    symbol: 'gnt',
    name: 'Genit Chain',
  },
  {
    id: 'genius',
    symbol: 'geni',
    name: 'Genius',
  },
  {
    id: 'genius-playboy-billionaire-philanthropist',
    symbol: 'gpbp',
    name: 'Genius Playboy Billionaire Philanthropist',
  },
  {
    id: 'genius-x',
    symbol: 'gensx',
    name: 'Genius X',
  },
  {
    id: 'genius-yield',
    symbol: 'gens',
    name: 'Genius Yield',
  },
  {
    id: 'geniux',
    symbol: 'iux',
    name: 'GeniuX',
  },
  {
    id: 'genix',
    symbol: 'genix',
    name: 'Genix',
  },
  {
    id: 'genomesdao',
    symbol: '$gene',
    name: 'GenomesDAO',
  },
  {
    id: 'genopet-ki',
    symbol: 'ki',
    name: 'Genopets KI',
  },
  {
    id: 'genopets',
    symbol: 'gene',
    name: 'Genopets',
  },
  {
    id: 'genshinflokiinu',
    symbol: 'gfloki',
    name: 'GenshinFlokiInu',
  },
  {
    id: 'genshiro',
    symbol: 'gens',
    name: 'Genshiro',
  },
  {
    id: 'gensokishis-metaverse',
    symbol: 'mv',
    name: 'GensoKishi Metaverse',
  },
  {
    id: 'genz-token',
    symbol: 'genz',
    name: 'GENZ Token',
  },
  {
    id: 'geodb',
    symbol: 'geo',
    name: 'GeoDB',
  },
  {
    id: 'geodnet',
    symbol: 'geod',
    name: 'Geodnet',
  },
  {
    id: 'geojam',
    symbol: 'jam',
    name: 'Geojam',
  },
  {
    id: 'geoleaf',
    symbol: 'glt',
    name: 'GeoLeaf (OLD)',
  },
  {
    id: 'geoleaf-2',
    symbol: 'glt',
    name: 'GeoLeaf',
  },
  {
    id: 'geometric-energy-corporation',
    symbol: 'gec',
    name: 'Geometric Energy Corporation',
  },
  {
    id: 'geopoly',
    symbol: 'geo$',
    name: 'Geopoly',
  },
  {
    id: 'gera-coin',
    symbol: 'gera',
    name: 'Gera Coin',
  },
  {
    id: 'gerowallet',
    symbol: 'gero',
    name: 'GeroWallet',
  },
  {
    id: 'getaverse',
    symbol: 'geta',
    name: 'Getaverse',
  },
  {
    id: 'getkicks',
    symbol: 'kicks',
    name: 'GetKicks',
  },
  {
    id: 'get-rich-with-meme',
    symbol: '$memememe$',
    name: 'get rich with meme',
  },
  {
    id: 'get-token',
    symbol: 'get',
    name: 'GET Protocol',
  },
  {
    id: 'geuro',
    symbol: 'geuro',
    name: 'GEURO',
  },
  {
    id: 'geyser',
    symbol: 'gysr',
    name: 'Geyser',
  },
  {
    id: 'ggtkn',
    symbol: 'ggtkn',
    name: 'GGTKN',
  },
  {
    id: 'gg-token',
    symbol: 'ggtk',
    name: 'GG',
  },
  {
    id: 'ghacoin',
    symbol: 'ghacoin',
    name: 'GhaCoin',
  },
  {
    id: 'ghast',
    symbol: 'gha',
    name: 'Ghast',
  },
  {
    id: 'gho',
    symbol: 'gho',
    name: 'GHO',
  },
  {
    id: 'ghost',
    symbol: 'ghost',
    name: 'Ghost',
  },
  {
    id: 'ghost-by-mcafee',
    symbol: 'ghost',
    name: 'Ghost',
  },
  {
    id: 'ghostdag-org',
    symbol: 'gdag',
    name: 'GhostDAG.org',
  },
  {
    id: 'ghostkids',
    symbol: 'boo',
    name: 'GhostKids',
  },
  {
    id: 'ghostwifhat',
    symbol: 'gif',
    name: 'Ghostwifhat',
  },
  {
    id: 'ghosty',
    symbol: 'ghsy',
    name: 'GHOSTY',
  },
  {
    id: 'giannidoge-esport',
    symbol: 'gde',
    name: 'GianniDoge Esport',
  },
  {
    id: 'giant-mammoth',
    symbol: 'gmmt',
    name: 'Giant Mammoth',
  },
  {
    id: 'gibx-swap',
    symbol: 'x',
    name: 'GIBX Swap',
  },
  {
    id: 'gictrade',
    symbol: 'gict',
    name: 'GICTrade',
  },
  {
    id: 'giddy',
    symbol: 'giddy',
    name: 'Giddy',
  },
  {
    id: 'gif-dao',
    symbol: 'gif',
    name: 'GIF DAO',
  },
  {
    id: 'giftedhands',
    symbol: 'ghd',
    name: 'Giftedhands',
  },
  {
    id: 'gifto',
    symbol: 'gft',
    name: 'Gifto',
  },
  {
    id: 'gify-ai',
    symbol: 'gify',
    name: 'Gify AI',
  },
  {
    id: 'gigachad-2',
    symbol: 'giga',
    name: 'Gigachad',
  },
  {
    id: 'gigadao',
    symbol: 'gigs',
    name: 'GigaDAO',
  },
  {
    id: 'gigantix-wallet',
    symbol: 'gtx',
    name: 'Gigantix Wallet',
  },
  {
    id: 'gigaswap',
    symbol: 'giga',
    name: 'GigaSwap',
  },
  {
    id: 'gigatoken',
    symbol: 'giga',
    name: 'GigaToken',
  },
  {
    id: 'gilgeous',
    symbol: 'glg',
    name: 'Gilgeous',
  },
  {
    id: 'ginger',
    symbol: 'ginger',
    name: 'GINGER',
  },
  {
    id: 'gingers-have-no-sol',
    symbol: 'ginger',
    name: 'Gingers Have No Sol',
  },
  {
    id: 'ginoa',
    symbol: 'ginoa',
    name: 'Ginoa',
  },
  {
    id: 'ginza-network',
    symbol: 'ginza',
    name: 'Ginza Network',
  },
  {
    id: 'gitcoin',
    symbol: 'gtc',
    name: 'Gitcoin',
  },
  {
    id: 'gitcoin-staked-eth-index',
    symbol: 'gtceth',
    name: 'Gitcoin Staked ETH Index',
  },
  {
    id: 'gitopia',
    symbol: 'lore',
    name: 'Gitopia',
  },
  {
    id: 'givestation',
    symbol: 'gvst',
    name: 'GiveStation',
  },
  {
    id: 'giveth',
    symbol: 'giv',
    name: 'Giveth',
  },
  {
    id: 'give-tr-your-coq',
    symbol: 'gtryc',
    name: 'GIVE TR YOUR COQ',
  },
  {
    id: 'givewell-inu',
    symbol: 'ginu',
    name: 'Givewell Inu',
  },
  {
    id: 'givingtoservices-svs',
    symbol: 'svs',
    name: 'GivingToServices SVS',
  },
  {
    id: 'glacier',
    symbol: 'glcr',
    name: 'Glacier',
  },
  {
    id: 'gld-tokenized-stock-defichain',
    symbol: 'dgld',
    name: 'SPDR Gold Shares Defichain',
  },
  {
    id: 'gleec-coin',
    symbol: 'gleec',
    name: 'Gleec Coin',
  },
  {
    id: 'glend',
    symbol: 'glend',
    name: 'GLend',
  },
  {
    id: 'gli',
    symbol: 'gli',
    name: 'GLI',
  },
  {
    id: 'glide-finance',
    symbol: 'glide',
    name: 'Glide Finance',
  },
  {
    id: 'glint-coin',
    symbol: 'glint',
    name: 'Glint Coin',
  },
  {
    id: 'glitch-protocol',
    symbol: 'glch',
    name: 'Glitch Protocol',
  },
  {
    id: 'glitter-finance',
    symbol: 'xgli',
    name: 'XGLI DAO Protocol',
  },
  {
    id: 'glitzkoin',
    symbol: 'gtn',
    name: 'GlitzKoin',
  },
  {
    id: 'globalboost',
    symbol: 'bsty',
    name: 'GlobalBoost',
  },
  {
    id: 'globalchainz',
    symbol: 'gcz',
    name: 'GlobalChainZ',
  },
  {
    id: 'global-coin-research',
    symbol: 'gcr',
    name: 'Global Coin Research',
  },
  {
    id: 'global-digital-cluster-co',
    symbol: 'gdcc',
    name: 'Global Digital Cluster Coin',
  },
  {
    id: 'global-digital-content',
    symbol: 'gdc',
    name: 'Global Digital Content',
  },
  {
    id: 'global-fan-token',
    symbol: 'glft',
    name: 'Global Fan Token',
  },
  {
    id: 'global-innovation-platform',
    symbol: 'gip',
    name: 'Global Innovation Platform',
  },
  {
    id: 'global-miracle',
    symbol: 'gm',
    name: 'Global Miracle',
  },
  {
    id: 'global-smart-asset',
    symbol: 'gsa',
    name: 'Global Smart Asset',
  },
  {
    id: 'global-social-chain',
    symbol: 'gsc',
    name: 'Global Social Chain',
  },
  {
    id: 'global-trading-xenocurren',
    symbol: 'gtx',
    name: 'Global Trading Xenocurrency',
  },
  {
    id: 'global-trust-coin',
    symbol: 'gtc',
    name: 'Global Trust Coin',
  },
  {
    id: 'global-virtual-coin',
    symbol: 'gvc',
    name: 'Global Virtual Coin',
  },
  {
    id: 'globe-derivative-exchange',
    symbol: 'gdt',
    name: 'Globe Derivative Exchange',
  },
  {
    id: 'globees',
    symbol: 'bee',
    name: 'Globees',
  },
  {
    id: 'globel-community',
    symbol: 'gc',
    name: 'Globel Community',
  },
  {
    id: 'globiance-exchange',
    symbol: 'gbex',
    name: 'Globiance Exchange',
  },
  {
    id: 'glo-dollar',
    symbol: 'usdglo',
    name: 'Glo Dollar',
  },
  {
    id: 'glory-token',
    symbol: 'glr',
    name: 'Glory Token',
  },
  {
    id: 'glouki',
    symbol: 'glk',
    name: 'Glouki',
  },
  {
    id: 'glow-token-8fba1e9e-5643-47b4-8fef-d0eef67af854',
    symbol: 'glow',
    name: 'Glow Token',
  },
  {
    id: 'gm',
    symbol: 'gm',
    name: 'GM',
  },
  {
    id: 'gmbl-computer-chip',
    symbol: 'gmbl',
    name: 'GMBL COMPUTER CHiP',
  },
  {
    id: 'gmbot',
    symbol: 'gmbt',
    name: 'GMBot',
  },
  {
    id: 'gmcash',
    symbol: 'gmc',
    name: 'GMCash',
  },
  {
    id: 'gmcash-share',
    symbol: 'gshare',
    name: 'GMCash Share',
  },
  {
    id: 'gmcoin-2',
    symbol: 'gmcoin',
    name: 'GMCoin',
  },
  {
    id: 'gmd-protocol',
    symbol: 'gmd',
    name: 'GMD',
  },
  {
    id: 'gme',
    symbol: 'gme',
    name: 'GME',
  },
  {
    id: 'gmfam',
    symbol: 'gmfam',
    name: 'GMFAM',
  },
  {
    id: 'gmlp',
    symbol: 'gmlp',
    name: 'GMLP',
  },
  {
    id: 'gm-machine',
    symbol: 'gm',
    name: 'GM Machine',
  },
  {
    id: 'gmt-token',
    symbol: 'gmt',
    name: 'Gomining Token',
  },
  {
    id: 'gmusd',
    symbol: 'gmusd',
    name: 'gmUSD',
  },
  {
    id: 'gmx',
    symbol: 'gmx',
    name: 'GMX',
  },
  {
    id: 'gnd-protocol',
    symbol: 'gnd',
    name: 'GND Protocol',
  },
  {
    id: 'gnft',
    symbol: 'gnft',
    name: 'GNFT',
  },
  {
    id: 'gnome',
    symbol: '$gnome',
    name: 'GNOME',
  },
  {
    id: 'gnosis',
    symbol: 'gno',
    name: 'Gnosis',
  },
  {
    id: 'gny',
    symbol: 'gny',
    name: 'GNY',
  },
  {
    id: 'goal3',
    symbol: 'zkusd',
    name: 'zkUSD',
  },
  {
    id: 'goal-token',
    symbol: 'goal',
    name: 'GOAL Token',
  },
  {
    id: 'goated',
    symbol: 'goat',
    name: 'GOATED',
  },
  {
    id: 'goatly-farm',
    symbol: 'gtf',
    name: 'Goatly.farm',
  },
  {
    id: 'goblin',
    symbol: 'goblin',
    name: 'Goblin',
  },
  {
    id: 'gobtc',
    symbol: 'gobtc',
    name: 'goBTC',
  },
  {
    id: 'gobyte',
    symbol: 'gbx',
    name: 'GoByte',
  },
  {
    id: 'gochain',
    symbol: 'go',
    name: 'GoChain',
  },
  {
    id: 'gocharge-tech',
    symbol: 'charged',
    name: 'GoCharge Tech',
  },
  {
    id: 'gocryptome',
    symbol: 'gcme',
    name: 'GoCryptoMe',
  },
  {
    id: 'god',
    symbol: 'god',
    name: 'God',
  },
  {
    id: 'godbot',
    symbol: 'godbot',
    name: 'GodBot',
  },
  {
    id: 'god-coin',
    symbol: 'god',
    name: 'GOD Coin',
  },
  {
    id: 'gode-chain',
    symbol: 'gode',
    name: 'Gode Chain',
  },
  {
    id: 'god-of-wealth',
    symbol: 'gow39',
    name: 'God of Wealth',
  },
  {
    id: 'gods-unchained',
    symbol: 'gods',
    name: 'Gods Unchained',
  },
  {
    id: 'godzilla',
    symbol: 'godz',
    name: 'Godzilla',
  },
  {
    id: 'goerli-eth',
    symbol: 'geth',
    name: 'Goerli ETH',
  },
  {
    id: 'goeth',
    symbol: 'goeth',
    name: 'goETH',
  },
  {
    id: 'gofitterai',
    symbol: 'fitai',
    name: 'GoFitterAI',
  },
  {
    id: 'go-fu-k-yourself',
    symbol: 'gfy',
    name: 'go fu*k yourself.',
  },
  {
    id: 'gogocoin',
    symbol: 'gogo',
    name: 'GOGOcoin',
  },
  {
    id: 'gogolcoin',
    symbol: 'gol',
    name: 'GogolCoin',
  },
  {
    id: 'gogopool',
    symbol: 'ggp',
    name: 'GoGoPool',
  },
  {
    id: 'gogopool-ggavax',
    symbol: 'ggavax',
    name: 'GoGoPool ggAVAX',
  },
  {
    id: 'gojo-bsc',
    symbol: 'gojobsc',
    name: 'Gojo BSC',
  },
  {
    id: 'goku',
    symbol: 'goku',
    name: 'Goku',
  },
  {
    id: 'goku-money-gai',
    symbol: 'gai',
    name: 'Goku Money GAI',
  },
  {
    id: 'golcoin',
    symbol: 'golc',
    name: 'GOLCOIN',
  },
  {
    id: 'gold-2',
    symbol: 'gold',
    name: 'Gold',
  },
  {
    id: 'gold-3',
    symbol: 'gold',
    name: 'GOLD',
  },
  {
    id: 'gold8',
    symbol: 'gold8',
    name: 'GOLD8',
  },
  {
    id: 'goldcoin',
    symbol: 'glc',
    name: 'Goldcoin',
  },
  {
    id: 'golden-ball',
    symbol: 'glb',
    name: 'Golden Ball',
  },
  {
    id: 'goldenboys',
    symbol: 'gold',
    name: 'GoldenBoys',
  },
  {
    id: 'goldencoin',
    symbol: 'gld',
    name: 'GoldenCoin',
  },
  {
    id: 'golden-doge',
    symbol: 'gdoge',
    name: 'Golden Doge',
  },
  {
    id: 'golden-goose',
    symbol: 'gold',
    name: 'Golden Goose',
  },
  {
    id: 'golden-inu',
    symbol: 'golden',
    name: 'Golden Inu',
  },
  {
    id: 'golden-inu-token',
    symbol: 'golden',
    name: 'Golden Inu',
  },
  {
    id: 'golden-token',
    symbol: 'gold',
    name: 'Golden',
  },
  {
    id: 'golden-zen-token',
    symbol: 'gzt',
    name: 'Golden Zen Token',
  },
  {
    id: 'goldex-token',
    symbol: 'gldx',
    name: 'Goldex',
  },
  {
    id: 'gold-fever-native-gold',
    symbol: 'ngl',
    name: 'Gold Fever Native Gold',
  },
  {
    id: 'goldfinch',
    symbol: 'gfi',
    name: 'Goldfinch',
  },
  {
    id: 'goldfinx',
    symbol: 'gix',
    name: 'GoldFinX',
  },
  {
    id: 'goldkash',
    symbol: 'xgk',
    name: 'GoldKash',
  },
  {
    id: 'goldminer',
    symbol: 'gm',
    name: 'GoldMiner',
  },
  {
    id: 'gold-pegged-coin',
    symbol: 'gpc',
    name: 'Gold Pegged Coin',
  },
  {
    id: 'goldpesa-option',
    symbol: 'gpo',
    name: 'GoldPesa Option',
  },
  {
    id: 'gold-pieces',
    symbol: 'gp',
    name: 'gold pieces',
  },
  {
    id: 'gold-secured-currency',
    symbol: 'gsx',
    name: 'Gold Secured Currency',
  },
  {
    id: 'gold-utility-token',
    symbol: 'agf',
    name: 'Gold Utility Token',
  },
  {
    id: 'goledo',
    symbol: 'gol',
    name: 'Goledo (OLD)',
  },
  {
    id: 'goledo-2',
    symbol: 'gol',
    name: 'Goledo',
  },
  {
    id: 'golem',
    symbol: 'glm',
    name: 'Golem',
  },
  {
    id: 'golff',
    symbol: 'gof',
    name: 'Golff',
  },
  {
    id: 'golteum',
    symbol: 'gltm',
    name: 'Golteum',
  },
  {
    id: 'gomeat',
    symbol: 'gomt',
    name: 'GoMeat',
  },
  {
    id: 'gone',
    symbol: 'gone',
    name: 'Gone',
  },
  {
    id: 'gooch',
    symbol: 'gooch',
    name: 'Gooch',
  },
  {
    id: 'goodcryptox',
    symbol: 'good',
    name: 'goodcryptoX',
  },
  {
    id: 'good-dog',
    symbol: 'heel',
    name: 'Good Dog',
  },
  {
    id: 'gooddollar',
    symbol: '$g',
    name: 'GoodDollar',
  },
  {
    id: 'good-entry',
    symbol: 'good',
    name: 'Good Entry',
  },
  {
    id: 'good-games-guild',
    symbol: 'ggg',
    name: 'Good Games Guild',
  },
  {
    id: 'good-gensler',
    symbol: 'genslr',
    name: 'Good Gensler',
  },
  {
    id: 'goodmeme',
    symbol: 'gmeme',
    name: 'GoodMeme',
  },
  {
    id: 'good-morning-2',
    symbol: 'gm',
    name: 'Good Morning',
  },
  {
    id: 'good-person-coin',
    symbol: 'gpcx',
    name: 'Good Person Coin',
  },
  {
    id: 'gooeys',
    symbol: 'goo',
    name: 'Gooeys',
  },
  {
    id: 'goofy-inu',
    symbol: 'goofy',
    name: 'Goofy Inu',
  },
  {
    id: 'google-tokenized-stock-defichain',
    symbol: 'dgoogl',
    name: 'Google Tokenized Stock Defichain',
  },
  {
    id: 'goon',
    symbol: 'goon',
    name: 'GOON',
  },
  {
    id: 'goons-of-balatroon',
    symbol: 'gob',
    name: 'Goons of Balatroon',
  },
  {
    id: 'goose-finance',
    symbol: 'egg',
    name: 'Goose Finance',
  },
  {
    id: 'goosefx',
    symbol: 'gofx',
    name: 'GooseFX',
  },
  {
    id: 'goracle-network',
    symbol: 'gora',
    name: 'Gora',
  },
  {
    id: 'goricher',
    symbol: 'goricher',
    name: 'Goricher',
  },
  {
    id: 'gorilla',
    symbol: 'gorilla',
    name: 'Gorilla',
  },
  {
    id: 'gorilla-2',
    symbol: 'gorilla',
    name: 'GORILLA',
  },
  {
    id: 'gorilla-finance',
    symbol: 'gorilla',
    name: 'Gorilla Finance',
  },
  {
    id: 'gotem',
    symbol: 'gotem',
    name: 'gotEM',
  },
  {
    id: 'got-guaranteed',
    symbol: 'gotg',
    name: 'Got Guaranteed',
  },
  {
    id: 'gourmetgalaxy',
    symbol: 'gum',
    name: 'Gourmet Galaxy',
  },
  {
    id: 'governance-algo',
    symbol: 'galgo',
    name: 'Governance Algo',
  },
  {
    id: 'governance-ohm',
    symbol: 'gohm',
    name: 'Governance OHM',
  },
  {
    id: 'governance-xalgo',
    symbol: 'xalgo',
    name: 'Governance xALGO',
  },
  {
    id: 'governance-zil',
    symbol: 'gzil',
    name: 'governance ZIL',
  },
  {
    id: 'governor-dao',
    symbol: 'gdao',
    name: 'Governor DAO',
  },
  {
    id: 'govi',
    symbol: 'govi',
    name: 'CVI',
  },
  {
    id: 'govworld',
    symbol: 'gov',
    name: 'GovWorld',
  },
  {
    id: 'gowithmi',
    symbol: 'gmat',
    name: 'GoWithMi',
  },
  {
    id: 'gowrap',
    symbol: 'gwgw',
    name: 'GoWrap',
  },
  {
    id: 'goyoo',
    symbol: 'goyoo',
    name: 'GoYoo',
  },
  {
    id: 'goztepe-s-k-fan-token',
    symbol: 'goz',
    name: 'Göztepe S.K. Fan Token',
  },
  {
    id: 'gp-coin',
    symbol: 'xgp',
    name: 'GP Coin',
  },
  {
    id: 'gplx',
    symbol: 'gplx',
    name: 'Gplx',
  },
  {
    id: 'gpt-ai',
    symbol: 'ai',
    name: 'GPT AI',
  },
  {
    id: 'gptplus',
    symbol: 'gptplus',
    name: 'GPTPlus',
  },
  {
    id: 'gptverse',
    symbol: 'gptv',
    name: 'GPTVerse',
  },
  {
    id: 'grabcoinclub',
    symbol: 'gc',
    name: 'GrabCoinClub',
  },
  {
    id: 'gracy',
    symbol: 'gracy',
    name: 'Gracy',
  },
  {
    id: 'grai',
    symbol: 'grai',
    name: 'Grai',
  },
  {
    id: 'grail-inu',
    symbol: 'igrail',
    name: 'Grail Inu',
  },
  {
    id: 'gram-gold',
    symbol: 'gramg',
    name: 'Gram Gold',
  },
  {
    id: 'gram-platinum',
    symbol: 'gramp',
    name: 'Gram Platinum',
  },
  {
    id: 'gram-silver',
    symbol: 'grams',
    name: 'Gram Silver',
  },
  {
    id: 'granary',
    symbol: 'grain',
    name: 'Granary',
  },
  {
    id: 'grand-base',
    symbol: 'gb',
    name: 'Grand Base',
  },
  {
    id: 'grand-theft-degens',
    symbol: 'gtd',
    name: 'Grand Theft Degens',
  },
  {
    id: 'grape-2',
    symbol: 'grape',
    name: 'Grape Protocol',
  },
  {
    id: 'grape-2-2',
    symbol: 'grp',
    name: 'Grape',
  },
  {
    id: 'grape-finance',
    symbol: 'grape',
    name: 'Grape Finance',
  },
  {
    id: 'grape-governance-token',
    symbol: 'ggt',
    name: 'Grape Governance Token',
  },
  {
    id: 'grapevine',
    symbol: 'xgrape',
    name: 'GrapeVine',
  },
  {
    id: 'graphite-protocol',
    symbol: 'gp',
    name: 'Graphite Protocol',
  },
  {
    id: 'graphlinq-protocol',
    symbol: 'glq',
    name: 'GraphLinq Protocol',
  },
  {
    id: 'grave',
    symbol: 'grve',
    name: 'Grave',
  },
  {
    id: 'graviocoin',
    symbol: 'gio',
    name: 'Graviocoin',
  },
  {
    id: 'gravitas',
    symbol: 'gravitas',
    name: 'Gravitas',
  },
  {
    id: 'graviton',
    symbol: 'grav',
    name: 'Graviton',
  },
  {
    id: 'gravitron',
    symbol: 'gtron',
    name: 'Gravitron',
  },
  {
    id: 'gravity-bridge-dai',
    symbol: 'g-dai',
    name: 'Gravity Bridge DAI',
  },
  {
    id: 'gravity-bridge-usdc',
    symbol: 'g-usdc',
    name: 'Bridged USD Coin (Gravity Bridge)',
  },
  {
    id: 'gravity-finance',
    symbol: 'gfi',
    name: 'Gravity Finance',
  },
  {
    id: 'greasycex',
    symbol: 'gcx',
    name: 'GreasyCEX',
  },
  {
    id: 'great-bounty-dealer',
    symbol: 'gbd',
    name: 'Great Bounty Dealer',
  },
  {
    id: 'greelance',
    symbol: '$grl',
    name: 'Greelance',
  },
  {
    id: 'greenart-coin',
    symbol: 'gac',
    name: 'Greenart Coin',
  },
  {
    id: 'green-beli',
    symbol: 'grbe',
    name: 'Green Beli',
  },
  {
    id: 'green-ben',
    symbol: 'eben',
    name: 'Green Ben',
  },
  {
    id: 'green-block',
    symbol: 'gbt',
    name: 'Green Block',
  },
  {
    id: 'greendex',
    symbol: 'ged',
    name: 'GreenDex',
  },
  {
    id: 'greenenvcoalition',
    symbol: 'gec',
    name: 'GreenEnvCoalition',
  },
  {
    id: 'greenenvironmentalcoins',
    symbol: 'gec',
    name: 'GreenEnvironmentalCoins',
  },
  {
    id: 'greenercoin',
    symbol: 'gnc',
    name: 'Greenercoin',
  },
  {
    id: 'green-foundation',
    symbol: 'tripx',
    name: 'Green Foundation',
  },
  {
    id: 'greengold',
    symbol: '$greengold',
    name: 'GREENGOLD',
  },
  {
    id: 'green-grass-hopper',
    symbol: '$ggh',
    name: 'Green Grass Hopper',
  },
  {
    id: 'greenheart-cbd',
    symbol: 'cbd',
    name: 'Greenheart CBD',
  },
  {
    id: 'green-life-energy',
    symbol: 'gle',
    name: 'Green Life Energy',
  },
  {
    id: 'green-planet',
    symbol: 'gamma',
    name: 'Green Planet',
  },
  {
    id: 'green-satoshi-token',
    symbol: 'gst-sol',
    name: 'STEPN Green Satoshi Token on Solana',
  },
  {
    id: 'green-satoshi-token-bsc',
    symbol: 'gst-bsc',
    name: 'STEPN Green Satoshi Token on BSC',
  },
  {
    id: 'green-satoshi-token-on-eth',
    symbol: 'gst-eth',
    name: 'STEPN Green Satoshi Token on ETH',
  },
  {
    id: 'green-shiba-inu',
    symbol: 'ginux',
    name: 'Green Shiba Inu',
  },
  {
    id: 'greentrust',
    symbol: 'gnt',
    name: 'GreenTrust',
  },
  {
    id: 'greenworld',
    symbol: 'gwd',
    name: 'GreenWorld',
  },
  {
    id: 'greenzonex',
    symbol: 'gzx',
    name: 'GreenZoneX',
  },
  {
    id: 'greg',
    symbol: 'greg',
    name: 'greg',
  },
  {
    id: 'grelf',
    symbol: 'grelf',
    name: 'GRELF',
  },
  {
    id: 'g-revolution',
    symbol: 'g',
    name: 'G Revolution',
  },
  {
    id: 'greyhound',
    symbol: 'greyhound',
    name: 'Greyhound',
  },
  {
    id: 'gridcoin-research',
    symbol: 'grc',
    name: 'Gridcoin',
  },
  {
    id: 'grid-operating-systems',
    symbol: 'gos',
    name: 'Grid Operating Systems',
  },
  {
    id: 'griffin-art-ecosystem',
    symbol: 'gart',
    name: 'Griffin Art Ecosystem',
  },
  {
    id: 'grimace',
    symbol: 'grimace',
    name: 'Grimace',
  },
  {
    id: 'grimace-coin',
    symbol: 'grimace',
    name: 'Grimace Coin',
  },
  {
    id: 'grim-evo',
    symbol: 'grim evo',
    name: 'Grim EVO',
  },
  {
    id: 'grimoire-finance-token',
    symbol: 'grim',
    name: 'Grimoire Finance Token',
  },
  {
    id: 'grin',
    symbol: 'grin',
    name: 'Grin',
  },
  {
    id: 'grizzly-bot',
    symbol: 'grizzly',
    name: 'Grizzly Bot',
  },
  {
    id: 'grizzly-honey',
    symbol: 'ghny',
    name: 'Grizzly Honey',
  },
  {
    id: 'grn-grid',
    symbol: 'g',
    name: 'GRNGrid',
  },
  {
    id: 'gro-dao-token',
    symbol: 'gro',
    name: 'Gro DAO',
  },
  {
    id: 'groestlcoin',
    symbol: 'grs',
    name: 'Groestlcoin',
  },
  {
    id: 'groge',
    symbol: 'groge',
    name: 'Groge',
  },
  {
    id: 'grok1-5',
    symbol: 'grok1.5',
    name: 'Grok1.5',
  },
  {
    id: 'grok-2',
    symbol: '$grok',
    name: 'Grok',
  },
  {
    id: 'grok-2-0',
    symbol: 'grok2',
    name: 'GROK 2.0',
  },
  {
    id: 'grok2-0',
    symbol: 'grok2.0',
    name: 'Grok2.0',
  },
  {
    id: 'grok2-0-2',
    symbol: 'grok2.0',
    name: 'GROK2.0',
  },
  {
    id: 'grok-3',
    symbol: 'xai',
    name: 'Grok',
  },
  {
    id: 'grok-4',
    symbol: 'grok',
    name: 'GROK',
  },
  {
    id: 'grok-5',
    symbol: 'grok',
    name: 'GROK',
  },
  {
    id: 'grok-6',
    symbol: 'grok',
    name: 'GROK',
  },
  {
    id: 'grok-bank',
    symbol: 'grokbank',
    name: 'Grok Bank',
  },
  {
    id: 'grokbot',
    symbol: 'grokbot',
    name: 'GROKBOT',
  },
  {
    id: 'grokboy',
    symbol: 'grokboy',
    name: 'grokboy',
  },
  {
    id: 'grok-bull',
    symbol: 'grokbull',
    name: 'Grok Bull',
  },
  {
    id: 'grok-by-grok-com',
    symbol: 'grōk',
    name: 'Grok by Grōk.com',
  },
  {
    id: 'grok-ceo',
    symbol: 'grokceo',
    name: 'GROK CEO',
  },
  {
    id: 'grok-chain',
    symbol: 'groc',
    name: 'Grok Chain',
  },
  {
    id: 'grok-codes',
    symbol: 'grok',
    name: 'Grok Codes',
  },
  {
    id: 'grok-community',
    symbol: 'grok cm',
    name: 'Grok Community',
  },
  {
    id: 'grokdoge',
    symbol: 'grokdoge',
    name: 'GrokDoge',
  },
  {
    id: 'grokdogex',
    symbol: 'gdx',
    name: 'GrokDogeX',
  },
  {
    id: 'grok-elo',
    symbol: 'gelo',
    name: 'Grok Elo',
  },
  {
    id: 'grok-girl',
    symbol: 'grokgirl',
    name: 'Grok Girl',
  },
  {
    id: 'grokgrow',
    symbol: 'grokgrow',
    name: 'GrokGrow',
  },
  {
    id: 'grok-heroes',
    symbol: 'grokheroes',
    name: 'GROK heroes',
  },
  {
    id: 'grok-inu',
    symbol: 'grokinu',
    name: 'Grok Inu',
  },
  {
    id: 'grokking',
    symbol: 'grokking',
    name: 'GrokKing',
  },
  {
    id: 'grokky',
    symbol: 'grokky',
    name: 'GroKKy',
  },
  {
    id: 'grok-moon',
    symbol: 'grokmoon',
    name: 'Grok Moon',
  },
  {
    id: 'grok-queen',
    symbol: 'grokqueen',
    name: 'Grok Queen',
  },
  {
    id: 'groktether',
    symbol: 'groktether',
    name: 'GrokTether',
  },
  {
    id: 'grokx',
    symbol: 'grokx',
    name: 'GROKX',
  },
  {
    id: 'grok-x',
    symbol: 'grok x',
    name: 'Grok X',
  },
  {
    id: 'grok-x-ai',
    symbol: 'grok x ai',
    name: 'Grok X Ai',
  },
  {
    id: 'grom',
    symbol: 'gr',
    name: 'GROM',
  },
  {
    id: 'groooook',
    symbol: 'groooook',
    name: 'Groooook',
  },
  {
    id: 'groq',
    symbol: 'groq',
    name: 'GROQ',
  },
  {
    id: 'groupdao',
    symbol: 'gdo',
    name: 'GroupDao',
  },
  {
    id: 'grove',
    symbol: 'grv',
    name: 'GroveCoin',
  },
  {
    id: 'grovecoin-gburn',
    symbol: 'gburn',
    name: 'GBURN',
  },
  {
    id: 'grumpy',
    symbol: 'grum',
    name: 'Grumpy',
  },
  {
    id: 'grumpy-cat-2c33af8d-87a8-4154-b004-0686166bdc45',
    symbol: 'grumpycat',
    name: 'Grumpy Cat',
  },
  {
    id: 'gscarab',
    symbol: 'gscarab',
    name: 'GScarab',
  },
  {
    id: 'gsenetwork',
    symbol: 'gse',
    name: 'GSENetwork',
  },
  {
    id: 'gstcoin',
    symbol: 'gst',
    name: 'GSTCOIN',
  },
  {
    id: 'gt-protocol',
    symbol: 'gtai',
    name: 'GT-Protocol',
  },
  {
    id: 'gu',
    symbol: 'gu',
    name: 'Kugle GU',
  },
  {
    id: 'guacamole',
    symbol: 'guac',
    name: 'Guacamole',
  },
  {
    id: 'guapcoin',
    symbol: 'guap',
    name: 'Guapcoin',
  },
  {
    id: 'guarantee',
    symbol: 'tee',
    name: 'Guarantee',
  },
  {
    id: 'guarded-ether',
    symbol: 'geth',
    name: 'Guarded Ether',
  },
  {
    id: 'guardian-ai',
    symbol: 'guardian',
    name: 'Guardian AI',
  },
  {
    id: 'guardian-token',
    symbol: 'guard',
    name: 'Guardian GUARD',
  },
  {
    id: 'guccipepe',
    symbol: 'guccipepe',
    name: 'GucciPepe',
  },
  {
    id: 'guessonchain',
    symbol: 'guess',
    name: 'GuessOnChain',
  },
  {
    id: 'gui-inu',
    symbol: 'gui',
    name: 'Gui Inu',
  },
  {
    id: 'guildfi',
    symbol: 'gf',
    name: 'GuildFi',
  },
  {
    id: 'guild-of-guardians',
    symbol: 'gog',
    name: 'Guild of Guardians',
  },
  {
    id: 'guiser',
    symbol: 'guise',
    name: 'Guiser',
  },
  {
    id: 'gulfcoin-2',
    symbol: 'gulf',
    name: 'GulfCoin',
  },
  {
    id: 'gumbovile',
    symbol: 'bo',
    name: 'gumBOvile',
  },
  {
    id: 'gunbet',
    symbol: 'gunbet',
    name: 'GunBet',
  },
  {
    id: 'guncoin',
    symbol: 'gun',
    name: 'Guncoin',
  },
  {
    id: 'gunstar-metaverse',
    symbol: 'gsts',
    name: 'Gunstar Metaverse',
  },
  {
    id: 'guppi-finance',
    symbol: 'guppi',
    name: 'Guppi Finance',
  },
  {
    id: 'gursonavax',
    symbol: 'gurs',
    name: 'GursOnAVAX',
  },
  {
    id: 'gusd-token-49eca0d2-b7ae-4a58-bef7-2310688658f2',
    symbol: 'gusd',
    name: 'GUSD Token (Gaura)',
  },
  {
    id: 'guys',
    symbol: 'hole',
    name: 'Hole Guys',
  },
  {
    id: 'guzzler',
    symbol: 'gzlr',
    name: 'Guzzler',
  },
  {
    id: 'gvs',
    symbol: 'gvs',
    name: 'GVS',
  },
  {
    id: 'gxchain',
    symbol: 'gxc',
    name: 'GXChain',
  },
  {
    id: 'gyen',
    symbol: 'gyen',
    name: 'GYEN',
  },
  {
    id: 'gym-ai',
    symbol: 'gym ai',
    name: 'Gym AI',
  },
  {
    id: 'gym-network',
    symbol: 'gymnet',
    name: 'Gym Network',
  },
  {
    id: 'gyoshi',
    symbol: 'gyoshi',
    name: 'GYOSHI',
  },
  {
    id: 'gyoza',
    symbol: 'gyoza',
    name: 'Gyoza',
  },
  {
    id: 'gyroscope',
    symbol: 'gyfi',
    name: 'Gyroscope',
  },
  {
    id: 'gyroscope-gyd',
    symbol: 'gyd',
    name: 'Gyroscope GYD',
  },
  {
    id: 'gyrowin',
    symbol: 'gw',
    name: 'Gyrowin',
  },
  {
    id: 'h2finance',
    symbol: 'yfih2',
    name: 'H2Finance',
  },
  {
    id: 'h2o',
    symbol: 'h2o',
    name: 'H2O',
  },
  {
    id: 'h2o-dao',
    symbol: 'h2o',
    name: 'H2O Dao',
  },
  {
    id: 'h2o-securities',
    symbol: 'h2on',
    name: 'H2O Securities',
  },
  {
    id: 'h3ro3s',
    symbol: 'h3ro3s',
    name: 'H3RO3S',
  },
  {
    id: 'habibi',
    symbol: 'habibi',
    name: 'Habibi',
  },
  {
    id: 'hacash',
    symbol: 'hac',
    name: 'Hacash',
  },
  {
    id: 'hachi',
    symbol: 'hachi',
    name: 'Hachi',
  },
  {
    id: 'hachiko-injective',
    symbol: 'hachi',
    name: 'Hachikō (Injective)',
  },
  {
    id: 'hachikoinu',
    symbol: 'inu',
    name: 'HachikoInu',
  },
  {
    id: 'hackenai',
    symbol: 'hai',
    name: 'Hacken',
  },
  {
    id: 'hades',
    symbol: 'hades',
    name: 'Hades',
  },
  {
    id: 'hades-2',
    symbol: 'hades',
    name: 'Hades',
  },
  {
    id: 'haedal-staked-sui',
    symbol: 'hasui',
    name: 'Haedal Staked SUI',
  },
  {
    id: 'haha',
    symbol: 'haha',
    name: 'HAHA',
  },
  {
    id: 'hairdao',
    symbol: 'hair',
    name: 'HairDAO',
  },
  {
    id: 'hairyplotterftx',
    symbol: 'ftx',
    name: 'HairyPlotterFTX',
  },
  {
    id: 'haki-token',
    symbol: 'haki',
    name: 'HAKI Token',
  },
  {
    id: 'hakka-finance',
    symbol: 'hakka',
    name: 'Hakka Finance',
  },
  {
    id: 'hakuswap',
    symbol: 'haku',
    name: 'HakuSwap',
  },
  {
    id: 'halcyon',
    symbol: 'hal',
    name: 'Halcyon',
  },
  {
    id: 'halfpizza',
    symbol: 'piza',
    name: 'Half Pizza',
  },
  {
    id: 'half-shiba-inu',
    symbol: 'shib0.5',
    name: 'Half Shiba Inu',
  },
  {
    id: 'halisworld',
    symbol: 'hls',
    name: 'HalisWorld',
  },
  {
    id: 'halloween-2',
    symbol: 'halloween',
    name: 'HALLOWEEN',
  },
  {
    id: 'halo-coin',
    symbol: 'halo',
    name: 'Halo Coin',
  },
  {
    id: 'halo-network',
    symbol: 'ho',
    name: 'HALO Network',
  },
  {
    id: 'halonft-art',
    symbol: 'halo',
    name: 'HALOnft.art',
  },
  {
    id: 'hamachi-finance',
    symbol: 'hami',
    name: 'Hamachi Finance',
  },
  {
    id: 'hamster',
    symbol: 'ham',
    name: 'Hamster',
  },
  {
    id: 'hamster-groomers',
    symbol: 'groomer',
    name: 'Hamster Groomers',
  },
  {
    id: 'hamsters',
    symbol: 'hams',
    name: 'Hamsters',
  },
  {
    id: 'hanchain',
    symbol: 'han',
    name: 'HanChain',
  },
  {
    id: 'handle-fi',
    symbol: 'forex',
    name: 'handle.fi',
  },
  {
    id: 'handleusd',
    symbol: 'fxusd',
    name: 'handleUSD',
  },
  {
    id: 'handshake',
    symbol: 'hns',
    name: 'Handshake',
  },
  {
    id: 'handy',
    symbol: 'handy',
    name: 'Handy',
  },
  {
    id: 'handz-of-gods',
    symbol: 'handz',
    name: 'Handz of Gods',
  },
  {
    id: 'hanuman-universe',
    symbol: 'hut',
    name: 'Hanuman Universe',
  },
  {
    id: 'hanu-yokia',
    symbol: 'hanu',
    name: 'Hanu Yokia',
  },
  {
    id: 'hapi',
    symbol: 'hapi',
    name: 'HAPI',
  },
  {
    id: 'happyai',
    symbol: 'smileai',
    name: 'HappyAI',
  },
  {
    id: 'happybear',
    symbol: 'happy',
    name: 'HappyBear',
  },
  {
    id: 'happy-birthday-coin',
    symbol: 'hbdc',
    name: 'Happy Birthday Coin',
  },
  {
    id: 'happycat',
    symbol: 'happycat',
    name: 'HAPPYCAT',
  },
  {
    id: 'happyfans',
    symbol: 'happy',
    name: 'HappyFans',
  },
  {
    id: 'happy-train',
    symbol: 'htr',
    name: 'Happy Train',
  },
  {
    id: 'haram',
    symbol: '$haram',
    name: 'Haram',
  },
  {
    id: 'harambe',
    symbol: 'harambe',
    name: 'Harambe',
  },
  {
    id: 'harambe-2',
    symbol: 'harambe',
    name: 'Harambe on Solana',
  },
  {
    id: 'harambe-wisdom',
    symbol: 'rambe',
    name: 'Harambe Wisdom',
  },
  {
    id: 'hara-token',
    symbol: 'hart',
    name: 'Hara',
  },
  {
    id: 'harbor-2',
    symbol: 'harbor',
    name: 'Harbor Protocol',
  },
  {
    id: 'hard-frog-nick',
    symbol: 'nick',
    name: 'Hard Frog Nick',
  },
  {
    id: 'hare-token',
    symbol: 'hare',
    name: 'Hare [OLD]',
  },
  {
    id: 'harlequins-fan-token',
    symbol: 'quins',
    name: 'Harlequins Fan Token',
  },
  {
    id: 'harmony',
    symbol: 'one',
    name: 'Harmony',
  },
  {
    id: 'haroldcoin',
    symbol: 'hrld',
    name: 'Haroldcoin',
  },
  {
    id: 'harpoon',
    symbol: 'hrp',
    name: 'Harpoon',
  },
  {
    id: 'harrypotterobamainu',
    symbol: 'inu',
    name: 'HarryPotterObamaInu',
  },
  {
    id: 'harrypotterobamapacman8inu',
    symbol: 'xrp',
    name: 'HarryPotterObamaPacMan8Inu',
  },
  {
    id: 'harrypotterobamasonic10in',
    symbol: 'bitcoin',
    name: 'HarryPotterObamaSonic10Inu (ETH)',
  },
  {
    id: 'harrypotterobamasonic10inu',
    symbol: 'bitcoin',
    name: 'HarryPotterObamaSonic10Inu',
  },
  {
    id: 'harrypotterohtanistreetfighter2cultinu',
    symbol: 'xlm',
    name: 'HarryPotterOhtaniStreetFighter2CultInu',
  },
  {
    id: 'harrypotterrussellsonic1inu',
    symbol: 'saitama',
    name: 'HarryPotterRussellSonic1Inu',
  },
  {
    id: 'harrypottertrumphomersimpson777inu',
    symbol: 'ethereum',
    name: 'HarryPotterTrumpHomerSimpson777Inu',
  },
  {
    id: 'harrypotterwifhatmyrowynn',
    symbol: 'solana',
    name: 'HarryPotterWifHatMyroWynn',
  },
  {
    id: 'harvest-finance',
    symbol: 'farm',
    name: 'Harvest Finance',
  },
  {
    id: 'hashbit',
    symbol: 'hbit',
    name: 'HashBit',
  },
  {
    id: 'hash-bridge-oracle',
    symbol: 'hbo',
    name: 'Hash Bridge Oracle',
  },
  {
    id: 'hashcoin',
    symbol: 'hsc',
    name: 'HashCoin',
  },
  {
    id: 'hashflow',
    symbol: 'hft',
    name: 'Hashflow',
  },
  {
    id: 'hashgard',
    symbol: 'gard',
    name: 'Hashgard',
  },
  {
    id: 'hashkey-ecopoints',
    symbol: 'hsk',
    name: 'Hashkey EcoPoints',
  },
  {
    id: 'hashpad',
    symbol: 'hpad',
    name: 'Hashpad',
  },
  {
    id: 'hashpanda',
    symbol: 'panda',
    name: 'HashPanda',
  },
  {
    id: 'hashport-bridged-link',
    symbol: 'link[hts]',
    name: 'Hashport Bridged LINK',
  },
  {
    id: 'hashport-bridged-qnt',
    symbol: 'qnt[hts]',
    name: 'Hashport Bridged QNT',
  },
  {
    id: 'hashport-bridged-wavax',
    symbol: 'wavax[hts]',
    name: 'Hashport Bridged wAVAX',
  },
  {
    id: 'hashtagger',
    symbol: 'mooo',
    name: 'Hashtagger',
  },
  {
    id: 'hashtag-united-fan-token',
    symbol: 'hashtag',
    name: 'Hashtag United Fan Token',
  },
  {
    id: 'hatchypocket',
    symbol: 'hatchy',
    name: 'HatchyPocket',
  },
  {
    id: 'hathor',
    symbol: 'htr',
    name: 'Hathor',
  },
  {
    id: 'hati',
    symbol: 'hati',
    name: 'Hati',
  },
  {
    id: 'hatom',
    symbol: 'htm',
    name: 'Hatom',
  },
  {
    id: 'havah',
    symbol: 'hvh',
    name: 'HAVAH',
  },
  {
    id: 'have-fun-598a6209-8136-4282-a14c-1f2b2b5d0c26',
    symbol: 'hf',
    name: 'Have Fun Token',
  },
  {
    id: 'haven',
    symbol: 'xhv',
    name: 'Haven',
  },
  {
    id: 'haven1',
    symbol: 'h1',
    name: 'Haven1',
  },
  {
    id: 'haven-token',
    symbol: 'haven',
    name: 'Safehaven DeFi',
  },
  {
    id: 'havoc',
    symbol: 'havoc',
    name: 'havoc',
  },
  {
    id: 'havven',
    symbol: 'snx',
    name: 'Synthetix Network',
  },
  {
    id: 'hawex',
    symbol: 'hawex',
    name: 'Hawex',
  },
  {
    id: 'hawksight',
    symbol: 'hawk',
    name: 'Hawksight',
  },
  {
    id: 'haycoin',
    symbol: 'hay',
    name: 'HayCoin',
  },
  {
    id: 'hbarx',
    symbol: 'hbarx',
    name: 'HBARX',
  },
  {
    id: 'h-df0f364f-76a6-47fd-9c38-f8a239a4faad',
    symbol: 'h',
    name: 'H',
  },
  {
    id: 'hdoki',
    symbol: 'oki',
    name: 'HDOKI',
  },
  {
    id: 'headline',
    symbol: 'hdl',
    name: 'Headline',
  },
  {
    id: 'headstarter',
    symbol: 'hst',
    name: 'HeadStarter',
  },
  {
    id: 'health-potion',
    symbol: 'hep',
    name: 'Health Potion',
  },
  {
    id: 'heartx-utility-token',
    symbol: 'hnx',
    name: 'HeartX Utility Token',
  },
  {
    id: 'heavenland-hto',
    symbol: 'hto',
    name: 'Heavenland HTO',
  },
  {
    id: 'hebeblock',
    symbol: 'hebe',
    name: 'HebeBlock',
  },
  {
    id: 'hecofi',
    symbol: 'hfi',
    name: 'HecoFi',
  },
  {
    id: 'heco-peg-bnb',
    symbol: 'bnb',
    name: 'Heco-Peg Binance Coin',
  },
  {
    id: 'heco-peg-xrp',
    symbol: 'xrp',
    name: 'Heco-Peg XRP',
  },
  {
    id: 'hector-dao',
    symbol: 'hec',
    name: 'Hector Network',
  },
  {
    id: 'hedera-hashgraph',
    symbol: 'hbar',
    name: 'Hedera',
  },
  {
    id: 'hedgbet',
    symbol: 'hdg',
    name: 'HedgBet',
  },
  {
    id: 'hedgehog',
    symbol: 'hedgehog',
    name: 'Hedgehog',
  },
  {
    id: 'hedgehog-racer',
    symbol: 'sonic',
    name: 'Hedgehog Racer',
  },
  {
    id: 'hedge-on-sol',
    symbol: 'hedge',
    name: 'HEDGE on Sol',
  },
  {
    id: 'hedgepay',
    symbol: 'hpay',
    name: 'HedgePay',
  },
  {
    id: 'hedget',
    symbol: 'hget',
    name: 'Hedget',
  },
  {
    id: 'hedgetrade',
    symbol: 'hedg',
    name: 'HedgeTrade',
  },
  {
    id: 'hedge-usd',
    symbol: 'ush',
    name: 'Hedge USD',
  },
  {
    id: 'hedpay',
    symbol: 'hdp.ф',
    name: 'HEdpAY',
  },
  {
    id: 'hedron',
    symbol: 'hdrn',
    name: 'Hedron',
  },
  {
    id: 'hefi',
    symbol: 'hefi',
    name: 'HeFi',
  },
  {
    id: 'hegic',
    symbol: 'hegic',
    name: 'Hegic',
  },
  {
    id: 'hegic-yvault',
    symbol: 'yvhegic',
    name: 'HEGIC yVault',
  },
  {
    id: 'helena',
    symbol: 'helena',
    name: 'Helena Financial [OLD]',
  },
  {
    id: 'helicopter-finance',
    symbol: 'copter',
    name: 'Helicopter Finance',
  },
  {
    id: 'heli-doge',
    symbol: 'hd',
    name: 'HELI Doge',
  },
  {
    id: 'helio-protocol-hay',
    symbol: 'hay',
    name: 'Destablecoin HAY',
  },
  {
    id: 'heliswap',
    symbol: 'heli',
    name: 'HeliSwap',
  },
  {
    id: 'heliswap-bridged-usdc-hts',
    symbol: 'usdc[hts]',
    name: 'Bridged USDC (Hashport)',
  },
  {
    id: 'helium',
    symbol: 'hnt',
    name: 'Helium',
  },
  {
    id: 'helium-iot',
    symbol: 'iot',
    name: 'Helium IOT',
  },
  {
    id: 'helium-mobile',
    symbol: 'mobile',
    name: 'Helium Mobile',
  },
  {
    id: 'hellar',
    symbol: 'hel',
    name: 'Hellar',
  },
  {
    id: 'helleniccoin',
    symbol: 'hnc',
    name: 'HNC Coin',
  },
  {
    id: 'hello-art',
    symbol: 'htt',
    name: 'Hello Art',
  },
  {
    id: 'hello-labs',
    symbol: 'hello',
    name: 'HELLO',
  },
  {
    id: 'helmet-insure',
    symbol: 'helmet',
    name: 'Helmet Insure',
  },
  {
    id: 'helpico',
    symbol: 'help',
    name: 'Helpico',
  },
  {
    id: 'helpkidz-coin',
    symbol: 'hkc',
    name: 'HelpKidz Coin',
  },
  {
    id: 'help-the-homeless-coin',
    symbol: 'hth',
    name: 'Help The Homeless Coin',
  },
  {
    id: 'hempcoin-thc',
    symbol: 'thc',
    name: 'Hempcoin',
  },
  {
    id: 'hemule',
    symbol: 'hemule',
    name: 'Hemule',
  },
  {
    id: 'heptafranc',
    symbol: 'hptf',
    name: 'HEPTAFRANC',
  },
  {
    id: 'hepton',
    symbol: 'hte',
    name: 'Hepton',
  },
  {
    id: 'hera-finance',
    symbol: 'hera',
    name: 'Hera Finance',
  },
  {
    id: 'her-ai',
    symbol: 'her',
    name: 'Her.AI',
  },
  {
    id: 'herbalist-token',
    symbol: 'herb',
    name: 'Herbalist',
  },
  {
    id: 'herity-network',
    symbol: 'her',
    name: 'Herity Network',
  },
  {
    id: 'hermes-bot',
    symbol: 'hermes',
    name: 'Hermes Bot',
  },
  {
    id: 'hermes-dao',
    symbol: 'hmx',
    name: 'Hermes DAO',
  },
  {
    id: 'hermes-protocol',
    symbol: 'hermes',
    name: 'Hermes Protocol',
  },
  {
    id: 'hermez-network-token',
    symbol: 'hez',
    name: 'Hermez Network',
  },
  {
    id: 'hermionegrangerclintonamberamyrose9inu',
    symbol: 'tether',
    name: 'HermioneGrangerClintonAmberAmyRose9Inu',
  },
  {
    id: 'hero-arena',
    symbol: 'hera',
    name: 'Hero Arena',
  },
  {
    id: 'hero-blaze-three-kingdoms',
    symbol: 'mudol2',
    name: 'Hero Blaze: Three Kingdoms',
  },
  {
    id: 'hero-cat-token',
    symbol: 'hct',
    name: 'Hero Cat',
  },
  {
    id: 'herocoin',
    symbol: 'play',
    name: 'HEROcoin',
  },
  {
    id: 'heroeschained',
    symbol: 'hec',
    name: 'HeroesChained',
  },
  {
    id: 'heroes-empires',
    symbol: 'he',
    name: 'Heroes & Empires',
  },
  {
    id: 'heroes-of-mavia',
    symbol: 'mavia',
    name: 'Heroes of Mavia',
  },
  {
    id: 'heroes-of-nft',
    symbol: 'hon',
    name: 'Heroes of NFT',
  },
  {
    id: 'heroes-td',
    symbol: 'htd',
    name: 'Heroes TD',
  },
  {
    id: 'heroestd-cgc',
    symbol: 'cgc',
    name: 'HeroesTD CGC',
  },
  {
    id: 'herofi-token-2',
    symbol: 'rofi',
    name: 'HeroFi ROFI',
  },
  {
    id: 'heropark',
    symbol: 'hp',
    name: 'HeroPark',
  },
  {
    id: 'herum',
    symbol: 'ram',
    name: 'Herum',
  },
  {
    id: 'hex',
    symbol: 'hex',
    name: 'HEX',
  },
  {
    id: 'hex-orange-address',
    symbol: 'hoa',
    name: 'Hex Orange Address',
  },
  {
    id: 'hex-pulsechain',
    symbol: 'hex',
    name: 'HEX (PulseChain)',
  },
  {
    id: 'heyflokiai',
    symbol: 'a2e',
    name: 'Hey Floki Ai',
  },
  {
    id: 'hey-reborn-new',
    symbol: 'rb',
    name: 'Hey Reborn [NEW]',
  },
  {
    id: 'hiazuki',
    symbol: 'hiazuki',
    name: 'hiAZUKI',
  },
  {
    id: 'hibakc',
    symbol: 'hibakc',
    name: 'hiBAKC',
  },
  {
    id: 'hibayc',
    symbol: 'hibayc',
    name: 'hiBAYC',
  },
  {
    id: 'hibeanz',
    symbol: 'hibeanz',
    name: 'hiBEANZ',
  },
  {
    id: 'hibiki-run',
    symbol: 'hut',
    name: 'Hibiki Run',
  },
  {
    id: 'hiblocks',
    symbol: 'hibs',
    name: 'Hiblocks',
  },
  {
    id: 'hic-et-nunc-dao',
    symbol: 'hdao',
    name: 'Hic et nunc DAO',
  },
  {
    id: 'hiclonex',
    symbol: 'hiclonex',
    name: 'hiCLONEX',
  },
  {
    id: 'hicoolcats',
    symbol: 'hicoolcats',
    name: 'hiCOOLCATS',
  },
  {
    id: 'hi-dollar',
    symbol: 'hi',
    name: 'hi Dollar',
  },
  {
    id: 'hidoodles',
    symbol: 'hidoodles',
    name: 'hiDOODLES',
  },
  {
    id: 'hiens3',
    symbol: 'hiens3',
    name: 'hiENS3',
  },
  {
    id: 'hiens4',
    symbol: 'hiens4',
    name: 'hiENS4',
  },
  {
    id: 'hifidenza',
    symbol: 'hifidenza',
    name: 'hiFIDENZA',
  },
  {
    id: 'hifi-finance',
    symbol: 'hifi',
    name: 'Hifi Finance',
  },
  {
    id: 'hifluf',
    symbol: 'hifluf',
    name: 'hiFLUF',
  },
  {
    id: 'hifriends',
    symbol: 'hifriends',
    name: 'hiFRIENDS',
  },
  {
    id: 'higazers',
    symbol: 'higazers',
    name: 'hiGAZERS',
  },
  {
    id: 'higher-imo',
    symbol: 'higher',
    name: 'HIgher IMO',
  },
  {
    id: 'highnoon',
    symbol: 'noon',
    name: 'HighNoon',
  },
  {
    id: 'high-performance-blockchain',
    symbol: 'hpb',
    name: 'High Performance Blockchain',
  },
  {
    id: 'high-roller-hippo-clique',
    symbol: 'roll',
    name: 'High Roller Hippo Clique',
  },
  {
    id: 'highstreet',
    symbol: 'high',
    name: 'Highstreet',
  },
  {
    id: 'high-yield-usd',
    symbol: 'hyusd',
    name: 'High Yield USD',
  },
  {
    id: 'high-yield-usd-base',
    symbol: 'hyusd',
    name: 'High Yield USD (Base)',
  },
  {
    id: 'hikari-protocol',
    symbol: 'hikari',
    name: 'Hikari Protocol',
  },
  {
    id: 'hillstone',
    symbol: 'hsf',
    name: 'Hillstone Finance',
  },
  {
    id: 'hilo',
    symbol: 'hilo',
    name: 'HILO',
  },
  {
    id: 'himayc',
    symbol: 'himayc',
    name: 'hiMAYC',
  },
  {
    id: 'himeebits',
    symbol: 'himeebits',
    name: 'hiMEEBITS',
  },
  {
    id: 'himfers',
    symbol: 'himfers',
    name: 'hiMFERS',
  },
  {
    id: 'himoonbirds',
    symbol: 'himoonbirds',
    name: 'hiMOONBIRDS',
  },
  {
    id: 'himo-world',
    symbol: 'himo',
    name: 'Himo World',
  },
  {
    id: 'hinoki-protocol',
    symbol: 'hnk',
    name: 'Hinoki Protocol',
  },
  {
    id: 'hiod',
    symbol: 'hiod',
    name: 'hiOD',
  },
  {
    id: 'hiodbs',
    symbol: 'hiodbs',
    name: 'hiODBS',
  },
  {
    id: 'hipenguins',
    symbol: 'hipenguins',
    name: 'hiPENGUINS',
  },
  {
    id: 'hippopotamus',
    symbol: 'hpo',
    name: 'Hippo Wallet',
  },
  {
    id: 'hippo-token',
    symbol: 'hip',
    name: 'Hippo',
  },
  {
    id: 'hipunks',
    symbol: 'hipunks',
    name: 'hiPunks',
  },
  {
    id: 'hipvpgame',
    symbol: 'hipvp',
    name: 'HiPvPGame',
  },
  {
    id: 'hiram',
    symbol: 'hiram',
    name: 'Hiram',
  },
  {
    id: 'hirenga',
    symbol: 'hirenga',
    name: 'hiRENGA',
  },
  {
    id: 'hirevibes',
    symbol: 'vibes',
    name: 'HireVibes',
  },
  {
    id: 'hisand33',
    symbol: 'hisand33',
    name: 'hiSAND33',
  },
  {
    id: 'hiseals',
    symbol: 'hiseals',
    name: 'hiSEALS',
  },
  {
    id: 'hisquiggle',
    symbol: 'hisquiggle',
    name: 'hiSQUIGGLE',
  },
  {
    id: 'historia',
    symbol: 'hta',
    name: 'Historia',
  },
  {
    id: 'historydao',
    symbol: 'hao',
    name: 'HistoryDAO',
  },
  {
    id: 'hitbtc-token',
    symbol: 'hit',
    name: 'HitBTC',
  },
  {
    id: 'hitchain',
    symbol: 'hit',
    name: 'HitChain',
  },
  {
    id: 'hiundead',
    symbol: 'hiundead',
    name: 'hiUNDEAD',
  },
  {
    id: 'hivalhalla',
    symbol: 'hivalhalla',
    name: 'hiVALHALLA',
  },
  {
    id: 'hive',
    symbol: 'hive',
    name: 'Hive',
  },
  {
    id: 'hive_dollar',
    symbol: 'hbd',
    name: 'Hive Dollar',
  },
  {
    id: 'hive-game-token',
    symbol: 'hgt',
    name: 'Hive Game Token',
  },
  {
    id: 'hive-investments-honey',
    symbol: 'hny',
    name: 'Hive.Investments HONEY',
  },
  {
    id: 'hivemapper',
    symbol: 'honey',
    name: 'Hivemapper',
  },
  {
    id: 'hiveterminal',
    symbol: 'hvn',
    name: 'Hiveterminal',
  },
  {
    id: 'hivewater',
    symbol: 'hivewater',
    name: 'hiveWater',
  },
  {
    id: 'hk-chat',
    symbol: 'yoho',
    name: 'HK.Chat',
  },
  {
    id: 'hmx',
    symbol: 'hmx',
    name: 'HMX',
  },
  {
    id: 'hnb-protocol',
    symbol: 'hnb',
    name: 'HNB Protocol',
  },
  {
    id: 'hobbes',
    symbol: 'hobbes',
    name: 'Hobbes [OLD]',
  },
  {
    id: 'hobbes-new',
    symbol: 'hobbes',
    name: 'Hobbes',
  },
  {
    id: 'hocus-pocus-finance',
    symbol: 'hoc',
    name: 'Hocus Pocus Finance',
  },
  {
    id: 'hodlassets',
    symbol: 'hodl',
    name: 'HodlAssets',
  },
  {
    id: 'hodless-bot',
    symbol: 'hbot',
    name: 'Hodless Bot',
  },
  {
    id: 'hodl-finance',
    symbol: 'hft',
    name: 'Hodl Finance',
  },
  {
    id: 'hodl-token',
    symbol: 'hodl',
    name: 'HODL',
  },
  {
    id: 'hodooi-com',
    symbol: 'hod',
    name: 'HoDooi.com',
  },
  {
    id: 'hoge-finance',
    symbol: 'hoge',
    name: 'Hoge Finance',
  },
  {
    id: 'hoichi',
    symbol: 'hoichi',
    name: 'Hoichi',
  },
  {
    id: 'hokkaido-inu-30bdfab6-dfb9-4fc0-b3c3-02bffe162ee4',
    symbol: 'hoka',
    name: 'Hokkaido Inu',
  },
  {
    id: 'hokkaidu-inu',
    symbol: 'hokk',
    name: 'HOKK Finance',
  },
  {
    id: 'hola-token',
    symbol: '$hola',
    name: 'Hola Token',
  },
  {
    id: 'hold-2',
    symbol: 'earn',
    name: 'HOLD',
  },
  {
    id: 'hold-on-for-dear-life',
    symbol: 'hodl',
    name: 'Hold On for Dear Life',
  },
  {
    id: 'hold-on-for-dear-life-hodl',
    symbol: 'hodl',
    name: 'Hold on for dear life HODL',
  },
  {
    id: 'holdr',
    symbol: 'hldr',
    name: 'Holdr',
  },
  {
    id: 'holdstation',
    symbol: 'hold',
    name: 'Holdstation',
  },
  {
    id: 'holdstation-usd-coin',
    symbol: 'hsusdc',
    name: 'Holdstation USDC',
  },
  {
    id: 'holdstation-utility-gold',
    symbol: 'ugold',
    name: 'Holdstation Utility GOLD',
  },
  {
    id: 'hold-vip',
    symbol: 'hold',
    name: 'Hold VIP',
  },
  {
    id: 'hollygold',
    symbol: 'hgold',
    name: 'HollyGold',
  },
  {
    id: 'hollywood-capital-group-warrior',
    symbol: 'wor',
    name: 'Hollywood Capital Group WARRIOR',
  },
  {
    id: 'hololoot',
    symbol: 'hol',
    name: 'Hololoot',
  },
  {
    id: 'holonus',
    symbol: 'hln',
    name: 'Holonus',
  },
  {
    id: 'holoride',
    symbol: 'ride',
    name: 'holoride',
  },
  {
    id: 'holotoken',
    symbol: 'hot',
    name: 'Holo',
  },
  {
    id: 'holy-bread',
    symbol: 'bread',
    name: 'Holy Bread',
  },
  {
    id: 'holygrail',
    symbol: 'hly',
    name: 'HolyGrail',
  },
  {
    id: 'holygrails-io',
    symbol: 'holy',
    name: 'HolyGrails.io',
  },
  {
    id: 'holyheld-2',
    symbol: 'move',
    name: 'Mover',
  },
  {
    id: 'holy-spirit',
    symbol: 'holy',
    name: 'HOLY SPIRIT',
  },
  {
    id: 'hom',
    symbol: 'hom',
    name: 'Homeety',
  },
  {
    id: 'homer',
    symbol: 'simpson',
    name: 'Homer',
  },
  {
    id: 'homer-2',
    symbol: 'simpson 2.0',
    name: 'Homer',
  },
  {
    id: 'homeros',
    symbol: 'hmr',
    name: 'Homeros',
  },
  {
    id: 'homeunity',
    symbol: 'hrpt',
    name: 'Homeunity',
  },
  {
    id: 'homie-wars',
    symbol: 'homiecoin',
    name: 'Homie Wars',
  },
  {
    id: 'hondaiscoin',
    symbol: 'hndc',
    name: 'HondaisCoin',
  },
  {
    id: 'honest-mining',
    symbol: 'hnst',
    name: 'Honest',
  },
  {
    id: 'honey',
    symbol: 'hny',
    name: 'Honey',
  },
  {
    id: 'honey-finance',
    symbol: 'honey',
    name: 'Honey Finance',
  },
  {
    id: 'honeyland-honey',
    symbol: 'hxd',
    name: 'Honeyland',
  },
  {
    id: 'honeymoon-token',
    symbol: 'moon',
    name: 'HoneyMOON',
  },
  {
    id: 'honey-pot-beekeepers',
    symbol: 'honey',
    name: 'Honey Pot BeeKeepers',
  },
  {
    id: 'honeywood',
    symbol: 'cone',
    name: 'HoneyWood',
  },
  {
    id: 'hongkongdao',
    symbol: 'hkd',
    name: 'HongKongDAO',
  },
  {
    id: 'honk',
    symbol: 'honk',
    name: 'Honk',
  },
  {
    id: 'honor-world-token',
    symbol: 'hwt',
    name: 'Honor World Token',
  },
  {
    id: 'hooked-protocol',
    symbol: 'hook',
    name: 'Hooked Protocol',
  },
  {
    id: 'hope-2',
    symbol: 'hope',
    name: 'Hope.money',
  },
  {
    id: 'hoppers-game',
    symbol: 'fly',
    name: 'Hoppers Game',
  },
  {
    id: 'hop-protocol',
    symbol: 'hop',
    name: 'Hop Protocol',
  },
  {
    id: 'hoppy',
    symbol: 'hop',
    name: 'HOPPY',
  },
  {
    id: 'hoppyinu',
    symbol: 'hoppyinu',
    name: 'HoppyInu',
  },
  {
    id: 'hoppy-token',
    symbol: 'hoppy',
    name: 'Hoppy Token',
  },
  {
    id: 'hopr',
    symbol: 'hopr',
    name: 'HOPR',
  },
  {
    id: 'hord',
    symbol: 'hord',
    name: 'Hord',
  },
  {
    id: 'hord-heth',
    symbol: 'heth',
    name: 'Hord hETH',
  },
  {
    id: 'horizon-2',
    symbol: 'hzn',
    name: 'Horizon',
  },
  {
    id: 'horizon-3',
    symbol: 'hrzn',
    name: 'Horizon',
  },
  {
    id: 'horizon-protocol',
    symbol: 'hzn',
    name: 'Horizon Protocol',
  },
  {
    id: 'horizon-protocol-zbnb',
    symbol: 'zbnb',
    name: 'Horizon Protocol zBNB',
  },
  {
    id: 'horny-hyenas',
    symbol: 'horny',
    name: 'Horny Hyenas',
  },
  {
    id: 'horuspay',
    symbol: 'horus',
    name: 'HorusPay',
  },
  {
    id: 'hosky',
    symbol: 'hosky',
    name: 'Hosky',
  },
  {
    id: 'hot-cross',
    symbol: 'hotcross',
    name: 'Hot Cross',
  },
  {
    id: 'hot-doge',
    symbol: 'hotdoge',
    name: 'HotDoge [OLD]',
  },
  {
    id: 'hotelium',
    symbol: 'htl',
    name: 'Hotelium',
  },
  {
    id: 'hotel-of-secrets',
    symbol: 'hos',
    name: 'Hotel of Secrets',
  },
  {
    id: 'hotmoon',
    symbol: 'hotmoon',
    name: 'HotMoon',
  },
  {
    id: 'hot-n-cold-finance',
    symbol: 'hnc',
    name: "Hot'n Cold Finance",
  },
  {
    id: 'hottel',
    symbol: 'hott',
    name: 'Hottel',
  },
  {
    id: 'hourglass',
    symbol: 'wait',
    name: 'Hourglass',
  },
  {
    id: 'house',
    symbol: 'house',
    name: 'House',
  },
  {
    id: 'house-of-degenerates',
    symbol: 'hod',
    name: 'House of Degenerates',
  },
  {
    id: 'houston-token',
    symbol: 'hou',
    name: 'Houston Token',
  },
  {
    id: 'howinu',
    symbol: 'how',
    name: 'HowInu',
  },
  {
    id: 'howl-city',
    symbol: 'hwl',
    name: 'Howl City',
  },
  {
    id: 'hpohs888inu',
    symbol: 'tether',
    name: 'Hpohs888inu',
  },
  {
    id: 'hrc-crypto',
    symbol: 'hrcc',
    name: 'HRC Crypto',
  },
  {
    id: 'hsac-ordinals',
    symbol: 'hsac',
    name: 'HSAC (Ordinals)',
  },
  {
    id: 'hshare',
    symbol: 'hc',
    name: 'HyperCash',
  },
  {
    id: 'hsuite',
    symbol: 'hsuite',
    name: 'HbarSuite',
  },
  {
    id: 'htm',
    symbol: 'htm',
    name: 'HTM',
  },
  {
    id: 'htmlcoin',
    symbol: 'html',
    name: 'HTMLCOIN',
  },
  {
    id: 'hubble',
    symbol: 'hbb',
    name: 'Hubble',
  },
  {
    id: 'hubin-network',
    symbol: 'hbn',
    name: 'Hubin Network',
  },
  {
    id: 'hubot',
    symbol: 'hbt',
    name: 'HUBOT',
  },
  {
    id: 'hubswirl',
    symbol: 'swirlx',
    name: 'SwirlTokenX',
  },
  {
    id: 'huckleberry',
    symbol: 'finn',
    name: 'Huckleberry',
  },
  {
    id: 'hudex',
    symbol: 'hu',
    name: 'Hudex',
  },
  {
    id: 'hudi',
    symbol: 'hudi',
    name: 'Hudi',
  },
  {
    id: 'huebel-bolt',
    symbol: 'bolt',
    name: 'Huebel Bolt',
  },
  {
    id: 'hughug-coin',
    symbol: 'hghg',
    name: 'HUGHUG',
  },
  {
    id: 'huma-finance',
    symbol: 'huma',
    name: 'Huma Finance',
  },
  {
    id: 'humandao',
    symbol: 'hdao',
    name: 'humanDAO',
  },
  {
    id: 'humaniq',
    symbol: 'hmq',
    name: 'Humaniq',
  },
  {
    id: 'humanize',
    symbol: '$hmt',
    name: 'Humanize',
  },
  {
    id: 'humanode',
    symbol: 'hmnd',
    name: 'Humanode',
  },
  {
    id: 'humanoid-ai',
    symbol: 'humai',
    name: 'Humanoid AI',
  },
  {
    id: 'human-protocol',
    symbol: 'hmt',
    name: 'HUMAN Protocol',
  },
  {
    id: 'humans-ai',
    symbol: 'heart',
    name: 'Humans.ai',
  },
  {
    id: 'humanscape',
    symbol: 'hpo',
    name: 'Hippocrat',
  },
  {
    id: 'humanscarefoundationwater',
    symbol: 'hcfw',
    name: 'HumansCareFoundationWater',
  },
  {
    id: 'hummingbird-egg-token',
    symbol: 'hegg',
    name: 'Hummingbird Egg',
  },
  {
    id: 'hummingbird-finance',
    symbol: 'hmng',
    name: 'Hummingbird Finance [OLD]',
  },
  {
    id: 'hummingbird-finance-2',
    symbol: 'hmng',
    name: 'Hummingbird Finance',
  },
  {
    id: 'hummingbot',
    symbol: 'hbot',
    name: 'Hummingbot',
  },
  {
    id: 'hummus',
    symbol: 'hum',
    name: 'Hummus',
  },
  {
    id: 'hundred',
    symbol: 'hundred',
    name: 'HUNDRED (BSC)',
  },
  {
    id: 'hundred-eth',
    symbol: 'hundred',
    name: 'HUNDRED (ETH)',
  },
  {
    id: 'hundred-finance',
    symbol: 'hnd',
    name: 'Hundred Finance',
  },
  {
    id: 'hungarian-vizsla-inu',
    symbol: 'hvi',
    name: 'Hungarian Vizsla Inu',
  },
  {
    id: 'hunny-love-token',
    symbol: 'love',
    name: 'HunnyDAO',
  },
  {
    id: 'hunter',
    symbol: 'hntr',
    name: 'Hunter Token',
  },
  {
    id: 'hunt-token',
    symbol: 'hunt',
    name: 'Hunt',
  },
  {
    id: 'huny',
    symbol: 'huny',
    name: 'Huny',
  },
  {
    id: 'huobi-bitcoin-cash',
    symbol: 'hbch',
    name: 'Huobi Bitcoin Cash',
  },
  {
    id: 'huobi-btc',
    symbol: 'hbtc',
    name: 'Huobi BTC',
  },
  {
    id: 'huobi-btc-wormhole',
    symbol: 'hbtc',
    name: 'Huobi BTC (Wormhole)',
  },
  {
    id: 'huobi-ethereum',
    symbol: 'heth',
    name: 'Huobi Ethereum',
  },
  {
    id: 'huobi-fil',
    symbol: 'hfil',
    name: 'Huobi FIL',
  },
  {
    id: 'huobi-litecoin',
    symbol: 'hltc',
    name: 'Huobi Litecoin',
  },
  {
    id: 'huobi-polkadot',
    symbol: 'hdot',
    name: 'Huobi Polkadot',
  },
  {
    id: 'huobi-pool-token',
    symbol: 'hpt',
    name: 'Huobi Pool',
  },
  {
    id: 'huobi-token',
    symbol: 'ht',
    name: 'Huobi',
  },
  {
    id: 'huralya',
    symbol: 'lya',
    name: 'Huralya',
  },
  {
    id: 'hurricane-nft',
    symbol: 'nhct',
    name: 'Hurricane NFT',
  },
  {
    id: 'hurricaneswap-token',
    symbol: 'hct',
    name: 'HurricaneSwap',
  },
  {
    id: 'husbant',
    symbol: 'husbant',
    name: 'Husbant',
  },
  {
    id: 'husd',
    symbol: 'husd',
    name: 'HUSD',
  },
  {
    id: 'hush',
    symbol: 'hush',
    name: 'Hush',
  },
  {
    id: 'hush-cash',
    symbol: 'hush',
    name: 'Hush.cash',
  },
  {
    id: 'husky-ai',
    symbol: 'hus',
    name: 'Husky.AI',
  },
  {
    id: 'husky-avax',
    symbol: 'husky',
    name: 'Husky Avax',
  },
  {
    id: 'hustlebot',
    symbol: 'hustle',
    name: 'HustleBot',
  },
  {
    id: 'hxacoin',
    symbol: 'hxa',
    name: 'HXAcoin',
  },
  {
    id: 'hxro',
    symbol: 'hxro',
    name: 'HXRO',
  },
  {
    id: 'hybrid-token-2f302f60-395f-4dd0-8c18-9c5418a61a31',
    symbol: 'hbd',
    name: 'HYBRID TOKEN',
  },
  {
    id: 'hydra',
    symbol: 'hydra',
    name: 'Hydra',
  },
  {
    id: 'hydra-2',
    symbol: 'hydra',
    name: 'Hydra',
  },
  {
    id: 'hydradx',
    symbol: 'hdx',
    name: 'HydraDX',
  },
  {
    id: 'hydra-ecosystem',
    symbol: 'hdr',
    name: 'Hydra Ecosystem',
  },
  {
    id: 'hydranet',
    symbol: 'hdn',
    name: 'Hydranet',
  },
  {
    id: 'hydraverse',
    symbol: 'hdv',
    name: 'Hydraverse',
  },
  {
    id: 'hydt-protocol-hydt',
    symbol: 'hydt',
    name: 'HYDT',
  },
  {
    id: 'hyena-coin',
    symbol: 'hyc',
    name: 'Hyena Coin',
  },
  {
    id: 'hygt',
    symbol: 'hygt',
    name: 'HYGT',
  },
  {
    id: 'hyme',
    symbol: 'hyme',
    name: 'HYME',
  },
  {
    id: 'hype-meme-token',
    symbol: 'hmtt',
    name: 'Hype Meme Token',
  },
  {
    id: 'hyper-2',
    symbol: 'hyper',
    name: 'Hyper',
  },
  {
    id: 'hyperbc',
    symbol: 'hbt',
    name: 'HyperBC',
  },
  {
    id: 'hyperbolic-protocol',
    symbol: 'hype',
    name: 'Hyperbolic Protocol',
  },
  {
    id: 'hypercent',
    symbol: 'hype',
    name: 'Hypercent',
  },
  {
    id: 'hyperchainx',
    symbol: 'hyper',
    name: 'HyperChainX',
  },
  {
    id: 'hypercomic',
    symbol: 'hyco',
    name: 'HYPERCOMIC',
  },
  {
    id: 'hypercycle',
    symbol: 'hypc',
    name: 'HyperCycle',
  },
  {
    id: 'hyperdao',
    symbol: 'hdao',
    name: 'HyperDAO',
  },
  {
    id: 'hypergpt',
    symbol: 'hgpt',
    name: 'HyperGPT',
  },
  {
    id: 'hyper-pay',
    symbol: 'hpy',
    name: 'Hyper Pay',
  },
  {
    id: 'hypersign-identity-token',
    symbol: 'hid',
    name: 'Hypersign Identity',
  },
  {
    id: 'hyperstake',
    symbol: 'hyp',
    name: 'Element',
  },
  {
    id: 'hypra',
    symbol: 'hyp',
    name: 'HYPRA',
  },
  {
    id: 'hypr-network',
    symbol: 'hypr',
    name: 'Hypr Network',
  },
  {
    id: 'hyruleswap',
    symbol: 'rupee',
    name: 'HyruleSwap',
  },
  {
    id: 'hytopia',
    symbol: 'topia',
    name: 'Hytopia',
  },
  {
    id: 'hyve',
    symbol: 'hyve',
    name: 'Hyve',
  },
  {
    id: 'hzm-coin',
    symbol: 'hzm',
    name: 'HZM Coin',
  },
  {
    id: 'iagon',
    symbol: 'iag',
    name: 'Iagon',
  },
  {
    id: 'iamx',
    symbol: 'iamx',
    name: 'IAMX',
  },
  {
    id: 'iassets',
    symbol: 'asset',
    name: 'iAssets',
  },
  {
    id: 'iazuki',
    symbol: 'iazuki',
    name: 'IAzuki',
  },
  {
    id: 'ibc-index',
    symbol: 'ibcx',
    name: 'IBC Index',
  },
  {
    id: 'ibg-token',
    symbol: 'ibg',
    name: 'iBG Finance (BSC)',
  },
  {
    id: 'ibithub',
    symbol: 'ibh',
    name: 'iBitHub',
  },
  {
    id: 'ibs',
    symbol: 'ibs',
    name: 'IBS',
  },
  {
    id: 'ibtc-2',
    symbol: 'ibtc',
    name: 'iBTC',
  },
  {
    id: 'ibuffer-token',
    symbol: 'bfr',
    name: 'Buffer Token',
  },
  {
    id: 'ice',
    symbol: 'ice',
    name: 'Ice Network',
  },
  {
    id: 'icebergy',
    symbol: 'ice',
    name: 'Icebergy (Friend.tech)',
  },
  {
    id: 'icecream',
    symbol: 'ice',
    name: 'IceCreamSwap',
  },
  {
    id: 'ice-net',
    symbol: 'ice',
    name: 'ICE NET',
  },
  {
    id: 'ice-token',
    symbol: 'ice',
    name: 'Popsicle Finance',
  },
  {
    id: 'ic-ghost',
    symbol: 'ghost',
    name: 'IC Ghost',
  },
  {
    id: 'ichi-farm',
    symbol: 'ichi',
    name: 'ICHI',
  },
  {
    id: 'iclick-inu',
    symbol: 'iclick',
    name: 'ICLICK Inu',
  },
  {
    id: 'icomex',
    symbol: 'icmx',
    name: 'iCOMEX',
  },
  {
    id: 'icommunity',
    symbol: 'icom',
    name: 'iCommunity',
  },
  {
    id: 'icon',
    symbol: 'icx',
    name: 'ICON',
  },
  {
    id: 'iconiq-lab-token',
    symbol: 'icnq',
    name: 'Deutsche Digital Assets',
  },
  {
    id: 'icosa',
    symbol: 'icsa',
    name: 'Icosa',
  },
  {
    id: 'icpi',
    symbol: 'icpi',
    name: 'ICPI',
  },
  {
    id: 'icrypex-token',
    symbol: 'icpx',
    name: 'Icrypex Token',
  },
  {
    id: 'ictech',
    symbol: 'ict',
    name: 'ICTech',
  },
  {
    id: 'ic-x',
    symbol: 'icx',
    name: 'IC-X',
  },
  {
    id: 'icy',
    symbol: 'ic',
    name: 'Icy',
  },
  {
    id: 'idavoll-network',
    symbol: 'idv',
    name: 'Idavoll DAO',
  },
  {
    id: 'ideachain',
    symbol: 'ich',
    name: 'IdeaChain',
  },
  {
    id: 'ideal-opportunities',
    symbol: 'io',
    name: 'IO',
  },
  {
    id: 'ideaology',
    symbol: 'idea',
    name: 'Ideaology',
  },
  {
    id: 'ideas',
    symbol: 'ideas',
    name: 'IDEAS',
  },
  {
    id: 'idefiyieldprotocol',
    symbol: 'idyp',
    name: 'iDypius',
  },
  {
    id: 'idena',
    symbol: 'idna',
    name: 'Idena',
  },
  {
    id: 'ide-x-ai',
    symbol: 'ide',
    name: 'Ide.x.ai',
  },
  {
    id: 'idexo-token',
    symbol: 'ido',
    name: 'Idexo',
  },
  {
    id: 'idia',
    symbol: 'idia',
    name: 'Impossible Finance Launchpad',
  },
  {
    id: 'idle',
    symbol: 'idle',
    name: 'IDLE',
  },
  {
    id: 'idle-dai-risk-adjusted',
    symbol: 'idledaisafe',
    name: 'IdleDAI (Risk Adjusted)',
  },
  {
    id: 'idle-dai-yield',
    symbol: 'idledaiyield',
    name: 'IdleDAI (Best Yield)',
  },
  {
    id: 'idle-susd-yield',
    symbol: 'idlesusdyield',
    name: 'IdleSUSD (Yield)',
  },
  {
    id: 'idle-tusd-yield',
    symbol: 'idletusdyield',
    name: 'IdleTUSD (Best Yield)',
  },
  {
    id: 'idle-usdc-risk-adjusted',
    symbol: 'idleusdcsafe',
    name: 'IdleUSDC (Risk Adjusted)',
  },
  {
    id: 'idle-usdc-yield',
    symbol: 'idleusdcyield',
    name: 'IdleUSDC (Yield)',
  },
  {
    id: 'idle-usdt-risk-adjusted',
    symbol: 'idleusdtsafe',
    name: 'IdleUSDT (Risk Adjusted)',
  },
  {
    id: 'idle-usdt-yield',
    symbol: 'idleusdtyield',
    name: 'IdleUSDT (Yield)',
  },
  {
    id: 'idle-wbtc-yield',
    symbol: 'idlewbtcyield',
    name: 'IdleWBTC (Best Yield)',
  },
  {
    id: 'idm-token',
    symbol: 'idm',
    name: 'IDM Coop',
  },
  {
    id: 'idoodles',
    symbol: 'idoodles',
    name: 'IDOODLES',
  },
  {
    id: 'idrx',
    symbol: 'idrx',
    name: 'IDRX',
  },
  {
    id: 'ierc-20',
    symbol: '',
    name: 'IERC-20',
  },
  {
    id: 'iethereum',
    symbol: 'ieth',
    name: 'iEthereum',
  },
  {
    id: 'iexec-rlc',
    symbol: 'rlc',
    name: 'iExec RLC',
  },
  {
    id: 'ifarm',
    symbol: 'ifarm',
    name: 'iFARM',
  },
  {
    id: 'ifortune',
    symbol: 'ifc',
    name: 'iFortune',
  },
  {
    id: 'igames',
    symbol: 'igs',
    name: 'iGameS',
  },
  {
    id: 'ignis',
    symbol: 'ignis',
    name: 'Ignis',
  },
  {
    id: 'ignite-the-chain',
    symbol: 'ignt',
    name: 'Ignite The Chain',
  },
  {
    id: 'ignore-fud',
    symbol: '4token',
    name: 'Ignore Fud',
  },
  {
    id: 'iguverse',
    symbol: 'igup',
    name: 'IguVerse IGUP',
  },
  {
    id: 'iguverse-igu',
    symbol: 'igu',
    name: 'IguVerse IGU',
  },
  {
    id: 'iht-real-estate-protocol',
    symbol: 'iht',
    name: 'IHT Real Estate Protocol',
  },
  {
    id: 'iinjaz',
    symbol: 'ijz',
    name: 'iinjaz',
  },
  {
    id: 'ijascoin',
    symbol: 'ijc',
    name: 'IjasCoin',
  },
  {
    id: 'ikolf',
    symbol: 'ikolf',
    name: 'IKOLF',
  },
  {
    id: 'ilcapo',
    symbol: 'capo',
    name: 'ILCAPO',
  },
  {
    id: 'ilcoin',
    symbol: 'ilc',
    name: 'ILCOIN',
  },
  {
    id: 'illumicati',
    symbol: 'milk',
    name: 'Illumicati',
  },
  {
    id: 'illuminati',
    symbol: 'ilum',
    name: 'Illuminati',
  },
  {
    id: 'illuminaticoin',
    symbol: 'nati',
    name: 'IlluminatiCoin',
  },
  {
    id: 'illuvia',
    symbol: 'illuvia',
    name: 'illuvia',
  },
  {
    id: 'illuvium',
    symbol: 'ilv',
    name: 'Illuvium',
  },
  {
    id: 'i-love-snoopy',
    symbol: 'lovesnoopy',
    name: 'I LOVE SNOOPY',
  },
  {
    id: 'ilus-coin',
    symbol: 'ilus',
    name: 'ILUS Coin',
  },
  {
    id: 'imagecoin',
    symbol: 'img',
    name: 'ImageCoin',
  },
  {
    id: 'imayc',
    symbol: 'imayc',
    name: 'IMAYC',
  },
  {
    id: 'ime-lab',
    symbol: 'lime',
    name: 'iMe Lab',
  },
  {
    id: 'imgnai',
    symbol: 'imgnai',
    name: 'Image Generation AI',
  },
  {
    id: 'immortaldao',
    symbol: 'immo',
    name: 'ImmortalDAO',
  },
  {
    id: 'immortl',
    symbol: 'imrtl',
    name: 'Immortl (OLD)',
  },
  {
    id: 'immutable',
    symbol: 'dara',
    name: 'Immutable',
  },
  {
    id: 'immutable-x',
    symbol: 'imx',
    name: 'Immutable',
  },
  {
    id: 'imo',
    symbol: 'imo',
    name: 'IMO',
  },
  {
    id: 'imov',
    symbol: 'imt',
    name: 'IMOV',
  },
  {
    id: 'impactmarket',
    symbol: 'pact',
    name: 'impactMarket',
  },
  {
    id: 'imperium-empires',
    symbol: 'ime',
    name: 'Imperium Empires',
  },
  {
    id: 'impermax-2',
    symbol: 'ibex',
    name: 'Impermax',
  },
  {
    id: 'impls-finance',
    symbol: 'impls',
    name: 'IMPLS Finance',
  },
  {
    id: 'impossible-finance',
    symbol: 'if',
    name: 'Impossible Finance',
  },
  {
    id: 'impostors-blood',
    symbol: 'blood',
    name: 'Impostors Blood',
  },
  {
    id: 'impt',
    symbol: 'impt',
    name: 'IMPT',
  },
  {
    id: 'inae',
    symbol: 'inae',
    name: 'iNAE',
  },
  {
    id: 'inception-restaked-sweth',
    symbol: 'insweth',
    name: 'Inception Restaked swETH',
  },
  {
    id: 'inception-restaked-wbeth',
    symbol: 'inwbeth',
    name: 'Inception Restaked wbETH',
  },
  {
    id: 'inci-token',
    symbol: 'inci',
    name: 'Inci',
  },
  {
    id: 'incognito-2',
    symbol: 'prv',
    name: 'Incognito',
  },
  {
    id: 'indestcoin',
    symbol: 'intd',
    name: 'INDESTCOIN',
  },
  {
    id: 'index20',
    symbol: 'i20',
    name: 'INDEX20',
  },
  {
    id: 'index-avalanche-defi',
    symbol: 'ixad',
    name: 'Index Avalanche DeFi',
  },
  {
    id: 'index-coop-coindesk-eth-trend-index',
    symbol: 'cdeti',
    name: 'Index Coop CoinDesk ETH Trend Index',
  },
  {
    id: 'index-cooperative',
    symbol: 'index',
    name: 'Index Cooperative',
  },
  {
    id: 'index-coop-eth-2x-flexible-leverage-index',
    symbol: 'eth2x-fli-p',
    name: 'Index Coop - ETH 2x Flexible Leverage Index (Polygon)',
  },
  {
    id: 'index-coop-large-cap',
    symbol: 'ic21',
    name: 'Index Coop Large Cap Index',
  },
  {
    id: 'index-coop-matic-2x-flexible-leverage-index',
    symbol: 'matic2x-fli-p',
    name: 'Index Coop - MATIC 2x Flexible Leverage Index',
  },
  {
    id: 'indexed-finance',
    symbol: 'ndx',
    name: 'Indexed Finance',
  },
  {
    id: 'indian-shiba-inu',
    symbol: 'indshib',
    name: 'Indian Shiba Inu',
  },
  {
    id: 'indigg',
    symbol: 'indi',
    name: 'IndiGG',
  },
  {
    id: 'indigo-dao-governance-token',
    symbol: 'indy',
    name: 'Indigo Protocol',
  },
  {
    id: 'indigo-protocol-ieth',
    symbol: 'ieth',
    name: 'Indigo Protocol iETH',
  },
  {
    id: 'inedible-coin',
    symbol: 'inedible',
    name: 'Inedible Coin',
  },
  {
    id: 'inery',
    symbol: '$inr',
    name: 'Inery',
  },
  {
    id: 'infiblue-world',
    symbol: 'monie',
    name: 'Infiblue World',
  },
  {
    id: 'infinitee',
    symbol: 'inftee',
    name: 'Infinitee',
  },
  {
    id: 'infinitorr',
    symbol: 'torr',
    name: 'InfiniTORR',
  },
  {
    id: 'infinity-angel',
    symbol: 'ing',
    name: 'Infinity Games',
  },
  {
    id: 'infinitybit-token',
    symbol: 'ibit',
    name: 'InfinityBit Token',
  },
  {
    id: 'infinity-box',
    symbol: 'ibox',
    name: 'Infinity Box',
  },
  {
    id: 'infinity-network',
    symbol: 'in',
    name: 'Infinity Network',
  },
  {
    id: 'infinity-pad-2',
    symbol: 'ipad',
    name: 'Infinity PAD',
  },
  {
    id: 'infinity-protocol',
    symbol: 'infinity',
    name: 'Infinity Protocol',
  },
  {
    id: 'infinity-rocket-token',
    symbol: 'irt',
    name: 'Infinity Rocket',
  },
  {
    id: 'infinity-skies',
    symbol: 'isky',
    name: 'Infinity Skies',
  },
  {
    id: 'inflation-hedging-coin',
    symbol: 'ihc',
    name: 'Inflation Hedging Coin',
  },
  {
    id: 'infliv',
    symbol: 'ifv',
    name: 'INFLIV',
  },
  {
    id: 'inftspace',
    symbol: 'ins',
    name: 'iNFTspace',
  },
  {
    id: 'inheritance-art',
    symbol: 'iai',
    name: 'inheritance Art',
  },
  {
    id: 'init',
    symbol: 'init',
    name: 'Inite',
  },
  {
    id: 'injective-pepes',
    symbol: '$ipepe',
    name: 'Injective Pepes',
  },
  {
    id: 'injective-protocol',
    symbol: 'inj',
    name: 'Injective',
  },
  {
    id: 'injineer',
    symbol: 'injer',
    name: 'Injineer',
  },
  {
    id: 'inj-inu',
    symbol: '$injinu',
    name: 'INJ INU',
  },
  {
    id: 'ink',
    symbol: 'ink',
    name: 'Ink',
  },
  {
    id: 'ink-2',
    symbol: 'ink',
    name: 'INK',
  },
  {
    id: 'ink-fantom',
    symbol: 'ink',
    name: 'Ink Fantom',
  },
  {
    id: 'ink-finance',
    symbol: 'quill',
    name: 'Ink Finance',
  },
  {
    id: 'innitforthetech',
    symbol: 'innit',
    name: 'InnitForTheTECH',
  },
  {
    id: 'innova',
    symbol: 'inn',
    name: 'Innova',
  },
  {
    id: 'innova-defi',
    symbol: '$innova',
    name: 'Innova DeFi',
  },
  {
    id: 'innovative-bioresearch',
    symbol: 'innbc',
    name: 'Innovative Bioresearch Coin',
  },
  {
    id: 'inofi',
    symbol: 'fon',
    name: 'INOFi',
  },
  {
    id: 'inoovi',
    symbol: 'ivi',
    name: 'Inoovi',
  },
  {
    id: 'in-pepe-we-trust',
    symbol: 'ipwt',
    name: 'IN PEPE WE TRUST',
  },
  {
    id: 'inpulse-x-2',
    symbol: 'ipx',
    name: 'InpulseX',
  },
  {
    id: 'ins3-finance-coin',
    symbol: 'itf',
    name: 'Ins3.Finance Coin',
  },
  {
    id: 'insc',
    symbol: 'insc',
    name: 'INSC (Ordinals)',
  },
  {
    id: 'inscription-dao',
    symbol: 'icda',
    name: 'Inscription DAO',
  },
  {
    id: 'insights-network',
    symbol: 'instar',
    name: 'INSTAR',
  },
  {
    id: 'insightx',
    symbol: 'inx',
    name: 'InsightX',
  },
  {
    id: 'insolvent',
    symbol: 'insolvent',
    name: 'inSOLvent',
  },
  {
    id: 'inspect',
    symbol: 'insp',
    name: 'Inspect',
  },
  {
    id: 'insrt-finance',
    symbol: '$insrt',
    name: 'Insrt Finance',
  },
  {
    id: 'instabridge-wrapped-eth',
    symbol: 'xeth',
    name: 'Instabridge Wrapped ETH (Radix)',
  },
  {
    id: 'instabridge-wrapped-usdt',
    symbol: 'xusdt',
    name: 'Instabridge Wrapped USDT (Radix)',
  },
  {
    id: 'instadapp',
    symbol: 'inst',
    name: 'Instadapp',
  },
  {
    id: 'instadapp-dai',
    symbol: 'idai',
    name: 'Instadapp DAI',
  },
  {
    id: 'instadapp-eth',
    symbol: 'ieth',
    name: 'iETH v1',
  },
  {
    id: 'instadapp-eth-v2',
    symbol: 'ieth v2',
    name: 'Instadapp ETH v2',
  },
  {
    id: 'instadapp-usdc',
    symbol: 'iusdc',
    name: 'Instadapp USDC',
  },
  {
    id: 'instadapp-wbtc',
    symbol: 'iwbtc',
    name: 'Instadapp WBTC',
  },
  {
    id: 'instrumental-finance',
    symbol: 'strm',
    name: 'Instrumental Finance',
  },
  {
    id: 'insula',
    symbol: 'isla',
    name: 'Insula',
  },
  {
    id: 'insurabler',
    symbol: 'insr',
    name: 'Insurabler',
  },
  {
    id: 'insurace',
    symbol: 'insur',
    name: 'InsurAce',
  },
  {
    id: 'insure',
    symbol: 'sure',
    name: 'inSure DeFi',
  },
  {
    id: 'insureum',
    symbol: 'isr',
    name: 'Insureum',
  },
  {
    id: 'insurex',
    symbol: 'ixt',
    name: 'iXledger',
  },
  {
    id: 'integral',
    symbol: 'itgr',
    name: 'Integral',
  },
  {
    id: 'integritee',
    symbol: 'teer',
    name: 'Integritee',
  },
  {
    id: 'intelligence-on-chain',
    symbol: 'ioc',
    name: 'Intelligence On Chain',
  },
  {
    id: 'intellix',
    symbol: 'itx',
    name: 'Intellix',
  },
  {
    id: 'intelly',
    symbol: 'intl',
    name: 'Intelly',
  },
  {
    id: 'interactwith-token',
    symbol: 'inter',
    name: 'InteractWith',
  },
  {
    id: 'interbtc',
    symbol: 'ibtc',
    name: 'interBTC',
  },
  {
    id: 'interest-bearing-eth',
    symbol: 'ibeth',
    name: 'Interest Bearing ETH',
  },
  {
    id: 'interest-compounding-eth-index',
    symbol: 'iceth',
    name: 'Interest Compounding ETH Index',
  },
  {
    id: 'interest-protocol-token',
    symbol: 'ipt',
    name: 'Interest Protocol Token',
  },
  {
    id: 'interfinex-bills',
    symbol: 'ifex',
    name: 'Interfinex Bills',
  },
  {
    id: 'interlay',
    symbol: 'intr',
    name: 'Interlay',
  },
  {
    id: 'inter-milan-fan-token',
    symbol: 'inter',
    name: 'Inter Milan Fan Token',
  },
  {
    id: 'international-stable-currency',
    symbol: 'isc',
    name: 'International Stable Currency',
  },
  {
    id: 'internet-computer',
    symbol: 'icp',
    name: 'Internet Computer',
  },
  {
    id: 'internet-computer-technology',
    symbol: 'ict',
    name: 'Internet Computer Technology',
  },
  {
    id: 'internet-doge',
    symbol: 'idoge',
    name: 'Internet Doge',
  },
  {
    id: 'internet-money',
    symbol: 'im',
    name: 'Internet Money (ETH)',
  },
  {
    id: 'internet-money-bsc',
    symbol: 'im',
    name: 'Internet Money (BSC)',
  },
  {
    id: 'internet-of-energy-network',
    symbol: 'ioen',
    name: 'Internet of Energy Network',
  },
  {
    id: 'interns',
    symbol: 'intern',
    name: 'Interns',
  },
  {
    id: 'internxt',
    symbol: 'inxt',
    name: 'Internxt',
  },
  {
    id: 'interport-token',
    symbol: 'itp',
    name: 'Interport Token',
  },
  {
    id: 'inter-stable-token',
    symbol: 'ist',
    name: 'Inter Stable Token',
  },
  {
    id: 'interstellar-domain-order',
    symbol: 'ido',
    name: 'Interstellar Domain Order',
  },
  {
    id: 'intexcoin',
    symbol: 'intx',
    name: 'INTEXCOIN',
  },
  {
    id: 'intoverse',
    symbol: 'tox',
    name: 'INTOverse',
  },
  {
    id: 'intrepid-token',
    symbol: 'int',
    name: 'Intrepid Token',
  },
  {
    id: 'intrinsic-number-up',
    symbol: 'inu',
    name: 'Intrinsic Number Up',
  },
  {
    id: 'inu',
    symbol: 'inu',
    name: 'Inu.',
  },
  {
    id: 'inu-inu',
    symbol: 'inuinu',
    name: 'Inu Inu',
  },
  {
    id: 'inuko-finance',
    symbol: 'inuko',
    name: 'Inuko Finance',
  },
  {
    id: 'inu-token-63736428-0d5c-4281-8038-3e62c35ac278',
    symbol: 'inu',
    name: 'Inu Token',
  },
  {
    id: 'invectai',
    symbol: 'invectai',
    name: 'InvectAI',
  },
  {
    id: 'inverse-ethereum-volatility-index-token',
    symbol: 'iethv',
    name: 'Inverse Ethereum Volatility Index Token',
  },
  {
    id: 'inverse-finance',
    symbol: 'inv',
    name: 'Inverse Finance',
  },
  {
    id: 'invest-club-global',
    symbol: 'icg',
    name: 'Invest Club Global',
  },
  {
    id: 'investdex',
    symbol: 'invest',
    name: 'InvestDex',
  },
  {
    id: 'invi-token',
    symbol: 'invi',
    name: 'INVI',
  },
  {
    id: 'invoke',
    symbol: 'iv',
    name: 'Invoker',
  },
  {
    id: 'invox-finance',
    symbol: 'invox',
    name: 'Invox Finance',
  },
  {
    id: 'inx-token-2',
    symbol: 'inx',
    name: 'INX Token',
  },
  {
    id: 'iobusd',
    symbol: 'iobusd',
    name: 'ioBUSD',
  },
  {
    id: 'ioeth',
    symbol: 'ioeth',
    name: 'ioETH',
  },
  {
    id: 'ioex',
    symbol: 'ioex',
    name: 'ioeX',
  },
  {
    id: 'ioi-token',
    symbol: 'ioi',
    name: 'IOI Token',
  },
  {
    id: 'ion',
    symbol: 'ion',
    name: 'Ion',
  },
  {
    id: 'iostoken',
    symbol: 'iost',
    name: 'IOST',
  },
  {
    id: 'iota',
    symbol: 'iota',
    name: 'IOTA',
  },
  {
    id: 'iotec-finance',
    symbol: 'iot',
    name: 'Iotec Finance',
  },
  {
    id: 'iotex',
    symbol: 'iotx',
    name: 'IoTeX',
  },
  {
    id: 'iotex-monster-go',
    symbol: 'mtgo',
    name: 'Iotex Monster Go',
  },
  {
    id: 'iotexpad',
    symbol: 'tex',
    name: 'IoTeXPad',
  },
  {
    id: 'iotexshiba',
    symbol: 'ioshib',
    name: 'IoTexShiba',
  },
  {
    id: 'iotube-bridged-geod-iotex',
    symbol: 'geod',
    name: 'ioTube Bridged GEOD (IoTeX)',
  },
  {
    id: 'iotube-bridged-wifi-iotex',
    symbol: 'wifi',
    name: 'ioTube Bridged WIFI (IoTeX)',
  },
  {
    id: 'iotube-bridged-wnt-iotex',
    symbol: 'wnt',
    name: 'ioTube Bridged WNT (IoTeX)',
  },
  {
    id: 'iotube-bridged-xnet-iotex',
    symbol: 'xnet',
    name: 'ioTube Bridged XNET (IoTeX)',
  },
  {
    id: 'iousdc',
    symbol: 'iousdc',
    name: 'Bridged USD Coin (IoTeX)',
  },
  {
    id: 'iousdt',
    symbol: 'iousdt',
    name: 'Bridged Tether (IoTeX)',
  },
  {
    id: 'iowbtc',
    symbol: 'iowbtc',
    name: 'ioWBTC',
  },
  {
    id: 'iown',
    symbol: 'iown',
    name: 'iOWN',
  },
  {
    id: 'ipi-shorter',
    symbol: 'ipistr',
    name: 'Shorter Finance',
  },
  {
    id: 'ipmb',
    symbol: 'ipmb',
    name: 'IPMB',
  },
  {
    id: 'ipor',
    symbol: 'ipor',
    name: 'IPOR',
  },
  {
    id: 'ipulse',
    symbol: 'pls',
    name: 'iPulse',
  },
  {
    id: 'ipverse',
    symbol: 'ipv',
    name: 'IPVERSE',
  },
  {
    id: 'ipx-token',
    symbol: 'ipx',
    name: 'Tachyon Protocol',
  },
  {
    id: 'iqeon',
    symbol: 'iqn',
    name: 'IQeon',
  },
  {
    id: 'iq-protocol',
    symbol: 'iqt',
    name: 'IQ Protocol',
  },
  {
    id: 'irena-green-energy',
    symbol: 'irena',
    name: 'Irena Coin Apps',
  },
  {
    id: 'iridium',
    symbol: 'ird',
    name: 'Iridium',
  },
  {
    id: 'iris-ecosystem',
    symbol: 'iristoken',
    name: 'Iris Ecosystem',
  },
  {
    id: 'iris-network',
    symbol: 'iris',
    name: 'IRISnet',
  },
  {
    id: 'iris-token-2',
    symbol: 'iris',
    name: 'Iris',
  },
  {
    id: 'iron-bank',
    symbol: 'ib',
    name: 'Iron Bank',
  },
  {
    id: 'iron-bank-euro',
    symbol: 'ibeur',
    name: 'Iron Bank EUR',
  },
  {
    id: 'iron-bsc',
    symbol: 'iron',
    name: 'Iron BSC',
  },
  {
    id: 'iron-finance',
    symbol: 'ice',
    name: 'Iron Finance',
  },
  {
    id: 'iron-fish',
    symbol: 'iron',
    name: 'Iron Fish',
  },
  {
    id: 'iron-stablecoin',
    symbol: 'iron',
    name: 'Iron',
  },
  {
    id: 'iron-titanium-token',
    symbol: 'titan',
    name: 'IRON Titanium',
  },
  {
    id: 'isengard-nft-marketplace',
    symbol: 'iset-84e55e',
    name: 'Isengard NFT Marketplace',
  },
  {
    id: 'ishares-msci-world-etf-tokenized-stock-defichain',
    symbol: 'durth',
    name: 'iShares MSCI World ETF Tokenized Stock Defichain',
  },
  {
    id: 'ishib',
    symbol: 'ishib',
    name: 'iSHIB',
  },
  {
    id: 'ishook',
    symbol: 'shk',
    name: 'iShook',
  },
  {
    id: 'isiklar-coin',
    symbol: 'isikc',
    name: 'Isiklar Coin',
  },
  {
    id: 'iskra-token',
    symbol: 'isk',
    name: 'ISKRA Token',
  },
  {
    id: 'islamic-coin',
    symbol: 'islm',
    name: 'Islamic Coin',
  },
  {
    id: 'islamicoin',
    symbol: 'islami',
    name: 'ISLAMICOIN',
  },
  {
    id: 'islander',
    symbol: 'isa',
    name: 'Islander',
  },
  {
    id: 'ispolink',
    symbol: 'isp',
    name: 'Ispolink',
  },
  {
    id: 'issp',
    symbol: 'issp',
    name: 'ISSP',
  },
  {
    id: 'issuaa',
    symbol: 'iss',
    name: 'ISSUAA',
  },
  {
    id: 'istable',
    symbol: 'i-stable',
    name: 'iStable',
  },
  {
    id: 'istanbul-basaksehir-fan-token',
    symbol: 'ibfk',
    name: 'İstanbul Başakşehir Fan Token',
  },
  {
    id: 'istanbul-wild-cats-fan-token',
    symbol: 'iwft',
    name: 'İstanbul Wild Cats Fan Token',
  },
  {
    id: 'istep',
    symbol: 'istep',
    name: 'iSTEP',
  },
  {
    id: 'italian-national-football-team-fan-token',
    symbol: 'ita',
    name: 'Italian National Football Team Fan Token',
  },
  {
    id: 'itam-games',
    symbol: 'itam',
    name: 'ITAM Games',
  },
  {
    id: 'itc',
    symbol: 'itc',
    name: 'ITC',
  },
  {
    id: 'itemverse',
    symbol: 'item',
    name: 'ITEMVERSE',
  },
  {
    id: 'itheum',
    symbol: 'itheum',
    name: 'Itheum',
  },
  {
    id: 'its-as-shrimple-as-that',
    symbol: 'shrimple',
    name: 'its as shrimple as that',
  },
  {
    id: 'itsbloc',
    symbol: 'itsb',
    name: 'ITSBLOC',
  },
  {
    id: 'it-s-just-a-rock',
    symbol: 'rock',
    name: 'Rock',
  },
  {
    id: 'it-technology-global-ltd',
    symbol: 'itg',
    name: 'IT Technology Global Ltd',
  },
  {
    id: 'iucn-coin',
    symbol: 'iucn',
    name: 'IUCN Coin',
  },
  {
    id: 'iusd',
    symbol: 'iusd',
    name: 'iUSD',
  },
  {
    id: 'iustitia-coin',
    symbol: 'ius',
    name: 'Iustitia Coin',
  },
  {
    id: 'ivendpay',
    symbol: 'ivpay',
    name: 'ivendPay',
  },
  {
    id: 'ivy-live',
    symbol: 'ivy',
    name: 'Ivy Live',
  },
  {
    id: 'i-well-track-pro',
    symbol: 'iwell',
    name: 'I WELL TRACK PRO',
  },
  {
    id: 'i-will-poop-it-nft',
    symbol: 'shit',
    name: 'I will poop it NFT',
  },
  {
    id: 'ixcoin',
    symbol: 'ixc',
    name: 'Ixcoin',
  },
  {
    id: 'ixicash',
    symbol: 'ixi',
    name: 'IxiCash',
  },
  {
    id: 'ixirswap',
    symbol: 'ixir',
    name: 'Ixirswap',
  },
  {
    id: 'ixo',
    symbol: 'ixo',
    name: 'IXO',
  },
  {
    id: 'ix-swap',
    symbol: 'ixs',
    name: 'IX Swap',
  },
  {
    id: 'ix-token',
    symbol: 'ixt',
    name: 'Planet IX',
  },
  {
    id: 'iykyk',
    symbol: 'iykyk',
    name: 'IYKYK',
  },
  {
    id: 'iyu-finance',
    symbol: 'iyu',
    name: 'IYU Finance',
  },
  {
    id: 'izumi-bond-usd',
    symbol: 'iusd',
    name: 'iZUMi Bond USD',
  },
  {
    id: 'izumi-finance',
    symbol: 'izi',
    name: 'iZUMi Finance',
  },
  {
    id: 'jable',
    symbol: 'jab',
    name: 'Jable',
  },
  {
    id: 'jackal-protocol',
    symbol: 'jkl',
    name: 'Jackal Protocol',
  },
  {
    id: 'jackbot',
    symbol: 'jbot',
    name: 'JACKBOT',
  },
  {
    id: 'jackpool-finance',
    symbol: 'jfi',
    name: 'JackPool.finance',
  },
  {
    id: 'jackpot',
    symbol: '777',
    name: 'Jackpot',
  },
  {
    id: 'jackpotdoge',
    symbol: 'jpd',
    name: 'JackpotDoge',
  },
  {
    id: 'jack-token',
    symbol: 'jack',
    name: 'Jack Token',
  },
  {
    id: 'jacy',
    symbol: 'jacy',
    name: 'JACY',
  },
  {
    id: 'jade',
    symbol: 'jade',
    name: 'DeFi Kingdoms Jade',
  },
  {
    id: 'jade-currency',
    symbol: 'jade',
    name: 'Jade Currency',
  },
  {
    id: 'jaiho-crypto',
    symbol: 'jaiho',
    name: 'Jaiho Crypto',
  },
  {
    id: 'jake-newman-enterprises',
    symbol: 'jne',
    name: 'Jake Newman Enterprises',
  },
  {
    id: 'janus-network',
    symbol: 'jns',
    name: 'Janus Network',
  },
  {
    id: 'jared-from-subway',
    symbol: 'jared',
    name: 'Jared From Subway',
  },
  {
    id: 'jarvis-brazilian-real',
    symbol: 'jbrl',
    name: 'Jarvis Brazilian Real',
  },
  {
    id: 'jarvis-reward-token',
    symbol: 'jrt',
    name: 'Jarvis Reward',
  },
  {
    id: 'jarvis-synthetic-british-pound',
    symbol: 'jgbp',
    name: 'Jarvis Synthetic British Pound',
  },
  {
    id: 'jarvis-synthetic-euro',
    symbol: 'jeur',
    name: 'Jarvis Synthetic Euro',
  },
  {
    id: 'jarvis-synthetic-japanese-yen',
    symbol: 'jjpy',
    name: 'Jarvis Synthetic Japanese Yen',
  },
  {
    id: 'jarvis-synthetic-swiss-franc',
    symbol: 'jchf',
    name: 'Jarvis Synthetic Swiss Franc',
  },
  {
    id: 'jaseonmun',
    symbol: 'jsm',
    name: 'Joseon-Mun',
  },
  {
    id: 'jasmycoin',
    symbol: 'jasmy',
    name: 'JasmyCoin',
  },
  {
    id: 'javelin',
    symbol: 'jvl',
    name: 'Javelin',
  },
  {
    id: 'jax-network',
    symbol: 'wjxn',
    name: 'Jax.Network',
  },
  {
    id: 'jaypeggers-usdc',
    symbol: 'jusdc',
    name: 'Jaypeggers USDC',
  },
  {
    id: 'jaypegggers',
    symbol: 'jay',
    name: 'Jaypeggers',
  },
  {
    id: 'jd-coin',
    symbol: 'jdc',
    name: 'JD Coin',
  },
  {
    id: 'jdinsights',
    symbol: 'jd',
    name: 'JDinsights',
  },
  {
    id: 'jedstar',
    symbol: '$jed',
    name: 'JEDSTAR',
  },
  {
    id: 'jeet-detector-bot',
    symbol: 'jdb',
    name: 'JDB',
  },
  {
    id: 'jefe',
    symbol: 'jefe',
    name: 'Jefe',
  },
  {
    id: 'jeff',
    symbol: 'jeff',
    name: 'Jeff',
  },
  {
    id: 'jeffworld-token',
    symbol: 'jeff',
    name: 'JEFFWorld Token',
  },
  {
    id: 'jelly-esports',
    symbol: 'jelly',
    name: 'Jelly eSports',
  },
  {
    id: 'jellyfish-mobile',
    symbol: 'jfish',
    name: 'Jellyfish Mobile',
  },
  {
    id: 'jellyverse',
    symbol: 'jly',
    name: 'Jellyverse',
  },
  {
    id: 'jen-coin',
    symbol: 'jen',
    name: 'JEN COIN',
  },
  {
    id: 'jennyco',
    symbol: 'jco',
    name: 'JennyCo',
  },
  {
    id: 'jerry-inu',
    symbol: 'jerry',
    name: 'Jerry Inu',
  },
  {
    id: 'jesus',
    symbol: 'raptor',
    name: 'Jesus',
  },
  {
    id: 'jesus-coin',
    symbol: 'jesus',
    name: 'Jesus Coin',
  },
  {
    id: 'jesus-on-sol',
    symbol: 'jesus',
    name: 'JESUS ON SOL',
  },
  {
    id: 'jet',
    symbol: 'jet',
    name: 'JET',
  },
  {
    id: 'jetcoin',
    symbol: 'jet',
    name: 'Jetcoin',
  },
  {
    id: 'jetoken',
    symbol: 'jets',
    name: 'JeToken',
  },
  {
    id: 'jetset',
    symbol: 'jts',
    name: 'Jetset',
  },
  {
    id: 'jetton',
    symbol: 'jetton',
    name: 'JetTon Game',
  },
  {
    id: 'jexchange',
    symbol: 'jex',
    name: 'JEXchange',
  },
  {
    id: 'jfin-coin',
    symbol: 'jfin',
    name: 'JFIN Coin',
  },
  {
    id: 'jigstack',
    symbol: 'stak',
    name: 'Jigstack',
  },
  {
    id: 'jimmy-on-solana',
    symbol: 'jimmy',
    name: 'Jimmy on Solana',
  },
  {
    id: 'jindo-inu',
    symbol: 'jind',
    name: 'Jindo Inu',
  },
  {
    id: 'jinko-ai',
    symbol: 'jinko',
    name: 'Jinko AI',
  },
  {
    id: 'jito-governance-token',
    symbol: 'jto',
    name: 'Jito',
  },
  {
    id: 'jito-staked-sol',
    symbol: 'jitosol',
    name: 'Jito Staked SOL',
  },
  {
    id: 'jiyuu',
    symbol: 'jiyuu',
    name: 'Jiyuu',
  },
  {
    id: 'jizzrocket',
    symbol: 'jizz',
    name: 'JizzRocket',
  },
  {
    id: 'jjmoji',
    symbol: 'jj',
    name: 'Jjmoji',
  },
  {
    id: 'jkrantz-friend-tech',
    symbol: 'jeff',
    name: 'JKrantz (Friend.tech)',
  },
  {
    id: 'jobai',
    symbol: 'job',
    name: 'JobAi',
  },
  {
    id: 'jobchain',
    symbol: 'job',
    name: 'Jobchain',
  },
  {
    id: 'joe',
    symbol: 'joe',
    name: 'JOE',
  },
  {
    id: 'joe-coin',
    symbol: 'joe',
    name: 'Joe Coin',
  },
  {
    id: 'joe-hat-token',
    symbol: 'hat',
    name: 'Joe Hat',
  },
  {
    id: 'joel',
    symbol: 'joel',
    name: 'Joel',
  },
  {
    id: 'joe-yo-coin',
    symbol: 'jyc',
    name: 'Joe-Yo Coin',
  },
  {
    id: 'john-pork',
    symbol: 'pork',
    name: 'john pork',
  },
  {
    id: 'johor-darul-ta-zim-fc',
    symbol: 'jdt',
    name: 'Johor Darul Ta’zim FC Fan Token',
  },
  {
    id: 'jojo',
    symbol: 'jojo',
    name: 'JOJO',
  },
  {
    id: 'joltify',
    symbol: 'jolt',
    name: 'Joltify',
  },
  {
    id: 'jones',
    symbol: '$jones',
    name: '$JONES',
  },
  {
    id: 'jones-dao',
    symbol: 'jones',
    name: 'Jones DAO',
  },
  {
    id: 'jones-glp',
    symbol: 'jglp',
    name: 'Jones GLP',
  },
  {
    id: 'jones-usdc',
    symbol: 'jusdc',
    name: 'Jones USDC',
  },
  {
    id: 'journart',
    symbol: 'jart',
    name: 'JournArt',
  },
  {
    id: 'journey-ai',
    symbol: 'jrny',
    name: 'Journey',
  },
  {
    id: 'jovjou',
    symbol: 'jovjou',
    name: 'JovJou',
  },
  {
    id: 'joystick1',
    symbol: 'joy',
    name: 'Joystick',
  },
  {
    id: 'joystick-club',
    symbol: 'joy',
    name: 'Joystick.club',
  },
  {
    id: 'joystream',
    symbol: 'joy',
    name: 'Joystream',
  },
  {
    id: 'jp',
    symbol: 'jp',
    name: 'JP',
  },
  {
    id: 'jpeg-d',
    symbol: 'jpeg',
    name: "JPEG'd",
  },
  {
    id: 'jpeg-ordinals',
    symbol: 'jpeg',
    name: 'JPEG (Ordinals)',
  },
  {
    id: 'jpg-nft-index',
    symbol: 'jpg',
    name: 'JPG NFT Index',
  },
  {
    id: 'jpgoldcoin',
    symbol: 'jpgc',
    name: 'JPGoldCoin',
  },
  {
    id: 'jpg-store',
    symbol: 'jpg',
    name: 'JPG',
  },
  {
    id: 'jpool',
    symbol: 'jsol',
    name: 'JPool',
  },
  {
    id: 'jpyc',
    symbol: 'jpyc',
    name: 'JPY Coin v1',
  },
  {
    id: 'jpy-coin',
    symbol: 'jpyc',
    name: 'JPY Coin',
  },
  {
    id: 'jswap-finance',
    symbol: 'jf',
    name: 'Jswap.Finance',
  },
  {
    id: 'jtc-network',
    symbol: 'jtc',
    name: 'JTC Network',
  },
  {
    id: 'jts',
    symbol: 'jts',
    name: 'JTS',
  },
  {
    id: 'judas',
    symbol: '$judas',
    name: 'JUDAS',
  },
  {
    id: 'juggernaut',
    symbol: 'jgn',
    name: 'Juggernaut',
  },
  {
    id: 'jugni',
    symbol: 'jugni',
    name: 'JUGNI',
  },
  {
    id: 'juicebox',
    symbol: 'jbx',
    name: 'Juicebox',
  },
  {
    id: 'jujube',
    symbol: 'jujube',
    name: 'Jujube',
  },
  {
    id: 'julswap',
    symbol: 'juld',
    name: 'JulSwap',
  },
  {
    id: 'jumbo-exchange',
    symbol: 'jumbo',
    name: 'Jumbo Exchange',
  },
  {
    id: 'jumptoken',
    symbol: 'jmpt',
    name: 'JumpToken',
  },
  {
    id: 'jungle',
    symbol: 'jungle',
    name: 'Jungle',
  },
  {
    id: 'jungle-defi',
    symbol: 'jfi',
    name: 'Jungle DeFi',
  },
  {
    id: 'jungleking-tigercoin',
    symbol: 'tiger',
    name: 'JungleKing TigerCoin',
  },
  {
    id: 'jungle-labz',
    symbol: 'jngl',
    name: 'Jungle Labz',
  },
  {
    id: 'juno-network',
    symbol: 'juno',
    name: 'JUNO',
  },
  {
    id: 'jupbot',
    symbol: 'jupbot',
    name: 'JupBot',
  },
  {
    id: 'jupiter',
    symbol: 'jup',
    name: 'Jupiter Project',
  },
  {
    id: 'jupiter-exchange-solana',
    symbol: 'jup',
    name: 'Jupiter',
  },
  {
    id: 'jupiter-perpetuals-liquidity-provider-token',
    symbol: 'jlp',
    name: 'Jupiter Perpetuals Liquidity Provider Token',
  },
  {
    id: 'jupu',
    symbol: 'jupu',
    name: 'Jupu',
  },
  {
    id: 'jur',
    symbol: 'jur',
    name: 'Jur',
  },
  {
    id: 'jusd',
    symbol: 'jusd',
    name: 'JUSD',
  },
  {
    id: 'just',
    symbol: 'jst',
    name: 'JUST',
  },
  {
    id: 'just-clone-it',
    symbol: 'clone',
    name: 'Just Clone It',
  },
  {
    id: 'justmoney-2',
    symbol: 'jm',
    name: 'JustMoney',
  },
  {
    id: 'just-stablecoin',
    symbol: 'usdj',
    name: 'JUST Stablecoin',
  },
  {
    id: 'justus',
    symbol: 'jtt',
    name: 'Justus',
  },
  {
    id: 'juventus-fan-token',
    symbol: 'juv',
    name: 'Juventus Fan Token',
  },
  {
    id: 'k21',
    symbol: 'k21',
    name: 'K21',
  },
  {
    id: 'kabochan',
    symbol: 'kabo',
    name: 'KaboChan',
  },
  {
    id: 'kabosu',
    symbol: 'kabosu',
    name: 'Kabosu',
  },
  {
    id: 'kabosu-2',
    symbol: 'kabosu',
    name: 'KaBoSu',
  },
  {
    id: 'kabosu-3',
    symbol: '$kabosu',
    name: 'Kabosu',
  },
  {
    id: 'kabosu-arbitrum',
    symbol: 'kabosu',
    name: 'Kabosu (Arbitrum)',
  },
  {
    id: 'kabosuceo',
    symbol: 'kceo',
    name: 'KabosuCEO',
  },
  {
    id: 'kabosu-inu',
    symbol: 'kabosu',
    name: 'Kabosu Inu',
  },
  {
    id: 'kabuni',
    symbol: 'kbc',
    name: 'Kabuni',
  },
  {
    id: 'kaby-arena',
    symbol: 'kaby',
    name: 'Kaby Arena',
  },
  {
    id: 'kaching',
    symbol: 'kch',
    name: 'Kaching',
  },
  {
    id: 'kaddex',
    symbol: 'kdx',
    name: 'eckoDAO',
  },
  {
    id: 'kadena',
    symbol: 'kda',
    name: 'Kadena',
  },
  {
    id: 'kaeri',
    symbol: 'kaeri',
    name: 'Kaeri',
  },
  {
    id: 'kafenio-coin',
    symbol: 'kfn',
    name: 'Kafenio Coin',
  },
  {
    id: 'kagla-finance',
    symbol: 'kgl',
    name: 'Kagla Finance',
  },
  {
    id: 'kaidex',
    symbol: 'kdx',
    name: 'Kaidex',
  },
  {
    id: 'kaif',
    symbol: 'kaf',
    name: 'KAIF',
  },
  {
    id: 'kairos-a612bf05-b9c8-4e6b-aeb6-1f5b788ddd40',
    symbol: '$kairos',
    name: 'Kairos',
  },
  {
    id: 'kaizen',
    symbol: 'kzen',
    name: 'Kaizen.Finance',
  },
  {
    id: 'kaka-nft-world',
    symbol: 'kaka',
    name: 'KAKA NFT World',
  },
  {
    id: 'kala',
    symbol: 'kala',
    name: 'Kala',
  },
  {
    id: 'kalamint',
    symbol: 'kalam',
    name: 'Kalamint',
  },
  {
    id: 'kalao',
    symbol: 'klo',
    name: 'Kalao',
  },
  {
    id: 'kalar-chain',
    symbol: 'klc',
    name: 'Kalar Chain',
  },
  {
    id: 'kalichain',
    symbol: 'kalis',
    name: 'Kalichain',
  },
  {
    id: 'kalisten',
    symbol: 'ks',
    name: 'Kalisten',
  },
  {
    id: 'kalmar',
    symbol: 'kalm',
    name: 'KALM',
  },
  {
    id: 'kalycoin',
    symbol: 'klc',
    name: 'KalyChain',
  },
  {
    id: 'kamaleont',
    symbol: 'klt',
    name: 'Kamaleont',
  },
  {
    id: 'kambria',
    symbol: 'kat',
    name: 'Kambria',
  },
  {
    id: 'kampay',
    symbol: 'kampay',
    name: 'Kampay',
  },
  {
    id: 'kan',
    symbol: 'kan',
    name: 'BitKan',
  },
  {
    id: 'kanagawa-nami',
    symbol: 'okinami',
    name: 'Kanagawa Nami',
  },
  {
    id: 'kanaloa-network',
    symbol: 'kana',
    name: 'Kanaloa Network',
  },
  {
    id: 'kang3n',
    symbol: 'kang3n',
    name: 'kang3n',
  },
  {
    id: 'kanga-exchange',
    symbol: 'kng',
    name: 'Kanga Exchange',
  },
  {
    id: 'kangal',
    symbol: 'kangal',
    name: 'Kangal',
  },
  {
    id: 'kangaroo-community',
    symbol: 'kroo',
    name: 'Kangaroo Community',
  },
  {
    id: 'kannagi-finance',
    symbol: 'kana',
    name: 'Kannagi Finance',
  },
  {
    id: 'kanpeki',
    symbol: 'kae',
    name: 'Kanpeki',
  },
  {
    id: 'kanye',
    symbol: 'ye',
    name: 'Kanye',
  },
  {
    id: 'kapital-dao',
    symbol: 'kap',
    name: 'KAP Games',
  },
  {
    id: 'karastar-umy',
    symbol: 'umy',
    name: 'KaraStar UMY',
  },
  {
    id: 'karat',
    symbol: 'kat',
    name: 'Karat',
  },
  {
    id: 'karate-combat',
    symbol: 'karate',
    name: 'Karate Combat',
  },
  {
    id: 'karbo',
    symbol: 'krb',
    name: 'Karbo',
  },
  {
    id: 'kardiachain',
    symbol: 'kai',
    name: 'KardiaChain',
  },
  {
    id: 'karencoin',
    symbol: 'karen',
    name: 'KarenCoin',
  },
  {
    id: 'karen-pepe',
    symbol: '$kepe',
    name: 'Karen Pepe',
  },
  {
    id: 'karlsen',
    symbol: 'kls',
    name: 'Karlsen',
  },
  {
    id: 'karma-dao',
    symbol: 'karma',
    name: 'Karma DAO',
  },
  {
    id: 'karmaverse',
    symbol: 'knot',
    name: 'Karmaverse',
  },
  {
    id: 'karmaverse-zombie-serum',
    symbol: 'serum',
    name: 'Karmaverse Zombie Serum',
  },
  {
    id: 'karsiyaka-taraftar-token',
    symbol: 'ksk',
    name: 'Karşıyaka Taraftar Fan Token',
  },
  {
    id: 'karura',
    symbol: 'kar',
    name: 'Karura',
  },
  {
    id: 'kasa-central',
    symbol: 'kasa',
    name: 'Kasa Central',
  },
  {
    id: 'kaspa',
    symbol: 'kas',
    name: 'Kaspa',
  },
  {
    id: 'kaspamining',
    symbol: 'kmn',
    name: 'KASPAMINING',
  },
  {
    id: 'kassandra',
    symbol: 'kacy',
    name: 'Kassandra',
  },
  {
    id: 'kasta',
    symbol: 'kasta',
    name: 'Kasta',
  },
  {
    id: 'katalyo',
    symbol: 'ktlyo',
    name: 'Katalyo',
  },
  {
    id: 'katana-inu',
    symbol: 'kata',
    name: 'Katana Inu',
  },
  {
    id: 'kattana',
    symbol: 'ktn',
    name: 'Kattana',
  },
  {
    id: 'kava',
    symbol: 'kava',
    name: 'Kava',
  },
  {
    id: 'kava-lend',
    symbol: 'hard',
    name: 'Kava Lend',
  },
  {
    id: 'kava-swap',
    symbol: 'swp',
    name: 'Kava Swap',
  },
  {
    id: 'kawaii-islands',
    symbol: 'kwt',
    name: 'Kawaii Islands',
  },
  {
    id: 'kawakami',
    symbol: 'kawa',
    name: 'Kawakami',
  },
  {
    id: 'kay-pacha',
    symbol: 'pacha',
    name: 'Kay Pacha',
  },
  {
    id: 'kcal',
    symbol: 'kcal',
    name: 'KCAL',
  },
  {
    id: 'kccpad',
    symbol: 'kccpad',
    name: 'KCCPad',
  },
  {
    id: 'kdag',
    symbol: 'kdag',
    name: 'King DAG',
  },
  {
    id: 'kdlaunch',
    symbol: 'kdl',
    name: 'KDLaunch',
  },
  {
    id: 'kdswap',
    symbol: 'kds',
    name: 'KDSwap',
  },
  {
    id: 'keep3rv1',
    symbol: 'kp3r',
    name: 'Keep3rV1',
  },
  {
    id: 'keep-network',
    symbol: 'keep',
    name: 'Keep Network',
  },
  {
    id: 'keeps-coin',
    symbol: 'kverse',
    name: 'KEEPs Coin',
  },
  {
    id: 'kei-finance',
    symbol: 'kei',
    name: 'Kei Finance',
  },
  {
    id: 'kek',
    symbol: 'keke',
    name: 'KEK',
  },
  {
    id: 'kekchain',
    symbol: 'kek',
    name: 'KeKChain',
  },
  {
    id: 'kekcoin-eth',
    symbol: 'kek',
    name: 'Kekcoin (ETH)',
  },
  {
    id: 'keke-inu',
    symbol: 'keke',
    name: 'Keke Inu',
  },
  {
    id: 'keko',
    symbol: 'keko',
    name: 'Keko',
  },
  {
    id: 'kelp-dao-restaked-eth',
    symbol: 'rseth',
    name: 'Kelp DAO Restaked ETH',
  },
  {
    id: 'kelvpn',
    symbol: 'kel',
    name: 'KelVPN',
  },
  {
    id: 'kemacoin',
    symbol: 'kema',
    name: 'KemaCoin',
  },
  {
    id: 'kenda',
    symbol: 'knda',
    name: 'Kenda',
  },
  {
    id: 'kendoll-janner',
    symbol: 'ken',
    name: 'Kendoll Janner',
  },
  {
    id: 'kenka-metaverse',
    symbol: 'kenka',
    name: 'KENKA METAVERSE',
  },
  {
    id: 'kenshi-2',
    symbol: 'kns',
    name: 'Kenshi',
  },
  {
    id: 'kento',
    symbol: 'knto',
    name: 'Kento',
  },
  {
    id: 'kephi-gallery',
    symbol: 'kphi',
    name: 'Kephi Gallery',
  },
  {
    id: 'kepple',
    symbol: 'kpl',
    name: 'Kepple',
  },
  {
    id: 'kermit-cc0e2d66-4b46-4eaf-9f4e-5caa883d1c09',
    symbol: 'kermit',
    name: 'Kermit',
  },
  {
    id: 'ketaicoin',
    symbol: 'ethereum',
    name: 'Ketaicoin',
  },
  {
    id: 'kevin-2',
    symbol: 'kevin',
    name: 'Kevin',
  },
  {
    id: 'kewl',
    symbol: 'kewl',
    name: 'Kewl',
  },
  {
    id: 'keyboard-cat',
    symbol: 'keycat',
    name: 'Keyboard Cat',
  },
  {
    id: 'keyfi',
    symbol: 'keyfi',
    name: 'KeyFi',
  },
  {
    id: 'keysatin',
    symbol: 'keysatin',
    name: 'KeySATIN',
  },
  {
    id: 'keysians-network',
    symbol: 'ken',
    name: 'Keysians Network',
  },
  {
    id: 'keys-token',
    symbol: 'keys',
    name: 'Keys',
  },
  {
    id: 'ki',
    symbol: 'xki',
    name: 'KI',
  },
  {
    id: 'kiba-inu',
    symbol: 'kiba',
    name: 'Kiba Inu',
  },
  {
    id: 'kibbleswap',
    symbol: 'kib',
    name: 'KibbleSwap',
  },
  {
    id: 'kiboshib',
    symbol: 'kibshi',
    name: 'KiboShib',
  },
  {
    id: 'kick',
    symbol: 'kick',
    name: 'Kick',
  },
  {
    id: 'kickpad',
    symbol: 'kpad',
    name: 'KickPad',
  },
  {
    id: 'kiirocoin',
    symbol: 'kiiro',
    name: 'Kiirocoin',
  },
  {
    id: 'kilopi-8ee65670-efa5-4414-b9b4-1a1240415d74',
    symbol: 'lop',
    name: 'Kilopi',
  },
  {
    id: 'kilt-protocol',
    symbol: 'kilt',
    name: 'KILT Protocol',
  },
  {
    id: 'kimbo',
    symbol: 'kimbo',
    name: 'Kimbo',
  },
  {
    id: 'kimchi-finance',
    symbol: 'kimchi',
    name: 'KIMCHI.finance',
  },
  {
    id: 'kin',
    symbol: 'kin',
    name: 'Kin',
  },
  {
    id: 'kindness-for-the-soul',
    symbol: 'kind',
    name: 'Kindness For The Soul',
  },
  {
    id: 'kindness-for-the-soul-soul',
    symbol: 'soul',
    name: 'Kindness For The Soul SOUL',
  },
  {
    id: 'kinect-finance',
    symbol: 'knt',
    name: 'Kinect Finance',
  },
  {
    id: 'kineko',
    symbol: 'kko',
    name: 'KKO Protocol',
  },
  {
    id: 'kineko-knk',
    symbol: 'knk',
    name: 'Kineko',
  },
  {
    id: 'kine-protocol',
    symbol: 'kine',
    name: 'Kine Protocol',
  },
  {
    id: 'kinesis-gold',
    symbol: 'kau',
    name: 'Kinesis Gold',
  },
  {
    id: 'kinesis-silver',
    symbol: 'kag',
    name: 'Kinesis Silver',
  },
  {
    id: 'kinetixfi',
    symbol: 'kfi',
    name: 'KinetixFi',
  },
  {
    id: 'king-2',
    symbol: 'king',
    name: 'KING',
  },
  {
    id: 'kingaru',
    symbol: 'kru',
    name: 'Kingaru',
  },
  {
    id: 'king-bonk',
    symbol: 'kingbonk',
    name: 'King Bonk',
  },
  {
    id: 'king-dog-inu',
    symbol: 'kingdog',
    name: 'King Dog Inu',
  },
  {
    id: 'kingdomgame',
    symbol: 'kingdom',
    name: 'KingdomGame',
  },
  {
    id: 'kingdom-game-4-0',
    symbol: 'kdg',
    name: 'KingdomStarter',
  },
  {
    id: 'kingdom-karnage',
    symbol: 'kkt',
    name: 'Kingdom Karnage',
  },
  {
    id: 'kingdom-of-ants-ant-coins',
    symbol: 'antc',
    name: 'Kingdom of ANTs ANT Coins',
  },
  {
    id: 'kingdom-quest',
    symbol: 'kgc',
    name: 'Kingdom Quest',
  },
  {
    id: 'kingdomverse',
    symbol: 'king',
    name: 'Kingdomverse',
  },
  {
    id: 'kingdomx',
    symbol: 'kt',
    name: 'KingdomX',
  },
  {
    id: 'king-forever',
    symbol: 'kfr',
    name: 'KING FOREVER',
  },
  {
    id: 'king-grok',
    symbol: 'kinggrok',
    name: 'King Grok',
  },
  {
    id: 'king-of-legends-2',
    symbol: 'kol',
    name: 'King of Legends',
  },
  {
    id: 'king-shiba',
    symbol: 'kingshib',
    name: 'King Shiba',
  },
  {
    id: 'kingspeed',
    symbol: 'ksc',
    name: 'KingSpeed',
  },
  {
    id: 'kingu',
    symbol: 'kingu',
    name: 'KingU',
  },
  {
    id: 'king-wif',
    symbol: 'kingwif',
    name: 'King WIF',
  },
  {
    id: 'kingyton',
    symbol: 'kingy',
    name: 'KingyTON',
  },
  {
    id: 'kintsugi',
    symbol: 'kint',
    name: 'Kintsugi',
  },
  {
    id: 'kintsugi-btc',
    symbol: 'kbtc',
    name: 'Kintsugi BTC',
  },
  {
    id: 'kira',
    symbol: 'kira',
    name: 'KIRA',
  },
  {
    id: 'kira-network',
    symbol: 'kex',
    name: 'KIRA Network',
  },
  {
    id: 'kira-the-injective-cat',
    symbol: 'kira',
    name: 'Kira the Injective Cat',
  },
  {
    id: 'kirobo',
    symbol: 'kiro',
    name: 'KIRO',
  },
  {
    id: 'kiseki',
    symbol: 'kitup',
    name: 'Kiseki',
  },
  {
    id: 'kishimoto',
    symbol: 'kishimoto',
    name: 'Kishimoto',
  },
  {
    id: 'kishu-inu',
    symbol: 'kishu',
    name: 'Kishu Inu',
  },
  {
    id: 'kishu-ken',
    symbol: 'kishk',
    name: 'Kishu Ken',
  },
  {
    id: 'kissan',
    symbol: 'ksn',
    name: 'Kissan',
  },
  {
    id: 'kitbull',
    symbol: 'kitbull',
    name: 'Kitbull',
  },
  {
    id: 'kitsumon',
    symbol: '$kmc',
    name: 'Kitsumon',
  },
  {
    id: 'kittenfinance',
    symbol: 'kif',
    name: 'KittenFinance',
  },
  {
    id: 'kitti',
    symbol: 'kitti',
    name: 'KITTI',
  },
  {
    id: 'kitty',
    symbol: 'kit',
    name: 'Kitty',
  },
  {
    id: 'kitty-ai',
    symbol: 'kitty',
    name: 'Kitty AI',
  },
  {
    id: 'kittycake',
    symbol: 'kcake',
    name: 'KittyCake',
  },
  {
    id: 'kitty-coin-solana',
    symbol: 'kitty',
    name: 'Kitty Coin Solana',
  },
  {
    id: 'kitty-inu',
    symbol: 'kitty',
    name: 'Kitty Inu',
  },
  {
    id: 'kitty-solana',
    symbol: 'kitty',
    name: 'Kitty Solana',
  },
  {
    id: 'kiwi',
    symbol: 'kiwi',
    name: 'kiwi',
  },
  {
    id: 'kizuna',
    symbol: 'kizuna',
    name: 'Kizuna',
  },
  {
    id: 'klap-finance',
    symbol: 'klap',
    name: 'Klap Finance',
  },
  {
    id: 'klaycity-orb',
    symbol: 'orb',
    name: 'Orbcity',
  },
  {
    id: 'klaydice',
    symbol: 'dice',
    name: 'Klaydice',
  },
  {
    id: 'klayfi-finance',
    symbol: 'kfi',
    name: 'KlayFi Finance',
  },
  {
    id: 'klayswap-protocol',
    symbol: 'ksp',
    name: 'KlaySwap Protocol',
  },
  {
    id: 'klaytn-dai',
    symbol: 'kdai',
    name: 'Klaytn Dai',
  },
  {
    id: 'klay-token',
    symbol: 'klay',
    name: 'Klaytn',
  },
  {
    id: 'klaytu',
    symbol: 'ktu',
    name: 'Klaytu',
  },
  {
    id: 'kleekai',
    symbol: 'klee',
    name: 'KleeKai',
  },
  {
    id: 'kleomedes',
    symbol: 'kleo',
    name: 'Kleomedes',
  },
  {
    id: 'kleros',
    symbol: 'pnk',
    name: 'Kleros',
  },
  {
    id: 'kleva',
    symbol: 'kleva',
    name: 'KLEVA',
  },
  {
    id: 'klever',
    symbol: 'klv',
    name: 'Klever',
  },
  {
    id: 'klever-finance',
    symbol: 'kfi',
    name: 'Klever Finance',
  },
  {
    id: 'klima-dao',
    symbol: 'klima',
    name: 'KlimaDAO',
  },
  {
    id: 'klubcoin',
    symbol: 'klub',
    name: 'KlubCoin',
  },
  {
    id: 'kmushicoin',
    symbol: 'ktv',
    name: 'Kmushicoin',
  },
  {
    id: 'knights-peasants',
    symbol: 'knight',
    name: 'Knights & Peasants',
  },
  {
    id: 'knightswap',
    symbol: 'knight',
    name: 'KnightSwap',
  },
  {
    id: 'knight-war-spirits',
    symbol: 'kws',
    name: 'Knight War Spirits',
  },
  {
    id: 'knit-finance',
    symbol: 'kft',
    name: 'Knit Finance',
  },
  {
    id: 'knob',
    symbol: 'knob',
    name: 'KNOB$',
  },
  {
    id: 'knowyourdev',
    symbol: 'kyd',
    name: 'KnowYourDev',
  },
  {
    id: 'knoxfs',
    symbol: 'kfx',
    name: 'KnoxFS',
  },
  {
    id: 'koakuma',
    symbol: 'kkma',
    name: 'Koakuma',
  },
  {
    id: 'kocaelispor-fan-token',
    symbol: 'kstt',
    name: 'Kocaelispor Fan Token',
  },
  {
    id: 'kochi-ken',
    symbol: 'kochi',
    name: 'Kochi Ken',
  },
  {
    id: 'koda-finance',
    symbol: 'koda',
    name: 'Koda Cryptocurrency',
  },
  {
    id: 'kogecoin',
    symbol: 'kogecoin',
    name: 'KogeCoin',
  },
  {
    id: 'kohenoor',
    symbol: 'ken',
    name: 'KOHENOOR',
  },
  {
    id: 'koi',
    symbol: 'koi',
    name: 'KOI',
  },
  {
    id: 'koi-2',
    symbol: 'koi',
    name: 'KOI',
  },
  {
    id: 'koinon',
    symbol: 'koin',
    name: 'Koinon',
  },
  {
    id: 'koinos',
    symbol: 'koin',
    name: 'Koinos',
  },
  {
    id: 'koji',
    symbol: 'koji',
    name: 'Koji',
  },
  {
    id: 'kok',
    symbol: 'kok',
    name: 'KOK',
  },
  {
    id: 'kokoa-finance',
    symbol: 'kokoa',
    name: 'Kokoa Finance',
  },
  {
    id: 'kokoa-stable-dollar',
    symbol: 'ksd',
    name: 'Kokoa Stable Dollar',
  },
  {
    id: 'kokonut-swap',
    symbol: 'kokos',
    name: 'Kokonut Swap',
  },
  {
    id: 'kolibri-dao',
    symbol: 'kdao',
    name: 'Kolibri DAO',
  },
  {
    id: 'kolibri-usd',
    symbol: 'kusd',
    name: 'Kolibri USD',
  },
  {
    id: 'kollect',
    symbol: 'kol',
    name: 'Kollect',
  },
  {
    id: 'kollector',
    symbol: 'kltr',
    name: 'Kollector',
  },
  {
    id: 'kolnet',
    symbol: 'kolnet',
    name: 'KOLNET',
  },
  {
    id: 'kommunitas',
    symbol: 'kom',
    name: 'Kommunitas',
  },
  {
    id: 'komodo',
    symbol: 'kmd',
    name: 'Komodo',
  },
  {
    id: 'kompete',
    symbol: 'kompete',
    name: 'KOMPETE',
  },
  {
    id: 'kondux-v2',
    symbol: 'kndx',
    name: 'KONDUX',
  },
  {
    id: 'konke',
    symbol: 'konke',
    name: 'Konke',
  },
  {
    id: 'konnect',
    symbol: 'kct',
    name: 'Konnect',
  },
  {
    id: 'konomi-network',
    symbol: 'kono',
    name: 'Konomi Network',
  },
  {
    id: 'konpay',
    symbol: 'kon',
    name: 'KonPay',
  },
  {
    id: 'koop360',
    symbol: 'koop',
    name: 'Koop360',
  },
  {
    id: 'korra',
    symbol: 'korra',
    name: 'KORRA',
  },
  {
    id: 'kortana',
    symbol: 'kora',
    name: 'Kortana',
  },
  {
    id: 'kotia',
    symbol: 'kot',
    name: 'kotia',
  },
  {
    id: 'koubek',
    symbol: 'kbk',
    name: 'Koubek',
  },
  {
    id: 'kounotori',
    symbol: 'kto',
    name: 'Kounotori',
  },
  {
    id: 'koyo',
    symbol: 'kyo',
    name: 'Kōyō',
  },
  {
    id: 'koyo-6e93c7c7-03a3-4475-86a1-f0bc80ee09d6',
    symbol: 'koy',
    name: 'Koyo',
  },
  {
    id: 'k-pop-click-coin',
    symbol: 'kpc',
    name: 'K-POP CLICK COIN',
  },
  {
    id: 'kpop-coin',
    symbol: 'kpop',
    name: 'KPOP Coin',
  },
  {
    id: 'k-pop-on-solana',
    symbol: 'kpop',
    name: 'K-Pop on Solana',
  },
  {
    id: 'kragger-inu',
    symbol: 'kinu',
    name: 'Kragger Inu',
  },
  {
    id: 'krav',
    symbol: 'krav',
    name: 'Krav',
  },
  {
    id: 'kreaitor',
    symbol: 'kai',
    name: 'Kreaitor',
  },
  {
    id: 'kred',
    symbol: 'kred',
    name: 'KRED',
  },
  {
    id: 'krest',
    symbol: 'krest',
    name: 'Krest',
  },
  {
    id: 'krida-fans',
    symbol: 'krida',
    name: 'Krida Fans',
  },
  {
    id: 'krill',
    symbol: 'krill',
    name: 'Krill',
  },
  {
    id: 'kripto',
    symbol: 'kripto',
    name: 'Kripto',
  },
  {
    id: 'kripto-galaxy-battle',
    symbol: 'kaba',
    name: 'Kripto Galaxy Battle',
  },
  {
    id: 'krogan',
    symbol: 'kro',
    name: 'Krogan',
  },
  {
    id: 'kroma',
    symbol: 'kro',
    name: 'Kroma',
  },
  {
    id: 'kromatika',
    symbol: 'krom',
    name: 'Kromatika',
  },
  {
    id: 'kronobit',
    symbol: 'knb',
    name: 'Kronobit Networks Blockchain',
  },
  {
    id: 'krown',
    symbol: 'krw',
    name: 'KROWN',
  },
  {
    id: 'kryll',
    symbol: 'krl',
    name: 'KRYLL',
  },
  {
    id: 'kryptokrona',
    symbol: 'xkr',
    name: 'Kryptokrona',
  },
  {
    id: 'kryptomon',
    symbol: 'kmon',
    name: 'Kryptomon',
  },
  {
    id: 'krypton-dao',
    symbol: 'krd',
    name: 'Krypton DAO',
  },
  {
    id: 'kryptonite',
    symbol: 'seilor',
    name: 'Kryptonite',
  },
  {
    id: 'kryptonite-staked-sei',
    symbol: 'stsei',
    name: 'Kryptonite Staked SEI',
  },
  {
    id: 'krypton-token',
    symbol: 'kgc',
    name: 'Krypton Galaxy Coin',
  },
  {
    id: 'kryxivia-game',
    symbol: 'kxa',
    name: 'Kryxivia Game',
  },
  {
    id: 'kryza-exchange',
    symbol: 'krx',
    name: 'KRYZA Exchange',
  },
  {
    id: 'kryza-network',
    symbol: 'krn',
    name: 'KRYZA Network',
  },
  {
    id: 'k-stadium',
    symbol: 'ksta',
    name: 'K Stadium',
  },
  {
    id: 'kstarcoin',
    symbol: 'ksc',
    name: 'KStarCoin',
  },
  {
    id: 'kstarnft',
    symbol: 'knft',
    name: 'KStarNFT',
  },
  {
    id: 'k-tune',
    symbol: 'ktt',
    name: 'K-Tune',
  },
  {
    id: 'ktx-finance',
    symbol: 'ktc',
    name: 'KTX.Finance',
  },
  {
    id: 'kubecoin',
    symbol: 'kube',
    name: 'KubeCoin',
  },
  {
    id: 'kubic',
    symbol: 'kubic',
    name: 'Kubic',
  },
  {
    id: 'kucoin-shares',
    symbol: 'kcs',
    name: 'KuCoin',
  },
  {
    id: 'kudoe',
    symbol: 'kdoe',
    name: 'Kudoe',
  },
  {
    id: 'kujira',
    symbol: 'kuji',
    name: 'Kujira',
  },
  {
    id: 'kuku',
    symbol: 'kuku',
    name: 'KuKu',
  },
  {
    id: 'kuku-eth',
    symbol: 'kuku',
    name: 'KuKu',
  },
  {
    id: 'kuma',
    symbol: 'kuma',
    name: 'KUMA',
  },
  {
    id: 'kumadex-token',
    symbol: 'dkuma',
    name: 'KumaDex Token',
  },
  {
    id: 'kuma-inu',
    symbol: 'kuma',
    name: 'Kuma Inu',
  },
  {
    id: 'kumamon-finance',
    symbol: 'kumamon',
    name: 'Kumamon Finance',
  },
  {
    id: 'kuma-protocol-fr-kuma-interest-bearing-token',
    symbol: 'frk',
    name: 'KUMA Protocol FR KUMA Interest Bearing Token',
  },
  {
    id: 'kuma-protocol-wrapped-frk',
    symbol: 'wfrk',
    name: 'KUMA Protocol Wrapped FRK',
  },
  {
    id: 'kuma-protocol-wrapped-usk',
    symbol: 'wusk',
    name: 'KUMA Protocol Wrapped USK',
  },
  {
    id: 'kunci-coin',
    symbol: 'kunci',
    name: 'Kunci Coin',
  },
  {
    id: 'kunji-finance',
    symbol: 'knj',
    name: 'Kunji Finance',
  },
  {
    id: 'kunkun-coin',
    symbol: 'kunkun',
    name: 'KUNKUN Coin',
  },
  {
    id: 'kurobi',
    symbol: 'kuro',
    name: 'Kurobi',
  },
  {
    id: 'kuroneko',
    symbol: 'jiji',
    name: 'KURONEKO',
  },
  {
    id: 'kusama',
    symbol: 'ksm',
    name: 'Kusama',
  },
  {
    id: 'kusd-t',
    symbol: 'kusd-t',
    name: 'KUSD-T',
  },
  {
    id: 'kushcoin-sol',
    symbol: 'kush',
    name: 'kushcoin.sol',
  },
  {
    id: 'kusunoki-samurai',
    symbol: 'kusunoki',
    name: 'Kusunoki Samurai',
  },
  {
    id: 'kuswap',
    symbol: 'kus',
    name: 'KuSwap',
  },
  {
    id: 'kvants-ai',
    symbol: 'kvnt',
    name: 'Kvants AI',
  },
  {
    id: 'kwai',
    symbol: 'kwai',
    name: 'KWAI',
  },
  {
    id: 'kwenta',
    symbol: 'kwenta',
    name: 'Kwenta',
  },
  {
    id: 'kyanite',
    symbol: 'kyan',
    name: 'Kyanite',
  },
  {
    id: 'kyberdyne',
    symbol: 'kbd',
    name: 'Kyberdyne',
  },
  {
    id: 'kyber-network',
    symbol: 'kncl',
    name: 'Kyber Network Crystal Legacy',
  },
  {
    id: 'kyber-network-crystal',
    symbol: 'knc',
    name: 'Kyber Network Crystal',
  },
  {
    id: 'kylacoin',
    symbol: 'kcn',
    name: 'Kylacoin',
  },
  {
    id: 'kyoko',
    symbol: 'kyoko',
    name: 'Kyoko',
  },
  {
    id: 'kyotoswap',
    symbol: 'kswap',
    name: 'KyotoSwap',
  },
  {
    id: 'kyrrex',
    symbol: 'krrx',
    name: 'Kyrrex',
  },
  {
    id: 'kyte-one',
    symbol: 'kte',
    name: 'Kyte.One',
  },
  {
    id: 'kyve-network',
    symbol: 'kyve',
    name: 'KYVE Network',
  },
  {
    id: 'kzcash',
    symbol: 'kzc',
    name: 'Kzcash',
  },
  {
    id: 'l',
    symbol: 'l',
    name: 'L',
  },
  {
    id: 'l3usd',
    symbol: 'l3usd',
    name: 'L3USD',
  },
  {
    id: 'l7dex',
    symbol: 'lsd',
    name: 'L7DEX',
  },
  {
    id: 'laari-finance',
    symbol: 'laari',
    name: 'Laari Finance',
  },
  {
    id: 'label-foundation',
    symbol: 'lbl',
    name: 'LABEL Foundation',
  },
  {
    id: 'labrador',
    symbol: '$lab',
    name: 'Labrador',
  },
  {
    id: 'labs-group',
    symbol: 'labsv2',
    name: 'LABSV2',
  },
  {
    id: 'labs-protocol',
    symbol: 'labs',
    name: 'LABS Protocol',
  },
  {
    id: 'la-coin',
    symbol: 'lac',
    name: 'La Coin',
  },
  {
    id: 'lacostoken',
    symbol: 'lcsn',
    name: 'Lacostoken',
  },
  {
    id: 'laelaps',
    symbol: 'laelaps',
    name: 'Laelaps',
  },
  {
    id: 'laika',
    symbol: 'laika',
    name: 'Laika',
  },
  {
    id: 'laikaverse',
    symbol: 'laika',
    name: 'LaikaVerse',
  },
  {
    id: 'laine-stake',
    symbol: 'lainesol',
    name: 'Laine Stake',
  },
  {
    id: 'lakeviewmeta',
    symbol: 'lvm',
    name: 'LakeViewMeta',
  },
  {
    id: 'lamas-finance',
    symbol: 'lmf',
    name: 'Lamas Finance',
  },
  {
    id: 'lambda',
    symbol: 'lamb',
    name: 'Lambda',
  },
  {
    id: 'lambda-markets',
    symbol: 'lmda',
    name: 'Lambda Markets',
  },
  {
    id: 'lambo-0fcbf0f7-1a8f-470d-ba09-797d5e95d836',
    symbol: 'lambo',
    name: '$LAMBO',
  },
  {
    id: 'lambo-and-moon',
    symbol: 'lm',
    name: 'LAMBO AND MOON',
  },
  {
    id: 'lanacoin',
    symbol: 'lana',
    name: 'LanaCoin',
  },
  {
    id: 'lanceria',
    symbol: 'lanc',
    name: 'Lanceria',
  },
  {
    id: 'landboard',
    symbol: 'land',
    name: 'Landboard',
  },
  {
    id: 'land-of-conquest-slg',
    symbol: 'slg',
    name: 'SLG.GAMES',
  },
  {
    id: 'land-of-heroes',
    symbol: 'loh',
    name: 'Land Of Heroes',
  },
  {
    id: 'landshare',
    symbol: 'land',
    name: 'Landshare',
  },
  {
    id: 'landtorn-shard',
    symbol: 'shard',
    name: 'Landtorn Shard',
  },
  {
    id: 'landwolf',
    symbol: 'wolf',
    name: 'LandWolf',
  },
  {
    id: 'landwolf-on-avax',
    symbol: 'wolf',
    name: 'Landwolf on AVAX',
  },
  {
    id: 'landx-governance-token',
    symbol: 'lndx',
    name: 'LandX Governance Token',
  },
  {
    id: 'lan-network',
    symbol: 'lan',
    name: 'LAN Network',
  },
  {
    id: 'la-peseta',
    symbol: 'pta',
    name: 'La Peseta [OLD]',
  },
  {
    id: 'la-peseta-2',
    symbol: 'ptas',
    name: 'La Peseta',
  },
  {
    id: 'larace',
    symbol: 'lar',
    name: 'LaRace',
  },
  {
    id: 'larissa-blockchain',
    symbol: 'lrs',
    name: 'Larissa Blockchain',
  },
  {
    id: 'larix',
    symbol: 'larix',
    name: 'Larix',
  },
  {
    id: 'laro-classic',
    symbol: 'lro',
    name: 'Laro Classic',
  },
  {
    id: 'larry',
    symbol: 'larry',
    name: 'Larry',
  },
  {
    id: 'larry-the-llama',
    symbol: 'larry',
    name: 'Larry the Llama',
  },
  {
    id: 'latoken',
    symbol: 'la',
    name: 'LA',
  },
  {
    id: 'lattice-token',
    symbol: 'ltx',
    name: 'Lattice',
  },
  {
    id: 'laughcoin',
    symbol: 'laughcoin',
    name: 'Laughcoin',
  },
  {
    id: 'launchblock',
    symbol: 'lbp',
    name: 'LaunchBlock',
  },
  {
    id: 'launchpool',
    symbol: 'lpool',
    name: 'Launchpool',
  },
  {
    id: 'lava',
    symbol: 'lava',
    name: 'Lava',
  },
  {
    id: 'lavandos',
    symbol: 'lave',
    name: 'Lavandos',
  },
  {
    id: 'lavaswap',
    symbol: 'lava',
    name: 'Lavaswap',
  },
  {
    id: 'lavita',
    symbol: 'lavita',
    name: 'Lavita',
  },
  {
    id: 'law',
    symbol: 'law',
    name: 'LAW',
  },
  {
    id: 'law-blocks',
    symbol: 'lbt',
    name: 'Law Blocks',
  },
  {
    id: 'layer2dao',
    symbol: 'l2dao',
    name: 'Layer2DAO',
  },
  {
    id: 'layer4-network',
    symbol: 'layer4',
    name: 'Layer4 Network',
  },
  {
    id: 'layergpt',
    symbol: 'lgpt',
    name: 'LayerGPT',
  },
  {
    id: 'layerium',
    symbol: 'lyum',
    name: 'Layerium',
  },
  {
    id: 'layer-network',
    symbol: 'layer',
    name: 'Layer Network',
  },
  {
    id: 'layer-one-x',
    symbol: 'l1x',
    name: 'Layer One X',
  },
  {
    id: 'layerzero',
    symbol: 'zro',
    name: 'LayerZero',
  },
  {
    id: 'layerzero-usdc',
    symbol: 'lzusdc',
    name: 'Bridged USD Coin (LayerZero)',
  },
  {
    id: 'lazio-fan-token',
    symbol: 'lazio',
    name: 'Lazio Fan Token',
  },
  {
    id: 'lbk',
    symbol: 'lbk',
    name: 'LBK',
  },
  {
    id: 'lbry-credits',
    symbol: 'lbc',
    name: 'LBRY Credits',
  },
  {
    id: 'lcx',
    symbol: 'lcx',
    name: 'LCX',
  },
  {
    id: 'leaderdao',
    symbol: 'ldao',
    name: 'LeaderDAO',
  },
  {
    id: 'lead-token',
    symbol: 'lead',
    name: 'Lead',
  },
  {
    id: 'league-bot',
    symbol: 'league',
    name: 'League Bot',
  },
  {
    id: 'league-of-ancients',
    symbol: 'loa',
    name: 'League of Ancients',
  },
  {
    id: 'league-of-kingdoms',
    symbol: 'loka',
    name: 'League of Kingdoms',
  },
  {
    id: 'leancoin',
    symbol: 'lean',
    name: 'Leancoin',
  },
  {
    id: 'leandro-lopes',
    symbol: 'lopes',
    name: 'Leandro Lopes',
  },
  {
    id: 'leap-token',
    symbol: 'leap',
    name: 'LEAP Token',
  },
  {
    id: 'learning-star',
    symbol: 'lstar',
    name: 'Learning Star',
  },
  {
    id: 'leash',
    symbol: 'leash',
    name: 'Doge Killer',
  },
  {
    id: 'leaxcoin',
    symbol: 'leax',
    name: 'Leaxcoin',
  },
  {
    id: 'ledgerland',
    symbol: 'lger',
    name: 'LedgerLand',
  },
  {
    id: 'ledgis',
    symbol: 'led',
    name: 'Ledgis',
  },
  {
    id: 'lee',
    symbol: 'lee',
    name: 'Lee',
  },
  {
    id: 'leeds-united-fan-token',
    symbol: 'lufc',
    name: 'Leeds United Fan Token',
  },
  {
    id: 'leeroy-jenkins',
    symbol: 'leeroy',
    name: 'LEEROY JENKINS',
  },
  {
    id: 'leetcoin',
    symbol: 'leet',
    name: 'LEETCoin',
  },
  {
    id: 'leetswap-canto',
    symbol: 'leet',
    name: 'LeetSwap (Canto)',
  },
  {
    id: 'legacy-ichi',
    symbol: 'ichi',
    name: 'Legacy ICHI',
  },
  {
    id: 'legend-of-annihilation',
    symbol: 'loa',
    name: 'Legend of Annihilation',
  },
  {
    id: 'legend-of-fantasy-war',
    symbol: 'lfw',
    name: 'Linked Finance World',
  },
  {
    id: 'legends-token',
    symbol: 'lg',
    name: 'Legends Token',
  },
  {
    id: 'legia-warsaw-fan-token',
    symbol: 'leg',
    name: 'Legia Warsaw Fan Token',
  },
  {
    id: 'legion-network',
    symbol: 'lgx',
    name: 'Legion Network',
  },
  {
    id: 'legion-ventures',
    symbol: '$legion',
    name: 'Legion Ventures',
  },
  {
    id: 'lego-coin-v2',
    symbol: 'lego',
    name: 'Lego Coin V2',
  },
  {
    id: 'leia-the-cat',
    symbol: 'leia',
    name: 'Leia the Cat',
  },
  {
    id: 'leicester-tigers-fan-token',
    symbol: 'tigers',
    name: 'Leicester Tigers Fan Token',
  },
  {
    id: 'leisuremeta',
    symbol: 'lm',
    name: 'LeisureMeta',
  },
  {
    id: 'lemochain',
    symbol: 'lemo',
    name: 'LemoChain',
  },
  {
    id: 'lemonchain',
    symbol: 'lemc',
    name: 'LemonChain',
  },
  {
    id: 'lemond',
    symbol: 'lemd',
    name: 'Lemond',
  },
  {
    id: 'lemon-terminal',
    symbol: 'lemon',
    name: 'Lemon Terminal',
  },
  {
    id: 'lemon-token',
    symbol: 'lemn',
    name: 'Crypto Lemon',
  },
  {
    id: 'lena',
    symbol: 'lena',
    name: 'Lena',
  },
  {
    id: 'lendexe',
    symbol: 'lexe',
    name: 'LendeXe Finance',
  },
  {
    id: 'lendhub',
    symbol: 'lhb',
    name: 'Lendhub',
  },
  {
    id: 'lendle',
    symbol: 'lend',
    name: 'Lendle',
  },
  {
    id: 'lendora-protocol',
    symbol: 'lora',
    name: 'Lendora Protocol',
  },
  {
    id: 'lendrr',
    symbol: 'lndrr',
    name: 'LendrR',
  },
  {
    id: 'lendrusre',
    symbol: 'usre',
    name: 'LendrUSRE',
  },
  {
    id: 'lenny-face',
    symbol: '( ͡° ͜ʖ ͡°)',
    name: 'Lenny Face',
  },
  {
    id: 'leonicorn-swap-leons',
    symbol: 'leons',
    name: 'Leonicorn Swap LEONS',
  },
  {
    id: 'leonidasbilic',
    symbol: 'lio',
    name: 'Leonidasbilic',
  },
  {
    id: 'leonidas-token',
    symbol: 'leonidas',
    name: 'Leonidas Token',
  },
  {
    id: 'leopard',
    symbol: 'leopard',
    name: 'Leopard',
  },
  {
    id: 'leopold',
    symbol: 'leo',
    name: 'LEO',
  },
  {
    id: 'leo-token',
    symbol: 'leo',
    name: 'LEO Token',
  },
  {
    id: 'leox',
    symbol: 'leox',
    name: 'LEOX',
  },
  {
    id: 'leslie',
    symbol: 'leslie',
    name: 'Leslie',
  },
  {
    id: 'lessfngas',
    symbol: 'lfg',
    name: 'LessFnGas',
  },
  {
    id: 'lethean',
    symbol: 'lthn',
    name: 'Lethean',
  },
  {
    id: 'lets-go-brandon',
    symbol: 'letsgo',
    name: 'Lets Go Brandon',
  },
  {
    id: 'levana-protocol',
    symbol: 'lvn',
    name: 'Levana',
  },
  {
    id: 'levante-ud-fan-token',
    symbol: 'lev',
    name: 'Levante U.D. Fan Token',
  },
  {
    id: 'level',
    symbol: 'lvl',
    name: 'Level',
  },
  {
    id: 'levelg',
    symbol: 'levelg',
    name: 'LEVELG',
  },
  {
    id: 'level-governance',
    symbol: 'lgo',
    name: 'Level Governance',
  },
  {
    id: 'lever',
    symbol: 'lever',
    name: 'LeverFi',
  },
  {
    id: 'leveraged-reth-staking-yield',
    symbol: 'icreth',
    name: 'Leveraged rETH Staking Yield',
  },
  {
    id: 'leverageinu',
    symbol: 'levi',
    name: 'LeverageInu',
  },
  {
    id: 'leverj-gluon',
    symbol: 'l2',
    name: 'Leverj Gluon',
  },
  {
    id: 'lever-network',
    symbol: 'lev',
    name: 'Lever Network',
  },
  {
    id: 'levolution',
    symbol: 'levl',
    name: 'Levolution',
  },
  {
    id: 'lexer-markets',
    symbol: 'lex',
    name: 'LEXER Markets',
  },
  {
    id: 'lfg',
    symbol: '@lfg',
    name: 'LFG',
  },
  {
    id: 'lfg-coin',
    symbol: 'lfg',
    name: 'LFG coin',
  },
  {
    id: 'lfgswap-finance',
    symbol: 'lfg',
    name: 'LFGSwap Finance',
  },
  {
    id: 'lfgswap-finance-core',
    symbol: 'lfg',
    name: 'LFGSwap Finance(CORE)',
  },
  {
    id: 'lfi',
    symbol: 'lfi',
    name: 'LFi',
  },
  {
    id: 'lgcy-network',
    symbol: 'lgcy',
    name: 'LGCY Network',
  },
  {
    id: 'libera-financial',
    symbol: 'libera',
    name: 'Libera Financial',
  },
  {
    id: 'liberland-lld',
    symbol: 'lld',
    name: 'Liberland LLD',
  },
  {
    id: 'libero-financial',
    symbol: 'libero',
    name: 'Libero Financial',
  },
  {
    id: 'liberty-square-filth',
    symbol: 'flth',
    name: 'Liberty Square Filth',
  },
  {
    id: 'libfi',
    symbol: 'libx',
    name: 'Libfi',
  },
  {
    id: 'libra-3',
    symbol: 'libra',
    name: 'Libra',
  },
  {
    id: 'libra-credit',
    symbol: 'lba',
    name: 'Libra Credit',
  },
  {
    id: 'libra-incentix',
    symbol: 'lixx',
    name: 'Libra Incentix',
  },
  {
    id: 'libra-protocol',
    symbol: 'lbr',
    name: 'Libra Protocol',
  },
  {
    id: 'libre',
    symbol: 'libre',
    name: 'Libre',
  },
  {
    id: 'lichang',
    symbol: 'lc',
    name: 'Lichang',
  },
  {
    id: 'lido-dao',
    symbol: 'ldo',
    name: 'Lido DAO',
  },
  {
    id: 'lido-dao-wormhole',
    symbol: 'ldo',
    name: 'Lido DAO (Wormhole)',
  },
  {
    id: 'lido-on-kusama',
    symbol: 'wstksm',
    name: 'Lido on Kusama',
  },
  {
    id: 'lido-staked-matic',
    symbol: 'stmatic',
    name: 'Lido Staked Matic',
  },
  {
    id: 'lido-staked-sol',
    symbol: 'stsol',
    name: 'Lido Staked SOL',
  },
  {
    id: 'lidya',
    symbol: 'lidya',
    name: 'Lidya',
  },
  {
    id: 'lieflat',
    symbol: 'lieflat',
    name: 'LieFlat',
  },
  {
    id: 'lien',
    symbol: 'lien',
    name: 'Lien',
  },
  {
    id: 'lif3',
    symbol: 'lif3',
    name: 'LIF3 (OLD)',
  },
  {
    id: 'lif3-2',
    symbol: 'lif3',
    name: 'Lif3',
  },
  {
    id: 'lif3-lshare',
    symbol: 'lshare',
    name: 'LIF3 LSHARE (OLD)',
  },
  {
    id: 'lif3-lshare-new',
    symbol: 'lshare',
    name: 'LIF3 LSHARE',
  },
  {
    id: 'life-coin',
    symbol: 'lfc',
    name: 'Supernova Shards Life Coin',
  },
  {
    id: 'life-crypto',
    symbol: 'life',
    name: 'Life Crypto',
  },
  {
    id: 'liferestart',
    symbol: 'efil',
    name: 'LifeRestart (Ordinals)',
  },
  {
    id: 'life-token-v2',
    symbol: 'ltnv2',
    name: 'Life v2',
  },
  {
    id: 'lifinity',
    symbol: 'lfnty',
    name: 'Lifinity',
  },
  {
    id: 'lifti',
    symbol: 'lft',
    name: 'Lifti',
  },
  {
    id: 'lightcoin',
    symbol: 'lhc',
    name: 'Lightcoin',
  },
  {
    id: 'lightcycle',
    symbol: 'lilc',
    name: 'LightCycle',
  },
  {
    id: 'light-defi',
    symbol: 'light',
    name: 'Light Defi',
  },
  {
    id: 'lightning-bitcoin',
    symbol: 'lbtc',
    name: 'Lightning Bitcoin',
  },
  {
    id: 'lightning-bot',
    symbol: 'light',
    name: 'Lightning Bot',
  },
  {
    id: 'lightning-protocol',
    symbol: 'light',
    name: 'Lightning Protocol',
  },
  {
    id: 'light-token',
    symbol: 'lt',
    name: 'Hope.money LightDAO',
  },
  {
    id: 'lightyears',
    symbol: 'year',
    name: 'Lightyears',
  },
  {
    id: 'ligo-ordinals',
    symbol: 'ligo',
    name: 'Ligo (Ordinals)',
  },
  {
    id: 'likecoin',
    symbol: 'like',
    name: 'LikeCoin',
  },
  {
    id: 'lilai',
    symbol: 'lilai',
    name: 'LilAI',
  },
  {
    id: 'lillian-token',
    symbol: 'lyf',
    name: 'Lillian Token',
  },
  {
    id: 'limestone-network',
    symbol: 'limex',
    name: 'Limestone Network',
  },
  {
    id: 'limewire-token',
    symbol: 'lmwr',
    name: 'LimeWire',
  },
  {
    id: 'limocoin-swap',
    symbol: 'lmcswap',
    name: 'Limocoin Swap',
  },
  {
    id: 'limoverse',
    symbol: 'limo',
    name: 'Limoverse',
  },
  {
    id: 'lina',
    symbol: 'lina',
    name: 'LINA',
  },
  {
    id: 'linda-2',
    symbol: 'linda',
    name: 'Linda',
  },
  {
    id: 'lineabank',
    symbol: 'lab',
    name: 'LineaBank',
  },
  {
    id: 'linear',
    symbol: 'lina',
    name: 'Linear',
  },
  {
    id: 'linear-protocol',
    symbol: 'linear',
    name: 'LiNEAR Protocol Staked NEAR',
  },
  {
    id: 'linear-protocol-lnr',
    symbol: 'lnr',
    name: 'LiNEAR Protocol LNR',
  },
  {
    id: 'linea-velocore',
    symbol: 'lvc',
    name: 'Linea Velocore',
  },
  {
    id: 'linea-voyage-xp',
    symbol: 'lxp',
    name: 'Linea Voyage XP',
  },
  {
    id: 'linework-coin',
    symbol: 'lwc',
    name: 'Linework Coin',
  },
  {
    id: 'lingose',
    symbol: 'ling',
    name: 'Lingose',
  },
  {
    id: 'link',
    symbol: 'fnsa',
    name: 'FINSCHIA',
  },
  {
    id: 'linkdao',
    symbol: 'lkd',
    name: 'LinkDao',
  },
  {
    id: 'linkeye',
    symbol: 'let',
    name: 'Linkeye',
  },
  {
    id: 'linkfi',
    symbol: 'linkfi',
    name: 'LINKFI',
  },
  {
    id: 'linkpool',
    symbol: 'lpl',
    name: 'LinkPool',
  },
  {
    id: 'links',
    symbol: 'links',
    name: 'Links',
  },
  {
    id: 'link-yvault',
    symbol: 'yvlink',
    name: 'LINK yVault',
  },
  {
    id: 'linq',
    symbol: 'linq',
    name: 'Linq',
  },
  {
    id: 'lionceo',
    symbol: 'lceo',
    name: 'LionCEO',
  },
  {
    id: 'lion-dao',
    symbol: 'roar',
    name: 'Lion DAO',
  },
  {
    id: 'liondex',
    symbol: 'lion',
    name: 'LionDEX',
  },
  {
    id: 'lion-scrub-money-2',
    symbol: 'lion',
    name: 'Lion Scrub Money',
  },
  {
    id: 'lion-token',
    symbol: 'lion',
    name: 'Lion',
  },
  {
    id: 'liq-protocol',
    symbol: 'liq',
    name: 'LIQ Protocol',
  },
  {
    id: 'liquicats',
    symbol: 'meow',
    name: 'LiquiCats',
  },
  {
    id: 'liquid-astr',
    symbol: 'nastr',
    name: 'Liquid ASTR',
  },
  {
    id: 'liquid-atom',
    symbol: 'latom',
    name: 'Liquid ATOM',
  },
  {
    id: 'liquid-collectibles',
    symbol: 'lico',
    name: 'Liquid Collectibles',
  },
  {
    id: 'liquid-cro',
    symbol: 'lcro',
    name: 'Liquid CRO',
  },
  {
    id: 'liquid-crypto',
    symbol: 'lqdx',
    name: 'Liquid Crypto',
  },
  {
    id: 'liquiddriver',
    symbol: 'lqdr',
    name: 'LiquidDriver',
  },
  {
    id: 'liquid-driver-liveretro',
    symbol: 'liveretro',
    name: 'Liquid Driver liveRETRO',
  },
  {
    id: 'liquid-driver-livethe',
    symbol: 'livethe',
    name: 'Liquid Driver liveTHE',
  },
  {
    id: 'liquid-finance',
    symbol: 'liqd',
    name: 'Liquid Finance',
  },
  {
    id: 'liquid-finance-arch',
    symbol: 'sarch',
    name: 'Liquid Finance ARCH',
  },
  {
    id: 'liquidifty',
    symbol: 'lqt',
    name: 'Lifty',
  },
  {
    id: 'liquidify-077fd783-dead-4809-b5a9-0d9876f6ea5c',
    symbol: 'liquid',
    name: 'Liquidify',
  },
  {
    id: 'liquidity-money',
    symbol: 'lim',
    name: 'Liquidity.money',
  },
  {
    id: 'liquidityrush',
    symbol: 'liqr',
    name: 'LiquidityRush',
  },
  {
    id: 'liquid-ksm',
    symbol: 'lksm',
    name: 'Liquid KSM',
  },
  {
    id: 'liquidlayer',
    symbol: 'lila',
    name: 'LiquidLayer',
  },
  {
    id: 'liquid-loans',
    symbol: 'loan',
    name: 'Liquid Loans',
  },
  {
    id: 'liquid-loans-usdl',
    symbol: 'usdl',
    name: 'Liquid Loans USDL',
  },
  {
    id: 'liquid-mercury',
    symbol: 'merc',
    name: 'Liquid Mercury',
  },
  {
    id: 'liquid-protocol',
    symbol: 'lp',
    name: 'Liquid Protocol',
  },
  {
    id: 'liquid-savings-dai',
    symbol: 'lsdai',
    name: 'Liquid Savings DAI',
  },
  {
    id: 'liquid-share',
    symbol: 'lshare',
    name: 'Liquid Share',
  },
  {
    id: 'liquid-solana-derivative',
    symbol: 'lsd',
    name: 'Liquid Solana Derivative',
  },
  {
    id: 'liquid-staked-canto',
    symbol: 'scanto',
    name: 'Liquid Staked Canto',
  },
  {
    id: 'liquid-staked-ethereum',
    symbol: 'lseth',
    name: 'Liquid Staked ETH',
  },
  {
    id: 'liquid-staked-flow',
    symbol: 'stflow',
    name: 'Increment Staked FLOW',
  },
  {
    id: 'liquid-staking-crescent',
    symbol: 'bcre',
    name: 'Liquid Staking Crescent',
  },
  {
    id: 'liquid-staking-derivative',
    symbol: 'lsd',
    name: 'Liquid Staking Derivative',
  },
  {
    id: 'liquid-staking-dot',
    symbol: 'ldot',
    name: 'Liquid Staking Dot',
  },
  {
    id: 'liquid-staking-index',
    symbol: 'lsi',
    name: 'Liquid Staking Index',
  },
  {
    id: 'liquid-staking-token',
    symbol: 'lst',
    name: 'Liquid Staking Token',
  },
  {
    id: 'liquidus',
    symbol: 'liq',
    name: 'Liquidus (Old)',
  },
  {
    id: 'liquidus-2',
    symbol: 'liq',
    name: 'Liquidus',
  },
  {
    id: 'liquify-network',
    symbol: 'liquify',
    name: 'Liquify Network',
  },
  {
    id: 'liquis',
    symbol: 'liq',
    name: 'Liquis',
  },
  {
    id: 'liquishield',
    symbol: 'liqs',
    name: 'LiquiShield',
  },
  {
    id: 'liquity',
    symbol: 'lqty',
    name: 'Liquity',
  },
  {
    id: 'liquity-usd',
    symbol: 'lusd',
    name: 'Liquity USD',
  },
  {
    id: 'liqwid-finance',
    symbol: 'lq',
    name: 'Liqwid Finance',
  },
  {
    id: 'liqwrap',
    symbol: 'lqw',
    name: 'LiqWrap',
  },
  {
    id: 'lirat',
    symbol: 'tryt',
    name: 'LiraT',
  },
  {
    id: 'lisk',
    symbol: 'lsk',
    name: 'Lisk',
  },
  {
    id: 'lit',
    symbol: 'lit',
    name: 'LIT',
  },
  {
    id: 'lite',
    symbol: 'lite',
    name: 'LITE',
  },
  {
    id: 'litecash',
    symbol: 'cash',
    name: 'Litecash',
  },
  {
    id: 'litecoin',
    symbol: 'ltc',
    name: 'Litecoin',
  },
  {
    id: 'litecoin-cash',
    symbol: 'lcc',
    name: 'Litecoin Cash',
  },
  {
    id: 'litecoin-plus',
    symbol: 'spb',
    name: 'SpiderByte',
  },
  {
    id: 'litecoinz',
    symbol: 'ltz',
    name: 'LitecoinZ',
  },
  {
    id: 'litedoge',
    symbol: 'ldoge',
    name: 'LiteDoge',
  },
  {
    id: 'litentry',
    symbol: 'lit',
    name: 'Litentry',
  },
  {
    id: 'litherium',
    symbol: 'lith',
    name: 'Litherium',
  },
  {
    id: 'lithium-finance',
    symbol: 'lith',
    name: 'Lithium Finance',
  },
  {
    id: 'lithium-ventures',
    symbol: 'ions',
    name: 'Lithium Ventures',
  },
  {
    id: 'lithosphere',
    symbol: 'litho',
    name: 'Lithosphere',
  },
  {
    id: 'litlab-games',
    symbol: 'litt',
    name: 'LitLab Games',
  },
  {
    id: 'little-angry-bunny-v2',
    symbol: 'lab-v2',
    name: 'Little Angry Bunny v2',
  },
  {
    id: 'little-bunny-rocket',
    symbol: 'lbr',
    name: 'Little Bunny Rocket',
  },
  {
    id: 'littleinu',
    symbol: 'linu',
    name: 'LittleInu',
  },
  {
    id: 'little-rabbit-v2',
    symbol: 'ltrbt',
    name: 'Little Rabbit V2',
  },
  {
    id: 'little-ugly-duck',
    symbol: 'lud',
    name: 'Little Ugly Duck',
  },
  {
    id: 'livegreen-coin',
    symbol: 'lgc',
    name: 'LiveGreen Coin',
  },
  {
    id: 'livepeer',
    symbol: 'lpt',
    name: 'Livepeer',
  },
  {
    id: 'livestreambets',
    symbol: 'live',
    name: 'Livestreambets',
  },
  {
    id: 'livex-network',
    symbol: 'live',
    name: 'Livex Network',
  },
  {
    id: 'liza-2',
    symbol: 'liza',
    name: 'LIZA',
  },
  {
    id: 'lizard',
    symbol: 'lizard',
    name: 'Lizard',
  },
  {
    id: 'lizardtoken-finance',
    symbol: 'liz',
    name: 'LizardToken.Finance',
  },
  {
    id: 'lmeow',
    symbol: 'lmeow',
    name: 'lmeow',
  },
  {
    id: 'lndry',
    symbol: 'lndry',
    name: 'LNDRY',
  },
  {
    id: 'loaf-cat',
    symbol: 'loaf',
    name: 'LOAF CAT',
  },
  {
    id: 'lobster',
    symbol: '$lobster',
    name: 'LOBSTER',
  },
  {
    id: 'localcoinswap',
    symbol: 'lcs',
    name: 'LocalCoinSwap',
  },
  {
    id: 'local-money',
    symbol: 'local',
    name: 'Local Money',
  },
  {
    id: 'localtrade',
    symbol: 'ltt',
    name: 'LocalTrade',
  },
  {
    id: 'local-traders',
    symbol: 'lct',
    name: 'Local Traders',
  },
  {
    id: 'locgame',
    symbol: '$locg',
    name: 'LOCG',
  },
  {
    id: 'lockchain',
    symbol: 'loc',
    name: 'LockTrip',
  },
  {
    id: 'lockheed-martin-inu',
    symbol: 'lmi',
    name: 'Lockheed Martin Inu',
  },
  {
    id: 'lockness',
    symbol: 'lkn',
    name: 'Lockness',
  },
  {
    id: 'lockon-passive-index',
    symbol: 'lpi',
    name: 'LOCKON Passive Index',
  },
  {
    id: 'locus-chain',
    symbol: 'locus',
    name: 'Locus Chain',
  },
  {
    id: 'locus-finance',
    symbol: 'locus',
    name: 'Locus Finance',
  },
  {
    id: 'lodestar',
    symbol: 'lode',
    name: 'Lodestar',
  },
  {
    id: 'lode-token',
    symbol: 'lode',
    name: 'LODE Token',
  },
  {
    id: 'lofi',
    symbol: 'lofi',
    name: 'LOFI',
  },
  {
    id: 'logarithm-games',
    symbol: 'logg',
    name: 'Logarithm games',
  },
  {
    id: 'logic',
    symbol: 'logic',
    name: 'LOGIC',
  },
  {
    id: 'loki-network',
    symbol: 'oxen',
    name: 'Oxen',
  },
  {
    id: 'lokr',
    symbol: 'lkr',
    name: 'Lokr',
  },
  {
    id: 'lola',
    symbol: 'lola',
    name: 'LOLA',
  },
  {
    id: 'lolik-staked-ftn',
    symbol: 'stftn',
    name: 'Lolik Staked FTN',
  },
  {
    id: 'lonelyfans',
    symbol: 'lof',
    name: 'LonelyFans',
  },
  {
    id: 'long',
    symbol: 'long',
    name: 'LONG',
  },
  {
    id: 'long-2',
    symbol: 'long',
    name: 'Long 龙',
  },
  {
    id: 'long-bitcoin',
    symbol: 'long',
    name: 'Long Bitcoin',
  },
  {
    id: 'longchenchen',
    symbol: 'long',
    name: 'Longchenchen',
  },
  {
    id: 'long-eth',
    symbol: 'long',
    name: 'LONG (ETH)',
  },
  {
    id: 'long-nose-dog',
    symbol: 'long',
    name: 'Long Nose Dog',
  },
  {
    id: 'lonk-on-near',
    symbol: 'lonk',
    name: 'Lonk',
  },
  {
    id: 'lookscoin',
    symbol: 'look',
    name: 'LooksCoin',
  },
  {
    id: 'looksrare',
    symbol: 'looks',
    name: 'LooksRare',
  },
  {
    id: 'loom-network',
    symbol: 'loomold',
    name: 'Loom Network (OLD)',
  },
  {
    id: 'loom-network-new',
    symbol: 'loom',
    name: 'Loom Network (NEW)',
  },
  {
    id: 'loong',
    symbol: 'loong',
    name: 'Loong',
  },
  {
    id: 'loong-2',
    symbol: 'loong',
    name: 'Loong',
  },
  {
    id: 'loong-2024',
    symbol: 'loong',
    name: 'LOONG 2024',
  },
  {
    id: 'loon-network',
    symbol: 'loon',
    name: 'Loon Network',
  },
  {
    id: 'loop',
    symbol: 'loop',
    name: 'LOOP',
  },
  {
    id: 'loopnetwork',
    symbol: 'loop',
    name: 'LoopNetwork',
  },
  {
    id: 'loopring',
    symbol: 'lrc',
    name: 'Loopring',
  },
  {
    id: 'loopswap',
    symbol: 'lswap',
    name: 'LoopSwap',
  },
  {
    id: 'loot',
    symbol: 'loot',
    name: 'Lootex',
  },
  {
    id: 'lootbot',
    symbol: 'loot',
    name: 'LootBot',
  },
  {
    id: 'lopo',
    symbol: 'lopo',
    name: 'LOPO',
  },
  {
    id: 'lord-of-dragons',
    symbol: 'logt',
    name: 'Lord of Dragons',
  },
  {
    id: 'lords',
    symbol: 'lords',
    name: 'LORDS',
  },
  {
    id: 'loserchick-egg',
    symbol: 'egg',
    name: 'LoserChick EGG',
  },
  {
    id: 'loser-coin',
    symbol: 'lowb',
    name: 'Loser Coin',
  },
  {
    id: 'lossless',
    symbol: 'lss',
    name: 'Lossless',
  },
  {
    id: 'lost-world',
    symbol: 'lost',
    name: 'Lost World',
  },
  {
    id: 'lotty',
    symbol: 'lotty',
    name: 'Lotty',
  },
  {
    id: 'loungem',
    symbol: 'lzm',
    name: 'LoungeM',
  },
  {
    id: 'love-earn-enjoy',
    symbol: 'lee',
    name: 'Love Earn Enjoy',
  },
  {
    id: 'love-hate-inu',
    symbol: 'lhinu',
    name: 'Love Hate Inu',
  },
  {
    id: 'love-io',
    symbol: 'love',
    name: 'Love.io',
  },
  {
    id: 'lovely-inu-finance',
    symbol: 'lovely',
    name: 'Lovely Inu finance',
  },
  {
    id: 'lox-network',
    symbol: 'lox',
    name: 'Lox Network',
  },
  {
    id: 'loyalty-labs',
    symbol: 'loyal',
    name: 'Loyalty Labs',
  },
  {
    id: 'lp-3pool-curve',
    symbol: '3crv',
    name: 'LP 3pool Curve',
  },
  {
    id: 'lp-renbtc-curve',
    symbol: 'renbtccurve',
    name: 'LP renBTC Curve',
  },
  {
    id: 'lp-scurve',
    symbol: 'scurve',
    name: 'LP-sCurve',
  },
  {
    id: 'lp-yearn-crv-vault',
    symbol: 'lp-ycrv',
    name: 'LP Yearn CRV Vault',
  },
  {
    id: 'lsdoge',
    symbol: 'lsdoge',
    name: 'LSDoge',
  },
  {
    id: 'lsdx-finance',
    symbol: 'lsd',
    name: 'LSDx Finance',
  },
  {
    id: 'lsdx-pool',
    symbol: 'ethx',
    name: 'LSDx Pool',
  },
  {
    id: 'lto-network',
    symbol: 'lto',
    name: 'LTO Network',
  },
  {
    id: 'ltradex',
    symbol: 'ltex',
    name: 'Ltradex',
  },
  {
    id: 'lua-token',
    symbol: 'lua',
    name: 'LuaSwap',
  },
  {
    id: 'lube',
    symbol: 'lube',
    name: 'LUBE',
  },
  {
    id: 'luca',
    symbol: 'luca',
    name: 'LUCA',
  },
  {
    id: 'lucha',
    symbol: 'lucha',
    name: 'Lucha',
  },
  {
    id: 'lucidao',
    symbol: 'lcd',
    name: 'Lucidao',
  },
  {
    id: 'lucky7',
    symbol: '7',
    name: 'Lucky7',
  },
  {
    id: 'lucky8',
    symbol: '888',
    name: 'Lucky8',
  },
  {
    id: 'luckybird',
    symbol: 'bird',
    name: 'LuckyBird',
  },
  {
    id: 'lucky-block',
    symbol: 'lblock',
    name: 'Lucky Block',
  },
  {
    id: 'luckycoin-2',
    symbol: 'lkc',
    name: 'LuckyCoin',
  },
  {
    id: 'lucky-roo',
    symbol: 'roo',
    name: 'Lucky Roo',
  },
  {
    id: 'luckysleprecoin',
    symbol: 'luckyslp',
    name: 'LuckysLeprecoin',
  },
  {
    id: 'luckytoad',
    symbol: 'toad',
    name: 'LuckyToad',
  },
  {
    id: 'lucretius',
    symbol: 'luc',
    name: 'Lucretius',
  },
  {
    id: 'lucro',
    symbol: 'lcr',
    name: 'Lucro',
  },
  {
    id: 'lucrosus-capital',
    symbol: '$luca',
    name: 'Lucrosus Capital',
  },
  {
    id: 'ludena-protocol',
    symbol: 'ldn',
    name: 'Ludena Protocol',
  },
  {
    id: 'ludos',
    symbol: 'lud',
    name: 'Ludos Protocol',
  },
  {
    id: 'luffy-inu',
    symbol: 'luffy',
    name: 'Luffy',
  },
  {
    id: 'luigiswap',
    symbol: 'luigi',
    name: 'LuigiSwap',
  },
  {
    id: 'lukso-token',
    symbol: 'lyxe',
    name: 'LUKSO [OLD]',
  },
  {
    id: 'lukso-token-2',
    symbol: 'lyx',
    name: 'LUKSO',
  },
  {
    id: 'lumenswap',
    symbol: 'lsp',
    name: 'Lumenswap',
  },
  {
    id: 'lumerin',
    symbol: 'lmr',
    name: 'Lumerin',
  },
  {
    id: 'lumi',
    symbol: 'lumi',
    name: 'LUMI',
  },
  {
    id: 'lumi-credits',
    symbol: 'lumi',
    name: 'LUMI Credits',
  },
  {
    id: 'lumi-finance',
    symbol: 'lua',
    name: 'Lumi Finance',
  },
  {
    id: 'lumi-finance-governance-token',
    symbol: 'luag',
    name: 'Lumi Finance Governance Token',
  },
  {
    id: 'lumi-finance-lua-option',
    symbol: 'luaop',
    name: 'Lumi Finance LUA Option',
  },
  {
    id: 'lumi-finance-luausd',
    symbol: 'luausd',
    name: 'Lumi Finance LUAUSD',
  },
  {
    id: 'lumina-bot',
    symbol: 'lbot',
    name: 'Lumina Bot',
  },
  {
    id: 'lumishare',
    symbol: 'lumi',
    name: 'Lumishare',
  },
  {
    id: 'lum-network',
    symbol: 'lum',
    name: 'Lum Network',
  },
  {
    id: 'luna28',
    symbol: '$luna',
    name: 'Luna28',
  },
  {
    id: 'lunachow',
    symbol: 'luchow',
    name: 'LunaChow',
  },
  {
    id: 'lunadoge',
    symbol: 'loge',
    name: 'LunaDoge',
  },
  {
    id: 'lunafi',
    symbol: 'lfi',
    name: 'Lunafi',
  },
  {
    id: 'lunagens',
    symbol: 'lung',
    name: 'LunaGens',
  },
  {
    id: 'luna-inu',
    symbol: 'linu',
    name: 'Luna Inu',
  },
  {
    id: 'lunaone',
    symbol: 'xln',
    name: 'LunaOne',
  },
  {
    id: 'lunar',
    symbol: 'lnr',
    name: 'Lunar [OLD]',
  },
  {
    id: 'lunar-2',
    symbol: 'lnr',
    name: 'Lunar',
  },
  {
    id: 'lunarium',
    symbol: 'xln',
    name: 'Lunarium',
  },
  {
    id: 'luna-rush',
    symbol: 'lus',
    name: 'Luna Rush',
  },
  {
    id: 'lunatics',
    symbol: 'lunat',
    name: 'Lunatics',
  },
  {
    id: 'lunatics-eth',
    symbol: 'lunat',
    name: 'Lunatics [ETH]',
  },
  {
    id: 'luna-wormhole',
    symbol: 'lunc',
    name: 'Terra Classic (Wormhole)',
  },
  {
    id: 'lunax',
    symbol: 'lunax',
    name: 'Stader LunaX',
  },
  {
    id: 'luncarmy',
    symbol: 'luncarmy',
    name: 'LUNCARMY',
  },
  {
    id: 'lunchdao',
    symbol: 'lunch',
    name: 'LunchDAO',
  },
  {
    id: 'lunch-money',
    symbol: 'lmy',
    name: 'Lunch Money',
  },
  {
    id: 'lunes',
    symbol: 'lunes',
    name: 'Lunes',
  },
  {
    id: 'lunr-token',
    symbol: 'lunr',
    name: 'Lunr',
  },
  {
    id: 'lunyr',
    symbol: 'lun',
    name: 'Lunyr',
  },
  {
    id: 'lusd',
    symbol: 'lusd',
    name: 'LUSD [OLD]',
  },
  {
    id: 'lusd-2',
    symbol: 'lusd',
    name: 'LUSD',
  },
  {
    id: 'lusd-yvault',
    symbol: 'yvlusd',
    name: 'LUSD yVault',
  },
  {
    id: 'lux-bio-exchange-coin',
    symbol: 'lbxc',
    name: 'LUX BIO EXCHANGE COIN',
  },
  {
    id: 'luxcoin',
    symbol: 'lux',
    name: 'LUXCoin',
  },
  {
    id: 'luxo',
    symbol: 'luxo',
    name: 'Luxo',
  },
  {
    id: 'luxurious-pro-network-token',
    symbol: 'lpnt',
    name: 'Luxurious Pro Network',
  },
  {
    id: 'luxy',
    symbol: 'luxy',
    name: 'Luxy',
  },
  {
    id: 'lvusd',
    symbol: 'lvusd',
    name: 'lvUSD',
  },
  {
    id: 'lybra-finance',
    symbol: 'lbr',
    name: 'Lybra',
  },
  {
    id: 'lydia-finance',
    symbol: 'lyd',
    name: 'Lydia Finance',
  },
  {
    id: 'lyfe-2',
    symbol: 'lyfe',
    name: 'Lyfe',
  },
  {
    id: 'lyfebloc',
    symbol: 'lbt',
    name: 'Lyfebloc',
  },
  {
    id: 'lyfe-gold',
    symbol: 'lgold',
    name: 'Lyfe Gold',
  },
  {
    id: 'lyfe-silver',
    symbol: 'lsilver',
    name: 'Lyfe Silver',
  },
  {
    id: 'lympo',
    symbol: 'lym',
    name: 'Lympo',
  },
  {
    id: 'lympo-market-token',
    symbol: 'lmt',
    name: 'Lympo Market',
  },
  {
    id: 'lyncoin',
    symbol: 'lcn',
    name: 'Lyncoin',
  },
  {
    id: 'lynex',
    symbol: 'lynx',
    name: 'Lynex',
  },
  {
    id: 'lynkey',
    symbol: 'lynk',
    name: 'LynKey',
  },
  {
    id: 'lynx',
    symbol: 'lynx',
    name: 'Lynx',
  },
  {
    id: 'lynx-2',
    symbol: 'lynx',
    name: 'LYNX',
  },
  {
    id: 'lyptus-token',
    symbol: 'lyptus',
    name: 'Lyptus',
  },
  {
    id: 'lyra-2',
    symbol: 'lyra',
    name: 'Lyra',
  },
  {
    id: 'lyra-finance',
    symbol: 'lyra',
    name: 'Lyra Finance',
  },
  {
    id: 'lyve-finance',
    symbol: 'lyve',
    name: 'Lyve Finance',
  },
  {
    id: 'lyzi',
    symbol: 'lyzi',
    name: 'Lyzi',
  },
  {
    id: 'm2',
    symbol: 'm2',
    name: 'M2',
  },
  {
    id: 'm2-global-wealth-limited-mmx',
    symbol: 'mmx',
    name: 'MMX',
  },
  {
    id: 'maal-chain',
    symbol: 'maal',
    name: 'Maal Chain',
  },
  {
    id: 'macaronswap',
    symbol: 'mcrn',
    name: 'MacaronSwap',
  },
  {
    id: 'mackerel',
    symbol: 'macks',
    name: 'Mackerel',
  },
  {
    id: 'mackerel-2',
    symbol: 'macke',
    name: 'Mackerel',
  },
  {
    id: 'mad',
    symbol: 'mad',
    name: 'MAD',
  },
  {
    id: 'madai',
    symbol: 'madai',
    name: 'Morpho-Aave Dai Stablecoin',
  },
  {
    id: 'madape',
    symbol: 'madape',
    name: 'MadApe',
  },
  {
    id: 'mad-bears-club',
    symbol: 'bear',
    name: 'Mad Bears Club [OLD]',
  },
  {
    id: 'mad-bears-club-2',
    symbol: 'mbc',
    name: 'Mad Bears Club',
  },
  {
    id: 'mad-bucks',
    symbol: 'mad',
    name: 'MAD Bucks',
  },
  {
    id: 'made-in-real-life',
    symbol: 'mirl',
    name: 'Made In Real Life',
  },
  {
    id: 'mad-meerkat-etf',
    symbol: 'metf',
    name: 'Mad Meerkat ETF',
  },
  {
    id: 'mad-meerkat-optimizer',
    symbol: 'mmo',
    name: 'Mad Meerkat Optimizer',
  },
  {
    id: 'mad-meerkat-optimizer-polygon',
    symbol: 'mmo',
    name: 'Mad Meerkat Optimizer (Polygon)',
  },
  {
    id: 'mad-usd',
    symbol: 'musd',
    name: 'Mad USD',
  },
  {
    id: 'mad-viking-games-token',
    symbol: 'mvg',
    name: 'Mad Viking Games Token',
  },
  {
    id: 'madworld',
    symbol: 'umad',
    name: 'MADworld',
  },
  {
    id: 'maga',
    symbol: 'trump',
    name: 'MAGA',
  },
  {
    id: 'maga-2',
    symbol: 'trump',
    name: 'MAGA',
  },
  {
    id: 'maga-coin',
    symbol: 'maga',
    name: 'MAGA Coin BSC',
  },
  {
    id: 'maga-coin-eth',
    symbol: 'maga',
    name: 'MAGA Coin ETH',
  },
  {
    id: 'magic',
    symbol: 'magic',
    name: 'Magic',
  },
  {
    id: 'magical-blocks',
    symbol: 'mblk',
    name: 'Magical Blocks',
  },
  {
    id: 'magicaltux',
    symbol: 'tux',
    name: 'Magicaltux',
  },
  {
    id: 'magic-beasties',
    symbol: 'bsts',
    name: 'Magic Beasties',
  },
  {
    id: 'magic-bot',
    symbol: 'magic',
    name: 'Magic-BOT',
  },
  {
    id: 'magiccraft',
    symbol: 'mcrt',
    name: 'MagicCraft',
  },
  {
    id: 'magic-cube',
    symbol: 'mcc',
    name: 'Magic Cube Coin',
  },
  {
    id: 'magicglp',
    symbol: 'magicglp',
    name: 'MagicGLP',
  },
  {
    id: 'magic-gpt-game',
    symbol: 'mgpt',
    name: 'Magic GPT Game',
  },
  {
    id: 'magic-internet-cash',
    symbol: 'mic',
    name: 'Magic Internet Cash',
  },
  {
    id: 'magic-internet-money',
    symbol: 'mim',
    name: 'Magic Internet Money',
  },
  {
    id: 'magic-power',
    symbol: 'mgp',
    name: 'Magic Power',
  },
  {
    id: 'magic-shiba-starter',
    symbol: 'mshib',
    name: 'Magic Shiba Starter',
  },
  {
    id: 'magic-shoes',
    symbol: 'mct',
    name: 'MAGIC SHOES',
  },
  {
    id: 'magic-square',
    symbol: 'sqr',
    name: 'Magic Square',
  },
  {
    id: 'magic-token',
    symbol: 'magic',
    name: 'MagicLand',
  },
  {
    id: 'magic-yearn-share',
    symbol: 'mys',
    name: 'Magic Yearn Share',
  },
  {
    id: 'magik',
    symbol: 'magik',
    name: 'Magik',
  },
  {
    id: 'magikal-ai',
    symbol: 'mgkl',
    name: 'MAGIKAL.ai',
  },
  {
    id: 'magnate-finance',
    symbol: 'mag',
    name: 'Magnate Finance',
  },
  {
    id: 'magnesium',
    symbol: 'mag',
    name: 'Magnesium',
  },
  {
    id: 'magnetgold',
    symbol: 'mtg',
    name: 'MagnetGold',
  },
  {
    id: 'magnetic',
    symbol: 'mag',
    name: 'Magnetic',
  },
  {
    id: 'magnum-2',
    symbol: 'mag',
    name: 'Magnum',
  },
  {
    id: 'magnus',
    symbol: 'mag',
    name: 'Magnus',
  },
  {
    id: 'magpie',
    symbol: 'mgp',
    name: 'Magpie',
  },
  {
    id: 'magpie-wom',
    symbol: 'mwom',
    name: 'Magpie WOM',
  },
  {
    id: 'mahadao',
    symbol: 'maha',
    name: 'MahaDAO',
  },
  {
    id: 'maia',
    symbol: 'maia',
    name: 'Maia',
  },
  {
    id: 'maiar-dex',
    symbol: 'mex',
    name: 'xExchange',
  },
  {
    id: 'maidsafecoin',
    symbol: 'emaid',
    name: 'MaidSafeCoin',
  },
  {
    id: 'maidsafecoin-token',
    symbol: 'maid',
    name: 'Maidsafecoin Token',
  },
  {
    id: 'main',
    symbol: 'main',
    name: 'Main',
  },
  {
    id: 'mainframe',
    symbol: 'mft',
    name: 'Mainframe',
  },
  {
    id: 'mainnetz',
    symbol: 'netz',
    name: 'MainnetZ',
  },
  {
    id: 'mainstream-for-the-underground',
    symbol: 'mftu',
    name: 'Mainstream For The Underground',
  },
  {
    id: 'majo',
    symbol: 'majo',
    name: 'Majo',
  },
  {
    id: 'majority-blockchain',
    symbol: 'tmc',
    name: 'Majority Blockchain',
  },
  {
    id: 'makalink',
    symbol: 'maka',
    name: 'Makalink',
  },
  {
    id: 'make-ethereum-great-again',
    symbol: '$mega',
    name: 'Make Ethereum Great Again',
  },
  {
    id: 'maker',
    symbol: 'mkr',
    name: 'Maker',
  },
  {
    id: 'makerx',
    symbol: 'mkx',
    name: 'MakerX',
  },
  {
    id: 'make-solana-great-again',
    symbol: '$trump',
    name: 'Make Solana Great Again',
  },
  {
    id: 'makiswap',
    symbol: 'maki',
    name: 'MakiSwap',
  },
  {
    id: 'malaysian-ringgit-coin',
    symbol: 'myrc',
    name: 'Malaysian Ringgit Coin',
  },
  {
    id: 'malgo-finance',
    symbol: 'mgxg',
    name: 'Malgo Finance',
  },
  {
    id: 'malinka',
    symbol: 'mlnk',
    name: 'Malinka',
  },
  {
    id: 'mammoth-2',
    symbol: 'wooly',
    name: 'Mammoth',
  },
  {
    id: 'manacoin',
    symbol: 'mnc',
    name: 'ManaCoin',
  },
  {
    id: 'manchester-city-fan-token',
    symbol: 'city',
    name: 'Manchester City Fan Token',
  },
  {
    id: 'mancium',
    symbol: 'manc',
    name: 'Mancium',
  },
  {
    id: 'mandox-2',
    symbol: 'mandox',
    name: 'MandoX',
  },
  {
    id: 'mane',
    symbol: 'mane',
    name: 'MANE',
  },
  {
    id: 'maneki-neko',
    symbol: 'neki',
    name: 'Maneki-neko',
  },
  {
    id: 'mangata-x',
    symbol: 'mgx',
    name: 'Mangata X',
  },
  {
    id: 'manga-token',
    symbol: '$manga',
    name: 'Manga',
  },
  {
    id: 'mangoman-intelligent',
    symbol: 'mmit',
    name: 'MangoMan Intelligent',
  },
  {
    id: 'mango-markets',
    symbol: 'mngo',
    name: 'Mango',
  },
  {
    id: 'manifold-finance',
    symbol: 'fold',
    name: 'Manifold Finance',
  },
  {
    id: 'man-man-man',
    symbol: 'man',
    name: 'MAN MAN MAN',
  },
  {
    id: 'manna',
    symbol: 'manna',
    name: 'Manna',
  },
  {
    id: 'mantadao',
    symbol: 'mnta',
    name: 'MantaDAO',
  },
  {
    id: 'manta-network',
    symbol: 'manta',
    name: 'Manta Network',
  },
  {
    id: 'mantaswap',
    symbol: 'mts',
    name: 'MantaSwap',
  },
  {
    id: 'mante',
    symbol: 'mante',
    name: 'Mante',
  },
  {
    id: 'mantis-network',
    symbol: 'mntis',
    name: 'Mantis Network',
  },
  {
    id: 'mantle',
    symbol: 'mnt',
    name: 'Mantle',
  },
  {
    id: 'mantle-doge',
    symbol: 'mandoge',
    name: 'Mantle Doge',
  },
  {
    id: 'mantle-inu',
    symbol: 'minu',
    name: 'Mantle Inu',
  },
  {
    id: 'mantle-staked-ether',
    symbol: 'meth',
    name: 'Mantle Staked Ether',
  },
  {
    id: 'mantle-usd',
    symbol: 'musd',
    name: 'Mantle USD',
  },
  {
    id: 'mantra-dao',
    symbol: 'om',
    name: 'MANTRA',
  },
  {
    id: 'manufactory-2',
    symbol: 'mnft',
    name: 'ManuFactory',
  },
  {
    id: 'maorabbit',
    symbol: 'maorabbit',
    name: 'MaoRabbit',
  },
  {
    id: 'maple',
    symbol: 'mpl',
    name: 'Maple',
  },
  {
    id: 'map-node',
    symbol: 'mni',
    name: 'Map Node',
  },
  {
    id: 'maps',
    symbol: 'maps',
    name: 'MAPS',
  },
  {
    id: 'mar3-ai',
    symbol: 'mar3',
    name: 'MAR3 AI',
  },
  {
    id: 'maranbet',
    symbol: 'maran',
    name: 'MaranBet',
  },
  {
    id: 'marbledao-artex',
    symbol: 'artex',
    name: 'MarbleDAO ARTEX',
  },
  {
    id: 'marblex',
    symbol: 'mbx',
    name: 'Marblex',
  },
  {
    id: 'marcopolo',
    symbol: 'map',
    name: 'MAP Protocol',
  },
  {
    id: 'mare-finance',
    symbol: 'mare',
    name: 'Mare Finance',
  },
  {
    id: 'margaritis',
    symbol: 'marga',
    name: 'Margaritis',
  },
  {
    id: 'marginswap',
    symbol: 'mfi',
    name: 'Marginswap',
  },
  {
    id: 'marhabadefi',
    symbol: 'mrhb',
    name: 'MarhabaDeFi',
  },
  {
    id: 'maria',
    symbol: 'maria',
    name: 'Maria',
  },
  {
    id: 'maricoin',
    symbol: 'mcoin',
    name: 'MariCoin',
  },
  {
    id: 'marinade',
    symbol: 'mnde',
    name: 'Marinade',
  },
  {
    id: 'market-making-pro',
    symbol: 'mmpro',
    name: 'Market Making Pro',
  },
  {
    id: 'marketpeak',
    symbol: 'peak',
    name: 'PEAKDEFI',
  },
  {
    id: 'marketraker',
    symbol: 'raker',
    name: 'MarketRaker',
  },
  {
    id: 'marketviz',
    symbol: 'viz',
    name: 'MARKETVIZ',
  },
  {
    id: 'mark-friend-tech',
    symbol: 'mark',
    name: 'Mark Jeffrey (Friend.tech)',
  },
  {
    id: 'marksman',
    symbol: 'marks',
    name: 'Marksman',
  },
  {
    id: 'marlin',
    symbol: 'pond',
    name: 'Marlin',
  },
  {
    id: 'marmalade-token',
    symbol: 'mard',
    name: 'Marmalade Token',
  },
  {
    id: 'marmara-credit-loops',
    symbol: 'mcl',
    name: 'Marmara Credit Loops',
  },
  {
    id: 'marnotaur',
    symbol: 'taur',
    name: 'Marnotaur',
  },
  {
    id: 'marpto-ordinals',
    symbol: 'mrpt',
    name: 'MARPTO (Ordinals)',
  },
  {
    id: 'marquee',
    symbol: 'marq',
    name: 'Marquee',
  },
  {
    id: 'mars4',
    symbol: 'mars4',
    name: 'MARS4',
  },
  {
    id: 'marscoin',
    symbol: 'mars',
    name: 'Marscoin',
  },
  {
    id: 'marscolony',
    symbol: 'clny',
    name: 'MarsColony',
  },
  {
    id: 'marsdao',
    symbol: 'mdao',
    name: 'MarsDAO',
  },
  {
    id: 'mars-doginals',
    symbol: 'mars',
    name: 'MARS (DRC-20)',
  },
  {
    id: 'mars-ecosystem-token',
    symbol: 'xms',
    name: 'Mars Ecosystem',
  },
  {
    id: 'marshall-fighting-champio',
    symbol: 'mfc',
    name: 'Marshall Fighting Championship',
  },
  {
    id: 'mars-protocol-a7fcbcfb-fd61-4017-92f0-7ee9f9cc6da3',
    symbol: 'mars',
    name: 'Mars Protocol',
  },
  {
    id: 'marswap',
    symbol: 'mswap',
    name: 'Marswap',
  },
  {
    id: 'marswap-farm',
    symbol: 'mswapf',
    name: 'MARSWAP FARM',
  },
  {
    id: 'martik',
    symbol: 'mtk',
    name: 'Martik',
  },
  {
    id: 'martin-shkreli-inu',
    symbol: 'msi',
    name: 'Martin Shkreli Inu',
  },
  {
    id: 'martkist',
    symbol: 'martk',
    name: 'Martkist',
  },
  {
    id: 'marumarunft',
    symbol: 'maru',
    name: 'marumaruNFT',
  },
  {
    id: 'marutaro',
    symbol: 'maru',
    name: 'MaruTaro',
  },
  {
    id: 'marvellex-classic',
    symbol: 'mlxc',
    name: 'Marvellex Classic',
  },
  {
    id: 'marvellex-venture-token',
    symbol: 'mlxv',
    name: 'Marvellex Venture Token',
  },
  {
    id: 'marvelous-nfts',
    symbol: 'mnft',
    name: 'Marvelous NFTs',
  },
  {
    id: 'marvin',
    symbol: 'marvin',
    name: 'MARVIN',
  },
  {
    id: 'marvin-inu',
    symbol: 'marvin',
    name: 'Marvin Inu',
  },
  {
    id: 'masari',
    symbol: 'msr',
    name: 'Masari',
  },
  {
    id: 'mask-network',
    symbol: 'mask',
    name: 'Mask Network',
  },
  {
    id: 'masq',
    symbol: 'masq',
    name: 'MASQ',
  },
  {
    id: 'mass',
    symbol: 'mass',
    name: 'MASS',
  },
  {
    id: 'massa',
    symbol: 'massa',
    name: 'Massa',
  },
  {
    id: 'massive-protocol',
    symbol: 'mav',
    name: 'Massive Protocol',
  },
  {
    id: 'mass-protocol',
    symbol: 'mass',
    name: 'Mass Protocol',
  },
  {
    id: 'mass-vehicle-ledger',
    symbol: 'mvl',
    name: 'MVL',
  },
  {
    id: 'masterdex',
    symbol: 'mdex',
    name: 'MasterDEX',
  },
  {
    id: 'mastermind',
    symbol: 'mastermind',
    name: 'Mastermind',
  },
  {
    id: 'masters-of-the-memes',
    symbol: 'mom',
    name: 'Masters Of The Memes',
  },
  {
    id: 'masterwin',
    symbol: 'mw',
    name: 'MasterWin',
  },
  {
    id: 'mastery-of-monsters',
    symbol: 'mom',
    name: 'Mastery of Monsters',
  },
  {
    id: 'matchcup',
    symbol: 'match',
    name: 'Matchcup',
  },
  {
    id: 'match-finance-eslbr',
    symbol: 'meslbr',
    name: 'Match Finance esLBR',
  },
  {
    id: 'matchnova-champion-coin',
    symbol: 'mcc',
    name: 'MatchNova Champion Coin',
  },
  {
    id: 'match-token',
    symbol: 'match',
    name: 'Match Token',
  },
  {
    id: 'matchtrade',
    symbol: 'match',
    name: 'MatchTrade',
  },
  {
    id: 'mateable',
    symbol: 'mtbc',
    name: 'Mateable',
  },
  {
    id: 'materium',
    symbol: 'mtrm',
    name: 'Materium',
  },
  {
    id: 'math',
    symbol: 'math',
    name: 'MATH',
  },
  {
    id: 'math-coin',
    symbol: 'math',
    name: 'Math Coin',
  },
  {
    id: 'matic-aave-aave',
    symbol: 'maaave',
    name: 'Matic Aave Interest Bearing AAVE',
  },
  {
    id: 'matic-aave-usdc',
    symbol: 'mausdc',
    name: 'Matic Aave Interest Bearing USDC',
  },
  {
    id: 'matic-dai-stablecoin',
    symbol: 'dai-matic',
    name: 'Matic DAI Stablecoin',
  },
  {
    id: 'matic-launchpad',
    symbol: 'maticpad',
    name: 'Matic Launchpad',
  },
  {
    id: 'matic-network',
    symbol: 'matic',
    name: 'Polygon',
  },
  {
    id: 'matic-plenty-bridge',
    symbol: 'matic.e',
    name: 'MATIC (Plenty Bridge)',
  },
  {
    id: 'matic-wormhole',
    symbol: 'maticpo',
    name: 'MATIC (Wormhole)',
  },
  {
    id: 'matrak-fan-token',
    symbol: 'mtrk',
    name: 'Matrak Fan Token',
  },
  {
    id: 'matrix-533ba916-8d8a-4979-b5d5-34483cdee5b1',
    symbol: 'matrix',
    name: 'Matrix',
  },
  {
    id: 'matrix-ai-network',
    symbol: 'man',
    name: 'Matrix AI Network',
  },
  {
    id: 'matrixetf',
    symbol: 'mdf',
    name: 'MatrixETF',
  },
  {
    id: 'matrixgpt',
    symbol: 'mai',
    name: 'MatrixGPT',
  },
  {
    id: 'matrix-protocol',
    symbol: 'mtx',
    name: 'Matrix Protocol',
  },
  {
    id: 'matrixswap',
    symbol: 'matrix',
    name: 'Matrix Labs',
  },
  {
    id: 'matsuri-shiba-inu',
    symbol: 'mshiba',
    name: 'Matsuri Shiba Inu',
  },
  {
    id: 'mau',
    symbol: 'mau',
    name: 'MAU',
  },
  {
    id: 'mausdc',
    symbol: 'mausdc',
    name: 'Morpho-Aave USD Coin',
  },
  {
    id: 'mausdt',
    symbol: 'mausdt',
    name: 'Morpho-Aave Tether USD',
  },
  {
    id: 'mavaverse-token',
    symbol: 'mvx',
    name: 'Mavaverse',
  },
  {
    id: 'maverick-protocol',
    symbol: 'mav',
    name: 'Maverick Protocol',
  },
  {
    id: 'maw',
    symbol: 'maw',
    name: 'MAW',
  },
  {
    id: 'maxcoin',
    symbol: 'max',
    name: 'Maxcoin',
  },
  {
    id: 'maximus',
    symbol: 'maxi',
    name: 'Maximus',
  },
  {
    id: 'maximus-base',
    symbol: 'base',
    name: 'Maximus BASE',
  },
  {
    id: 'maximus-dao',
    symbol: 'maxi',
    name: 'Maximus DAO',
  },
  {
    id: 'maximus-deci',
    symbol: 'deci',
    name: 'Maximus DECI',
  },
  {
    id: 'maximus-lucky',
    symbol: 'lucky',
    name: 'Maximus LUCKY',
  },
  {
    id: 'maximus-trio',
    symbol: 'trio',
    name: 'Maximus TRIO',
  },
  {
    id: 'maxi-ordinals',
    symbol: 'maxi',
    name: 'MAXI (Ordinals)',
  },
  {
    id: 'maxity',
    symbol: 'max',
    name: 'Maxity',
  },
  {
    id: 'max-token',
    symbol: 'max',
    name: 'MAX',
  },
  {
    id: 'maxwell-the-cat',
    symbol: 'maxcat',
    name: 'Maxwell The Cat',
  },
  {
    id: 'maxx',
    symbol: '$maxx',
    name: 'Maxx',
  },
  {
    id: 'maxx-finance',
    symbol: 'maxx',
    name: 'MAXX Finance',
  },
  {
    id: 'maya-preferred-223',
    symbol: 'mayp',
    name: 'Maya Preferred',
  },
  {
    id: 'mayfair',
    symbol: 'may',
    name: 'Mayfair',
  },
  {
    id: 'maza',
    symbol: 'mzc',
    name: 'Maza',
  },
  {
    id: 'mazimatic',
    symbol: 'mazi',
    name: 'MaziMatic',
  },
  {
    id: 'mbd-financials',
    symbol: 'mbd',
    name: 'MBD Financials',
  },
  {
    id: 'mcbase',
    symbol: 'mcbase',
    name: 'McBase',
  },
  {
    id: 'mcbroken',
    symbol: 'mcbroken',
    name: 'McBROKEN',
  },
  {
    id: 'mcdex',
    symbol: 'mcb',
    name: 'MUX Protocol',
  },
  {
    id: 'mcelo',
    symbol: 'mcelo',
    name: 'mCELO',
  },
  {
    id: 'mceur',
    symbol: 'mceur',
    name: 'mcEUR',
  },
  {
    id: 'mcfinance',
    symbol: 'mcf',
    name: 'MCFinance',
  },
  {
    id: 'mch-coin',
    symbol: 'mchc',
    name: 'MCH Coin',
  },
  {
    id: 'mci-coin',
    symbol: 'cyclub',
    name: 'Cyclub',
  },
  {
    id: 'mclaren-f1-fan-token',
    symbol: 'mcl',
    name: 'McLaren F1 Fan Token',
  },
  {
    id: 'mcoin1',
    symbol: 'mcoin',
    name: 'mCoin',
  },
  {
    id: 'mcontent',
    symbol: 'mcontent',
    name: 'MContent',
  },
  {
    id: 'mcpepe-s',
    symbol: 'pepes',
    name: "McPepe's",
  },
  {
    id: 'mcverse',
    symbol: 'mcv',
    name: 'MCVERSE',
  },
  {
    id: 'mdex',
    symbol: 'mdx',
    name: 'Mdex (HECO)',
  },
  {
    id: 'mdex-bsc',
    symbol: 'mdx',
    name: 'Mdex (BSC)',
  },
  {
    id: 'mdsquare',
    symbol: 'tmed',
    name: 'MDsquare',
  },
  {
    id: 'meadow',
    symbol: 'meadow',
    name: 'Meadow',
  },
  {
    id: 'meanfi',
    symbol: 'mean',
    name: 'Mean DAO',
  },
  {
    id: 'measurable-data-token',
    symbol: 'mdt',
    name: 'Measurable Data',
  },
  {
    id: 'meblox-protocol',
    symbol: 'meb',
    name: 'Meblox Protocol',
  },
  {
    id: 'mechachain',
    symbol: '$mecha',
    name: 'Mechanium',
  },
  {
    id: 'mecha-morphing',
    symbol: 'mape',
    name: 'Mecha Morphing',
  },
  {
    id: 'mechaverse',
    symbol: 'mc',
    name: 'Mechaverse',
  },
  {
    id: 'mechazilla',
    symbol: 'mecha',
    name: 'Mechazilla',
  },
  {
    id: 'mech-master',
    symbol: 'mech',
    name: 'Mech Master',
  },
  {
    id: 'mech-x',
    symbol: 'mechx',
    name: 'Mech X',
  },
  {
    id: 'meconcash',
    symbol: 'mch',
    name: 'Meconcash',
  },
  {
    id: 'medamon',
    symbol: 'mon',
    name: 'Medamon',
  },
  {
    id: 'media-licensing-token',
    symbol: 'mlt',
    name: 'Media Licensing Token',
  },
  {
    id: 'media-network',
    symbol: 'media',
    name: 'Media Network',
  },
  {
    id: 'medibloc',
    symbol: 'med',
    name: 'Medibloc',
  },
  {
    id: 'medicalchain',
    symbol: 'mtn',
    name: 'Medicalchain',
  },
  {
    id: 'medicalveda',
    symbol: 'mveda',
    name: 'MedicalVeda',
  },
  {
    id: 'medicle',
    symbol: 'mdi',
    name: 'Medicle',
  },
  {
    id: 'medieval-empires',
    symbol: 'mee',
    name: 'Medieval Empires',
  },
  {
    id: 'medifakt',
    symbol: 'fakt',
    name: 'Medifakt',
  },
  {
    id: 'medishares',
    symbol: 'mds',
    name: 'MediShares',
  },
  {
    id: 'medping',
    symbol: 'mpg',
    name: 'Medping',
  },
  {
    id: 'meeb-master',
    symbol: 'meeb',
    name: 'Meeb Master',
  },
  {
    id: 'meeb-vault-nftx',
    symbol: 'meeb',
    name: 'MEEB Vault (NFTX)',
  },
  {
    id: 'meeds-dao',
    symbol: 'meed',
    name: 'Meeds DAO',
  },
  {
    id: 'meerkat-shares',
    symbol: 'mshare',
    name: 'Meerkat Shares',
  },
  {
    id: 'meetple',
    symbol: 'mpt',
    name: 'Meetple',
  },
  {
    id: 'meflex',
    symbol: 'mef',
    name: 'MEFLEX',
  },
  {
    id: 'meg4mint',
    symbol: 'meg',
    name: 'Meg4mint',
  },
  {
    id: 'megabot',
    symbol: 'megabot',
    name: 'Megabot',
  },
  {
    id: 'megalodon',
    symbol: 'mega',
    name: 'MEGALODON',
  },
  {
    id: 'megapont',
    symbol: 'mega',
    name: 'Megapont',
  },
  {
    id: 'mega-protocol',
    symbol: 'mega',
    name: 'Mega Protocol',
  },
  {
    id: 'megashibazilla',
    symbol: 'msz',
    name: 'MegaShibaZilla',
  },
  {
    id: 'megatech',
    symbol: 'mgt',
    name: 'Megatech',
  },
  {
    id: 'megatoken',
    symbol: 'mega',
    name: 'MegaToken',
  },
  {
    id: 'megaton-finance',
    symbol: 'mega',
    name: 'Megaton Finance',
  },
  {
    id: 'megaton-finance-wrapped-toncoin',
    symbol: 'wton',
    name: 'Megaton Finance Wrapped Toncoin',
  },
  {
    id: 'megaweapon',
    symbol: '$weapon',
    name: 'Megaweapon',
  },
  {
    id: 'megaworld',
    symbol: 'mega',
    name: 'MegaWorld',
  },
  {
    id: 'mega-yacht-cult',
    symbol: 'myc',
    name: 'Mega Yacht Cult',
  },
  {
    id: 'megods',
    symbol: 'megods',
    name: 'MeGods',
  },
  {
    id: 'me-gusta',
    symbol: 'gusta',
    name: 'Me Gusta',
  },
  {
    id: 'melalie',
    symbol: 'mel',
    name: 'MELX',
  },
  {
    id: 'meld',
    symbol: 'meld',
    name: 'MELD [OLD]',
  },
  {
    id: 'meld-2',
    symbol: 'meld',
    name: 'MELD',
  },
  {
    id: 'meld-gold',
    symbol: 'mcau',
    name: 'Meld Gold',
  },
  {
    id: 'melega',
    symbol: 'marco',
    name: 'Melega',
  },
  {
    id: 'meli-games',
    symbol: 'meli',
    name: 'Meli Games',
  },
  {
    id: 'mellivora',
    symbol: 'mell',
    name: 'Mellivora',
  },
  {
    id: 'melon',
    symbol: 'mln',
    name: 'Enzyme',
  },
  {
    id: 'melon-2',
    symbol: 'melon',
    name: 'MELON',
  },
  {
    id: 'melon-dog',
    symbol: 'melon',
    name: 'Melon Dog',
  },
  {
    id: 'melos-studio',
    symbol: 'melos',
    name: 'Melos Studio',
  },
  {
    id: 'membot',
    symbol: 'membot',
    name: 'Membot',
  },
  {
    id: 'membrane',
    symbol: 'mbrn',
    name: 'Membrane',
  },
  {
    id: 'meme-ai',
    symbol: 'memeai',
    name: 'Meme AI',
  },
  {
    id: 'meme-ai-coin',
    symbol: 'memeai',
    name: 'Meme AI Coin',
  },
  {
    id: 'meme-alliance',
    symbol: 'mma',
    name: 'Meme Alliance',
  },
  {
    id: 'meme-brc-20',
    symbol: 'meme',
    name: 'MEME (Ordinals)',
  },
  {
    id: 'memebull',
    symbol: 'memebull',
    name: 'MemeBull',
  },
  {
    id: 'memecoin',
    symbol: 'mem',
    name: 'Memecoin',
  },
  {
    id: 'memecoin-2',
    symbol: 'meme',
    name: 'Memecoin',
  },
  {
    id: 'memecoindao',
    symbol: '$memes',
    name: 'Memecoindao',
  },
  {
    id: 'memedao',
    symbol: 'memd',
    name: 'MemeDAO',
  },
  {
    id: 'memedao-ai',
    symbol: 'mdai',
    name: 'MemeDao.Ai',
  },
  {
    id: 'memedefi',
    symbol: 'memefi',
    name: 'MemeDefi',
  },
  {
    id: 'meme-elon-doge-floki-2',
    symbol: 'memelon',
    name: 'Meme Elon Doge Floki',
  },
  {
    id: 'memeetf',
    symbol: 'memeetf',
    name: 'Bitget MemeETF',
  },
  {
    id: 'meme-etf',
    symbol: 'memeetf',
    name: 'Meme ETF',
  },
  {
    id: 'memeflate',
    symbol: 'mflate',
    name: 'Memeflate',
  },
  {
    id: 'memehub',
    symbol: 'memehub',
    name: 'Memehub',
  },
  {
    id: 'meme-inu',
    symbol: 'meme',
    name: 'Meme Inu',
  },
  {
    id: 'meme-lordz',
    symbol: 'lordz',
    name: 'Meme Lordz',
  },
  {
    id: 'mememe',
    symbol: '$mememe',
    name: 'MEMEME',
  },
  {
    id: 'meme-mint',
    symbol: 'mememint',
    name: 'MEME MINT',
  },
  {
    id: 'meme-musk',
    symbol: 'mememusk',
    name: 'MEME MUSK',
  },
  {
    id: 'meme-network',
    symbol: 'meme',
    name: 'Meme Network',
  },
  {
    id: 'memepad',
    symbol: 'mepad',
    name: 'MemePad',
  },
  {
    id: 'memes-casino',
    symbol: 'memes',
    name: 'Memes Casino',
  },
  {
    id: 'meme-shib',
    symbol: 'ms',
    name: 'Meme Shib',
  },
  {
    id: 'memeshub',
    symbol: 'mht',
    name: 'MemesHub',
  },
  {
    id: 'memes-street',
    symbol: 'memes',
    name: 'Memes Street',
  },
  {
    id: 'memes-street-ai',
    symbol: 'mst',
    name: 'Memes Street AI',
  },
  {
    id: 'memestation',
    symbol: 'memes',
    name: 'MemeStation',
  },
  {
    id: 'memes-vs-undead',
    symbol: 'mvu',
    name: 'Memes vs Undead',
  },
  {
    id: 'meme-token',
    symbol: 'meme',
    name: 'MEME Token',
  },
  {
    id: 'memetoon',
    symbol: 'meme',
    name: 'MEMETOON',
  },
  {
    id: 'memevengers',
    symbol: 'mmvg',
    name: 'MEMEVENGERS',
  },
  {
    id: 'memeverse',
    symbol: 'meme',
    name: 'Memeverse',
  },
  {
    id: 'memex',
    symbol: 'memex',
    name: 'MEMEX',
  },
  {
    id: 'menapay',
    symbol: 'mpay',
    name: 'Menapay',
  },
  {
    id: 'mend',
    symbol: 'mend',
    name: 'Mend',
  },
  {
    id: 'mendi-finance',
    symbol: 'mendi',
    name: 'Mendi Finance',
  },
  {
    id: 'menzy',
    symbol: 'mnz',
    name: 'Menzy',
  },
  {
    id: 'meowcoin',
    symbol: 'mewc',
    name: 'MeowCoin',
  },
  {
    id: 'meowl',
    symbol: 'meowl',
    name: 'Meowl',
  },
  {
    id: 'merchant-token',
    symbol: 'mto',
    name: 'Merchant',
  },
  {
    id: 'merchdao',
    symbol: 'mrch',
    name: 'MerchDAO',
  },
  {
    id: 'mercle',
    symbol: '$mercle',
    name: 'MERCLE',
  },
  {
    id: 'mercor-finance',
    symbol: 'mrcr',
    name: 'Mercor Finance',
  },
  {
    id: 'mercurial',
    symbol: 'mer',
    name: 'Mercurial',
  },
  {
    id: 'merebel',
    symbol: 'meri',
    name: 'Merebel',
  },
  {
    id: 'merge',
    symbol: 'merge',
    name: 'Merge',
  },
  {
    id: 'mergen',
    symbol: 'mrgn',
    name: 'Mergen',
  },
  {
    id: 'meridian-mst',
    symbol: 'mst',
    name: 'Meridian MST',
  },
  {
    id: 'merit-circle',
    symbol: 'mc',
    name: 'Merit Circle',
  },
  {
    id: 'merrychristmas',
    symbol: 'hohoho',
    name: 'MerryChristmas [OLD]',
  },
  {
    id: 'merrychristmas-2',
    symbol: 'hohoho',
    name: 'MerryChristmas',
  },
  {
    id: 'merry-christmas-token',
    symbol: 'mct',
    name: 'Merry Christmas Token',
  },
  {
    id: 'meshbox',
    symbol: 'mesh',
    name: 'MeshBox',
  },
  {
    id: 'meshswap-protocol',
    symbol: 'mesh',
    name: 'Meshswap Protocol',
  },
  {
    id: 'meso',
    symbol: 'meso',
    name: 'Meso',
  },
  {
    id: 'messi-coin',
    symbol: 'messi',
    name: 'MESSI COIN',
  },
  {
    id: 'messier',
    symbol: 'm87',
    name: 'MESSIER',
  },
  {
    id: 'meta',
    symbol: 'mta',
    name: 'mStable Governance: Meta',
  },
  {
    id: 'meta-apes-peel',
    symbol: 'peel',
    name: 'Meta Apes PEEL',
  },
  {
    id: 'meta-art-connection',
    symbol: 'mac',
    name: 'Meta Art Connection',
  },
  {
    id: 'metababy',
    symbol: 'baby',
    name: 'Metababy',
  },
  {
    id: 'metabeat',
    symbol: '$beat',
    name: 'MetaBeat',
  },
  {
    id: 'metabet',
    symbol: 'mbet',
    name: 'MetaBET',
  },
  {
    id: 'metabit-network',
    symbol: 'bmtc',
    name: 'Metabit Network',
  },
  {
    id: 'metable',
    symbol: 'mtbl',
    name: 'Metable',
  },
  {
    id: 'metablox',
    symbol: 'mbx',
    name: 'MetaBlox',
  },
  {
    id: 'metabot',
    symbol: 'metabot',
    name: 'MetaBot',
  },
  {
    id: 'metabrands',
    symbol: 'mage',
    name: 'MetaBrands',
  },
  {
    id: 'meta-bsc',
    symbol: 'meta',
    name: 'Meta BSC',
  },
  {
    id: 'metabusdcoin',
    symbol: 'mbc',
    name: 'MetaBUSDCoin',
  },
  {
    id: 'metacade',
    symbol: 'mcade',
    name: 'Metacade',
  },
  {
    id: 'metacash',
    symbol: 'meta',
    name: 'MetaCash',
  },
  {
    id: 'metacraft',
    symbol: 'mct',
    name: 'Metacraft',
  },
  {
    id: 'meta-dance',
    symbol: 'mdt',
    name: 'META DANCE',
  },
  {
    id: 'metaderby',
    symbol: 'dby',
    name: 'Metaderby',
  },
  {
    id: 'metaderby-hoof',
    symbol: 'hoof',
    name: 'Metaderby Hoof',
  },
  {
    id: 'metadium',
    symbol: 'meta',
    name: 'Metadium',
  },
  {
    id: 'meta-doge',
    symbol: 'metadoge',
    name: 'Meta Doge',
  },
  {
    id: 'metadoge-bsc',
    symbol: 'metadoge',
    name: 'MetaDoge BSC',
  },
  {
    id: 'metadoge-v2',
    symbol: 'metadogev2',
    name: 'MetaDoge V2',
  },
  {
    id: 'metados',
    symbol: 'second',
    name: 'MetaDOS',
  },
  {
    id: 'metaelfland',
    symbol: 'meld',
    name: 'MetaElfLand',
  },
  {
    id: 'metafabric',
    symbol: 'fabric',
    name: 'MetaFabric',
  },
  {
    id: 'metafighter',
    symbol: 'mf',
    name: 'MetaFighter',
  },
  {
    id: 'metafinance',
    symbol: 'mfi',
    name: 'MetaFinance',
  },
  {
    id: 'meta_finance',
    symbol: 'mf1',
    name: 'Meta Finance',
  },
  {
    id: 'meta-finance',
    symbol: 'meta',
    name: 'Meta Finance',
  },
  {
    id: 'metafishing-2',
    symbol: 'dgc',
    name: 'MetaFishing',
  },
  {
    id: 'metafluence',
    symbol: 'meto',
    name: 'Metafluence',
  },
  {
    id: 'metafootball',
    symbol: 'mtf',
    name: 'MetaFootball',
  },
  {
    id: 'meta-fps',
    symbol: 'mfps',
    name: 'Meta FPS',
  },
  {
    id: 'metagalaxy-land',
    symbol: 'megaland',
    name: 'Metagalaxy Land',
  },
  {
    id: 'metagame',
    symbol: 'seed',
    name: 'MetaGame',
  },
  {
    id: 'metagame-arena',
    symbol: 'mga',
    name: 'Metagame Arena',
  },
  {
    id: 'metagamehub-dao',
    symbol: 'mgh',
    name: 'MetaGameHub DAO',
  },
  {
    id: 'metagaming-guild',
    symbol: 'mgg',
    name: 'MetaGaming Guild',
  },
  {
    id: 'metagods',
    symbol: 'mgod',
    name: 'MetaGods',
  },
  {
    id: 'metaguard',
    symbol: 'mtgrd',
    name: 'MetaGuard',
  },
  {
    id: 'metahamster',
    symbol: 'mham',
    name: 'Metahamster',
  },
  {
    id: 'metahero',
    symbol: 'hero',
    name: 'Metahero',
  },
  {
    id: 'metahub-finance',
    symbol: 'men',
    name: 'MetaHub Finance',
  },
  {
    id: 'metajuice',
    symbol: 'vcoin',
    name: 'Metajuice',
  },
  {
    id: 'metakings',
    symbol: 'mtk',
    name: 'Metakings',
  },
  {
    id: 'metal',
    symbol: 'mtl',
    name: 'Metal DAO',
  },
  {
    id: 'metaland-gameverse',
    symbol: 'mst',
    name: 'Monster',
  },
  {
    id: 'metalands',
    symbol: 'pvp',
    name: 'Metalands',
  },
  {
    id: 'meta-launcher',
    symbol: 'mtla',
    name: 'Meta Launcher',
  },
  {
    id: 'metal-blockchain',
    symbol: 'metal',
    name: 'Metal Blockchain',
  },
  {
    id: 'metal-dollar',
    symbol: 'xmd',
    name: 'Metal Dollar',
  },
  {
    id: 'metal-friends',
    symbol: 'mtls',
    name: 'Metal Friends',
  },
  {
    id: 'metalswap',
    symbol: 'xmt',
    name: 'MetalSwap',
  },
  {
    id: 'metal-tools',
    symbol: 'metal',
    name: 'Metal Tools',
  },
  {
    id: 'metamafia',
    symbol: 'maf',
    name: 'MetaMAFIA',
  },
  {
    id: 'metamall',
    symbol: 'mall',
    name: 'MetaMall',
  },
  {
    id: 'meta-masters-guild',
    symbol: 'memag',
    name: 'Meta Masters Guild',
  },
  {
    id: 'metamecha',
    symbol: 'mm',
    name: 'MetaMecha',
  },
  {
    id: 'meta-merge-mana',
    symbol: 'mmm',
    name: 'Meta Merge Mana',
  },
  {
    id: 'met-a-meta-metameme',
    symbol: 'metameme',
    name: 'met a meta metameme',
  },
  {
    id: 'meta-mine',
    symbol: 'mtmn',
    name: 'META MINE',
  },
  {
    id: 'metamonkeyai',
    symbol: 'mmai',
    name: 'MetamonkeyAi',
  },
  {
    id: 'meta-monopoly',
    symbol: 'monopoly',
    name: 'Meta Monopoly',
  },
  {
    id: 'metamoon',
    symbol: 'metamoon',
    name: 'MetaMoon',
  },
  {
    id: 'metamui',
    symbol: 'mmui',
    name: 'MetaMUI',
  },
  {
    id: 'metamundo',
    symbol: 'mmt',
    name: 'Metamundo',
  },
  {
    id: 'meta-mvrs',
    symbol: 'mvrs',
    name: 'Meta MVRS',
  },
  {
    id: 'metanept',
    symbol: 'nept',
    name: 'Metanept',
  },
  {
    id: 'metan-evolutions',
    symbol: 'metan',
    name: 'Metan Evolutions',
  },
  {
    id: 'metaniagames',
    symbol: 'metania',
    name: 'MetaniaGames',
  },
  {
    id: 'metano',
    symbol: 'metano',
    name: 'Metano',
  },
  {
    id: 'metanyx',
    symbol: 'metx',
    name: 'Metanyx',
  },
  {
    id: 'meta-oasis',
    symbol: 'aim',
    name: 'Meta Oasis',
  },
  {
    id: 'metaoctagon',
    symbol: 'motg',
    name: 'MetaOctagon',
  },
  {
    id: 'metapioneers',
    symbol: 'mpi',
    name: 'MetaPioneers',
  },
  {
    id: 'metaplanet-ai',
    symbol: 'mplai',
    name: 'MetaPlanet AI',
  },
  {
    id: 'metaplex',
    symbol: 'mplx',
    name: 'Metaplex',
  },
  {
    id: 'meta-plus-token',
    symbol: 'mts',
    name: 'Meta Plus Token',
  },
  {
    id: 'metapocket',
    symbol: 'mpckt',
    name: 'MetaPocket',
  },
  {
    id: 'metapolitans',
    symbol: 'maps',
    name: 'Metapolitans',
  },
  {
    id: 'meta-pool',
    symbol: 'meta',
    name: 'Meta Pool',
  },
  {
    id: 'metapuss',
    symbol: 'mtp',
    name: 'MetaPuss',
  },
  {
    id: 'metaq',
    symbol: 'metaq',
    name: 'MetaQ',
  },
  {
    id: 'metarim',
    symbol: 'rim',
    name: 'MetaRim',
  },
  {
    id: 'metarix',
    symbol: 'mtrx',
    name: 'Metarix',
  },
  {
    id: 'metars-genesis',
    symbol: 'mrs',
    name: 'Metars Genesis',
  },
  {
    id: 'meta-ruffy',
    symbol: 'mr',
    name: 'MetaRuffy (MR)',
  },
  {
    id: 'metarun',
    symbol: 'mrun',
    name: 'Metarun',
  },
  {
    id: 'metasafemoon',
    symbol: 'metasfm',
    name: 'MetaSafeMoon',
  },
  {
    id: 'metashooter',
    symbol: 'mhunt',
    name: 'MetaShooter',
  },
  {
    id: 'metasoccer',
    symbol: 'msu',
    name: 'MetaSoccer',
  },
  {
    id: 'metastrike',
    symbol: 'mts',
    name: 'Metastrike',
  },
  {
    id: 'metatdex',
    symbol: 'tt',
    name: 'TDEX Token',
  },
  {
    id: 'metathings',
    symbol: 'mett',
    name: 'Metathings',
  },
  {
    id: 'metatime-coin',
    symbol: 'mtc',
    name: 'Metatime Coin',
  },
  {
    id: 'metatoken',
    symbol: 'mtk',
    name: 'MetaToken',
  },
  {
    id: 'meta-toy-dragonz-saga-fxerc20',
    symbol: 'fxmetod',
    name: 'Meta Toy DragonZ SAGA (FXERC20)',
  },
  {
    id: 'metatrace',
    symbol: 'trc',
    name: 'MetaTrace',
  },
  {
    id: 'metavault-dao',
    symbol: 'mvd',
    name: 'Metavault DAO',
  },
  {
    id: 'metavault-trade',
    symbol: 'mvx',
    name: 'Metavault Trade',
  },
  {
    id: 'metaverse-etp',
    symbol: 'etp',
    name: 'Metaverse ETP',
  },
  {
    id: 'metaverse-face',
    symbol: 'mefa',
    name: 'Metaverse Face',
  },
  {
    id: 'metaverse-hub',
    symbol: 'mhub',
    name: 'Metaverse Hub',
  },
  {
    id: 'metaverse-index',
    symbol: 'mvi',
    name: 'Metaverse Index',
  },
  {
    id: 'metaverse-kombat',
    symbol: 'mvk',
    name: 'Metaverse Kombat',
  },
  {
    id: 'metaverse-m',
    symbol: 'm',
    name: 'MetaVerse-M',
  },
  {
    id: 'metaverse-miner',
    symbol: 'meta',
    name: 'Metaverse Miner',
  },
  {
    id: 'metaverse-network-pioneer',
    symbol: 'neer',
    name: 'Metaverse.Network Pioneer',
  },
  {
    id: 'metaverser',
    symbol: 'mtvt',
    name: 'Metaverser',
  },
  {
    id: 'metaverse-universal-assets-bmbi-ordinals',
    symbol: 'bmbi',
    name: 'Metaverse Universal Assets BMBI (Ordinals)',
  },
  {
    id: 'metaverse-vr',
    symbol: 'mevr',
    name: 'Metaverse VR',
  },
  {
    id: 'metaverse-world-membership',
    symbol: 'mwm',
    name: 'Metaverse World Membership',
  },
  {
    id: 'metaversex',
    symbol: 'metax',
    name: 'MetaverseX',
  },
  {
    id: 'metavisa',
    symbol: 'mesa',
    name: 'metavisa',
  },
  {
    id: 'metavpad',
    symbol: 'metav',
    name: 'MetaVPad',
  },
  {
    id: 'metawars',
    symbol: 'wars',
    name: 'MetaWars',
  },
  {
    id: 'metawear',
    symbol: 'wear',
    name: 'MetaWear',
  },
  {
    id: 'metaworld',
    symbol: 'mw',
    name: 'MetaWorld',
  },
  {
    id: 'metaxcosmos',
    symbol: 'metax',
    name: 'MetaXCosmos',
  },
  {
    id: 'metaxy',
    symbol: 'mxy',
    name: 'Metaxy',
  },
  {
    id: 'metazero',
    symbol: 'mzero',
    name: 'MetaZero',
  },
  {
    id: 'metazilla',
    symbol: 'mz',
    name: 'MetaZilla',
  },
  {
    id: 'metazoomee',
    symbol: 'mzm',
    name: 'MetaZooMee',
  },
  {
    id: 'metchain',
    symbol: 'met',
    name: 'Metchain',
  },
  {
    id: 'meter',
    symbol: 'mtrg',
    name: 'Meter Governance',
  },
  {
    id: 'meter-governance-mapped-by-meter-io',
    symbol: 'emtrg',
    name: 'Meter Governance mapped by Meter.io',
  },
  {
    id: 'meter-io-staked-mtrg',
    symbol: 'stmtrg',
    name: 'Meter.io Staked MTRG',
  },
  {
    id: 'meter-io-wrapped-stmtrg',
    symbol: 'wstmtrg',
    name: 'Meter.io Wrapped stMTRG',
  },
  {
    id: 'meter-stable',
    symbol: 'mtr',
    name: 'Meter Stable',
  },
  {
    id: 'metfi-2',
    symbol: 'metfi',
    name: 'MetFi',
  },
  {
    id: 'metisbot',
    symbol: 'mbot',
    name: 'MetisBot',
  },
  {
    id: 'metis-token',
    symbol: 'metis',
    name: 'Metis',
  },
  {
    id: 'metoshi',
    symbol: 'meto',
    name: 'Metoshi',
  },
  {
    id: 'metronome',
    symbol: 'met',
    name: 'Metronome',
  },
  {
    id: 'metropoly',
    symbol: 'metro',
    name: 'Metropoly',
  },
  {
    id: 'metroxynth',
    symbol: 'mxh',
    name: 'Metroxynth',
  },
  {
    id: 'mettalex',
    symbol: 'mtlx',
    name: 'Mettalex',
  },
  {
    id: 'metti-inu',
    symbol: 'metti',
    name: 'Metti Inu',
  },
  {
    id: 'mevai',
    symbol: 'mai',
    name: 'MevAI',
  },
  {
    id: 'meverse',
    symbol: 'mev',
    name: 'MEVerse',
  },
  {
    id: 'meveth',
    symbol: 'meveth',
    name: 'mevETH',
  },
  {
    id: 'mevfree',
    symbol: 'mevfree',
    name: 'MEVFree',
  },
  {
    id: 'mexican-peso-tether',
    symbol: 'mxnt',
    name: 'Mexican Peso Tether',
  },
  {
    id: 'mezz',
    symbol: 'mezz',
    name: 'MEZZ',
  },
  {
    id: 'mfet',
    symbol: 'mfet',
    name: 'MFET',
  },
  {
    id: 'mhcash',
    symbol: 'mhcash',
    name: 'MHCASH',
  },
  {
    id: 'miamicoin',
    symbol: 'mia',
    name: 'MiamiCoin',
  },
  {
    id: 'miaswap',
    symbol: 'mia',
    name: 'MiaSwap',
  },
  {
    id: 'mib-coin',
    symbol: 'mib',
    name: 'MIB Coin',
  },
  {
    id: 'mibr-fan-token',
    symbol: 'mibr',
    name: 'MIBR Fan Token',
  },
  {
    id: 'mice',
    symbol: 'mice',
    name: 'Mice (Ordinals)',
  },
  {
    id: 'micro-ai',
    symbol: 'mai',
    name: 'Micro AI',
  },
  {
    id: 'micro-bitcoin-finance',
    symbol: 'mbtc',
    name: 'Micro Bitcoin Finance',
  },
  {
    id: 'micro-coq',
    symbol: 'micro',
    name: 'Micro Coq',
  },
  {
    id: 'microcredittoken',
    symbol: '1mct',
    name: 'MicroCreditToken',
  },
  {
    id: 'micromoney',
    symbol: 'amm',
    name: 'MicroMoney',
  },
  {
    id: 'micropepe',
    symbol: 'mpepe',
    name: 'MicroPepe',
  },
  {
    id: 'micropets',
    symbol: 'pets',
    name: 'MicroPets [OLD]',
  },
  {
    id: 'micropets-2',
    symbol: 'pets',
    name: 'MicroPets',
  },
  {
    id: 'microsoft-tokenized-stock-defichain',
    symbol: 'dmsft',
    name: 'Microsoft Tokenized Stock Defichain',
  },
  {
    id: 'microtick',
    symbol: 'tick',
    name: 'Microtick',
  },
  {
    id: 'microtuber',
    symbol: 'mct',
    name: 'MicroTuber',
  },
  {
    id: 'microvisionchain',
    symbol: 'space',
    name: 'MicrovisionChain',
  },
  {
    id: 'midas-stusd',
    symbol: 'stusd',
    name: 'Midas stUSD',
  },
  {
    id: 'midas-token',
    symbol: 'mds',
    name: 'MIDAS Token',
  },
  {
    id: 'miggy',
    symbol: 'miggy',
    name: 'Miggy',
  },
  {
    id: 'miidas',
    symbol: 'miidas',
    name: 'Miidas',
  },
  {
    id: 'mikawa-inu',
    symbol: 'mikawa',
    name: 'Mikawa Inu',
  },
  {
    id: 'mikeneko',
    symbol: 'mike',
    name: 'MiKeNeKo',
  },
  {
    id: 'milady-meme-coin',
    symbol: 'ladys',
    name: 'Milady Meme Coin',
  },
  {
    id: 'milady-vault-nftx',
    symbol: 'milady',
    name: 'Milady Vault (NFTX)',
  },
  {
    id: 'milei',
    symbol: 'milei',
    name: 'MILEI',
  },
  {
    id: 'milei-token',
    symbol: 'milei',
    name: 'MILEI Token',
  },
  {
    id: 'milestone-millions',
    symbol: 'msmil',
    name: 'Milestone Millions',
  },
  {
    id: 'mileverse',
    symbol: 'mvc',
    name: 'MileVerse',
  },
  {
    id: 'milk',
    symbol: 'milk',
    name: 'Cool Cats Milk',
  },
  {
    id: 'milk-2',
    symbol: 'milk',
    name: 'Milk',
  },
  {
    id: 'milkai',
    symbol: 'milkai',
    name: 'MilkAI',
  },
  {
    id: 'milk-alliance',
    symbol: 'mlk',
    name: 'MiL.k Alliance',
  },
  {
    id: 'milkshakeswap',
    symbol: 'milk',
    name: 'Milkshake Swap',
  },
  {
    id: 'milkyswap',
    symbol: 'milky',
    name: 'MilkySwap',
  },
  {
    id: 'milky-token',
    symbol: 'milky',
    name: 'Milky',
  },
  {
    id: 'milkyway-staked-tia',
    symbol: 'milktia',
    name: 'MilkyWay Staked TIA',
  },
  {
    id: 'mille-chain',
    symbol: 'mille',
    name: 'MILLE CHAIN',
  },
  {
    id: 'millenniumclub',
    symbol: 'mclb',
    name: 'MillenniumClub Coin [OLD]',
  },
  {
    id: 'millenniumclub-coin-new',
    symbol: 'mclb',
    name: 'MillenniumClub Coin [NEW]',
  },
  {
    id: 'millimeter',
    symbol: 'mm',
    name: 'Millimeter',
  },
  {
    id: 'million',
    symbol: 'mm',
    name: 'Million',
  },
  {
    id: 'milliondollarbaby',
    symbol: 'mdb',
    name: 'Make DeFi Better',
  },
  {
    id: 'million-monke',
    symbol: 'mimo',
    name: 'Million Monke',
  },
  {
    id: 'millonarios-fc-fan-token',
    symbol: 'mfc',
    name: 'Millonarios FC Fan Token',
  },
  {
    id: 'milo',
    symbol: 'milo',
    name: 'MILO',
  },
  {
    id: 'milo-inu',
    symbol: 'milo',
    name: 'Milo Inu',
  },
  {
    id: 'mim',
    symbol: 'swarm',
    name: 'MIM',
  },
  {
    id: 'mimas-finance',
    symbol: 'mimas',
    name: 'Mimas Finance',
  },
  {
    id: 'mimatic',
    symbol: 'mimatic',
    name: 'MAI',
  },
  {
    id: 'mimblewimblecoin',
    symbol: 'mwc',
    name: 'MimbleWimbleCoin',
  },
  {
    id: 'mimbo',
    symbol: 'mimbo',
    name: 'Mimbo',
  },
  {
    id: 'mimir-token',
    symbol: 'mimir',
    name: 'Mimir',
  },
  {
    id: 'mimo-capital-ag-us-kuma-interest-bearing-token',
    symbol: 'usk',
    name: 'KUMA Protocol US KUMA Interest Bearing Token',
  },
  {
    id: 'mimo-parallel-governance-token',
    symbol: 'mimo',
    name: 'Mimo Governance',
  },
  {
    id: 'mimosa',
    symbol: 'mimo',
    name: 'Mimosa',
  },
  {
    id: 'mina-protocol',
    symbol: 'mina',
    name: 'Mina Protocol',
  },
  {
    id: 'minativerse',
    symbol: 'mntc',
    name: 'MINATIVERSE',
  },
  {
    id: 'minato',
    symbol: 'mnto',
    name: 'Minato',
  },
  {
    id: 'mind-connect',
    symbol: 'mind',
    name: 'Mind Connect',
  },
  {
    id: 'mind-games-cortex',
    symbol: 'crx',
    name: 'MIND Games CORTEX',
  },
  {
    id: 'mind-language',
    symbol: 'mnd',
    name: 'Mind',
  },
  {
    id: 'mind-matrix',
    symbol: 'aimx',
    name: 'Mind Matrix',
  },
  {
    id: 'minds',
    symbol: 'minds',
    name: 'Minds',
  },
  {
    id: 'mineable',
    symbol: 'mnb',
    name: 'Mineable',
  },
  {
    id: 'minebase',
    symbol: 'mbase',
    name: 'Minebase',
  },
  {
    id: 'minelab',
    symbol: 'melb',
    name: 'Minelab',
  },
  {
    id: 'mineral',
    symbol: 'mnr',
    name: 'Mineral',
  },
  {
    id: 'miner-arena',
    symbol: 'minar',
    name: 'Miner Arena',
  },
  {
    id: 'minergatetoken',
    symbol: 'mgt',
    name: 'MinerGateToken',
  },
  {
    id: 'minergold-io',
    symbol: 'mgold',
    name: 'MinerGold.io',
  },
  {
    id: 'miners-of-kadenia',
    symbol: 'mok',
    name: 'Miners of Kadenia',
  },
  {
    id: 'minerva-money',
    symbol: 'mine',
    name: 'Minerva Money',
  },
  {
    id: 'minerva-wallet',
    symbol: 'miva',
    name: 'Minerva Wallet',
  },
  {
    id: 'minesee',
    symbol: 'see',
    name: 'MineSee',
  },
  {
    id: 'mines-of-dalarnia',
    symbol: 'dar',
    name: 'Mines of Dalarnia',
  },
  {
    id: 'mini',
    symbol: 'mini',
    name: 'Mini',
  },
  {
    id: 'mini-grok',
    symbol: 'mini grok',
    name: 'Mini Grok (OLD)',
  },
  {
    id: 'mini-grok-2',
    symbol: 'mini grok',
    name: 'Mini Grok',
  },
  {
    id: 'mini-metis',
    symbol: 'minime',
    name: 'Mini Metis',
  },
  {
    id: 'minnapad',
    symbol: 'minna',
    name: 'Minnapad',
  },
  {
    id: 'minswap',
    symbol: 'min',
    name: 'Minswap',
  },
  {
    id: 'mint-club',
    symbol: 'mint',
    name: 'Mint Club',
  },
  {
    id: 'mintcoin',
    symbol: 'mint',
    name: 'Mintcoin',
  },
  {
    id: 'mintdao',
    symbol: 'mint',
    name: 'MintDAO',
  },
  {
    id: 'minted',
    symbol: 'mtd',
    name: 'Minted',
  },
  {
    id: 'mintera',
    symbol: 'mnte',
    name: 'Mintera',
  },
  {
    id: 'minterest',
    symbol: 'mnt',
    name: 'Minterest',
  },
  {
    id: 'minti',
    symbol: 'minti',
    name: 'Minti',
  },
  {
    id: 'mintlayer',
    symbol: 'ml',
    name: 'Mintlayer',
  },
  {
    id: 'minto',
    symbol: 'btcmt',
    name: 'Minto',
  },
  {
    id: 'mintra',
    symbol: 'mint',
    name: 'Mintra',
  },
  {
    id: 'minu',
    symbol: 'minu',
    name: 'Minu',
  },
  {
    id: 'minu-the-manta',
    symbol: 'mnu',
    name: 'Minu the Manta',
  },
  {
    id: 'miracle-play',
    symbol: 'mpt',
    name: 'Miracle Play',
  },
  {
    id: 'mirage-2',
    symbol: 'mirage',
    name: 'Mirage',
  },
  {
    id: 'miraqle',
    symbol: 'mql',
    name: 'MiraQle',
  },
  {
    id: 'mirrored-ether',
    symbol: 'meth',
    name: 'Mirrored Ether',
  },
  {
    id: 'mirror-protocol',
    symbol: 'mir',
    name: 'Mirror Protocol',
  },
  {
    id: 'mir-token',
    symbol: 'mir',
    name: 'Mir Token',
  },
  {
    id: 'misbloc',
    symbol: 'msb',
    name: 'Misbloc',
  },
  {
    id: 'missionmars',
    symbol: 'mmars',
    name: 'MissionMars',
  },
  {
    id: 'mist',
    symbol: 'mist',
    name: 'Mist',
  },
  {
    id: 'mithril',
    symbol: 'mith',
    name: 'Mithril',
  },
  {
    id: 'mithril-share',
    symbol: 'mis',
    name: 'Mithril Share',
  },
  {
    id: 'mixaverse',
    symbol: 'mixcoin',
    name: 'Mixaverse',
  },
  {
    id: 'mixerbot',
    symbol: 'mxrbot',
    name: 'MixerBot',
  },
  {
    id: 'mixin',
    symbol: 'xin',
    name: 'Mixin',
  },
  {
    id: 'mixmarvel',
    symbol: 'mix',
    name: 'MixMarvel',
  },
  {
    id: 'mixmob',
    symbol: 'mxm',
    name: 'MixMob',
  },
  {
    id: 'mixquity-finance',
    symbol: 'mixq',
    name: 'Mixquity Finance',
  },
  {
    id: 'mixtoearn',
    symbol: 'mte',
    name: 'MixToEarn',
  },
  {
    id: 'mixtrust',
    symbol: 'mxt',
    name: 'MixTrust',
  },
  {
    id: 'mizar',
    symbol: 'mzr',
    name: 'Mizar',
  },
  {
    id: 'mktcash',
    symbol: 'mch',
    name: 'Mktcash',
  },
  {
    id: 'mktcoin',
    symbol: 'mkt',
    name: 'MktCoin',
  },
  {
    id: 'mlord',
    symbol: 'mlord',
    name: 'MLORD',
  },
  {
    id: 'mm72',
    symbol: 'mm72',
    name: 'MM72',
  },
  {
    id: 'mmfinance',
    symbol: 'mmf',
    name: 'MMFinance (Cronos)',
  },
  {
    id: 'mmfinance-arbitrum',
    symbol: 'mmf',
    name: 'MMFinance (Arbitrum)',
  },
  {
    id: 'mmf-money',
    symbol: 'burrow',
    name: 'MMF Money',
  },
  {
    id: 'mmocoin',
    symbol: 'mmo',
    name: 'MMOCoin',
  },
  {
    id: 'mms-cash',
    symbol: 'mcash',
    name: 'MMS Cash',
  },
  {
    id: 'mms-coin',
    symbol: 'mmsc',
    name: 'MMS Coin',
  },
  {
    id: 'mmss',
    symbol: 'mmss',
    name: 'MMSS (Ordinals)',
  },
  {
    id: 'mn-bridge',
    symbol: 'mnb',
    name: 'MN Bridge',
  },
  {
    id: 'mnicorp',
    symbol: 'mni',
    name: 'MnICorp',
  },
  {
    id: 'mnmcoin',
    symbol: 'mnmc',
    name: 'MNMCoin',
  },
  {
    id: 'moai',
    symbol: 'moai',
    name: 'MOAI',
  },
  {
    id: 'mobifi',
    symbol: 'mofi',
    name: 'MobiFi',
  },
  {
    id: 'mobilecoin',
    symbol: 'mob',
    name: 'MobileCoin',
  },
  {
    id: 'mobile-crypto-pay-coin',
    symbol: 'mcpc',
    name: 'Mobile Crypto Pay Coin',
  },
  {
    id: 'mobility-coin',
    symbol: 'mobic',
    name: 'Mobility Coin',
  },
  {
    id: 'mobipad',
    symbol: 'mbp',
    name: 'Mobipad',
  },
  {
    id: 'mobist',
    symbol: 'mitx',
    name: 'Mobist',
  },
  {
    id: 'mobius',
    symbol: 'mobi',
    name: 'Mobius',
  },
  {
    id: 'mobius-finance',
    symbol: 'mot',
    name: 'Mobius Finance',
  },
  {
    id: 'mobius-money',
    symbol: 'mobi',
    name: 'Mobius Money',
  },
  {
    id: 'mobix',
    symbol: 'mobx',
    name: 'MOBIX',
  },
  {
    id: 'mobox',
    symbol: 'mbox',
    name: 'Mobox',
  },
  {
    id: 'mobster',
    symbol: 'mob',
    name: 'Mobster',
  },
  {
    id: 'mochi',
    symbol: 'mochi',
    name: 'Mochi',
  },
  {
    id: 'mochi-2-2',
    symbol: 'mochi',
    name: 'Mochi (Old)',
  },
  {
    id: 'mochi-market',
    symbol: 'moma',
    name: 'Mochi Market',
  },
  {
    id: 'mochi-thecatcoin',
    symbol: 'mochi',
    name: 'Mochi',
  },
  {
    id: 'mockjup',
    symbol: 'mockjup',
    name: 'mockJUP',
  },
  {
    id: 'mocossi-planet',
    symbol: 'mcos',
    name: 'Mocossi Planet',
  },
  {
    id: 'moda-dao',
    symbol: 'moda',
    name: 'MODA DAO',
  },
  {
    id: 'modclub',
    symbol: 'mod',
    name: 'Modclub',
  },
  {
    id: 'modefi',
    symbol: 'mod',
    name: 'Modefi',
  },
  {
    id: 'model-labs',
    symbol: 'model',
    name: 'Model Labs',
  },
  {
    id: 'modex',
    symbol: 'modex',
    name: 'Modex',
  },
  {
    id: 'modular-wallet',
    symbol: 'mod',
    name: 'Modular Wallet',
  },
  {
    id: 'modulus-domains-service',
    symbol: 'mods',
    name: 'Modulus Domain Service',
  },
  {
    id: 'moe',
    symbol: 'moe',
    name: 'MOE',
  },
  {
    id: 'moe-2',
    symbol: 'moe',
    name: 'Moe',
  },
  {
    id: 'moe-3',
    symbol: 'moe',
    name: 'MOE',
  },
  {
    id: 'moeda-loyalty-points',
    symbol: 'mda',
    name: 'Moeda Loyalty Points',
  },
  {
    id: 'moeta',
    symbol: 'moeta',
    name: 'Moeta',
  },
  {
    id: 'mog',
    symbol: 'mog',
    name: 'MOG',
  },
  {
    id: 'mog-coin',
    symbol: 'mog',
    name: 'Mog Coin',
  },
  {
    id: 'mogul-productions',
    symbol: 'stars',
    name: 'Mogul Productions',
  },
  {
    id: 'mojito',
    symbol: 'mojo',
    name: 'Mojito',
  },
  {
    id: 'mojitoswap',
    symbol: 'mjt',
    name: 'MojitoSwap',
  },
  {
    id: 'molecules-of-korolchuk-ip-nft',
    symbol: 'vita-fast',
    name: 'Molecules of Korolchuk IP-NFT',
  },
  {
    id: 'molly-ai',
    symbol: 'molly',
    name: 'Molly AI',
  },
  {
    id: 'molly-gateway',
    symbol: 'molly',
    name: 'Molly',
  },
  {
    id: 'moments',
    symbol: 'mmt',
    name: 'Moments Market',
  },
  {
    id: 'mommy-doge',
    symbol: 'mommydoge',
    name: 'Mommy Doge',
  },
  {
    id: 'momoji',
    symbol: 'emoji',
    name: 'MOMOJI',
  },
  {
    id: 'momo-key',
    symbol: 'key',
    name: 'MoMo Key',
  },
  {
    id: 'momo-v2',
    symbol: 'momo v2',
    name: 'Momo v2',
  },
  {
    id: 'mona',
    symbol: 'mona',
    name: 'Monaco Planet',
  },
  {
    id: 'monaco',
    symbol: 'mco',
    name: 'MCO',
  },
  {
    id: 'monacoin',
    symbol: 'mona',
    name: 'MonaCoin',
  },
  {
    id: 'monaki',
    symbol: 'monk',
    name: 'Monaki',
  },
  {
    id: 'monat-money',
    symbol: 'monat',
    name: 'Monat Money',
  },
  {
    id: 'mona-token',
    symbol: 'lisa',
    name: 'Mona Token',
  },
  {
    id: 'monavale',
    symbol: 'mona',
    name: 'Monavale',
  },
  {
    id: 'mondo-community-coin',
    symbol: 'mndcc',
    name: 'Mondo Community Coin',
  },
  {
    id: 'monerium-eur-money',
    symbol: 'eure',
    name: 'Monerium EUR emoney',
  },
  {
    id: 'monero',
    symbol: 'xmr',
    name: 'Monero',
  },
  {
    id: 'monero-classic-xmc',
    symbol: 'xmc',
    name: 'Monero-Classic',
  },
  {
    id: 'monerov',
    symbol: 'xmv',
    name: 'MoneroV',
  },
  {
    id: 'monetas',
    symbol: 'mntg',
    name: 'Monetas [OLD]',
  },
  {
    id: 'monetas-2',
    symbol: 'mntg',
    name: 'Monetas',
  },
  {
    id: 'monetha',
    symbol: 'mth',
    name: 'Monetha',
  },
  {
    id: 'monet-society',
    symbol: 'monet',
    name: 'Monet Society',
  },
  {
    id: 'moneyark',
    symbol: 'mark',
    name: 'MoneyArk',
  },
  {
    id: 'moneybrain-bips',
    symbol: 'bips',
    name: 'Moneybrain BiPS',
  },
  {
    id: 'moneybyte',
    symbol: 'mon',
    name: 'Moneybyte',
  },
  {
    id: 'moneyhero',
    symbol: 'myh',
    name: 'Moneyhero',
  },
  {
    id: 'money-laundering-protocol',
    symbol: 'mlp',
    name: 'Money Laundering Protocol',
  },
  {
    id: 'money-on-chain',
    symbol: 'moc',
    name: 'Money On Chain',
  },
  {
    id: 'moneyswap',
    symbol: 'mswap',
    name: 'MoneySwap',
  },
  {
    id: 'mongcoin',
    symbol: 'mong',
    name: 'MongCoin',
  },
  {
    id: 'mongol-nft',
    symbol: 'mnft',
    name: 'Mongol NFT',
  },
  {
    id: 'mongoose',
    symbol: 'mongoose',
    name: 'Mongoose',
  },
  {
    id: 'monk',
    symbol: 'monk',
    name: 'Monk',
  },
  {
    id: 'monkcoin',
    symbol: 'monk',
    name: 'MonkCoin',
  },
  {
    id: 'monke',
    symbol: 'monke',
    name: 'Monke',
  },
  {
    id: 'monke-2',
    symbol: 'monke',
    name: 'Monke',
  },
  {
    id: 'monke-coin',
    symbol: '$monke',
    name: 'Monke',
  },
  {
    id: 'monked',
    symbol: 'monked',
    name: 'MONKED',
  },
  {
    id: 'monkex',
    symbol: 'monkex',
    name: 'Monkex',
  },
  {
    id: 'monkey',
    symbol: 'monkey',
    name: 'MONKEY',
  },
  {
    id: 'monkeyball',
    symbol: 'mbs',
    name: 'UNKJD',
  },
  {
    id: 'monkeyhaircut',
    symbol: 'monk',
    name: 'monkeyhaircut',
  },
  {
    id: 'monkey-puppet',
    symbol: 'mpm',
    name: 'Monkey Puppet',
  },
  {
    id: 'monkeys',
    symbol: 'monkeys',
    name: 'Monkeys',
  },
  {
    id: 'monkeys-token',
    symbol: 'monkeys',
    name: 'Monkeys Token',
  },
  {
    id: 'monk-gg',
    symbol: 'monkgg',
    name: 'Monk.gg',
  },
  {
    id: 'monnos',
    symbol: 'mns',
    name: 'Monnos',
  },
  {
    id: 'monolend',
    symbol: 'mld',
    name: 'MonoLend',
  },
  {
    id: 'monomoney',
    symbol: 'mono',
    name: 'MonoMoney',
  },
  {
    id: 'mononoke-inu',
    symbol: 'mononoke-inu',
    name: 'Mononoke Inu',
  },
  {
    id: 'monopoly-layer2-duo',
    symbol: 'duo',
    name: 'Monopoly Layer2 DUO',
  },
  {
    id: 'monopoly-layer-3-poly',
    symbol: 'poly',
    name: 'Monopoly Layer 3 POLY',
  },
  {
    id: 'monopoly-millionaire-control',
    symbol: 'mmc',
    name: 'Monopoly Millionaire Control',
  },
  {
    id: 'monox',
    symbol: 'mono',
    name: 'MonoX',
  },
  {
    id: 'monsoon-finance',
    symbol: 'mcash',
    name: 'Monsoon Finance',
  },
  {
    id: 'monsta-infinite',
    symbol: 'moni',
    name: 'Monsta Infinite',
  },
  {
    id: 'monster-ball',
    symbol: 'mfb',
    name: 'Monster Ball',
  },
  {
    id: 'monster-galaxy',
    symbol: 'ggm',
    name: 'Monster Galaxy',
  },
  {
    id: 'monsterra',
    symbol: 'mstr',
    name: 'Monsterra',
  },
  {
    id: 'monsterra-mag',
    symbol: 'mag',
    name: 'Monsterra MAG',
  },
  {
    id: 'monstock',
    symbol: 'mon',
    name: 'Monstock',
  },
  {
    id: 'monte',
    symbol: 'monte',
    name: 'Monte',
  },
  {
    id: 'moochii',
    symbol: 'moochii',
    name: 'Moochii',
  },
  {
    id: 'moo-garden',
    symbol: 'moo',
    name: 'Moo Garden',
  },
  {
    id: 'mooi-network',
    symbol: 'mooi',
    name: 'MOOI Network',
  },
  {
    id: 'moola-celo-dollars',
    symbol: 'mcusd',
    name: 'Moola Celo Dollars',
  },
  {
    id: 'moolahverse',
    symbol: 'mlh',
    name: 'Moolahverse',
  },
  {
    id: 'moola-interest-bearing-creal',
    symbol: 'mcreal',
    name: 'Moola interest bearing CREAL',
  },
  {
    id: 'moola-market',
    symbol: 'moo',
    name: 'Moola Market',
  },
  {
    id: 'moomonster',
    symbol: 'moo',
    name: 'MooMonster',
  },
  {
    id: 'moon',
    symbol: 'moon',
    name: 'r/CryptoCurrency Moons',
  },
  {
    id: 'moonai-2',
    symbol: 'moonai',
    name: 'MoonAI',
  },
  {
    id: 'moon-air',
    symbol: 'moonair',
    name: 'Moon Air',
  },
  {
    id: 'moon-app',
    symbol: 'app',
    name: 'Moon App',
  },
  {
    id: 'moonarch',
    symbol: 'moonarch',
    name: 'Moonarch',
  },
  {
    id: 'moonbase-2',
    symbol: 'moon',
    name: 'MoonBase',
  },
  {
    id: 'moon-bay',
    symbol: 'bay',
    name: 'Moon Bay',
  },
  {
    id: 'moonbeam',
    symbol: 'glmr',
    name: 'Moonbeam',
  },
  {
    id: 'moonbeans',
    symbol: 'beans',
    name: 'MoonBeans',
  },
  {
    id: 'moonbot',
    symbol: 'mbot',
    name: 'MoonBot',
  },
  {
    id: 'mooncat-vault-nftx',
    symbol: 'mooncat',
    name: 'MOONCAT Vault (NFTX)',
  },
  {
    id: 'moondogs',
    symbol: 'woof',
    name: 'Moondogs',
  },
  {
    id: 'moonedge',
    symbol: 'mooned',
    name: 'MoonEdge',
  },
  {
    id: 'mooner',
    symbol: 'mnr',
    name: 'Mooner',
  },
  {
    id: 'moonerium',
    symbol: 'moonerium',
    name: 'MOONERIUM',
  },
  {
    id: 'mooney',
    symbol: 'mooney',
    name: 'Moon DAO',
  },
  {
    id: 'moonflow',
    symbol: 'moon',
    name: 'Moonflow',
  },
  {
    id: 'moonft',
    symbol: 'mtc',
    name: 'Moonft',
  },
  {
    id: 'moonions',
    symbol: 'moonion',
    name: 'Moonions',
  },
  {
    id: 'moonlana',
    symbol: 'mola',
    name: 'MoonLana',
  },
  {
    id: 'moonlift',
    symbol: 'mltpx',
    name: 'Moonlift Capital',
  },
  {
    id: 'moonlight-token',
    symbol: 'moonlight',
    name: 'Moonlight',
  },
  {
    id: 'moon-maker-protocol',
    symbol: 'mmp',
    name: 'Moon Maker Protocol',
  },
  {
    id: 'moon-ordinals',
    symbol: 'moon',
    name: 'MOON (Ordinals)',
  },
  {
    id: 'moonpot',
    symbol: 'pots',
    name: 'Moonpot',
  },
  {
    id: 'moonpot-finance',
    symbol: 'moonpot',
    name: 'MoonPot Finance',
  },
  {
    id: 'moon-rabbit',
    symbol: 'aaa',
    name: 'Moon Rabbit',
  },
  {
    id: 'moonriver',
    symbol: 'movr',
    name: 'Moonriver',
  },
  {
    id: 'moonscape',
    symbol: 'mscp',
    name: 'Moonscape',
  },
  {
    id: 'moonsdust',
    symbol: 'moond',
    name: 'MoonsDust',
  },
  {
    id: 'moonshots-farm',
    symbol: 'bones',
    name: 'Moonshots Farm',
  },
  {
    id: 'moonstarter',
    symbol: 'mnst',
    name: 'MoonStarter',
  },
  {
    id: 'moon-tropica',
    symbol: 'cah',
    name: 'Moon Tropica',
  },
  {
    id: 'moonwell',
    symbol: 'mfam',
    name: 'Moonwell Apollo',
  },
  {
    id: 'moonwell-artemis',
    symbol: 'well',
    name: 'Moonwell',
  },
  {
    id: 'moonwolf-io',
    symbol: 'wolf',
    name: 'moonwolf.io',
  },
  {
    id: 'moove-protocol',
    symbol: 'moove',
    name: 'Moove Protocol',
  },
  {
    id: 'mooxmoo',
    symbol: 'moox',
    name: 'MOOxMOO',
  },
  {
    id: 'mops',
    symbol: 'mops',
    name: 'Mops',
  },
  {
    id: 'mora',
    symbol: 'mora',
    name: 'Mora',
  },
  {
    id: 'mora-2',
    symbol: 'mora',
    name: 'Mora',
  },
  {
    id: 'moremoney-usd',
    symbol: 'money',
    name: 'Moremoney USD',
  },
  {
    id: 'more-token',
    symbol: 'more',
    name: 'Moremoney Finance',
  },
  {
    id: 'mori-finance',
    symbol: 'mori',
    name: 'Mori Finance',
  },
  {
    id: 'mork',
    symbol: 'mork',
    name: 'MORK',
  },
  {
    id: 'moros-net',
    symbol: 'moros',
    name: 'MOROS NET',
  },
  {
    id: 'morpher',
    symbol: 'mph',
    name: 'Morpher',
  },
  {
    id: 'morpheus-labs',
    symbol: 'mitx',
    name: 'Morpheus Labs',
  },
  {
    id: 'morpheus-network',
    symbol: 'mnw',
    name: 'Morpheus Network',
  },
  {
    id: 'morpheus-token',
    symbol: 'pills',
    name: 'Morpheus Swap',
  },
  {
    id: 'morpho',
    symbol: 'morpho',
    name: 'Morpho',
  },
  {
    id: 'morpho-aave-curve-dao-token',
    symbol: 'macrv',
    name: 'Morpho-Aave Curve DAO Token',
  },
  {
    id: 'morpho-aave-wrapped-btc',
    symbol: 'mawbtc',
    name: 'Morpho-Aave Wrapped BTC',
  },
  {
    id: 'morpho-aave-wrapped-ether',
    symbol: 'maweth',
    name: 'Morpho-Aave Wrapped Ether',
  },
  {
    id: 'morpho-network',
    symbol: 'morpho',
    name: 'Morpho Network',
  },
  {
    id: 'morphswap',
    symbol: 'ms',
    name: 'Morphswap',
  },
  {
    id: 'morra',
    symbol: 'morra',
    name: 'Morra',
  },
  {
    id: 'mosolid',
    symbol: 'mosolid',
    name: 'moSOLID',
  },
  {
    id: 'mosquitos-finance',
    symbol: 'suckr',
    name: 'Mosquitos Finance',
  },
  {
    id: 'moss-carbon-credit',
    symbol: 'mco2',
    name: 'Moss Carbon Credit',
  },
  {
    id: 'mossland',
    symbol: 'moc',
    name: 'Mossland',
  },
  {
    id: 'most-global',
    symbol: 'mgp',
    name: 'MOST Global',
  },
  {
    id: 'motacoin',
    symbol: 'mota',
    name: 'MotaCoin',
  },
  {
    id: 'mother-earth',
    symbol: 'mot',
    name: 'Mother Earth',
  },
  {
    id: 'mother-of-memes',
    symbol: 'mom',
    name: 'Mother of Memes',
  },
  {
    id: 'motion-motn',
    symbol: 'motn',
    name: 'MOTION',
  },
  {
    id: 'motogp-fan-token',
    symbol: 'mgpt',
    name: 'MotoGP Fan Token',
  },
  {
    id: 'mound-token',
    symbol: 'mnd',
    name: 'Mound',
  },
  {
    id: 'mountain-protocol-usdm',
    symbol: 'usdm',
    name: 'Mountain Protocol USD',
  },
  {
    id: 'moutai',
    symbol: 'moutai',
    name: 'Moutai',
  },
  {
    id: 'moveapp',
    symbol: 'move',
    name: 'MoveApp',
  },
  {
    id: 'movecash',
    symbol: 'mca',
    name: 'MoveCash',
  },
  {
    id: 'move-dollar',
    symbol: 'mod',
    name: 'Move Dollar',
  },
  {
    id: 'mover-xyz',
    symbol: 'mover',
    name: 'Mover.xyz',
  },
  {
    id: 'movex-token',
    symbol: 'movex',
    name: 'Movex Token',
  },
  {
    id: 'movez',
    symbol: 'movez',
    name: 'MoveZ',
  },
  {
    id: 'moviebloc',
    symbol: 'mbl',
    name: 'MovieBloc',
  },
  {
    id: 'movo-smart-chain',
    symbol: 'movo',
    name: 'Movo Smart Chain',
  },
  {
    id: 'mozaic',
    symbol: 'moz',
    name: 'Mozaic',
  },
  {
    id: 'mp3',
    symbol: 'mp3',
    name: 'MP3',
  },
  {
    id: 'mpendle',
    symbol: 'mpendle',
    name: 'mPendle',
  },
  {
    id: 'mpx',
    symbol: 'mpx',
    name: 'Morphex',
  },
  {
    id: 'mr-beast-dog',
    symbol: 'pinky',
    name: 'Mr.Beast Dog',
  },
  {
    id: 'mr-mint',
    symbol: 'mnt',
    name: 'Mr. Mint',
  },
  {
    id: 'mr-potato',
    symbol: '$mrpotato',
    name: 'Mr Potato Token',
  },
  {
    id: 'mr-rabbit-coin',
    symbol: 'mrabbit',
    name: 'Mr Rabbit Coin',
  },
  {
    id: 'mrspepe',
    symbol: 'mrspepe',
    name: 'MrsPepe',
  },
  {
    id: 'mrweb-finance-2',
    symbol: 'ama',
    name: 'MrWeb Finance',
  },
  {
    id: 'msol',
    symbol: 'msol',
    name: 'Marinade staked SOL',
  },
  {
    id: 'ms-paint',
    symbol: 'paint',
    name: 'MS Paint',
  },
  {
    id: 'msquare-global',
    symbol: 'msq',
    name: 'MSquare Global',
  },
  {
    id: 'mtg-token',
    symbol: 'mtg',
    name: 'MTG Token',
  },
  {
    id: 'mt-pelerin-shares',
    symbol: 'mps',
    name: 'Mt Pelerin Shares',
  },
  {
    id: 'mt-token',
    symbol: 'mt',
    name: 'MT Tower',
  },
  {
    id: 'mu-coin',
    symbol: 'mu',
    name: 'Mu Coin',
  },
  {
    id: 'mudra-exchange',
    symbol: 'mudra',
    name: 'Mudra',
  },
  {
    id: 'mudra-mdr',
    symbol: 'mdr',
    name: 'Mudra MDR',
  },
  {
    id: 'muesliswap-milk',
    symbol: 'milk',
    name: 'MuesliSwap MILK',
  },
  {
    id: 'muesliswap-yield-token',
    symbol: 'myield',
    name: 'MuesliSwap Yield',
  },
  {
    id: 'mugen-finance',
    symbol: 'mgn',
    name: 'Mugen Finance',
  },
  {
    id: 'multi-ai',
    symbol: 'mai',
    name: 'Multi AI',
  },
  {
    id: 'multibit',
    symbol: 'mubi',
    name: 'Multibit',
  },
  {
    id: 'multibtc',
    symbol: 'multibtc',
    name: 'MultiBTC',
  },
  {
    id: 'multichain',
    symbol: 'multi',
    name: 'Multichain',
  },
  {
    id: 'multimoney-global',
    symbol: 'mmgt',
    name: 'MultiMoney.Global',
  },
  {
    id: 'multipad',
    symbol: 'mpad',
    name: 'MultiPad',
  },
  {
    id: 'multiplanetary-inus',
    symbol: 'inus',
    name: 'MultiPlanetary Inus',
  },
  {
    id: 'multiport',
    symbol: 'port',
    name: 'Multiport',
  },
  {
    id: 'multisys',
    symbol: 'myus',
    name: 'Multisys',
  },
  {
    id: 'multivac',
    symbol: 'mtv',
    name: 'MultiVAC',
  },
  {
    id: 'multiverse',
    symbol: 'ai',
    name: 'Multiverse',
  },
  {
    id: 'multiverse-capital',
    symbol: 'mvc',
    name: 'Multiverse Capital',
  },
  {
    id: 'multi-wallet-suite',
    symbol: 'mws',
    name: 'Multi Wallet Suite',
  },
  {
    id: 'mu-meme',
    symbol: 'mume',
    name: 'Mu Meme',
  },
  {
    id: 'mummy-finance',
    symbol: 'mmy',
    name: 'Mummy Finance',
  },
  {
    id: 'mumon-ginsen',
    symbol: 'mg',
    name: 'Mumon-Ginsen',
  },
  {
    id: 'mumu',
    symbol: 'mumu',
    name: 'Mumu',
  },
  {
    id: 'mumu-the-bull-2',
    symbol: 'bull',
    name: 'Mumu the Bull',
  },
  {
    id: 'munch-token',
    symbol: 'munch',
    name: 'Munch',
  },
  {
    id: 'mundocrypto',
    symbol: 'mct',
    name: 'Mundocrypto',
  },
  {
    id: 'murasaki',
    symbol: 'mura',
    name: 'Murasaki',
  },
  {
    id: 'muratiai',
    symbol: 'muratiai',
    name: 'MuratiAI',
  },
  {
    id: 'musd',
    symbol: 'musd',
    name: 'mStable USD',
  },
  {
    id: 'muse-2',
    symbol: 'muse',
    name: 'Muse DAO',
  },
  {
    id: 'muse-ent-nft',
    symbol: 'msct',
    name: 'Muse ENT NFT',
  },
  {
    id: 'museum-of-crypto-art',
    symbol: 'moca',
    name: 'Museum of Crypto Art',
  },
  {
    id: 'musicn',
    symbol: 'mint',
    name: 'MusicN',
  },
  {
    id: 'musk-dao',
    symbol: 'musk',
    name: 'MUSK DAO',
  },
  {
    id: 'musk-gold',
    symbol: 'musk',
    name: 'MUSK Gold',
  },
  {
    id: 'musk-meme',
    symbol: 'muskmeme',
    name: 'MUSK MEME',
  },
  {
    id: 'muskx',
    symbol: 'muskx',
    name: 'MuskX',
  },
  {
    id: 'must',
    symbol: 'must',
    name: 'Must',
  },
  {
    id: 'mutant-pepe',
    symbol: 'mutant',
    name: 'Mutant Pepe',
  },
  {
    id: 'mute',
    symbol: 'mute',
    name: 'Mute',
  },
  {
    id: 'muu-inu',
    symbol: '$muu',
    name: 'MUU',
  },
  {
    id: 'muuu',
    symbol: 'muuu',
    name: 'Muuu Finance',
  },
  {
    id: 'muverse',
    symbol: 'mu',
    name: 'Muverse',
  },
  {
    id: 'muverse-token',
    symbol: 'mct',
    name: 'Muverse MCT',
  },
  {
    id: 'mvs-multiverse',
    symbol: 'mvs',
    name: 'MVS Multiverse',
  },
  {
    id: 'mwcc-ordinals',
    symbol: 'mwcc',
    name: 'MWCC (Ordinals)',
  },
  {
    id: 'mxc',
    symbol: 'mxc',
    name: 'MXC',
  },
  {
    id: 'mxgp-fan-token',
    symbol: 'mxgp',
    name: 'MXGP Fan Token',
  },
  {
    id: 'mxmboxceus-token',
    symbol: 'mbe',
    name: 'MxmBoxcEus Token',
  },
  {
    id: 'mx-million-metaverse-dao',
    symbol: 'mxmdao',
    name: 'Mx Million Metaverse DAO',
  },
  {
    id: 'mx-token',
    symbol: 'mx',
    name: 'MX',
  },
  {
    id: 'mx-token-2',
    symbol: 'mxt',
    name: 'MX TOKEN',
  },
  {
    id: 'mybit-token',
    symbol: 'myb',
    name: 'MyBit',
  },
  {
    id: 'mybricks',
    symbol: 'bricks',
    name: 'MyBricks',
  },
  {
    id: 'myce',
    symbol: 'yce',
    name: 'MYCE',
  },
  {
    id: 'mycelium',
    symbol: 'myc',
    name: 'Mycelium',
  },
  {
    id: 'my-defi-legends',
    symbol: 'dlegends',
    name: 'My DeFi Legends',
  },
  {
    id: 'my-defi-pet',
    symbol: 'dpet',
    name: 'My DeFi Pet',
  },
  {
    id: 'my-master-war',
    symbol: 'mat',
    name: 'My Master War',
  },
  {
    id: 'my-metatrader',
    symbol: 'mmt',
    name: 'My MetaTrader',
  },
  {
    id: 'my-neighbor-alice',
    symbol: 'alice',
    name: 'My Neighbor Alice',
  },
  {
    id: 'myntpay',
    symbol: 'mynt',
    name: 'MyntPay',
  },
  {
    id: 'mypiggiesbank',
    symbol: 'piggie',
    name: 'MyPiggiesBank',
  },
  {
    id: 'myra',
    symbol: 'myra',
    name: 'Myra',
  },
  {
    id: 'myra-token',
    symbol: 'myr',
    name: 'Myra Token',
  },
  {
    id: 'myre-the-dog',
    symbol: '$myre',
    name: 'Myre The Dog',
  },
  {
    id: 'myria',
    symbol: 'myria',
    name: 'Myria',
  },
  {
    id: 'myriadcoin',
    symbol: 'xmy',
    name: 'Myriad',
  },
  {
    id: 'myriad-social',
    symbol: 'myria',
    name: 'Myriad Social',
  },
  {
    id: 'myro',
    symbol: '$myro',
    name: 'Myro',
  },
  {
    id: 'myro-2-0',
    symbol: 'myro2.0',
    name: 'MYRO 2.0',
  },
  {
    id: 'myrowif',
    symbol: 'myrowif',
    name: 'MYROWIF',
  },
  {
    id: 'mysterium',
    symbol: 'myst',
    name: 'Mysterium',
  },
  {
    id: 'mystic-treasure',
    symbol: 'myt',
    name: 'Mystic Treasure',
  },
  {
    id: 'myteamcoin',
    symbol: 'myc',
    name: 'Myteamcoin',
  },
  {
    id: 'mytheria',
    symbol: 'myra',
    name: 'Mytheria',
  },
  {
    id: 'mythic-ore',
    symbol: 'more',
    name: 'Mythic Ore',
  },
  {
    id: 'mythos',
    symbol: 'myth',
    name: 'Mythos',
  },
  {
    id: 'mytoken',
    symbol: 'mt',
    name: 'MyToken',
  },
  {
    id: 'n286',
    symbol: 'n286',
    name: 'N286',
  },
  {
    id: 'nabox',
    symbol: 'nabox',
    name: 'Nabox',
  },
  {
    id: 'nacho-finance',
    symbol: 'nacho',
    name: 'Nacho Finance',
  },
  {
    id: 'nada-protocol-token',
    symbol: 'nada',
    name: 'NADA Protocol Token',
  },
  {
    id: 'nafter',
    symbol: 'naft',
    name: 'Nafter',
  },
  {
    id: 'naga',
    symbol: 'ngc',
    name: 'NAGA',
  },
  {
    id: 'nagaya',
    symbol: 'ngy',
    name: 'NAGAYA',
  },
  {
    id: 'nahmii',
    symbol: 'nii',
    name: 'Nahmii',
  },
  {
    id: 'naka-bodhi-token',
    symbol: 'nbot',
    name: 'Naka Bodhi',
  },
  {
    id: 'nakamoto-games',
    symbol: 'naka',
    name: 'Nakamoto Games',
  },
  {
    id: 'nals',
    symbol: 'nals',
    name: 'NALS',
  },
  {
    id: 'namecoin',
    symbol: 'nmc',
    name: 'Namecoin',
  },
  {
    id: 'namx',
    symbol: 'namx',
    name: 'Namx',
  },
  {
    id: 'nana-token',
    symbol: 'nana',
    name: 'NANA Token',
  },
  {
    id: 'nandin',
    symbol: 'nandi',
    name: 'Nandin',
  },
  {
    id: 'nano',
    symbol: 'xno',
    name: 'Nano',
  },
  {
    id: 'nanobyte',
    symbol: 'nbt',
    name: 'NanoByte',
  },
  {
    id: 'nano-dogecoin',
    symbol: 'indc',
    name: 'Nano Dogecoin',
  },
  {
    id: 'nanomatic',
    symbol: 'nano',
    name: 'Nanomatic',
  },
  {
    id: 'nanometer-bitcoin',
    symbol: 'nmbtc',
    name: 'NanoMeter Bitcoin',
  },
  {
    id: 'naos-finance',
    symbol: 'naos',
    name: 'NAOS Finance',
  },
  {
    id: 'napoleon-x',
    symbol: 'npx',
    name: 'Napoleon X',
  },
  {
    id: 'napoli-fan-token',
    symbol: 'nap',
    name: 'Napoli Fan Token',
  },
  {
    id: 'naruto',
    symbol: 'naruto',
    name: 'Naruto',
  },
  {
    id: 'nasdacoin',
    symbol: 'nsd',
    name: 'Nasdacoin',
  },
  {
    id: 'nasdex-token',
    symbol: 'nsdx',
    name: 'NASDEX',
  },
  {
    id: 'nation3',
    symbol: 'nation',
    name: 'Nation3',
  },
  {
    id: 'naturesgold',
    symbol: 'ngold',
    name: 'NaturesGold',
  },
  {
    id: 'natus-vincere-fan-token',
    symbol: 'navi',
    name: 'Natus Vincere Fan Token',
  },
  {
    id: 'nav-coin',
    symbol: 'nav',
    name: 'Navcoin',
  },
  {
    id: 'navi',
    symbol: 'navi',
    name: 'NAVI',
  },
  {
    id: 'navis',
    symbol: 'nvs',
    name: 'Navis',
  },
  {
    id: 'navy-seal',
    symbol: 'navyseal',
    name: 'Navy seal',
  },
  {
    id: 'naxar',
    symbol: 'naxar',
    name: 'Naxar',
  },
  {
    id: 'naxion',
    symbol: 'nxn',
    name: 'Naxion',
  },
  {
    id: 'nbl',
    symbol: 'nbl',
    name: 'NBL',
  },
  {
    id: 'nchart',
    symbol: 'chart',
    name: 'Nchart Token',
  },
  {
    id: 'ndb',
    symbol: 'ndb',
    name: 'NDB',
  },
  {
    id: 'near',
    symbol: 'near',
    name: 'NEAR Protocol',
  },
  {
    id: 'nearlend-dao',
    symbol: 'neld',
    name: 'Nearlend DAO',
  },
  {
    id: 'nearpad',
    symbol: 'pad',
    name: 'Pad.Fi',
  },
  {
    id: 'nearstarter',
    symbol: 'nstart',
    name: 'NEARStarter',
  },
  {
    id: 'near-tinker-union-gear',
    symbol: 'gear',
    name: 'Near Tinker Union GEAR',
  },
  {
    id: 'neat',
    symbol: 'neat',
    name: 'NEAT',
  },
  {
    id: 'neblio',
    symbol: 'nebl',
    name: 'Neblio',
  },
  {
    id: 'nebula-2',
    symbol: 'nebula',
    name: 'Nebula',
  },
  {
    id: 'nebula-project',
    symbol: 'nbla',
    name: 'Nebula Project',
  },
  {
    id: 'nebulas',
    symbol: 'nas',
    name: 'Nebulas',
  },
  {
    id: 'ned',
    symbol: 'ned',
    name: 'NED',
  },
  {
    id: 'nefty',
    symbol: 'nefty',
    name: 'NeftyBlocks',
  },
  {
    id: 'neighbourhoods',
    symbol: 'nht',
    name: 'Neighbourhoods',
  },
  {
    id: 'neko',
    symbol: 'neko',
    name: 'NEKO',
  },
  {
    id: 'nekoverse-city-of-greed-anima-spirit-gem',
    symbol: 'asg',
    name: 'Nekoverse: City of Greed Anima Spirit Gem',
  },
  {
    id: 'nelore-coin',
    symbol: 'nlc',
    name: 'Nelore Coin',
  },
  {
    id: 'nem',
    symbol: 'xem',
    name: 'NEM',
  },
  {
    id: 'nemesis-downfall',
    symbol: 'nd',
    name: 'Nemesis Downfall',
  },
  {
    id: 'nemgame',
    symbol: 'nem',
    name: 'NemGame',
  },
  {
    id: 'nemo',
    symbol: 'nemo',
    name: 'NEMO',
  },
  {
    id: 'nengcoin',
    symbol: 'neng',
    name: 'Nengcoin',
  },
  {
    id: 'neo',
    symbol: 'neo',
    name: 'NEO',
  },
  {
    id: 'neoaudit-ai',
    symbol: 'naai',
    name: 'NeoAudit AI',
  },
  {
    id: 'neobot',
    symbol: 'neobot',
    name: 'NeoBot',
  },
  {
    id: 'neocortexai',
    symbol: 'corai',
    name: 'NeoCortexAI [OLD]',
  },
  {
    id: 'neocortexai-2',
    symbol: 'ncorai',
    name: 'NeoCortexAI',
  },
  {
    id: 'neon',
    symbol: 'neon',
    name: 'Neon',
  },
  {
    id: 'neon-exchange',
    symbol: 'nex',
    name: 'Nash',
  },
  {
    id: 'neonomad-finance',
    symbol: 'nni',
    name: 'Neonomad',
  },
  {
    id: 'neopepe',
    symbol: 'neop',
    name: 'NeoPepe',
  },
  {
    id: 'neo-pepe',
    symbol: 'nepe',
    name: 'NEO PEPE',
  },
  {
    id: 'neopin',
    symbol: 'npt',
    name: 'Neopin',
  },
  {
    id: 'neorbit',
    symbol: 'safo',
    name: 'SAFEONE CHAIN',
  },
  {
    id: 'neos-credits',
    symbol: 'ncr',
    name: 'Neos Credits',
  },
  {
    id: 'neo-tokyo',
    symbol: 'bytes',
    name: 'Neo Tokyo',
  },
  {
    id: 'neoxa',
    symbol: 'neox',
    name: 'Neoxa',
  },
  {
    id: 'neptune-mutual',
    symbol: 'npm',
    name: 'Neptune Mutual',
  },
  {
    id: 'nerdbot',
    symbol: 'nerd',
    name: 'NerdBot',
  },
  {
    id: 'nero',
    symbol: 'npt',
    name: 'Nero',
  },
  {
    id: 'nero-token',
    symbol: 'nero',
    name: 'Nero Token',
  },
  {
    id: 'nerva',
    symbol: 'xnv',
    name: 'Nerva',
  },
  {
    id: 'nerve-finance',
    symbol: 'nrv',
    name: 'Nerve Finance',
  },
  {
    id: 'nerveflux',
    symbol: 'nerve',
    name: 'NerveFlux',
  },
  {
    id: 'nervenetwork',
    symbol: 'nvt',
    name: 'NerveNetwork',
  },
  {
    id: 'nervos-network',
    symbol: 'ckb',
    name: 'Nervos Network',
  },
  {
    id: 'ness-lab',
    symbol: 'ness',
    name: 'Ness Lab',
  },
  {
    id: 'nest',
    symbol: 'nest',
    name: 'Nest Protocol',
  },
  {
    id: 'nest-arcade',
    symbol: 'nesta',
    name: 'Nest Arcade',
  },
  {
    id: 'nestegg-coin',
    symbol: 'egg',
    name: 'NestEgg Coin',
  },
  {
    id: 'nesten',
    symbol: 'nit',
    name: 'Nesten',
  },
  {
    id: 'nestree',
    symbol: 'egg',
    name: 'Nestree',
  },
  {
    id: 'neta',
    symbol: 'neta',
    name: 'NETA',
  },
  {
    id: 'netflix-tokenized-stock-defichain',
    symbol: 'dnflx',
    name: 'Netflix Tokenized Stock Defichain',
  },
  {
    id: 'nether',
    symbol: 'ntr',
    name: 'Nether',
  },
  {
    id: 'netherfi',
    symbol: 'nfi',
    name: 'NetherFi',
  },
  {
    id: 'neton',
    symbol: 'nto',
    name: 'Neton',
  },
  {
    id: 'netsis',
    symbol: 'net',
    name: 'Netsis',
  },
  {
    id: 'netswap',
    symbol: 'nett',
    name: 'Netswap',
  },
  {
    id: 'netvrk',
    symbol: 'ntvrk',
    name: 'Netvrk',
  },
  {
    id: 'network-capital-token',
    symbol: 'netc',
    name: 'Network Capital Token',
  },
  {
    id: 'network-spirituality',
    symbol: 'net',
    name: 'Network Spirituality',
  },
  {
    id: 'netzero',
    symbol: 'nzero',
    name: 'NETZERO',
  },
  {
    id: 'neurai',
    symbol: 'xna',
    name: 'Neurai',
  },
  {
    id: 'neural-ai',
    symbol: 'neuralai',
    name: 'Neural AI',
  },
  {
    id: 'neuralbot',
    symbol: '$neural',
    name: 'NeuralBot',
  },
  {
    id: 'neural-radiance-field',
    symbol: 'nerf',
    name: 'Neural Radiance Field',
  },
  {
    id: 'neurashi',
    symbol: 'nei',
    name: 'Neurashi',
  },
  {
    id: 'neuroni-ai',
    symbol: 'neuroni',
    name: 'Neuroni AI',
  },
  {
    id: 'neuropulse-ai',
    symbol: 'npai',
    name: 'NeuroPulse AI',
  },
  {
    id: 'neurowebai',
    symbol: 'neuro',
    name: 'NeuroWebAI',
  },
  {
    id: 'neutra-finance',
    symbol: 'neu',
    name: 'Neutra Finance',
  },
  {
    id: 'neutrino',
    symbol: 'xtn',
    name: 'Neutrino Index Token',
  },
  {
    id: 'neutrinos',
    symbol: 'neutr',
    name: 'Neutrinos',
  },
  {
    id: 'neutrino-system-base-token',
    symbol: 'nsbt',
    name: 'Neutrino System Base',
  },
  {
    id: 'neutron-1',
    symbol: 'ntrn',
    name: 'Neutron Coin',
  },
  {
    id: 'neutron-3',
    symbol: 'ntrn',
    name: 'Neutron',
  },
  {
    id: 'neutroswap',
    symbol: 'neutro',
    name: 'Neutroswap',
  },
  {
    id: 'neuy',
    symbol: 'neuy',
    name: 'NEUY',
  },
  {
    id: 'nevacoin',
    symbol: 'neva',
    name: 'NevaCoin',
  },
  {
    id: 'newb-farm',
    symbol: 'newb',
    name: 'NewB.Farm',
  },
  {
    id: 'new-bitshares',
    symbol: 'nbs',
    name: 'New BitShares',
  },
  {
    id: 'new-landbox',
    symbol: 'land',
    name: 'LandBox',
  },
  {
    id: 'newm',
    symbol: 'newm',
    name: 'NEWM',
  },
  {
    id: 'new-order',
    symbol: 'newo',
    name: 'New Order',
  },
  {
    id: 'newpepe',
    symbol: 'pepe',
    name: 'NEWPEPE',
  },
  {
    id: 'newscrypto-coin',
    symbol: 'nwc',
    name: 'Newscrypto Coin',
  },
  {
    id: 'newsly',
    symbol: 'news',
    name: 'Newsly',
  },
  {
    id: 'newt',
    symbol: 'newt',
    name: 'Newt',
  },
  {
    id: 'newton',
    symbol: 'ntn',
    name: 'Newton',
  },
  {
    id: 'newton-project',
    symbol: 'new',
    name: 'Newton Project',
  },
  {
    id: 'newtowngaming',
    symbol: 'ntg',
    name: 'NEWTOWNGAMING',
  },
  {
    id: 'newu-ordinals',
    symbol: 'newu',
    name: 'NEWU (Ordinals)',
  },
  {
    id: 'new-world-order',
    symbol: 'state',
    name: 'New World Order',
  },
  {
    id: 'new-year-token',
    symbol: 'nyt',
    name: 'New Year',
  },
  {
    id: 'newyorkcoin',
    symbol: 'nyc',
    name: 'NewYorkCoin',
  },
  {
    id: 'newyork-exchange',
    symbol: 'nye',
    name: 'NewYork Exchange',
  },
  {
    id: 'nexacoin',
    symbol: 'nexa',
    name: 'Nexa',
  },
  {
    id: 'nexai',
    symbol: 'nex',
    name: 'NexAI',
  },
  {
    id: 'nexalt',
    symbol: 'xlt',
    name: 'Nexalt',
  },
  {
    id: 'nexbox',
    symbol: 'nexbox',
    name: 'NEXBOX',
  },
  {
    id: 'nexdax',
    symbol: 'nt',
    name: 'NexDAX',
  },
  {
    id: 'nexo',
    symbol: 'nexo',
    name: 'NEXO',
  },
  {
    id: 'nextdao',
    symbol: 'nax',
    name: 'NextDAO',
  },
  {
    id: 'next-earth',
    symbol: 'nxtt',
    name: 'Next Earth',
  },
  {
    id: 'nextexchange',
    symbol: 'next',
    name: 'NEXT',
  },
  {
    id: 'nextype-finance',
    symbol: 'nt',
    name: 'NEXTYPE Finance',
  },
  {
    id: 'nexum',
    symbol: 'nexm',
    name: 'Nexum',
  },
  {
    id: 'nexus',
    symbol: 'nxs',
    name: 'Nexus',
  },
  {
    id: 'nexus-asa',
    symbol: 'gp',
    name: 'Nexus ASA',
  },
  {
    id: 'nexus-chain',
    symbol: 'wnexus',
    name: 'Nexus Chain',
  },
  {
    id: 'nexus-dubai',
    symbol: 'nxd',
    name: 'Nexus Dubai',
  },
  {
    id: 'nexuspad',
    symbol: 'nexus',
    name: 'Nexuspad',
  },
  {
    id: 'nexus-pro',
    symbol: 'nexus',
    name: 'Nexus Pro',
  },
  {
    id: 'nezuko',
    symbol: 'nezuko',
    name: 'Nezuko',
  },
  {
    id: 'nfprompt-token',
    symbol: 'nfp',
    name: 'NFPrompt',
  },
  {
    id: 'nft11',
    symbol: 'nft11',
    name: 'NFT11',
  },
  {
    id: 'nft-art-finance',
    symbol: 'nftart',
    name: 'NFT Art Finance',
  },
  {
    id: 'nftb',
    symbol: 'nftb',
    name: 'NFTb',
  },
  {
    id: 'nftblackmarket',
    symbol: 'nbm',
    name: 'NFTBlackmarket',
  },
  {
    id: 'nftbomb',
    symbol: 'nbp',
    name: 'NFTBomb',
  },
  {
    id: 'nftbooks',
    symbol: 'nftbs',
    name: 'NFTBooks',
  },
  {
    id: 'nft-champions',
    symbol: 'champ',
    name: 'NFT Champions',
  },
  {
    id: 'nftcloud',
    symbol: 'cloud',
    name: 'NFTCloud',
  },
  {
    id: 'nft-combining',
    symbol: 'nftc',
    name: 'NFT Combining',
  },
  {
    id: 'nftdao',
    symbol: 'nao',
    name: 'NFTDAO',
  },
  {
    id: 'nftdeli',
    symbol: 'deli',
    name: 'NFTDeli',
  },
  {
    id: 'nftearth',
    symbol: 'nfte',
    name: 'NFTEarth',
  },
  {
    id: 'nfteyez',
    symbol: 'eye',
    name: 'NftEyez',
  },
  {
    id: 'nftfundart',
    symbol: 'nfa',
    name: 'NFTFundArt',
  },
  {
    id: 'nftify',
    symbol: 'n1',
    name: 'NFTify',
  },
  {
    id: 'nftlaunch',
    symbol: 'nftl',
    name: 'NFTLaunch',
  },
  {
    id: 'nft-maker',
    symbol: '$nmkr',
    name: 'NMKR',
  },
  {
    id: 'nftmall',
    symbol: 'gem',
    name: 'NFTmall',
  },
  {
    id: 'nftmart-token',
    symbol: 'nmt',
    name: 'NFTMart',
  },
  {
    id: 'nft-protocol',
    symbol: 'nft',
    name: 'NFT Protocol',
  },
  {
    id: 'nftpunk-finance',
    symbol: 'nftpunk',
    name: 'NFTPunk.Finance',
  },
  {
    id: 'nftrade',
    symbol: 'nftd',
    name: 'NFTrade',
  },
  {
    id: 'nft-soccer-games',
    symbol: 'nfsg',
    name: 'NFT Soccer Games',
  },
  {
    id: 'nft-stars',
    symbol: 'nfts',
    name: 'NFT Stars',
  },
  {
    id: 'nftstyle',
    symbol: 'nftstyle',
    name: 'NFTStyle',
  },
  {
    id: 'nft-track-protocol',
    symbol: 'ntp',
    name: 'NFT Track Protocol',
  },
  {
    id: 'nft-workx',
    symbol: 'wrkx',
    name: 'NFT Workx',
  },
  {
    id: 'nft-worlds',
    symbol: 'wrld',
    name: 'NFT Worlds',
  },
  {
    id: 'nftx',
    symbol: 'nftx',
    name: 'NFTX',
  },
  {
    id: 'nfty-token',
    symbol: 'nfty',
    name: 'NFTY',
  },
  {
    id: 'ngatiger',
    symbol: 'nga',
    name: 'NGATiger',
  },
  {
    id: 'ngt',
    symbol: 'ngt',
    name: 'GoSleep NGT',
  },
  {
    id: 'nibi',
    symbol: 'nibi',
    name: 'NIBI',
  },
  {
    id: 'niccagewaluigielmo42069inu',
    symbol: 'shib',
    name: 'NicCageWaluigiElmo42069Inu',
  },
  {
    id: 'nice-2',
    symbol: '6969',
    name: 'NICE',
  },
  {
    id: 'niftify',
    symbol: 'nift',
    name: 'Niftify',
  },
  {
    id: 'nifty-league',
    symbol: 'nftl',
    name: 'Nifty League',
  },
  {
    id: 'nifty-token',
    symbol: 'nfty',
    name: 'NFTY DeFi Protocol',
  },
  {
    id: 'nightingale-token',
    symbol: 'ngit',
    name: 'Nightingale Token',
  },
  {
    id: 'nightverse-game',
    symbol: 'nvg',
    name: 'NightVerse Game',
  },
  {
    id: 'nihao',
    symbol: 'nihao',
    name: 'Nihao',
  },
  {
    id: 'niifi',
    symbol: 'niifi',
    name: 'NiiFi',
  },
  {
    id: 'niko-2',
    symbol: 'nko',
    name: 'Niko',
  },
  {
    id: 'nikssa',
    symbol: 'nks',
    name: 'Nikssa',
  },
  {
    id: 'nile',
    symbol: 'nile',
    name: 'Nile',
  },
  {
    id: 'nimbus-platform-gnimb',
    symbol: 'gnimb',
    name: 'Nimbus Platform GNIMB',
  },
  {
    id: 'nimbus-utility',
    symbol: 'nimb',
    name: 'Nimbus Utility',
  },
  {
    id: 'nimiq-2',
    symbol: 'nim',
    name: 'Nimiq',
  },
  {
    id: 'ninapumps',
    symbol: 'nina',
    name: 'NinaPumps',
  },
  {
    id: 'ninjapepe',
    symbol: 'ninjapepe',
    name: 'NinjaPepe',
  },
  {
    id: 'ninja-protocol',
    symbol: 'ninja',
    name: 'Ninja Protocol',
  },
  {
    id: 'ninjaroll',
    symbol: 'roll',
    name: 'NinjaRoll',
  },
  {
    id: 'ninja-squad',
    symbol: 'nst',
    name: 'Ninja Squad',
  },
  {
    id: 'ninja-turtles',
    symbol: '$ninja',
    name: 'NINJA TURTLES',
  },
  {
    id: 'ninja-warriors',
    symbol: 'nwt',
    name: 'Ninja Warriors',
  },
  {
    id: 'niob',
    symbol: 'niob',
    name: 'NIOB',
  },
  {
    id: 'niobio-cash',
    symbol: 'nbr',
    name: 'Niobio',
  },
  {
    id: 'nioctib',
    symbol: 'nioctib',
    name: 'nioctiB',
  },
  {
    id: 'nirvana-ana',
    symbol: 'ana',
    name: 'Nirvana ANA',
  },
  {
    id: 'nirvana-chain',
    symbol: 'nac',
    name: 'Nirvana Chain',
  },
  {
    id: 'nirvana-meta-mnu-chain',
    symbol: 'mnu',
    name: 'Nirvana Meta MNU Chain',
  },
  {
    id: 'nirvana-prana',
    symbol: 'prana',
    name: 'Nirvana prANA',
  },
  {
    id: 'nitrobots',
    symbol: 'nitro',
    name: 'NitroBots',
  },
  {
    id: 'nitro-cartel',
    symbol: 'trove',
    name: 'Arbitrove Governance Token',
  },
  {
    id: 'nitroex',
    symbol: 'ntx',
    name: 'NitroEX',
  },
  {
    id: 'nitroken',
    symbol: 'nito',
    name: 'Nitroken',
  },
  {
    id: 'nitro-league',
    symbol: 'nitro',
    name: 'Nitro League',
  },
  {
    id: 'nitro-network',
    symbol: 'ncash',
    name: 'Nitro Network',
  },
  {
    id: 'nitroshiba',
    symbol: 'nishib',
    name: 'NitroShiba',
  },
  {
    id: 'nix',
    symbol: 'nix',
    name: 'NIX',
  },
  {
    id: 'nix-bridge-token',
    symbol: 'voice',
    name: 'Voice',
  },
  {
    id: 'niza-global',
    symbol: 'niza',
    name: 'Niza Global',
  },
  {
    id: 'nkcl-classic',
    symbol: 'nkclc',
    name: 'NKCL Classic',
  },
  {
    id: 'nkn',
    symbol: 'nkn',
    name: 'NKN',
  },
  {
    id: 'nkyc-token',
    symbol: 'nkyc',
    name: 'NKYC Token',
  },
  {
    id: 'noahswap',
    symbol: 'noah',
    name: 'NoahSwap',
  },
  {
    id: 'noahswap-2',
    symbol: 'noah',
    name: 'Noahswap',
  },
  {
    id: 'noa-play',
    symbol: 'noa',
    name: 'NOA PLAY',
  },
  {
    id: 'nobility',
    symbol: 'nbl',
    name: 'Nobility',
  },
  {
    id: 'no-decimal',
    symbol: 'scarce',
    name: 'No Decimal',
  },
  {
    id: 'nodes-reward-coin',
    symbol: 'nrc',
    name: 'Nodes Reward Coin',
  },
  {
    id: 'nodestats',
    symbol: 'ns',
    name: 'Nodestats',
  },
  {
    id: 'nodetrade',
    symbol: 'mnx',
    name: 'Nodetrade',
  },
  {
    id: 'nodewaves',
    symbol: 'nws',
    name: 'Nodewaves',
  },
  {
    id: 'nodle-network',
    symbol: 'nodl',
    name: 'Nodle Network',
  },
  {
    id: 'nogas',
    symbol: 'ngs',
    name: 'NoGas',
  },
  {
    id: 'noia-network',
    symbol: 'noia',
    name: 'Syntropy',
  },
  {
    id: 'noir-phygital',
    symbol: 'noir',
    name: 'Noir Phygital',
  },
  {
    id: 'nois',
    symbol: 'nois',
    name: 'Nois',
  },
  {
    id: 'noisegpt',
    symbol: 'enqai',
    name: 'enqAI',
  },
  {
    id: 'noka-solana-a',
    symbol: 'noka',
    name: 'Noka Solana A',
  },
  {
    id: 'nola',
    symbol: 'nola',
    name: 'Nola',
  },
  {
    id: 'nole-inu',
    symbol: 'n0le',
    name: 'Nole Inu',
  },
  {
    id: 'nolimitcoin',
    symbol: 'nlc',
    name: 'NoLimitCoin',
  },
  {
    id: 'nolus',
    symbol: 'nls',
    name: 'Nolus',
  },
  {
    id: 'nomad-exiles',
    symbol: 'pride',
    name: 'Nomad Exiles',
  },
  {
    id: 'nominex',
    symbol: 'nmx',
    name: 'Nominex',
  },
  {
    id: 'none-trading',
    symbol: 'none',
    name: 'None Trading',
  },
  {
    id: 'non-fungible-fungi',
    symbol: 'spores',
    name: 'Non-Fungible Fungi',
  },
  {
    id: 'non-playable-coin',
    symbol: 'npc',
    name: 'Non-Playable Coin',
  },
  {
    id: 'no-one',
    symbol: 'noone',
    name: 'No One',
  },
  {
    id: 'noot',
    symbol: 'noot',
    name: 'NOOT',
  },
  {
    id: 'noot-ordinals',
    symbol: 'noot',
    name: 'NOOT (Ordinals)',
  },
  {
    id: 'nordek',
    symbol: 'nrk',
    name: 'Nordek',
  },
  {
    id: 'nord-finance',
    symbol: 'nord',
    name: 'Nord Finance',
  },
  {
    id: 'norigo',
    symbol: 'go!',
    name: 'NoriGO!',
  },
  {
    id: 'norma-in-metaland',
    symbol: 'gram',
    name: 'GRAM Token',
  },
  {
    id: 'nort',
    symbol: 'xrt',
    name: 'norT',
  },
  {
    id: 'nosana',
    symbol: 'nos',
    name: 'Nosana',
  },
  {
    id: 'noso',
    symbol: 'noso',
    name: 'Noso',
  },
  {
    id: 'nosturis',
    symbol: 'ntrs',
    name: 'Nosturis',
  },
  {
    id: 'notable',
    symbol: 'nbl',
    name: 'Notable',
  },
  {
    id: 'notcoin',
    symbol: 'not',
    name: 'Notcoin',
  },
  {
    id: 'note',
    symbol: 'note',
    name: 'Note',
  },
  {
    id: 'not-financial-advice',
    symbol: 'nfai',
    name: 'Not Financial Advice',
  },
  {
    id: 'nothing-2',
    symbol: 'nothing',
    name: 'NOTHING',
  },
  {
    id: 'nothing-token',
    symbol: 'thing',
    name: 'Nothing Token',
  },
  {
    id: 'no-ticker',
    symbol: 'noticker',
    name: 'No ticker',
  },
  {
    id: 'notional-finance',
    symbol: 'note',
    name: 'Notional Finance',
  },
  {
    id: 'nova-2',
    symbol: 'nova',
    name: 'Nova',
  },
  {
    id: 'novacoin',
    symbol: 'nvc',
    name: 'Novacoin',
  },
  {
    id: 'nova-dao',
    symbol: 'nova',
    name: 'Nova DAO',
  },
  {
    id: 'novadex',
    symbol: 'nvx',
    name: 'NovaDEX',
  },
  {
    id: 'nova-finance',
    symbol: 'nova',
    name: 'Nova Finance',
  },
  {
    id: 'novara-calcio-fan-token',
    symbol: 'nov',
    name: 'Novara Calcio Fan Token',
  },
  {
    id: 'novatti-australian-digital-dollar',
    symbol: 'audd',
    name: 'Novatti Australian Digital Dollar',
  },
  {
    id: 'novawchi',
    symbol: 'vachi',
    name: 'NOVAWCHI',
  },
  {
    id: 'novax',
    symbol: 'novax',
    name: 'NovaX',
  },
  {
    id: 'novem-gold',
    symbol: 'nnn',
    name: 'Novem Gold',
  },
  {
    id: 'novem-pro',
    symbol: 'nvm',
    name: 'Novem Pro',
  },
  {
    id: 'novo-9b9480a5-9545-49c3-a999-94ec2902cedb',
    symbol: 'novo',
    name: 'Novo',
  },
  {
    id: 'nowai',
    symbol: '$nowai',
    name: 'NOWAI',
  },
  {
    id: 'npick-block',
    symbol: 'npick',
    name: 'NPick Block',
  },
  {
    id: 'nshare',
    symbol: 'nshare',
    name: 'NSHARE',
  },
  {
    id: 'nsights',
    symbol: 'nsi',
    name: 'nSights',
  },
  {
    id: 'nsurance',
    symbol: 'n',
    name: 'nsurance',
  },
  {
    id: 'nsure-network',
    symbol: 'nsure',
    name: 'Nsure Network',
  },
  {
    id: 'nuance',
    symbol: 'nua',
    name: 'Nuance',
  },
  {
    id: 'nuclear-waste-water',
    symbol: 'ncww',
    name: 'Nuclear Waste Water',
  },
  {
    id: 'nucleon-space',
    symbol: 'nut',
    name: 'Nucleon',
  },
  {
    id: 'nucleon-xcfx',
    symbol: 'xcfx',
    name: 'Nucleon xCFX',
  },
  {
    id: 'nucleus-vision',
    symbol: 'ncash',
    name: 'Nucleus Vision',
  },
  {
    id: 'nuco-cloud',
    symbol: 'ncdt',
    name: 'nuco.cloud',
  },
  {
    id: 'nucypher',
    symbol: 'nu',
    name: 'NuCypher',
  },
  {
    id: 'nugencoin',
    symbol: 'nugen',
    name: 'Nugencoin',
  },
  {
    id: 'null-social-finance',
    symbol: 'nsf',
    name: 'Null Social Finance',
  },
  {
    id: 'nuls',
    symbol: 'nuls',
    name: 'NULS',
  },
  {
    id: 'nulswap',
    symbol: 'nswap',
    name: 'Nulswap',
  },
  {
    id: 'num-ars',
    symbol: 'nars',
    name: 'Num ARS',
  },
  {
    id: 'number-1-token',
    symbol: 'nr1',
    name: 'Number 1',
  },
  {
    id: 'numbers-protocol',
    symbol: 'num',
    name: 'Numbers Protocol',
  },
  {
    id: 'numeraire',
    symbol: 'nmr',
    name: 'Numeraire',
  },
  {
    id: 'numi-shards',
    symbol: 'numi',
    name: 'Numi Shards',
  },
  {
    id: 'numisme2',
    symbol: 'nume2',
    name: 'Numisme2',
  },
  {
    id: 'numitor',
    symbol: 'numi',
    name: 'Numitor',
  },
  {
    id: 'nuna',
    symbol: 'nuna',
    name: 'Nuna',
  },
  {
    id: 'nunet',
    symbol: 'ntx',
    name: 'NuNet',
  },
  {
    id: 'nunu-spirits',
    symbol: 'nnt',
    name: 'Nunu Spirits',
  },
  {
    id: 'nuon',
    symbol: 'nuon',
    name: 'Nuon',
  },
  {
    id: 'nurifootball',
    symbol: 'nrfb',
    name: 'NuriFootBall',
  },
  {
    id: 'nuritopia',
    symbol: 'nblu',
    name: 'Nuritopia',
  },
  {
    id: 'nusa-finance',
    symbol: 'nusa',
    name: 'NUSA',
  },
  {
    id: 'nusd',
    symbol: 'susd',
    name: 'sUSD',
  },
  {
    id: 'nuson-chain',
    symbol: 'nsc',
    name: 'Nuson Chain',
  },
  {
    id: 'nutgain',
    symbol: 'nutgv2',
    name: 'NUTGAIN',
  },
  {
    id: 'nvidia-tokenized-stock-defichain',
    symbol: 'dnvda',
    name: 'Nvidia Tokenized Stock Defichain',
  },
  {
    id: 'nvirworld',
    symbol: 'nvir',
    name: 'NvirWorld',
  },
  {
    id: 'nx7',
    symbol: 'nx7',
    name: 'NX7',
  },
  {
    id: 'nxd-next',
    symbol: 'nxdt',
    name: 'NXD Next',
  },
  {
    id: 'nxm',
    symbol: 'nxm',
    name: 'Nexus Mutual',
  },
  {
    id: 'nxt',
    symbol: 'nxt',
    name: 'NXT',
  },
  {
    id: 'nxusd',
    symbol: 'nxusd',
    name: 'NXUSD',
  },
  {
    id: 'nyandoge-international',
    symbol: 'nyandoge',
    name: 'NyanDOGE International',
  },
  {
    id: 'nyan-meme-coin',
    symbol: 'nyan',
    name: 'Nyan Meme Coin',
  },
  {
    id: 'nyantereum',
    symbol: 'nyante',
    name: 'Nyantereum International',
  },
  {
    id: 'ny-blockchain',
    symbol: 'nybc',
    name: 'NY Blockchain',
  },
  {
    id: 'nycccoin',
    symbol: 'nyc',
    name: 'NewYorkCityCoin',
  },
  {
    id: 'nym',
    symbol: 'nym',
    name: 'Nym',
  },
  {
    id: 'nyro',
    symbol: 'nyro',
    name: 'Nyro',
  },
  {
    id: 'nyxia-ai',
    symbol: 'nyxc',
    name: 'Nyxia AI',
  },
  {
    id: 'nyzo',
    symbol: 'nyzo',
    name: 'Nyzo',
  },
  {
    id: 'o3-swap',
    symbol: 'o3',
    name: 'O3 Swap',
  },
  {
    id: 'oak-network',
    symbol: 'oak',
    name: 'OAK Network',
  },
  {
    id: 'oasis-metaverse',
    symbol: 'oasis',
    name: 'Oasis Metaverse',
  },
  {
    id: 'oasis-network',
    symbol: 'rose',
    name: 'Oasis Network',
  },
  {
    id: 'oasys',
    symbol: 'oas',
    name: 'Oasys',
  },
  {
    id: 'oath',
    symbol: 'oath',
    name: 'OATH',
  },
  {
    id: 'obama6900',
    symbol: 'obx',
    name: 'Obama6900',
  },
  {
    id: 'obortech',
    symbol: 'obot',
    name: 'Obortech',
  },
  {
    id: 'obrok',
    symbol: 'obrok',
    name: 'OBRok',
  },
  {
    id: 'obscuro',
    symbol: 'obx',
    name: 'Obscuro',
  },
  {
    id: 'observer-coin',
    symbol: 'obsr',
    name: 'Observer',
  },
  {
    id: 'obsidian-coin',
    symbol: 'obn',
    name: 'Obsidian Coin',
  },
  {
    id: 'obsidium',
    symbol: 'obs',
    name: 'Obsidium',
  },
  {
    id: 'obtoken',
    symbol: 'obt',
    name: 'OB',
  },
  {
    id: 'ocavu-network',
    symbol: 'ocavu',
    name: 'Ocavu Network',
  },
  {
    id: 'occamfi',
    symbol: 'occ',
    name: 'OccamFi',
  },
  {
    id: 'occamx',
    symbol: 'ocx',
    name: 'OccamX',
  },
  {
    id: 'oceanex',
    symbol: 'oce',
    name: 'OceanEX',
  },
  {
    id: 'oceanfi',
    symbol: 'ocf',
    name: 'OceanFi',
  },
  {
    id: 'oceanland',
    symbol: 'oland',
    name: 'OceanLand',
  },
  {
    id: 'ocean-protocol',
    symbol: 'ocean',
    name: 'Ocean Protocol',
  },
  {
    id: 'och',
    symbol: 'och',
    name: 'Orchai',
  },
  {
    id: 'ociswap',
    symbol: 'oci',
    name: 'Ociswap',
  },
  {
    id: 'octaplex-network',
    symbol: 'plx',
    name: 'Octaplex Network',
  },
  {
    id: 'octaspace',
    symbol: 'octa',
    name: 'OctaSpace',
  },
  {
    id: 'octavus-prime',
    symbol: 'octavus',
    name: 'Octavus Prime',
  },
  {
    id: 'octofi',
    symbol: 'octo',
    name: 'OctoFi',
  },
  {
    id: 'octo-gaming',
    symbol: 'otk',
    name: 'Octokn',
  },
  {
    id: 'octopus-network',
    symbol: 'oct',
    name: 'Octopus Network',
  },
  {
    id: 'octopus-protocol',
    symbol: 'ops',
    name: 'Octopus Protocol',
  },
  {
    id: 'octorand',
    symbol: 'octo',
    name: 'Octorand',
  },
  {
    id: 'octus-bridge',
    symbol: 'bridge',
    name: 'Octus Bridge',
  },
  {
    id: 'ocvcoin',
    symbol: 'ocv',
    name: 'Ocvcoin',
  },
  {
    id: 'oddz',
    symbol: 'oddz',
    name: 'Oddz',
  },
  {
    id: 'odem',
    symbol: 'ode',
    name: 'ODEM',
  },
  {
    id: 'odin-protocol',
    symbol: 'odin',
    name: 'Odin Protocol',
  },
  {
    id: 'odung',
    symbol: 'derp',
    name: 'oDung',
  },
  {
    id: 'oduwa-coin',
    symbol: 'owc',
    name: 'Oduwa Coin',
  },
  {
    id: 'odyssey',
    symbol: 'ocn',
    name: 'Odyssey',
  },
  {
    id: 'odysseywallet',
    symbol: 'odys',
    name: 'OdysseyWallet',
  },
  {
    id: 'oec-bch',
    symbol: 'bchk',
    name: 'OEC BCH',
  },
  {
    id: 'oec-binance-coin',
    symbol: 'bnb',
    name: 'OEC Binance Coin',
  },
  {
    id: 'oec-btc',
    symbol: 'btck',
    name: 'OEC BTC',
  },
  {
    id: 'oec-etc',
    symbol: 'etck',
    name: 'OEC ETC',
  },
  {
    id: 'oec-eth',
    symbol: 'ethk',
    name: 'OEC ETH',
  },
  {
    id: 'oec-fil',
    symbol: 'filk',
    name: 'OEC FIL',
  },
  {
    id: 'oec-ltc',
    symbol: 'ltck',
    name: 'OEC LTC',
  },
  {
    id: 'oec-shib',
    symbol: 'shibk',
    name: 'OEC SHIB',
  },
  {
    id: 'oec-token',
    symbol: 'okt',
    name: 'OKT Chain',
  },
  {
    id: 'oec-uni',
    symbol: 'unik',
    name: 'OEC UNI',
  },
  {
    id: 'ofcourse-i-still-love-you',
    symbol: 'ocisly',
    name: 'Of Course I Still Love You',
  },
  {
    id: 'ofero',
    symbol: 'ofe',
    name: 'Ofero',
  },
  {
    id: 'offshift',
    symbol: 'xft',
    name: 'Offshift',
  },
  {
    id: 'ofi-cash',
    symbol: 'ofi',
    name: 'OFI.cash',
  },
  {
    id: 'og-fan-token',
    symbol: 'og',
    name: 'OG Fan Token',
  },
  {
    id: 'oggy-inu',
    symbol: 'oggy',
    name: 'Oggy Inu',
  },
  {
    id: 'oggy-inu-2',
    symbol: 'oggy',
    name: 'Oggy Inu [ETH]',
  },
  {
    id: 'og-sminem',
    symbol: 'ogsm',
    name: 'OG SMINEM',
  },
  {
    id: 'ogzclub',
    symbol: 'ogz',
    name: 'OGzClub',
  },
  {
    id: 'oh-finance',
    symbol: 'oh',
    name: 'Oh! Finance',
  },
  {
    id: 'ohms',
    symbol: 'ohms',
    name: 'OHMS',
  },
  {
    id: 'oho-blockchain',
    symbol: 'oho',
    name: 'OHO Blockchain',
  },
  {
    id: 'oikos',
    symbol: 'oks',
    name: 'Oikos',
  },
  {
    id: 'oiler',
    symbol: 'oil',
    name: 'Oiler',
  },
  {
    id: 'oil-token-162dc739-3b37-4da2-88a7-0d5b8e03ab14',
    symbol: 'oil',
    name: 'Oil Token',
  },
  {
    id: 'oin-finance',
    symbol: 'oin',
    name: 'OIN Finance',
  },
  {
    id: 'ojamu',
    symbol: 'oja',
    name: 'Ojamu',
  },
  {
    id: 'okage-inu',
    symbol: 'okage',
    name: 'Okage Inu',
  },
  {
    id: 'okami-lana',
    symbol: 'okana',
    name: 'Okami Lana',
  },
  {
    id: 'okb',
    symbol: 'okb',
    name: 'OKB',
  },
  {
    id: 'okcash',
    symbol: 'ok',
    name: 'Okcash',
  },
  {
    id: 'okeycoin',
    symbol: 'okey',
    name: 'OKEYCOIN',
  },
  {
    id: 'okidoki-social',
    symbol: 'doki',
    name: 'Okidoki Social',
  },
  {
    id: 'okiku',
    symbol: 'okiku',
    name: 'Okiku',
  },
  {
    id: 'okiku-kento',
    symbol: 'oken',
    name: 'Okiku Kento',
  },
  {
    id: 'okletsplay',
    symbol: 'oklp',
    name: 'OkLetsPlay',
  },
  {
    id: 'okratech-token',
    symbol: 'ort',
    name: 'Okratech',
  },
  {
    id: 'okuru',
    symbol: 'xot',
    name: 'Okuru',
  },
  {
    id: 'okx-beth',
    symbol: 'beth',
    name: 'OKX BETH',
  },
  {
    id: 'okx-staked-dot2',
    symbol: 'okdot2',
    name: 'OKX Staked DOT2',
  },
  {
    id: 'old-bitcoin',
    symbol: 'bc',
    name: 'Old Bitcoin',
  },
  {
    id: 'olecoin',
    symbol: 'ole',
    name: 'OleCoin',
  },
  {
    id: 'olive',
    symbol: 'olv',
    name: 'OLIVE',
  },
  {
    id: 'olivecash',
    symbol: 'olive',
    name: 'Olive Cash',
  },
  {
    id: 'oloid',
    symbol: 'oloid',
    name: 'OLOID',
  },
  {
    id: 'olympus',
    symbol: 'ohm',
    name: 'Olympus',
  },
  {
    id: 'olympus-2',
    symbol: 'olai',
    name: 'OLYMPUS',
  },
  {
    id: 'olympus-v1',
    symbol: 'ohm',
    name: 'Olympus v1',
  },
  {
    id: 'olyverse',
    symbol: 'oly',
    name: 'Olyverse',
  },
  {
    id: 'omamori',
    symbol: 'omm',
    name: 'OMAMORI',
  },
  {
    id: 'omax-token',
    symbol: 'omax',
    name: 'Omax',
  },
  {
    id: 'ombre',
    symbol: 'omb',
    name: 'Ombre',
  },
  {
    id: 'omchain',
    symbol: 'omc',
    name: 'Omchain',
  },
  {
    id: 'omega',
    symbol: 'omega',
    name: 'OMEGA',
  },
  {
    id: 'omega-network',
    symbol: 'omn',
    name: 'OmegaNetwork',
  },
  {
    id: 'omeletteswap',
    symbol: 'omlt',
    name: 'OmeletteSwap',
  },
  {
    id: 'omisego',
    symbol: 'omg',
    name: 'OMG Network',
  },
  {
    id: 'ommniverse',
    symbol: 'ommi',
    name: 'Ommniverse',
  },
  {
    id: 'omni',
    symbol: 'omni',
    name: 'Omni',
  },
  {
    id: 'omniabot',
    symbol: 'omnia',
    name: 'OmniaBot',
  },
  {
    id: 'omniaverse',
    symbol: 'omnia',
    name: 'OmniaVerse',
  },
  {
    id: 'omnibot',
    symbol: 'omni',
    name: 'omniBOT',
  },
  {
    id: 'omnibotx',
    symbol: 'omnix',
    name: 'OmniBotX',
  },
  {
    id: 'omnicat',
    symbol: 'omni',
    name: 'OmniCat',
  },
  {
    id: 'omni-consumer-protocol',
    symbol: 'ocp',
    name: 'Omni Consumer Protocol',
  },
  {
    id: 'omniflix-network',
    symbol: 'flix',
    name: 'OmniFlix Network',
  },
  {
    id: 'omnikingdoms-gold',
    symbol: 'omkg',
    name: 'OmniKingdoms Gold',
  },
  {
    id: 'omnisea',
    symbol: 'osea',
    name: 'Omnisea',
  },
  {
    id: 'omo-exchange',
    symbol: 'omo',
    name: 'OMO Exchange',
  },
  {
    id: 'omotenashicoin',
    symbol: 'mtns',
    name: 'OmotenashiCoin',
  },
  {
    id: 'onbuff',
    symbol: 'onit',
    name: 'ONBUFF',
  },
  {
    id: 'onchain-ai',
    symbol: 'ocai',
    name: 'Onchain AI',
  },
  {
    id: 'on-chain-dynamics',
    symbol: 'ocd',
    name: 'On-Chain Dynamics',
  },
  {
    id: 'onchain-trade',
    symbol: 'ot',
    name: 'Onchain Trade',
  },
  {
    id: 'onchain-trade-protocol',
    symbol: 'ot',
    name: 'Onchain Trade Protocol',
  },
  {
    id: 'ondo-finance',
    symbol: 'ondo',
    name: 'Ondo',
  },
  {
    id: 'ondo-us-dollar-yield',
    symbol: 'usdy',
    name: 'Ondo US Dollar Yield',
  },
  {
    id: 'one',
    symbol: 'one',
    name: 'One',
  },
  {
    id: 'one-basis-cash',
    symbol: 'obs',
    name: 'One Basis Cash',
  },
  {
    id: 'one-cash',
    symbol: 'onc',
    name: 'One Cash',
  },
  {
    id: 'onedex',
    symbol: 'one',
    name: 'OneDex',
  },
  {
    id: 'onedex-rone',
    symbol: 'rone-bb2e',
    name: 'OneDex rONE',
  },
  {
    id: 'one-hundred-million-inu',
    symbol: 'ohmi',
    name: 'One Hundred Million Inu',
  },
  {
    id: 'oneichi',
    symbol: 'oneichi',
    name: 'oneICHI',
  },
  {
    id: 'one-ledger',
    symbol: 'olt',
    name: 'OneLedger',
  },
  {
    id: 'onerare',
    symbol: 'orare',
    name: 'OneRare',
  },
  {
    id: 'onering',
    symbol: 'ring',
    name: 'OneRing',
  },
  {
    id: 'one-share',
    symbol: 'ons',
    name: 'One Share',
  },
  {
    id: 'onespace',
    symbol: '1sp',
    name: 'Onespace',
  },
  {
    id: 'onestop',
    symbol: 'ost',
    name: 'Onestop',
  },
  {
    id: 'onetokenburn',
    symbol: 'one',
    name: 'onetokenburn',
  },
  {
    id: 'one-world-coin',
    symbol: 'owo',
    name: 'One World Coin',
  },
  {
    id: 'onez',
    symbol: 'onez',
    name: 'ONEZ',
  },
  {
    id: 'ong',
    symbol: 'ong',
    name: 'Ontology Gas',
  },
  {
    id: 'onigiri-kitty',
    symbol: 'oky',
    name: 'Onigiri Kitty',
  },
  {
    id: 'oni-token',
    symbol: 'oni',
    name: 'ONINO',
  },
  {
    id: 'only1',
    symbol: 'like',
    name: 'Only1',
  },
  {
    id: 'onlycockscrypto',
    symbol: 'cox',
    name: 'OnlyCocksCrypto',
  },
  {
    id: 'only-possible-on-ethereum',
    symbol: 'opoe',
    name: 'Only Possible On Ethereum',
  },
  {
    id: 'only-possible-on-solana',
    symbol: 'opos',
    name: 'Only Possible On Solana',
  },
  {
    id: 'onmax',
    symbol: 'omp',
    name: 'Onmax',
  },
  {
    id: 'onomy-protocol',
    symbol: 'nom',
    name: 'Onomy Protocol',
  },
  {
    id: 'onooks',
    symbol: 'ooks',
    name: 'Onooks',
  },
  {
    id: 'onpulse',
    symbol: 'opls',
    name: 'OnPulse',
  },
  {
    id: 'onston',
    symbol: 'onston',
    name: 'Onston',
  },
  {
    id: 'ontology',
    symbol: 'ont',
    name: 'Ontology',
  },
  {
    id: 'onus',
    symbol: 'onus',
    name: 'ONUS',
  },
  {
    id: 'onx-finance',
    symbol: 'onx',
    name: 'OnX Finance',
  },
  {
    id: 'oobit',
    symbol: 'obt',
    name: 'Oobit',
  },
  {
    id: 'oof',
    symbol: 'oof',
    name: 'OOF',
  },
  {
    id: 'oof-2',
    symbol: 'oof',
    name: 'oof',
  },
  {
    id: 'oofp',
    symbol: 'oofp',
    name: 'OOFP',
  },
  {
    id: 'oogi',
    symbol: 'oogi',
    name: 'OOGI',
  },
  {
    id: 'oogix',
    symbol: 'oogix',
    name: 'OOGIX',
  },
  {
    id: 'ookeenga',
    symbol: 'okg',
    name: 'Ookeenga',
  },
  {
    id: 'ooki',
    symbol: 'ooki',
    name: 'Ooki',
  },
  {
    id: 'oolong',
    symbol: 'ウーロン',
    name: 'OoLong',
  },
  {
    id: 'oolongswap',
    symbol: 'olo',
    name: 'OolongSwap',
  },
  {
    id: 'oort',
    symbol: 'oort',
    name: 'OORT',
  },
  {
    id: 'oort-digital',
    symbol: 'oort',
    name: 'Oort Digital',
  },
  {
    id: 'opacity',
    symbol: 'opct',
    name: 'Opacity',
  },
  {
    id: 'opal-2',
    symbol: 'gem',
    name: 'Opal',
  },
  {
    id: 'opcz',
    symbol: 'opcz',
    name: 'OpCZ',
  },
  {
    id: 'openai-erc',
    symbol: 'openai erc',
    name: 'OpenAI ERC',
  },
  {
    id: 'openalexa-protocol',
    symbol: 'oap',
    name: 'OpenAlexa Protocol',
  },
  {
    id: 'openanx',
    symbol: 'oax',
    name: 'OAX',
  },
  {
    id: 'openbetai',
    symbol: 'openbet',
    name: 'OpenbetAI',
  },
  {
    id: 'openblox',
    symbol: 'obx',
    name: 'OpenBlox',
  },
  {
    id: 'openchat',
    symbol: 'chat',
    name: 'OpenChat',
  },
  {
    id: 'opendao',
    symbol: 'sos',
    name: 'OpenDAO',
  },
  {
    id: 'open-dollar-governance',
    symbol: 'odg',
    name: 'Open Dollar Governance',
  },
  {
    id: 'openeden-tbill',
    symbol: 'tbill',
    name: 'OpenEden TBILL',
  },
  {
    id: 'open-exchange-token',
    symbol: 'ox',
    name: 'Open Exchange Token',
  },
  {
    id: 'openfabric',
    symbol: 'ofn',
    name: 'Openfabric AI',
  },
  {
    id: 'open-governance-token',
    symbol: 'open',
    name: 'OPEN Governance',
  },
  {
    id: 'openleverage',
    symbol: 'ole',
    name: 'OpenLeverage',
  },
  {
    id: 'openlive-nft',
    symbol: 'opv',
    name: 'OMarket',
  },
  {
    id: 'openocean',
    symbol: 'ooe',
    name: 'OpenOcean',
  },
  {
    id: 'open-platform',
    symbol: 'open',
    name: 'Open Platform',
  },
  {
    id: 'openpool',
    symbol: 'opl',
    name: 'OpenPool',
  },
  {
    id: 'opensky-finance',
    symbol: 'osky',
    name: 'OpenSky Finance',
  },
  {
    id: 'open-source-network',
    symbol: 'opn',
    name: 'Open Source Network',
  },
  {
    id: 'openswap-token',
    symbol: 'openx',
    name: 'OpenSwap.One',
  },
  {
    id: 'openworldnft',
    symbol: 'owner',
    name: 'OPENWORLDNFT',
  },
  {
    id: 'openxswap',
    symbol: 'openx',
    name: 'OpenXSwap',
  },
  {
    id: 'openxswap-gov-token',
    symbol: 'xopenx',
    name: 'OpenXSwap Gov. Token',
  },
  {
    id: 'opera-protocol',
    symbol: 'opera',
    name: 'Opera Protocol',
  },
  {
    id: 'operon-origins',
    symbol: 'oro',
    name: 'Operon Origins',
  },
  {
    id: 'opes-wrapped-pe',
    symbol: 'wpe',
    name: 'OpesAI',
  },
  {
    id: 'opipets',
    symbol: 'opip',
    name: 'OpiPets',
  },
  {
    id: 'opium',
    symbol: 'opium',
    name: 'Opium',
  },
  {
    id: 'opmoon',
    symbol: 'opmoon',
    name: 'OpMoon',
  },
  {
    id: 'oppa',
    symbol: 'oppa',
    name: 'OPPA',
  },
  {
    id: 'opportunity',
    symbol: 'opy',
    name: 'OPYx',
  },
  {
    id: 'opsec',
    symbol: 'opsec',
    name: 'OpSec',
  },
  {
    id: 'optical-bitcoin',
    symbol: 'obtc',
    name: 'Optical Bitcoin',
  },
  {
    id: 'opticash',
    symbol: 'opch',
    name: 'Opticash',
  },
  {
    id: 'optim',
    symbol: 'optim',
    name: 'OPTIM',
  },
  {
    id: 'optimism',
    symbol: 'op',
    name: 'Optimism',
  },
  {
    id: 'optimus',
    symbol: 'optcm',
    name: 'Optimus',
  },
  {
    id: 'optimus-ai',
    symbol: 'opti',
    name: 'Optimus AI',
  },
  {
    id: 'optimus-al-bsc',
    symbol: 'optimus al',
    name: 'Optimus Al (BSC)',
  },
  {
    id: 'optimuselonai',
    symbol: 'optimuselo',
    name: 'OptimusElonAI',
  },
  {
    id: 'optimus-inu',
    symbol: 'opinu',
    name: 'Optimus Inu',
  },
  {
    id: 'optimus-x',
    symbol: 'opx',
    name: 'Optimus X',
  },
  {
    id: 'optionflow-finance',
    symbol: 'opt',
    name: 'OptionFlow Finance',
  },
  {
    id: 'option-panda-platform',
    symbol: 'opa',
    name: 'Option Panda Platform',
  },
  {
    id: 'option-room',
    symbol: 'room',
    name: 'OptionRoom',
  },
  {
    id: 'opulous',
    symbol: 'opul',
    name: 'Opulous',
  },
  {
    id: 'opvoid',
    symbol: 'opvoid',
    name: 'OpVoid',
  },
  {
    id: 'opx-finance',
    symbol: 'opx',
    name: 'OPX Finance',
  },
  {
    id: 'opxsliz',
    symbol: 'opxvesliz',
    name: 'opxSliz',
  },
  {
    id: 'opyn-squeeth',
    symbol: 'osqth',
    name: 'Opyn Squeeth',
  },
  {
    id: 'oracle-2',
    symbol: 'oracle',
    name: 'ORACLE',
  },
  {
    id: 'oraclechain',
    symbol: 'oct',
    name: 'OracleChain',
  },
  {
    id: 'oracle-layer2',
    symbol: 'oracle',
    name: 'Oracle Layer2',
  },
  {
    id: 'oracle-meta-technologies',
    symbol: 'omt',
    name: 'Oracle Meta Technologies',
  },
  {
    id: 'oracleswap',
    symbol: 'oracle',
    name: 'OracleSwap',
  },
  {
    id: 'oraichain-token',
    symbol: 'orai',
    name: 'Oraichain',
  },
  {
    id: 'oraidex',
    symbol: 'oraix',
    name: 'OraiDEX',
  },
  {
    id: 'orang',
    symbol: 'orang',
    name: 'Orang',
  },
  {
    id: 'orange',
    symbol: 'ornj',
    name: 'Orange',
  },
  {
    id: 'orange-bot',
    symbol: 'orbot',
    name: 'Orange BOT',
  },
  {
    id: 'orao-network',
    symbol: 'orao',
    name: 'ORAO Network',
  },
  {
    id: 'orbeon-protocol',
    symbol: 'orbn',
    name: 'Orbeon Protocol',
  },
  {
    id: 'orbit-bridge-klaytn-belt',
    symbol: 'obelt',
    name: 'Orbit Bridge Klaytn BELT',
  },
  {
    id: 'orbit-bridge-klaytn-binance-coin',
    symbol: 'obnb',
    name: 'Orbit Bridge Klaytn Binance Coin',
  },
  {
    id: 'orbit-bridge-klaytn-ethereum',
    symbol: 'oeth',
    name: 'Orbit Bridge Klaytn Ethereum',
  },
  {
    id: 'orbit-bridge-klaytn-handy',
    symbol: 'ohandy',
    name: 'Orbit Bridge Klaytn Handy',
  },
  {
    id: 'orbit-bridge-klaytn-matic',
    symbol: 'omatic',
    name: 'Orbit Bridge Klaytn MATIC',
  },
  {
    id: 'orbit-bridge-klaytn-orbit-chain',
    symbol: 'oorc',
    name: 'Orbit Bridge Klaytn Orbit Chain',
  },
  {
    id: 'orbit-bridge-klaytn-ripple',
    symbol: 'oxrp',
    name: 'Orbit Bridge Klaytn Ripple',
  },
  {
    id: 'orbit-bridge-klaytn-usdc',
    symbol: 'ousdc',
    name: 'Bridged USD Coin (Orbit Bridge)',
  },
  {
    id: 'orbit-bridge-klaytn-usd-tether',
    symbol: 'ousdt',
    name: 'Bridged Tether (Orbit Bridge)',
  },
  {
    id: 'orbit-bridge-klaytn-wrapped-btc',
    symbol: 'owbtc',
    name: 'Orbit Bridge Klaytn Wrapped BTC',
  },
  {
    id: 'orbit-chain',
    symbol: 'orc',
    name: 'Orbit Chain',
  },
  {
    id: 'orbitpad',
    symbol: 'opad',
    name: 'Orbitpad',
  },
  {
    id: 'orbitt-pro',
    symbol: 'orbt',
    name: 'Orbitt Pro',
  },
  {
    id: 'orbler',
    symbol: 'orbr',
    name: 'Orbler',
  },
  {
    id: 'orbofi-ai',
    symbol: 'obi',
    name: 'Orbofi AI',
  },
  {
    id: 'orbs',
    symbol: 'orbs',
    name: 'Orbs',
  },
  {
    id: 'orb-wizz-council',
    symbol: 'orb',
    name: 'ORB WIZZ COUNCIL',
  },
  {
    id: 'orca',
    symbol: 'orca',
    name: 'Orca',
  },
  {
    id: 'orca-avai',
    symbol: 'avai',
    name: 'Orca AVAI',
  },
  {
    id: 'orca-inu',
    symbol: 'orcainu',
    name: 'ORCA INU',
  },
  {
    id: 'orcfax',
    symbol: 'fact',
    name: 'Orcfax',
  },
  {
    id: 'orchai-protocol-staked-compound-atom',
    symbol: 'scatom',
    name: 'Orchai Protocol Staked Compound ATOM',
  },
  {
    id: 'orchid-protocol',
    symbol: 'oxt',
    name: 'Orchid Protocol',
  },
  {
    id: 'orclands-metaverse',
    symbol: 'orc',
    name: 'Orclands Metaverse',
  },
  {
    id: 'ordbridge',
    symbol: 'wbrge',
    name: 'Wrapped OrdBridge',
  },
  {
    id: 'orders-exchange',
    symbol: 'rdex',
    name: 'Orders.Exchange',
  },
  {
    id: 'ordible',
    symbol: 'orb',
    name: 'Ordible',
  },
  {
    id: 'ordibot',
    symbol: 'ordibot',
    name: 'OrdiBot',
  },
  {
    id: 'ordigen',
    symbol: 'odgn',
    name: 'OrdiGen',
  },
  {
    id: 'ordi-launch',
    symbol: 'orla',
    name: 'Ordi Launch',
  },
  {
    id: 'ordinal-bitcoin',
    symbol: 'obtc',
    name: 'ORDINAL BITCOIN',
  },
  {
    id: 'ordinal-bridge',
    symbol: 'ordibridge',
    name: 'Ordinal Bridge',
  },
  {
    id: 'ordinal-btc',
    symbol: 'obtc',
    name: 'Ordinal BTC',
  },
  {
    id: 'ordinal-doge',
    symbol: 'odoge',
    name: 'Ordinal Doge',
  },
  {
    id: 'ordinals',
    symbol: 'ordi',
    name: 'ORDI',
  },
  {
    id: 'ordinals-deflation',
    symbol: 'odef',
    name: 'Ordinals Deflation',
  },
  {
    id: 'ordinalsfi',
    symbol: 'ordifi',
    name: 'OrdinalsFi',
  },
  {
    id: 'ordinals-finance',
    symbol: 'ofi',
    name: 'Ordinals Finance',
  },
  {
    id: 'ordinals-inscription-bot',
    symbol: 'oib',
    name: 'Ordinals Inscription Bot',
  },
  {
    id: 'ordinals-world',
    symbol: 'ord',
    name: 'Ordinals World',
  },
  {
    id: 'ordinal-tools',
    symbol: 'ort',
    name: 'ORDINAL TOOLS',
  },
  {
    id: 'ordinex',
    symbol: 'ord',
    name: 'ordinex',
  },
  {
    id: 'ordiswap-token',
    symbol: 'ords',
    name: 'Ordiswap',
  },
  {
    id: 'ordizk',
    symbol: 'ozk',
    name: 'OrdiZK',
  },
  {
    id: 'ordmint',
    symbol: 'ormm',
    name: 'Ordmint',
  },
  {
    id: 'oreofi',
    symbol: 'oreo',
    name: 'OreoFi',
  },
  {
    id: 'oreoswap',
    symbol: 'oreo',
    name: 'OreoSwap',
  },
  {
    id: 'ore-token',
    symbol: 'ore',
    name: 'ORE',
  },
  {
    id: 'oreto-network',
    symbol: 'ort',
    name: 'Oreto Network',
  },
  {
    id: 'origen-defi',
    symbol: 'origen',
    name: 'Origen DEFI',
  },
  {
    id: 'origin-dollar',
    symbol: 'ousd',
    name: 'Origin Dollar',
  },
  {
    id: 'origin-dollar-governance',
    symbol: 'ogv',
    name: 'Origin DeFi Governance',
  },
  {
    id: 'origin-ether',
    symbol: 'oeth',
    name: 'Origin Ether',
  },
  {
    id: 'origin-protocol',
    symbol: 'ogn',
    name: 'Origin Protocol',
  },
  {
    id: 'origintrail',
    symbol: 'trac',
    name: 'OriginTrail',
  },
  {
    id: 'origintrail-parachain',
    symbol: 'otp',
    name: 'OriginTrail Parachain',
  },
  {
    id: 'origyn-foundation',
    symbol: 'ogy',
    name: 'ORIGYN Foundation',
  },
  {
    id: 'orion-money',
    symbol: 'orion',
    name: 'Orion Money',
  },
  {
    id: 'orion-protocol',
    symbol: 'orn',
    name: 'Orion',
  },
  {
    id: 'orkan',
    symbol: 'ork',
    name: 'Orkan',
  },
  {
    id: 'ormeus-cash',
    symbol: 'omc',
    name: 'Ormeus Cash',
  },
  {
    id: 'ormeuscoin',
    symbol: 'orme',
    name: 'Ormeus Coin',
  },
  {
    id: 'ormeus-ecosystem',
    symbol: 'eco',
    name: 'Ormeus Ecosystem',
  },
  {
    id: 'oro',
    symbol: 'oro',
    name: 'ORO',
  },
  {
    id: 'orpo',
    symbol: 'orpo',
    name: 'ORPO',
  },
  {
    id: 'orym',
    symbol: 'orym',
    name: 'Orym',
  },
  {
    id: 'osaka-protocol',
    symbol: 'osak',
    name: 'Osaka Protocol',
  },
  {
    id: 'oscarswap',
    symbol: 'oscar',
    name: 'Oscarswap',
  },
  {
    id: 'osean',
    symbol: 'osean',
    name: 'Osean',
  },
  {
    id: 'oshi',
    symbol: 'oshi',
    name: 'OSHI',
  },
  {
    id: 'osis',
    symbol: 'osis',
    name: 'OSIS',
  },
  {
    id: 'osk',
    symbol: 'osk',
    name: 'OSK',
  },
  {
    id: 'osmo-bot',
    symbol: 'osmo',
    name: 'Osmo Bot',
  },
  {
    id: 'osmosis',
    symbol: 'osmo',
    name: 'Osmosis',
  },
  {
    id: 'ospy',
    symbol: 'ospy',
    name: 'OSPY',
  },
  {
    id: 'osschain',
    symbol: 'oss',
    name: 'OSSChain',
  },
  {
    id: 'otacon-ai',
    symbol: 'otacon',
    name: 'Otacon AI',
  },
  {
    id: 'otflow',
    symbol: 'otf',
    name: 'OTFLOW',
  },
  {
    id: 'otocash',
    symbol: 'oto',
    name: 'OTOCASH',
  },
  {
    id: 'otsea',
    symbol: 'otsea',
    name: 'OTSea',
  },
  {
    id: 'otterhome',
    symbol: 'home',
    name: 'OtterHome',
  },
  {
    id: 'otx-exchange',
    symbol: 'otx',
    name: 'OTX EXCHANGE',
  },
  {
    id: 'ouro-governance-share',
    symbol: 'ogs',
    name: 'Ouro Governance Share',
  },
  {
    id: 'ousg',
    symbol: 'ousg',
    name: 'OUSG',
  },
  {
    id: 'outdefine',
    symbol: 'outdefine',
    name: 'Outdefine',
  },
  {
    id: 'outer-ring',
    symbol: 'gq',
    name: 'Blink Galaxy',
  },
  {
    id: 'outter-finance',
    symbol: 'out',
    name: 'Outter Finance [OLD]',
  },
  {
    id: 'outter-finance-2',
    symbol: 'out',
    name: 'Outter Finance',
  },
  {
    id: 'oval3',
    symbol: 'ovl3',
    name: 'OVAL3',
  },
  {
    id: 'overnight-dai',
    symbol: 'dai+',
    name: 'Overnight.fi DAI+',
  },
  {
    id: 'overnight-finance',
    symbol: 'ovn',
    name: 'Overnight Finance',
  },
  {
    id: 'ovols-floor-index',
    symbol: '$ovol',
    name: 'Ovols Floor Index',
  },
  {
    id: 'ovo-nft-platform',
    symbol: 'ovo',
    name: 'OVO',
  },
  {
    id: 'ovr',
    symbol: 'ovr',
    name: 'Ovr',
  },
  {
    id: 'owloper',
    symbol: 'owl',
    name: 'Owloper Owl',
  },
  {
    id: 'own-token',
    symbol: 'own',
    name: 'OWN Token',
  },
  {
    id: 'oxai-com',
    symbol: 'oxai',
    name: 'OxAI.com',
  },
  {
    id: 'oxbitcoin',
    symbol: '0xbtc',
    name: '0xBitcoin',
  },
  {
    id: 'oxbt',
    symbol: 'oxbt',
    name: 'OXBT',
  },
  {
    id: 'oxbull-solana',
    symbol: 'oxs',
    name: 'Oxbull Solana',
  },
  {
    id: 'oxbull-tech-2',
    symbol: 'oxb',
    name: 'Oxbull Tech',
  },
  {
    id: 'oxygen',
    symbol: 'oxy',
    name: 'Oxygen',
  },
  {
    id: 'oxymetatoken',
    symbol: 'omt',
    name: 'OxyMetaToken',
  },
  {
    id: 'oxyo2',
    symbol: 'krpza',
    name: 'OxyO2',
  },
  {
    id: 'ozonechain',
    symbol: 'ozone',
    name: 'Ozonechain',
  },
  {
    id: 'ozone-chain',
    symbol: 'ozo',
    name: 'Ozone Chain',
  },
  {
    id: 'ozone-metaverse',
    symbol: '$ozone',
    name: 'Ozone Metaverse',
  },
  {
    id: 'p2p-solutions-foundation',
    symbol: 'p2ps',
    name: 'P2P solutions foundation',
  },
  {
    id: 'p3pe-hacker',
    symbol: 'p3pe',
    name: 'P3PE HACKER',
  },
  {
    id: 'paal-ai',
    symbol: 'paal',
    name: 'PAAL AI',
  },
  {
    id: 'pablo-defi',
    symbol: 'pablo',
    name: 'Pablo DeFi',
  },
  {
    id: 'paccoin',
    symbol: 'pac',
    name: 'PAC Protocol',
  },
  {
    id: 'pacific',
    symbol: 'paf',
    name: 'Pacific',
  },
  {
    id: 'pack',
    symbol: 'pack',
    name: 'Pack',
  },
  {
    id: 'packageportal',
    symbol: 'port',
    name: 'PackagePortal',
  },
  {
    id: 'packetchain',
    symbol: 'ptcl',
    name: 'Packetchain',
  },
  {
    id: 'pacman',
    symbol: 'pac',
    name: 'Pacman',
  },
  {
    id: 'pacman-native-token',
    symbol: 'pac',
    name: 'Pacman Native Token',
  },
  {
    id: 'pacoca',
    symbol: 'pacoca',
    name: 'Pacoca',
  },
  {
    id: 'padawan',
    symbol: 'padawan',
    name: 'PADAWAN',
  },
  {
    id: 'page',
    symbol: 'page',
    name: 'Page',
  },
  {
    id: 'paideia',
    symbol: 'pai',
    name: 'Paideia',
  },
  {
    id: 'paid-network',
    symbol: 'paid',
    name: 'PAID Network',
  },
  {
    id: 'paint',
    symbol: 'paint',
    name: 'MurAll',
  },
  {
    id: 'paint-swap',
    symbol: 'brush',
    name: 'Paint Swap',
  },
  {
    id: 'paisapad',
    symbol: 'ppd',
    name: 'PaisaPad',
  },
  {
    id: 'paje-etdev-company',
    symbol: 'bolly',
    name: 'BOLLY.DEV',
  },
  {
    id: 'pakcoin',
    symbol: 'pak',
    name: 'Pakcoin',
  },
  {
    id: 'paladeum',
    symbol: 'plb',
    name: 'Paladeum',
  },
  {
    id: 'paladin',
    symbol: 'pal',
    name: 'Paladin',
  },
  {
    id: 'paladinai',
    symbol: 'palai',
    name: 'PaladinAI',
  },
  {
    id: 'palantir-tokenized-stock-defichain',
    symbol: 'dpltr',
    name: 'Palantir Tokenized Stock Defichain',
  },
  {
    id: 'palette',
    symbol: 'plt',
    name: 'Palette',
  },
  {
    id: 'palgold',
    symbol: 'palg',
    name: 'PalGold',
  },
  {
    id: 'palm-ai',
    symbol: 'palm',
    name: 'PaLM AI',
  },
  {
    id: 'palmeiras-fan-token',
    symbol: 'verdao',
    name: 'Palmeiras Fan Token',
  },
  {
    id: 'palmpay',
    symbol: 'palm',
    name: 'PalmPay',
  },
  {
    id: 'palmswap',
    symbol: 'palm',
    name: 'PalmSwap',
  },
  {
    id: 'pancake-bunny',
    symbol: 'bunny',
    name: 'Pancake Bunny',
  },
  {
    id: 'pancake-games',
    symbol: 'gcake',
    name: 'Pancake Games',
  },
  {
    id: 'pancake-hunny',
    symbol: 'hunny',
    name: 'Hunny Finance',
  },
  {
    id: 'pancakeswap-token',
    symbol: 'cake',
    name: 'PancakeSwap',
  },
  {
    id: 'panda',
    symbol: 'ptkn',
    name: 'Panda',
  },
  {
    id: 'pandacoin',
    symbol: 'pnd',
    name: 'Pandacoin',
  },
  {
    id: 'panda-coin',
    symbol: 'panda',
    name: 'Panda Coin',
  },
  {
    id: 'pandadao',
    symbol: 'panda',
    name: 'PandaDAO',
  },
  {
    id: 'pandagrown',
    symbol: 'pga',
    name: 'PandaGrown',
  },
  {
    id: 'pandai',
    symbol: 'pandai',
    name: 'PandAI',
  },
  {
    id: 'pandao',
    symbol: '$panda',
    name: 'PanDAO',
  },
  {
    id: 'pando',
    symbol: 'pando',
    name: 'Pando',
  },
  {
    id: 'pandora-cash',
    symbol: 'pcash',
    name: 'Pandora Cash',
  },
  {
    id: 'pandora-protocol',
    symbol: 'pndr',
    name: 'Pandora Finance',
  },
  {
    id: 'pando-token',
    symbol: 'ptx',
    name: 'PandoProject',
  },
  {
    id: 'pangea-governance-token',
    symbol: 'stone',
    name: 'PANGEA GOVERNANCE TOKEN',
  },
  {
    id: 'pangolin',
    symbol: 'png',
    name: 'Pangolin',
  },
  {
    id: 'pangolin-flare',
    symbol: 'pfl',
    name: 'Pangolin Flare',
  },
  {
    id: 'pangolin-hedera',
    symbol: 'pbar',
    name: 'Pangolin Hedera',
  },
  {
    id: 'pangolin-songbird',
    symbol: 'psb',
    name: 'Pangolin Songbird',
  },
  {
    id: 'panicswap',
    symbol: 'panic',
    name: 'PanicSwap',
  },
  {
    id: 'panjea',
    symbol: 'panj',
    name: 'Panjea',
  },
  {
    id: 'pankuku',
    symbol: 'kuku',
    name: 'panKUKU',
  },
  {
    id: 'panorama-swap-token',
    symbol: 'panx',
    name: 'Panorama Swap Token',
  },
  {
    id: 'panoverse',
    symbol: 'pano',
    name: 'PanoVerse',
  },
  {
    id: 'panther',
    symbol: 'zkp',
    name: 'Panther Protocol',
  },
  {
    id: 'pantos',
    symbol: 'pan',
    name: 'Pantos',
  },
  {
    id: 'papa',
    symbol: 'papa',
    name: 'Papa',
  },
  {
    id: 'papa2049',
    symbol: 'papa2049',
    name: 'papa2049',
  },
  {
    id: 'papa-bear',
    symbol: 'papa',
    name: 'PAPA BEAR (Old)',
  },
  {
    id: 'papa-bear-2',
    symbol: 'papa',
    name: 'PAPA BEAR',
  },
  {
    id: 'papa-doge',
    symbol: 'papadoge',
    name: 'Papa Doge',
  },
  {
    id: 'paper-fantom',
    symbol: 'paper',
    name: 'Paper',
  },
  {
    id: 'papi',
    symbol: 'papi',
    name: 'PAPI',
  },
  {
    id: 'papyrus-swap',
    symbol: 'papyrus',
    name: 'Papyrus Swap',
  },
  {
    id: 'parachute',
    symbol: 'par',
    name: 'Parachute',
  },
  {
    id: 'paradise-defi',
    symbol: 'pdf',
    name: 'Paradise Defi',
  },
  {
    id: 'paradisefi',
    symbol: 'eden',
    name: 'ParadiseFi',
  },
  {
    id: 'paradox-metaverse',
    symbol: 'paradox',
    name: 'Paradox Metaverse',
  },
  {
    id: 'paragen',
    symbol: 'rgen',
    name: 'Paragen',
  },
  {
    id: 'paragonsdao',
    symbol: 'pdt',
    name: 'ParagonsDAO',
  },
  {
    id: 'paralink-network',
    symbol: 'para',
    name: 'Paralink Network',
  },
  {
    id: 'parallax',
    symbol: 'plx',
    name: 'Parallax',
  },
  {
    id: 'parallelchain',
    symbol: 'xpll',
    name: 'ParallelChain',
  },
  {
    id: 'parallel-finance',
    symbol: 'para',
    name: 'Parallel Finance',
  },
  {
    id: 'pararium',
    symbol: 'paz',
    name: 'Pararium',
  },
  {
    id: 'paras',
    symbol: 'paras',
    name: 'Paras',
  },
  {
    id: 'parasol-finance',
    symbol: 'psol',
    name: 'Parasol Finance',
  },
  {
    id: 'paraswap',
    symbol: 'psp',
    name: 'ParaSwap',
  },
  {
    id: 'paratoken-2',
    symbol: 'para',
    name: 'Para',
  },
  {
    id: 'paraverse',
    symbol: 'para',
    name: 'Paraverse',
  },
  {
    id: 'parex',
    symbol: 'prx',
    name: 'Parex',
  },
  {
    id: 'paribu-net',
    symbol: 'prb',
    name: 'Paribu Net',
  },
  {
    id: 'paribus',
    symbol: 'pbx',
    name: 'Paribus',
  },
  {
    id: 'parifi',
    symbol: 'prf',
    name: 'Parifi',
  },
  {
    id: 'paris-saint-germain-fan-token',
    symbol: 'psg',
    name: 'Paris Saint-Germain Fan Token',
  },
  {
    id: 'parma-calcio-1913-fan-token',
    symbol: 'parma',
    name: 'Parma Calcio 1913 Fan Token',
  },
  {
    id: 'parrotly',
    symbol: 'pbirb',
    name: 'Parrotly',
  },
  {
    id: 'parrot-protocol',
    symbol: 'prt',
    name: 'Parrot Protocol',
  },
  {
    id: 'parrot-usd',
    symbol: 'pai',
    name: 'Parrot USD',
  },
  {
    id: 'parsiq',
    symbol: 'prq',
    name: 'PARSIQ',
  },
  {
    id: 'par-stablecoin',
    symbol: 'par',
    name: 'Parallel',
  },
  {
    id: 'particl',
    symbol: 'part',
    name: 'Particl',
  },
  {
    id: 'particle-2',
    symbol: 'prtcle',
    name: 'Particle',
  },
  {
    id: 'particles-money',
    symbol: 'particle',
    name: 'Particles Money',
  },
  {
    id: 'particles-money-xeth',
    symbol: 'xeth',
    name: 'Particles Money xETH',
  },
  {
    id: 'party',
    symbol: 'party',
    name: 'PARTY',
  },
  {
    id: 'party-2',
    symbol: 'party',
    name: 'Party',
  },
  {
    id: 'partyhat-meme',
    symbol: 'phat',
    name: 'partyhat (Meme)',
  },
  {
    id: 'pascalcoin',
    symbol: 'pasc',
    name: 'Pascal',
  },
  {
    id: 'passage',
    symbol: 'pasg',
    name: 'Passage',
  },
  {
    id: 'passivesphere',
    symbol: 'ppx',
    name: 'PassiveSphere',
  },
  {
    id: 'pastafarian',
    symbol: 'pasta',
    name: 'Pastafarian',
  },
  {
    id: 'pastel',
    symbol: 'psl',
    name: 'Pastel',
  },
  {
    id: 'patientory',
    symbol: 'ptoy',
    name: 'Patientory',
  },
  {
    id: 'patriot-pay',
    symbol: 'ppy',
    name: 'Patriot Pay',
  },
  {
    id: 'paul-token',
    symbol: 'paul',
    name: 'PAUL',
  },
  {
    id: 'pavia',
    symbol: 'pavia',
    name: 'Pavia',
  },
  {
    id: 'paw-2',
    symbol: 'paw',
    name: 'PAW',
  },
  {
    id: 'paw-a-gotchi',
    symbol: 'pag',
    name: 'Paw-a-Gotchi',
  },
  {
    id: 'pawstars',
    symbol: 'paws',
    name: 'PawStars',
  },
  {
    id: 'pawswap',
    symbol: 'paw',
    name: 'PAWSWAP',
  },
  {
    id: 'pawthereum',
    symbol: 'pawth',
    name: 'Pawthereum',
  },
  {
    id: 'pawtocol',
    symbol: 'upi',
    name: 'Pawtocol',
  },
  {
    id: 'paw-v2',
    symbol: 'paw',
    name: 'Paw V2',
  },
  {
    id: 'pawzone',
    symbol: 'paw',
    name: 'PAWZONE',
  },
  {
    id: 'paxb',
    symbol: 'paxb',
    name: 'PAXB',
  },
  {
    id: 'pax-gold',
    symbol: 'paxg',
    name: 'PAX Gold',
  },
  {
    id: 'paxos-standard',
    symbol: 'usdp',
    name: 'Pax Dollar',
  },
  {
    id: 'pax-unitas',
    symbol: 'paxu',
    name: 'Pax Unitas',
  },
  {
    id: 'payaccept',
    symbol: 'payt',
    name: 'PayAccept',
  },
  {
    id: 'payb',
    symbol: 'payb',
    name: 'PayB',
  },
  {
    id: 'paybandcoin',
    symbol: 'pybc',
    name: 'PaybandCoin',
  },
  {
    id: 'paybit',
    symbol: 'paybit',
    name: 'PayBit',
  },
  {
    id: 'paybolt',
    symbol: 'pay',
    name: 'PayBolt',
  },
  {
    id: 'pay-coin',
    symbol: 'pci',
    name: 'Paycoin',
  },
  {
    id: 'payday',
    symbol: 'payday',
    name: 'Payday',
  },
  {
    id: 'pay-it-now',
    symbol: 'pin',
    name: 'Pay It Now',
  },
  {
    id: 'payments',
    symbol: 'xpay',
    name: '𝕏 Payments',
  },
  {
    id: 'payment-swap-utility-board',
    symbol: 'psub',
    name: 'Payment Swap Utility Board',
  },
  {
    id: 'paynet-coin',
    symbol: 'payn',
    name: 'PAYNET',
  },
  {
    id: 'paypal-usd',
    symbol: 'pyusd',
    name: 'PayPal USD',
  },
  {
    id: 'paypolitan-token',
    symbol: 'epan',
    name: 'Paypolitan',
  },
  {
    id: 'payrue',
    symbol: 'propel',
    name: 'PayRue',
  },
  {
    id: 'paysenger-ego',
    symbol: 'ego',
    name: 'Paysenger EGO',
  },
  {
    id: 'payslink-token',
    symbol: 'pays',
    name: 'Payslink Token',
  },
  {
    id: 'payvertise',
    symbol: 'pvt',
    name: 'Payvertise',
  },
  {
    id: 'paywong',
    symbol: 'pwg',
    name: 'Paywong',
  },
  {
    id: 'payx',
    symbol: 'payx',
    name: 'PayX',
  },
  {
    id: 'pbm',
    symbol: 'pbmc',
    name: 'PBM Coin',
  },
  {
    id: 'pbtc35a',
    symbol: 'pbtc35a',
    name: 'pBTC35A',
  },
  {
    id: 'pchain',
    symbol: 'pi',
    name: 'Plian',
  },
  {
    id: 'pdbc-defichain',
    symbol: 'dpdbc',
    name: 'PDBC Defichain',
  },
  {
    id: 'pdx-coin',
    symbol: 'pdx',
    name: 'PDX Coin',
  },
  {
    id: 'peace-coin',
    symbol: 'pce',
    name: 'PEACE COIN',
  },
  {
    id: 'peach-2',
    symbol: 'pch',
    name: 'Peach',
  },
  {
    id: 'peachfolio',
    symbol: 'pchf',
    name: 'Peachfolio',
  },
  {
    id: 'peach-inu-bsc',
    symbol: 'peach',
    name: 'Peach Inu (BSC)',
  },
  {
    id: 'peanut',
    symbol: 'nux',
    name: 'Peanut',
  },
  {
    id: 'peapods-finance',
    symbol: 'peas',
    name: 'Peapods Finance',
  },
  {
    id: 'peardao',
    symbol: 'pex',
    name: 'PearDAO',
  },
  {
    id: 'pearl',
    symbol: 'pearl',
    name: 'Pearl',
  },
  {
    id: 'pearl-finance',
    symbol: 'pearl',
    name: 'Pearl Finance',
  },
  {
    id: 'pear-swap',
    symbol: 'pear',
    name: 'Pear Swap',
  },
  {
    id: 'peepo',
    symbol: 'peepo',
    name: 'Peepo',
  },
  {
    id: 'peepo-sol',
    symbol: '$peep',
    name: 'Peepo (SOL)',
  },
  {
    id: 'peercoin',
    symbol: 'ppc',
    name: 'Peercoin',
  },
  {
    id: 'peer-to-peer',
    symbol: 'p2p',
    name: 'Peer-to-Peer',
  },
  {
    id: 'pegasusbot',
    symbol: 'pgs',
    name: 'PegasusBot',
  },
  {
    id: 'pegasus-dex',
    symbol: 'peg',
    name: 'Pegasus DEX',
  },
  {
    id: 'pegasys',
    symbol: 'psys',
    name: 'Pegasys (Syscoin NEVM)',
  },
  {
    id: 'pegasys-rollux',
    symbol: 'psys',
    name: 'Pegasys (Rollux)',
  },
  {
    id: 'pegaxy-stone',
    symbol: 'pgx',
    name: 'Pegaxy',
  },
  {
    id: 'pegazus-finance',
    symbol: 'peg',
    name: 'Pegazus Finance',
  },
  {
    id: 'peg-eusd',
    symbol: 'peusd',
    name: 'peg-eUSD',
  },
  {
    id: 'pego-network-2',
    symbol: 'pg',
    name: 'PEGO Network',
  },
  {
    id: 'peipei',
    symbol: 'peipei',
    name: 'PEIPEI',
  },
  {
    id: 'pelfort',
    symbol: 'pelf',
    name: 'PELFORT',
  },
  {
    id: 'pembrock',
    symbol: 'pem',
    name: 'Pembrock',
  },
  {
    id: 'pendle',
    symbol: 'pendle',
    name: 'Pendle',
  },
  {
    id: 'pendulum-chain',
    symbol: 'pen',
    name: 'Pendulum',
  },
  {
    id: 'penguin-finance',
    symbol: 'pefi',
    name: 'Penguin Finance',
  },
  {
    id: 'penguin-karts',
    symbol: 'pgk',
    name: 'Penguin Karts',
  },
  {
    id: 'penguinwak',
    symbol: 'wak',
    name: 'PenguinWak',
  },
  {
    id: 'pengy',
    symbol: 'pengyx',
    name: 'PengyX',
  },
  {
    id: 'penpie',
    symbol: 'pnp',
    name: 'Penpie',
  },
  {
    id: 'penrose-finance',
    symbol: 'pen',
    name: 'Penrose Finance',
  },
  {
    id: 'peony-coin',
    symbol: 'pny',
    name: 'Peony Coin',
  },
  {
    id: 'pepa-erc',
    symbol: 'pepa',
    name: 'Pepa ERC',
  },
  {
    id: 'pepa-inu',
    symbol: 'pepa',
    name: 'Pepa Inu',
  },
  {
    id: 'pepe',
    symbol: 'pepe',
    name: 'Pepe',
  },
  {
    id: 'pepe-2-0',
    symbol: 'pepe2.0',
    name: 'Pepe 2.0',
  },
  {
    id: 'pepeai',
    symbol: 'pepeai',
    name: 'PepeAI',
  },
  {
    id: 'pepe-ai',
    symbol: 'pepeai',
    name: 'Pepe AI',
  },
  {
    id: 'pepe-ai-token',
    symbol: 'pepeai',
    name: 'Pepe AI Token',
  },
  {
    id: 'pepe-analytics',
    symbol: 'pepeai',
    name: 'Pepe Analytics',
  },
  {
    id: 'pepebeast',
    symbol: 'pepebeast',
    name: 'PEPEBEAST',
  },
  {
    id: 'pepeblue',
    symbol: 'pepeblue',
    name: 'Pepeblue',
  },
  {
    id: 'pepebrc',
    symbol: 'pepe',
    name: 'PEPE (Ordinals)',
  },
  {
    id: 'pepe-ceo',
    symbol: 'peo',
    name: 'Pepe CEO',
  },
  {
    id: 'pepe-ceo-bsc',
    symbol: 'pepe ceo',
    name: 'Pepe CEO BSC',
  },
  {
    id: 'pepe-ceo-inu',
    symbol: 'pepe',
    name: 'PEPE CEO INU',
  },
  {
    id: 'pepechain',
    symbol: 'pc',
    name: 'Pepechain',
  },
  {
    id: 'pepe-chain',
    symbol: 'pepechain',
    name: 'PEPE Chain',
  },
  {
    id: 'pepe-chain-2',
    symbol: 'pc',
    name: 'Pepe Chain',
  },
  {
    id: 'pepecoin-2',
    symbol: 'pepecoin',
    name: 'PepeCoin',
  },
  {
    id: 'pepecoin-on-sol',
    symbol: 'pepe',
    name: 'PEPECOIN on SOL',
  },
  {
    id: 'pepe-dao',
    symbol: 'peped',
    name: 'PEPE DAO',
  },
  {
    id: 'pepe-dash-ai',
    symbol: 'pepedashai',
    name: 'Pepe Dash AI',
  },
  {
    id: 'pepedex',
    symbol: 'ppdex',
    name: 'Pepedex',
  },
  {
    id: 'pepe-doge',
    symbol: 'pepedoge',
    name: 'Pepe Doge',
  },
  {
    id: 'pepe-doginals',
    symbol: 'pepe',
    name: 'PEPE (DRC-20)',
  },
  {
    id: 'pepe-floki',
    symbol: 'pepef',
    name: 'PEPE FLOKI',
  },
  {
    id: 'pepefork',
    symbol: 'pork',
    name: 'PepeFork',
  },
  {
    id: 'pepe-girl',
    symbol: 'pepeg',
    name: 'Pepe Girl',
  },
  {
    id: 'pepegoat',
    symbol: 'pepegoat',
    name: 'PepeGOAT',
  },
  {
    id: 'pepegpt',
    symbol: 'pepegpt',
    name: 'pepeGPT',
  },
  {
    id: 'pepeinu',
    symbol: 'pepeinu',
    name: 'Pepeinu',
  },
  {
    id: 'pepe-inu',
    symbol: 'pepinu',
    name: 'Pepe Inu',
  },
  {
    id: 'pepe-inverted',
    symbol: 'ǝԁǝԁ',
    name: 'Pepe Inverted',
  },
  {
    id: 'pepe-king-prawn',
    symbol: 'pepe',
    name: 'Pepe King Prawn',
  },
  {
    id: 'pepe-le-pew-coin',
    symbol: '$plpc',
    name: 'Pepe Le Pew Coin',
  },
  {
    id: 'pepelon',
    symbol: 'pepelon',
    name: 'Pepelon',
  },
  {
    id: 'pepe-longstocking',
    symbol: 'ppls',
    name: 'Pepe Longstocking',
  },
  {
    id: 'pepelon-token',
    symbol: 'pelo',
    name: 'PepElon',
  },
  {
    id: 'pepe-mining-company',
    symbol: 'ppmc',
    name: 'Pepe Mining Company',
  },
  {
    id: 'pepemon-pepeballs',
    symbol: 'ppblz',
    name: 'Pepemon Pepeballs',
  },
  {
    id: 'pepe-of-wallstreet',
    symbol: 'pow',
    name: 'Pepe Of Wallstreet',
  },
  {
    id: 'pepe-of-yellow',
    symbol: 'pypy',
    name: 'Pepe of Yellow',
  },
  {
    id: 'pepe-og',
    symbol: 'pog',
    name: 'Pepe OG',
  },
  {
    id: 'pepe-on-solana',
    symbol: 'pepe',
    name: 'Pepe on Solana',
  },
  {
    id: 'pepe-original-version',
    symbol: 'pov',
    name: 'Pepe Original Version',
  },
  {
    id: 'pepepad',
    symbol: 'pepe',
    name: 'PepePAD',
  },
  {
    id: 'pe-pe-pokemoon',
    symbol: 'pemon',
    name: 'PE PE POKEMOON',
  },
  {
    id: 'pepepow',
    symbol: 'pepew',
    name: 'PEPEPOW',
  },
  {
    id: 'pepe-predator',
    symbol: 'snake',
    name: 'Pepe Predator',
  },
  {
    id: 'pepe-prophet',
    symbol: 'kek',
    name: 'Pepe Prophet',
  },
  {
    id: 'peper',
    symbol: 'peper',
    name: 'PEPER',
  },
  {
    id: 'pepera',
    symbol: 'pepera',
    name: 'Pepera',
  },
  {
    id: 'pepe-shibarium',
    symbol: 'pepe',
    name: 'PEPE Shibarium',
  },
  {
    id: 'pepesol',
    symbol: 'pepe',
    name: 'PepeSol',
  },
  {
    id: 'pepe-the-frog',
    symbol: 'pepebnb',
    name: 'Pepe the Frog',
  },
  {
    id: 'pepe-token',
    symbol: 'pepe',
    name: 'Pepe Token',
  },
  {
    id: 'pepeusdt',
    symbol: 'ppusdt',
    name: 'PepeUSDT',
  },
  {
    id: 'pepe-uwu',
    symbol: 'cute',
    name: 'PEPE UWU',
  },
  {
    id: 'pepewifhat',
    symbol: 'pif',
    name: 'pepewifhat',
  },
  {
    id: 'pepex',
    symbol: 'pepex',
    name: 'PEPEX',
  },
  {
    id: 'pepexl',
    symbol: 'pepexl',
    name: 'PepeXL',
  },
  {
    id: 'pepito',
    symbol: 'pepi',
    name: 'Pepito',
  },
  {
    id: 'peppa',
    symbol: 'peppa',
    name: 'PEPPA',
  },
  {
    id: 'pepurai',
    symbol: 'pepurai',
    name: 'PEPURAI',
  },
  {
    id: 'pera-finance',
    symbol: 'pera',
    name: 'Pera Finance',
  },
  {
    id: 'peri-finance',
    symbol: 'peri',
    name: 'PERI Finance',
  },
  {
    id: 'perion',
    symbol: 'perc',
    name: 'Perion',
  },
  {
    id: 'perlin',
    symbol: 'perl',
    name: 'PERL.eco',
  },
  {
    id: 'permission-coin',
    symbol: 'ask',
    name: 'Permission Coin',
  },
  {
    id: 'perpboost',
    symbol: 'boost',
    name: 'Perpboost',
  },
  {
    id: 'perpbot',
    symbol: 'pb',
    name: 'Perpbot',
  },
  {
    id: 'perpetual-protocol',
    symbol: 'perp',
    name: 'Perpetual Protocol',
  },
  {
    id: 'perpetual-wallet',
    symbol: 'pwt',
    name: 'Perpetual Wallet',
  },
  {
    id: 'perpetuum-coin',
    symbol: 'prp',
    name: 'Perpetuum Coin',
  },
  {
    id: 'per-project',
    symbol: 'per',
    name: 'PER Project',
  },
  {
    id: 'perpy-finance',
    symbol: 'pry',
    name: 'Perpy Finance',
  },
  {
    id: 'perry-the-bnb',
    symbol: 'perry',
    name: 'Perry The BNB',
  },
  {
    id: 'perseid-finance',
    symbol: 'ped',
    name: 'Perseid Finance',
  },
  {
    id: 'persib-fan-token',
    symbol: 'persib',
    name: 'Persib Fan Token',
  },
  {
    id: 'persistence',
    symbol: 'xprt',
    name: 'Persistence',
  },
  {
    id: 'peruvian-national-football-team-fan-token',
    symbol: 'fpft',
    name: 'Peruvian National Football Team Fan Token',
  },
  {
    id: 'pesabase',
    symbol: 'pesa',
    name: 'Pesabase',
  },
  {
    id: 'peseta-digital',
    symbol: 'ptd',
    name: 'Peseta Digital',
  },
  {
    id: 'petals',
    symbol: 'pts',
    name: 'Petals',
  },
  {
    id: 'petcoin-2',
    symbol: 'pet',
    name: 'Petcoin',
  },
  {
    id: 'pete',
    symbol: 'pete',
    name: 'PETE',
  },
  {
    id: 'peth',
    symbol: 'peth',
    name: 'pETH',
  },
  {
    id: 'petroleum-oil',
    symbol: 'oil',
    name: 'Petroleum OIL',
  },
  {
    id: 'petshop-io',
    symbol: 'ptshp',
    name: 'Petshop.io',
  },
  {
    id: 'pftm',
    symbol: 'pftm',
    name: 'pFTM',
  },
  {
    id: 'pgala',
    symbol: 'pgala',
    name: 'pGALA',
  },
  {
    id: 'pha',
    symbol: 'pha',
    name: 'Phala',
  },
  {
    id: 'phaeton',
    symbol: 'phae',
    name: 'Phaeton',
  },
  {
    id: 'phala-moonbeam',
    symbol: '$xcpha',
    name: 'Phala (Moonbeam)',
  },
  {
    id: 'phame',
    symbol: 'phame',
    name: 'PHAME',
  },
  {
    id: 'phantasma',
    symbol: 'soul',
    name: 'Phantasma',
  },
  {
    id: 'phantom-of-the-kill-alternative-imitation-oshi',
    symbol: 'oshi',
    name: 'Phantom of the Kill -Alternative Imitation- OSHI',
  },
  {
    id: 'phantom-protocol',
    symbol: 'phm',
    name: 'Phantom Protocol',
  },
  {
    id: 'pharaoh',
    symbol: 'phar',
    name: 'Pharaoh',
  },
  {
    id: 'pharos',
    symbol: 'pharos',
    name: 'Pharos',
  },
  {
    id: 'phase-dollar',
    symbol: 'cash',
    name: 'Phase Dollar',
  },
  {
    id: 'phemex',
    symbol: 'pt',
    name: 'Phemex Token',
  },
  {
    id: 'phenix-finance-2',
    symbol: 'phnx',
    name: 'Phenix Finance (Cronos)',
  },
  {
    id: 'phiat-protocol',
    symbol: 'phiat',
    name: 'Phiat Protocol',
  },
  {
    id: 'philosoraptor',
    symbol: 'rap',
    name: 'Philosoraptor',
  },
  {
    id: 'phobos-token',
    symbol: 'pbos',
    name: 'Phobos Token',
  },
  {
    id: 'phoenix',
    symbol: 'phx',
    name: 'Phoenix Blockchain',
  },
  {
    id: 'phoenix-chain',
    symbol: 'phx',
    name: 'Phoenix Chain',
  },
  {
    id: 'phoenixcoin',
    symbol: 'pxc',
    name: 'Phoenixcoin',
  },
  {
    id: 'phoenixdao',
    symbol: 'phnx',
    name: 'PhoenixDAO',
  },
  {
    id: 'phoenix-dragon',
    symbol: 'pdragon',
    name: 'Phoenix Dragon',
  },
  {
    id: 'phoenix-global',
    symbol: 'phb',
    name: 'Phoenix',
  },
  {
    id: 'phoenix-token',
    symbol: 'phx',
    name: 'Phoenix Finance',
  },
  {
    id: 'phoneum',
    symbol: 'pht',
    name: 'Phoneum',
  },
  {
    id: 'phonon-dao',
    symbol: 'phonon',
    name: 'Phonon DAO',
  },
  {
    id: 'phore',
    symbol: 'phr',
    name: 'Phore',
  },
  {
    id: 'photochromic',
    symbol: 'phcr',
    name: 'PhotoChromic',
  },
  {
    id: 'photon-milky-way',
    symbol: 'pmw',
    name: 'Photon Milky Way',
  },
  {
    id: 'photonswap',
    symbol: 'photon',
    name: 'PhotonSwap',
  },
  {
    id: 'phpcoin',
    symbol: 'php',
    name: 'PHPCoin',
  },
  {
    id: 'phteven',
    symbol: 'phteve',
    name: 'Phteven',
  },
  {
    id: 'phunk-vault-nftx',
    symbol: 'phunk',
    name: 'PHUNK Vault (NFTX)',
  },
  {
    id: 'phuntoken',
    symbol: 'phtk',
    name: 'Phun Token',
  },
  {
    id: 'phuture',
    symbol: 'phtr',
    name: 'Phuture',
  },
  {
    id: 'phux-governance-token',
    symbol: 'phux',
    name: 'PHUX Governance Token',
  },
  {
    id: 'physics',
    symbol: 'physics',
    name: 'Physics',
  },
  {
    id: 'physis',
    symbol: 'phy',
    name: 'Physis',
  },
  {
    id: 'pias',
    symbol: 'pias',
    name: 'PIAS',
  },
  {
    id: 'pibble',
    symbol: 'pib',
    name: 'Pibble',
  },
  {
    id: 'picasso',
    symbol: 'pica',
    name: 'Picasso',
  },
  {
    id: 'piccolo-inu',
    symbol: 'pinu',
    name: 'Piccolo Inu',
  },
  {
    id: 'pickle',
    symbol: 'pickle',
    name: 'PICKLE',
  },
  {
    id: 'pickle-finance',
    symbol: 'pickle',
    name: 'Pickle Finance',
  },
  {
    id: 'pick-or-rick',
    symbol: 'rick',
    name: 'Pick Or Rick',
  },
  {
    id: 'piedao-dough-v2',
    symbol: 'dough',
    name: 'PieDAO DOUGH v2',
  },
  {
    id: 'pig-2-0',
    symbol: 'pig2.0',
    name: 'Pig 2.0',
  },
  {
    id: 'pigeoncoin',
    symbol: 'pgn',
    name: 'Pigeoncoin',
  },
  {
    id: 'pig-finance',
    symbol: 'pig',
    name: 'Pig Finance',
  },
  {
    id: 'piggy-bank-2',
    symbol: 'piggy',
    name: 'Piggy Bank',
  },
  {
    id: 'pig-inu',
    symbol: 'piginu',
    name: 'Pig Inu',
  },
  {
    id: 'pikaboss',
    symbol: 'pika',
    name: 'Pikaboss',
  },
  {
    id: 'pikachu',
    symbol: 'pika',
    name: 'Pika',
  },
  {
    id: 'pika-protocol',
    symbol: 'pika',
    name: 'Pika Protocol',
  },
  {
    id: 'pikaster',
    symbol: 'mls',
    name: 'Metaland Shares',
  },
  {
    id: 'pillar',
    symbol: 'plr',
    name: 'Pillar',
  },
  {
    id: 'pilot',
    symbol: 'ptd',
    name: 'Pilot',
  },
  {
    id: 'pilotcoin',
    symbol: 'ptc',
    name: 'PILOTCOIN',
  },
  {
    id: 'pine',
    symbol: 'pine',
    name: 'Pine',
  },
  {
    id: 'pineapple-owl',
    symbol: 'pineowl',
    name: 'Pineapple Owl',
  },
  {
    id: 'pi-network-iou',
    symbol: 'pi',
    name: 'Pi Network',
  },
  {
    id: 'pingu',
    symbol: 'noot noot',
    name: 'PINGU',
  },
  {
    id: 'pingu-exchange',
    symbol: 'pingu',
    name: 'Pingu Exchange',
  },
  {
    id: 'pinjam-kava',
    symbol: 'pinkav',
    name: 'Pinjam.Kava',
  },
  {
    id: 'pink-bnb',
    symbol: 'pnb',
    name: 'Pink BNB',
  },
  {
    id: 'pinkcoin',
    symbol: 'pink',
    name: 'Pinkcoin',
  },
  {
    id: 'pinkmoon',
    symbol: 'pinkm',
    name: 'PinkMoon',
  },
  {
    id: 'pinksale',
    symbol: 'pinksale',
    name: 'PinkSale',
  },
  {
    id: 'pink-vote',
    symbol: 'pit',
    name: 'Pink Vote',
  },
  {
    id: 'pinnako',
    symbol: 'piko',
    name: 'Pinnako',
  },
  {
    id: 'pintswap',
    symbol: 'pint',
    name: 'PintSwap',
  },
  {
    id: 'pintu-token',
    symbol: 'ptu',
    name: 'Pintu',
  },
  {
    id: 'pion',
    symbol: 'pion',
    name: 'Pion',
  },
  {
    id: 'pip',
    symbol: 'pip',
    name: 'PIP',
  },
  {
    id: 'pipi-the-cat',
    symbol: 'pipi',
    name: 'pipi the cat',
  },
  {
    id: 'pi-protocol',
    symbol: 'pip',
    name: 'Pi Protocol',
  },
  {
    id: 'piratecash',
    symbol: 'pirate',
    name: 'PirateCash',
  },
  {
    id: 'pirate-chain',
    symbol: 'arrr',
    name: 'Pirate Chain',
  },
  {
    id: 'piratecoin',
    symbol: 'piratecoin☠',
    name: 'PirateCoin',
  },
  {
    id: 'pirate-dice',
    symbol: 'booty',
    name: 'Pirate Dice',
  },
  {
    id: 'piratera',
    symbol: 'pira',
    name: 'Piratera',
  },
  {
    id: 'pirate-x-pirate',
    symbol: 'pxp',
    name: 'Pirate x Pirate',
  },
  {
    id: 'pirb',
    symbol: 'pirb',
    name: 'PIRB',
  },
  {
    id: 'pirichain',
    symbol: 'piri',
    name: 'Pirichain',
  },
  {
    id: 'pisscoin',
    symbol: 'piss',
    name: 'Pisscoin',
  },
  {
    id: 'pitbull',
    symbol: 'pit',
    name: 'Pitbull',
  },
  {
    id: 'pitch-fxs',
    symbol: 'pitchfxs',
    name: 'Pitch FXS',
  },
  {
    id: 'piteas',
    symbol: 'pts',
    name: 'Piteas',
  },
  {
    id: 'pivn',
    symbol: 'pivn',
    name: 'PIVN',
  },
  {
    id: 'pivx',
    symbol: 'pivx',
    name: 'PIVX',
  },
  {
    id: 'pixel-battle',
    symbol: 'pwc',
    name: 'Pixel Battle',
  },
  {
    id: 'pixelpotus',
    symbol: 'pxl',
    name: 'PixelPotus',
  },
  {
    id: 'pixelverse',
    symbol: 'pixel',
    name: 'PixelVerse',
  },
  {
    id: 'pixer-eternity',
    symbol: 'pxt',
    name: 'Pixer Eternity',
  },
  {
    id: 'pixie',
    symbol: 'pix',
    name: 'Pixie',
  },
  {
    id: 'pixiu-finance',
    symbol: 'pixiu',
    name: 'Pixiu Finance',
  },
  {
    id: 'pizabrc',
    symbol: 'piza',
    name: 'PIZA (Ordinals)',
  },
  {
    id: 'pizon',
    symbol: 'pzt',
    name: 'Pizon',
  },
  {
    id: 'pizza-game',
    symbol: 'pizza',
    name: 'Pizza Game',
  },
  {
    id: 'pkey',
    symbol: 'pkey',
    name: 'Pkey',
  },
  {
    id: 'pkt',
    symbol: 'pkt',
    name: 'PKT',
  },
  {
    id: 'place-war',
    symbol: 'place',
    name: 'PlaceWar Governance',
  },
  {
    id: 'plan-b-dao',
    symbol: 'planb',
    name: 'Plan B DAO',
  },
  {
    id: 'plan-blui',
    symbol: 'pblui',
    name: 'Plan Blui',
  },
  {
    id: 'planetcats',
    symbol: 'catcoin',
    name: 'PlanetCats',
  },
  {
    id: 'planeteer-social',
    symbol: 'planeteer',
    name: 'Planeteer Social',
  },
  {
    id: 'planet-finance',
    symbol: 'aqua',
    name: 'Planet Finance',
  },
  {
    id: 'planet-hares',
    symbol: 'hac',
    name: 'Planet Hares',
  },
  {
    id: 'planet-sandbox',
    symbol: 'psb',
    name: 'Planet Sandbox',
  },
  {
    id: 'planet-token',
    symbol: 'planet',
    name: 'Planet Token',
  },
  {
    id: 'planetwatch',
    symbol: 'planets',
    name: 'PlanetWatch',
  },
  {
    id: 'plankton',
    symbol: 'plnk',
    name: 'Plankton',
  },
  {
    id: 'planq',
    symbol: 'plq',
    name: 'Planq',
  },
  {
    id: 'plant-vs-undead-token',
    symbol: 'pvu',
    name: 'Plant vs Undead',
  },
  {
    id: 'plasma-finance',
    symbol: 'ppay',
    name: 'Plasma Finance',
  },
  {
    id: 'plastichero',
    symbol: 'pth',
    name: 'PlasticHero',
  },
  {
    id: 'plastiks',
    symbol: 'plastik',
    name: 'Plastiks',
  },
  {
    id: 'plata-network',
    symbol: 'plata',
    name: 'Plata Network',
  },
  {
    id: 'platform-of-meme-coins',
    symbol: 'payu',
    name: 'Platform of meme coins',
  },
  {
    id: 'platincoin',
    symbol: 'plc',
    name: 'PlatinCoin',
  },
  {
    id: 'platinx',
    symbol: 'ptx',
    name: 'PlatinX',
  },
  {
    id: 'platon-network',
    symbol: 'lat',
    name: 'PlatON Network',
  },
  {
    id: 'platypus-finance',
    symbol: 'ptp',
    name: 'Platypus Finance',
  },
  {
    id: 'platypus-usd',
    symbol: 'usp',
    name: 'Platypus USD',
  },
  {
    id: 'playa3ull-games-2',
    symbol: '3ull',
    name: 'PLAYA3ULL GAMES',
  },
  {
    id: 'playcent',
    symbol: 'pcnt',
    name: 'Playcent',
  },
  {
    id: 'playchip',
    symbol: 'pla',
    name: 'PlayChip',
  },
  {
    id: 'playdapp',
    symbol: 'pla',
    name: 'PlayDapp',
  },
  {
    id: 'player-2',
    symbol: 'deo',
    name: 'Player 2',
  },
  {
    id: 'playermon',
    symbol: 'pym',
    name: 'Playermon',
  },
  {
    id: 'playgame',
    symbol: 'pxg',
    name: 'PlayGame',
  },
  {
    id: 'playground-waves-floor-index',
    symbol: 'waves',
    name: 'Playground Waves Floor Index',
  },
  {
    id: 'play-it-forward-dao',
    symbol: 'pif',
    name: 'Play It Forward DAO',
  },
  {
    id: 'playkey',
    symbol: 'pkt',
    name: 'PlayKey',
  },
  {
    id: 'play-kingdom',
    symbol: 'pkt',
    name: 'Play Kingdom',
  },
  {
    id: 'playnity',
    symbol: 'ply',
    name: 'PlayNity',
  },
  {
    id: 'playpad',
    symbol: 'ppad',
    name: 'PlayPad',
  },
  {
    id: 'play-to-create',
    symbol: 'drn',
    name: 'Play To Create',
  },
  {
    id: 'playzap',
    symbol: 'pzp',
    name: 'PlayZap',
  },
  {
    id: 'plaza-dao',
    symbol: 'plaz',
    name: 'Plaza DAO',
  },
  {
    id: 'plc-ultima',
    symbol: 'plcu',
    name: 'PLC Ultima',
  },
  {
    id: 'plc-ultima-classic',
    symbol: 'plcuc',
    name: 'PLC Ultima Classic',
  },
  {
    id: 'plearn',
    symbol: 'pln',
    name: 'PLEARN',
  },
  {
    id: 'pleasure-coin',
    symbol: 'nsfw',
    name: 'Pleasure Coin',
  },
  {
    id: 'plebbit',
    symbol: 'pleb',
    name: 'Plebbit',
  },
  {
    id: 'pleb-token',
    symbol: 'pleb',
    name: 'PLEB Token',
  },
  {
    id: 'plenty-dao',
    symbol: 'plenty',
    name: 'Plenty DeFi',
  },
  {
    id: 'plenty-ply',
    symbol: 'ply',
    name: 'Plenty PLY',
  },
  {
    id: 'plex',
    symbol: 'plex',
    name: 'PLEX',
  },
  {
    id: 'plexus-app',
    symbol: 'plx',
    name: 'PLEXUS',
  },
  {
    id: 'plgnet',
    symbol: 'plug',
    name: 'PL^Gnet',
  },
  {
    id: 'plotx',
    symbol: 'plot',
    name: 'PlotX',
  },
  {
    id: 'plsjones',
    symbol: 'plsjones',
    name: 'plsJONES',
  },
  {
    id: 'plsspa',
    symbol: 'plsspa',
    name: 'plsSPA',
  },
  {
    id: 'plug-chain',
    symbol: 'pc',
    name: 'Plug Chain',
  },
  {
    id: 'plugin',
    symbol: 'pli',
    name: 'Plugin',
  },
  {
    id: 'plug-power-ai',
    symbol: 'ppai',
    name: 'Plug Power AI',
  },
  {
    id: 'plums',
    symbol: 'plums',
    name: 'PLUMS',
  },
  {
    id: 'plusonecoin',
    symbol: 'plus1',
    name: 'PlusOneCoin',
  },
  {
    id: 'pluton',
    symbol: 'plu',
    name: 'Pluton',
  },
  {
    id: 'plutonian-dao',
    symbol: 'pld',
    name: 'Plutonian DAO',
  },
  {
    id: 'plutus-arb',
    symbol: 'plsarb',
    name: 'Plutus ARB',
  },
  {
    id: 'plutusdao',
    symbol: 'pls',
    name: 'PlutusDAO',
  },
  {
    id: 'plutus-dpx',
    symbol: 'plsdpx',
    name: 'Plutus DPX',
  },
  {
    id: 'plutus-rdnt',
    symbol: 'plsrdnt',
    name: 'Plutus RDNT',
  },
  {
    id: 'plvglp',
    symbol: 'plvglp',
    name: 'plvGLP',
  },
  {
    id: 'plxyer',
    symbol: 'plxy',
    name: 'Plxyer',
  },
  {
    id: 'plz-come-back-to-eth',
    symbol: 'plz',
    name: 'PLZ COME BACK TO ETH',
  },
  {
    id: 'pmg-coin',
    symbol: 'pmg',
    name: 'PMG Coin',
  },
  {
    id: 'pmxx',
    symbol: 'pmxx',
    name: 'People’s Money PMXX',
  },
  {
    id: 'pnetwork',
    symbol: 'pnt',
    name: 'pNetwork',
  },
  {
    id: 'pnpcoin',
    symbol: 'pnpc',
    name: 'PNPCoin',
  },
  {
    id: 'poc-blockchain',
    symbol: 'poc',
    name: 'POC Blockchain',
  },
  {
    id: 'pochi-inu',
    symbol: 'pochi',
    name: 'Pochi Inu',
  },
  {
    id: 'pocketcoin',
    symbol: 'pkoin',
    name: 'Pocketcoin',
  },
  {
    id: 'pocket-network',
    symbol: 'pokt',
    name: 'Pocket Network',
  },
  {
    id: 'pocket-watcher-bot',
    symbol: 'pocket',
    name: 'Pocket Watcher Bot',
  },
  {
    id: 'pocoland',
    symbol: 'poco',
    name: 'Pocoland',
  },
  {
    id: 'podfast',
    symbol: '$fast',
    name: 'PodFast',
  },
  {
    id: 'poet',
    symbol: 'poe',
    name: 'Po.et',
  },
  {
    id: 'pogai',
    symbol: 'pogai',
    name: 'POGAI',
  },
  {
    id: 'pogex',
    symbol: 'pogex',
    name: 'PogeX',
  },
  {
    id: 'poglana',
    symbol: 'pog',
    name: 'Poglana',
  },
  {
    id: 'pointpay',
    symbol: 'pxp',
    name: 'PointPay',
  },
  {
    id: 'points',
    symbol: 'points',
    name: 'Points',
  },
  {
    id: 'poison-finance',
    symbol: 'poi$on',
    name: 'Poison Finance',
  },
  {
    id: 'pokedx',
    symbol: 'pdx',
    name: 'PokeDX',
  },
  {
    id: 'pokegrok',
    symbol: 'pokegrok',
    name: 'PokeGROK',
  },
  {
    id: 'poken',
    symbol: 'pkn',
    name: 'Poken',
  },
  {
    id: 'pokeplay-token',
    symbol: 'ppc',
    name: 'PokePlay Token',
  },
  {
    id: 'poker-chads',
    symbol: 'pkc',
    name: 'Poker Chads',
  },
  {
    id: 'polar',
    symbol: 'polar',
    name: 'POLAR',
  },
  {
    id: 'polar-bear-2026',
    symbol: 'ойойойойой',
    name: 'Polar Bear 2026',
  },
  {
    id: 'polaris-share',
    symbol: 'pola',
    name: 'Polaris Share',
  },
  {
    id: 'polar-sync',
    symbol: 'polar',
    name: 'Polar Sync',
  },
  {
    id: 'polar-token',
    symbol: 'polar',
    name: 'Polaris Finance Polar',
  },
  {
    id: 'poldo',
    symbol: 'poldo',
    name: 'Poldo',
  },
  {
    id: 'polimec',
    symbol: 'plmc',
    name: 'Polimec',
  },
  {
    id: 'polinate',
    symbol: 'poli',
    name: 'Polinate',
  },
  {
    id: 'polis',
    symbol: 'polis',
    name: 'Polis',
  },
  {
    id: 'polkabridge',
    symbol: 'pbr',
    name: 'PolkaBridge',
  },
  {
    id: 'polka-city',
    symbol: 'polc',
    name: 'Polkacity',
  },
  {
    id: 'polka-classic',
    symbol: 'dotc',
    name: 'Polka Classic',
  },
  {
    id: 'polkadex',
    symbol: 'pdex',
    name: 'Polkadex',
  },
  {
    id: 'polkadot',
    symbol: 'dot',
    name: 'Polkadot',
  },
  {
    id: 'polkafantasy',
    symbol: 'xp',
    name: 'PolkaFantasy',
  },
  {
    id: 'polkafoundry',
    symbol: 'pkf',
    name: 'Red Kite',
  },
  {
    id: 'polkagold',
    symbol: 'pgold',
    name: 'Polkagold',
  },
  {
    id: 'polkamarkets',
    symbol: 'polk',
    name: 'Polkamarkets',
  },
  {
    id: 'polkapet-world',
    symbol: 'pets',
    name: 'PolkaPet World',
  },
  {
    id: 'polkaplay',
    symbol: 'polo',
    name: 'NftyPlay',
  },
  {
    id: 'polkarare',
    symbol: 'prare',
    name: 'Polkarare',
  },
  {
    id: 'polkastarter',
    symbol: 'pols',
    name: 'Polkastarter',
  },
  {
    id: 'polkaswap',
    symbol: 'pswap',
    name: 'Polkaswap',
  },
  {
    id: 'polkawar',
    symbol: 'pwar',
    name: 'PolkaWar',
  },
  {
    id: 'polker',
    symbol: 'pkr',
    name: 'Polker',
  },
  {
    id: 'pollchain',
    symbol: 'poll',
    name: 'Pollchain',
  },
  {
    id: 'pollen',
    symbol: 'pln',
    name: 'Pollen',
  },
  {
    id: 'pollux-coin',
    symbol: 'pox',
    name: 'Pollux Coin',
  },
  {
    id: 'polly',
    symbol: 'polly',
    name: 'Polly Finance',
  },
  {
    id: 'polly-defi-nest',
    symbol: 'ndefi',
    name: 'Polly DeFi Nest',
  },
  {
    id: 'polycat-finance',
    symbol: 'fish',
    name: 'Polycat Finance',
  },
  {
    id: 'polychain-monsters',
    symbol: 'pmon',
    name: 'Polychain Monsters',
  },
  {
    id: 'polycub',
    symbol: 'polycub',
    name: 'PolyCub',
  },
  {
    id: 'polydoge',
    symbol: 'polydoge',
    name: 'PolyDoge',
  },
  {
    id: 'polygame',
    symbol: 'pgem',
    name: 'Polygame',
  },
  {
    id: 'polygamma',
    symbol: 'gamma',
    name: 'PolyGamma Finance',
  },
  {
    id: 'polygen',
    symbol: 'pgen',
    name: 'Polygen',
  },
  {
    id: 'polygod',
    symbol: 'gull',
    name: 'PolyGod',
  },
  {
    id: 'polygold',
    symbol: 'polygold',
    name: 'PolyGold',
  },
  {
    id: 'polygon-ecosystem-token',
    symbol: 'pol',
    name: 'Polygon Ecosystem Token',
  },
  {
    id: 'polygonfarm-finance',
    symbol: 'spade',
    name: 'PolygonFarm Finance',
  },
  {
    id: 'polygon-hbd',
    symbol: 'phbd',
    name: 'Polygon HBD',
  },
  {
    id: 'polygon-star',
    symbol: 'pos',
    name: 'Polygon Star',
  },
  {
    id: 'polylastic',
    symbol: 'polx',
    name: 'Polylastic',
  },
  {
    id: 'polylauncher',
    symbol: 'angel',
    name: 'Polylauncher',
  },
  {
    id: 'polymath',
    symbol: 'poly',
    name: 'Polymath',
  },
  {
    id: 'polymesh',
    symbol: 'polyx',
    name: 'Polymesh',
  },
  {
    id: 'polypad',
    symbol: 'polypad',
    name: 'PolyPad',
  },
  {
    id: 'poly-peg-mdex',
    symbol: 'hmdx',
    name: 'Poly-Peg Mdex',
  },
  {
    id: 'polypup',
    symbol: 'pup',
    name: 'PolyPup',
  },
  {
    id: 'polyshark-finance',
    symbol: 'shark',
    name: 'PolyShark Finance',
  },
  {
    id: 'polyshield',
    symbol: 'shi3ld',
    name: 'PolyShield',
  },
  {
    id: 'polysports',
    symbol: 'ps1',
    name: 'POLYSPORTS',
  },
  {
    id: 'polyswarm',
    symbol: 'nct',
    name: 'PolySwarm',
  },
  {
    id: 'polytrade',
    symbol: 'trade',
    name: 'Polytrade',
  },
  {
    id: 'polywhale',
    symbol: 'krill',
    name: 'Polywhale',
  },
  {
    id: 'polywolf',
    symbol: 'moon',
    name: 'Polywolf',
  },
  {
    id: 'polyyeld-token',
    symbol: 'yeld',
    name: 'PolyYeld',
  },
  {
    id: 'polyyield-token',
    symbol: 'yield',
    name: 'PolyYield',
  },
  {
    id: 'polyzap',
    symbol: 'pzap',
    name: 'PolyZap',
  },
  {
    id: 'pomerium-community-meme-t',
    symbol: 'pme',
    name: 'Pomerium Community Meme Token',
  },
  {
    id: 'pomerium-ecosystem',
    symbol: 'pmg',
    name: 'Pomerium Ecosystem Token',
  },
  {
    id: 'pom-governance',
    symbol: 'pomg',
    name: 'POM Governance',
  },
  {
    id: 'pomo',
    symbol: 'pomo',
    name: 'Pomo',
  },
  {
    id: 'pond-coin',
    symbol: 'pndc',
    name: 'PondCoin',
  },
  {
    id: 'pong-heroes',
    symbol: 'pong',
    name: 'Pong Heroes',
  },
  {
    id: 'ponk',
    symbol: 'ponk',
    name: 'Ponk',
  },
  {
    id: 'ponke',
    symbol: 'ponke',
    name: 'PONKE',
  },
  {
    id: 'pontoon',
    symbol: 'toon',
    name: 'Pontoon',
  },
  {
    id: 'ponyhawk',
    symbol: 'skate',
    name: 'PONYHAWK',
  },
  {
    id: 'pooch',
    symbol: 'pooch',
    name: 'Pooch',
  },
  {
    id: 'poocoin',
    symbol: 'poocoin',
    name: 'PooCoin',
  },
  {
    id: 'poodle',
    symbol: 'poodl',
    name: 'Poodl',
  },
  {
    id: 'poodl-exchange-token',
    symbol: 'pet',
    name: 'Poodl Exchange Token',
  },
  {
    id: 'poo-doge',
    symbol: 'poo doge',
    name: 'Poo Doge',
  },
  {
    id: 'poofcash',
    symbol: 'poof',
    name: 'PoofCash',
  },
  {
    id: 'poof-token',
    symbol: 'poof',
    name: 'Poof Token',
  },
  {
    id: 'pooh',
    symbol: 'pooh',
    name: 'POOH',
  },
  {
    id: 'poollotto-finance',
    symbol: 'plt',
    name: 'Poollotto.finance',
  },
  {
    id: 'pool-partyyy',
    symbol: 'party',
    name: 'Pool Partyyy',
  },
  {
    id: 'poolshark',
    symbol: 'fin',
    name: 'Poolshark',
  },
  {
    id: 'pooltogether',
    symbol: 'pool',
    name: 'PoolTogether',
  },
  {
    id: 'pooltogether-prize-usdc',
    symbol: 'pusdc.e',
    name: 'PoolTogether Prize USD Coin',
  },
  {
    id: 'pooltogether-prize-weth-aave',
    symbol: 'pweth',
    name: 'PoolTogether Prize WETH - Aave',
  },
  {
    id: 'poolz-finance',
    symbol: 'poolz',
    name: 'Poolz Finance [OLD]',
  },
  {
    id: 'poolz-finance-2',
    symbol: 'poolx',
    name: 'Poolz Finance',
  },
  {
    id: 'poopsicle',
    symbol: 'poop',
    name: 'Poopsicle',
  },
  {
    id: 'poorpleb',
    symbol: 'pp',
    name: 'PoorPleb',
  },
  {
    id: 'popcat',
    symbol: 'popcat',
    name: 'Popcat',
  },
  {
    id: 'pop-chest-token',
    symbol: 'pop',
    name: 'POP Network',
  },
  {
    id: 'popcoin',
    symbol: 'pop',
    name: 'Popcoin',
  },
  {
    id: 'popcorn',
    symbol: 'pop',
    name: 'Popcorn',
  },
  {
    id: 'popdog',
    symbol: 'popdog',
    name: 'POPDOG',
  },
  {
    id: 'popecoin',
    symbol: 'pope',
    name: 'PopeCoin',
  },
  {
    id: 'popkon',
    symbol: 'popk',
    name: 'POPKON',
  },
  {
    id: 'popo-pepe-s-dog',
    symbol: '$popo',
    name: "Popo, Pepe's Dog",
  },
  {
    id: 'pop-token',
    symbol: 'ppt',
    name: 'Pop Token',
  },
  {
    id: 'populous',
    symbol: 'ppt',
    name: 'Populous',
  },
  {
    id: 'pork',
    symbol: 'pork',
    name: 'Pork',
  },
  {
    id: 'pornrocket',
    symbol: 'pornrocket',
    name: 'PornRocket',
  },
  {
    id: 'port3-network',
    symbol: 'port3',
    name: 'Port3 Network',
  },
  {
    id: 'port-finance',
    symbol: 'port',
    name: 'Port Finance',
  },
  {
    id: 'portion',
    symbol: 'prt',
    name: 'Portion',
  },
  {
    id: 'portugal-national-team-fan-token',
    symbol: 'por',
    name: 'Portugal National Team Fan Token',
  },
  {
    id: 'portuma',
    symbol: 'por',
    name: 'Portuma',
  },
  {
    id: 'porygon',
    symbol: 'pory',
    name: 'Porygon',
  },
  {
    id: 'pos-32',
    symbol: 'pos32',
    name: 'PoS-32',
  },
  {
    id: 'poseidon-2',
    symbol: 'psdn',
    name: 'Poseidon',
  },
  {
    id: 'poseidon-finance',
    symbol: 'psdn',
    name: 'Poseidon Finance',
  },
  {
    id: 'poseidon-ocean',
    symbol: 'psdnocean',
    name: 'Poseidon OCEAN',
  },
  {
    id: 'position-token',
    symbol: 'posi',
    name: 'Position',
  },
  {
    id: 'possum',
    symbol: 'psm',
    name: 'Possum',
  },
  {
    id: 'posthuman',
    symbol: 'phmn',
    name: 'POSTHUMAN',
  },
  {
    id: 'post-tech',
    symbol: 'post',
    name: 'Post.Tech',
  },
  {
    id: 'potato',
    symbol: 'potato',
    name: 'Potato',
  },
  {
    id: 'potato-2',
    symbol: 'tato',
    name: 'POTATO',
  },
  {
    id: 'potcoin',
    symbol: 'pot',
    name: 'Potcoin',
  },
  {
    id: 'potentiam',
    symbol: 'ptm',
    name: 'Potentiam',
  },
  {
    id: 'potfolio',
    symbol: 'ptf',
    name: 'Potfolio',
  },
  {
    id: 'potion-exchange',
    symbol: 'ptn',
    name: 'Potion Exchange',
  },
  {
    id: 'potter-predator',
    symbol: 'voldemort',
    name: 'Potter Predator',
  },
  {
    id: 'poundtoken',
    symbol: 'gbpt',
    name: 'poundtoken',
  },
  {
    id: 'pov-chain',
    symbol: '$povchain',
    name: 'POV Chain',
  },
  {
    id: 'powblocks',
    symbol: 'xpb',
    name: 'PowBlocks',
  },
  {
    id: 'power',
    symbol: 'pwr',
    name: 'MaxxChain',
  },
  {
    id: 'powercity-earn-protocol',
    symbol: 'earn',
    name: 'POWERCITY Earn Protocol',
  },
  {
    id: 'powercity-pxdc',
    symbol: 'pxdc',
    name: 'POWERCITY PXDC',
  },
  {
    id: 'powercity-watt',
    symbol: 'watt',
    name: 'POWERCITY WATT',
  },
  {
    id: 'powercoin',
    symbol: 'pwr',
    name: 'PWR Coin',
  },
  {
    id: 'powerful',
    symbol: 'pwfl',
    name: 'Powerful',
  },
  {
    id: 'power-ledger',
    symbol: 'powr',
    name: 'Powerledger',
  },
  {
    id: 'power-nodes',
    symbol: 'power',
    name: 'Power Nodes',
  },
  {
    id: 'power-of-deep-ocean',
    symbol: 'podo',
    name: 'Power Of Deep Ocean',
  },
  {
    id: 'power-token',
    symbol: 'pwr',
    name: 'Power Token',
  },
  {
    id: 'powertrade-fuel',
    symbol: 'ptf',
    name: 'PowerTrade Fuel',
  },
  {
    id: 'powswap',
    symbol: 'pow',
    name: 'Powswap',
  },
  {
    id: 'ppizza',
    symbol: 'ppizza',
    name: 'PPizza',
  },
  {
    id: 'pqx',
    symbol: 'pqx',
    name: 'PQX',
  },
  {
    id: 'pracht-pay',
    symbol: 'prachtpay',
    name: 'Pracht Pay',
  },
  {
    id: 'prcy-coin',
    symbol: 'prcy',
    name: 'PRivaCY Coin',
  },
  {
    id: 'pre',
    symbol: 'pre',
    name: 'Pre',
  },
  {
    id: 'precipitate-ai',
    symbol: 'rain',
    name: 'Precipitate.ai',
  },
  {
    id: 'predictcoin',
    symbol: 'pred',
    name: 'Predictcoin',
  },
  {
    id: 'prema',
    symbol: 'prmx',
    name: 'PREMA',
  },
  {
    id: 'premia',
    symbol: 'premia',
    name: 'Premia',
  },
  {
    id: 'preon-star',
    symbol: 'star',
    name: 'Star',
  },
  {
    id: 'pre-retogeum',
    symbol: 'prtg',
    name: 'Pre-Retogeum',
  },
  {
    id: 'presearch',
    symbol: 'pre',
    name: 'Presearch',
  },
  {
    id: 'president-ron-desantis',
    symbol: 'ron',
    name: 'President Ron DeSantis',
  },
  {
    id: 'pricetools',
    symbol: 'ptools',
    name: 'Pricetools',
  },
  {
    id: 'primal-b3099cd0-995a-4311-80d5-9c133153b38e',
    symbol: 'primal',
    name: 'PRIMAL',
  },
  {
    id: 'primas',
    symbol: 'pst',
    name: 'Primas',
  },
  {
    id: 'primate',
    symbol: 'primate',
    name: 'Primate',
  },
  {
    id: 'prime',
    symbol: 'd2d',
    name: 'Prime',
  },
  {
    id: 'primecoin',
    symbol: 'xpm',
    name: 'Primecoin',
  },
  {
    id: 'prime-numbers',
    symbol: 'prnt',
    name: 'Prime Numbers Ecosystem',
  },
  {
    id: 'primex-finance',
    symbol: 'pmx',
    name: 'Primex Finance',
  },
  {
    id: 'print-mining',
    symbol: 'print',
    name: 'Print Mining',
  },
  {
    id: 'print-protocol',
    symbol: 'print',
    name: 'Print Protocol',
  },
  {
    id: 'print-the-pepe',
    symbol: '$pp',
    name: 'Print The Pepe',
  },
  {
    id: 'prism',
    symbol: 'prism',
    name: 'Prism',
  },
  {
    id: 'prism-2',
    symbol: 'prism',
    name: 'Prism',
  },
  {
    id: 'prisma-governance-token',
    symbol: 'prisma',
    name: 'Prisma Governance Token',
  },
  {
    id: 'prisma-mkusd',
    symbol: 'mkusd',
    name: 'Prisma mkUSD',
  },
  {
    id: 'privacoin',
    symbol: 'prvc',
    name: 'PrivaCoin',
  },
  {
    id: 'privapp-network',
    symbol: 'bpriva',
    name: 'Privapp Network',
  },
  {
    id: 'privateum',
    symbol: 'pri',
    name: 'Privateum Global',
  },
  {
    id: 'privcy',
    symbol: 'priv',
    name: 'PRiVCY',
  },
  {
    id: 'prizm',
    symbol: 'pzm',
    name: 'Prizm',
  },
  {
    id: 'prnt',
    symbol: 'prnt',
    name: 'PRNT',
  },
  {
    id: 'probinex',
    symbol: 'pbx',
    name: 'Probinex',
  },
  {
    id: 'probit-exchange',
    symbol: 'prob',
    name: 'Probit',
  },
  {
    id: 'prodigy-bot',
    symbol: 'pro',
    name: 'Prodigy Bot',
  },
  {
    id: 'professional-fighters-league-fan-token',
    symbol: 'pfl',
    name: 'Professional Fighters League Fan Token',
  },
  {
    id: 'profit-blue',
    symbol: 'blue',
    name: 'Profit Blue',
  },
  {
    id: 'project-dojo',
    symbol: 'dojo',
    name: 'Project Dojo',
  },
  {
    id: 'project-galaxy',
    symbol: 'gal',
    name: 'Galxe',
  },
  {
    id: 'project-oasis',
    symbol: 'oasis',
    name: 'ProjectOasis',
  },
  {
    id: 'project-quantum',
    symbol: 'qbit',
    name: 'Project Quantum',
  },
  {
    id: 'project-with',
    symbol: 'wiken',
    name: 'Project WITH',
  },
  {
    id: 'projectx',
    symbol: 'xil',
    name: 'Xillion',
  },
  {
    id: 'projectx-d78dc2ae-9c8a-45ed-bd6a-22291d9d0812',
    symbol: 'prox',
    name: 'ProjectX',
  },
  {
    id: 'project-xeno',
    symbol: 'gxe',
    name: 'PROJECT XENO',
  },
  {
    id: 'project-zed-zed',
    symbol: 'zed',
    name: 'ZED',
  },
  {
    id: 'prometeus',
    symbol: 'prom',
    name: 'Prom',
  },
  {
    id: 'prometheum-prodigy',
    symbol: 'pmpy',
    name: 'Prometheum Prodigy',
  },
  {
    id: 'prometheus-3',
    symbol: 'prome',
    name: 'Prometheus Trading',
  },
  {
    id: 'prometheus-token',
    symbol: 'pro',
    name: 'Peak Finance Prometheus',
  },
  {
    id: 'promethios',
    symbol: 'fire',
    name: 'Promethios',
  },
  {
    id: 'promptide',
    symbol: 'promptide',
    name: 'PromptIDE',
  },
  {
    id: 'proof-of-anon',
    symbol: 'prf',
    name: 'Proof of Anon',
  },
  {
    id: 'proof-of-gorila',
    symbol: 'pog',
    name: 'Proof Of Gorila',
  },
  {
    id: 'proof-of-liquidity',
    symbol: 'pol',
    name: 'Proof Of Liquidity',
  },
  {
    id: 'proof-of-pepe',
    symbol: 'pop',
    name: 'Proof Of Pepe',
  },
  {
    id: 'proof-platform',
    symbol: 'proof',
    name: 'PROOF Platform',
  },
  {
    id: 'propbase',
    symbol: 'props',
    name: 'Propbase',
  },
  {
    id: 'propchain',
    symbol: 'propc',
    name: 'Propchain',
  },
  {
    id: 'propel-token',
    symbol: 'pel',
    name: 'Propel',
  },
  {
    id: 'property-blockchain-trade',
    symbol: 'pbt',
    name: 'PROPERTY BLOCKCHAIN TRADE',
  },
  {
    id: 'prophet',
    symbol: 'pro',
    name: 'Prophet',
  },
  {
    id: 'prophet-2',
    symbol: 'prophet',
    name: 'Prophet',
  },
  {
    id: 'props',
    symbol: 'props',
    name: 'Props',
  },
  {
    id: 'propy',
    symbol: 'pro',
    name: 'Propy',
  },
  {
    id: 'prosper',
    symbol: 'pros',
    name: 'Prosper',
  },
  {
    id: 'prospera-tax-credit',
    symbol: 'ptc',
    name: 'Prospera Tax Credit',
  },
  {
    id: 'prostarter-token',
    symbol: 'prot',
    name: 'ProStarter',
  },
  {
    id: 'protectorate-protocol',
    symbol: 'prtc',
    name: 'Protectorate Protocol',
  },
  {
    id: 'proteo-defi',
    symbol: 'proteo',
    name: 'Proteo DeFi',
  },
  {
    id: 'protocon',
    symbol: 'pen',
    name: 'Protocon',
  },
  {
    id: 'protofi',
    symbol: 'proto',
    name: 'Protofi',
  },
  {
    id: 'proto-gyro-dollar',
    symbol: 'p-gyd',
    name: 'Proto Gyro Dollar',
  },
  {
    id: 'proton',
    symbol: 'xpr',
    name: 'XPR Network',
  },
  {
    id: 'proton-coin',
    symbol: 'pro',
    name: 'Proton Coin',
  },
  {
    id: 'proton-loan',
    symbol: 'loan',
    name: 'Loan Protocol',
  },
  {
    id: 'proton-project',
    symbol: 'prtn',
    name: 'Proton Project',
  },
  {
    id: 'proton-protocol',
    symbol: 'proton',
    name: 'Proton Protocol',
  },
  {
    id: 'proxima',
    symbol: 'prox',
    name: 'Proxima',
  },
  {
    id: 'proximax',
    symbol: 'xpx',
    name: 'ProximaX',
  },
  {
    id: 'proxy',
    symbol: 'prxy',
    name: 'Proxy',
  },
  {
    id: 'prux-coin',
    symbol: 'prux',
    name: 'PRUX-Coin',
  },
  {
    id: 'pstake-finance',
    symbol: 'pstake',
    name: 'pSTAKE Finance',
  },
  {
    id: 'pstake-staked-bnb',
    symbol: 'stkbnb',
    name: 'pSTAKE Staked BNB',
  },
  {
    id: 'pstake-staked-osmo',
    symbol: 'stkosmo',
    name: 'pSTAKE Staked OSMO',
  },
  {
    id: 'psyche',
    symbol: 'usd1',
    name: 'Psyche',
  },
  {
    id: 'psyop',
    symbol: 'psyop',
    name: 'PSYOP',
  },
  {
    id: 'psyoptions',
    symbol: 'psy',
    name: 'PsyFi',
  },
  {
    id: 'pterosaur-finance',
    symbol: 'pter',
    name: 'Pterosaur Finance',
  },
  {
    id: 'ptokens-btc',
    symbol: 'pbtc',
    name: 'pTokens BTC [OLD]',
  },
  {
    id: 'ptokens-btc-2',
    symbol: 'pbtc',
    name: 'pTokens BTC',
  },
  {
    id: 'ptokens-ore',
    symbol: 'ore',
    name: 'ORE Network',
  },
  {
    id: 'pube-finance',
    symbol: 'pube',
    name: 'Pube Finance',
  },
  {
    id: 'pubgame-coin',
    symbol: 'pgc',
    name: 'PubGame Coin',
  },
  {
    id: 'publc',
    symbol: 'publx',
    name: 'PUBLC',
  },
  {
    id: 'public-index-network',
    symbol: 'pin',
    name: 'Public Index Network',
  },
  {
    id: 'public-mint',
    symbol: 'mint',
    name: 'Public Mint',
  },
  {
    id: 'public-violet-fybo',
    symbol: 'pvfybo',
    name: 'Public Violet Fybo',
  },
  {
    id: 'publish',
    symbol: 'news',
    name: 'PUBLISH',
  },
  {
    id: 'pudgy-cat',
    symbol: '$pudgy',
    name: 'Pudgy Cat',
  },
  {
    id: 'puff',
    symbol: 'puff',
    name: 'PUFF',
  },
  {
    id: 'puffin-global',
    symbol: 'puffin',
    name: 'Puffin Global',
  },
  {
    id: 'pug-ai',
    symbol: 'pugai',
    name: 'PUG AI',
  },
  {
    id: 'pullix',
    symbol: 'plx',
    name: 'Pullix',
  },
  {
    id: 'pulsar-coin',
    symbol: 'plsr',
    name: 'Pulsar Coin',
  },
  {
    id: 'pulseai',
    symbol: 'pulse',
    name: 'PulseAI',
  },
  {
    id: 'pulsebitcoin',
    symbol: 'plsb',
    name: 'PulseBitcoin',
  },
  {
    id: 'pulsebitcoin-pulsechain',
    symbol: 'plsb',
    name: 'PulseBitcoin (PulseChain)',
  },
  {
    id: 'pulsechain',
    symbol: 'pls',
    name: 'PulseChain',
  },
  {
    id: 'pulsechain-flow',
    symbol: 'flow',
    name: 'Pulsechain FLOW',
  },
  {
    id: 'pulsecrypt',
    symbol: 'plscx',
    name: 'PulseCrypt',
  },
  {
    id: 'pulsedoge',
    symbol: 'pulsedoge',
    name: 'PulseDoge',
  },
  {
    id: 'pulsedogecoin',
    symbol: 'plsd',
    name: 'PulseDogecoin',
  },
  {
    id: 'pulsefolio',
    symbol: 'pulse',
    name: 'PulseFolio',
  },
  {
    id: 'pulse-inu',
    symbol: 'pinu',
    name: 'Pulse Inu',
  },
  {
    id: 'pulse-inu-2',
    symbol: 'pinu',
    name: 'Pulse Inu',
  },
  {
    id: 'pulseln',
    symbol: 'pln',
    name: 'PulseLN',
  },
  {
    id: 'pulsepad',
    symbol: 'plspad',
    name: 'PulsePad',
  },
  {
    id: 'pulsepot',
    symbol: 'plsp',
    name: 'PulsePot',
  },
  {
    id: 'pulsereflections',
    symbol: 'prs',
    name: 'PulseReflections',
  },
  {
    id: 'pulse-token',
    symbol: 'pulse',
    name: 'PulseMarkets',
  },
  {
    id: 'pulsex',
    symbol: 'plsx',
    name: 'PulseX',
  },
  {
    id: 'pulsex-incentive-token',
    symbol: 'inc',
    name: 'PulseX Incentive Token',
  },
  {
    id: 'puma',
    symbol: 'puma',
    name: 'Puma',
  },
  {
    id: 'pumapay',
    symbol: 'pma',
    name: 'PumaPay',
  },
  {
    id: 'puml-better-health',
    symbol: 'puml',
    name: 'PUML Better Health',
  },
  {
    id: 'pumlx',
    symbol: 'pumlx',
    name: 'PUMLx',
  },
  {
    id: 'pump-it-up',
    symbol: 'pumpit',
    name: 'Pump It Up',
  },
  {
    id: 'pumpkin',
    symbol: 'pump',
    name: 'Pumpkin',
  },
  {
    id: 'pumpkin-2',
    symbol: 'pumpkin',
    name: 'Pumpkin',
  },
  {
    id: 'pumpkin-monster-token',
    symbol: 'pum',
    name: 'Pumpkin Monster Token',
  },
  {
    id: 'pumpopoly',
    symbol: 'pumpopoly',
    name: 'Pumpopoly',
  },
  {
    id: 'pumpr',
    symbol: 'pumpr',
    name: 'Pumpr',
  },
  {
    id: 'punchy-token',
    symbol: 'punch',
    name: 'Punchy Token',
  },
  {
    id: 'pundi-x',
    symbol: 'npxs',
    name: 'Pundi X [OLD]',
  },
  {
    id: 'pundi-x-2',
    symbol: 'pundix',
    name: 'Pundi X',
  },
  {
    id: 'pundi-x-purse',
    symbol: 'purse',
    name: 'Pundi X PURSE',
  },
  {
    id: 'punk-2',
    symbol: 'punk',
    name: 'PunkCity',
  },
  {
    id: 'punk-sat',
    symbol: 'psat',
    name: 'Punk Sat',
  },
  {
    id: 'punks-comic-pow',
    symbol: 'pow',
    name: 'POW',
  },
  {
    id: 'punkswap',
    symbol: 'punk',
    name: 'PunkSwap',
  },
  {
    id: 'punk-vault-nftx',
    symbol: 'punk',
    name: 'Punk Vault (NFTX)',
  },
  {
    id: 'punk-x',
    symbol: 'punk',
    name: 'Punk X',
  },
  {
    id: 'pup-doge',
    symbol: 'pupdoge',
    name: 'Pup Doge',
  },
  {
    id: 'puppets-arts-2',
    symbol: 'puppets',
    name: 'Puppets Coin',
  },
  {
    id: 'pups-ordinals',
    symbol: 'pups',
    name: 'PUPS (Ordinals)',
  },
  {
    id: 'purchasa',
    symbol: 'pca',
    name: 'Purchasa',
  },
  {
    id: 'purefi',
    symbol: 'ufi',
    name: 'PureFi',
  },
  {
    id: 'puriever',
    symbol: 'pure',
    name: 'Puriever',
  },
  {
    id: 'purple-ai',
    symbol: 'pai',
    name: 'Purple AI',
  },
  {
    id: 'purpose',
    symbol: 'prps',
    name: 'Purpose',
  },
  {
    id: 'purr',
    symbol: 'purr',
    name: 'Purr',
  },
  {
    id: 'pusd',
    symbol: 'pusd',
    name: 'PUSD_Polyquity',
  },
  {
    id: 'pusd-2',
    symbol: 'pusd',
    name: 'PUSd',
  },
  {
    id: 'pushd',
    symbol: 'pushd',
    name: 'PUSHD',
  },
  {
    id: 'pussy-financial',
    symbol: 'pussy',
    name: 'Pussy Financial',
  },
  {
    id: 'pusuke-inu',
    symbol: 'pusuke',
    name: 'Pusuke Inu',
  },
  {
    id: 'putincoin',
    symbol: 'put',
    name: 'PUTinCoin',
  },
  {
    id: 'puzzle-swap',
    symbol: 'puzzle',
    name: 'Puzzle Swap',
  },
  {
    id: 'pvc-meta',
    symbol: 'pvc',
    name: 'PVC META',
  },
  {
    id: 'pvp',
    symbol: 'pvp',
    name: 'PVP',
  },
  {
    id: 'pvptrading',
    symbol: 'pvpbot',
    name: 'PVPTrading',
  },
  {
    id: 'pwrcash',
    symbol: 'pwrc',
    name: 'PWRCASH',
  },
  {
    id: 'pymedao',
    symbol: 'pyme',
    name: 'PymeDAO',
  },
  {
    id: 'pyrin',
    symbol: 'pyi',
    name: 'Pyrin',
  },
  {
    id: 'pyrrho-defi',
    symbol: 'pyo',
    name: 'Pyrrho',
  },
  {
    id: 'pyth-network',
    symbol: 'pyth',
    name: 'Pyth Network',
  },
  {
    id: 'qanplatform',
    symbol: 'qanx',
    name: 'QANplatform',
  },
  {
    id: 'qash',
    symbol: 'qash',
    name: 'QASH',
  },
  {
    id: 'qatargrow',
    symbol: 'qatargrow',
    name: 'QatarGrow',
  },
  {
    id: 'qawalla',
    symbol: 'qwla',
    name: 'Qawalla',
  },
  {
    id: 'qbao',
    symbol: 'qbt',
    name: 'Qbao',
  },
  {
    id: 'qcash',
    symbol: 'qc',
    name: 'Qcash',
  },
  {
    id: 'qchain-qdt',
    symbol: 'qdt',
    name: 'QChain QDT',
  },
  {
    id: 'qi-dao',
    symbol: 'qi',
    name: 'Qi Dao',
  },
  {
    id: 'qie',
    symbol: 'qie',
    name: 'QI Blockchain',
  },
  {
    id: 'qiswap',
    symbol: 'qi',
    name: 'QiSwap',
  },
  {
    id: 'qitchain-network',
    symbol: 'qtc',
    name: 'Qitcoin',
  },
  {
    id: 'qitmeer-network',
    symbol: 'meer',
    name: 'Qitmeer Network',
  },
  {
    id: 'qiusd',
    symbol: 'qiusd',
    name: 'QiUSD',
  },
  {
    id: 'qlindo',
    symbol: 'qlindo',
    name: 'QLINDO',
  },
  {
    id: 'qlink',
    symbol: 'qlc',
    name: 'Kepple [OLD]',
  },
  {
    id: 'qmall',
    symbol: 'qmall',
    name: 'Qmall',
  },
  {
    id: 'qmcoin',
    symbol: 'qmc',
    name: 'QMCoin',
  },
  {
    id: 'qoodo',
    symbol: 'qdo',
    name: 'Qoodo',
  },
  {
    id: 'qowatt',
    symbol: 'qwt',
    name: 'QoWatt',
  },
  {
    id: 'qqq-token',
    symbol: 'qqq',
    name: 'Poseidon Network',
  },
  {
    id: 'qqq-tokenized-stock-defichain',
    symbol: 'dqqq',
    name: 'Invesco QQQ Trust Defichain',
  },
  {
    id: 'qredit',
    symbol: 'xqr',
    name: 'Qredit',
  },
  {
    id: 'qredo',
    symbol: 'qrdo',
    name: 'Qredo',
  },
  {
    id: 'qrkita-token',
    symbol: 'qrt',
    name: 'Qrkita',
  },
  {
    id: 'qro',
    symbol: 'qro',
    name: 'Querio',
  },
  {
    id: 'qrolli',
    symbol: 'qr',
    name: 'Qrolli',
  },
  {
    id: 'qstar',
    symbol: 'q*',
    name: 'QSTAR',
  },
  {
    id: 'qtoken',
    symbol: 'qto',
    name: 'Qtoken',
  },
  {
    id: 'qtum',
    symbol: 'qtum',
    name: 'Qtum',
  },
  {
    id: 'quack',
    symbol: 'quack',
    name: 'QUACK',
  },
  {
    id: 'quack-token',
    symbol: 'quack',
    name: 'Quack Token',
  },
  {
    id: 'quadency',
    symbol: 'quad',
    name: 'Quadency',
  },
  {
    id: 'quadrant-protocol',
    symbol: 'equad',
    name: 'Quadrant Protocol',
  },
  {
    id: 'quantfury',
    symbol: 'qtf',
    name: 'Quantfury',
  },
  {
    id: 'quantic-protocol',
    symbol: 'quantic',
    name: 'Quantic Protocol',
  },
  {
    id: 'quantland',
    symbol: 'qlt',
    name: 'Quantland',
  },
  {
    id: 'quant-network',
    symbol: 'qnt',
    name: 'Quant',
  },
  {
    id: 'quantoz-eurd',
    symbol: 'eurd',
    name: 'Quantoz EURD',
  },
  {
    id: 'quantstamp',
    symbol: 'qsp',
    name: 'Quantstamp',
  },
  {
    id: 'quantum-chaos',
    symbol: 'chaos',
    name: 'Quantum Chaos',
  },
  {
    id: 'quantum-resistant-ledger',
    symbol: 'qrl',
    name: 'Quantum Resistant Ledger',
  },
  {
    id: 'quantum-tech',
    symbol: 'qua',
    name: 'Quantum Tech',
  },
  {
    id: 'quantum-x',
    symbol: 'qtx',
    name: 'Quantum X',
  },
  {
    id: 'quark',
    symbol: 'qrk',
    name: 'Quark',
  },
  {
    id: 'quark-chain',
    symbol: 'qkc',
    name: 'QuarkChain',
  },
  {
    id: 'quark-protocol-staked-kuji',
    symbol: 'qckuji',
    name: 'Quark Protocol Staked KUJI',
  },
  {
    id: 'quark-protocol-staked-mnta',
    symbol: 'qcmnta',
    name: 'Quark Protocol Staked MNTA',
  },
  {
    id: 'quartz',
    symbol: 'qtz',
    name: 'Quartz',
  },
  {
    id: 'quasacoin',
    symbol: 'qua',
    name: 'Quasacoin',
  },
  {
    id: 'quasar-2',
    symbol: 'qsr',
    name: 'Quasar',
  },
  {
    id: 'qubic-network',
    symbol: 'qubic',
    name: 'Qubic',
  },
  {
    id: 'qubit',
    symbol: 'qbt',
    name: 'Qubit',
  },
  {
    id: 'quebecoin',
    symbol: 'qbc',
    name: 'Quebecoin',
  },
  {
    id: 'queenbee-2',
    symbol: 'qube',
    name: 'QueenBee',
  },
  {
    id: 'queeneth',
    symbol: 'qeth',
    name: 'queenETH',
  },
  {
    id: 'quick',
    symbol: 'quick',
    name: 'Quickswap [OLD]',
  },
  {
    id: 'quick-intel',
    symbol: 'qkntl',
    name: 'Quick Intel',
  },
  {
    id: 'quicksilver',
    symbol: 'qck',
    name: 'Quicksilver',
  },
  {
    id: 'quickswap',
    symbol: 'quick',
    name: 'Quickswap',
  },
  {
    id: 'quidax',
    symbol: 'qdx',
    name: 'Quidax',
  },
  {
    id: 'quidd',
    symbol: 'quidd',
    name: 'Quidd',
  },
  {
    id: 'quincoin',
    symbol: 'qin',
    name: 'QUINCOIN',
  },
  {
    id: 'quint',
    symbol: 'quint',
    name: 'Quint',
  },
  {
    id: 'quipuswap-governance-token',
    symbol: 'quipu',
    name: 'QuipuSwap Governance',
  },
  {
    id: 'quiverx',
    symbol: 'qrx',
    name: 'QuiverX',
  },
  {
    id: 'quiztok',
    symbol: 'qtcon',
    name: 'Quiztok',
  },
  {
    id: 'quo',
    symbol: 'quo',
    name: 'Quoll Finance',
  },
  {
    id: 'quorium',
    symbol: 'qgold',
    name: 'Quorium',
  },
  {
    id: 'r',
    symbol: 'r',
    name: 'R',
  },
  {
    id: 'r34p',
    symbol: 'r34p',
    name: 'R34P',
  },
  {
    id: 'rabbit-finance',
    symbol: 'rabbit',
    name: 'Rabbit Finance',
  },
  {
    id: 'rabbit-games',
    symbol: 'rait',
    name: 'Rabbit Games',
  },
  {
    id: 'rabbit-inu',
    symbol: 'rbit',
    name: 'Rabbit Inu',
  },
  {
    id: 'rabbitking',
    symbol: 'rb',
    name: 'RabbitKing',
  },
  {
    id: 'rabbitswap',
    symbol: 'rabbit',
    name: 'RabbitSwap',
  },
  {
    id: 'rabbit-wallet',
    symbol: 'rab',
    name: 'Rabbit Wallet',
  },
  {
    id: 'rabbitx',
    symbol: 'rbx',
    name: 'RabbitX',
  },
  {
    id: 'rabi',
    symbol: 'rabi',
    name: 'Rabi',
  },
  {
    id: 'rabity-finance',
    symbol: 'rbf',
    name: 'Rabity Finance',
  },
  {
    id: 'racefi',
    symbol: 'racefi',
    name: 'RaceFi',
  },
  {
    id: 'race-kingdom',
    symbol: 'atoz',
    name: 'Race Kingdom',
  },
  {
    id: 'racex',
    symbol: 'racex',
    name: 'RaceX',
  },
  {
    id: 'racing-club-fan-token',
    symbol: 'racing',
    name: 'Racing Club Fan Token',
  },
  {
    id: 'racket',
    symbol: '$rkt',
    name: 'Racket',
  },
  {
    id: 'racoon',
    symbol: 'rac',
    name: 'Racoon',
  },
  {
    id: 'rad',
    symbol: 'rad',
    name: 'RAD',
  },
  {
    id: 'rada-foundation',
    symbol: 'rada',
    name: 'RADA Foundation',
  },
  {
    id: 'radar',
    symbol: 'radar',
    name: 'Radar',
  },
  {
    id: 'radial-finance',
    symbol: 'rdl',
    name: 'Radial Finance',
  },
  {
    id: 'radiant',
    symbol: 'rxd',
    name: 'Radiant',
  },
  {
    id: 'radiant-capital',
    symbol: 'rdnt',
    name: 'Radiant Capital',
  },
  {
    id: 'radical-chess',
    symbol: 'chess',
    name: 'Radical Chess',
  },
  {
    id: 'radicle',
    symbol: 'rad',
    name: 'Radworks',
  },
  {
    id: 'radio-caca',
    symbol: 'raca',
    name: 'Radio Caca',
  },
  {
    id: 'radioshack',
    symbol: 'radio',
    name: 'RadioShack',
  },
  {
    id: 'radium',
    symbol: 'val',
    name: 'Validity',
  },
  {
    id: 'radix',
    symbol: 'xrd',
    name: 'Radix',
  },
  {
    id: 'radpie',
    symbol: 'rdp',
    name: 'Radpie',
  },
  {
    id: 'rae-token',
    symbol: 'rae',
    name: 'Receive Access Ecosystem',
  },
  {
    id: 'raft',
    symbol: 'raft',
    name: 'Raft',
  },
  {
    id: 'rage-fan',
    symbol: 'rage',
    name: 'Rage.Fan',
  },
  {
    id: 'rage-on-wheels',
    symbol: 'row',
    name: 'Rage On Wheels',
  },
  {
    id: 'ragingelonmarscoin',
    symbol: 'dogecoin',
    name: 'RagingElonMarsCoin',
  },
  {
    id: 'rai',
    symbol: 'rai',
    name: 'Rai Reflex Index',
  },
  {
    id: 'raiden-network',
    symbol: 'rdn',
    name: 'Raiden Network',
  },
  {
    id: 'raider-aurum',
    symbol: 'aurum',
    name: 'Raider Aurum',
  },
  {
    id: 'raid-token',
    symbol: 'raid',
    name: 'Raid',
  },
  {
    id: 'rai-finance',
    symbol: 'sofi',
    name: 'RAI Finance',
  },
  {
    id: 'railgun',
    symbol: 'rail',
    name: 'Railgun',
  },
  {
    id: 'rainbowtoken',
    symbol: 'rainbowtoken',
    name: 'RainbowToken',
  },
  {
    id: 'rainbow-token',
    symbol: 'rnbw',
    name: 'HaloDAO',
  },
  {
    id: 'rainbow-token-2',
    symbol: 'rbw',
    name: 'Rainbow Token',
  },
  {
    id: 'rain-coin',
    symbol: 'rain',
    name: 'Rain Coin',
  },
  {
    id: 'rainicorn',
    symbol: '$raini',
    name: 'Raini',
  },
  {
    id: 'raini-studios-token',
    symbol: 'rst',
    name: 'Raini Studios Token',
  },
  {
    id: 'rainmaker-games',
    symbol: 'rain',
    name: 'Rainmaker Games',
  },
  {
    id: 'raiser-token',
    symbol: 'rzr',
    name: 'RaiseR Token',
  },
  {
    id: 'rai-yvault',
    symbol: 'yvrai',
    name: 'RAI yVault',
  },
  {
    id: 'rake-casino',
    symbol: 'rake',
    name: 'Rake Casino',
  },
  {
    id: 'rake-com',
    symbol: 'rake',
    name: 'Rake.com',
  },
  {
    id: 'rake-finance',
    symbol: 'rak',
    name: 'Rake Finance',
  },
  {
    id: 'rake-in',
    symbol: 'rake',
    name: 'Rake.in',
  },
  {
    id: 'rally-2',
    symbol: 'rly',
    name: 'Rally',
  },
  {
    id: 'rally-solana',
    symbol: 'srly',
    name: 'Rally (Solana)',
  },
  {
    id: 'rambox',
    symbol: 'ram',
    name: 'Rambox',
  },
  {
    id: 'ramestta',
    symbol: 'rama',
    name: 'Ramestta',
  },
  {
    id: 'ramifi',
    symbol: 'ram',
    name: 'Ramifi Protocol',
  },
  {
    id: 'ramp',
    symbol: 'ramp',
    name: 'RAMP [OLD]',
  },
  {
    id: 'ramses-exchange',
    symbol: 'ram',
    name: 'Ramses Exchange',
  },
  {
    id: 'rand',
    symbol: 'rnd',
    name: 'Rand',
  },
  {
    id: 'random',
    symbol: 'rndm',
    name: 'Random',
  },
  {
    id: 'rangers-fan-token',
    symbol: 'rft',
    name: 'Rangers Fan Token',
  },
  {
    id: 'rangers-protocol-gas',
    symbol: 'rpg',
    name: 'Rangers Protocol',
  },
  {
    id: 'rankerdao',
    symbol: 'ranker',
    name: 'RankerDao',
  },
  {
    id: 'raphael',
    symbol: 'raphael',
    name: 'Raphael',
  },
  {
    id: 'rapids',
    symbol: 'rpd',
    name: 'Rapids',
  },
  {
    id: 'rapid-stakes',
    symbol: 'rapid',
    name: 'Rapid Stakes',
  },
  {
    id: 'raptor',
    symbol: 'bible',
    name: 'Raptor',
  },
  {
    id: 'raptoreum',
    symbol: 'rtm',
    name: 'Raptoreum',
  },
  {
    id: 'raptor-finance-2',
    symbol: 'rptr',
    name: 'Raptor Finance',
  },
  {
    id: 'rare-ball-shares',
    symbol: 'rbp',
    name: 'Rare Ball Potion',
  },
  {
    id: 'rare-fnd',
    symbol: 'fnd',
    name: 'Rare FND',
  },
  {
    id: 'rarible',
    symbol: 'rari',
    name: 'Rarible',
  },
  {
    id: 'rari-governance-token',
    symbol: 'rgt',
    name: 'Rari Governance',
  },
  {
    id: 'rasper-ai',
    symbol: 'rasp',
    name: 'Rasper.ai',
  },
  {
    id: 'rasta-kitty',
    symbol: 'ras',
    name: 'Rasta Kitty',
  },
  {
    id: 'ratcoin',
    symbol: 'rat',
    name: 'RatCoin',
  },
  {
    id: 'rate',
    symbol: 'rate',
    name: 'Rate',
  },
  {
    id: 'ratecoin',
    symbol: 'xra',
    name: 'Ratecoin',
  },
  {
    id: 'ratio',
    symbol: 'ratio',
    name: 'RATIO',
  },
  {
    id: 'ratio-finance',
    symbol: 'ratio',
    name: 'Ratio Protocol',
  },
  {
    id: 'rats',
    symbol: 'rats',
    name: 'Rats',
  },
  {
    id: 'ratsbase',
    symbol: 'rats',
    name: 'RatsBase',
  },
  {
    id: 'ratsdao',
    symbol: 'rat',
    name: 'ratsDAO',
  },
  {
    id: 'ravelin-finance',
    symbol: 'rav',
    name: 'Ravelin Finance',
  },
  {
    id: 'ravencoin',
    symbol: 'rvn',
    name: 'Ravencoin',
  },
  {
    id: 'ravencoin-classic',
    symbol: 'rvc',
    name: 'Ravencoin Classic',
  },
  {
    id: 'ravendex',
    symbol: 'rave',
    name: 'Ravendex',
  },
  {
    id: 'rave-nft',
    symbol: 'rave',
    name: 'RAVE NFT',
  },
  {
    id: 'ravenfund',
    symbol: 'raven',
    name: 'RavenFund',
  },
  {
    id: 'raven-protocol',
    symbol: 'raven',
    name: 'Raven Protocol',
  },
  {
    id: 'raydium',
    symbol: 'ray',
    name: 'Raydium',
  },
  {
    id: 'ray-network',
    symbol: 'xray',
    name: 'Ray Network',
  },
  {
    id: 'rays',
    symbol: 'rays',
    name: 'RAYS',
  },
  {
    id: 'raze-network',
    symbol: 'raze',
    name: 'Raze Network',
  },
  {
    id: 'razor-network',
    symbol: 'razor',
    name: 'Razor Network',
  },
  {
    id: 'rb-finance',
    symbol: 'rb',
    name: 'RB Finance',
  },
  {
    id: 'rb-share',
    symbol: 'rbx',
    name: 'RB Share',
  },
  {
    id: 'rbx-token',
    symbol: 'rbx',
    name: 'RBX',
  },
  {
    id: 'rc-celta-de-vigo-fan-token',
    symbol: 'cft',
    name: 'RC Celta de Vigo Fan Token',
  },
  {
    id: 'rcd-espanyol-fan-token',
    symbol: 'enft',
    name: 'RCD Espanyol Fan Token',
  },
  {
    id: 'r-dee-protocol',
    symbol: 'rdgx',
    name: 'R-DEE Protocol',
  },
  {
    id: 'reach',
    symbol: '$reach',
    name: 'Reach',
  },
  {
    id: 'reaction',
    symbol: 'rtc',
    name: 'Reaction',
  },
  {
    id: 'reactorfusion',
    symbol: 'rf',
    name: 'ReactorFusion',
  },
  {
    id: 'readfi',
    symbol: 'rdf',
    name: 'ReadFi',
  },
  {
    id: 'reaktor',
    symbol: 'rkr',
    name: 'Reaktor',
  },
  {
    id: 'realaliensenjoyingliquidity',
    symbol: '$rael',
    name: 'RealAliensEnjoyingLiquidity',
  },
  {
    id: 'real-big-coin',
    symbol: 'rbc',
    name: 'Real BIG Coin',
  },
  {
    id: 'real-estate-token',
    symbol: 'r3t',
    name: 'Real Estate Token',
  },
  {
    id: 'realfevr',
    symbol: 'fevr',
    name: 'RealFevr',
  },
  {
    id: 'realfinance-network',
    symbol: 'refi',
    name: 'Realfinance Network',
  },
  {
    id: 'realio-network',
    symbol: 'rio',
    name: 'Realio',
  },
  {
    id: 'realis-network',
    symbol: 'lis',
    name: 'Realis Network',
  },
  {
    id: 'reality-metaverse',
    symbol: 'rmv',
    name: 'Reality Metaverse',
  },
  {
    id: 'reality-vr',
    symbol: 'rvr',
    name: 'Reality VR',
  },
  {
    id: 'reallink',
    symbol: 'real',
    name: 'RealLink',
  },
  {
    id: 'realm',
    symbol: 'realm',
    name: 'Realm',
  },
  {
    id: 'realmoneyworld',
    symbol: 'rmw',
    name: 'RealMoneyWorld',
  },
  {
    id: 'real-realm',
    symbol: 'real',
    name: 'Real Realm',
  },
  {
    id: 'real-smurf-cat',
    symbol: 'шайлушай',
    name: 'Real Smurf Cat',
  },
  {
    id: 'real-smurf-cat-bsc',
    symbol: 'шайлушай',
    name: 'Real Smurf Cat BSC',
  },
  {
    id: 'real-sociedad-fan-token',
    symbol: 'rso',
    name: 'Real Sociedad Fan Token',
  },
  {
    id: 'real-strawberry-elephant',
    symbol: 'صباح الفرو',
    name: 'Real Strawberry Elephant',
  },
  {
    id: 'real-tok',
    symbol: 'rlto',
    name: 'REAL-TOK',
  },
  {
    id: 'realtract',
    symbol: 'ret',
    name: 'RealTract',
  },
  {
    id: 'real-usd',
    symbol: 'usdr',
    name: 'Real USD',
  },
  {
    id: 'real-world-assets',
    symbol: 'rwa',
    name: 'Real World Assets',
  },
  {
    id: 'realworldx',
    symbol: 'rwx',
    name: 'RealWorldX',
  },
  {
    id: 'realy-metaverse',
    symbol: 'real',
    name: 'Realy Metaverse',
  },
  {
    id: 'reapchain',
    symbol: 'reap',
    name: 'ReapChain',
  },
  {
    id: 'reaper-token',
    symbol: 'reaper',
    name: 'Reaper',
  },
  {
    id: 'rebase-base',
    symbol: 'rebase',
    name: 'Rebase',
  },
  {
    id: 'rebasechain',
    symbol: 'base',
    name: 'ReBaseChain',
  },
  {
    id: 'rebase-gg-irl',
    symbol: '$irl',
    name: 'Rebase GG IRL',
  },
  {
    id: 'rebasing-tbt',
    symbol: 'tbt',
    name: 'Rebasing TBT',
  },
  {
    id: 'rebel-bots',
    symbol: 'rbls',
    name: 'Rebel Bots',
  },
  {
    id: 'rebel-bots-oil',
    symbol: 'xoil',
    name: 'Rebel Bots Oil',
  },
  {
    id: 'rebirth-protocol',
    symbol: 'rbh',
    name: 'Rebirth Protocol',
  },
  {
    id: 'reboot',
    symbol: 'gg',
    name: 'Reboot',
  },
  {
    id: 'rebus',
    symbol: 'rebus',
    name: 'Rebus',
  },
  {
    id: 'recast1',
    symbol: 'r1',
    name: 'Recast1',
  },
  {
    id: 'recharge',
    symbol: 'rcg',
    name: 'Recharge',
  },
  {
    id: 'recoverydao',
    symbol: 'rec',
    name: 'RecoveryDAO',
  },
  {
    id: 'recovery-right-token',
    symbol: 'rrt',
    name: 'Recovery Right',
  },
  {
    id: 'recovery-value-usd',
    symbol: 'rvusd',
    name: 'Recovery Value USD',
  },
  {
    id: 'rectangle-finance',
    symbol: 'rtg',
    name: 'Rectangle Finance',
  },
  {
    id: 'rectime',
    symbol: 'rtime',
    name: 'RecTime',
  },
  {
    id: 'recycle-impact-world-association',
    symbol: 'riwa',
    name: 'Recycle Impact World Association',
  },
  {
    id: 'recycle-x',
    symbol: 'rcx',
    name: 'Recycle-X',
  },
  {
    id: 'recycling-ai',
    symbol: 'cycai',
    name: 'RECYCLING AI',
  },
  {
    id: 'red',
    symbol: 'red',
    name: 'Red',
  },
  {
    id: 'redacted',
    symbol: 'btrfly',
    name: 'Redacted',
  },
  {
    id: 'redancoin',
    symbol: 'redan',
    name: 'REDANCOIN',
  },
  {
    id: 'redbelly-network-token',
    symbol: 'rbnt',
    name: 'Redbelly Network Token',
  },
  {
    id: 'reddcoin',
    symbol: 'rdd',
    name: 'Reddcoin',
  },
  {
    id: 'reddit',
    symbol: 'reddit',
    name: 'Reddit',
  },
  {
    id: 'redemption-finance',
    symbol: 'rdmp',
    name: 'Redemption Finance',
  },
  {
    id: 'redemption-token',
    symbol: 'rdtn',
    name: 'Redemption Token',
  },
  {
    id: 'red-falcon',
    symbol: 'rfn',
    name: 'Red Falcon',
  },
  {
    id: 'redfeg',
    symbol: 'redfeg',
    name: 'RedFeg',
  },
  {
    id: 'redfireants',
    symbol: 'rants',
    name: 'redFireAnts',
  },
  {
    id: 'red-floki-ceo',
    symbol: 'redflokiceo',
    name: 'Red Floki CEO',
  },
  {
    id: 'redfox-labs-2',
    symbol: 'rfox',
    name: 'RFOX',
  },
  {
    id: 'redneckmountaindew',
    symbol: 'rmd',
    name: 'RedneckMountainDew',
  },
  {
    id: 'red-pepe',
    symbol: 'redpepe',
    name: 'Red Pepe',
  },
  {
    id: 'red-pepe-2',
    symbol: 'rpepe',
    name: 'Red Pepe',
  },
  {
    id: 'red-pill-2',
    symbol: 'rpill',
    name: 'Red Pill',
  },
  {
    id: 'red-ponzi-gud',
    symbol: 'rpg',
    name: 'Red Ponzi Gud',
  },
  {
    id: 'red-pulse',
    symbol: 'phb',
    name: 'Phoenix Global [OLD]',
  },
  {
    id: 'red-team',
    symbol: 'red',
    name: 'Red Team',
  },
  {
    id: 'red-token',
    symbol: 'red',
    name: 'RED TOKEN',
  },
  {
    id: 'reef',
    symbol: 'reef',
    name: 'Reef',
  },
  {
    id: 'reelfi',
    symbol: 'reelfi',
    name: 'ReelFi',
  },
  {
    id: 'reel-token',
    symbol: 'reelt',
    name: 'Reel Token',
  },
  {
    id: 'reental',
    symbol: 'rnt',
    name: 'Reental',
  },
  {
    id: 'refereum',
    symbol: 'rfr',
    name: 'Refereum',
  },
  {
    id: 'ref-finance',
    symbol: 'ref',
    name: 'Ref Finance',
  },
  {
    id: 'refinable',
    symbol: 'fine',
    name: 'Refinable',
  },
  {
    id: 'reflect-finance',
    symbol: 'rfi',
    name: 'reflect.finance',
  },
  {
    id: 'reflecto',
    symbol: 'rto',
    name: 'Reflecto',
  },
  {
    id: 'reflex',
    symbol: 'rfx',
    name: 'Reflex',
  },
  {
    id: 'reflexer-ungovernance-token',
    symbol: 'flx',
    name: 'Reflexer Ungovernance',
  },
  {
    id: 'reflex-staking-bot',
    symbol: 'reflex',
    name: 'Reflex Staking Bot',
  },
  {
    id: 'reftoken',
    symbol: 'ref',
    name: 'Ref',
  },
  {
    id: 'refund',
    symbol: 'rfd',
    name: 'Refund',
  },
  {
    id: 'regen',
    symbol: 'regen',
    name: 'Regen',
  },
  {
    id: 'regent-coin',
    symbol: 'regent',
    name: 'Regent Coin',
  },
  {
    id: 'regularpresale',
    symbol: 'regu',
    name: 'RegularPresale',
  },
  {
    id: 'reign-of-terror',
    symbol: 'reign',
    name: 'Reign of Terror',
  },
  {
    id: 'rei-network',
    symbol: 'rei',
    name: 'REI Network',
  },
  {
    id: 'rejuve-ai',
    symbol: 'rjv',
    name: 'Rejuve.AI',
  },
  {
    id: 'rekt-04bbe51a-e290-450a-afb5-b2b43b80b20e',
    symbol: 'rekt',
    name: 'REKT',
  },
  {
    id: 'rekt-3',
    symbol: 'rekt',
    name: '$REKT',
  },
  {
    id: 'rektskulls',
    symbol: 'rekt',
    name: 'RektSkulls',
  },
  {
    id: 'relation-native-token',
    symbol: 'rel',
    name: 'Relation Native Token',
  },
  {
    id: 'relay-token',
    symbol: 'relay',
    name: 'Relay Chain',
  },
  {
    id: 'releap',
    symbol: 'reap',
    name: 'Releap',
  },
  {
    id: 'relevant',
    symbol: 'rel',
    name: 'Relevant',
  },
  {
    id: 'relictumpro-genesis-token',
    symbol: 'gtn',
    name: 'RelictumPro Genesis Token',
  },
  {
    id: 'remme',
    symbol: 'rem',
    name: 'Remme',
  },
  {
    id: 'rena-finance',
    symbol: 'rena',
    name: 'RENA Finance',
  },
  {
    id: 'renbtc',
    symbol: 'renbtc',
    name: 'renBTC',
  },
  {
    id: 'rencom-network',
    symbol: 'rnt',
    name: 'Rencom Network',
  },
  {
    id: 'render-token',
    symbol: 'rndr',
    name: 'Render',
  },
  {
    id: 'rendoge',
    symbol: 'rendoge',
    name: 'renDOGE',
  },
  {
    id: 'renec',
    symbol: 'renec',
    name: 'RENEC',
  },
  {
    id: 'renewable-energy',
    symbol: 'ret',
    name: 'Renewable Energy',
  },
  {
    id: 'renq-finance',
    symbol: 'renq',
    name: 'Renq Finance',
  },
  {
    id: 'rentberry',
    symbol: 'berry',
    name: 'Rentberry',
  },
  {
    id: 'rentible',
    symbol: 'rnb',
    name: 'Rentible',
  },
  {
    id: 'renzo-restaked-eth',
    symbol: 'ezeth',
    name: 'Renzo Restaked ETH',
  },
  {
    id: 'reon',
    symbol: 'reon',
    name: 'Reon',
  },
  {
    id: 'reptilianzuckerbidenbartcoin',
    symbol: 'bart',
    name: 'ReptilianZuckerBidenBartcoin',
  },
  {
    id: 'republic-credits',
    symbol: 'rpc',
    name: 'Republic Credits',
  },
  {
    id: 'republic-note',
    symbol: 'note',
    name: 'Republic Note',
  },
  {
    id: 'republic-protocol',
    symbol: 'ren',
    name: 'Ren',
  },
  {
    id: 'republik',
    symbol: 'rpk',
    name: 'RepubliK',
  },
  {
    id: 'request-network',
    symbol: 'req',
    name: 'Request',
  },
  {
    id: 'researchcoin',
    symbol: 'rsc',
    name: 'ResearchCoin',
  },
  {
    id: 'reserve-2',
    symbol: 'rsrv',
    name: 'Reserve',
  },
  {
    id: 'reserveblock',
    symbol: 'rbx',
    name: 'ReserveBlock',
  },
  {
    id: 'reserve-rights-token',
    symbol: 'rsr',
    name: 'Reserve Rights',
  },
  {
    id: 'reset',
    symbol: 'reset',
    name: 'MetaReset',
  },
  {
    id: 'resistance-dog',
    symbol: 'redo',
    name: 'Resistance Dog',
  },
  {
    id: 'resource-protocol',
    symbol: 'source',
    name: 'ReSource Protocol',
  },
  {
    id: 'restaked-swell-eth',
    symbol: 'rsweth',
    name: 'Restaked Swell ETH',
  },
  {
    id: 'restake-finance',
    symbol: 'rstk',
    name: 'Restake Finance',
  },
  {
    id: 'reth',
    symbol: 'reth',
    name: 'StaFi Staked ETH',
  },
  {
    id: 'reth2',
    symbol: 'reth2',
    name: 'rETH2',
  },
  {
    id: 'retik-finance',
    symbol: 'retik',
    name: 'Retik Finance',
  },
  {
    id: 'retrocraft',
    symbol: 'retro',
    name: 'RetroCraft',
  },
  {
    id: 'retro-finance',
    symbol: 'retro',
    name: 'Retro Finance',
  },
  {
    id: 'retro-finance-oretro',
    symbol: 'oretro',
    name: 'Retro Finance oRETRO',
  },
  {
    id: 'reunit-wallet',
    symbol: 'reuni',
    name: 'Reunit Wallet',
  },
  {
    id: 'rev3al',
    symbol: 'rev3l',
    name: 'REV3AL',
  },
  {
    id: 'revain',
    symbol: 'rev',
    name: 'Revain',
  },
  {
    id: 'revenant',
    symbol: 'gamefi',
    name: 'Revenant',
  },
  {
    id: 'revenue-coin',
    symbol: 'rvc',
    name: 'Revenue Coin',
  },
  {
    id: 'revepe',
    symbol: 'rev',
    name: 'REVEPE',
  },
  {
    id: 'revest-finance',
    symbol: 'rvst',
    name: 'Revest Finance',
  },
  {
    id: 'revhub',
    symbol: 'revhub',
    name: 'Revhub',
  },
  {
    id: 'revival-2',
    symbol: 'revival',
    name: 'REVIVAL',
  },
  {
    id: 'revivalx',
    symbol: 'rvlx',
    name: 'RevivalX',
  },
  {
    id: 'reviveeth',
    symbol: 'revive',
    name: 'ReviveEth',
  },
  {
    id: 'revoai',
    symbol: 'revoai',
    name: 'revoAI',
  },
  {
    id: 'revoland',
    symbol: 'revo',
    name: 'Revoland',
  },
  {
    id: 'revolotto',
    symbol: 'rvl',
    name: 'Revolotto',
  },
  {
    id: 'revolt-2-earn',
    symbol: 'rvlt',
    name: 'Revolt 2 Earn',
  },
  {
    id: 'revolutiongames',
    symbol: 'rvlng',
    name: 'RevolutionGames',
  },
  {
    id: 'revolve-games',
    symbol: 'rpg',
    name: 'Revolve Games',
  },
  {
    id: 'revomon-2',
    symbol: 'revo',
    name: 'Revomon',
  },
  {
    id: 'revswap',
    symbol: 'rvs',
    name: 'Revswap',
  },
  {
    id: 'revuto',
    symbol: 'revu',
    name: 'Revuto',
  },
  {
    id: 'revv',
    symbol: 'revv',
    name: 'REVV',
  },
  {
    id: 'rex-token',
    symbol: 'xrx',
    name: 'Rex',
  },
  {
    id: 'rexx-coin',
    symbol: 'rexx',
    name: 'Rexx Coin',
  },
  {
    id: 'rezolut',
    symbol: 'zolt',
    name: 'Rezolut',
  },
  {
    id: 'rfk-coin',
    symbol: 'rfkc',
    name: 'RFK Coin',
  },
  {
    id: 'rhinofi',
    symbol: 'dvf',
    name: 'Rhino.fi',
  },
  {
    id: 'rho-token',
    symbol: 'rho',
    name: 'Rho',
  },
  {
    id: 'rhythm',
    symbol: 'rhythm',
    name: 'Rhythm',
  },
  {
    id: 'ribbit-2',
    symbol: 'rbt',
    name: 'RIBBIT',
  },
  {
    id: 'ribbit-meme',
    symbol: 'ribbit',
    name: 'Ribbit Meme',
  },
  {
    id: 'ribbon-finance',
    symbol: 'rbn',
    name: 'Ribbon Finance',
  },
  {
    id: 'riceswap',
    symbol: 'rice',
    name: 'RiceSwap',
  },
  {
    id: 'rich',
    symbol: 'rch',
    name: 'Rich',
  },
  {
    id: 'richai',
    symbol: 'richai',
    name: 'RichAI',
  },
  {
    id: 'richard',
    symbol: 'richard',
    name: 'Richard',
  },
  {
    id: 'richcity',
    symbol: 'rich',
    name: 'RichCity',
  },
  {
    id: 'richquack',
    symbol: 'quack',
    name: 'Rich Quack',
  },
  {
    id: 'rich-rabbit',
    symbol: 'rabbit',
    name: 'Rich Rabbit',
  },
  {
    id: 'ride_finance',
    symbol: 'rides',
    name: 'Rides Finance',
  },
  {
    id: 'ridotto',
    symbol: 'rdt',
    name: 'Ridotto',
  },
  {
    id: 'riecoin',
    symbol: 'ric',
    name: 'Riecoin',
  },
  {
    id: 'rifi-united',
    symbol: 'ru',
    name: 'RIFI United',
  },
  {
    id: 'rif-token',
    symbol: 'rif',
    name: 'RSK Infrastructure Framework',
  },
  {
    id: 'rigel-protocol',
    symbol: 'rgp',
    name: 'Rigel Protocol',
  },
  {
    id: 'rigoblock',
    symbol: 'grg',
    name: 'RigoBlock',
  },
  {
    id: 'rikeza',
    symbol: 'rik',
    name: 'RIKEZA',
  },
  {
    id: 'rikkei-finance',
    symbol: 'rifi',
    name: 'Rikkei Finance',
  },
  {
    id: 'rilcoin',
    symbol: 'ril',
    name: 'Rilcoin',
  },
  {
    id: 'rillafi',
    symbol: 'rilla',
    name: 'RillaFi',
  },
  {
    id: 'rimaunangis',
    symbol: 'rxt',
    name: 'RIMAUNANGIS',
  },
  {
    id: 'rin-finance-coin',
    symbol: 'rifico',
    name: 'Rin Finance Coin',
  },
  {
    id: 'rinia-inu',
    symbol: 'rinia',
    name: 'Rinia Inu',
  },
  {
    id: 'rio-defi',
    symbol: 'rfuel',
    name: 'RioDeFi',
  },
  {
    id: 'riot-racers',
    symbol: 'riot',
    name: 'Riot Racers',
  },
  {
    id: 'ripae',
    symbol: 'pae',
    name: 'Ripae',
  },
  {
    id: 'ripae-pmatic',
    symbol: 'pmatic',
    name: 'Ripae pMATIC',
  },
  {
    id: 'ripio-credit-network',
    symbol: 'rcn',
    name: 'Ripio Credit Network',
  },
  {
    id: 'ripple',
    symbol: 'xrp',
    name: 'XRP',
  },
  {
    id: 'risecoin',
    symbol: 'rsc',
    name: 'Risecoin',
  },
  {
    id: 'risitas',
    symbol: 'risita',
    name: 'Risitas',
  },
  {
    id: 'risitas-coin',
    symbol: 'risita',
    name: 'Risitas Coin',
  },
  {
    id: 'ritestream',
    symbol: 'rite',
    name: 'ritestream',
  },
  {
    id: 'rito',
    symbol: 'rito',
    name: 'Rito',
  },
  {
    id: 'riverboat',
    symbol: 'rib',
    name: 'RiverBoat',
  },
  {
    id: 'riverex-welle',
    symbol: 'welle',
    name: 'Welle',
  },
  {
    id: 'rizon',
    symbol: 'atolo',
    name: 'RIZON',
  },
  {
    id: 'rizz',
    symbol: 'rizz',
    name: 'RIZZ',
  },
  {
    id: 'rizz-coin',
    symbol: 'rizz',
    name: 'RIZZ Coin',
  },
  {
    id: 'rmrk',
    symbol: 'rmrk',
    name: 'RMRK',
  },
  {
    id: 'roaland-core',
    symbol: 'roa',
    name: 'ROACORE',
  },
  {
    id: 'roaring-kitty',
    symbol: 'roar',
    name: 'Roaring Kitty',
  },
  {
    id: 'roasthimjim',
    symbol: 'jim',
    name: 'Jim',
  },
  {
    id: 'robin-hood',
    symbol: 'hood',
    name: 'Robin Hood',
  },
  {
    id: 'robinos',
    symbol: 'rbn',
    name: 'Robinos [OLD]',
  },
  {
    id: 'robinos-2',
    symbol: 'rbn',
    name: 'Robinos',
  },
  {
    id: 'robodoge-coin',
    symbol: 'robodoge',
    name: 'RoboDoge Coin',
  },
  {
    id: 'robofi-token',
    symbol: 'vics',
    name: 'RoboFi',
  },
  {
    id: 'robo-inu-finance',
    symbol: 'rbif',
    name: 'Robo Inu Finance',
  },
  {
    id: 'robonomics-network',
    symbol: 'xrt',
    name: 'Robonomics Network',
  },
  {
    id: 'robot',
    symbol: 'robot',
    name: 'Robot',
  },
  {
    id: 'robotrade',
    symbol: 'robo',
    name: 'Robotrade',
  },
  {
    id: 'robust-token',
    symbol: 'rbt',
    name: 'Robust',
  },
  {
    id: 'rocifi',
    symbol: 'roci',
    name: 'RociFi',
  },
  {
    id: 'rock',
    symbol: 'rock',
    name: 'ROCK',
  },
  {
    id: 'rock-2',
    symbol: 'rock',
    name: 'Rock',
  },
  {
    id: 'rock-dao',
    symbol: 'rock',
    name: 'ROCK DAO',
  },
  {
    id: 'rocketcoin-2',
    symbol: 'rocket',
    name: 'RocketCoin',
  },
  {
    id: 'rocket-pool',
    symbol: 'rpl',
    name: 'Rocket Pool',
  },
  {
    id: 'rocket-pool-eth',
    symbol: 'reth',
    name: 'Rocket Pool ETH',
  },
  {
    id: 'rocket-raccoon',
    symbol: 'roc',
    name: 'Rocket Raccoon',
  },
  {
    id: 'rocketswap',
    symbol: 'rckt',
    name: 'RocketSwap',
  },
  {
    id: 'rocketverse',
    symbol: 'rkv',
    name: 'RocketVerse [OLD]',
  },
  {
    id: 'rocketverse-2',
    symbol: 'rkv',
    name: 'RocketVerse',
  },
  {
    id: 'rocketx',
    symbol: 'rvf',
    name: 'RocketX Exchange',
  },
  {
    id: 'rocki',
    symbol: 'rocki',
    name: 'Rocki',
  },
  {
    id: 'rockstar',
    symbol: 'rr',
    name: 'Rockstar',
  },
  {
    id: 'rockswap',
    symbol: 'rock',
    name: 'Rockswap',
  },
  {
    id: 'rocky',
    symbol: 'rocky',
    name: 'Rocky',
  },
  {
    id: 'rocky-the-dog',
    symbol: 'rocky',
    name: 'Rocky the dog',
  },
  {
    id: 'roco-finance',
    symbol: 'roco',
    name: 'Roco Finance',
  },
  {
    id: 'rodeo-finance',
    symbol: 'rdo',
    name: 'Rodeo Finance',
  },
  {
    id: 'rogin-ai',
    symbol: 'rog',
    name: 'ROGin AI',
  },
  {
    id: 'rogue-mav',
    symbol: 'rmav',
    name: 'Rogue MAV',
  },
  {
    id: 'roko-network',
    symbol: 'roko',
    name: 'Roko Network',
  },
  {
    id: 'rollbit-coin',
    symbol: 'rlb',
    name: 'Rollbit Coin',
  },
  {
    id: 'rollium',
    symbol: 'rlm',
    name: 'MarbleVerse',
  },
  {
    id: 'rome',
    symbol: 'rome',
    name: 'Rome',
  },
  {
    id: 'roncoin',
    symbol: 'ron',
    name: 'Roncoin',
  },
  {
    id: 'rond',
    symbol: 'rond',
    name: 'ROND',
  },
  {
    id: 'rong',
    symbol: 'rong',
    name: 'Rong',
  },
  {
    id: 'ronin',
    symbol: 'ron',
    name: 'Ronin',
  },
  {
    id: 'ronweasleytrumptoadn64inu',
    symbol: 'bnb',
    name: 'RonWeasleyTrumpToadN64Inu',
  },
  {
    id: 'roobee',
    symbol: 'roobee',
    name: 'Roobee',
  },
  {
    id: 'rook',
    symbol: 'rook',
    name: 'Rook',
  },
  {
    id: 'rootstock',
    symbol: 'rbtc',
    name: 'Rootstock RSK',
  },
  {
    id: 'rope-token',
    symbol: 'rope',
    name: 'Rope Token',
  },
  {
    id: 'ror-universe',
    symbol: 'ror',
    name: 'ROR Universe',
  },
  {
    id: 'rose',
    symbol: 'rose',
    name: 'Rose',
  },
  {
    id: 'rose-finance',
    symbol: 'rose',
    name: 'Rose Finance',
  },
  {
    id: 'rosen-bridge',
    symbol: 'rsn',
    name: 'Rosen Bridge',
  },
  {
    id: 'roseon',
    symbol: 'rosx',
    name: 'Roseon',
  },
  {
    id: 'roshambo',
    symbol: 'ros',
    name: 'Roshambo',
  },
  {
    id: 'roso-elite-gamblers-mansion',
    symbol: 'roso',
    name: 'Roso Elite Gamblers Mansion',
  },
  {
    id: 'rotharium',
    symbol: 'rth',
    name: 'Rotharium',
  },
  {
    id: 'roulettebot',
    symbol: 'roulettebo',
    name: 'RouletteBot',
  },
  {
    id: 'round-x',
    symbol: 'rndx',
    name: 'Round X',
  },
  {
    id: 'roup',
    symbol: 'roup',
    name: 'Roup (Ordinals)',
  },
  {
    id: 'roush-fenway-racing-fan-token',
    symbol: 'roush',
    name: 'Roush Fenway Racing Fan Token',
  },
  {
    id: 'route',
    symbol: 'route',
    name: 'Router Protocol',
  },
  {
    id: 'rovi-protocol',
    symbol: 'rovi',
    name: 'ROVI Protocol',
  },
  {
    id: 'rowan-coin',
    symbol: 'rwn',
    name: 'Rowan Coin',
  },
  {
    id: 'royal',
    symbol: 'royal',
    name: 'ROYAL',
  },
  {
    id: 'royale',
    symbol: 'roya',
    name: 'Royale',
  },
  {
    id: 'royal-shiba',
    symbol: 'royalshiba',
    name: 'Royal Shiba',
  },
  {
    id: 'royal-smart-future-token',
    symbol: 'rsft',
    name: 'ROYAL SMART FUTURE TOKEN',
  },
  {
    id: 'rpg-maker-ai',
    symbol: 'rpgmai',
    name: 'RPG Maker Ai',
  },
  {
    id: 'rps-league',
    symbol: 'rps',
    name: 'Rps League',
  },
  {
    id: 'rss3',
    symbol: 'rss3',
    name: 'RSS3',
  },
  {
    id: 'rssc',
    symbol: 'rssc',
    name: 'RSSC',
  },
  {
    id: 'rubic',
    symbol: 'rbc',
    name: 'Rubic',
  },
  {
    id: 'rubidium',
    symbol: 'rbd',
    name: 'Rubidium',
  },
  {
    id: 'rubix',
    symbol: 'rbt',
    name: 'Rubix',
  },
  {
    id: 'ruby',
    symbol: 'ruby',
    name: 'RUBY',
  },
  {
    id: 'ruby-currency',
    symbol: 'rbc',
    name: 'Ruby Currency',
  },
  {
    id: 'ruby-play-network',
    symbol: 'ruby',
    name: 'Ruby Play Network',
  },
  {
    id: 'ruff',
    symbol: 'ruff',
    name: 'Ruff',
  },
  {
    id: 'rugame',
    symbol: 'rug',
    name: 'RUGAME',
  },
  {
    id: 'rugbet',
    symbol: 'rbet',
    name: 'RugBet',
  },
  {
    id: 'rugzombie',
    symbol: 'zmbe',
    name: 'RugZombie',
  },
  {
    id: 'rule-token',
    symbol: 'rule',
    name: 'Rule Token',
  },
  {
    id: 'rumi-finance',
    symbol: 'rumi',
    name: 'Rumi Finance',
  },
  {
    id: 'run',
    symbol: 'run',
    name: 'Run',
  },
  {
    id: 'runblox',
    symbol: 'rux',
    name: 'RunBlox',
  },
  {
    id: 'runblox-arbitrum',
    symbol: 'rux',
    name: 'RunBlox (Arbitrum)',
  },
  {
    id: 'runner',
    symbol: 'runner',
    name: 'RUNNER',
  },
  {
    id: 'runy',
    symbol: 'runy',
    name: 'Runy',
  },
  {
    id: 'rupee',
    symbol: 'rup',
    name: 'Rupee',
  },
  {
    id: 'rupiah-token',
    symbol: 'idrt',
    name: 'Rupiah Token',
  },
  {
    id: 'rusd',
    symbol: 'rusd',
    name: 'rUSD',
  },
  {
    id: 'rush-2',
    symbol: 'rush',
    name: 'RUSH',
  },
  {
    id: 'rushcmc',
    symbol: 'rushcmc',
    name: 'RUSHCMC',
  },
  {
    id: 'rushcoin',
    symbol: 'rush',
    name: 'RushCoin',
  },
  {
    id: 'rusty-robot-country-club',
    symbol: 'rust',
    name: 'Rusty Robot Country Club',
  },
  {
    id: 'rutheneum',
    symbol: 'rth',
    name: 'Rutheneum',
  },
  {
    id: 'ruufcoin',
    symbol: 'ruuf',
    name: 'RuufCoin',
  },
  {
    id: 'rxcgames',
    symbol: 'rxcg',
    name: 'RXCGames',
  },
  {
    id: 'ryi-unity',
    symbol: 'ryiu',
    name: 'RYI Unity',
  },
  {
    id: 'ryo',
    symbol: 'ryo',
    name: 'Ryo Currency',
  },
  {
    id: 'ryoma',
    symbol: 'ryoma',
    name: 'Ryoma',
  },
  {
    id: 'ryoshi-s',
    symbol: 'ryoshi',
    name: "Ryoshi's",
  },
  {
    id: 'ryoshis-vision',
    symbol: 'ryoshi',
    name: 'Ryoshis Vision',
  },
  {
    id: 'ryoshi-token',
    symbol: 'ryoshi',
    name: 'Ryoshi',
  },
  {
    id: 's4fe',
    symbol: 's4f',
    name: 'S4FE',
  },
  {
    id: 'saba-finance',
    symbol: 'saba',
    name: 'Saba Finance',
  },
  {
    id: 'sabai-ecovers',
    symbol: 'sabai',
    name: 'Sabai Ecoverse',
  },
  {
    id: 'sabaka-inu',
    symbol: 'sabaka inu',
    name: 'Sabaka Inu',
  },
  {
    id: 'saber',
    symbol: 'sbr',
    name: 'Saber',
  },
  {
    id: 'saber-2',
    symbol: 'saber',
    name: 'Saber',
  },
  {
    id: 'sable',
    symbol: 'sable',
    name: 'Sable',
  },
  {
    id: 'sable-coin',
    symbol: 'usds',
    name: 'Sable Coin',
  },
  {
    id: 'sacabam',
    symbol: 'scb',
    name: 'Sacabam',
  },
  {
    id: 'sacabambaspis',
    symbol: 'saca',
    name: 'Sacabambaspis',
  },
  {
    id: 'saddle-finance',
    symbol: 'sdl',
    name: 'Saddle Finance',
  },
  {
    id: 'safcoin',
    symbol: 'saf',
    name: 'SafCoin',
  },
  {
    id: 'safe-anwang',
    symbol: 'safe',
    name: 'SAFE(AnWang)',
  },
  {
    id: 'safeblast',
    symbol: 'blast',
    name: 'SafeBlast',
  },
  {
    id: 'safebonk',
    symbol: 'sbonk',
    name: 'SafeBonk',
  },
  {
    id: 'safecapital',
    symbol: 'scap',
    name: 'SafeCapital',
  },
  {
    id: 'safeclassic',
    symbol: 'safeclassic',
    name: 'SafeClassic',
  },
  {
    id: 'safe-coin-2',
    symbol: 'safe',
    name: 'SafeCoin',
  },
  {
    id: 'safe-deal',
    symbol: 'sfd',
    name: 'SafeDeal',
  },
  {
    id: 'safegem',
    symbol: 'gems',
    name: 'Safegem',
  },
  {
    id: 'safegrok',
    symbol: 'safegrok',
    name: 'SafeGrok',
  },
  {
    id: 'safe-haven',
    symbol: 'sha',
    name: 'Safe Haven',
  },
  {
    id: 'safeinsure',
    symbol: 'sins',
    name: 'SafeInsure',
  },
  {
    id: 'safelaunch',
    symbol: 'sfex',
    name: 'SafeLaunch',
  },
  {
    id: 'safemars',
    symbol: 'safemars',
    name: 'Safemars',
  },
  {
    id: 'safemars-2',
    symbol: 'safemars',
    name: 'SAFEMARS',
  },
  {
    id: 'safemars-protocol',
    symbol: 'smars',
    name: 'Safemars Protocol',
  },
  {
    id: 'safememe',
    symbol: 'sme',
    name: 'SafeMeme',
  },
  {
    id: 'safeminecoin',
    symbol: 'smcn',
    name: 'SafeMineCoin',
  },
  {
    id: 'safemoo',
    symbol: 'safemoo',
    name: 'SafeMoo',
  },
  {
    id: 'safemoon-1996',
    symbol: 'sm96',
    name: 'Safemoon 1996',
  },
  {
    id: 'safemoon-2',
    symbol: 'sfm',
    name: 'SafeMoon',
  },
  {
    id: 'safemoon-inu',
    symbol: 'smi',
    name: 'SafeMoon Inu',
  },
  {
    id: 'safemoon-zilla',
    symbol: 'sfz',
    name: 'Safemoon Zilla',
  },
  {
    id: 'safe-nebula',
    symbol: 'snb',
    name: 'Safe Nebula',
  },
  {
    id: 'safepal',
    symbol: 'sfp',
    name: 'SafePal',
  },
  {
    id: 'safe-planet-earth-ai',
    symbol: 'speai',
    name: 'SAFE PLANET EARTH AI',
  },
  {
    id: 'safereum',
    symbol: 'safereum',
    name: 'SAFEREUM',
  },
  {
    id: 'safe-seafood-coin',
    symbol: 'ssf',
    name: 'Safe SeaFood Coin',
  },
  {
    id: 'safestake',
    symbol: 'dvt',
    name: 'SafeStake',
  },
  {
    id: 'safeswap-token',
    symbol: 'ssgtx',
    name: 'Safeswap SSGTX',
  },
  {
    id: 'safe-token',
    symbol: 'safe',
    name: 'Safe',
  },
  {
    id: 'safetrees',
    symbol: 'trees',
    name: 'Safetrees',
  },
  {
    id: 'safeward-ai',
    symbol: 'swi',
    name: 'SafeWard AI',
  },
  {
    id: 'saffron-finance',
    symbol: 'sfi',
    name: 'saffron.finance',
  },
  {
    id: 'safle',
    symbol: 'safle',
    name: 'Safle',
  },
  {
    id: 'safu-crypto-cz',
    symbol: 'freecz',
    name: 'FREECZ',
  },
  {
    id: 'safudex',
    symbol: 'sfd',
    name: 'SafuDex',
  },
  {
    id: 'safu-protocol',
    symbol: 'safu',
    name: 'SAFU Protocol',
  },
  {
    id: 'safuu',
    symbol: 'safuu',
    name: 'SAFUU',
  },
  {
    id: 'sai',
    symbol: 'sai',
    name: 'Sai',
  },
  {
    id: 'saiko-the-revival',
    symbol: 'saiko',
    name: 'Saiko - The Revival',
  },
  {
    id: 'sail-2',
    symbol: 'sail',
    name: 'Clipper SAIL',
  },
  {
    id: 'sailwars',
    symbol: 'swt',
    name: 'Sailwars',
  },
  {
    id: 'saintbot',
    symbol: 'saint',
    name: 'Saintbot',
  },
  {
    id: 'saitachain-coin-2',
    symbol: 'stc',
    name: 'SaitaChain Coin',
  },
  {
    id: 'saitama-inu',
    symbol: 'saitama',
    name: 'Saitama',
  },
  {
    id: 'saitama-soltama',
    symbol: 'soltama',
    name: 'Saitama (SOLTAMA)',
  },
  {
    id: 'saitarealty',
    symbol: 'srlty',
    name: 'SaitaRealty',
  },
  {
    id: 'saito',
    symbol: 'saito',
    name: 'Saito',
  },
  {
    id: 'saiyan-pepe',
    symbol: 'spepe',
    name: 'Saiyan PEPE',
  },
  {
    id: 'sakai-vault',
    symbol: 'sakai',
    name: 'Sakai Vault',
  },
  {
    id: 'sake-token',
    symbol: 'sake',
    name: 'SakeSwap',
  },
  {
    id: 'sakura',
    symbol: 'sku',
    name: 'Sakura',
  },
  {
    id: 'sakura-united-platform',
    symbol: 'sup',
    name: 'SAKURA UNITED PLATFORM',
  },
  {
    id: 'salad',
    symbol: 'sald',
    name: 'Salad',
  },
  {
    id: 'salamander',
    symbol: 'sally',
    name: 'SALAMANDER',
  },
  {
    id: 'sallar',
    symbol: 'all',
    name: 'Sallar',
  },
  {
    id: 'salmon',
    symbol: 'slm',
    name: 'Salmon',
  },
  {
    id: 'salmonation',
    symbol: 'sui',
    name: 'Salmonation',
  },
  {
    id: 'salsa-liquid-multiversx',
    symbol: 'legld',
    name: 'SALSA Liquid MultiversX',
  },
  {
    id: 'salt',
    symbol: 'salt',
    name: 'SALT',
  },
  {
    id: 'saltmarble',
    symbol: 'sml',
    name: 'Saltmarble',
  },
  {
    id: 'salvor',
    symbol: 'art',
    name: 'Salvor',
  },
  {
    id: 'samoyedcoin',
    symbol: 'samo',
    name: 'Samoyedcoin',
  },
  {
    id: 'samsunspor-fan-token',
    symbol: 'sam',
    name: 'Samsunspor Fan Token',
  },
  {
    id: 'samurai-legends',
    symbol: 'smg',
    name: 'Samurai Legends',
  },
  {
    id: 'sanctum',
    symbol: 'sanctum',
    name: 'Sanctum',
  },
  {
    id: 'sanctum-coin',
    symbol: 'sancta',
    name: 'Sanctum Coin',
  },
  {
    id: 'sandclock',
    symbol: 'quartz',
    name: 'Sandclock',
  },
  {
    id: 'san-diego-coin',
    symbol: 'sand',
    name: 'San Diego Coin',
  },
  {
    id: 'sandwich-network',
    symbol: '$sandwich',
    name: 'Sandwich Network',
  },
  {
    id: 'sangkara',
    symbol: 'misa',
    name: 'Sangkara',
  },
  {
    id: 'sanin-inu',
    symbol: 'sani',
    name: 'Sanin Inu',
  },
  {
    id: 'sanji-inu',
    symbol: 'sanji',
    name: 'Sanji Inu',
  },
  {
    id: 'sanshu-inu',
    symbol: 'sanshu',
    name: 'Sanshu Inu',
  },
  {
    id: 'santa-coin-2',
    symbol: 'santa',
    name: 'Santa Coin',
  },
  {
    id: 'santa-grok',
    symbol: 'santagrok',
    name: 'Santa Grok',
  },
  {
    id: 'santa-inu',
    symbol: 'saninu',
    name: 'Santa Inu',
  },
  {
    id: 'santiment-network-token',
    symbol: 'san',
    name: 'Santiment Network',
  },
  {
    id: 'santos-fc-fan-token',
    symbol: 'santos',
    name: 'Santos FC Fan Token',
  },
  {
    id: 'sao-paulo-fc-fan-token',
    symbol: 'spfc',
    name: 'Sao Paulo FC Fan Token',
  },
  {
    id: 'sapphire',
    symbol: 'sapp',
    name: 'Sapphire',
  },
  {
    id: 'sappy-seals-pixl',
    symbol: 'pixl',
    name: 'PIXL',
  },
  {
    id: 'saracens-fan-token',
    symbol: 'sarries',
    name: 'Saracens Fan Token',
  },
  {
    id: 'sarcophagus',
    symbol: 'sarco',
    name: 'Sarcophagus',
  },
  {
    id: 'sardis-network',
    symbol: 'srds',
    name: 'Sardis Network',
  },
  {
    id: 'saros-finance',
    symbol: 'saros',
    name: 'Saros',
  },
  {
    id: 'sashimi',
    symbol: 'sashimi',
    name: 'Sashimi',
  },
  {
    id: 'satellite-doge-1',
    symbol: 'doge-1',
    name: 'Satellite Doge-1',
  },
  {
    id: 'satellite-doge-1-mission',
    symbol: 'doge-1',
    name: 'Satellite Doge-1 Mission',
  },
  {
    id: 'sathosi-airlines-token',
    symbol: 'sat',
    name: 'Satoshi Airlines Token',
  },
  {
    id: 'satin-exchange',
    symbol: 'satin',
    name: 'Satin Exchange',
  },
  {
    id: 'sato',
    symbol: 'sato',
    name: 'SATO',
  },
  {
    id: 'sator',
    symbol: 'sao',
    name: 'Sator',
  },
  {
    id: 'satoshi-island',
    symbol: 'stc',
    name: 'Satoshi Island',
  },
  {
    id: 'satoshis-vision',
    symbol: 'sats',
    name: 'Satoshis Vision',
  },
  {
    id: 'satoshiswap-2',
    symbol: 'swap',
    name: 'SatoshiSwap',
  },
  {
    id: 'satoshivm',
    symbol: 'savm',
    name: 'SatoshiVM',
  },
  {
    id: 'satoxcoin',
    symbol: 'satox',
    name: 'Satoxcoin',
  },
  {
    id: 'satozhi',
    symbol: 'satoz',
    name: 'Satozhi',
  },
  {
    id: 'satsbridge',
    symbol: 'sabr',
    name: 'SatsBridge',
  },
  {
    id: 'satscan-ordinals',
    symbol: 'scan',
    name: 'SATSCAN (Ordinals)',
  },
  {
    id: 'sats-hunters',
    symbol: 'shnt',
    name: 'Sats Hunters',
  },
  {
    id: 'sats-ordinals',
    symbol: 'sats',
    name: 'SATS (Ordinals)',
  },
  {
    id: 'satt',
    symbol: 'satt',
    name: 'SaTT',
  },
  {
    id: 'saturna',
    symbol: 'sat',
    name: 'Saturna',
  },
  {
    id: 'sauce-inu',
    symbol: 'sauceinu',
    name: 'Sauce Inu',
  },
  {
    id: 'saucerswap',
    symbol: 'sauce',
    name: 'SaucerSwap',
  },
  {
    id: 'saudi-bonk',
    symbol: 'saudibonk',
    name: 'Saudi Bonk',
  },
  {
    id: 'saudi-pepe',
    symbol: 'saudipepe',
    name: 'SAUDI PEPE',
  },
  {
    id: 'sausagers-meat',
    symbol: 'meat',
    name: 'Meat',
  },
  {
    id: 'savage',
    symbol: 'savg',
    name: 'SAVAGE',
  },
  {
    id: 'savanna',
    symbol: 'svn',
    name: 'Savanna',
  },
  {
    id: 'savant-ai',
    symbol: 'savantai',
    name: 'Savant AI',
  },
  {
    id: 'save-baby-doge',
    symbol: 'babydoge',
    name: 'Save Baby Doge',
  },
  {
    id: 'savedroid',
    symbol: 'svd',
    name: 'Savedroid',
  },
  {
    id: 'save-elon-coin',
    symbol: 'sec',
    name: 'Save Elon Coin',
  },
  {
    id: 'saveplanetearth',
    symbol: 'spe',
    name: 'SavePlanetEarth',
  },
  {
    id: 'savings-dai',
    symbol: 'sdai',
    name: 'Savings Dai',
  },
  {
    id: 'savings-xdai',
    symbol: 'sdai',
    name: 'Savings xDAI',
  },
  {
    id: 'savvy-btc',
    symbol: 'svbtc',
    name: 'Savvy BTC',
  },
  {
    id: 'savvy-defi',
    symbol: 'svy',
    name: 'Savvy',
  },
  {
    id: 'savvy-eth',
    symbol: 'sveth',
    name: 'Savvy ETH',
  },
  {
    id: 'savvy-usd',
    symbol: 'svusd',
    name: 'Savvy USD',
  },
  {
    id: 'saxumdao',
    symbol: 'sxm',
    name: 'SaxumDAO',
  },
  {
    id: 'sayve-protocol',
    symbol: 'sayve',
    name: 'SAYVE Protocol',
  },
  {
    id: 'sbet',
    symbol: 'sbet',
    name: 'SBET',
  },
  {
    id: 'sb-group',
    symbol: 'sbg',
    name: 'SB Group',
  },
  {
    id: 'sbtc',
    symbol: 'sbtc',
    name: 'sBTC',
  },
  {
    id: 'sbu-honey',
    symbol: 'bhny',
    name: 'SBU Honey',
  },
  {
    id: 'scaleswap-token',
    symbol: 'sca',
    name: 'Scaleswap',
  },
  {
    id: 'scaleton',
    symbol: 'scale',
    name: 'Scaleton',
  },
  {
    id: 'scallop',
    symbol: 'sclp',
    name: 'Scallop',
  },
  {
    id: 'scallop-2',
    symbol: 'sca',
    name: 'Scallop',
  },
  {
    id: 'scamfari',
    symbol: 'scm',
    name: 'ScamFari',
  },
  {
    id: 'scapesmania',
    symbol: '$mania',
    name: 'ScapesMania',
  },
  {
    id: 'scarab-dao',
    symbol: 'scarab',
    name: 'Scarab DAO',
  },
  {
    id: 'scarab-finance',
    symbol: 'scarab',
    name: 'Scarab Finance',
  },
  {
    id: 'scarab-tools',
    symbol: 'dung',
    name: 'Scarab Tools',
  },
  {
    id: 'scarcity',
    symbol: 'scx',
    name: 'Scarcity',
  },
  {
    id: 'scarecrow',
    symbol: 'scare',
    name: 'ScareCrow',
  },
  {
    id: 'scat',
    symbol: 'cat',
    name: 'Scat',
  },
  {
    id: 's-c-corinthians-fan-token',
    symbol: 'sccp',
    name: 'S.C. Corinthians Fan Token',
  },
  {
    id: 'scholarship-coin',
    symbol: 'scho',
    name: 'Scholarship Coin',
  },
  {
    id: 'schrodinger',
    symbol: 'meow',
    name: 'Schrodinger',
  },
  {
    id: 'schwiftai',
    symbol: 'swai',
    name: 'SchwiftAI',
  },
  {
    id: 'scientia',
    symbol: 'scie',
    name: 'Scientia',
  },
  {
    id: 'scientix',
    symbol: 'scix',
    name: 'Scientix',
  },
  {
    id: 'sc-internacional-fan-token',
    symbol: 'saci',
    name: 'SC Internacional Fan Token',
  },
  {
    id: 'scom-coin',
    symbol: 'scom',
    name: 'Scom Coin',
  },
  {
    id: 'sconex',
    symbol: 'sconex',
    name: 'SCOneX',
  },
  {
    id: 'scooby-doo',
    symbol: 'sodo',
    name: 'Scooby Doo',
  },
  {
    id: 'scopecoin',
    symbol: 'xscp',
    name: 'ScopeCoin',
  },
  {
    id: 'scope-sniper',
    symbol: 'scope',
    name: 'Scope Sniper',
  },
  {
    id: 'scopuly-token',
    symbol: 'scop',
    name: 'Scopuly',
  },
  {
    id: 'scorai',
    symbol: 'scorai',
    name: 'Staking Compound ORAI',
  },
  {
    id: 'score-token',
    symbol: 'sco',
    name: 'Score',
  },
  {
    id: 'scotty-beam',
    symbol: 'scotty',
    name: 'Scotty Beam',
  },
  {
    id: 'scrap',
    symbol: 'scrap',
    name: 'Scrap',
  },
  {
    id: 'scratch-2',
    symbol: 'scratch',
    name: 'SCRATCH',
  },
  {
    id: 'scratch-meme-coin',
    symbol: 'scrats',
    name: 'Scratch Meme Coin',
  },
  {
    id: 'scream',
    symbol: 'scream',
    name: 'Scream',
  },
  {
    id: 'script-network',
    symbol: 'scpt',
    name: 'Script Network',
  },
  {
    id: 'script-network-spay',
    symbol: 'spay',
    name: 'Script Network SPAY',
  },
  {
    id: 'scriv',
    symbol: 'scriv',
    name: 'SCRIV',
  },
  {
    id: 'scroll-doge',
    symbol: 'zkdoge',
    name: 'Scroll Doge',
  },
  {
    id: 'scrollswap-finance',
    symbol: 'sf',
    name: 'Scrollswap Finance',
  },
  {
    id: 'scrolly-the-map',
    symbol: 'scrolly',
    name: 'Scrolly the map',
  },
  {
    id: 'scrooge',
    symbol: 'scrooge',
    name: 'Scrooge (OLD)',
  },
  {
    id: 'scry-info',
    symbol: 'ddd',
    name: 'Scry.info',
  },
  {
    id: 'scry-protocol',
    symbol: 'scry',
    name: 'Scry Protocol',
  },
  {
    id: 'sdoge',
    symbol: 'sdoge',
    name: 'SDOGE',
  },
  {
    id: 'sdrive-app',
    symbol: 'scoin',
    name: 'Sdrive.app',
  },
  {
    id: 'sealink-network',
    symbol: 'slk',
    name: 'Sealink Network',
  },
  {
    id: 'seamless-protocol',
    symbol: 'seam',
    name: 'Seamless Protocol',
  },
  {
    id: 'seamlessswap-token',
    symbol: 'seamless',
    name: 'SeamlessSwap',
  },
  {
    id: 'seapad',
    symbol: 'spt',
    name: 'SeaPad',
  },
  {
    id: 'seatlabnft',
    symbol: 'seat',
    name: 'SeatlabNFT',
  },
  {
    id: 'seba',
    symbol: 'seba',
    name: 'Seba',
  },
  {
    id: 'sechain',
    symbol: 'snn',
    name: 'SeChain',
  },
  {
    id: 'secret',
    symbol: 'scrt',
    name: 'Secret',
  },
  {
    id: 'secret-erc20',
    symbol: 'wscrt',
    name: 'Secret (ERC20)',
  },
  {
    id: 'secret-skellies-society',
    symbol: '$crypt',
    name: 'Secret Skellies Society',
  },
  {
    id: 'secretum',
    symbol: 'ser',
    name: 'Secretum',
  },
  {
    id: 'sect-bot',
    symbol: 'sect',
    name: 'SECT BOT',
  },
  {
    id: 'sector',
    symbol: 'sect',
    name: 'Sector',
  },
  {
    id: 'secure-cash',
    symbol: 'scsx',
    name: 'Secure Cash',
  },
  {
    id: 'securechain-ai',
    symbol: 'scai',
    name: 'SecureChain AI',
  },
  {
    id: 'secured-moonrat-token',
    symbol: 'smrat',
    name: 'Secured MoonRat',
  },
  {
    id: 'secured-on-blockchain',
    symbol: 'sob',
    name: 'Secured On Blockchain',
  },
  {
    id: 'seeded-network',
    symbol: 'seeded',
    name: 'Seeded Network',
  },
  {
    id: 'seedify-fund',
    symbol: 'sfund',
    name: 'Seedify.fund',
  },
  {
    id: 'seedlaunch',
    symbol: 'slt',
    name: 'SeedLaunch',
  },
  {
    id: 'seed-photo',
    symbol: 'seed',
    name: 'Seed.Photo',
  },
  {
    id: 'seeds',
    symbol: 'seeds',
    name: 'Seeds',
  },
  {
    id: 'seedx',
    symbol: 'seedx',
    name: 'SEEDx',
  },
  {
    id: 'seek-tiger',
    symbol: 'sti',
    name: 'Seek Tiger',
  },
  {
    id: 'segment',
    symbol: 'sef',
    name: 'Segment',
  },
  {
    id: 'seidow',
    symbol: 'seidow',
    name: 'Seidow',
  },
  {
    id: 'seifmoon',
    symbol: '$seif',
    name: 'Seifmoon',
  },
  {
    id: 'seiga',
    symbol: 'seiga',
    name: 'Seiga',
  },
  {
    id: 'seigniorage-shares',
    symbol: 'share',
    name: 'Seigniorage Shares',
  },
  {
    id: 'seilormoon',
    symbol: 'seilor',
    name: 'Seilormoon',
  },
  {
    id: 'seilu-bridge',
    symbol: 'seilu',
    name: 'Seilu Bridge',
  },
  {
    id: 'seimen',
    symbol: 'seimen',
    name: 'SEIMEN',
  },
  {
    id: 'seimoyed',
    symbol: 'seimoyed',
    name: 'Seimoyed',
  },
  {
    id: 'sei-network',
    symbol: 'sei',
    name: 'Sei',
  },
  {
    id: 'seiren-games-network',
    symbol: 'serg',
    name: 'Seiren Games Network',
  },
  {
    id: 'seiwhale',
    symbol: 'sei',
    name: 'SeiWhale',
  },
  {
    id: 'seiyan',
    symbol: 'seiyan',
    name: 'SEIYAN',
  },
  {
    id: 'sekuritance',
    symbol: 'skrt',
    name: 'Sekuritance',
  },
  {
    id: 'selfbar',
    symbol: 'sbar',
    name: 'Selfbar',
  },
  {
    id: 'selfcrypto',
    symbol: 'self',
    name: 'SELFCrypto',
  },
  {
    id: 'selfkey',
    symbol: 'key',
    name: 'SelfKey',
  },
  {
    id: 'self-token',
    symbol: 'self',
    name: 'Self Token',
  },
  {
    id: 'selo',
    symbol: 'selo',
    name: 'Selo',
  },
  {
    id: 'senate',
    symbol: 'senate',
    name: 'SENATE',
  },
  {
    id: 'sendcrypto',
    symbol: 'sendc',
    name: 'SendCrypto',
  },
  {
    id: 'send-finance',
    symbol: 'send',
    name: 'Send Finance',
  },
  {
    id: 'sendpicks',
    symbol: 'send',
    name: 'Sendpicks',
  },
  {
    id: 'send-token',
    symbol: 'send',
    name: 'Send',
  },
  {
    id: 'seneca',
    symbol: 'sen',
    name: 'Seneca',
  },
  {
    id: 'sense4fit',
    symbol: 'sfit',
    name: 'Sense4FIT',
  },
  {
    id: 'sensei-dog',
    symbol: 'sensei',
    name: 'Sensei Dog',
  },
  {
    id: 'sensi',
    symbol: 'sensi',
    name: 'Sensi',
  },
  {
    id: 'sensitrust',
    symbol: 'sets',
    name: 'Sensitrust',
  },
  {
    id: 'senso',
    symbol: 'senso',
    name: 'SENSO',
  },
  {
    id: 'sentimentai',
    symbol: 'sent',
    name: 'SentimentAI',
  },
  {
    id: 'sentiment-token',
    symbol: 'sent',
    name: 'Sentiment',
  },
  {
    id: 'sentinel',
    symbol: 'dvpn',
    name: 'Sentinel',
  },
  {
    id: 'sentinel-chain',
    symbol: 'senc',
    name: 'Sentinel Chain',
  },
  {
    id: 'sentinel-group',
    symbol: 'dvpn',
    name: 'Sentinel [OLD]',
  },
  {
    id: 'sentinel-protocol',
    symbol: 'upp',
    name: 'Sentinel Protocol',
  },
  {
    id: 'sentre',
    symbol: 'sntr',
    name: 'Sentre',
  },
  {
    id: 'seor-network',
    symbol: 'seor',
    name: 'SEOR Network',
  },
  {
    id: 'separly',
    symbol: 'separly',
    name: 'Separly',
  },
  {
    id: 'serbian-dancing-lady',
    symbol: 'сербскаяле',
    name: 'SERBIAN DANCING LADY',
  },
  {
    id: 'serenity-shield',
    symbol: 'sersh',
    name: 'Serenity Shield',
  },
  {
    id: 'serum',
    symbol: 'srm',
    name: 'Serum',
  },
  {
    id: 'serum-ser',
    symbol: 'ser',
    name: 'Serum SER',
  },
  {
    id: 'sesterce',
    symbol: 'ses',
    name: 'Sesterce',
  },
  {
    id: 'seth',
    symbol: 'seth',
    name: 'sETH',
  },
  {
    id: 'seth2',
    symbol: 'seth2',
    name: 'sETH2',
  },
  {
    id: 'settled-ethxy-token',
    symbol: 'sexy',
    name: 'Settled EthXY Token',
  },
  {
    id: 'seur',
    symbol: 'seur',
    name: 'sEUR',
  },
  {
    id: 'sevilla-fan-token',
    symbol: 'sevilla',
    name: 'Sevilla Fan Token',
  },
  {
    id: 'sexone',
    symbol: 'sex',
    name: 'Sexone',
  },
  {
    id: 'sf-capital',
    symbol: 'sfcp',
    name: 'SF Capital',
  },
  {
    id: 's-finance',
    symbol: 'sfg',
    name: 'S.Finance',
  },
  {
    id: 'shack',
    symbol: 'shack',
    name: 'Shack',
  },
  {
    id: 'shackleford',
    symbol: 'shack',
    name: 'Shackleford',
  },
  {
    id: 'shade-cash',
    symbol: 'shade',
    name: 'Shade Cash',
  },
  {
    id: 'shade-protocol',
    symbol: 'shd',
    name: 'Shade Protocol',
  },
  {
    id: 'shadowcats',
    symbol: 'shadowcats',
    name: 'Shadowcats',
  },
  {
    id: 'shadowfi-2',
    symbol: 'sdf',
    name: 'ShadowFi',
  },
  {
    id: 'shadows',
    symbol: 'dows',
    name: 'Shadows',
  },
  {
    id: 'shadowswap-token',
    symbol: 'shdw',
    name: 'ShadowSwap Token',
  },
  {
    id: 'shadow-wizard-money-gang',
    symbol: 'gang',
    name: 'Shadow Wizard Money Gang',
  },
  {
    id: 'shakita-inu',
    symbol: 'shak',
    name: 'Shakita Inu',
  },
  {
    id: 'shambala',
    symbol: 'bala',
    name: 'Shambala',
  },
  {
    id: 'shanghai-inu',
    symbol: 'shang',
    name: 'Shanghai Inu',
  },
  {
    id: 'shanum',
    symbol: 'shan',
    name: 'Shanum',
  },
  {
    id: 'shapeshift-fox-token',
    symbol: 'fox',
    name: 'ShapeShift FOX',
  },
  {
    id: 'sharbi',
    symbol: '$sharbi',
    name: 'Sharbi',
  },
  {
    id: 'shardeum',
    symbol: 'shm',
    name: 'Shardeum',
  },
  {
    id: 'shardus',
    symbol: 'ult',
    name: 'Shardus',
  },
  {
    id: 'sharedstake-governance-token',
    symbol: 'sgtv2',
    name: 'SharedStake Governance v2',
  },
  {
    id: 'share-friend',
    symbol: 'sfriend',
    name: 'Share.Friend',
  },
  {
    id: 'sharering',
    symbol: 'shr',
    name: 'Share',
  },
  {
    id: 'shares-finance',
    symbol: 'shares',
    name: 'shares.finance',
  },
  {
    id: 'sharesgram',
    symbol: 'sg',
    name: 'SharesGram',
  },
  {
    id: 'shark',
    symbol: 'shark',
    name: 'Shark',
  },
  {
    id: 'sharky-swap',
    symbol: 'sharky',
    name: 'Sharky Swap',
  },
  {
    id: 'sharp-portfolio-index',
    symbol: 'spi',
    name: 'Sharp Portfolio Index',
  },
  {
    id: 'shaun-inu',
    symbol: 'shaun',
    name: 'Shaun Inu',
  },
  {
    id: 'sheesh-2',
    symbol: 'shs',
    name: 'Sheesh',
  },
  {
    id: 'sheesha-finance',
    symbol: 'sheesha',
    name: 'Sheesha Finance (BEP20)',
  },
  {
    id: 'sheesha-finance-erc20',
    symbol: 'sheesha',
    name: 'Sheesha Finance (ERC20)',
  },
  {
    id: 'sheesha-finance-polygon',
    symbol: 'msheesha',
    name: 'Sheesha Finance Polygon',
  },
  {
    id: 'sheeshin-on-solana',
    symbol: 'sheesh',
    name: 'SheeshSPL',
  },
  {
    id: 'sheikh-inu',
    symbol: 'shinu',
    name: 'Sheikh Inu',
  },
  {
    id: 'shell',
    symbol: 'ss20',
    name: 'SHELL',
  },
  {
    id: 'shelterz',
    symbol: 'terz',
    name: 'SHELTERZ',
  },
  {
    id: 'shen',
    symbol: 'shen',
    name: 'Shen',
  },
  {
    id: 'shepherd-inu-2',
    symbol: 'sinu',
    name: 'Shepherd Inu',
  },
  {
    id: 'sherlock-defi',
    symbol: 'slock',
    name: 'Sherlock Defi',
  },
  {
    id: 'shezmu',
    symbol: 'shezmu',
    name: 'Shezmu',
  },
  {
    id: 'shib2',
    symbol: 'shib2',
    name: 'SHIB2',
  },
  {
    id: 'shib2-0',
    symbol: 'shib2.0',
    name: 'Shib2.0',
  },
  {
    id: 'shiba',
    symbol: 'shiba',
    name: 'Shiba',
  },
  {
    id: 'shiba-bsc',
    symbol: 'shibsc',
    name: 'SHIBA BSC',
  },
  {
    id: 'shiba-cartel',
    symbol: 'pesos',
    name: 'Shiba Cartel',
  },
  {
    id: 'shiba-ceo',
    symbol: 'shibceo',
    name: 'Shiba CEO',
  },
  {
    id: 'shiba-classic',
    symbol: 'shibc',
    name: 'Shiba Classic',
  },
  {
    id: 'shibacorgi',
    symbol: 'shico',
    name: 'ShibaCorgi',
  },
  {
    id: 'shibadoge',
    symbol: 'shibdoge',
    name: 'ShibaDoge',
  },
  {
    id: 'shiba-doge-burn',
    symbol: 'burn',
    name: 'BURN',
  },
  {
    id: 'shiba-fantom',
    symbol: 'shiba',
    name: 'Shiba Fantom',
  },
  {
    id: 'shibafomi',
    symbol: 'shifo',
    name: 'Shibafomi',
  },
  {
    id: 'shibai-labs',
    symbol: 'slab',
    name: 'ShibAI Labs',
  },
  {
    id: 'shiba-inu',
    symbol: 'shib',
    name: 'Shiba Inu',
  },
  {
    id: 'shiba-inu-classic-2',
    symbol: 'shibc',
    name: 'Shiba Inu Classic',
  },
  {
    id: 'shiba-inu-empire',
    symbol: 'shibemp',
    name: 'Shiba Inu Empire',
  },
  {
    id: 'shiba-inu-mother',
    symbol: 'shibm',
    name: 'Shiba Inu Mother',
  },
  {
    id: 'shiba-inu-wormhole',
    symbol: 'shib',
    name: 'Shiba Inu (Wormhole)',
  },
  {
    id: 'shibaken-finance',
    symbol: 'shibaken',
    name: 'Shibaken Finance',
  },
  {
    id: 'shibalite',
    symbol: 'shiblite',
    name: 'ShibaLite',
  },
  {
    id: 'shibaments',
    symbol: 'sbmt',
    name: 'Shibaments',
  },
  {
    id: 'shibana',
    symbol: 'bana',
    name: 'Shibana',
  },
  {
    id: 'shibanft',
    symbol: 'shibanft',
    name: 'ShibaNFT',
  },
  {
    id: 'shiba-nodes',
    symbol: 'shino',
    name: 'Shiba Nodes',
  },
  {
    id: 'shibapoconk',
    symbol: 'conk',
    name: 'ShibaPoconk',
  },
  {
    id: 'shiba-predator',
    symbol: 'qom',
    name: 'Shiba Predator',
  },
  {
    id: 'shiba-punkz',
    symbol: 'spunk',
    name: 'Shiba Punkz',
  },
  {
    id: 'shibarium-dao',
    symbol: 'shibdao',
    name: 'Shibarium DAO',
  },
  {
    id: 'shibarium-name-service',
    symbol: 'sns',
    name: 'Shibarium Name Service',
  },
  {
    id: 'shibarium-perpetuals',
    symbol: 'serp',
    name: 'Shibarium Perpetuals',
  },
  {
    id: 'shibarium-wrapped-bone',
    symbol: 'wbone',
    name: 'Shibarium Wrapped BONE',
  },
  {
    id: 'shiba-saga',
    symbol: 'shia',
    name: 'Shiba Saga',
  },
  {
    id: 'shibavax',
    symbol: 'shibx',
    name: 'Shibavax',
  },
  {
    id: 'shibaverse',
    symbol: 'verse',
    name: 'Shibaverse',
  },
  {
    id: 'shiba-v-pepe',
    symbol: 'shepe',
    name: 'Shiba V Pepe',
  },
  {
    id: 'shibawarp',
    symbol: 'sbwp',
    name: 'ShibaWarp',
  },
  {
    id: 'shibawifhat',
    symbol: '$wif',
    name: 'shibawifhat',
  },
  {
    id: 'shibaw-inu',
    symbol: 'shibaw',
    name: 'ShibaW Inu',
  },
  {
    id: 'shiba-wrestler-ai',
    symbol: 'swweai',
    name: 'SHIBA WRESTLER AI',
  },
  {
    id: 'shibceo',
    symbol: 'shibceo',
    name: 'ShibCEO',
  },
  {
    id: 'shibelon',
    symbol: 'shibelon',
    name: 'ShibElon',
  },
  {
    id: 'shibfalcon',
    symbol: 'shflcn',
    name: 'ShibFalcon',
  },
  {
    id: 'shibgf',
    symbol: 'shibgf',
    name: 'SHIBGF',
  },
  {
    id: 'shibking',
    symbol: 'shibking',
    name: 'ShibKing',
  },
  {
    id: 'shibnaut',
    symbol: 'shibn',
    name: 'Shibnaut',
  },
  {
    id: 'shibonk',
    symbol: 'shibo',
    name: 'ShibonkBSC',
  },
  {
    id: 'shibonk-311f81df-a4ea-4f31-9e61-df0af8211bd7',
    symbol: 'sbonk',
    name: 'SHIBONK',
  },
  {
    id: 'shib-ordinals',
    symbol: 'shib',
    name: 'SHIB (Ordinals)',
  },
  {
    id: 'shib-original-vision',
    symbol: 'sov',
    name: 'Shib Original Vision',
  },
  {
    id: 'shiboshi',
    symbol: 'shiboshi',
    name: 'Shiboshi',
  },
  {
    id: 'shibot',
    symbol: 'shibot',
    name: 'Shibot',
  },
  {
    id: 'shiden',
    symbol: 'sdn',
    name: 'Shiden Network',
  },
  {
    id: 'shido',
    symbol: 'shido',
    name: 'Shido [OLD]',
  },
  {
    id: 'shido-2',
    symbol: 'shido',
    name: 'Shido',
  },
  {
    id: 'shield-bsc-token',
    symbol: 'shdb',
    name: 'Shield BSC Token',
  },
  {
    id: 'shield-network',
    symbol: 'shieldnet',
    name: 'Shield Network',
  },
  {
    id: 'shield-protocol-2',
    symbol: 'shield',
    name: 'Shield Protocol',
  },
  {
    id: 'shieldtokencoin',
    symbol: '0stc',
    name: 'ShieldTokenCoin',
  },
  {
    id: 'shih-tzu',
    symbol: 'shih',
    name: 'Shih Tzu',
  },
  {
    id: 'shikoku',
    symbol: 'shik',
    name: 'Shikoku',
  },
  {
    id: 'shikoku-inu',
    symbol: 'shiko',
    name: 'Shikoku Inu',
  },
  {
    id: 'shila-inu',
    symbol: 'shil',
    name: 'Shila Inu',
  },
  {
    id: 'shilld',
    symbol: 'shilld',
    name: 'SHILLD',
  },
  {
    id: 'shill-guard-token',
    symbol: 'sgt',
    name: 'Shill Guard Token',
  },
  {
    id: 'shill-token',
    symbol: 'shill',
    name: 'SHILL Token',
  },
  {
    id: 'shilly-bar',
    symbol: 'shbar',
    name: 'Shilly Bar',
  },
  {
    id: 'shimmer',
    symbol: 'smr',
    name: 'Shimmer',
  },
  {
    id: 'shimmersea-lum',
    symbol: 'lum',
    name: 'ShimmerSea Lum',
  },
  {
    id: 'shina-inu',
    symbol: 'shi',
    name: 'Shina Inu',
  },
  {
    id: 'shinjarium',
    symbol: 'sjm',
    name: 'Shinjarium',
  },
  {
    id: 'shinji-inu',
    symbol: 'shinji',
    name: 'Shinji Inu',
  },
  {
    id: 'shinjiru-inu',
    symbol: 'shinji',
    name: 'Shinjiru Inu',
  },
  {
    id: 'shira-cat',
    symbol: 'catshira',
    name: 'Shira Cat',
  },
  {
    id: 'shirtum',
    symbol: 'shi',
    name: 'Shirtum',
  },
  {
    id: 'shiryo-inu',
    symbol: 'shiryo-inu',
    name: 'Shiryo',
  },
  {
    id: 'shita-kiri-suzume',
    symbol: 'suzume',
    name: 'Shita-kiri Suzume',
  },
  {
    id: 'shitzu',
    symbol: 'shitzu',
    name: 'Shitzu',
  },
  {
    id: 'shockwaves',
    symbol: 'neuros',
    name: 'Shockwaves',
  },
  {
    id: 'shoebill-coin',
    symbol: 'shbl',
    name: 'Shoebill Coin',
  },
  {
    id: 'shoefy',
    symbol: 'shoe',
    name: 'ShoeFy',
  },
  {
    id: 'shontoken',
    symbol: 'shon',
    name: 'Shon',
  },
  {
    id: 'shopbot',
    symbol: 'shop',
    name: 'ShopBot',
  },
  {
    id: 'shopnext-loyalty-token',
    symbol: 'next',
    name: 'ShopNext Loyalty Token',
  },
  {
    id: 'shopnext-reward-token',
    symbol: 'ste',
    name: 'ShopNEXT Reward Token',
  },
  {
    id: 'shoppingfriend-ai',
    symbol: 'aibuddy',
    name: 'ShoppingFriend.AI',
  },
  {
    id: 'shopping-io-token',
    symbol: 'shop',
    name: 'Shopping.io',
  },
  {
    id: 'short-term-t-bill-token',
    symbol: 'stbt',
    name: 'Short-term T-Bill Token',
  },
  {
    id: 'shping',
    symbol: 'shping',
    name: 'Shping',
  },
  {
    id: 'shrapnel-2',
    symbol: 'shrap',
    name: 'Shrapnel',
  },
  {
    id: 'shredn',
    symbol: 'shred',
    name: 'ShredN',
  },
  {
    id: 'shree',
    symbol: 'shr',
    name: 'SHREE',
  },
  {
    id: 'shroom-finance',
    symbol: 'shroom',
    name: 'Niftyx Protocol',
  },
  {
    id: 'shuffle-by-hupayx',
    symbol: 'sfl',
    name: 'SHUFFLE by HUPAYX',
  },
  {
    id: 'shuts-wave',
    symbol: 'swave',
    name: 'shuts Wave',
  },
  {
    id: 'shyft-network-2',
    symbol: 'shft',
    name: 'Shyft Network',
  },
  {
    id: 'siacoin',
    symbol: 'sc',
    name: 'Siacoin',
  },
  {
    id: 'siaprime-coin',
    symbol: 'scp',
    name: 'ScPrime',
  },
  {
    id: 'side-eye-cat',
    symbol: 'sec',
    name: 'Side Eye Cat',
  },
  {
    id: 'sideshift-token',
    symbol: 'xai',
    name: 'SideShift',
  },
  {
    id: 'sidus',
    symbol: 'sidus',
    name: 'Sidus',
  },
  {
    id: 'sienna',
    symbol: 'sienna',
    name: 'Sienna',
  },
  {
    id: 'sienna-erc20',
    symbol: 'wsienna',
    name: 'Sienna [ERC-20]',
  },
  {
    id: 'sifchain',
    symbol: 'erowan',
    name: 'Sifchain',
  },
  {
    id: 'sigil-finance',
    symbol: 'sigil',
    name: 'Sigil Finance',
  },
  {
    id: 'sign',
    symbol: 'sign',
    name: 'Sign Token',
  },
  {
    id: 'signata',
    symbol: 'sata',
    name: 'Signata',
  },
  {
    id: 'signed',
    symbol: 'sign',
    name: 'Signed',
  },
  {
    id: 'signet',
    symbol: 'sig',
    name: 'Signet',
  },
  {
    id: 'signum',
    symbol: 'signa',
    name: 'Signum',
  },
  {
    id: 'silent-notary',
    symbol: 'ubsn',
    name: 'Silent Notary',
  },
  {
    id: 'silk',
    symbol: 'silk',
    name: 'Spider Tanks',
  },
  {
    id: 'silk-bcec1136-561c-4706-a42c-8b67d0d7f7d2',
    symbol: 'silk',
    name: 'Silk',
  },
  {
    id: 'silly-bonk',
    symbol: 'sillybonk',
    name: 'Silly Bonk',
  },
  {
    id: 'silly-dragon',
    symbol: 'silly',
    name: 'Silly Dragon',
  },
  {
    id: 'silo-finance',
    symbol: 'silo',
    name: 'Silo Finance',
  },
  {
    id: 'silva-token',
    symbol: 'silva',
    name: 'Silva',
  },
  {
    id: 'silver',
    symbol: 'silver',
    name: 'SILVER',
  },
  {
    id: 'silvercashs',
    symbol: 'svc',
    name: 'Silvercashs',
  },
  {
    id: 'silverstonks',
    symbol: 'sstx',
    name: 'Silver Stonks',
  },
  {
    id: 'silver-tokenized-stock-defichain',
    symbol: 'dslv',
    name: 'iShares Silver Trust Defichain',
  },
  {
    id: 'simba-coin',
    symbol: 'simba',
    name: 'Simba Coin',
  },
  {
    id: 'simbcoin-swap',
    symbol: 'smbswap',
    name: 'SimbCoin Swap',
  },
  {
    id: 'simple-asymmetry-eth',
    symbol: 'safeth',
    name: 'Simple Asymmetry ETH',
  },
  {
    id: 'simplehub',
    symbol: 'shub',
    name: 'SimpleHub',
  },
  {
    id: 'simple-masternode-coin',
    symbol: 'smnc',
    name: 'Simple Masternode Coin',
  },
  {
    id: 'simple-token',
    symbol: 'ost',
    name: 'OST',
  },
  {
    id: 'simpli-finance',
    symbol: 'simpli',
    name: 'Simpli Finance',
  },
  {
    id: 'simpson6900',
    symbol: 'simpson690',
    name: 'Simpson6900',
  },
  {
    id: 'simracer-coin',
    symbol: 'src',
    name: 'Simracer Coin',
  },
  {
    id: 'sin-city',
    symbol: 'sin',
    name: 'Sinverse',
  },
  {
    id: 'sincronix',
    symbol: 'snx',
    name: 'SincroniX',
  },
  {
    id: 'sindi',
    symbol: 'sindi',
    name: 'SINDI',
  },
  {
    id: 'single-finance',
    symbol: 'single',
    name: 'Single Finance',
  },
  {
    id: 'sing-token',
    symbol: 'sing',
    name: 'Sing',
  },
  {
    id: 'sing-token-avalanche',
    symbol: 'sing',
    name: 'Sing (Avalanche)',
  },
  {
    id: 'sing-token-ftm',
    symbol: 'sing',
    name: 'Sing FTM',
  },
  {
    id: 'singulardtv',
    symbol: 'sngls',
    name: 'SingularDTV',
  },
  {
    id: 'singularity',
    symbol: 'sgly',
    name: 'Singularity',
  },
  {
    id: 'singularitydao',
    symbol: 'sdao',
    name: 'SingularityDAO',
  },
  {
    id: 'singularitynet',
    symbol: 'agix',
    name: 'SingularityNET',
  },
  {
    id: 'sino',
    symbol: 'sino',
    name: 'Cantosino.com',
  },
  {
    id: 'sint-truidense-voetbalvereniging-fan-token',
    symbol: 'stv',
    name: 'Sint-Truidense Voetbalvereniging Fan Token',
  },
  {
    id: 'sipher',
    symbol: 'sipher',
    name: 'SIPHER',
  },
  {
    id: 'siphon-life-spell',
    symbol: 'sls',
    name: 'Siphon Life Spell',
  },
  {
    id: 'siren',
    symbol: 'si',
    name: 'Siren',
  },
  {
    id: 'sirin-labs-token',
    symbol: 'srn',
    name: 'Sirin Labs',
  },
  {
    id: 'sirius-finance',
    symbol: 'srs',
    name: 'Sirius Finance',
  },
  {
    id: 'sispop',
    symbol: 'sispop',
    name: 'SISPOP',
  },
  {
    id: 'sisyphus',
    symbol: 'sis',
    name: 'Sisyphus (Friend.tech)',
  },
  {
    id: 'six-network',
    symbol: 'six',
    name: 'SIX Network',
  },
  {
    id: 'six-sigma',
    symbol: 'sge',
    name: 'Six Sigma',
  },
  {
    id: 'size',
    symbol: 'size',
    name: 'SIZE',
  },
  {
    id: 'size-2',
    symbol: 'size',
    name: 'SIZE',
  },
  {
    id: 'skale',
    symbol: 'skl',
    name: 'SKALE',
  },
  {
    id: 'skeb',
    symbol: 'skeb',
    name: 'Skeb',
  },
  {
    id: 'skey-network',
    symbol: 'skey',
    name: 'Skey Network',
  },
  {
    id: 'skibidi-toilet',
    symbol: 'toilet',
    name: 'Skibidi Toilet',
  },
  {
    id: 'sklay',
    symbol: 'sklay',
    name: 'sKLAY',
  },
  {
    id: 'skolana',
    symbol: 'skol',
    name: 'SKOLANA',
  },
  {
    id: 'skrimples',
    symbol: 'skrimp',
    name: 'Skrimples',
  },
  {
    id: 'skrumble-network',
    symbol: 'skm',
    name: 'Skrumble Network',
  },
  {
    id: 'skullswap-exchange',
    symbol: 'skull',
    name: 'SkullSwap Exchange',
  },
  {
    id: 'skycoin',
    symbol: 'sky',
    name: 'Skycoin',
  },
  {
    id: 'skydogenet',
    symbol: 'skydoge',
    name: 'skydogenet',
  },
  {
    id: 'skydrome',
    symbol: 'sky',
    name: 'Skydrome',
  },
  {
    id: 'skyplay',
    symbol: 'skp',
    name: 'SKYPlay',
  },
  {
    id: 'skyrim-finance',
    symbol: 'skyrim',
    name: 'Skyrim Finance',
  },
  {
    id: 'skyup',
    symbol: 'su',
    name: 'skyup',
  },
  {
    id: 'sky-v2',
    symbol: 'sky',
    name: 'SkyToken',
  },
  {
    id: 'slam-token',
    symbol: 'slam',
    name: 'Slam',
  },
  {
    id: 'slap-face',
    symbol: 'slafac',
    name: 'Slap Face',
  },
  {
    id: 'sl-benfica-fan-token',
    symbol: 'benfica',
    name: 'SL Benfica Fan Token',
  },
  {
    id: 'sleepfuture',
    symbol: 'sleepee',
    name: 'SleepFuture',
  },
  {
    id: 'sleepless-ai',
    symbol: 'ai',
    name: 'Sleepless AI',
  },
  {
    id: 'slimcoin',
    symbol: 'slm',
    name: 'Slimcoin',
  },
  {
    id: 'slm-games',
    symbol: 'slm',
    name: 'SLM.Games',
  },
  {
    id: 'slnv2',
    symbol: 'slnv2',
    name: 'SLNV2',
  },
  {
    id: 'slp',
    symbol: 'slp',
    name: 'SLP',
  },
  {
    id: 'slurp',
    symbol: '$slurp',
    name: 'Slurp',
  },
  {
    id: 'small-doge',
    symbol: 'sdog',
    name: 'Small Doge',
  },
  {
    id: 'smardex',
    symbol: 'sdex',
    name: 'SmarDex',
  },
  {
    id: 'smart-aliens',
    symbol: 'sas',
    name: 'Smart Aliens',
  },
  {
    id: 'smart-blockchain',
    symbol: 'smart',
    name: 'SMART BLOCKCHAIN',
  },
  {
    id: 'smart-block-chain-city',
    symbol: 'sbcc',
    name: 'Smart Block Chain City',
  },
  {
    id: 'smartcash',
    symbol: 'smart',
    name: 'SmartCash',
  },
  {
    id: 'smart-coin-smrtr',
    symbol: 'smrtr',
    name: 'SmarterCoin',
  },
  {
    id: 'smartcredit-token',
    symbol: 'smartcredit',
    name: 'SmartCredit',
  },
  {
    id: 'smart-game-finance',
    symbol: 'smart',
    name: 'Smart Game Finance',
  },
  {
    id: 'smartlands',
    symbol: 'dnt',
    name: 'Definder Network',
  },
  {
    id: 'smartlink',
    symbol: 'smak',
    name: 'Smartlink',
  },
  {
    id: 'smart-marketing-token',
    symbol: 'smt',
    name: 'Smart Marketing',
  },
  {
    id: 'smartmesh',
    symbol: 'smt',
    name: 'SmartMesh',
  },
  {
    id: 'smart-mfg',
    symbol: 'mfg',
    name: 'Smart MFG',
  },
  {
    id: 'smartnft',
    symbol: 'smartnft',
    name: 'SmartNFT',
  },
  {
    id: 'smartofgiving',
    symbol: 'aog',
    name: 'smARTOFGIVING',
  },
  {
    id: 'smartpad-2',
    symbol: 'pad',
    name: 'SmartPad',
  },
  {
    id: 'smart-reward-token',
    symbol: 'srt',
    name: 'Smart Reward Token',
  },
  {
    id: 'smartsettoken',
    symbol: 'sst',
    name: 'SmartsetToken',
  },
  {
    id: 'smartshare',
    symbol: 'ssp',
    name: 'Smartshare',
  },
  {
    id: 'smart-trade-bot',
    symbol: 'smart-bot',
    name: 'Smart Trade-BOT',
  },
  {
    id: 'smart-valor',
    symbol: 'valor',
    name: 'Smart Valor',
  },
  {
    id: 'smart-wallet-token',
    symbol: 'swt',
    name: 'Smart Wallet',
  },
  {
    id: 'smart-world-union',
    symbol: 'swu',
    name: 'Smart World Union',
  },
  {
    id: 'smarty-pay',
    symbol: 'spy',
    name: 'Smarty Pay',
  },
  {
    id: 'smash-cash',
    symbol: 'smash',
    name: 'Smash Cash',
  },
  {
    id: 'smell',
    symbol: 'sml',
    name: 'Smell',
  },
  {
    id: 'smileai',
    symbol: 'smile',
    name: 'SmileAI',
  },
  {
    id: 'smilestack',
    symbol: 'sst',
    name: 'SmileStack',
  },
  {
    id: 'smileycoin',
    symbol: 'smly',
    name: 'Smileycoin',
  },
  {
    id: 'smiley-coin',
    symbol: 'smiley',
    name: 'Smiley Coin',
  },
  {
    id: 'smolano',
    symbol: 'slo',
    name: 'SmoLanO',
  },
  {
    id: 'smolcoin',
    symbol: 'smol',
    name: 'Smolcoin',
  },
  {
    id: 'smol-su',
    symbol: 'su',
    name: 'Smol Su',
  },
  {
    id: 'smooth-love-potion',
    symbol: 'slp',
    name: 'Smooth Love Potion',
  },
  {
    id: 'smoothy',
    symbol: 'smty',
    name: 'Smoothy',
  },
  {
    id: 'smorf',
    symbol: 'smorf',
    name: 'smorf',
  },
  {
    id: 'smp-finance',
    symbol: 'smpf',
    name: 'SMP Finance',
  },
  {
    id: 'smudge-cat',
    symbol: 'smudcat',
    name: 'Smudge Cat',
  },
  {
    id: 'smudge-lord',
    symbol: 'smudge',
    name: 'Smudge Lord',
  },
  {
    id: 'smurfsinu',
    symbol: 'smurf',
    name: 'SmurfsINU',
  },
  {
    id: 'snailbrook',
    symbol: 'snail',
    name: 'SnailBrook',
  },
  {
    id: 'snailmoon',
    symbol: 'snm',
    name: 'SnailMoon',
  },
  {
    id: 'snail-trail',
    symbol: 'slime',
    name: 'Snail Trail',
  },
  {
    id: 'snakebot',
    symbol: 'snakebot',
    name: 'SnakeBot',
  },
  {
    id: 'snake-city',
    symbol: 'snct',
    name: 'Snake City',
  },
  {
    id: 'snakes-game',
    symbol: 'snakes',
    name: 'Snakes Game',
  },
  {
    id: 'snaps',
    symbol: 'snps',
    name: 'Snaps',
  },
  {
    id: 'snark-launch',
    symbol: '$snrk',
    name: 'Snark Launch',
  },
  {
    id: 'snek',
    symbol: 'snek',
    name: 'Snek',
  },
  {
    id: 'snepe',
    symbol: 'snepe',
    name: 'SNEPE',
  },
  {
    id: 'snetwork',
    symbol: 'snet',
    name: 'Snetwork',
  },
  {
    id: 'snfts-seedify-nft-space',
    symbol: 'snfts',
    name: 'Seedify NFT Space',
  },
  {
    id: 'snipe-finance',
    symbol: 'snipe',
    name: 'Snipe Finance',
  },
  {
    id: 'snkrz-fit',
    symbol: 'fit',
    name: 'Fit',
  },
  {
    id: 'snook',
    symbol: 'snk',
    name: 'Snook',
  },
  {
    id: 'snowball-2',
    symbol: 'snox',
    name: 'Snowball',
  },
  {
    id: 'snowball-token',
    symbol: 'snob',
    name: 'Snowball',
  },
  {
    id: 'snowbank',
    symbol: 'sb',
    name: 'Snowbank',
  },
  {
    id: 'snow-bot',
    symbol: 'sbot',
    name: 'Snow Bot',
  },
  {
    id: 'snowcrash-token',
    symbol: 'nora',
    name: 'SnowCrash',
  },
  {
    id: 'snow-inu',
    symbol: 'snow',
    name: 'Snow Inu',
  },
  {
    id: 'snow-leopard-irbis',
    symbol: 'irbis',
    name: 'Snow Leopard - IRBIS',
  },
  {
    id: 'snowman',
    symbol: 'snow',
    name: 'Snowman',
  },
  {
    id: 'snowswap',
    symbol: 'snow',
    name: 'Snowswap',
  },
  {
    id: 'snowtomb',
    symbol: 'stomb',
    name: 'Snowtomb',
  },
  {
    id: 'snowtomb-lot',
    symbol: 'slot',
    name: 'Snowtomb LOT',
  },
  {
    id: 'snx-yvault',
    symbol: 'yvsnx',
    name: 'SNX yVault',
  },
  {
    id: 'soaps-tech',
    symbol: 'soaps',
    name: 'Soaps Tech',
  },
  {
    id: 'soba-token',
    symbol: 'soba',
    name: 'SOBA',
  },
  {
    id: 'sobit-bridge',
    symbol: 'sobb',
    name: 'SoBit Bridge',
  },
  {
    id: 'soccer-crypto',
    symbol: 'sot',
    name: 'Soccer Crypto',
  },
  {
    id: 'socean-staked-sol',
    symbol: 'scnsol',
    name: 'Sanctum Staked SOL',
  },
  {
    id: 'social-ai',
    symbol: 'socialai',
    name: 'Social AI',
  },
  {
    id: 'social-capitalism-2',
    symbol: 'socap',
    name: 'Social Capitalism',
  },
  {
    id: 'social-good-project',
    symbol: 'sg',
    name: 'SocialGood',
  },
  {
    id: 'social-send',
    symbol: 'send',
    name: 'Social Send',
  },
  {
    id: 'socialswap-token',
    symbol: 'sst',
    name: 'Social Swap',
  },
  {
    id: 'societe-generale-forge-eurcv',
    symbol: 'eurcv',
    name: 'Societe Generale-FORGE EURCV',
  },
  {
    id: 'socio',
    symbol: 'socio',
    name: 'Socio',
  },
  {
    id: 'sociocat',
    symbol: '$cat',
    name: 'SocioCat',
  },
  {
    id: 'socol',
    symbol: 'simp',
    name: 'SO-COL',
  },
  {
    id: 'socomfy',
    symbol: 'comfy',
    name: 'SOCOMFY',
  },
  {
    id: 'socrates',
    symbol: 'soc',
    name: 'Socrates',
  },
  {
    id: 'soft-dao',
    symbol: 'soft',
    name: 'Soft DAO',
  },
  {
    id: 'soge-2',
    symbol: 'soge',
    name: 'SOGE',
  },
  {
    id: 'sohotrn',
    symbol: 'sohot',
    name: 'SOHOTRN',
  },
  {
    id: 'soil',
    symbol: 'soil',
    name: 'Soil',
  },
  {
    id: 'sojak',
    symbol: 'sojak',
    name: 'Sojak',
  },
  {
    id: 'sojudao',
    symbol: 'soju',
    name: 'SOJUDAO',
  },
  {
    id: 'sokuswap',
    symbol: 'soku',
    name: 'SokuSwap',
  },
  {
    id: 'solabrador-2',
    symbol: 'sober',
    name: 'Solabrador',
  },
  {
    id: 'solalgo',
    symbol: 'slgo',
    name: 'Solalgo',
  },
  {
    id: 'solama',
    symbol: 'solama',
    name: 'Solama',
  },
  {
    id: 'solamander',
    symbol: 'soly',
    name: 'Solamander',
  },
  {
    id: 'solana',
    symbol: 'sol',
    name: 'Solana',
  },
  {
    id: 'solanaape',
    symbol: 'sape',
    name: 'SolanaApe',
  },
  {
    id: 'solana-beach',
    symbol: 'solana',
    name: 'Solana Beach',
  },
  {
    id: 'solanacorn',
    symbol: 'corn',
    name: 'Solanacorn',
  },
  {
    id: 'solana-ecosystem-index',
    symbol: 'soli',
    name: 'Solana Ecosystem Index',
  },
  {
    id: 'solana-gun',
    symbol: 'solgun',
    name: 'Solana Gun',
  },
  {
    id: 'solana-inu',
    symbol: 'inu',
    name: 'Solana Inu',
  },
  {
    id: 'solana-meme-token',
    symbol: 'sol10',
    name: 'SOLANA MEME TOKEN',
  },
  {
    id: 'solana-nut',
    symbol: 'solnut',
    name: 'Solana Nut',
  },
  {
    id: 'solanapepe',
    symbol: 'spepe',
    name: 'SolanaPepe',
  },
  {
    id: 'solanaprime',
    symbol: 'prime',
    name: 'SolanaPrime',
  },
  {
    id: 'solana-shib',
    symbol: 'sshib',
    name: 'Solana Shib ',
  },
  {
    id: 'solana-street-bets',
    symbol: 'ssb',
    name: 'Solana Street Bets',
  },
  {
    id: 'solanium',
    symbol: 'slim',
    name: 'Solanium',
  },
  {
    id: 'solape-token',
    symbol: 'solape',
    name: 'SOLAPE',
  },
  {
    id: 'solar',
    symbol: 'solar',
    name: 'Solar',
  },
  {
    id: 'solarbeam',
    symbol: 'solar',
    name: 'Solarbeam',
  },
  {
    id: 'solar-bear',
    symbol: 'solbear',
    name: 'Solar Bear',
  },
  {
    id: 'solarcoin',
    symbol: 'slr',
    name: 'Solarcoin',
  },
  {
    id: 'solar-dex',
    symbol: 'solar',
    name: 'Solar Dex',
  },
  {
    id: 'solar-energy',
    symbol: 'seg',
    name: 'Solar Energy',
  },
  {
    id: 'solareum-2',
    symbol: 'solar',
    name: 'Solareum',
  },
  {
    id: 'solareum-3',
    symbol: 'solareum',
    name: 'SOLAREUM',
  },
  {
    id: 'solareum-d260e488-50a0-4048-ace4-1b82f9822903',
    symbol: 'srm',
    name: 'Solareum',
  },
  {
    id: 'solareum-wallet',
    symbol: 'xsb',
    name: 'Solareum Wallet',
  },
  {
    id: 'solarflare',
    symbol: 'flare',
    name: 'Solarflare',
  },
  {
    id: 'solar-full-cycle',
    symbol: 'sfc',
    name: 'Solar Full Cycle',
  },
  {
    id: 'solarx',
    symbol: 'solx',
    name: 'SolarX',
  },
  {
    id: 'sola-token',
    symbol: 'sola',
    name: 'SOLA',
  },
  {
    id: 'solav',
    symbol: 'solav',
    name: 'SOLAV',
  },
  {
    id: 'solawave',
    symbol: 'solawave',
    name: 'Solawave',
  },
  {
    id: 'sola-x',
    symbol: 'sax',
    name: 'SOLA-X',
  },
  {
    id: 'sol-baby-doge',
    symbol: 'sbabydoge',
    name: 'SOL Baby Doge',
  },
  {
    id: 'solbank-token',
    symbol: 'sbnk',
    name: 'Solbank',
  },
  {
    id: 'solberg',
    symbol: 'slb',
    name: 'Solberg',
  },
  {
    id: 'solblaze',
    symbol: 'blze',
    name: 'Blaze',
  },
  {
    id: 'solbot',
    symbol: 'solb',
    name: 'SOLBOT',
  },
  {
    id: 'solbroe',
    symbol: 'solbroe',
    name: 'SolBroe',
  },
  {
    id: 'solcash',
    symbol: 'solcash',
    name: 'SOLCash',
  },
  {
    id: 'solcasino-token',
    symbol: 'scs',
    name: 'Solcasino Token',
  },
  {
    id: 'solcats',
    symbol: 'meow',
    name: 'Solcats',
  },
  {
    id: 'solchicks-shards',
    symbol: 'shards',
    name: 'SolChicks Shards',
  },
  {
    id: 'solchicks-token',
    symbol: 'chicks',
    name: 'SolChicks',
  },
  {
    id: 'solcial',
    symbol: 'slcl',
    name: 'Solcial',
  },
  {
    id: 'solclout',
    symbol: 'sct',
    name: 'SolClout',
  },
  {
    id: 'solcubator',
    symbol: 'solc',
    name: 'Solcubator',
  },
  {
    id: 'soldex',
    symbol: 'solx',
    name: 'Soldex',
  },
  {
    id: 'soldoge',
    symbol: 'sdoge',
    name: 'SolDoge',
  },
  {
    id: 'soldragon',
    symbol: 'dragon',
    name: 'SolDragon',
  },
  {
    id: 'solend',
    symbol: 'slnd',
    name: 'Solend',
  },
  {
    id: 'soletheon',
    symbol: 'solen',
    name: 'Soletheon',
  },
  {
    id: 'solex-finance',
    symbol: 'slx',
    name: 'Solex Finance',
  },
  {
    id: 'solfarm',
    symbol: 'tulip',
    name: 'Tulip Protocol',
  },
  {
    id: 'solfarm-2',
    symbol: 'sfarm',
    name: 'SolFarm',
  },
  {
    id: 'solge',
    symbol: 'solge',
    name: 'Solge',
  },
  {
    id: 'solice',
    symbol: 'slc',
    name: 'Solice',
  },
  {
    id: 'solidex',
    symbol: 'sex',
    name: 'Solidex',
  },
  {
    id: 'solidlizard',
    symbol: 'sliz',
    name: 'SolidLizard',
  },
  {
    id: 'solidlizard-synthetic-usd',
    symbol: 'slzusdc',
    name: 'SolidLizard synthetic USD',
  },
  {
    id: 'solidlydex',
    symbol: 'solid',
    name: 'Solidly',
  },
  {
    id: 'solidus-aitech',
    symbol: 'aitech',
    name: 'Solidus Ai Tech',
  },
  {
    id: 'solimax',
    symbol: 'slm',
    name: 'SoliMax',
  },
  {
    id: 'solmash',
    symbol: 'mash',
    name: 'SolMash',
  },
  {
    id: 'solmoon-2',
    symbol: 'smoon',
    name: 'SolMoon',
  },
  {
    id: 'solmoon-bsc',
    symbol: 'smoon',
    name: 'Solmoon BSC',
  },
  {
    id: 'solnyfans',
    symbol: 'solnyfans',
    name: 'SolnyFans',
  },
  {
    id: 'solo-coin',
    symbol: 'solo',
    name: 'Sologenic',
  },
  {
    id: 'solomon-defi',
    symbol: 'slm',
    name: 'Solomon Defi',
  },
  {
    id: 'solong-the-dragon',
    symbol: 'solong',
    name: 'SOLONG The Dragon',
  },
  {
    id: 'solordi',
    symbol: 'solo',
    name: 'Solordi',
  },
  {
    id: 'solpad-finance',
    symbol: 'solpad',
    name: 'Solpad Finance',
  },
  {
    id: 'solpay-finance',
    symbol: 'solpay',
    name: 'SolPay Finance',
  },
  {
    id: 'solragon',
    symbol: 'srgn',
    name: 'SolRagon',
  },
  {
    id: 'solrazr',
    symbol: 'solr',
    name: 'RazrFi',
  },
  {
    id: 'solrise-finance',
    symbol: 'slrs',
    name: 'Solrise Finance',
  },
  {
    id: 'sols',
    symbol: 'sols',
    name: 'sols',
  },
  {
    id: 'sols-ordinals',
    symbol: 'sols',
    name: 'SOLS (Ordinals)',
  },
  {
    id: 'solster',
    symbol: 'str',
    name: 'Solster',
  },
  {
    id: 'soltato-fries',
    symbol: 'fries',
    name: 'Soltato FRIES',
  },
  {
    id: 'soltradingbot',
    symbol: 'stbot',
    name: 'SolTradingBot',
  },
  {
    id: 'solum',
    symbol: 'solum',
    name: 'Solum',
  },
  {
    id: 'solve-care',
    symbol: 'solve',
    name: 'SOLVE',
  },
  {
    id: 'solv-protocol-stusd',
    symbol: 'stusd',
    name: 'Solv Protocol stUSD',
  },
  {
    id: 'sol-wormhole',
    symbol: 'sol',
    name: 'SOL (Wormhole)',
  },
  {
    id: 'solx-gaming-guild',
    symbol: 'sgg',
    name: 'SolX Gaming Guild',
  },
  {
    id: 'solyard-finance',
    symbol: 'yard',
    name: 'Solyard Finance',
  },
  {
    id: 'solzilla',
    symbol: 'solzilla',
    name: 'Solzilla',
  },
  {
    id: 'sombra-network',
    symbol: 'smbr',
    name: 'Sombra',
  },
  {
    id: 'somee-social',
    symbol: 'somee',
    name: 'SoMee.Social',
  },
  {
    id: 'somesing',
    symbol: 'ssx',
    name: 'SOMESING Exchange',
  },
  {
    id: 'sommelier',
    symbol: 'somm',
    name: 'Sommelier',
  },
  {
    id: 'somnium-space-cubes',
    symbol: 'cube',
    name: 'Somnium Space CUBEs',
  },
  {
    id: 'sonarwatch',
    symbol: 'sonar',
    name: 'SonarWatch',
  },
  {
    id: 'sonata-network',
    symbol: 'sona',
    name: 'Sonata Network',
  },
  {
    id: 'songbird',
    symbol: 'sgb',
    name: 'Songbird',
  },
  {
    id: 'songbird-finance',
    symbol: 'sfin',
    name: 'Songbird Finance',
  },
  {
    id: 'sonic-2',
    symbol: 'sonic',
    name: 'Sonic',
  },
  {
    id: 'sonicbot',
    symbol: 'sonicbot',
    name: 'SonicBOT',
  },
  {
    id: 'sonic-hotdog',
    symbol: 'hotdog',
    name: 'Sonic',
  },
  {
    id: 'sonic-inu',
    symbol: 'sonic',
    name: 'Sonic Inu',
  },
  {
    id: 'sonicpad',
    symbol: 'snc',
    name: 'Sonicpad',
  },
  {
    id: 'sonic-sniper-bot',
    symbol: 'sonic',
    name: 'Sonic Sniper BOT',
  },
  {
    id: 'sonic-suite',
    symbol: 'sonic',
    name: 'Sonic Suite',
  },
  {
    id: 'sonik',
    symbol: 'sonik',
    name: 'SONIK',
  },
  {
    id: 'sonm',
    symbol: 'snm',
    name: 'SONM',
  },
  {
    id: 'sonne-finance',
    symbol: 'sonne',
    name: 'Sonne Finance',
  },
  {
    id: 'sonocoin',
    symbol: 'sono',
    name: 'SonoCoin',
  },
  {
    id: 'son-of-pepe',
    symbol: 'sop',
    name: 'Son Of Pepe',
  },
  {
    id: 'sonorus',
    symbol: 'sns',
    name: 'Sonorus',
  },
  {
    id: 'soonaverse',
    symbol: 'soon',
    name: 'Soonaverse',
  },
  {
    id: 'soonswap',
    symbol: 'soon',
    name: 'SoonVerse',
  },
  {
    id: 'sooooooooooooooooooooooooooooooooooooooooooooooo',
    symbol: 'sooooooooo',
    name: 'sooooooooooooooooooooooooooooooooooooooooooooooo',
  },
  {
    id: 'sopay',
    symbol: 'sop',
    name: 'SoPay',
  },
  {
    id: 'sopdap-ai',
    symbol: 'sdp',
    name: 'SOPDAP AI',
  },
  {
    id: 'sopermen',
    symbol: 'soopy',
    name: 'Sopermen',
  },
  {
    id: 'sophiaverse',
    symbol: 'soph',
    name: 'SophiaVerse',
  },
  {
    id: 'sora',
    symbol: 'xor',
    name: 'Sora',
  },
  {
    id: 'sorachancoin',
    symbol: 'sora',
    name: 'SorachanCoin',
  },
  {
    id: 'sora-synthetic-brl',
    symbol: 'xstbrl',
    name: 'SORA Synthetic BRL',
  },
  {
    id: 'sora-synthetic-btc',
    symbol: 'xstbtc',
    name: 'SORA Synthetic BTC',
  },
  {
    id: 'sora-synthetic-chf',
    symbol: 'xstchf',
    name: 'SORA Synthetic CHF',
  },
  {
    id: 'sora-synthetic-cny',
    symbol: 'xstcny',
    name: 'SORA Synthetic CNY',
  },
  {
    id: 'sora-synthetic-eur',
    symbol: 'xsteur',
    name: 'SORA Synthetic EUR',
  },
  {
    id: 'sora-synthetic-gbp',
    symbol: 'xstgbp',
    name: 'SORA Synthetic GBP',
  },
  {
    id: 'sora-synthetic-jpy',
    symbol: 'xstjpy',
    name: 'SORA Synthetic JPY',
  },
  {
    id: 'sora-synthetic-ltc',
    symbol: 'xstltc',
    name: 'SORA Synthetic LTC',
  },
  {
    id: 'sora-synthetic-rub',
    symbol: 'xstrub',
    name: 'SORA Synthetic RUB',
  },
  {
    id: 'sora-synthetics',
    symbol: 'xst',
    name: 'SORA Synthetics',
  },
  {
    id: 'sora-synthetic-usd',
    symbol: 'xstusd',
    name: 'SORA Synthetic USD',
  },
  {
    id: 'sora-synthetic-xag',
    symbol: 'xstxag',
    name: 'SORA Synthetic XAG',
  },
  {
    id: 'sora-synthetic-xau',
    symbol: 'xstxau',
    name: 'SORA Synthetic XAU',
  },
  {
    id: 'sora-validator-token',
    symbol: 'val',
    name: 'Sora Validator',
  },
  {
    id: 'sorcery-finance',
    symbol: 'sor',
    name: 'Sorcery Finance',
  },
  {
    id: 'soroosh-smart-ecosystem',
    symbol: 'sse',
    name: 'Soroosh Smart Ecosystem',
  },
  {
    id: 'soros',
    symbol: 'sor',
    name: 'Soros',
  },
  {
    id: 'soulboundid',
    symbol: 'soulb',
    name: 'SoulboundID',
  },
  {
    id: 'soul-dog-city-bones',
    symbol: 'bones',
    name: 'Soul Dogs City Bones',
  },
  {
    id: 'soulocoin',
    symbol: 'soulo',
    name: 'SouloCoin',
  },
  {
    id: 'soulsaver',
    symbol: 'soul',
    name: 'Soulsaver',
  },
  {
    id: 'soul-scanner',
    symbol: 'soul',
    name: 'Soul Scanner',
  },
  {
    id: 'soul-society',
    symbol: 'hon',
    name: 'Soul Society',
  },
  {
    id: 'soul-swap',
    symbol: 'soul',
    name: 'Soul Swap',
  },
  {
    id: 'souni-token',
    symbol: 'son',
    name: 'Souni',
  },
  {
    id: 'soup-finance',
    symbol: 'soup',
    name: 'Soup Finance',
  },
  {
    id: 'source',
    symbol: 'source',
    name: 'Source',
  },
  {
    id: 'sourceless',
    symbol: 'str',
    name: 'Sourceless',
  },
  {
    id: 'source-protocol',
    symbol: 'srcx',
    name: 'Source Protocol',
  },
  {
    id: 'southxchange-coin',
    symbol: 'sxcc',
    name: 'SouthXchange Coin',
  },
  {
    id: 'sovi-token',
    symbol: 'sovi',
    name: 'Sovi',
  },
  {
    id: 'sovryn',
    symbol: 'sov',
    name: 'Sovryn',
  },
  {
    id: 'sovryn-dollar',
    symbol: 'dllr',
    name: 'Sovryn Dollar',
  },
  {
    id: 'soy-finance',
    symbol: 'soy',
    name: 'Soy Finance',
  },
  {
    id: 'soyjak',
    symbol: 'soy',
    name: 'Soyjak',
  },
  {
    id: 'soylanamanletcaptainz',
    symbol: 'ansem',
    name: 'SoylanaManletCaptainZ',
  },
  {
    id: 'spaceai-finance',
    symbol: 'spai',
    name: 'SpaceAI Finance',
  },
  {
    id: 'spacechain-erc-20',
    symbol: 'spc',
    name: 'SpaceChain (ERC-20)',
  },
  {
    id: 'space-corsair-key',
    symbol: 'sck',
    name: 'Space Corsair Key',
  },
  {
    id: 'spacedawgs',
    symbol: 'dawgs',
    name: 'SpaceDawgs',
  },
  {
    id: 'spacefalcon',
    symbol: 'fcon',
    name: 'SpaceFalcon',
  },
  {
    id: 'spacefi',
    symbol: 'space',
    name: 'SpaceFi (Evmos)',
  },
  {
    id: 'spacefi-zksync',
    symbol: 'space',
    name: 'SpaceFi',
  },
  {
    id: 'spacegoat-token',
    symbol: 'sgt',
    name: 'SpaceGoat',
  },
  {
    id: 'spacegrime',
    symbol: 'grimex',
    name: 'SpaceGrime',
  },
  {
    id: 'space-guild-diamond-token',
    symbol: 'dnt',
    name: 'Space Guild Diamond Token',
  },
  {
    id: 'space-id',
    symbol: 'id',
    name: 'SPACE ID',
  },
  {
    id: 'space-iz',
    symbol: 'spiz',
    name: 'SPACE-iZ',
  },
  {
    id: 'spacelens',
    symbol: 'space',
    name: 'Spacelens',
  },
  {
    id: 'spacemesh',
    symbol: '$smh',
    name: 'Spacemesh',
  },
  {
    id: 'spacemine',
    symbol: 'mine',
    name: 'SpaceMine',
  },
  {
    id: 'space-misfits',
    symbol: 'smcw',
    name: 'Space Misfits',
  },
  {
    id: 'spacen',
    symbol: 'sn',
    name: 'SpaceN',
  },
  {
    id: 'space-rebase-xusd',
    symbol: 'xusd',
    name: 'Space Rebase XUSD',
  },
  {
    id: 'spaceshipx-ssx',
    symbol: 'ssx',
    name: 'SpaceShipX SSX',
  },
  {
    id: 'space-soldier',
    symbol: 'soldier',
    name: 'Space Soldier',
  },
  {
    id: 'spaceswap-milk2',
    symbol: 'milk2',
    name: 'Spaceswap MILK2',
  },
  {
    id: 'spaceswap-shake',
    symbol: 'shake',
    name: 'Spaceswap SHAKE',
  },
  {
    id: 'space-token-bsc',
    symbol: 'space',
    name: 'Space Token BSC',
  },
  {
    id: 'spacevikings',
    symbol: 'svt',
    name: 'SpaceVikings',
  },
  {
    id: 'space-xmitter',
    symbol: 'sx',
    name: 'Space Xmitter',
  },
  {
    id: 'spacexpanse',
    symbol: 'rod',
    name: 'SpaceXpanse',
  },
  {
    id: 'spacey-2025',
    symbol: 'spay',
    name: 'SpaceY 2025',
  },
  {
    id: 'spain-national-fan-token',
    symbol: 'snft',
    name: 'Spain National Football Team Fan Token',
  },
  {
    id: 'spankchain',
    symbol: 'spank',
    name: 'SpankChain',
  },
  {
    id: 'sparko',
    symbol: 'sparko',
    name: 'Sparko',
  },
  {
    id: 'sparkpoint',
    symbol: 'srk',
    name: 'SparkPoint',
  },
  {
    id: 'sparkpoint-fuel',
    symbol: 'sfuel',
    name: 'SparkPoint Fuel',
  },
  {
    id: 'sparks',
    symbol: 'spk',
    name: 'SparksPay',
  },
  {
    id: 'sparkswap',
    symbol: 'spark',
    name: 'Sparkswap',
  },
  {
    id: 'spartacus',
    symbol: 'spa',
    name: 'Spartacus',
  },
  {
    id: 'spartadex',
    symbol: 'sparta',
    name: 'SpartaDEX',
  },
  {
    id: 'spartan-protocol-token',
    symbol: 'sparta',
    name: 'Spartan Protocol',
  },
  {
    id: 'spdr-s-p-500-etf-trust-defichain',
    symbol: 'dspy',
    name: 'SPDR S&P 500 ETF Trust Defichain',
  },
  {
    id: 'speciex',
    symbol: 'spex',
    name: 'Speciex',
  },
  {
    id: 'spectra-cash',
    symbol: 'scl',
    name: 'Spectra Cash',
  },
  {
    id: 'spectre-ai',
    symbol: 'spectre',
    name: 'Spectre AI',
  },
  {
    id: 'spectrecoin',
    symbol: 'alias',
    name: 'Alias',
  },
  {
    id: 'spectresecuritycoin',
    symbol: 'xspc',
    name: 'SpectreSecurityCoin',
  },
  {
    id: 'spectrum-finance',
    symbol: 'spf',
    name: 'Spectrum Finance',
  },
  {
    id: 'spectrum-marketplace',
    symbol: 'spec',
    name: 'Spectrum Marketplace',
  },
  {
    id: 'speculate-dao',
    symbol: 'spec',
    name: 'Speculate DAO',
  },
  {
    id: 'speed-mining-service',
    symbol: 'sms',
    name: 'Speed Mining Service',
  },
  {
    id: 'speed-star-joc',
    symbol: 'joc',
    name: 'Speed Star JOC',
  },
  {
    id: 'speed-star-speed',
    symbol: 'speed',
    name: 'Speed Star SPEED',
  },
  {
    id: 'speed-star-star',
    symbol: 'star',
    name: 'Speed Star STAR',
  },
  {
    id: 'speero',
    symbol: 'speero',
    name: 'Speero',
  },
  {
    id: 'spellfire',
    symbol: 'spellfire',
    name: 'Spellfire',
  },
  {
    id: 'spell-token',
    symbol: 'spell',
    name: 'Spell',
  },
  {
    id: 'sperax',
    symbol: 'spa',
    name: 'Sperax',
  },
  {
    id: 'sperax-usd',
    symbol: 'usds',
    name: 'Sperax USD',
  },
  {
    id: 'spgbb',
    symbol: 'spgbb',
    name: 'SPGBB',
  },
  {
    id: 'sphere-finance',
    symbol: 'sphere',
    name: 'Sphere Finance',
  },
  {
    id: 'spheresxs',
    symbol: 'sxs',
    name: 'SphereSXS',
  },
  {
    id: 'spherium',
    symbol: 'sphri',
    name: 'Spherium',
  },
  {
    id: 'spheroid-universe',
    symbol: 'sph',
    name: 'Spheroid Universe',
  },
  {
    id: 'sphynx-labs-bae5b42e-5e37-4607-8691-b56d3a5f344c',
    symbol: 'sphynx',
    name: 'Sphynx Labs',
  },
  {
    id: 'spice',
    symbol: 'spice',
    name: 'Spice Token',
  },
  {
    id: 'spice-trade',
    symbol: 'spice',
    name: 'Spice Trade',
  },
  {
    id: 'spiceusd',
    symbol: 'usds',
    name: 'SpiceUSD',
  },
  {
    id: 'spider-cat',
    symbol: 'spidercat',
    name: 'Spider-Cat',
  },
  {
    id: 'spiderdao',
    symbol: 'spdr',
    name: 'SpiderDAO',
  },
  {
    id: 'spider-spirit',
    symbol: 'spider',
    name: 'Spider Spirit',
  },
  {
    id: 'spiderswap',
    symbol: 'spdr',
    name: 'SpiderSwap',
  },
  {
    id: 'spillways',
    symbol: 'spillways',
    name: 'Spillways',
  },
  {
    id: 'spinada-cash',
    symbol: 'spin',
    name: 'Spinada Cash',
  },
  {
    id: 'spinaq',
    symbol: 'spinaq',
    name: 'Spinaq',
  },
  {
    id: 'spin-fi',
    symbol: '$spin',
    name: 'Spin Fi',
  },
  {
    id: 'spintop',
    symbol: 'spin',
    name: 'Spintop',
  },
  {
    id: 'spiraldao-coil',
    symbol: 'coil',
    name: 'SpiralDAO Coil',
  },
  {
    id: 'spiral-dao-staked-coil',
    symbol: 'spr',
    name: 'Spiral',
  },
  {
    id: 'spiritswap',
    symbol: 'spirit',
    name: 'SpiritSwap',
  },
  {
    id: 'splinterlands',
    symbol: 'sps',
    name: 'Splintershards',
  },
  {
    id: 'splyt',
    symbol: 'shopx',
    name: 'SHOPX',
  },
  {
    id: 'spodermen',
    symbol: 'spoody',
    name: 'Spodermen',
  },
  {
    id: 'sponge-f08b2fe4-9d9c-47c3-b5a0-84c2ac3bbbff',
    symbol: '$sponge',
    name: 'Sponge',
  },
  {
    id: 'spookyshiba-2',
    symbol: 'spky',
    name: 'SpookyShiba',
  },
  {
    id: 'spookyswap',
    symbol: 'boo',
    name: 'Spookyswap',
  },
  {
    id: 'spookyz',
    symbol: 'spz',
    name: 'SpookyZ',
  },
  {
    id: 'spool-dao-token',
    symbol: 'spool',
    name: 'Spool',
  },
  {
    id: 'spoony',
    symbol: 'spoon',
    name: 'Spoony',
  },
  {
    id: 'spore',
    symbol: 'spore',
    name: 'Spore',
  },
  {
    id: 'spores-network',
    symbol: 'spo',
    name: 'Spores Network',
  },
  {
    id: 'sporkdao',
    symbol: 'spork',
    name: 'SporkDAO',
  },
  {
    id: 'sport',
    symbol: 'sport',
    name: 'SPORT',
  },
  {
    id: 'sportium',
    symbol: 'sprt',
    name: 'Sportium',
  },
  {
    id: 'sports-artificial',
    symbol: 'sports-ai',
    name: 'Sports Artificial',
  },
  {
    id: 'sports-bet',
    symbol: 'sbet',
    name: 'Sports Bet',
  },
  {
    id: 'sportsicon',
    symbol: '$icons',
    name: 'SportsIcon',
  },
  {
    id: 'sportzchain',
    symbol: 'spn',
    name: 'Sportzchain',
  },
  {
    id: 'spot',
    symbol: 'spot',
    name: 'Spot',
  },
  {
    id: 'spotted-turtle',
    symbol: 'st',
    name: 'Spotted Turtle',
  },
  {
    id: 'spowars',
    symbol: 'sow',
    name: 'SPOWARS',
  },
  {
    id: 'spread-wisdom',
    symbol: 'swim',
    name: 'Spread Wisdom',
  },
  {
    id: 'spring',
    symbol: 'spring',
    name: 'Spring Token',
  },
  {
    id: 'sprink',
    symbol: 'sprink',
    name: 'Sprink',
  },
  {
    id: 'sprint-2',
    symbol: 'swp',
    name: 'Sprint',
  },
  {
    id: 'sprint-coin',
    symbol: 'sprx',
    name: 'Sprint Coin',
  },
  {
    id: 'spritzmoon-crypto',
    symbol: 'spritzmoon',
    name: 'SpritzMoon Crypto Token',
  },
  {
    id: 'spume',
    symbol: 'spume',
    name: 'Spume',
  },
  {
    id: 'spurdex',
    symbol: 'spdx',
    name: 'SpurDex',
  },
  {
    id: 'spurdo',
    symbol: 'spurdo',
    name: 'Spurdo',
  },
  {
    id: 'spurdo-sparde',
    symbol: 'spurdo',
    name: 'Spurdo Spärde',
  },
  {
    id: 'spx6900',
    symbol: 'spx',
    name: 'SPX6900',
  },
  {
    id: 'spyrolana',
    symbol: 'spyro',
    name: 'Spyrolana',
  },
  {
    id: 'sqgl-vault-nftx',
    symbol: 'sqgl',
    name: 'SQGL Vault (NFTX)',
  },
  {
    id: 'sqts-ordinals',
    symbol: 'sqts',
    name: 'SQTS (Ordinals)',
  },
  {
    id: 'squad',
    symbol: 'squad',
    name: 'Superpower Squad',
  },
  {
    id: 'squadfund',
    symbol: 'sqf',
    name: 'SquadFund',
  },
  {
    id: 'squadswap',
    symbol: 'squad',
    name: 'SquadSwap',
  },
  {
    id: 'squid-game',
    symbol: 'squid',
    name: 'Squid Game',
  },
  {
    id: 'squid-game-2',
    symbol: 'squid',
    name: 'Squid Game',
  },
  {
    id: 'squid-game-2-0',
    symbol: 'squid2',
    name: 'Squid Game 2.0',
  },
  {
    id: 'squidgrow',
    symbol: 'squidgrow',
    name: 'SquidGrow',
  },
  {
    id: 'srune',
    symbol: 'srune',
    name: 'sRUNE',
  },
  {
    id: 'ssv-network',
    symbol: 'ssv',
    name: 'SSV Network',
  },
  {
    id: 'stabilize',
    symbol: 'stbz',
    name: 'Stabilize',
  },
  {
    id: 'stable-asset',
    symbol: 'sta',
    name: 'STABLE ASSET',
  },
  {
    id: 'stablecoin',
    symbol: 'stable',
    name: 'Stablecoin',
  },
  {
    id: 'stablecomp',
    symbol: 'scomp',
    name: 'Stablecomp',
  },
  {
    id: 'stabledoc-token',
    symbol: 'sdt',
    name: 'Stabledoc',
  },
  {
    id: 'stableusd',
    symbol: 'usds',
    name: 'USDS Classic',
  },
  {
    id: 'stable-usdlr',
    symbol: 'usdlr',
    name: 'Stable USDLR',
  },
  {
    id: 'stabl-fi',
    symbol: 'cash',
    name: 'Stabl.fi CASH',
  },
  {
    id: 'stably-cusd',
    symbol: 'cusd',
    name: 'CUSD',
  },
  {
    id: 'stablz',
    symbol: 'stablz',
    name: 'Stablz',
  },
  {
    id: 'stack',
    symbol: 'stack',
    name: 'Stack',
  },
  {
    id: 'stacker-ai',
    symbol: '$stack',
    name: 'STACKER AI',
  },
  {
    id: 'stackos',
    symbol: 'sfx',
    name: 'StackOS',
  },
  {
    id: 'stacks',
    symbol: 'stacks',
    name: 'STACKS',
  },
  {
    id: 'stackswap',
    symbol: 'stsw',
    name: 'Stackswap',
  },
  {
    id: 'stacktical',
    symbol: 'dsla',
    name: 'DSLA Protocol',
  },
  {
    id: 'stade-francais-paris-fan-token',
    symbol: 'sfp',
    name: 'Stade Français Paris Fan Token',
  },
  {
    id: 'stader',
    symbol: 'sd',
    name: 'Stader',
  },
  {
    id: 'stader-bnbx',
    symbol: 'bnbx',
    name: 'Stader BNBx',
  },
  {
    id: 'stader-ethx',
    symbol: 'ethx',
    name: 'Stader ETHx',
  },
  {
    id: 'stader-maticx',
    symbol: 'maticx',
    name: 'Stader MaticX',
  },
  {
    id: 'stader-nearx',
    symbol: 'nearx',
    name: 'Stader NearX',
  },
  {
    id: 'stader-sftmx',
    symbol: 'sftmx',
    name: 'Stader sFTMX',
  },
  {
    id: 'stafi',
    symbol: 'fis',
    name: 'Stafi',
  },
  {
    id: 'stafi-staked-atom',
    symbol: 'ratom',
    name: 'StaFi Staked ATOM',
  },
  {
    id: 'stafi-staked-bnb',
    symbol: 'rbnb',
    name: 'StaFi Staked BNB',
  },
  {
    id: 'stafi-staked-matic',
    symbol: 'rmatic',
    name: 'StaFi Staked MATIC',
  },
  {
    id: 'stafi-staked-sol',
    symbol: 'rsol',
    name: 'StaFi Staked SOL',
  },
  {
    id: 'stafi-staked-swth',
    symbol: 'rswth',
    name: 'StaFi Staked SWTH',
  },
  {
    id: 'staika',
    symbol: 'stik',
    name: 'Staika',
  },
  {
    id: 'stakeborg-dao',
    symbol: 'standard',
    name: 'Construct',
  },
  {
    id: 'stakecube',
    symbol: 'scc',
    name: 'Stakecube',
  },
  {
    id: 'staked-aave-balancer-pool-token',
    symbol: 'stkabpt',
    name: 'Staked Aave Balancer Pool Token',
  },
  {
    id: 'staked-ageur',
    symbol: 'steur',
    name: 'Staked agEUR',
  },
  {
    id: 'stake-dao',
    symbol: 'sdt',
    name: 'Stake DAO',
  },
  {
    id: 'stake-dao-crv',
    symbol: 'sdcrv',
    name: 'Stake DAO CRV',
  },
  {
    id: 'staked-aurora',
    symbol: 'staur',
    name: 'Staked Aurora',
  },
  {
    id: 'staked-bifi',
    symbol: 'moobifi',
    name: 'Staked BIFI',
  },
  {
    id: 'staked-core',
    symbol: 'score',
    name: 'Staked CORE',
  },
  {
    id: 'staked-ether',
    symbol: 'steth',
    name: 'Lido Staked Ether',
  },
  {
    id: 'staked-ethos-reserve-note',
    symbol: 'stern',
    name: 'Staked Ethos Reserve Note',
  },
  {
    id: 'staked-frax-ether',
    symbol: 'sfrxeth',
    name: 'Staked Frax Ether',
  },
  {
    id: 'staked-fx',
    symbol: 'stfx',
    name: 'Staked FX',
  },
  {
    id: 'staked-hope',
    symbol: 'sthope',
    name: 'Staked HOPE',
  },
  {
    id: 'staked-kcs',
    symbol: 'skcs',
    name: 'Staked KCS',
  },
  {
    id: 'staked-near',
    symbol: 'stnear',
    name: 'Staked NEAR',
  },
  {
    id: 'staked-tarot',
    symbol: 'xtarot',
    name: 'Staked TAROT',
  },
  {
    id: 'staked-thala-apt',
    symbol: 'sthapt',
    name: 'Staked Thala APT',
  },
  {
    id: 'staked-tlos',
    symbol: 'stlos',
    name: 'Staked TLOS',
  },
  {
    id: 'staked-trx',
    symbol: 'strx',
    name: 'Staked TRX',
  },
  {
    id: 'staked-usdt',
    symbol: 'stusdt',
    name: 'Staked USDT',
  },
  {
    id: 'staked-vlx',
    symbol: 'stvlx',
    name: 'Staked VLX',
  },
  {
    id: 'staked-yearn-crv-vault',
    symbol: 'st-ycrv',
    name: 'Staked Yearn CRV Vault',
  },
  {
    id: 'staked-yearn-ether',
    symbol: 'st-yeth',
    name: 'Staked Yearn Ether',
  },
  {
    id: 'stakehouse-deth',
    symbol: 'deth',
    name: 'Stakehouse dETH',
  },
  {
    id: 'stakehouse-keth',
    symbol: 'keth',
    name: 'Stakehouse kETH',
  },
  {
    id: 'stake-link',
    symbol: 'sdl',
    name: 'stake.link',
  },
  {
    id: 'stake-link-staked-link',
    symbol: 'stlink',
    name: 'Staked LINK',
  },
  {
    id: 'stakestone-ether',
    symbol: 'stone',
    name: 'StakeStone ETH',
  },
  {
    id: 'stake-together',
    symbol: 'stpeth',
    name: 'Stake Together',
  },
  {
    id: 'stakewise',
    symbol: 'swise',
    name: 'StakeWise',
  },
  {
    id: 'stakewise-v3-oseth',
    symbol: 'oseth',
    name: 'StakeWise Staked ETH',
  },
  {
    id: 'stakify-finance',
    symbol: 'sify',
    name: 'Stakify Finance',
  },
  {
    id: 'stamen-tellus-token',
    symbol: 'stt',
    name: 'Stamen Tellus Token',
  },
  {
    id: 'stamp-2',
    symbol: 'stamp',
    name: 'STAMP',
  },
  {
    id: 'stampmap',
    symbol: 'stmap',
    name: 'StampMap',
  },
  {
    id: 'standard-protocol',
    symbol: 'stnd',
    name: 'Standard Protocol',
  },
  {
    id: 'standard-token',
    symbol: 'tst',
    name: 'The Standard Token',
  },
  {
    id: 'stan-token',
    symbol: 'stan',
    name: 'STAN Token',
  },
  {
    id: 'star-atlas',
    symbol: 'atlas',
    name: 'Star Atlas',
  },
  {
    id: 'star-atlas-dao',
    symbol: 'polis',
    name: 'Star Atlas DAO',
  },
  {
    id: 'starbot',
    symbol: 'star',
    name: 'Starbot',
  },
  {
    id: 'starbots',
    symbol: 'bot',
    name: 'Starbots',
  },
  {
    id: 'starchain',
    symbol: 'stc',
    name: 'StarChain',
  },
  {
    id: 'starcoin',
    symbol: 'stc',
    name: 'Starcoin',
  },
  {
    id: 'starfish-finance',
    symbol: 'sean',
    name: 'Starfish Finance',
  },
  {
    id: 'stargate-finance',
    symbol: 'stg',
    name: 'Stargate Finance',
  },
  {
    id: 'stargaze',
    symbol: 'stars',
    name: 'Stargaze',
  },
  {
    id: 'starheroes',
    symbol: 'star',
    name: 'StarHeroes',
  },
  {
    id: 'stark-inu',
    symbol: 'starkinu',
    name: 'Stark Inu',
  },
  {
    id: 'starkmeta',
    symbol: 'smeta',
    name: 'StarkMeta',
  },
  {
    id: 'starknet',
    symbol: 'strk',
    name: 'Starknet',
  },
  {
    id: 'stark-owl',
    symbol: 'owl',
    name: 'Stark Owl',
  },
  {
    id: 'starkpepe',
    symbol: 'spepe',
    name: 'StarkPepe',
  },
  {
    id: 'starkpunks',
    symbol: 'punk',
    name: 'Starkpunks',
  },
  {
    id: 'starlaunch',
    symbol: 'stars',
    name: 'StarLaunch',
  },
  {
    id: 'starlay-finance',
    symbol: 'lay',
    name: 'Starlay Finance',
  },
  {
    id: 'starlink',
    symbol: 'starl',
    name: 'StarLink',
  },
  {
    id: 'starlink-program',
    symbol: 'slk',
    name: 'Starlink Program',
  },
  {
    id: 'starly',
    symbol: 'starly',
    name: 'Starly',
  },
  {
    id: 'starmon-token',
    symbol: 'smon',
    name: 'StarMon',
  },
  {
    id: 'starname',
    symbol: 'iov',
    name: 'Starname',
  },
  {
    id: 'starpad',
    symbol: 'srp',
    name: 'Starpad',
  },
  {
    id: 'star-quacks',
    symbol: 'quacks',
    name: 'STAR QUACKS',
  },
  {
    id: 'starry',
    symbol: 'starry',
    name: 'Starry',
  },
  {
    id: 'stars',
    symbol: 'srx',
    name: 'Stars',
  },
  {
    id: 'starsharks',
    symbol: 'sss',
    name: 'StarSharks',
  },
  {
    id: 'starship',
    symbol: 'starship',
    name: 'StarShip',
  },
  {
    id: 'starship-2',
    symbol: 'starship',
    name: 'Starship',
  },
  {
    id: 'starship-3',
    symbol: 'ssp',
    name: 'Starship',
  },
  {
    id: 'starship-4',
    symbol: 'stship',
    name: 'StarShip🚀',
  },
  {
    id: 'starship-ai',
    symbol: 'spai',
    name: 'Starship AI',
  },
  {
    id: 'starship-erc20',
    symbol: 'sship',
    name: 'StarShip ERC20',
  },
  {
    id: 'starslax',
    symbol: 'sslx',
    name: 'StarSlax',
  },
  {
    id: 'startuperscoin',
    symbol: 'star',
    name: 'Startupers',
  },
  {
    id: 'star-wars-cat',
    symbol: 'swcat',
    name: 'Star Wars Cat',
  },
  {
    id: 'starworks-global-ecosystem',
    symbol: 'starx',
    name: 'STARX',
  },
  {
    id: 'stasis-eurs',
    symbol: 'eurs',
    name: 'STASIS EURO',
  },
  {
    id: 'stasis-network',
    symbol: 'sts',
    name: 'Stasis Network',
  },
  {
    id: 'stat',
    symbol: 'stat',
    name: 'STAT',
  },
  {
    id: 'statera',
    symbol: 'sta',
    name: 'Statera',
  },
  {
    id: 'statik',
    symbol: 'statik',
    name: 'Statik',
  },
  {
    id: 'sta-token',
    symbol: 'sta',
    name: 'STA',
  },
  {
    id: 'stats',
    symbol: 'stats',
    name: 'STATS',
  },
  {
    id: 'status',
    symbol: 'snt',
    name: 'Status',
  },
  {
    id: 'stay',
    symbol: 'stay',
    name: 'STAY',
  },
  {
    id: 'staysafu',
    symbol: 'safu',
    name: 'StaySAFU',
  },
  {
    id: 'steakd',
    symbol: 'sdx',
    name: 'Steakd',
  },
  {
    id: 'steakhut-finance',
    symbol: 'steak',
    name: 'SteakHut Finance',
  },
  {
    id: 'stealthcoin',
    symbol: 'xst',
    name: 'Stealth',
  },
  {
    id: 'stealth-deals',
    symbol: 'deal',
    name: 'Stealth Deals',
  },
  {
    id: 'stealthpad',
    symbol: 'stealth',
    name: 'StealthPad',
  },
  {
    id: 'steam-exchange',
    symbol: 'steamx',
    name: 'Steam Exchange',
  },
  {
    id: 'steem',
    symbol: 'steem',
    name: 'Steem',
  },
  {
    id: 'steem-dollars',
    symbol: 'sbd',
    name: 'Steem Dollars',
  },
  {
    id: 'stella-2',
    symbol: 'stl',
    name: 'Stella',
  },
  {
    id: 'stella-fantasy-token',
    symbol: 'sfty',
    name: 'Stella Fantasy Token',
  },
  {
    id: 'stellar',
    symbol: 'xlm',
    name: 'Stellar',
  },
  {
    id: 'stellaswap',
    symbol: 'stella',
    name: 'StellaSwap',
  },
  {
    id: 'stellaswap-staked-dot',
    symbol: 'stdot',
    name: 'StellaSwap Staked DOT',
  },
  {
    id: 'stellite',
    symbol: 'xla',
    name: 'Scala',
  },
  {
    id: 'stem-ai',
    symbol: 'stem',
    name: 'Stem AI',
  },
  {
    id: 'stemx',
    symbol: 'stemx',
    name: 'STEMX',
  },
  {
    id: 'step',
    symbol: 'step',
    name: 'Step',
  },
  {
    id: 'step-app-fitfi',
    symbol: 'fitfi',
    name: 'Step App',
  },
  {
    id: 'stepex',
    symbol: 'spex',
    name: 'StepEx',
  },
  {
    id: 'step-finance',
    symbol: 'step',
    name: 'Step Finance',
  },
  {
    id: 'step-hero',
    symbol: 'hero',
    name: 'Step Hero',
  },
  {
    id: 'stepn',
    symbol: 'gmt',
    name: 'GMT',
  },
  {
    id: 'stereoai',
    symbol: 'stai',
    name: 'StereoAI',
  },
  {
    id: 'sterling-finance',
    symbol: 'str',
    name: 'Sterling Finance',
  },
  {
    id: 'stfx',
    symbol: 'stfx',
    name: 'STFX',
  },
  {
    id: 'stilton',
    symbol: 'stilt',
    name: 'Stilton',
  },
  {
    id: 'stima',
    symbol: 'stima',
    name: 'STIMA',
  },
  {
    id: 'stkatom',
    symbol: 'stkatom',
    name: 'stkATOM',
  },
  {
    id: 'stkd-scrt',
    symbol: 'stkd',
    name: 'Stkd SCRT',
  },
  {
    id: 'stobox-token',
    symbol: 'stbu',
    name: 'Stobox',
  },
  {
    id: 'stohn-coin',
    symbol: 'soh',
    name: 'Stohn Coin',
  },
  {
    id: 'stoicdao',
    symbol: 'zeta',
    name: 'stoicDAO',
  },
  {
    id: 'ston',
    symbol: 'ston',
    name: 'Ston',
  },
  {
    id: 'ston-2',
    symbol: 'ston',
    name: 'STON',
  },
  {
    id: 'stoned',
    symbol: 'stoned',
    name: 'STONED',
  },
  {
    id: 'stonkmememan',
    symbol: 'stonks',
    name: 'StonkMemeMan',
  },
  {
    id: 'stonks-2',
    symbol: 'stonks',
    name: 'STONKS',
  },
  {
    id: 'stonks-3',
    symbol: 'stonks',
    name: 'sTONks',
  },
  {
    id: 'stonks-bot',
    symbol: '$stonks',
    name: 'Stonks Bot',
  },
  {
    id: 'stonksdao',
    symbol: 'stonks',
    name: 'STONKSDAO',
  },
  {
    id: 'stopelon',
    symbol: 'stopelon',
    name: 'StopElon',
  },
  {
    id: 'storagechain',
    symbol: 'wstor',
    name: 'StorageChain',
  },
  {
    id: 'storepay',
    symbol: 'spc',
    name: 'Storepay',
  },
  {
    id: 'storex',
    symbol: 'strx',
    name: 'Storex',
  },
  {
    id: 'storj',
    symbol: 'storj',
    name: 'Storj',
  },
  {
    id: 'storm',
    symbol: 'stmx',
    name: 'StormX',
  },
  {
    id: 'storm-token',
    symbol: 'storm',
    name: 'Storm',
  },
  {
    id: 'storx',
    symbol: 'srx',
    name: 'StorX',
  },
  {
    id: 'story',
    symbol: 'story',
    name: 'Story',
  },
  {
    id: 'stox',
    symbol: 'stx',
    name: 'Stox',
  },
  {
    id: 'stp-network',
    symbol: 'stpt',
    name: 'STP',
  },
  {
    id: 'straitsx-indonesia-rupiah',
    symbol: 'xidr',
    name: 'XIDR',
  },
  {
    id: 'stratis',
    symbol: 'strax',
    name: 'Stratis',
  },
  {
    id: 'stratos',
    symbol: 'stos',
    name: 'Stratos',
  },
  {
    id: 'stratum-exchange',
    symbol: 'strat',
    name: 'Stratum Exchange',
  },
  {
    id: 'strawberry-elephant',
    symbol: 'صباح الفر',
    name: 'Strawberry Elephant',
  },
  {
    id: 'strch-token',
    symbol: 'strch',
    name: 'STRCH Token',
  },
  {
    id: 'streakk-chain',
    symbol: 'stkc',
    name: 'Streakk Chain',
  },
  {
    id: 'streamcoin',
    symbol: 'strm',
    name: 'StreamCoin',
  },
  {
    id: 'streamer-inu',
    symbol: 'streamerinu',
    name: 'Streamer Inu',
  },
  {
    id: 'streamr',
    symbol: 'data',
    name: 'Streamr',
  },
  {
    id: 'streamr-xdata',
    symbol: 'xdata',
    name: 'Streamr XDATA',
  },
  {
    id: 'streeth',
    symbol: 'streeth',
    name: 'STREETH',
  },
  {
    id: 'street-runner',
    symbol: 'srg',
    name: 'Street Runner',
  },
  {
    id: 'strelka-ai',
    symbol: 'strelka ai',
    name: 'Strelka AI',
  },
  {
    id: 'stride',
    symbol: 'strd',
    name: 'Stride',
  },
  {
    id: 'stride-staked-atom',
    symbol: 'statom',
    name: 'Stride Staked Atom',
  },
  {
    id: 'stride-staked-comdex',
    symbol: 'stcmdx',
    name: 'Stride Staked Comdex',
  },
  {
    id: 'stride-staked-dydx',
    symbol: 'stdydx',
    name: 'Stride Staked DYDX',
  },
  {
    id: 'stride-staked-evmos',
    symbol: 'stevmos',
    name: 'Stride Staked Evmos',
  },
  {
    id: 'stride-staked-injective',
    symbol: 'stinj',
    name: 'Stride Staked Injective',
  },
  {
    id: 'stride-staked-juno',
    symbol: 'stjuno',
    name: 'Stride Staked Juno',
  },
  {
    id: 'stride-staked-luna',
    symbol: '$stluna',
    name: 'Stride Staked Luna',
  },
  {
    id: 'stride-staked-osmo',
    symbol: 'stosmo',
    name: 'Stride Staked Osmo',
  },
  {
    id: 'stride-staked-sommelier',
    symbol: 'stsomm',
    name: 'Stride Staked Sommelier',
  },
  {
    id: 'stride-staked-stars',
    symbol: 'ststars',
    name: 'Stride Staked Stars',
  },
  {
    id: 'stride-staked-tia',
    symbol: 'sttia',
    name: 'Stride Staked TIA',
  },
  {
    id: 'stride-staked-umee',
    symbol: 'stumee',
    name: 'Stride Staked Umee',
  },
  {
    id: 'strike',
    symbol: 'strk',
    name: 'Strike',
  },
  {
    id: 'strikecoin',
    symbol: 'strx',
    name: 'StrikeX',
  },
  {
    id: 'strip-finance',
    symbol: 'strip',
    name: 'Strip Finance',
  },
  {
    id: 'strips-finance',
    symbol: 'strp',
    name: 'Strips Finance',
  },
  {
    id: 'stripto',
    symbol: 'strip',
    name: 'Stripto',
  },
  {
    id: 'strix',
    symbol: 'strix',
    name: 'Strix',
  },
  {
    id: 'stroke-prevention-genomicdao',
    symbol: 'pcsp',
    name: 'Stroke-Prevention GenomicDAO',
  },
  {
    id: 'strong',
    symbol: 'strong',
    name: 'Strong',
  },
  {
    id: 'stronger',
    symbol: 'strngr',
    name: 'Stronger',
  },
  {
    id: 'stronghands-finance',
    symbol: 'ishnd',
    name: 'StrongHands Finance',
  },
  {
    id: 'stronghold-token',
    symbol: 'shx',
    name: 'Stronghold',
  },
  {
    id: 'strongnode',
    symbol: 'sne',
    name: 'StrongNode',
  },
  {
    id: 'structure-finance',
    symbol: 'stf',
    name: 'Structure Finance',
  },
  {
    id: 'strx-finance',
    symbol: 'sfi',
    name: 'STRX Finance',
  },
  {
    id: 'student-coin',
    symbol: 'stc',
    name: 'Student Coin',
  },
  {
    id: 'studioai',
    symbol: 'sai',
    name: 'StudioAi',
  },
  {
    id: 'sturdy',
    symbol: 'strdy',
    name: 'Sturdy',
  },
  {
    id: 'style',
    symbol: 'style',
    name: 'Style',
  },
  {
    id: 'stzil',
    symbol: 'stzil',
    name: 'stZIL',
  },
  {
    id: 'suave',
    symbol: 'cce',
    name: 'Suave',
  },
  {
    id: 'subava-token',
    symbol: 'subava',
    name: 'Subava Token',
  },
  {
    id: 'subdao',
    symbol: 'gov',
    name: 'SubDAO',
  },
  {
    id: 'subi-network',
    symbol: 'subi',
    name: 'Subi Network',
  },
  {
    id: 'subquery-network',
    symbol: 'sqt',
    name: 'SubQuery Network',
  },
  {
    id: 'subsocial',
    symbol: 'sub',
    name: 'Subsocial',
  },
  {
    id: 'substratum',
    symbol: 'sub',
    name: 'Substratum',
  },
  {
    id: 'succession',
    symbol: 'sccn',
    name: 'Succession',
  },
  {
    id: 'sucrecoin',
    symbol: 'xsr',
    name: 'Sucrecoin',
  },
  {
    id: 'sudoswap',
    symbol: 'sudo',
    name: 'sudoswap',
  },
  {
    id: 'sugarbaby',
    symbol: 'sugar',
    name: 'Sugarbaby',
  },
  {
    id: 'sugarbounce',
    symbol: 'tip',
    name: 'SugarBounce',
  },
  {
    id: 'sugarchain',
    symbol: 'sugar',
    name: 'Sugarchain',
  },
  {
    id: 'sugaryield',
    symbol: 'sugar',
    name: 'SugarYield',
  },
  {
    id: 'sui',
    symbol: 'sui',
    name: 'Sui',
  },
  {
    id: 'suia',
    symbol: 'suia',
    name: 'SUIA',
  },
  {
    id: 'suiboxer',
    symbol: 'sbox',
    name: 'SUIBoxer',
  },
  {
    id: 'sui-iou',
    symbol: 'sui',
    name: 'Sui (IOU)',
  },
  {
    id: 'suijin',
    symbol: 'sin',
    name: 'Suijin',
  },
  {
    id: 'sui-launch-token',
    symbol: 'slt',
    name: 'Sui Launch Token',
  },
  {
    id: 'suipad',
    symbol: 'suip',
    name: 'SuiPad',
  },
  {
    id: 'suipepe',
    symbol: 'spepe',
    name: 'SuiPepe',
  },
  {
    id: 'sui-pepe',
    symbol: 'spepe',
    name: 'Sui Pepe',
  },
  {
    id: 'suishiba',
    symbol: 'suishib',
    name: 'SuiShiba',
  },
  {
    id: 'suiswap',
    symbol: 'sswp',
    name: 'Suiswap',
  },
  {
    id: 'suitable',
    symbol: 'table',
    name: 'Suitable',
  },
  {
    id: 'suite',
    symbol: 'suite',
    name: 'Suite',
  },
  {
    id: 'suitizen',
    symbol: 'stz',
    name: 'Suitizen',
  },
  {
    id: 'suizuki',
    symbol: 'zuki',
    name: 'Suizuki',
  },
  {
    id: 'sukhavati-network',
    symbol: 'skt',
    name: 'Sukhavati Network',
  },
  {
    id: 'suku',
    symbol: 'suku',
    name: 'SUKU',
  },
  {
    id: 'sumcoin',
    symbol: 'sum',
    name: 'Sumcoin',
  },
  {
    id: 'sumer-money-subtc',
    symbol: 'subtc',
    name: 'Sumer.Money suBTC',
  },
  {
    id: 'sumer-money-sueth',
    symbol: 'sueth',
    name: 'Sumer.Money suETH',
  },
  {
    id: 'sumer-money-suusd',
    symbol: 'suusd',
    name: 'Sumer.Money suUSD',
  },
  {
    id: 'summer',
    symbol: 'summer',
    name: 'Summer',
  },
  {
    id: 'sumokoin',
    symbol: 'sumo',
    name: 'Sumokoin',
  },
  {
    id: 'sumotex',
    symbol: 'smtx',
    name: 'SUMOTEX',
  },
  {
    id: 'sunala',
    symbol: 'sun',
    name: 'Sunala',
  },
  {
    id: 'suncontract',
    symbol: 'snc',
    name: 'SunContract',
  },
  {
    id: 'sundaeswap',
    symbol: 'sundae',
    name: 'SundaeSwap',
  },
  {
    id: 'sundae-the-dog',
    symbol: 'sundae',
    name: 'Sundae the Dog',
  },
  {
    id: 'sunflower-land',
    symbol: 'sfl',
    name: 'Sunflower Land',
  },
  {
    id: 'sunny-aggregator',
    symbol: 'sunny',
    name: 'Sunny Aggregator',
  },
  {
    id: 'sunnysideup',
    symbol: 'ssu',
    name: 'SunnySideUp',
  },
  {
    id: 'sunrise',
    symbol: 'sunc',
    name: 'Sunrise',
  },
  {
    id: 'sun-token',
    symbol: 'sun',
    name: 'Sun Token',
  },
  {
    id: 'sun-tzu',
    symbol: 'tzu',
    name: 'Sun Tzu',
  },
  {
    id: 'supe-infinity',
    symbol: 'supe',
    name: 'Supe Infinity',
  },
  {
    id: 'super-athletes-token',
    symbol: 'sat',
    name: 'Super Athletes Token',
  },
  {
    id: 'superbid',
    symbol: 'superbid',
    name: 'SuperBid',
  },
  {
    id: 'supercells',
    symbol: 'sct',
    name: 'SuperCells',
  },
  {
    id: 'superciety',
    symbol: 'super',
    name: 'PeerMe SUPER',
  },
  {
    id: 'super-cycle',
    symbol: 'rich',
    name: 'Super Cycle',
  },
  {
    id: 'superdapp',
    symbol: 'supr',
    name: 'SuperDapp',
  },
  {
    id: 'superfans-tech',
    symbol: 'fan',
    name: 'SuperFans.Tech',
  },
  {
    id: 'superfarm',
    symbol: 'super',
    name: 'SuperVerse',
  },
  {
    id: 'superfrank',
    symbol: 'chfp',
    name: 'SuperFrank',
  },
  {
    id: 'superlauncher-dao',
    symbol: 'launch',
    name: 'Superlauncher',
  },
  {
    id: 'supermarioporsche911inu',
    symbol: 'silkroad',
    name: 'SuperMarioPorsche911Inu',
  },
  {
    id: 'supermarket',
    symbol: 'super',
    name: 'SuperMarket',
  },
  {
    id: 'superrare',
    symbol: 'rare',
    name: 'SuperRare',
  },
  {
    id: 'super-rare-ball-shares',
    symbol: 'srbp',
    name: 'Super Rare Ball Potion',
  },
  {
    id: 'superrarebears-hype',
    symbol: 'hype',
    name: 'SuperRareBears HYPE',
  },
  {
    id: 'superrarebears-rare',
    symbol: 'rare',
    name: 'SuperRareBears RARE',
  },
  {
    id: 'super-seiyan',
    symbol: 'superseiyan',
    name: 'Super Seiyan',
  },
  {
    id: 'superstake',
    symbol: 'superstake',
    name: 'Superstake',
  },
  {
    id: 'super-vet',
    symbol: 'svet',
    name: 'Super Vet',
  },
  {
    id: 'superwalk',
    symbol: 'grnd',
    name: 'SuperWalk',
  },
  {
    id: 'super-zero',
    symbol: 'sero',
    name: 'SERO',
  },
  {
    id: 'supreme-finance',
    symbol: 'hype',
    name: 'Supreme Finance',
  },
  {
    id: 'supreme-finance-hypes',
    symbol: 'hypes',
    name: 'Supreme Finance HYPES',
  },
  {
    id: 'suprenft',
    symbol: 'snft',
    name: 'SupreNFT',
  },
  {
    id: 'sureremit',
    symbol: 'rmt',
    name: 'SureRemit',
  },
  {
    id: 'surfboard',
    symbol: 'board',
    name: 'SURFBOARD',
  },
  {
    id: 'surfexutilitytoken',
    symbol: 'surf',
    name: 'SurfExUtilityToken',
  },
  {
    id: 'surrealverse',
    symbol: 'azee',
    name: 'SurrealVerse',
  },
  {
    id: 'surveyor-dao',
    symbol: 'surv',
    name: 'Surveyor DAO',
  },
  {
    id: 'susd-yvault',
    symbol: 'yvsusd',
    name: 'sUSD yVault',
  },
  {
    id: 'sushi',
    symbol: 'sushi',
    name: 'Sushi',
  },
  {
    id: 'sushi-yvault',
    symbol: 'yvsushi',
    name: 'SUSHI yVault',
  },
  {
    id: 'sustainable-energy-token',
    symbol: 'set',
    name: 'Sustainable Energy',
  },
  {
    id: 'suterusu',
    symbol: 'suter',
    name: 'Suterusu',
  },
  {
    id: 'suvereno',
    symbol: 'suv',
    name: 'Suvereno',
  },
  {
    id: 'suzuverse',
    symbol: 'sgt',
    name: 'Suzuverse',
  },
  {
    id: 'swag-coin',
    symbol: 'swag',
    name: 'swag coin',
  },
  {
    id: 'swag-finance',
    symbol: 'swag',
    name: 'SWAG Finance',
  },
  {
    id: 'swamp-coin',
    symbol: 'swamp',
    name: 'Swamp Coin',
  },
  {
    id: 'swap',
    symbol: 'xwp',
    name: 'Swap',
  },
  {
    id: 'swapdex',
    symbol: 'sdxb',
    name: 'SwapDEX',
  },
  {
    id: 'swapped-finance',
    symbol: 'swpd',
    name: 'Swapped Finance',
  },
  {
    id: 'swappi',
    symbol: 'ppi',
    name: 'Swappi',
  },
  {
    id: 'swapr',
    symbol: 'swpr',
    name: 'Swapr',
  },
  {
    id: 'swaprum',
    symbol: 'sapr',
    name: 'Swaprum',
  },
  {
    id: 'swapsicle-pops',
    symbol: 'pops',
    name: 'Swapsicle',
  },
  {
    id: 'swaptracker',
    symbol: 'swpt',
    name: 'SwapTracker',
  },
  {
    id: 'swapz-app',
    symbol: 'swapz',
    name: 'SWAPZ.app',
  },
  {
    id: 'swarm',
    symbol: 'swm',
    name: 'Swarm Network',
  },
  {
    id: 'swarm-bzz',
    symbol: 'bzz',
    name: 'Swarm',
  },
  {
    id: 'swarm-markets',
    symbol: 'smt',
    name: 'Swarm Markets',
  },
  {
    id: 'swash',
    symbol: 'swash',
    name: 'Swash',
  },
  {
    id: 'sway-social',
    symbol: 'sway',
    name: 'Sway Social',
  },
  {
    id: 'sweatcoin',
    symbol: 'sweat',
    name: 'Sweat Economy',
  },
  {
    id: 'sweep-token',
    symbol: 'sweep',
    name: 'Sweep Token',
  },
  {
    id: 'sweet',
    symbol: 'sweet',
    name: 'Sweet',
  },
  {
    id: 'sweets',
    symbol: '$swts',
    name: 'SWEETS',
  },
  {
    id: 'swell-network',
    symbol: 'swell',
    name: 'Swell Network',
  },
  {
    id: 'sweply',
    symbol: 'swply',
    name: 'Sweply',
  },
  {
    id: 'swerve-dao',
    symbol: 'swrv',
    name: 'Swerve',
  },
  {
    id: 'swerve-protocol',
    symbol: 'swerve',
    name: 'SWERVE Protocol',
  },
  {
    id: 'sweth',
    symbol: 'sweth',
    name: 'Swell Ethereum',
  },
  {
    id: 'swftcoin',
    symbol: 'swftc',
    name: 'SWFTCOIN',
  },
  {
    id: 'swift',
    symbol: 'swift',
    name: 'Swift',
  },
  {
    id: 'swift-bot',
    symbol: '$swift',
    name: 'Swift Bot',
  },
  {
    id: 'swiftcash',
    symbol: 'swift',
    name: 'SwiftCash',
  },
  {
    id: 'swiftpad',
    symbol: 'swift',
    name: 'SwiftPad',
  },
  {
    id: 'swiftswap',
    symbol: 'sws',
    name: 'SwiftSwap',
  },
  {
    id: 'swinca-2',
    symbol: 'swi',
    name: 'Swinca',
  },
  {
    id: 'swingby',
    symbol: 'swingby',
    name: 'Swingby',
  },
  {
    id: 'swing-xyz',
    symbol: '$swing',
    name: 'Swing.xyz',
  },
  {
    id: 'swipe',
    symbol: 'sxp',
    name: 'SXP',
  },
  {
    id: 'swipe-token',
    symbol: 'swipe',
    name: 'Swipe Token',
  },
  {
    id: 'swirl-protocol',
    symbol: 'swi',
    name: 'Swirl Protocol',
  },
  {
    id: 'swirltoken',
    symbol: 'swirl',
    name: 'SwirlToken (OLD)',
  },
  {
    id: 'swirl-token',
    symbol: 'swirl',
    name: 'SwirlToken',
  },
  {
    id: 'swissborg',
    symbol: 'borg',
    name: 'SwissBorg',
  },
  {
    id: 'swisscheese',
    symbol: 'swch',
    name: 'SwissCheese',
  },
  {
    id: 'switcheo',
    symbol: 'swth',
    name: 'Carbon Protocol',
  },
  {
    id: 'switch-token',
    symbol: 'switch',
    name: 'Switch Token',
  },
  {
    id: 'swop',
    symbol: 'swop',
    name: 'Swop',
  },
  {
    id: 'sword-and-magic-world',
    symbol: 'swo',
    name: 'Sword and Magic World',
  },
  {
    id: 'sword-bot',
    symbol: 'sword',
    name: 'Sword Bot',
  },
  {
    id: 'sword-bsc-token',
    symbol: 'swdb',
    name: 'Sword BSC Token',
  },
  {
    id: 'swords-of-blood',
    symbol: 'swdtkn',
    name: 'Swords of Blood',
  },
  {
    id: 'swtcoin',
    symbol: 'swat',
    name: 'SWTCoin',
  },
  {
    id: 'swusd',
    symbol: 'swusd',
    name: 'Swerve.fi USD',
  },
  {
    id: 'swych',
    symbol: 'swych',
    name: 'Swych',
  },
  {
    id: 'swyp-foundation',
    symbol: 'swyp',
    name: 'SWYP Foundation',
  },
  {
    id: 'sx-network',
    symbol: 'sx',
    name: 'SX Network (OLD)',
  },
  {
    id: 'sx-network-2',
    symbol: 'sx',
    name: 'SX Network',
  },
  {
    id: 'sybulls',
    symbol: 'sybl',
    name: 'Sybulls',
  },
  {
    id: 'sylo',
    symbol: 'sylo',
    name: 'Sylo',
  },
  {
    id: 'symbiosis-finance',
    symbol: 'sis',
    name: 'Symbiosis',
  },
  {
    id: 'symbol',
    symbol: 'xym',
    name: 'Symbol',
  },
  {
    id: 'symmetry-solana-lsd-fund',
    symbol: 'ysol',
    name: 'Symmetry Solana LSD Fund',
  },
  {
    id: 'symverse',
    symbol: 'sym',
    name: 'SymVerse',
  },
  {
    id: 'synapse-2',
    symbol: 'syn',
    name: 'Synapse',
  },
  {
    id: 'synapse-network',
    symbol: 'snp',
    name: 'Synapse Network',
  },
  {
    id: 'synaptic-ai',
    symbol: 'synapticai',
    name: 'Synaptic AI',
  },
  {
    id: 'synatra-staked-sol',
    symbol: 'ysol',
    name: 'Synatra Staked SOL',
  },
  {
    id: 'syncdex',
    symbol: 'sydx',
    name: 'SyncDex',
  },
  {
    id: 'synchrony',
    symbol: 'scy',
    name: 'Synchrony',
  },
  {
    id: 'synclub-staked-bnb',
    symbol: 'snbnb',
    name: 'Synclub staked BNB',
  },
  {
    id: 'sync-network',
    symbol: 'sync',
    name: 'Sync Network',
  },
  {
    id: 'syncus',
    symbol: 'sync',
    name: 'Syncus',
  },
  {
    id: 'syndicate-2',
    symbol: 'synr',
    name: 'MOBLAND',
  },
  {
    id: 'synergy-crystal',
    symbol: 'crs',
    name: 'Synergy Crystal',
  },
  {
    id: 'synergy-diamonds',
    symbol: 'dia',
    name: 'Synergy Diamonds',
  },
  {
    id: 'synergy-land-token',
    symbol: 'sng',
    name: 'Synergy Land Token',
  },
  {
    id: 'synesis-one',
    symbol: 'sns',
    name: 'Synesis One',
  },
  {
    id: 'synopti',
    symbol: 'synopti',
    name: 'Synopti',
  },
  {
    id: 'synthetic-usd',
    symbol: 'xusd',
    name: 'Synthetic USD',
  },
  {
    id: 'synthetify-token',
    symbol: 'sny',
    name: 'Synthetify',
  },
  {
    id: 'synth-ousd',
    symbol: 'ousd',
    name: 'Synth oUSD',
  },
  {
    id: 'synthswap',
    symbol: 'synth',
    name: 'Synthswap',
  },
  {
    id: 'sypool',
    symbol: 'syp',
    name: 'Sypool',
  },
  {
    id: 'syrup-finance',
    symbol: 'srx',
    name: 'Syrup Finance',
  },
  {
    id: 'syscoin',
    symbol: 'sys',
    name: 'Syscoin',
  },
  {
    id: 'szab',
    symbol: 'szab',
    name: 'SZAB',
  },
  {
    id: 't23',
    symbol: 't23',
    name: 'T23',
  },
  {
    id: 't2t2',
    symbol: 't2t2',
    name: 'T2T2',
  },
  {
    id: 't3rn',
    symbol: 'trn',
    name: 't3rn',
  },
  {
    id: 'tabank',
    symbol: 'tab',
    name: 'Tabank',
  },
  {
    id: 'tabbypos',
    symbol: 'epos',
    name: 'TabbyPOS',
  },
  {
    id: 'tabo',
    symbol: 'tabo',
    name: 'TABO',
  },
  {
    id: 'taboo-token',
    symbol: 'taboo',
    name: 'Taboo',
  },
  {
    id: 'tabtrader',
    symbol: 'ttt',
    name: 'TabTrader',
  },
  {
    id: 'taggr',
    symbol: 'taggr',
    name: 'TAGGR',
  },
  {
    id: 'taho',
    symbol: 'taho',
    name: 'Taho',
  },
  {
    id: 'tahu',
    symbol: 'tahu',
    name: 'TAHU',
  },
  {
    id: 'tai',
    symbol: 'tai',
    name: 'tBridge',
  },
  {
    id: 'taikai',
    symbol: 'tkai',
    name: 'TAIKAI',
  },
  {
    id: 'taikula-coin',
    symbol: 'taikula',
    name: 'Taikula Coin',
  },
  {
    id: 'tail',
    symbol: 'tail',
    name: 'Tail',
  },
  {
    id: 'tai-money',
    symbol: 'tai',
    name: 'TAI',
  },
  {
    id: 'takamaka-green-coin',
    symbol: 'tkg',
    name: 'Takamaka',
  },
  {
    id: 'takepile',
    symbol: 'take',
    name: 'Takepile',
  },
  {
    id: 'taki',
    symbol: 'taki',
    name: 'Taki',
  },
  {
    id: 'talaxeum',
    symbol: 'talax',
    name: 'Talaxeum',
  },
  {
    id: 'talecraft',
    symbol: 'craft',
    name: 'TaleCraft',
  },
  {
    id: 'talent',
    symbol: 'tnt',
    name: 'Talent',
  },
  {
    id: 'talent-coin',
    symbol: 'tlnt',
    name: 'Talent Coin',
  },
  {
    id: 'talentido',
    symbol: 'tal',
    name: 'TalentIDO',
  },
  {
    id: 'taler',
    symbol: 'tlr',
    name: 'Taler',
  },
  {
    id: 'talis-protocol',
    symbol: 'talis',
    name: 'Talis Protocol',
  },
  {
    id: 'talkado',
    symbol: 'talk',
    name: 'Talkado',
  },
  {
    id: 'talken',
    symbol: 'talk',
    name: 'Talken',
  },
  {
    id: 'talki',
    symbol: 'tal',
    name: 'TALKI',
  },
  {
    id: 'tamadoge',
    symbol: 'tama',
    name: 'Tamadoge',
  },
  {
    id: 'tama-finance',
    symbol: 'tama',
    name: 'Tama Finance',
  },
  {
    id: 'tamagotchi',
    symbol: 'gotchi',
    name: 'Tamagotchi',
  },
  {
    id: 'tangent',
    symbol: 'tang',
    name: 'Tangent',
  },
  {
    id: 'tangible',
    symbol: 'tngbl',
    name: 'Tangible',
  },
  {
    id: 'tangleswap-void',
    symbol: 'void',
    name: 'TangleSwap VOID',
  },
  {
    id: 'tangoswap',
    symbol: 'tango',
    name: 'TangoSwap',
  },
  {
    id: 'tangyuan',
    symbol: 'tangyuan',
    name: 'TangYuan',
  },
  {
    id: 'tank-battle',
    symbol: 'tbl',
    name: 'Tank Battle',
  },
  {
    id: 'tank-gold',
    symbol: 'tgold',
    name: 'Tank Gold',
  },
  {
    id: 'tanks',
    symbol: 'tanks',
    name: 'Tanks',
  },
  {
    id: 'tanpin',
    symbol: 'tanpin',
    name: 'TanPin',
  },
  {
    id: 'tao-inu',
    symbol: 'taonu',
    name: 'TAO INU',
  },
  {
    id: 'tao-meme',
    symbol: 'tao',
    name: 'Tao Meme',
  },
  {
    id: 'taopad',
    symbol: 'tpad',
    name: 'TaoPad',
  },
  {
    id: 'tap',
    symbol: 'xtp',
    name: 'Tap',
  },
  {
    id: 'tap-fantasy',
    symbol: 'tap',
    name: 'Tap Fantasy',
  },
  {
    id: 'tapp-coin',
    symbol: 'tpx',
    name: 'Tapp Coin',
  },
  {
    id: 'tarality',
    symbol: 'taral',
    name: 'Tarality',
  },
  {
    id: 'taraxa',
    symbol: 'tara',
    name: 'Taraxa',
  },
  {
    id: 'targetwatch-bot',
    symbol: 'twb',
    name: 'TargetWatch Bot',
  },
  {
    id: 'tari-world',
    symbol: 'tari',
    name: 'Tari World',
  },
  {
    id: 'tarmex',
    symbol: 'tarm',
    name: 'Tarmex [OLD]',
  },
  {
    id: 'tarmex-2',
    symbol: 'tarm',
    name: 'Tarmex',
  },
  {
    id: 'tarot',
    symbol: 'tarot',
    name: 'Tarot V1',
  },
  {
    id: 'tarot-2',
    symbol: 'tarot',
    name: 'Tarot',
  },
  {
    id: 'tashi',
    symbol: 'tashi',
    name: 'Tashi',
  },
  {
    id: 'tastenft',
    symbol: 'taste',
    name: 'TasteNFT',
  },
  {
    id: 'tate',
    symbol: 'tate',
    name: 'TATE',
  },
  {
    id: 'taxa-token',
    symbol: 'txt',
    name: 'Taxa Network',
  },
  {
    id: 'tax-haven-inu',
    symbol: 'taxhaveninu',
    name: 'Tax Haven Inu',
  },
  {
    id: 'taylor-swift-s-cat',
    symbol: 'benji',
    name: "Taylor Swift's Cat",
  },
  {
    id: 'tbcc',
    symbol: 'tbcc',
    name: 'TBCC',
  },
  {
    id: 'tbtc',
    symbol: 'tbtc',
    name: 'tBTC',
  },
  {
    id: 'tcgcoin-2-0',
    symbol: 'tcg2',
    name: 'TCGCoin 2.0',
  },
  {
    id: 'tcg-verse',
    symbol: 'tcgc',
    name: 'TCG Verse',
  },
  {
    id: 'tdoge',
    symbol: 'tdoge',
    name: 'τDoge',
  },
  {
    id: 'team-heretics-fan-token',
    symbol: 'th',
    name: 'Team Heretics Fan Token',
  },
  {
    id: 'teamtoken',
    symbol: 'tt',
    name: 'TeamToken',
  },
  {
    id: 'team-vitality-fan-token',
    symbol: 'vit',
    name: 'Team Vitality Fan Token',
  },
  {
    id: 'teat',
    symbol: 'teat',
    name: 'Teat',
  },
  {
    id: 'technology-metal-network-global',
    symbol: 'tmng',
    name: 'Technology Metal Network Global',
  },
  {
    id: 'techno-mechanicus',
    symbol: 'tau',
    name: 'Techno Mechanicus',
  },
  {
    id: 'tectonic',
    symbol: 'tonic',
    name: 'Tectonic',
  },
  {
    id: 'tectum',
    symbol: 'tet',
    name: 'Tectum',
  },
  {
    id: 'teddy-bear',
    symbol: 'bear',
    name: 'TEDDY BEAR',
  },
  {
    id: 'teddy-bear-inu',
    symbol: 'tbi',
    name: 'Teddy Bear INU',
  },
  {
    id: 'teddy-dollar',
    symbol: 'tsd',
    name: 'Teddy Dollar',
  },
  {
    id: 'teddyswap',
    symbol: 'tedy',
    name: 'TeddySwap',
  },
  {
    id: 'te-food',
    symbol: 'tone',
    name: 'TE-FOOD',
  },
  {
    id: 'tegisto',
    symbol: 'tgs',
    name: 'Tegisto',
  },
  {
    id: 'tehbag',
    symbol: 'bag',
    name: 'tehBag',
  },
  {
    id: 'teh-fund',
    symbol: 'fund',
    name: 'Teh Fund',
  },
  {
    id: 'teh-golden-one',
    symbol: 'gold 1',
    name: 'Teh Golden One',
  },
  {
    id: 'teia-dao',
    symbol: 'teia',
    name: 'Teia DAO',
  },
  {
    id: 'telcoin',
    symbol: 'tel',
    name: 'Telcoin',
  },
  {
    id: 'telebridge',
    symbol: 'hype',
    name: 'HYPE',
  },
  {
    id: 'telebucks',
    symbol: 'teleb',
    name: 'TeleBucks',
  },
  {
    id: 'telefy',
    symbol: 'tele',
    name: 'Telefy',
  },
  {
    id: 'telegram-inu',
    symbol: 'tinu',
    name: 'Telegram Inu',
  },
  {
    id: 'teletreon',
    symbol: 'ttn',
    name: 'TeleTreon',
  },
  {
    id: 'tellor',
    symbol: 'trb',
    name: 'Tellor Tributes',
  },
  {
    id: 'telos',
    symbol: 'tlos',
    name: 'Telos',
  },
  {
    id: 'telos-coin',
    symbol: 'telos',
    name: 'Teloscoin',
  },
  {
    id: 'telos-velocore',
    symbol: 'tvc',
    name: 'Telos Velocore',
  },
  {
    id: 'temco',
    symbol: 'temco',
    name: 'TEMCO',
  },
  {
    id: 'temdao',
    symbol: 'tem',
    name: 'TemDAO',
  },
  {
    id: 'tempest',
    symbol: 'tem',
    name: 'Tempest',
  },
  {
    id: 'templardao',
    symbol: 'tem',
    name: 'Templar DAO',
  },
  {
    id: 'temple',
    symbol: 'temple',
    name: 'TempleDAO',
  },
  {
    id: 'temple-key',
    symbol: 'tkey',
    name: 'Temple Key',
  },
  {
    id: 'temtem',
    symbol: 'tem',
    name: 'Temtum',
  },
  {
    id: 'ten',
    symbol: 'tenfi',
    name: 'TEN',
  },
  {
    id: 'ten-best-coins',
    symbol: 'tbc',
    name: 'Ten Best Coins',
  },
  {
    id: 'tender-fi',
    symbol: 'tnd',
    name: 'Tender.fi',
  },
  {
    id: 'tendies-icp',
    symbol: 'tendy',
    name: 'Tendies (ICP)',
  },
  {
    id: 'tenet-1b000f7b-59cb-4e06-89ce-d62b32d362b9',
    symbol: 'tenet',
    name: 'TENET',
  },
  {
    id: 'tenset',
    symbol: '10set',
    name: 'Tenset',
  },
  {
    id: 'tenshi',
    symbol: 'tenshi',
    name: 'Tenshi',
  },
  {
    id: 'tenup',
    symbol: 'tup',
    name: 'Tenup',
  },
  {
    id: 'tenx',
    symbol: 'pay',
    name: 'TenX',
  },
  {
    id: 'tenx-2',
    symbol: 'tenx',
    name: 'TenX',
  },
  {
    id: 'terahertz-capital',
    symbol: 'thz',
    name: 'TeraHertz Capital',
  },
  {
    id: 'terareum',
    symbol: 'tera',
    name: 'Terareum [OLD]',
  },
  {
    id: 'tera-smart-money',
    symbol: 'tera',
    name: 'TERA',
  },
  {
    id: 'teratto',
    symbol: 'trcon',
    name: 'TERATTO',
  },
  {
    id: 'teritori',
    symbol: 'tori',
    name: 'Teritori',
  },
  {
    id: 'ternio',
    symbol: 'tern',
    name: 'Ternio',
  },
  {
    id: 'terracoin',
    symbol: 'trc',
    name: 'Terracoin',
  },
  {
    id: 'terra-luna',
    symbol: 'lunc',
    name: 'Terra Luna Classic',
  },
  {
    id: 'terra-luna-2',
    symbol: 'luna',
    name: 'Terra',
  },
  {
    id: 'terran-coin',
    symbol: 'trr',
    name: 'Terran Coin',
  },
  {
    id: 'terra-poker-token',
    symbol: 'tpt',
    name: 'Terra Poker Token',
  },
  {
    id: 'terraport',
    symbol: 'terra',
    name: 'Terraport',
  },
  {
    id: 'terrausd',
    symbol: 'ustc',
    name: 'TerraClassicUSD',
  },
  {
    id: 'terrausd-wormhole',
    symbol: 'ust',
    name: 'TerraUSD (Wormhole)',
  },
  {
    id: 'teso',
    symbol: 'teso',
    name: 'TeSo',
  },
  {
    id: 'test-2',
    symbol: 'test',
    name: 'Test',
  },
  {
    id: 'testo',
    symbol: 'testo',
    name: 'TESTO',
  },
  {
    id: 'tether',
    symbol: 'usdt',
    name: 'Tether',
  },
  {
    id: 'tether-6069e553-7ebb-487e-965e-2896cd21d6ac',
    symbol: 'zusdt',
    name: 'Bridged Tether (Zilliqa)',
  },
  {
    id: 'tether-avalanche-bridged-usdt-e',
    symbol: 'usdte',
    name: 'Bridged Tether (Avalanche)',
  },
  {
    id: 'tethereum',
    symbol: 't99',
    name: 'Tethereum',
  },
  {
    id: 'tether-eurt',
    symbol: 'eurt',
    name: 'Euro Tether',
  },
  {
    id: 'tether-gold',
    symbol: 'xaut',
    name: 'Tether Gold',
  },
  {
    id: 'tether-plenty-bridge',
    symbol: 'usdt.e',
    name: 'Bridged Tether (Plenty Bridge)',
  },
  {
    id: 'tether-pulsechain',
    symbol: 'usdt',
    name: 'Bridged Tether (PulseChain)',
  },
  {
    id: 'tether-rainbow-bridge',
    symbol: 'usdt.e',
    name: 'Bridged Tether (Rainbow Bridge)',
  },
  {
    id: 'tether-usd-celer',
    symbol: 'ceusdt',
    name: 'Bridged Tether (Celer)',
  },
  {
    id: 'tether-usd-pos-wormhole',
    symbol: 'usdtpo',
    name: 'Bridged Tether (Wormhole POS)',
  },
  {
    id: 'tether-usd-wormhole',
    symbol: 'usdtso',
    name: 'Bridged Tether (Wormhole)',
  },
  {
    id: 'tether-usd-wormhole-from-ethereum',
    symbol: 'usdtet',
    name: 'Bridged Tether (Wormhole Ethereum)',
  },
  {
    id: 'tethys-finance',
    symbol: 'tethys',
    name: 'Tethys Finance',
  },
  {
    id: 'tetra',
    symbol: 'tetrap',
    name: 'TETRA',
  },
  {
    id: 'tetris-2',
    symbol: 'tetris',
    name: 'Tetris',
  },
  {
    id: 'tetu',
    symbol: 'tetu',
    name: 'TETU',
  },
  {
    id: 'tetubal',
    symbol: 'tetubal',
    name: 'tetuBAL',
  },
  {
    id: 'tetuqi',
    symbol: 'tetuqi',
    name: 'tetuQi',
  },
  {
    id: 'texan',
    symbol: 'texan',
    name: 'Texan',
  },
  {
    id: 'tezos',
    symbol: 'xtz',
    name: 'Tezos',
  },
  {
    id: 'tezos-domains',
    symbol: 'ted',
    name: 'Tezos Domains',
  },
  {
    id: 'tezos-pepe',
    symbol: 'pepe',
    name: 'Tezos Pepe',
  },
  {
    id: 'tg-casino',
    symbol: 'tgc',
    name: 'TG.Casino',
  },
  {
    id: 'tg-dao',
    symbol: 'tgdao',
    name: 'TG DAO',
  },
  {
    id: 'tgold',
    symbol: 'txau',
    name: 'tGOLD',
  },
  {
    id: 'tgrade',
    symbol: 'tgd',
    name: 'Tgrade',
  },
  {
    id: 'thala',
    symbol: 'thl',
    name: 'Thala',
  },
  {
    id: 'thala-apt',
    symbol: 'thapt',
    name: 'Thala APT',
  },
  {
    id: 'thales',
    symbol: 'thales',
    name: 'Thales',
  },
  {
    id: 'the-1-club',
    symbol: '1%',
    name: 'The 1% Club',
  },
  {
    id: 'the-4th-pillar',
    symbol: 'four',
    name: 'FOUR',
  },
  {
    id: 'the9',
    symbol: 'the9',
    name: 'THE9',
  },
  {
    id: 'the-abyss',
    symbol: 'abyss',
    name: 'Abyss',
  },
  {
    id: 'theada',
    symbol: 'tada',
    name: 'TheADA',
  },
  {
    id: 'the-ape-society',
    symbol: 'society',
    name: 'The Ape Society',
  },
  {
    id: 'the-autism-token',
    symbol: 'tism',
    name: 'The Autism Token',
  },
  {
    id: 'the-balkan-dwarf',
    symbol: '$kekec',
    name: 'The Balkan Dwarf',
  },
  {
    id: 'the-bear',
    symbol: 'bear',
    name: 'The Bear',
  },
  {
    id: 'the-bet',
    symbol: 'bet',
    name: 'The Bet',
  },
  {
    id: 'the-big-five',
    symbol: 'bft',
    name: 'The Big Five',
  },
  {
    id: 'the-blox-project',
    symbol: 'blox',
    name: 'The Blox Project',
  },
  {
    id: 'the-blu-arctic-water-comp',
    symbol: 'barc',
    name: 'The Blu Arctic Water Comp',
  },
  {
    id: 'theca',
    symbol: 'theca',
    name: 'Theca',
  },
  {
    id: 'the-cat-inu',
    symbol: 'thecat',
    name: 'The Cat Inu',
  },
  {
    id: 'the-champcoin',
    symbol: 'tcc',
    name: 'The ChampCoin',
  },
  {
    id: 'the-citadel',
    symbol: 'citadel',
    name: 'The Citadel',
  },
  {
    id: 'the-corgi-of-polkabridge',
    symbol: 'corgib',
    name: 'The Corgi of PolkaBridge',
  },
  {
    id: 'the-crypto-prophecies',
    symbol: 'tcp',
    name: 'The Crypto Prophecies',
  },
  {
    id: 'the-crypto-you',
    symbol: 'milk',
    name: 'The Crypto You',
  },
  {
    id: 'the-debt-box',
    symbol: 'debt',
    name: 'The Debt Box',
  },
  {
    id: 'the-doge-nft',
    symbol: 'dog',
    name: 'The Doge NFT',
  },
  {
    id: 'the-employment-commons-work-token',
    symbol: 'work',
    name: 'The Employment Commons Work',
  },
  {
    id: 'the-ennead',
    symbol: 'neadram',
    name: 'The Ennead',
  },
  {
    id: 'the-essential-coin',
    symbol: 'esc',
    name: 'The Essential Coin',
  },
  {
    id: 'the-everlasting-parachain',
    symbol: 'elp',
    name: 'The Everlasting Parachain',
  },
  {
    id: 'theflashcurrency',
    symbol: 'tfc',
    name: 'TheFlashCurrency',
  },
  {
    id: 'theforce-trade',
    symbol: 'foc',
    name: 'TheForce Trade',
  },
  {
    id: 'the-goat-cz',
    symbol: 'czgoat',
    name: 'CZ THE GOAT',
  },
  {
    id: 'the-grapes-grape-coin',
    symbol: 'grape',
    name: 'Grape Coin',
  },
  {
    id: 'the-graph',
    symbol: 'grt',
    name: 'The Graph',
  },
  {
    id: 'the-grays-currency',
    symbol: 'ptgc',
    name: 'The Grays Currency',
  },
  {
    id: 'the-great-void-token',
    symbol: 'void',
    name: 'The Great Void Token',
  },
  {
    id: 'the-husl',
    symbol: 'husl',
    name: 'The HUSL',
  },
  {
    id: 'the-infinite-garden',
    symbol: 'eth',
    name: 'The Infinite Garden',
  },
  {
    id: 'thejanitor',
    symbol: 'eric',
    name: 'TheJanitor',
  },
  {
    id: 'the-jupiter-cat',
    symbol: 'jupcat',
    name: 'The Jupiter Cat',
  },
  {
    id: 'the-killbox-game',
    symbol: 'kbox',
    name: 'The Killbox Game',
  },
  {
    id: 'the-kingdom-coin',
    symbol: 'tkc',
    name: 'The Kingdom Coin',
  },
  {
    id: 'the-last-pepe',
    symbol: 'froggo',
    name: 'The Last Pepe',
  },
  {
    id: 'the-mars',
    symbol: 'mrst',
    name: 'Mars Token',
  },
  {
    id: 'the-monopolist',
    symbol: 'mono',
    name: 'The Monopolist',
  },
  {
    id: 'thena',
    symbol: 'the',
    name: 'Thena',
  },
  {
    id: 'the-neko',
    symbol: 'neko',
    name: 'The Neko',
  },
  {
    id: 'the-nemesis',
    symbol: 'nems',
    name: 'The Nemesis',
  },
  {
    id: 'the-node',
    symbol: 'the',
    name: 'THENODE',
  },
  {
    id: 'the-open-network',
    symbol: 'ton',
    name: 'Toncoin',
  },
  {
    id: 'theopetra',
    symbol: 'theo',
    name: 'Theopetra',
  },
  {
    id: 'theos',
    symbol: 'theos',
    name: 'Theos',
  },
  {
    id: 'the-other-party',
    symbol: 'pod',
    name: 'The Other Party',
  },
  {
    id: 'the-people-coin',
    symbol: 'peep$',
    name: 'The People’s Coin',
  },
  {
    id: 'the-phoenix',
    symbol: 'fire',
    name: 'The Phoenix',
  },
  {
    id: 'the-pond',
    symbol: 'thepond',
    name: 'The Pond',
  },
  {
    id: 'the-protocol',
    symbol: 'the',
    name: 'The Protocol',
  },
  {
    id: 'the-qwan',
    symbol: 'qwan',
    name: 'The QWAN',
  },
  {
    id: 'the-real-calcium',
    symbol: '$cal',
    name: 'The Real Calcium',
  },
  {
    id: 'the-real-world',
    symbol: 'trw',
    name: 'The Real World',
  },
  {
    id: 'the-reaper',
    symbol: 'rpr',
    name: 'The Reaper',
  },
  {
    id: 'the-reaper-coin',
    symbol: 'reaper',
    name: 'The Reaper Coin',
  },
  {
    id: 'the-roman-empire',
    symbol: 'rome',
    name: 'The Roman Empire',
  },
  {
    id: 'the-root-network',
    symbol: 'root',
    name: 'The Root Network',
  },
  {
    id: 'the-rug-game',
    symbol: 'trg',
    name: 'The Rug Game',
  },
  {
    id: 'the-sandbox',
    symbol: 'sand',
    name: 'The Sandbox',
  },
  {
    id: 'the-sandbox-wormhole',
    symbol: 'sand',
    name: 'The Sandbox (Wormhole)',
  },
  {
    id: 'the-secret-coin',
    symbol: 'tsc',
    name: 'The Secret Coin',
  },
  {
    id: 'the-sharks-fan-token',
    symbol: 'sharks',
    name: 'The Sharks Fan Token',
  },
  {
    id: 'thesirion',
    symbol: 'tso',
    name: 'Thesirion',
  },
  {
    id: 'thesolandao',
    symbol: 'sdo',
    name: 'TheSolanDAO',
  },
  {
    id: 'the-standard-euro',
    symbol: 'euros',
    name: 'The Standard EURO',
  },
  {
    id: 'thetadrop',
    symbol: 'tdrop',
    name: 'ThetaDrop',
  },
  {
    id: 'theta-fuel',
    symbol: 'tfuel',
    name: 'Theta Fuel',
  },
  {
    id: 'thetan-arena',
    symbol: 'thg',
    name: 'Thetan Arena',
  },
  {
    id: 'thetanuts-finance',
    symbol: 'nuts',
    name: 'Thetanuts Finance',
  },
  {
    id: 'theta-token',
    symbol: 'theta',
    name: 'Theta Network',
  },
  {
    id: 'the-three-kingdoms',
    symbol: 'ttk',
    name: 'The Three Kingdoms',
  },
  {
    id: 'the-tokenized-bitcoin',
    symbol: 'imbtc',
    name: 'The Tokenized Bitcoin',
  },
  {
    id: 'thetopspotonline',
    symbol: 'spott',
    name: 'TheTopSpotOnline',
  },
  {
    id: 'the-unbound',
    symbol: 'un',
    name: 'Unbound',
  },
  {
    id: 'the-unfettered-souls',
    symbol: 'souls',
    name: 'The Unfettered Ecosystem',
  },
  {
    id: 'the-vault-2',
    symbol: 'vault',
    name: 'The Vault',
  },
  {
    id: 'the-virtua-kolect',
    symbol: 'tvk',
    name: 'Virtua',
  },
  {
    id: 'the-wasted-lands',
    symbol: 'wal',
    name: 'The Wasted Lands',
  },
  {
    id: 'the-winkyverse',
    symbol: 'wnk',
    name: 'Winkies',
  },
  {
    id: 'the-word',
    symbol: 'twd',
    name: 'THE WORD',
  },
  {
    id: 'the-worked-dev',
    symbol: 'work',
    name: 'The Worked.Dev',
  },
  {
    id: 'the-world-state',
    symbol: 'w$c',
    name: 'World$tateCoin',
  },
  {
    id: 'the-xenobots-project',
    symbol: 'xeno',
    name: 'The Xenobots Project',
  },
  {
    id: 'thing',
    symbol: 'thing',
    name: 'Thing',
  },
  {
    id: 'this-is-fine',
    symbol: 'fine',
    name: 'This is Fine',
  },
  {
    id: 'tholana',
    symbol: 'thol',
    name: 'Tholana',
  },
  {
    id: 'thol-token',
    symbol: 'thol',
    name: 'AngelBlock',
  },
  {
    id: 'thor',
    symbol: 'thor',
    name: 'ThorFi',
  },
  {
    id: 'thorchain',
    symbol: 'rune',
    name: 'THORChain',
  },
  {
    id: 'thoreum-v2',
    symbol: 'thoreum',
    name: 'Thoreum V3',
  },
  {
    id: 'thorstarter',
    symbol: 'xrune',
    name: 'Thorstarter',
  },
  {
    id: 'thorswap',
    symbol: 'thor',
    name: 'THORSwap',
  },
  {
    id: 'thorus',
    symbol: 'tho',
    name: 'Thorus',
  },
  {
    id: 'thorwallet',
    symbol: 'tgt',
    name: 'THORWallet DEX',
  },
  {
    id: 'thought',
    symbol: 'tht',
    name: 'Thought',
  },
  {
    id: 'threads',
    symbol: 'threads',
    name: 'Threads',
  },
  {
    id: 'threefold-token',
    symbol: 'tft',
    name: 'ThreeFold',
  },
  {
    id: 'three-hundred-ai',
    symbol: 'thnd',
    name: 'Three Hundred AI',
  },
  {
    id: 'threshold-network-token',
    symbol: 't',
    name: 'Threshold Network',
  },
  {
    id: 'threshold-usd',
    symbol: 'thusd',
    name: 'Threshold USD',
  },
  {
    id: 'throne',
    symbol: 'thn',
    name: 'Throne',
  },
  {
    id: 'throne-2',
    symbol: 'oath',
    name: 'Throne',
  },
  {
    id: 'thrupenny',
    symbol: 'tpy',
    name: 'Thrupenny',
  },
  {
    id: 'thug-life',
    symbol: 'thug',
    name: 'Thug Life',
  },
  {
    id: 'thunderbot',
    symbol: 'thund',
    name: 'ThunderBot',
  },
  {
    id: 'thunderhead-staked-flip',
    symbol: 'stflip',
    name: 'Thunderhead Staked FLIP',
  },
  {
    id: 'thunder-lands',
    symbol: 'tndr',
    name: 'Thunder Lands',
  },
  {
    id: 'thunder-token',
    symbol: 'tt',
    name: 'ThunderCore',
  },
  {
    id: 'thx-network',
    symbol: 'thx',
    name: 'THX Network',
  },
  {
    id: 'tia',
    symbol: 'tia',
    name: 'TIA',
  },
  {
    id: 'tickr',
    symbol: 'tickr',
    name: 'Tickr',
  },
  {
    id: 'tidal-finance',
    symbol: 'tidal',
    name: 'Tidal Finance',
  },
  {
    id: 'tidalflats',
    symbol: 'tide',
    name: 'Tidalflats',
  },
  {
    id: 'tidefi',
    symbol: 'tdfy',
    name: 'Tidefi',
  },
  {
    id: 'tidex-token',
    symbol: 'tdx',
    name: 'Tidex',
  },
  {
    id: 'tierion',
    symbol: 'tnt',
    name: 'Tierion',
  },
  {
    id: 'tifi-token',
    symbol: 'tifi',
    name: 'TiFi',
  },
  {
    id: 'tif-protocol',
    symbol: 'tif',
    name: 'Tif Protocol',
  },
  {
    id: 'tigercash',
    symbol: 'tch',
    name: 'TigerCash',
  },
  {
    id: 'tiger-king',
    symbol: 'tking',
    name: 'Tiger King Coin',
  },
  {
    id: 'tiger-scrub-money-2',
    symbol: 'tiger',
    name: 'Tiger Scrub Money',
  },
  {
    id: 'tigres-fan-token',
    symbol: 'tigres',
    name: 'Tigres Fan Token',
  },
  {
    id: 'tigris',
    symbol: 'tig',
    name: 'Tigris',
  },
  {
    id: 'time-alliance-guild-time',
    symbol: 'time',
    name: 'Time Alliance Guild Time',
  },
  {
    id: 'timechain-swap-token',
    symbol: 'tcs',
    name: 'Timechain Swap',
  },
  {
    id: 't-i-m-e-dividend',
    symbol: 'time',
    name: 'T.I.M.E. Dividend',
  },
  {
    id: 'timeleap-finance',
    symbol: 'time',
    name: 'Timeleap Finance',
  },
  {
    id: 'timeless',
    symbol: 'lit',
    name: 'Timeless',
  },
  {
    id: 'timeseries-ai',
    symbol: 'timeseries',
    name: 'Timeseries AI',
  },
  {
    id: 'timtim-games',
    symbol: 'tim',
    name: 'TIMTIM GAMES',
  },
  {
    id: 'tinfa',
    symbol: 'tinfa',
    name: 'TINFA',
  },
  {
    id: 'tinkernet',
    symbol: 'tnkr',
    name: 'Tinkernet',
  },
  {
    id: 'tiny-bonez',
    symbol: 't1ny',
    name: 'Tiny Bonez',
  },
  {
    id: 'tiny-colony',
    symbol: 'tiny',
    name: 'Tiny Colony',
  },
  {
    id: 'tiny-era-shard',
    symbol: 'tes',
    name: 'Tiny Era Shard',
  },
  {
    id: 'tipcoin',
    symbol: 'tip',
    name: 'Tipcoin',
  },
  {
    id: 'tiperian',
    symbol: 'tip',
    name: 'Tiperian',
  },
  {
    id: 'tipg',
    symbol: 'tipg',
    name: 'TIPG',
  },
  {
    id: 'tipja',
    symbol: 'tipja',
    name: 'Tipja',
  },
  {
    id: 'tipsycoin',
    symbol: '$tipsy',
    name: 'TipsyCoin',
  },
  {
    id: 'tiraverse',
    symbol: 'tvrs',
    name: 'TiraVerse',
  },
  {
    id: 'titanborn',
    symbol: 'titans',
    name: 'TitanBorn',
  },
  {
    id: 'titan-hunters',
    symbol: 'tita',
    name: 'Titan Hunters',
  },
  {
    id: 'titanium22',
    symbol: 'ti',
    name: 'Titanium22',
  },
  {
    id: 'titanswap',
    symbol: 'titan',
    name: 'TitanSwap',
  },
  {
    id: 'titanx',
    symbol: 'titanx',
    name: 'TitanX',
  },
  {
    id: 'title-network',
    symbol: 'tnet',
    name: 'Bitcoin Clashic',
  },
  {
    id: 'titter',
    symbol: 'titr',
    name: 'Titter',
  },
  {
    id: 'tiusd',
    symbol: 'tiusd',
    name: 'TiUSD',
  },
  {
    id: 'tlifecoin',
    symbol: 'tlife',
    name: 'TLifeCoin',
  },
  {
    id: 'tlpt',
    symbol: 'tlpt',
    name: 'tLPT',
  },
  {
    id: 'tlsd-coin',
    symbol: 'tlsd',
    name: 'TLSD Coin',
  },
  {
    id: 't-mac-dao',
    symbol: 'tmg',
    name: 'T-mac DAO',
  },
  {
    id: 'tnc-coin',
    symbol: 'tnc',
    name: 'TNC Coin',
  },
  {
    id: 'toad',
    symbol: 'toad',
    name: 'TOAD',
  },
  {
    id: 'toad-killer',
    symbol: '$toad',
    name: 'Toad Killer',
  },
  {
    id: 'tocen',
    symbol: 'toce',
    name: 'Tocen',
  },
  {
    id: 'tochi-base',
    symbol: 'tochi',
    name: 'Tochi Base',
  },
  {
    id: 'toearnnow',
    symbol: 'now',
    name: 'ToEarnNow',
  },
  {
    id: 'toge',
    symbol: 'toge',
    name: 'TOGE',
  },
  {
    id: 'toka-2',
    symbol: 'toka',
    name: 'TOKA',
  },
  {
    id: 'tokamak-network',
    symbol: 'ton',
    name: 'Tokamak Network',
  },
  {
    id: 'tokel',
    symbol: 'tkl',
    name: 'Tokel',
  },
  {
    id: 'tokemak',
    symbol: 'toke',
    name: 'Tokemak',
  },
  {
    id: 'tokenasset',
    symbol: 'ntb',
    name: 'TokenAsset',
  },
  {
    id: 'tokenbot',
    symbol: 'tkb',
    name: 'TokenBot',
  },
  {
    id: 'tokencard',
    symbol: 'tkn',
    name: 'Monolith',
  },
  {
    id: 'tokenclub',
    symbol: 'tct',
    name: 'TokenClub',
  },
  {
    id: 'token-dforce-usd',
    symbol: 'usx',
    name: 'dForce USD',
  },
  {
    id: 'tokenfi',
    symbol: 'token',
    name: 'TokenFi',
  },
  {
    id: 'token-in',
    symbol: 'tin',
    name: 'Token IN',
  },
  {
    id: 'tokenize-xchange',
    symbol: 'tkx',
    name: 'Tokenize Xchange',
  },
  {
    id: 'tokenlon',
    symbol: 'lon',
    name: 'Tokenlon',
  },
  {
    id: 'tokenomy',
    symbol: 'ten',
    name: 'Tokenomy',
  },
  {
    id: 'tokenplace',
    symbol: 'tok',
    name: 'Tokenplace',
  },
  {
    id: 'token-pocket',
    symbol: 'tpt',
    name: 'TokenPocket Token',
  },
  {
    id: 'tokensight',
    symbol: 'tkst',
    name: 'TokenSight',
  },
  {
    id: 'token-teknoloji-a-s-euro',
    symbol: 'eurot',
    name: 'Token Teknoloji A.Ş. EURO',
  },
  {
    id: 'token-teknoloji-a-s-usd',
    symbol: 'usdot',
    name: 'Token Teknoloji A.Ş. USD',
  },
  {
    id: 'tokenwatch',
    symbol: 'tokenwatch',
    name: 'TokenWatch',
  },
  {
    id: 'tokhit',
    symbol: 'hitt',
    name: 'TOKHIT',
  },
  {
    id: 'toko',
    symbol: 'toko',
    name: 'Tokoin',
  },
  {
    id: 'tokocrypto',
    symbol: 'tko',
    name: 'Tokocrypto',
  },
  {
    id: 'tokpie',
    symbol: 'tkp',
    name: 'TOKPIE',
  },
  {
    id: 'toku',
    symbol: 'toku',
    name: 'Toku',
  },
  {
    id: 'tokyo',
    symbol: 'tokc',
    name: 'Tokyo Coin',
  },
  {
    id: 'tokyo-au',
    symbol: 'tokau',
    name: 'Tokyo AU',
  },
  {
    id: 'tolar',
    symbol: 'tol',
    name: 'Tolar',
  },
  {
    id: 'toly',
    symbol: 'toly',
    name: 'Toly',
  },
  {
    id: 'toman-coin',
    symbol: 'tmc',
    name: 'Toman Coin',
  },
  {
    id: 'tomb',
    symbol: 'tomb',
    name: 'Tomb',
  },
  {
    id: 'tombplus',
    symbol: 'tomb+',
    name: 'Tomb+',
  },
  {
    id: 'tombplus-tshare-plus',
    symbol: 'tshare+',
    name: 'TombPlus TSHARE+',
  },
  {
    id: 'tomb-shares',
    symbol: 'tshare',
    name: 'Tomb Shares',
  },
  {
    id: 'tom-coin',
    symbol: 'tmc',
    name: 'Tom Coin',
  },
  {
    id: 'tom-finance',
    symbol: 'tom',
    name: 'TOM Finance',
  },
  {
    id: 'tominet',
    symbol: 'tomi',
    name: 'tomiNet',
  },
  {
    id: 'tomochain',
    symbol: 'vic',
    name: 'Viction',
  },
  {
    id: 'tomoe',
    symbol: 'tomoe',
    name: 'TomoChain ERC-20',
  },
  {
    id: 'tomtomcoin',
    symbol: 'toms',
    name: 'TomTomCoin',
  },
  {
    id: 'tonestra',
    symbol: 'tnr',
    name: 'Tonestra',
  },
  {
    id: 'tonex',
    symbol: 'tnx',
    name: 'Tonex',
  },
  {
    id: 'ton-fish-memecoin',
    symbol: 'fish',
    name: 'TON FISH MEMECOIN',
  },
  {
    id: 'tongtong-coin',
    symbol: 'ttc',
    name: 'Tongtong Coin',
  },
  {
    id: 'ton-inu',
    symbol: 'tinu',
    name: 'Ton Inu',
  },
  {
    id: 'tonminer',
    symbol: '1rus',
    name: 'TonMiner',
  },
  {
    id: 'ton-raffles',
    symbol: 'raff',
    name: 'TON Raffles',
  },
  {
    id: 'tonsniper',
    symbol: 'tons',
    name: 'TONSniper',
  },
  {
    id: 'tonstarter',
    symbol: 'tos',
    name: 'TONStarter',
  },
  {
    id: 'tontoken',
    symbol: 'ton',
    name: 'TON Community',
  },
  {
    id: 'tony-mcduck',
    symbol: 'tony',
    name: 'Tony McDuck',
  },
  {
    id: 'tools',
    symbol: 'tools',
    name: 'TOOLS',
  },
  {
    id: 'tools-fi',
    symbol: 'tools-fi',
    name: 'Tools-Fi',
  },
  {
    id: 'topdown-survival-shooter',
    symbol: 'shooter',
    name: 'TopDown Survival Shooter',
  },
  {
    id: 'topgoal',
    symbol: 'goal',
    name: 'TopGoal',
  },
  {
    id: 'top-jeet',
    symbol: 'topj',
    name: 'Top Jeet',
  },
  {
    id: 'topmanager',
    symbol: 'tmt',
    name: 'TopManager',
  },
  {
    id: 'top-network',
    symbol: 'top',
    name: 'TOP Network',
  },
  {
    id: 'topshelf-finance',
    symbol: 'liqr',
    name: 'Topshelf Finance',
  },
  {
    id: 'toptrade',
    symbol: 'ttt',
    name: 'TopTrade',
  },
  {
    id: 'tor',
    symbol: 'tor',
    name: 'TOR',
  },
  {
    id: 'tora-inu',
    symbol: 'tora',
    name: 'Tora Inu',
  },
  {
    id: 'torekko',
    symbol: 'trk',
    name: 'Torekko',
  },
  {
    id: 'torg',
    symbol: 'torg',
    name: 'TORG',
  },
  {
    id: 'tornado-ai',
    symbol: 'tornai',
    name: 'Tornado AI',
  },
  {
    id: 'tornado-cash',
    symbol: 'torn',
    name: 'Tornado Cash',
  },
  {
    id: 'toro',
    symbol: 'toro',
    name: 'TORO',
  },
  {
    id: 'torque',
    symbol: 'torq',
    name: 'Torque',
  },
  {
    id: 'tortol',
    symbol: 'trtl',
    name: 'Tortol',
  },
  {
    id: 'tortuga-staked-aptos',
    symbol: 'tapt',
    name: 'Tortuga Staked Aptos',
  },
  {
    id: 'torum',
    symbol: 'xtm',
    name: 'Torum',
  },
  {
    id: 'tosdis',
    symbol: 'dis',
    name: 'TosDis',
  },
  {
    id: 'toshi',
    symbol: 'toshi',
    name: 'Toshi',
  },
  {
    id: 'toshi-tools',
    symbol: 'toshi',
    name: 'Toshi Tools',
  },
  {
    id: 'tosidrop',
    symbol: 'ctosi',
    name: 'TosiDrop',
  },
  {
    id: 'totemfi',
    symbol: 'totm',
    name: 'TotemFi',
  },
  {
    id: 'to-the-moon-token',
    symbol: 'ton',
    name: 'To The Moon Token',
  },
  {
    id: 'totocat',
    symbol: 'totocat',
    name: 'Totocat',
  },
  {
    id: 'totoro-inu',
    symbol: 'totoro',
    name: 'Totoro Inu',
  },
  {
    id: 'tottenham-hotspur-fc-fan-token',
    symbol: 'spurs',
    name: 'Tottenham Hotspur FC Fan Token',
  },
  {
    id: 'toucan-protocol-base-carbon-tonne',
    symbol: 'bct',
    name: 'Toucan Protocol: Base Carbon Tonne',
  },
  {
    id: 'toucan-protocol-nature-carbon-tonne',
    symbol: 'nct',
    name: 'Toucan Protocol: Nature Carbon Tonne',
  },
  {
    id: 'touchfan',
    symbol: 'tft',
    name: 'TouchFan',
  },
  {
    id: 'toupee-tech',
    symbol: 'wig',
    name: 'Toupée Tech',
  },
  {
    id: 'tourism-industry-metavers',
    symbol: 'tim',
    name: 'Tourism Industry Metavers',
  },
  {
    id: 'tourismx',
    symbol: 'trmx',
    name: 'TourismX',
  },
  {
    id: 'tourist-shiba-inu',
    symbol: 'tourists',
    name: 'Tourist Shiba Inu',
  },
  {
    id: 'tower',
    symbol: 'tower',
    name: 'Tower',
  },
  {
    id: 'toxicdeer-finance',
    symbol: 'deer',
    name: 'ToxicDeer Finance',
  },
  {
    id: 'toxicdeer-share',
    symbol: 'xdshare',
    name: 'ToxicDeer Share',
  },
  {
    id: 'toxicgarden-finance-seed',
    symbol: 'seed',
    name: 'ToxicGarden.finance SEED',
  },
  {
    id: 'tplatinum',
    symbol: 'txpt',
    name: 'tPLATINUM',
  },
  {
    id: 'tpro',
    symbol: 'tpro',
    name: 'TPRO',
  },
  {
    id: 'tr3zor',
    symbol: 'tr3',
    name: 'Tr3zor',
  },
  {
    id: 'tr8bit',
    symbol: 'trb',
    name: 'Tr8bit',
  },
  {
    id: 'traaitt',
    symbol: 'xte',
    name: 'traaitt',
  },
  {
    id: 'trabzonspor-fan-token',
    symbol: 'tra',
    name: 'Trabzonspor Fan Token',
  },
  {
    id: 'trac',
    symbol: 'trac',
    name: 'TRAC (Ordinals)',
  },
  {
    id: 'trace-ai',
    symbol: 'tai',
    name: 'Trace AI',
  },
  {
    id: 'trace-network-labs',
    symbol: 'trace',
    name: 'Trace Network Labs',
  },
  {
    id: 'tracer',
    symbol: 'trc',
    name: 'Tracer',
  },
  {
    id: 'tracer-dao',
    symbol: 'tcr',
    name: 'Tracer DAO',
  },
  {
    id: 'trackers-token',
    symbol: 'trt',
    name: 'Trackers Token',
  },
  {
    id: 'trackr',
    symbol: 'trackr',
    name: 'Trackr',
  },
  {
    id: 'track-the-funds-bot',
    symbol: 'ttf',
    name: 'Track The Funds Bot',
  },
  {
    id: 'tradao',
    symbol: 'tod',
    name: 'Trava Capital',
  },
  {
    id: 'trade-bionic',
    symbol: 'onic',
    name: 'Trade Bionic',
  },
  {
    id: 'trade-genius-ai',
    symbol: 'aigenius',
    name: 'Trade Genius AI',
  },
  {
    id: 'trade-leaf',
    symbol: 'tlf',
    name: 'Tradeleaf',
  },
  {
    id: 'traderdao-proof-of-trade',
    symbol: 'pot',
    name: 'TraderDAO Proof Of Trade',
  },
  {
    id: 'traders-coin',
    symbol: 'trdc',
    name: 'Traders Coin',
  },
  {
    id: 'traders-wallet',
    symbol: 'trw',
    name: 'Traders Wallet',
  },
  {
    id: 'traderx',
    symbol: '$tx',
    name: 'TraderX',
  },
  {
    id: 'tradestars',
    symbol: 'tsx',
    name: 'TradeStars',
  },
  {
    id: 'trade-tech-ai',
    symbol: 'ttai',
    name: 'Trade Tech AI',
  },
  {
    id: 'tradetomato',
    symbol: 'ttm',
    name: 'Tradetomato',
  },
  {
    id: 'tradex-ai',
    symbol: 'tradex',
    name: 'TradeX AI',
  },
  {
    id: 'tradix',
    symbol: 'tx',
    name: 'Tradix',
  },
  {
    id: 'tranche-finance',
    symbol: 'slice',
    name: 'Tranche Finance',
  },
  {
    id: 'tranchess',
    symbol: 'chess',
    name: 'Tranchess',
  },
  {
    id: 'tranquil-finance',
    symbol: 'tranq',
    name: 'Tranquil Finance',
  },
  {
    id: 'tranquility-city',
    symbol: 'lumen',
    name: 'Tranquility City',
  },
  {
    id: 'tranquil-staked-one',
    symbol: 'stone',
    name: 'Tranquil Staked ONE',
  },
  {
    id: 'transhuman-coin',
    symbol: 'thc',
    name: 'Transhuman Coin',
  },
  {
    id: 'trava-finance',
    symbol: 'trava',
    name: 'Trava Finance',
  },
  {
    id: 'travel-care-2',
    symbol: 'travel',
    name: 'Travel Care',
  },
  {
    id: 'travelers-token',
    symbol: 'trv',
    name: 'Travelers Token',
  },
  {
    id: 'traverse-labs',
    symbol: 'trvs',
    name: 'Traverse Labs',
  },
  {
    id: 'trax',
    symbol: 'trax',
    name: 'TRAX',
  },
  {
    id: 'traxx',
    symbol: 'traxx',
    name: 'Traxx',
  },
  {
    id: 'treasure-labs-loot',
    symbol: 'loot',
    name: 'Treasure Labs LOOT',
  },
  {
    id: 'treasure-under-sea',
    symbol: 'tus',
    name: 'Treasure Under Sea',
  },
  {
    id: 'treasury-bond-eth-tokenized-stock-defichain',
    symbol: 'dtlt',
    name: 'iShares 20+ Year Treasury Bond ETF Defichain',
  },
  {
    id: 'treat',
    symbol: 'treat',
    name: 'Treat',
  },
  {
    id: 'treatdao-v2',
    symbol: 'treat',
    name: 'TreatDAO',
  },
  {
    id: 'treat-token',
    symbol: 'treat',
    name: 'Treat Token',
  },
  {
    id: 'treeb',
    symbol: 'treeb',
    name: 'Retreeb',
  },
  {
    id: 'tree-capital',
    symbol: 'tree',
    name: 'Tree',
  },
  {
    id: 'treecle',
    symbol: 'trcl',
    name: 'Treecle',
  },
  {
    id: 'treemeister',
    symbol: 'tree',
    name: 'Treemeister',
  },
  {
    id: 'trellis',
    symbol: 'treis',
    name: 'Trellis',
  },
  {
    id: 'trendai',
    symbol: 'trendai',
    name: 'TrendAI',
  },
  {
    id: 'trendingtool',
    symbol: 'tt',
    name: 'TrendingTool',
  },
  {
    id: 'trendsy',
    symbol: 'trndz',
    name: 'Trendsy',
  },
  {
    id: 'trend-x',
    symbol: 'trendx',
    name: 'Trend X',
  },
  {
    id: 'trepe',
    symbol: '$trepe',
    name: 'Trepe',
  },
  {
    id: 'tres-chain',
    symbol: 'tres',
    name: 'Tres Chain',
  },
  {
    id: 'trezarcoin',
    symbol: 'tzc',
    name: 'TrezarCoin',
  },
  {
    id: 'triall',
    symbol: 'trl',
    name: 'Triall',
  },
  {
    id: 'trias-token',
    symbol: 'trias',
    name: 'TriasLab',
  },
  {
    id: 'tribal-token',
    symbol: 'tribl',
    name: 'Tribal Token',
  },
  {
    id: 'tribe-2',
    symbol: 'tribe',
    name: 'Tribe',
  },
  {
    id: 'tribeone',
    symbol: 'haka',
    name: 'TribeOne',
  },
  {
    id: 'tribe-token',
    symbol: 'tribex',
    name: 'Tribe Token',
  },
  {
    id: 'tribe-token-2',
    symbol: 'tribe',
    name: 'Tribe Token',
  },
  {
    id: 'trice',
    symbol: 'tri',
    name: 'Trice',
  },
  {
    id: 'tridentdao',
    symbol: 'psi',
    name: 'TridentDAO',
  },
  {
    id: 'trifecta-tribot',
    symbol: 'tribot',
    name: 'Trifecta TRIBOT',
  },
  {
    id: 'triipmiles',
    symbol: 'tiim',
    name: 'TriipMiles',
  },
  {
    id: 'trillant',
    symbol: 'tril',
    name: 'TRILLANT',
  },
  {
    id: 'trillioner',
    symbol: 'tlc',
    name: 'Trillioner',
  },
  {
    id: 'trimbex',
    symbol: 'trim',
    name: 'TRIMBEX',
  },
  {
    id: 'trinity-network-credit',
    symbol: 'tnc',
    name: 'Trinity Network Credit',
  },
  {
    id: 'triple',
    symbol: 'triple',
    name: 'TRIPLE',
  },
  {
    id: 'trism',
    symbol: 'trism',
    name: 'Trism',
  },
  {
    id: 'trisolaris',
    symbol: 'tri',
    name: 'Trisolaris',
  },
  {
    id: 'triton',
    symbol: 'xeq',
    name: 'Equilibria',
  },
  {
    id: 'trivian',
    symbol: 'trivia',
    name: 'Trivians',
  },
  {
    id: 'trolite',
    symbol: 'trl',
    name: 'Trolite',
  },
  {
    id: 'troll',
    symbol: 'troll',
    name: 'Troll',
  },
  {
    id: 'troll-2-0',
    symbol: 'troll 2.0',
    name: 'TROLL 2.0',
  },
  {
    id: 'trollbox',
    symbol: 'tox',
    name: 'trollbox',
  },
  {
    id: 'troll-face',
    symbol: 'troll',
    name: 'Troll Face',
  },
  {
    id: 'troll-inu',
    symbol: 'trollinu',
    name: 'Troll Inu',
  },
  {
    id: 'tron',
    symbol: 'trx',
    name: 'TRON',
  },
  {
    id: 'tronai',
    symbol: 'tai',
    name: 'TronAI',
  },
  {
    id: 'tronbetlive',
    symbol: 'live',
    name: 'TRONbetLive',
  },
  {
    id: 'tron-bsc',
    symbol: 'trx',
    name: 'TRON (BSC)',
  },
  {
    id: 'tronclassic',
    symbol: 'trxc',
    name: 'TronClassic',
  },
  {
    id: 'troneuroperewardcoin',
    symbol: 'terc',
    name: 'TronEuropeRewardCoin',
  },
  {
    id: 'tronpad',
    symbol: 'tronpad',
    name: 'TRONPAD',
  },
  {
    id: 'troy',
    symbol: 'troy',
    name: 'TROY',
  },
  {
    id: 'trrxitte',
    symbol: 'trrxitte',
    name: 'TRRXITTE International',
  },
  {
    id: 'trubadger',
    symbol: 'trubgr',
    name: 'TruBadger',
  },
  {
    id: 'truck',
    symbol: 'truck',
    name: 'Truck',
  },
  {
    id: 'truebit-protocol',
    symbol: 'tru',
    name: 'Truebit Protocol',
  },
  {
    id: 'truecnh',
    symbol: 'tcnh',
    name: 'TrueCNH',
  },
  {
    id: 'truefeedbackchain',
    symbol: 'tfbx',
    name: 'Truefeedback',
  },
  {
    id: 'truefi',
    symbol: 'tru',
    name: 'TrueFi',
  },
  {
    id: 'true-pnl',
    symbol: 'pnl',
    name: 'True PNL',
  },
  {
    id: 'true-usd',
    symbol: 'tusd',
    name: 'TrueUSD',
  },
  {
    id: 'trufin-staked-matic',
    symbol: 'trumatic',
    name: 'TruFin Staked MATIC',
  },
  {
    id: 'trumatic-matic-stable-pool',
    symbol: 'trumatic-matic',
    name: 'TruMATIC-MATIC Stable Pool',
  },
  {
    id: 'trump-cards-fraction-token',
    symbol: 'itrump',
    name: 'Trump Cards Fraction Token',
  },
  {
    id: 'trumpcoin-709b1637-4ceb-4e9e-878d-2b137bee017d',
    symbol: 'dtc',
    name: 'TrumpCoin',
  },
  {
    id: 'trust-ai',
    symbol: 'trt',
    name: 'TRUST AI',
  },
  {
    id: 'trustbase',
    symbol: 'tbe',
    name: 'TrustBase',
  },
  {
    id: 'trustbit-finance',
    symbol: 'trs',
    name: 'TrustBit Finance',
  },
  {
    id: 'trustfi-network-token',
    symbol: 'tfi',
    name: 'TrustFi Network',
  },
  {
    id: 'trustnft',
    symbol: 'trustnft',
    name: 'TrustNFT',
  },
  {
    id: 'trustpad-2-0',
    symbol: 'tpad',
    name: 'TrustPad',
  },
  {
    id: 'trustswap',
    symbol: 'swap',
    name: 'TrustSwap',
  },
  {
    id: 'trust-trading-group',
    symbol: 'ttg',
    name: 'Trust Trading Group',
  },
  {
    id: 'trustverse',
    symbol: 'trv',
    name: 'TrustVerse',
  },
  {
    id: 'trust-wallet-token',
    symbol: 'twt',
    name: 'Trust Wallet',
  },
  {
    id: 'truthgpt',
    symbol: 'truth',
    name: 'TruthGPT',
  },
  {
    id: 'truthgpt-bsc',
    symbol: 'truth',
    name: 'TruthGPT (BSC)',
  },
  {
    id: 'truthgpt-eth',
    symbol: 'truth',
    name: 'TruthGPT (ETH)',
  },
  {
    id: 'truth-pay',
    symbol: 'trp',
    name: 'Truth Pay',
  },
  {
    id: 'truth-seekers',
    symbol: 'truth',
    name: 'Truth Seekers',
  },
  {
    id: 'trxi-tron',
    symbol: 'trxi',
    name: 'TRXI (Tron)',
  },
  {
    id: 'tryc',
    symbol: 'tryc',
    name: 'TRYC',
  },
  {
    id: 'tryhards',
    symbol: 'try',
    name: 'TryHards',
  },
  {
    id: 'tsilver',
    symbol: 'txag',
    name: 'tSILVER',
  },
  {
    id: 'tsubasa-utilitiy-token',
    symbol: 'tsubasaut',
    name: 'TSUBASA Utilitiy Token',
  },
  {
    id: 'tsuki-inu',
    symbol: 'tkinu',
    name: 'Tsuki Inu',
  },
  {
    id: 'ttcoin',
    symbol: 'tc',
    name: 'TTcoin',
  },
  {
    id: 'ttc-protocol',
    symbol: 'maro',
    name: 'Maro',
  },
  {
    id: 'tucker-carlson',
    symbol: 'tucker',
    name: 'TUCKER CARLSON',
  },
  {
    id: 'tudabirds',
    symbol: 'burd',
    name: 'tudaBirds',
  },
  {
    id: 'tuf-token',
    symbol: 'tuf',
    name: 'TUF Token',
  },
  {
    id: 'tune-fm',
    symbol: 'jam',
    name: 'Tune.Fm',
  },
  {
    id: 'tupelothedog',
    symbol: 'tupelo',
    name: 'tupelothedog',
  },
  {
    id: 'turan-network',
    symbol: 'trn',
    name: 'Turan Network',
  },
  {
    id: 'turbo',
    symbol: 'turbo',
    name: 'Turbo',
  },
  {
    id: 'turbobot',
    symbol: 'turbo',
    name: 'TurboBot',
  },
  {
    id: 'turbodex',
    symbol: 'turbo',
    name: 'TurboDEX',
  },
  {
    id: 'turbos-finance',
    symbol: 'turbos',
    name: 'Turbos Finance',
  },
  {
    id: 'turbo-wallet',
    symbol: 'turbo',
    name: 'Turbo Wallet',
  },
  {
    id: 'turex',
    symbol: 'tur',
    name: 'Turex',
  },
  {
    id: 'turing-network',
    symbol: 'tur',
    name: 'Turing Network',
  },
  {
    id: 'turismo-ai',
    symbol: 'turai',
    name: 'Turismo AI',
  },
  {
    id: 'turkiye-basketbol-federasyonu-token',
    symbol: 'tbft',
    name: 'Türkiye Basketbol Federasyonu Fan Token',
  },
  {
    id: 'turkiye-motosiklet-federasyonu-fan-token',
    symbol: 'tmft',
    name: 'Türkiye Motosiklet Federasyonu Fan Token',
  },
  {
    id: 'turk-shiba',
    symbol: 'tushi',
    name: 'Turk Shiba',
  },
  {
    id: 'turtlecoin',
    symbol: 'trtl',
    name: 'TurtleCoin',
  },
  {
    id: 'turtsat',
    symbol: 'turt',
    name: 'TurtSat',
  },
  {
    id: 'tusd-yvault',
    symbol: 'yvtusd',
    name: 'TUSD yVault',
  },
  {
    id: 'tutela',
    symbol: 'tutl',
    name: 'Tutela',
  },
  {
    id: 'tutellus',
    symbol: 'tut',
    name: 'Tutellus',
  },
  {
    id: 'tweety',
    symbol: 'tweety',
    name: 'Tweety',
  },
  {
    id: 'twelve-legions',
    symbol: 'ctl',
    name: 'Twelve Legions',
  },
  {
    id: 'twelve-zodiac',
    symbol: 'twelve',
    name: 'Twelve Zodiac',
  },
  {
    id: 'twinby',
    symbol: 'twb',
    name: 'Twinby',
  },
  {
    id: 'twister-finance',
    symbol: 'twst',
    name: 'Twister Finance',
  },
  {
    id: 'twitter-ceo-floki',
    symbol: 'flokiceo',
    name: 'Twitter CEO Floki',
  },
  {
    id: 'txa',
    symbol: 'txa',
    name: 'TXA',
  },
  {
    id: 'txn-club',
    symbol: 'txn',
    name: 'TXN Club',
  },
  {
    id: 'txswap',
    symbol: 'txt',
    name: 'TXSwap',
  },
  {
    id: 'tyche-protocol',
    symbol: 'tyche',
    name: 'Tyche Protocol',
  },
  {
    id: 'tycoon',
    symbol: 'tyc',
    name: 'Tycoon',
  },
  {
    id: 'tyo-ghoul',
    symbol: 'tyo ghoul',
    name: 'TYO Ghoul',
  },
  {
    id: 'typeai',
    symbol: 'type',
    name: 'TypeAI',
  },
  {
    id: 'typeit',
    symbol: 'type',
    name: 'TypeIt',
  },
  {
    id: 'typerium',
    symbol: 'type',
    name: 'Typerium',
  },
  {
    id: 'tyrh',
    symbol: 'tyrh',
    name: 'TYRH',
  },
  {
    id: 'tyrion-finance',
    symbol: '$tyrion',
    name: 'Tyrion.finance',
  },
  {
    id: 'tzbtc',
    symbol: 'tzbtc',
    name: 'tzBTC',
  },
  {
    id: 'ubd-network',
    symbol: 'ubdn',
    name: 'UBD Network',
  },
  {
    id: 'ubeswap',
    symbol: 'ube',
    name: 'Ubeswap',
  },
  {
    id: 'ubiq',
    symbol: 'ubq',
    name: 'Ubiq',
  },
  {
    id: 'ubix-network',
    symbol: 'ubx',
    name: 'UBIX Network',
  },
  {
    id: 'ubxs-token',
    symbol: 'ubxs',
    name: 'UBXS',
  },
  {
    id: 'uca',
    symbol: 'uca',
    name: 'UCA Coin',
  },
  {
    id: 'ucash',
    symbol: 'ucash',
    name: 'U.CASH',
  },
  {
    id: 'uc-finance',
    symbol: 'ucf',
    name: 'UC Finance',
  },
  {
    id: 'ucon',
    symbol: 'ucon',
    name: 'YouCoin',
  },
  {
    id: 'ucrowdme',
    symbol: 'ucm',
    name: 'UCROWDME',
  },
  {
    id: 'ucx',
    symbol: 'ucx',
    name: 'UCX',
  },
  {
    id: 'udder-chaos-milk',
    symbol: 'milk',
    name: 'MILK',
  },
  {
    id: 'udinese-calcio-fan-token',
    symbol: 'udi',
    name: 'Udinese Calcio Fan Token',
  },
  {
    id: 'uerii',
    symbol: 'uerii',
    name: 'UERII',
  },
  {
    id: 'ufc-fan-token',
    symbol: 'ufc',
    name: 'UFC Fan Token',
  },
  {
    id: 'ufocoin',
    symbol: 'ufo',
    name: 'Uniform Fiscal Object',
  },
  {
    id: 'ufo-gaming',
    symbol: 'ufo',
    name: 'UFO Gaming',
  },
  {
    id: 'uforika',
    symbol: 'fora',
    name: 'UFORIKA',
  },
  {
    id: 'uhive',
    symbol: 'hve2',
    name: 'Uhive',
  },
  {
    id: 'ulanco',
    symbol: 'uac',
    name: 'Ulanco',
  },
  {
    id: 'uland',
    symbol: 'uland',
    name: 'ULAND',
  },
  {
    id: 'ulord',
    symbol: 'ut',
    name: 'Ulord',
  },
  {
    id: 'ultima',
    symbol: 'ultima',
    name: 'Ultima',
  },
  {
    id: 'ultimate-champions',
    symbol: 'champ',
    name: 'Ultimate Champions',
  },
  {
    id: 'ultimate-tipbot',
    symbol: 'ultimatebo',
    name: 'Ultimate Tipbot',
  },
  {
    id: 'ultra',
    symbol: 'uos',
    name: 'Ultra',
  },
  {
    id: 'ultra-clear',
    symbol: 'ucr',
    name: 'Ultra Clear',
  },
  {
    id: 'ultragate',
    symbol: 'ulg',
    name: 'Ultragate',
  },
  {
    id: 'ultrain',
    symbol: 'ugas',
    name: 'Ultrain',
  },
  {
    id: 'ultramoc',
    symbol: 'umc',
    name: 'Ultramoc',
  },
  {
    id: 'ultra-nft',
    symbol: 'unft',
    name: 'Ultra NFT',
  },
  {
    id: 'ultranote-infinity-bsc',
    symbol: 'bxuni',
    name: 'UltraNote Infinity (BSC)',
  },
  {
    id: 'ultrapro',
    symbol: 'upro',
    name: 'Ultrapro',
  },
  {
    id: 'ultrasafe',
    symbol: 'ultra',
    name: 'UltraSafe',
  },
  {
    id: 'ultron',
    symbol: 'ulx',
    name: 'ULTRON',
  },
  {
    id: 'ultron-vault',
    symbol: 'ultron',
    name: 'Ultron Vault',
  },
  {
    id: 'uma',
    symbol: 'uma',
    name: 'UMA',
  },
  {
    id: 'umami-finance',
    symbol: 'umami',
    name: 'Umami',
  },
  {
    id: 'umareum',
    symbol: 'umareum',
    name: 'UMAREUM',
  },
  {
    id: 'umbrella-network',
    symbol: 'umb',
    name: 'Umbrella Network',
  },
  {
    id: 'umee',
    symbol: 'ux',
    name: 'UX Chain',
  },
  {
    id: 'umi-digital',
    symbol: 'umi',
    name: 'Umi Digital',
  },
  {
    id: 'umi-s-friends-unity',
    symbol: 'unt',
    name: "Umi's Friends Unity",
  },
  {
    id: 'umma-token',
    symbol: 'umma',
    name: 'Umma Token',
  },
  {
    id: 'unagii-dai',
    symbol: 'udai',
    name: 'Unagii Dai',
  },
  {
    id: 'unagii-eth',
    symbol: 'ueth',
    name: 'Unagii ETH',
  },
  {
    id: 'unagii-tether-usd',
    symbol: 'uusdt',
    name: 'Unagii Tether USD',
  },
  {
    id: 'unagii-usd-coin',
    symbol: 'uusdc',
    name: 'Unagii USD Coin',
  },
  {
    id: 'unagii-wrapped-bitcoin',
    symbol: 'uwbtc',
    name: 'Unagii Wrapped Bitcoin',
  },
  {
    id: 'unagi-token',
    symbol: 'una',
    name: 'Unagi Token',
  },
  {
    id: 'unbanked',
    symbol: 'unbnk',
    name: 'Unbanked',
  },
  {
    id: 'unbound-finance',
    symbol: 'unb',
    name: 'Unbound Finance',
  },
  {
    id: 'uncl',
    symbol: 'uncl',
    name: 'UNCL',
  },
  {
    id: 'unclemine',
    symbol: 'um',
    name: 'UncleMine',
  },
  {
    id: 'unclesam-finance',
    symbol: 'sam',
    name: 'UncleSam Finance',
  },
  {
    id: 'undead-blocks',
    symbol: 'undead',
    name: 'Undead Blocks',
  },
  {
    id: 'u-network',
    symbol: 'uuu',
    name: 'U Network',
  },
  {
    id: 'unfederalreserve',
    symbol: 'ersdl',
    name: 'unFederalReserve',
  },
  {
    id: 'uni01cinosamaborettopyrra',
    symbol: 'nioctib',
    name: 'Uni01cinoSamabOrettoPyrra',
  },
  {
    id: 'unibets-ai',
    symbol: '$bets',
    name: 'Unibets.AI [OLD]',
  },
  {
    id: 'unibets-ai-2',
    symbol: '$bets',
    name: 'Unibets.AI',
  },
  {
    id: 'unibot',
    symbol: 'unibot',
    name: 'Unibot',
  },
  {
    id: 'unibright',
    symbol: 'ubt',
    name: 'Unibright',
  },
  {
    id: 'unice',
    symbol: 'unice',
    name: 'UNICE',
  },
  {
    id: 'unichad',
    symbol: 'unichad',
    name: 'Unichad',
  },
  {
    id: 'unicly',
    symbol: 'unic',
    name: 'Unicly',
  },
  {
    id: 'unicorn-2',
    symbol: 'unicorn',
    name: 'UNICORN',
  },
  {
    id: 'unicorn-metaverse',
    symbol: 'universe',
    name: 'Unicorn Metaverse',
  },
  {
    id: 'unicorn-milk',
    symbol: 'unim',
    name: 'Unicorn Milk',
  },
  {
    id: 'unicorn-token',
    symbol: 'uni',
    name: 'UNICORN',
  },
  {
    id: 'unicorn-ultra',
    symbol: 'u2u',
    name: 'Unicorn Ultra',
  },
  {
    id: 'unicrypt-2',
    symbol: 'uncx',
    name: 'UNCX Network',
  },
  {
    id: 'unidef',
    symbol: 'u',
    name: 'Unidef',
  },
  {
    id: 'unidex',
    symbol: 'unidx',
    name: 'UniDex',
  },
  {
    id: 'unido-ep',
    symbol: 'udo',
    name: 'Unido',
  },
  {
    id: 'unielon',
    symbol: 'unix',
    name: 'Unielon (DRC-20)',
  },
  {
    id: 'unifarm',
    symbol: 'ufarm',
    name: 'UniFarm',
  },
  {
    id: 'unifees',
    symbol: 'fees',
    name: 'Unifees',
  },
  {
    id: 'unifi',
    symbol: 'unifi',
    name: 'Covenants',
  },
  {
    id: 'unification',
    symbol: 'fund',
    name: 'Unification',
  },
  {
    id: 'unifi-protocol-dao',
    symbol: 'unfi',
    name: 'Unifi Protocol DAO',
  },
  {
    id: 'unigraph-ordinals',
    symbol: 'grph',
    name: 'Unigraph (Ordinals)',
  },
  {
    id: 'unilab-network',
    symbol: 'ulab',
    name: 'Unilab',
  },
  {
    id: 'unilapse',
    symbol: 'uni',
    name: 'UNILAPSE',
  },
  {
    id: 'unilayer',
    symbol: 'layer',
    name: 'UniLayer',
  },
  {
    id: 'union-finance',
    symbol: 'union',
    name: 'Union Finance',
  },
  {
    id: 'union-protocol-governance-token',
    symbol: 'unn',
    name: 'UNION Protocol Governance',
  },
  {
    id: 'unipilot',
    symbol: 'pilot',
    name: 'Unipilot',
  },
  {
    id: 'unipoly',
    symbol: 'unp',
    name: 'Unipoly',
  },
  {
    id: 'unipower',
    symbol: 'power',
    name: 'UniPower',
  },
  {
    id: 'unique-network',
    symbol: 'unq',
    name: 'Unique Network',
  },
  {
    id: 'unique-one',
    symbol: 'rare',
    name: 'Unique One',
  },
  {
    id: 'unique-utility-token',
    symbol: 'unqt',
    name: 'Unique Utility',
  },
  {
    id: 'unisocks',
    symbol: 'socks',
    name: 'Unisocks',
  },
  {
    id: 'unistake',
    symbol: 'unistake',
    name: 'Unistake',
  },
  {
    id: 'uniswap',
    symbol: 'uni',
    name: 'Uniswap',
  },
  {
    id: 'uniswap-wormhole',
    symbol: 'uni',
    name: 'Uniswap (Wormhole)',
  },
  {
    id: 'unit-dao',
    symbol: 'un',
    name: 'UNIT DAO',
  },
  {
    id: 'unitedcrowd',
    symbol: 'uct',
    name: 'UnitedCrowd',
  },
  {
    id: 'united-emirates-of-fun',
    symbol: '$uefn',
    name: 'United Emirates Of Fun',
  },
  {
    id: 'united-states-property-coin',
    symbol: 'usp',
    name: 'USP Token',
  },
  {
    id: 'united-token',
    symbol: 'uted',
    name: 'United',
  },
  {
    id: 'uni-terminal',
    symbol: 'unit',
    name: 'Uni Terminal',
  },
  {
    id: 'uni-the-wonder-dog',
    symbol: 'uni',
    name: 'Uni the Wonder Dog',
  },
  {
    id: 'unit-protocol-duck',
    symbol: 'duck',
    name: 'Unit Protocol',
  },
  {
    id: 'unitrade',
    symbol: 'trade',
    name: 'Unitrade',
  },
  {
    id: 'units-limited-supply',
    symbol: 'uls',
    name: 'UNITS LIMITED SUPPLY',
  },
  {
    id: 'unitus',
    symbol: 'uis',
    name: 'Unitus',
  },
  {
    id: 'unitus-2',
    symbol: 'uts',
    name: 'Unitus',
  },
  {
    id: 'unitybot',
    symbol: 'unitybot',
    name: 'UnityBot',
  },
  {
    id: 'unitycore',
    symbol: 'ucore',
    name: 'UnityCore',
  },
  {
    id: 'unitymeta-token',
    symbol: 'umt',
    name: 'UnityMeta Token',
  },
  {
    id: 'unity-network',
    symbol: 'unt',
    name: 'Unity Network',
  },
  {
    id: 'unityventures',
    symbol: 'uv',
    name: 'Unityventures',
  },
  {
    id: 'unium',
    symbol: 'unm',
    name: 'UNIUM',
  },
  {
    id: 'universal-basic-income',
    symbol: 'ubi',
    name: 'Universal Basic Income',
  },
  {
    id: 'universal-contact',
    symbol: 'cwf',
    name: 'Universal Contact',
  },
  {
    id: 'universal-eth',
    symbol: 'unieth',
    name: 'Universal ETH',
  },
  {
    id: 'universal-liquidity-union',
    symbol: 'ulu',
    name: 'Universal Liquidity Union',
  },
  {
    id: 'universe-xyz',
    symbol: 'xyz',
    name: 'Universe.XYZ',
  },
  {
    id: 'universidad-de-chile-fan-token',
    symbol: 'uch',
    name: 'Universidad de Chile Fan Token',
  },
  {
    id: 'uniwar',
    symbol: 'uniwar',
    name: 'Uniwar',
  },
  {
    id: 'uniwhale',
    symbol: 'unw',
    name: 'Uniwhale',
  },
  {
    id: 'uniworld',
    symbol: 'unw',
    name: 'UniWorld',
  },
  {
    id: 'uniwswap',
    symbol: 'uniw',
    name: 'UniWswap',
  },
  {
    id: 'unix',
    symbol: 'unix',
    name: 'UniX',
  },
  {
    id: 'uni-yvault',
    symbol: 'yvuni',
    name: 'UNI yVault',
  },
  {
    id: 'unizen',
    symbol: 'zcx',
    name: 'Unizen',
  },
  {
    id: 'unleashclub',
    symbol: 'unleash',
    name: 'UnleashClub',
  },
  {
    id: 'unleashed-beast',
    symbol: 'beast',
    name: 'Unleashed Beast',
  },
  {
    id: 'unlend-finance',
    symbol: 'uft',
    name: 'UniLend Finance',
  },
  {
    id: 'unlimited-network-token',
    symbol: 'uwu',
    name: 'Unlimited Network Token',
  },
  {
    id: 'unlock',
    symbol: 'unlock',
    name: 'UNLOCK',
  },
  {
    id: 'unlock-maverick',
    symbol: 'unkmav',
    name: 'Unlock Maverick',
  },
  {
    id: 'unlock-protocol',
    symbol: 'udt',
    name: 'Unlock Protocol',
  },
  {
    id: 'unlucky',
    symbol: 'unlucky',
    name: 'UNLUCKY',
  },
  {
    id: 'unmarshal',
    symbol: 'marsh',
    name: 'Unmarshal',
  },
  {
    id: 'unobtanium',
    symbol: 'uno',
    name: 'Unobtanium',
  },
  {
    id: 'unobtanium-tezos',
    symbol: 'uno',
    name: 'Unobtanium Tezos',
  },
  {
    id: 'unodex',
    symbol: 'undx',
    name: 'UNODEX',
  },
  {
    id: 'uno-re',
    symbol: 'uno',
    name: 'Uno Re',
  },
  {
    id: 'unq',
    symbol: 'unq',
    name: 'Unique Venture clubs',
  },
  {
    id: 'unreal-finance',
    symbol: 'ugt',
    name: 'Unreal Finance',
  },
  {
    id: 'unsheth',
    symbol: 'ush',
    name: 'unshETHing_Token',
  },
  {
    id: 'unsheth-unsheth',
    symbol: 'unsheth',
    name: 'unshETH Ether',
  },
  {
    id: 'unstablestabledependabletoken',
    symbol: 'usdt',
    name: 'UnstableStableDependableToken',
  },
  {
    id: 'unstake-fi',
    symbol: 'nstk',
    name: 'Unstake',
  },
  {
    id: 'uns-token',
    symbol: 'uns',
    name: 'UNS Token',
  },
  {
    id: 'unstoppable-defi',
    symbol: 'und',
    name: 'Unstoppable DeFi',
  },
  {
    id: 'unvaxxed-sperm',
    symbol: 'nubtc',
    name: 'Unvaxxed Sperm',
  },
  {
    id: 'unvest',
    symbol: 'unv',
    name: 'Unvest',
  },
  {
    id: 'unwa',
    symbol: 'unwa',
    name: 'unwa',
  },
  {
    id: 'up',
    symbol: 'up',
    name: 'UP',
  },
  {
    id: 'upbots',
    symbol: 'ubxn',
    name: 'UpBots',
  },
  {
    id: 'upcx',
    symbol: 'upc',
    name: 'UPCX',
  },
  {
    id: 'updog',
    symbol: 'updog',
    name: 'UpDog',
  },
  {
    id: 'upfi-network',
    symbol: 'ups',
    name: 'UPFI Network',
  },
  {
    id: 'upfire',
    symbol: 'upr',
    name: 'Upfire',
  },
  {
    id: 'upfront-protocol',
    symbol: 'up',
    name: 'Upfront Protocol',
  },
  {
    id: 'uplexa',
    symbol: 'upx',
    name: 'uPlexa',
  },
  {
    id: 'uplift',
    symbol: 'lift',
    name: 'Uplift',
  },
  {
    id: 'uponly-token',
    symbol: 'upo',
    name: 'UpOnly',
  },
  {
    id: 'u-protocol',
    symbol: 'you',
    name: 'U Protocol',
  },
  {
    id: 'upsidedowncat',
    symbol: 'usdc',
    name: 'UpSideDownCat',
  },
  {
    id: 'upsorber',
    symbol: 'up',
    name: 'Upsorber',
  },
  {
    id: 'up-spiral',
    symbol: 'spiral',
    name: 'Spiral',
  },
  {
    id: 'upstabletoken',
    symbol: 'ustx',
    name: 'UpStable',
  },
  {
    id: 'up-token-2',
    symbol: 'up',
    name: 'uP Token',
  },
  {
    id: 'upx',
    symbol: 'upx',
    name: 'uPX',
  },
  {
    id: 'uquid-coin',
    symbol: 'uqc',
    name: 'Uquid Coin',
  },
  {
    id: 'ura-dex',
    symbol: 'ura',
    name: 'Ura',
  },
  {
    id: 'uramaki',
    symbol: 'maki',
    name: 'Uramaki',
  },
  {
    id: 'uranium3o8',
    symbol: 'u',
    name: 'Uranium3o8',
  },
  {
    id: 'uraniumx',
    symbol: 'urx',
    name: 'UraniumX',
  },
  {
    id: 'uranus-sol',
    symbol: 'anus',
    name: 'URANUS (SOL)',
  },
  {
    id: 'urdex-finance',
    symbol: 'urd',
    name: 'UrDEX Finance',
  },
  {
    id: 'ureeqa',
    symbol: 'urqa',
    name: 'UREEQA',
  },
  {
    id: 'urmom',
    symbol: 'urmom',
    name: 'URMOM',
  },
  {
    id: 'urubit',
    symbol: 'urub',
    name: 'Urubit',
  },
  {
    id: 'urus-token',
    symbol: 'urus',
    name: 'Aurox',
  },
  {
    id: 'usc-2',
    symbol: 'usc',
    name: 'USC',
  },
  {
    id: 'usd',
    symbol: 'usd+',
    name: 'Overnight.fi USD+',
  },
  {
    id: 'usd-balance',
    symbol: 'usdb',
    name: 'USD Balance',
  },
  {
    id: 'usd-coin',
    symbol: 'usdc',
    name: 'USDC',
  },
  {
    id: 'usd-coin-avalanche-bridged-usdc-e',
    symbol: 'usdc.e',
    name: 'Bridged USDC (Avalanche Bridge)',
  },
  {
    id: 'usd-coin-celer',
    symbol: 'ceusdc',
    name: 'Bridged USD Coin (Celer)',
  },
  {
    id: 'usd-coin-ethereum-bridged',
    symbol: 'usdc.e',
    name: 'Bridged USDC (Arbitrum)',
  },
  {
    id: 'usd-coin-nomad',
    symbol: 'nomadusdc',
    name: 'USD Coin - Nomad',
  },
  {
    id: 'usd-coin-plenty-bridge',
    symbol: 'usdc.e',
    name: 'Bridged USDC (Plenty Bridge)',
  },
  {
    id: 'usd-coin-pos-wormhole',
    symbol: 'usdcpo',
    name: 'Bridged USD Coin (Wormhole POS)',
  },
  {
    id: 'usd-coin-pulsechain',
    symbol: 'usdc',
    name: 'Bridged USD Coin (PulseChain)',
  },
  {
    id: 'usd-coin-wormhole-arb',
    symbol: 'usdcarb',
    name: 'Bridged USD Coin (Wormhole Arbitrum)',
  },
  {
    id: 'usd-coin-wormhole-bnb',
    symbol: 'usdcbnb',
    name: 'Bridged USD Coin (Wormhole BNB)',
  },
  {
    id: 'usd-coin-wormhole-from-ethereum',
    symbol: 'usdcet',
    name: 'Bridged USD Coin (Wormhole Ethereum)',
  },
  {
    id: 'usdc-plus-overnight',
    symbol: 'usdc+',
    name: 'Overnight.fi USDC+',
  },
  {
    id: 'usdc-rainbow-bridge',
    symbol: 'usdc.e',
    name: 'Bridged USDC (Rainbow Bridge)',
  },
  {
    id: 'usdc-yvault',
    symbol: 'yvusdc',
    name: 'USDC yVault',
  },
  {
    id: 'usdd',
    symbol: 'usdd',
    name: 'USDD',
  },
  {
    id: 'usde-2',
    symbol: 'usde',
    name: 'USDE (ERD)',
  },
  {
    id: 'usdebt',
    symbol: 'usdebt',
    name: 'USDEBT',
  },
  {
    id: 'usdex-8136b88a-eceb-4eaf-b910-9578cbc70136',
    symbol: 'usdex+',
    name: 'USDEX+',
  },
  {
    id: 'usdfi',
    symbol: 'usdfi',
    name: 'USDFI',
  },
  {
    id: 'usdfi-stable',
    symbol: 'stable',
    name: 'Stable',
  },
  {
    id: 'usdh',
    symbol: 'usdh',
    name: 'USDH',
  },
  {
    id: 'usdjpm',
    symbol: 'jpm',
    name: 'USDJPM',
  },
  {
    id: 'usdk',
    symbol: 'usdk',
    name: 'USDK',
  },
  {
    id: 'usd-mars',
    symbol: 'usdm',
    name: 'USD Mars',
  },
  {
    id: 'usdollhairs',
    symbol: 'usdh',
    name: 'USDOLLHAIRS',
  },
  {
    id: 'usdp',
    symbol: 'usdp',
    name: 'USDP Stablecoin',
  },
  {
    id: 'usdtez',
    symbol: 'usdtz',
    name: 'USDtez',
  },
  {
    id: 'usdtplus',
    symbol: 'usdt+',
    name: 'Overnight.fi USDT+',
  },
  {
    id: 'usdt-yvault',
    symbol: 'yvusdt',
    name: 'USDT yVault',
  },
  {
    id: 'usdv-2',
    symbol: 'usdv',
    name: 'USDV',
  },
  {
    id: 'usdx',
    symbol: 'usdx',
    name: 'USDX',
  },
  {
    id: 'usd-zee',
    symbol: 'usdz',
    name: 'USD ZEE',
  },
  {
    id: 'useless-utility',
    symbol: 'uu',
    name: 'Useless Utility',
  },
  {
    id: 'ushark',
    symbol: 'ushark',
    name: 'uShark Token',
  },
  {
    id: 'ushi',
    symbol: 'ushi',
    name: 'Ushi',
  },
  {
    id: 'usk',
    symbol: 'usk',
    name: 'USK',
  },
  {
    id: 'usp',
    symbol: 'usp',
    name: 'USP',
  },
  {
    id: 'utility-ape',
    symbol: '$banana',
    name: 'Utility Ape',
  },
  {
    id: 'utility-meta-token',
    symbol: 'umt',
    name: 'Utility Meta Token',
  },
  {
    id: 'utility-net',
    symbol: 'unc',
    name: 'Utility Net',
  },
  {
    id: 'utility-web3shot',
    symbol: 'uw3s',
    name: 'Utility Web3Shot',
  },
  {
    id: 'utopia',
    symbol: 'crp',
    name: 'Crypton',
  },
  {
    id: 'utopia-bot',
    symbol: 'ub',
    name: 'Utopia Bot',
  },
  {
    id: 'utopia-usd',
    symbol: 'uusd',
    name: 'Utopia USD',
  },
  {
    id: 'utrust',
    symbol: 'utk',
    name: 'xMoney',
  },
  {
    id: 'utu-coin',
    symbol: 'utu',
    name: 'UTU Coin',
  },
  {
    id: 'uwu-lend',
    symbol: 'uwu',
    name: 'UwU Lend',
  },
  {
    id: 'uxd-protocol-token',
    symbol: 'uxp',
    name: 'UXD Protocol',
  },
  {
    id: 'uxd-stablecoin',
    symbol: 'uxd',
    name: 'UXD Stablecoin',
  },
  {
    id: 'uzxcoin',
    symbol: 'uzx',
    name: 'UZXCoin',
  },
  {
    id: 'v3s-share',
    symbol: 'vshare',
    name: 'V3S Share',
  },
  {
    id: 'vabble',
    symbol: 'vab',
    name: 'Vabble',
  },
  {
    id: 'vader-protocol',
    symbol: 'vader',
    name: 'Vader Protocol',
  },
  {
    id: 'vai',
    symbol: 'vai',
    name: 'Vai',
  },
  {
    id: 'vaiot',
    symbol: 'vai',
    name: 'Vaiot',
  },
  {
    id: 'valas-finance',
    symbol: 'valas',
    name: 'Valas Finance',
  },
  {
    id: 'valencia-cf-fan-token',
    symbol: 'vcf',
    name: 'Valencia CF Fan Token',
  },
  {
    id: 'valentine-floki',
    symbol: 'toshe',
    name: 'TOSHE',
  },
  {
    id: 'valeria',
    symbol: 'val',
    name: 'Valeria',
  },
  {
    id: 'validao',
    symbol: 'vdo',
    name: 'ValiDAO',
  },
  {
    id: 'valleydao',
    symbol: 'grow',
    name: 'ValleyDAO',
  },
  {
    id: 'valobit',
    symbol: 'vbit',
    name: 'VALOBIT',
  },
  {
    id: 'value-liquidity',
    symbol: 'value',
    name: 'Value DeFi',
  },
  {
    id: 'vanar-chain',
    symbol: 'vanry',
    name: 'Vanar Chain',
  },
  {
    id: 'vana-world',
    symbol: 'vana',
    name: 'NIRVANA',
  },
  {
    id: 'vanguard-real-estate-tokenized-stock-defichain',
    symbol: 'dvnq',
    name: 'Vanguard Real Estate Tokenized Stock Defichain',
  },
  {
    id: 'vanguard-sp-500-etf-tokenized-stock-defichain',
    symbol: 'dvoo',
    name: 'Vanguard S&P 500 ETF Tokenized Stock Defichain',
  },
  {
    id: 'vanilla-2',
    symbol: 'bum',
    name: 'Vanilla',
  },
  {
    id: 'vanilla-network',
    symbol: 'vnla',
    name: 'Vanilla Network',
  },
  {
    id: 'vanity',
    symbol: 'vny',
    name: 'Vanity',
  },
  {
    id: 'vankia-chain',
    symbol: 'vkt',
    name: 'Vankia Chain',
  },
  {
    id: 'vaporfi',
    symbol: 'vape',
    name: 'VAPE',
  },
  {
    id: 'vapornodes',
    symbol: 'vpnd',
    name: 'VaporNodes',
  },
  {
    id: 'vaporum-coin',
    symbol: 'vprm',
    name: 'Vaporum Coin',
  },
  {
    id: 'vapor-wallet',
    symbol: 'vpr',
    name: 'Vapor Wallet',
  },
  {
    id: 'vaporwave',
    symbol: 'vwave',
    name: 'Vaporwave',
  },
  {
    id: 'vara-network',
    symbol: 'vara',
    name: 'Vara Network',
  },
  {
    id: 'varen',
    symbol: 'vrn',
    name: 'Varen',
  },
  {
    id: 'vasco-da-gama-fan-token',
    symbol: 'vasco',
    name: 'Vasco da Gama Fan Token',
  },
  {
    id: 'vaultcraft',
    symbol: 'vcx',
    name: 'VaultCraft',
  },
  {
    id: 'vaulteum',
    symbol: 'vault',
    name: 'Vaulteum',
  },
  {
    id: 'vault-hill-city',
    symbol: 'vhc',
    name: 'Vault Hill City',
  },
  {
    id: 'vaultka',
    symbol: 'vka',
    name: 'Vaultka',
  },
  {
    id: 'vaulttech',
    symbol: '$vault',
    name: 'VaultTech',
  },
  {
    id: 'vbswap',
    symbol: 'vbswap',
    name: 'vBSWAP',
  },
  {
    id: 'v-bucks',
    symbol: 'vbuck',
    name: 'V-BUCKS',
  },
  {
    id: 'vcash',
    symbol: 'xvc',
    name: 'Vcash',
  },
  {
    id: 'vcgamers',
    symbol: 'vcg',
    name: 'VCGamers',
  },
  {
    id: 'vcore',
    symbol: 'vcore',
    name: 'IMVU',
  },
  {
    id: 'veax',
    symbol: 'veax',
    name: 'Veax',
  },
  {
    id: 'vechain',
    symbol: 'vet',
    name: 'VeChain',
  },
  {
    id: 'veco',
    symbol: 'veco',
    name: 'Veco',
  },
  {
    id: 'vecrv-dao-yvault',
    symbol: 'yve-crvdao',
    name: 'veCRV-DAO yVault',
  },
  {
    id: 'vectorchat-ai',
    symbol: 'chat',
    name: 'VectorChat.ai',
  },
  {
    id: 'vector-finance',
    symbol: 'vtx',
    name: 'Vector Finance',
  },
  {
    id: 'vectorium',
    symbol: 'vect',
    name: 'Vectorium',
  },
  {
    id: 'vector-reserve',
    symbol: 'vec',
    name: 'Vector Reserve',
  },
  {
    id: 'vectorspace',
    symbol: 'vxv',
    name: 'Vectorspace AI',
  },
  {
    id: 'vector-space-biosciences-inc',
    symbol: 'sbio',
    name: 'Vector Space Biosciences, Inc.',
  },
  {
    id: 'vedao',
    symbol: 'weve',
    name: 'veDAO',
  },
  {
    id: 'vee-finance',
    symbol: 'vee',
    name: 'Vee Finance',
  },
  {
    id: 'vega-2',
    symbol: 'vega',
    name: 'VEGA',
  },
  {
    id: 'vega-coin',
    symbol: 'vega',
    name: 'Vega Coin',
  },
  {
    id: 'vega-protocol',
    symbol: 'vega',
    name: 'Vega Protocol',
  },
  {
    id: 'vegasbot',
    symbol: 'vegas',
    name: 'VegasBot',
  },
  {
    id: 'vegasino',
    symbol: 'vegas',
    name: 'Vegasino',
  },
  {
    id: 'veggiecoin',
    symbol: 'vegi',
    name: 'VeggieCoin',
  },
  {
    id: 'vehicle-mining-system',
    symbol: 'vms',
    name: 'Vehicle Mining System',
  },
  {
    id: 'veil',
    symbol: 'veil',
    name: 'VEIL',
  },
  {
    id: 'veil-exchange',
    symbol: 'veil',
    name: 'Veil Exchange',
  },
  {
    id: 'velas',
    symbol: 'vlx',
    name: 'Velas',
  },
  {
    id: 'velaspad',
    symbol: 'vlxpad',
    name: 'VelasPad',
  },
  {
    id: 'vela-token',
    symbol: 'vela',
    name: 'Vela Token',
  },
  {
    id: 'veldorabsc',
    symbol: 'vdora',
    name: 'VeldoraBSC',
  },
  {
    id: 'velhalla',
    symbol: 'scar',
    name: 'ScarQuest',
  },
  {
    id: 'velo',
    symbol: 'velo',
    name: 'Velo',
  },
  {
    id: 'veloce-vext',
    symbol: 'vext',
    name: 'Veloce',
  },
  {
    id: 'velocimeter-flow',
    symbol: 'flow',
    name: 'Velocimeter FLOW',
  },
  {
    id: 'velocore',
    symbol: 'vc',
    name: 'Velocore',
  },
  {
    id: 'velocore-waifu',
    symbol: 'waifu',
    name: 'Waifu by Velocore',
  },
  {
    id: 'velodrome-finance',
    symbol: 'velo',
    name: 'Velodrome Finance',
  },
  {
    id: 'velorex',
    symbol: 'vex',
    name: 'Velorex',
  },
  {
    id: 'velosbot',
    symbol: 'velos',
    name: 'VelosBot',
  },
  {
    id: 'vemate',
    symbol: 'vmt',
    name: 'Vemate',
  },
  {
    id: 'vempire-ddao',
    symbol: 'vemp',
    name: 'VEMP',
  },
  {
    id: 'veno-finance',
    symbol: 'vno',
    name: 'Veno Finance',
  },
  {
    id: 'venom',
    symbol: 'venom',
    name: 'Venom',
  },
  {
    id: 'venox',
    symbol: 'vnx',
    name: 'Venox',
  },
  {
    id: 'vent-finance',
    symbol: 'vent',
    name: 'Vent Finance',
  },
  {
    id: 'vention',
    symbol: 'vention',
    name: 'Vention',
  },
  {
    id: 'ventiswap',
    symbol: 'vst',
    name: 'VentiSwap',
  },
  {
    id: 'venture-coin-2',
    symbol: 'vc',
    name: 'Venture Coin',
  },
  {
    id: 'venus',
    symbol: 'xvs',
    name: 'Venus',
  },
  {
    id: 'venus-bch',
    symbol: 'vbch',
    name: 'Venus BCH',
  },
  {
    id: 'venus-beth',
    symbol: 'vbeth',
    name: 'Venus BETH',
  },
  {
    id: 'venus-btc',
    symbol: 'vbtc',
    name: 'Venus BTC',
  },
  {
    id: 'venus-busd',
    symbol: 'vbusd',
    name: 'Venus BUSD',
  },
  {
    id: 'venus-dai',
    symbol: 'vdai',
    name: 'Venus DAI',
  },
  {
    id: 'venus-doge',
    symbol: 'vdoge',
    name: 'Venus DOGE',
  },
  {
    id: 'venus-dot',
    symbol: 'vdot',
    name: 'Venus DOT',
  },
  {
    id: 'venus-eth',
    symbol: 'veth',
    name: 'Venus ETH',
  },
  {
    id: 'venus-fil',
    symbol: 'vfil',
    name: 'Venus FIL',
  },
  {
    id: 'venus-link',
    symbol: 'vlink',
    name: 'Venus LINK',
  },
  {
    id: 'venus-ltc',
    symbol: 'vltc',
    name: 'Venus LTC',
  },
  {
    id: 'venus-reward-token',
    symbol: 'vrt',
    name: 'Venus Reward',
  },
  {
    id: 'venus-sxp',
    symbol: 'vsxp',
    name: 'Venus SXP',
  },
  {
    id: 'venus-usdc',
    symbol: 'vusdc',
    name: 'Venus USDC',
  },
  {
    id: 'venus-usdt',
    symbol: 'vusdt',
    name: 'Venus USDT',
  },
  {
    id: 'venus-xrp',
    symbol: 'vxrp',
    name: 'Venus XRP',
  },
  {
    id: 'venus-xvs',
    symbol: 'vxvs',
    name: 'Venus XVS',
  },
  {
    id: 'vera',
    symbol: 'vera',
    name: 'Vera',
  },
  {
    id: 'veraone',
    symbol: 'vro',
    name: 'VeraOne',
  },
  {
    id: 'verasity',
    symbol: 'vra',
    name: 'Verasity',
  },
  {
    id: 'verge',
    symbol: 'xvg',
    name: 'Verge',
  },
  {
    id: 'verge-eth',
    symbol: 'xvg',
    name: 'Verge (ETH)',
  },
  {
    id: 'verida',
    symbol: 'vda',
    name: 'Verida',
  },
  {
    id: 'verified-usd-foundation-usdv',
    symbol: 'usdv',
    name: 'Verified USD',
  },
  {
    id: 'verify-authentificator-bot',
    symbol: 'verify',
    name: 'Verify Authentificator Bot',
  },
  {
    id: 'veritaseum',
    symbol: 'veri',
    name: 'Veritaseum',
  },
  {
    id: 'veritise',
    symbol: 'vts',
    name: 'Veritise',
  },
  {
    id: 'veriumreserve',
    symbol: 'vrm',
    name: 'VeriumReserve',
  },
  {
    id: 'verox',
    symbol: 'vrx',
    name: 'Verox',
  },
  {
    id: 'versagames',
    symbol: 'versa',
    name: 'VersaGames',
  },
  {
    id: 'verse-bitcoin',
    symbol: 'verse',
    name: 'Verse',
  },
  {
    id: 'verso',
    symbol: 'vso',
    name: 'Verso',
  },
  {
    id: 'versoview',
    symbol: 'vvt',
    name: 'VersoView',
  },
  {
    id: 'versus-2',
    symbol: 'vs',
    name: 'Versus',
  },
  {
    id: 'vertcoin',
    symbol: 'vtc',
    name: 'Vertcoin',
  },
  {
    id: 'vertek',
    symbol: 'vrtk',
    name: 'Vertek',
  },
  {
    id: 'vertex-protocol',
    symbol: 'vrtx',
    name: 'Vertex Protocol',
  },
  {
    id: 'verus-coin',
    symbol: 'vrsc',
    name: 'Verus Coin',
  },
  {
    id: 'very-special-dragon',
    symbol: 'vito',
    name: 'Very Special Dragon',
  },
  {
    id: 'vesper-finance',
    symbol: 'vsp',
    name: 'Vesper Finance',
  },
  {
    id: 'vesta-finance',
    symbol: 'vsta',
    name: 'Vesta Finance',
  },
  {
    id: 'vesta-stable',
    symbol: 'vst',
    name: 'Vesta Stable',
  },
  {
    id: 'vestige',
    symbol: 'vest',
    name: 'Vestige',
  },
  {
    id: 'vesync',
    symbol: 'vs',
    name: 'veSync',
  },
  {
    id: 'vethor-token',
    symbol: 'vtho',
    name: 'VeThor',
  },
  {
    id: 'vetme',
    symbol: 'vetme',
    name: 'VetMe',
  },
  {
    id: 'vetter-skylabs',
    symbol: 'vsl',
    name: 'Vetter Skylabs',
  },
  {
    id: 'vetter-token',
    symbol: 'vetter',
    name: 'Vetter',
  },
  {
    id: 'veve',
    symbol: 'veve',
    name: 'VEVE',
  },
  {
    id: 'vexanium',
    symbol: 'vex',
    name: 'Vexanium',
  },
  {
    id: 'vfox',
    symbol: 'vfox',
    name: 'VFOX',
  },
  {
    id: 'viacoin',
    symbol: 'via',
    name: 'Viacoin',
  },
  {
    id: 'vibe',
    symbol: 'vibe',
    name: 'VIBE',
  },
  {
    id: 'viberate',
    symbol: 'vib',
    name: 'Viberate',
  },
  {
    id: 'vibing',
    symbol: 'vbg',
    name: 'Vibing',
  },
  {
    id: 'vibingcattoken',
    symbol: 'vct',
    name: 'VibingCatToken',
  },
  {
    id: 'vicat',
    symbol: 'vicat',
    name: 'ViCat',
  },
  {
    id: 'vica-token',
    symbol: 'vica',
    name: 'ViCA',
  },
  {
    id: 'vicicoin',
    symbol: 'vcnt',
    name: 'ViciCoin',
  },
  {
    id: 'victoria-vr',
    symbol: 'vr',
    name: 'Victoria VR',
  },
  {
    id: 'victorum',
    symbol: 'vcc',
    name: 'Victorum',
  },
  {
    id: 'victory-gem',
    symbol: 'vtg',
    name: 'Victory Gem',
  },
  {
    id: 'victory-impact',
    symbol: 'vic',
    name: 'Victory Impact',
  },
  {
    id: 'vicuna',
    symbol: 'vina',
    name: 'VICUNA',
  },
  {
    id: 'vidt-dao',
    symbol: 'vidt',
    name: 'VIDT DAO',
  },
  {
    id: 'vidulum',
    symbol: 'vdl',
    name: 'Vidulum',
  },
  {
    id: 'vidy',
    symbol: 'vidy',
    name: 'VIDY',
  },
  {
    id: 'vidya',
    symbol: 'vidya',
    name: 'Vidya',
  },
  {
    id: 'vidyx',
    symbol: 'vidyx',
    name: 'VidyX',
  },
  {
    id: 'vigorus',
    symbol: 'vis',
    name: 'Vigorus',
  },
  {
    id: 'viking-elon',
    symbol: 'velon',
    name: 'Viking Elon',
  },
  {
    id: 'vimmer',
    symbol: 'viz',
    name: 'Vim',
  },
  {
    id: 'vimverse',
    symbol: 'vim',
    name: 'Vimverse',
  },
  {
    id: 'vindax-coin',
    symbol: 'vd',
    name: 'VinDax Coin',
  },
  {
    id: 'vinlink',
    symbol: 'vnlnk',
    name: 'Vinlink',
  },
  {
    id: 'vinuchain',
    symbol: 'vc',
    name: 'VinuChain',
  },
  {
    id: 'vinu-network',
    symbol: 'vnn',
    name: 'VINU Network',
  },
  {
    id: 'vip-coin',
    symbol: 'vip',
    name: 'Vip Coin',
  },
  {
    id: 'viplus',
    symbol: 'vpl',
    name: 'Viplus',
  },
  {
    id: 'vip-token',
    symbol: 'vip',
    name: 'VIP',
  },
  {
    id: 'viral-inu',
    symbol: 'vinu',
    name: 'Viral Inu',
  },
  {
    id: 'viralsniper',
    symbol: 'viral',
    name: 'Viralsniper',
  },
  {
    id: 'virgo',
    symbol: 'vgo',
    name: 'Virgo',
  },
  {
    id: 'viridis-network',
    symbol: 'vrd',
    name: 'Viridis Network',
  },
  {
    id: 'virtual-coin',
    symbol: 'vrc',
    name: 'Virtual Coin',
  },
  {
    id: 'virtual-protocol',
    symbol: 'virtual',
    name: 'Virtual Protocol',
  },
  {
    id: 'virtual-reality-glasses',
    symbol: 'vrg',
    name: 'Virtual Reality Glasses',
  },
  {
    id: 'virtual-ride-token',
    symbol: 'vrt',
    name: 'Virtual Ride Token',
  },
  {
    id: 'virtual-tourist',
    symbol: 'vt',
    name: 'Virtual Tourist',
  },
  {
    id: 'virtual-trader',
    symbol: 'vtr',
    name: 'Virtual Trader',
  },
  {
    id: 'virtual-versions',
    symbol: 'vv',
    name: 'Virtual Versions',
  },
  {
    id: 'virtual-x',
    symbol: 'vrl',
    name: 'VIRTUAL X',
  },
  {
    id: 'virtue-poker',
    symbol: 'vpp',
    name: 'Virtue Poker Points',
  },
  {
    id: 'virtuswap',
    symbol: 'vrsw',
    name: 'VirtuSwap',
  },
  {
    id: 'vishai',
    symbol: 'vish',
    name: 'VishAI',
  },
  {
    id: 'vision-city',
    symbol: 'viz',
    name: 'Vision City',
  },
  {
    id: 'visiongame',
    symbol: 'vision',
    name: 'VisionGame',
  },
  {
    id: 'vision-metaverse',
    symbol: 'vs',
    name: 'Vision Metaverse',
  },
  {
    id: 'vitadao',
    symbol: 'vita',
    name: 'VitaDAO',
  },
  {
    id: 'vita-inu',
    symbol: 'vinu',
    name: 'Vita Inu',
  },
  {
    id: 'vitalikmum',
    symbol: 'vmum',
    name: 'VitalikMum',
  },
  {
    id: 'vitality',
    symbol: 'vita',
    name: 'Vitality',
  },
  {
    id: 'vitalxp',
    symbol: 'vital',
    name: 'VitalXP',
  },
  {
    id: 'vitamin-coin',
    symbol: 'vitc',
    name: 'Vitamin Coin',
  },
  {
    id: 'vite',
    symbol: 'vite',
    name: 'Vite',
  },
  {
    id: 'viterium',
    symbol: 'vt',
    name: 'Viterium',
  },
  {
    id: 'vitex',
    symbol: 'vx',
    name: 'ViteX Coin',
  },
  {
    id: 'vitnixx',
    symbol: 'vtc',
    name: 'VitnixX',
  },
  {
    id: 'vitra-studios',
    symbol: 'vitra',
    name: 'Vitra Studios',
  },
  {
    id: 'vitteey',
    symbol: 'vity',
    name: 'Vitteey',
  },
  {
    id: 'viva',
    symbol: 'viva',
    name: 'Viva',
  },
  {
    id: 'vivex',
    symbol: '$vivx',
    name: 'Vivex',
  },
  {
    id: 'vivo',
    symbol: 'vivo',
    name: 'VIVO',
  },
  {
    id: 'vixco',
    symbol: 'vix',
    name: 'Vixco',
  },
  {
    id: 'vizslaswap',
    symbol: 'vizslaswap',
    name: 'VizslaSwap',
  },
  {
    id: 'vlaunch',
    symbol: 'vpad',
    name: 'VLaunch',
  },
  {
    id: 'vmex',
    symbol: 'vmex',
    name: 'VMEX',
  },
  {
    id: 'vmpx',
    symbol: 'vmpx',
    name: 'VMPX',
  },
  {
    id: 'vmpx-erc20',
    symbol: 'vmpx',
    name: 'VMPX (ERC20)',
  },
  {
    id: 'vndc',
    symbol: 'vndc',
    name: 'VNDC',
  },
  {
    id: 'vnst-stablecoin',
    symbol: 'vnst',
    name: 'VNST Stablecoin',
  },
  {
    id: 'vnx-euro',
    symbol: 'veur',
    name: 'VNX EURO',
  },
  {
    id: 'vnx-exchange',
    symbol: 'vnxlu',
    name: 'VNX Exchange',
  },
  {
    id: 'vnx-gold',
    symbol: 'vnxau',
    name: 'VNX Gold',
  },
  {
    id: 'vnx-swiss-franc',
    symbol: 'vchf',
    name: 'VNX Swiss Franc',
  },
  {
    id: 'vocare-ex-machina',
    symbol: 'vocare',
    name: 'Vocare ex Machina',
  },
  {
    id: 'vodra',
    symbol: 'vdr',
    name: 'Vodra',
  },
  {
    id: 'voice-street',
    symbol: 'vst',
    name: 'Voice Street',
  },
  {
    id: 'void-games',
    symbol: 'void',
    name: 'Void Games',
  },
  {
    id: 'volare-network',
    symbol: 'volr',
    name: 'Volare Network',
  },
  {
    id: 'voldemorttrumprobotnik-10neko',
    symbol: 'ethereum',
    name: 'VoldemortTrumpRobotnik-10Neko',
  },
  {
    id: 'volentix-vtx',
    symbol: 'vtx',
    name: 'Volentix',
  },
  {
    id: 'volley',
    symbol: 'voy',
    name: 'Volley',
  },
  {
    id: 'volo-staked-sui',
    symbol: 'vsui',
    name: 'Volo Staked SUI',
  },
  {
    id: 'volta-club',
    symbol: 'volta',
    name: 'Volta Club',
  },
  {
    id: 'voltage',
    symbol: 'volt',
    name: 'Voltage',
  },
  {
    id: 'volta-protocol',
    symbol: 'volta',
    name: 'Volta Protocol',
  },
  {
    id: 'volt-inu-2',
    symbol: 'volt',
    name: 'Volt Inu',
  },
  {
    id: 'voltswap',
    symbol: 'volt',
    name: 'VoltSwap',
  },
  {
    id: 'volumint',
    symbol: 'vmint',
    name: 'VoluMint',
  },
  {
    id: 'voodoo',
    symbol: 'ldz',
    name: 'Voodoo',
  },
  {
    id: 'vortex-ai',
    symbol: 'vxai',
    name: 'Vortex AI',
  },
  {
    id: 'vortex-protocol',
    symbol: 'vp',
    name: 'Vortex Protocol',
  },
  {
    id: 'voucher-dot',
    symbol: 'vdot',
    name: 'Voucher DOT',
  },
  {
    id: 'voucher-ethereum-2-0',
    symbol: 'veth',
    name: 'Voucher Ethereum 2.0',
  },
  {
    id: 'voucher-glmr',
    symbol: 'vglmr',
    name: 'Voucher GLMR',
  },
  {
    id: 'voucher-ksm',
    symbol: 'vksm',
    name: 'Voucher KSM',
  },
  {
    id: 'voucher-movr',
    symbol: 'vmovr',
    name: 'Voucher MOVR',
  },
  {
    id: 'vow',
    symbol: 'vow',
    name: 'Vow',
  },
  {
    id: 'voxel-x-network',
    symbol: 'vxl',
    name: 'Voxel X Network',
  },
  {
    id: 'voxies',
    symbol: 'voxel',
    name: 'Voxies',
  },
  {
    id: 'voxnet',
    symbol: 'vxon',
    name: 'VoxNET',
  },
  {
    id: 'voxto',
    symbol: 'vxt',
    name: 'VOXTO',
  },
  {
    id: 'voy-finance',
    symbol: 'voy',
    name: 'Voy Finance',
  },
  {
    id: 'vrmars',
    symbol: 'vrm',
    name: 'VRMARS',
  },
  {
    id: 'vsolidus',
    symbol: 'vsol',
    name: 'VSolidus',
  },
  {
    id: 'v-systems',
    symbol: 'vsys',
    name: 'V.SYSTEMS',
  },
  {
    id: 'vtro',
    symbol: 'vtro',
    name: 'VTRO',
  },
  {
    id: 'vulcan-forged',
    symbol: 'pyr',
    name: 'Vulcan Forged',
  },
  {
    id: 'vulkania-2',
    symbol: 'vlk',
    name: 'Vulkania',
  },
  {
    id: 'vulture-peak',
    symbol: 'vpk',
    name: 'Vulture Peak',
  },
  {
    id: 'vuzzmind',
    symbol: 'vuzz',
    name: 'VuzzMind',
  },
  {
    id: 'vvs-finance',
    symbol: 'vvs',
    name: 'VVS Finance',
  },
  {
    id: 'vxdefi',
    symbol: 'vxdefi',
    name: 'vXDEFI',
  },
  {
    id: 'vyfinance',
    symbol: 'vyfi',
    name: 'VyFinance',
  },
  {
    id: 'vyvo-smart-chain',
    symbol: 'vsc',
    name: 'Vyvo Smart Chain',
  },
  {
    id: 'vyvo-us-dollar',
    symbol: 'usdv',
    name: 'Vyvo US Dollar',
  },
  {
    id: 'vzzn',
    symbol: 'vzzn',
    name: 'VZZN',
  },
  {
    id: 'w3gamez-network',
    symbol: 'w3g',
    name: 'W3Gamez Network',
  },
  {
    id: 'wadzpay-token',
    symbol: 'wtk',
    name: 'WadzPay',
  },
  {
    id: 'wager',
    symbol: 'vs',
    name: 'Wager',
  },
  {
    id: 'wageron',
    symbol: 'wager',
    name: 'WagerOn',
  },
  {
    id: 'wagerr',
    symbol: 'wgr',
    name: 'Wagerr',
  },
  {
    id: 'waggle-network',
    symbol: 'wag',
    name: 'Waggle Network',
  },
  {
    id: 'wagie-bot',
    symbol: 'wagiebot',
    name: 'Wagie Bot',
  },
  {
    id: 'wagmi-2',
    symbol: 'wagmi',
    name: 'Wagmi',
  },
  {
    id: 'wagmicatgirlkanye420etfmoon1000x',
    symbol: 'hood',
    name: 'wagmicatgirlkanye420etfmoon1000x',
  },
  {
    id: 'wagmi-coin',
    symbol: 'wagmi',
    name: 'Wagmi Coin',
  },
  {
    id: 'wagmi-game-2',
    symbol: 'wagmigames',
    name: 'WAGMI Games',
  },
  {
    id: 'wagmi-on-solana',
    symbol: 'wagmi',
    name: 'WAGMI On Solana',
  },
  {
    id: 'wagmi-token',
    symbol: 'wag',
    name: 'WAGMI Token',
  },
  {
    id: 'wagyu',
    symbol: '$wagyu',
    name: 'Wagyu',
  },
  {
    id: 'wagyuswap',
    symbol: 'wag',
    name: 'WagyuSwap',
  },
  {
    id: 'waifer',
    symbol: 'waif',
    name: 'Waifer',
  },
  {
    id: 'waifu',
    symbol: 'waifu',
    name: 'Waifu',
  },
  {
    id: 'waifuai',
    symbol: 'wfai',
    name: 'WaifuAI',
  },
  {
    id: 'walc',
    symbol: '$walc',
    name: 'WALC',
  },
  {
    id: 'walken',
    symbol: 'wlkn',
    name: 'Walken',
  },
  {
    id: 'walk-up',
    symbol: 'wut',
    name: 'Walk Up',
  },
  {
    id: 'wallet-defi',
    symbol: 'wdf',
    name: 'Wallet Defi',
  },
  {
    id: 'walletika',
    symbol: 'wltk',
    name: 'Walletika',
  },
  {
    id: 'walletnow',
    symbol: 'wnow',
    name: 'WalletNow',
  },
  {
    id: 'wallet-safu',
    symbol: 'wsafu',
    name: 'Wallet SAFU',
  },
  {
    id: 'wallet-sniffer',
    symbol: 'bo',
    name: 'Wallet Sniffer',
  },
  {
    id: 'wall-street-baby',
    symbol: 'wsb',
    name: 'Wall Street Baby',
  },
  {
    id: 'wall-street-bets',
    symbol: 'wsb',
    name: 'Wall Street Bets',
  },
  {
    id: 'wall-street-bets-dapp',
    symbol: 'wsb',
    name: 'WallStreetBets DApp',
  },
  {
    id: 'wall-street-games',
    symbol: 'wsg',
    name: 'Wall Street Games',
  },
  {
    id: 'wall-street-memes',
    symbol: 'wsm',
    name: 'Wall Street Memes',
  },
  {
    id: 'wall-street-pepes',
    symbol: 'wsp',
    name: 'Wall Street Pepes',
  },
  {
    id: 'wally-bot',
    symbol: 'wally',
    name: 'Wally Bot',
  },
  {
    id: 'walrus',
    symbol: 'wlrs',
    name: 'Walrus',
  },
  {
    id: 'walter-inu',
    symbol: '$winu',
    name: 'Walter Inu',
  },
  {
    id: 'waltonchain',
    symbol: 'wtc',
    name: 'Waltonchain',
  },
  {
    id: 'wam',
    symbol: 'wam',
    name: 'Wam',
  },
  {
    id: 'wanaka-farm',
    symbol: 'wana',
    name: 'Wanaka Farm',
  },
  {
    id: 'wanbtc',
    symbol: 'wanbtc',
    name: 'wanBTC',
  },
  {
    id: 'wanchain',
    symbol: 'wan',
    name: 'Wanchain',
  },
  {
    id: 'waneth',
    symbol: 'waneth',
    name: 'wanETH',
  },
  {
    id: 'wannaswap',
    symbol: 'wanna',
    name: 'WannaSwap',
  },
  {
    id: 'wanswap',
    symbol: 'wasp',
    name: 'WanSwap [OLD]',
  },
  {
    id: 'wanswap-2',
    symbol: 'wasp',
    name: 'WanSwap',
  },
  {
    id: 'wanusdc',
    symbol: 'wanusdc',
    name: 'Bridged USD Coin (Wanchain)',
  },
  {
    id: 'wanusdt',
    symbol: 'wanusdt',
    name: 'Bridged Tether (Wanchain)',
  },
  {
    id: 'wanxrp',
    symbol: 'wanxrp',
    name: 'wanXRP',
  },
  {
    id: 'wap-ordinals',
    symbol: '$wap',
    name: '$wap (Ordinals)',
  },
  {
    id: 'war-bond',
    symbol: 'wbond',
    name: 'War Bond',
  },
  {
    id: 'war-coin',
    symbol: 'war',
    name: 'War Coin',
  },
  {
    id: 'warena',
    symbol: 'rena',
    name: 'Warena',
  },
  {
    id: 'warioxrpdumbledoreyugioh69inu',
    symbol: 'xrp',
    name: 'WarioXRPDumbledoreYugioh69Inu',
  },
  {
    id: 'warlegends',
    symbol: 'war',
    name: 'War Legends',
  },
  {
    id: 'warp-cash',
    symbol: 'warp',
    name: 'Warp Cash',
  },
  {
    id: 'warped-games',
    symbol: 'warped',
    name: 'Warped Games',
  },
  {
    id: 'warp-finance',
    symbol: 'warp',
    name: 'Warp Finance',
  },
  {
    id: 'warrior-empires',
    symbol: 'chaos',
    name: 'Warrior Empires',
  },
  {
    id: 'wasder',
    symbol: 'was',
    name: 'Wasder',
  },
  {
    id: 'wash',
    symbol: 'wash',
    name: 'WASH',
  },
  {
    id: 'wassie',
    symbol: 'wassie',
    name: 'WASSIE',
  },
  {
    id: 'waste-coin',
    symbol: 'waco',
    name: 'Waste Digital Coin',
  },
  {
    id: 'watchdo',
    symbol: 'wdo',
    name: 'WatchDO',
  },
  {
    id: 'watchtowers-ai',
    symbol: 'wts',
    name: 'WatchTowers AI',
  },
  {
    id: 'wateenswap',
    symbol: 'wtn',
    name: 'Wateenswap',
  },
  {
    id: 'water-2',
    symbol: 'water',
    name: 'WATER',
  },
  {
    id: 'water-bsc',
    symbol: 'water',
    name: 'WATER (BSC)',
  },
  {
    id: 'waterfall-finance',
    symbol: 'waterfall',
    name: 'Waterfall Finance',
  },
  {
    id: 'waterfall-governance-token',
    symbol: 'wtf',
    name: 'Waterfall Governance',
  },
  {
    id: 'water-rabbit',
    symbol: 'war',
    name: 'Water Rabbit',
  },
  {
    id: 'wattton',
    symbol: 'watt',
    name: 'WATTTON',
  },
  {
    id: 'waultswap',
    symbol: 'wex',
    name: 'WaultSwap',
  },
  {
    id: 'wavelength',
    symbol: 'wave',
    name: 'Wavelength',
  },
  {
    id: 'waves',
    symbol: 'waves',
    name: 'Waves',
  },
  {
    id: 'waves-ducks',
    symbol: 'egg',
    name: 'Waves Ducks',
  },
  {
    id: 'waves-enterprise',
    symbol: 'west',
    name: 'Waves Enterprise',
  },
  {
    id: 'waves-exchange',
    symbol: 'wx',
    name: 'WX Network Token',
  },
  {
    id: 'wawacat',
    symbol: 'wawa',
    name: 'wawacat',
  },
  {
    id: 'waweswaps-global-token',
    symbol: 'gbl',
    name: 'WaweSwaps Global Token',
  },
  {
    id: 'wax',
    symbol: 'waxp',
    name: 'WAX',
  },
  {
    id: 'waxe',
    symbol: 'waxe',
    name: 'WAXE',
  },
  {
    id: 'wayawolfcoin',
    symbol: 'ww',
    name: 'WayaWolfCoin',
  },
  {
    id: 'waykichain',
    symbol: 'wicc',
    name: 'WaykiChain',
  },
  {
    id: 'waykichain-governance-coin',
    symbol: 'wgrt',
    name: 'WaykiChain Governance Coin',
  },
  {
    id: 'wazirx',
    symbol: 'wrx',
    name: 'WazirX',
  },
  {
    id: 'wbnb',
    symbol: 'wbnb',
    name: 'Wrapped BNB',
  },
  {
    id: 'wbtc-plenty-bridge',
    symbol: 'wbtc.e',
    name: 'WBTC (Plenty Bridge)',
  },
  {
    id: 'wbtc-yvault',
    symbol: 'yvwbtc',
    name: 'WBTC yVault',
  },
  {
    id: 'wcapes',
    symbol: 'wca',
    name: 'WCAPES',
  },
  {
    id: 'wdot',
    symbol: 'wdot',
    name: 'WDOT',
  },
  {
    id: 'we2net',
    symbol: 'we2net',
    name: 'We2net',
  },
  {
    id: 'we-all-got-mantle-illness',
    symbol: 'wagmi',
    name: 'We All Got Mantle Illness',
  },
  {
    id: 'wealthsecrets',
    symbol: 'wsc',
    name: 'WealthSecrets',
  },
  {
    id: 'we-are-all-richard',
    symbol: 'waar',
    name: 'We Are All Richard',
  },
  {
    id: 'web',
    symbol: 'web',
    name: 'Web',
  },
  {
    id: 'web3-bets',
    symbol: 'bxb',
    name: 'Web3 Bets',
  },
  {
    id: 'web3camp',
    symbol: '3p',
    name: 'Web3Camp',
  },
  {
    id: 'web3frontier',
    symbol: 'w3f',
    name: 'Web3Frontier',
  },
  {
    id: 'web3games-com-token',
    symbol: 'wgt',
    name: 'Web3Games.com Token',
  },
  {
    id: 'web3-no-value',
    symbol: 'w3n',
    name: 'Web3 No Value',
  },
  {
    id: 'web3shot',
    symbol: 'w3s',
    name: 'Web3Shot',
  },
  {
    id: 'web3tools',
    symbol: 'web3t',
    name: 'Web3Tools',
  },
  {
    id: 'web3war',
    symbol: 'fps',
    name: 'web3war',
  },
  {
    id: 'web4-ai',
    symbol: 'web4',
    name: 'WEB4 AI',
  },
  {
    id: 'web-ai',
    symbol: 'webai',
    name: 'Web AI',
  },
  {
    id: 'webcash',
    symbol: 'web',
    name: 'Webcash',
  },
  {
    id: 'webchain',
    symbol: 'mintme',
    name: 'MintMe.com Coin',
  },
  {
    id: 'web-four',
    symbol: 'webfour',
    name: 'WEBFOUR',
  },
  {
    id: 'weble-ecosystem-token',
    symbol: 'wet',
    name: 'Weble Ecosystem',
  },
  {
    id: 'websea',
    symbol: 'wbs',
    name: 'Websea',
  },
  {
    id: 'website-ai',
    symbol: 'webai',
    name: 'Website AI',
  },
  {
    id: 'webuy',
    symbol: 'we',
    name: 'WeBuy',
  },
  {
    id: 'wecan',
    symbol: 'wecan',
    name: 'Wecan',
  },
  {
    id: 'wecashcoin',
    symbol: 'wch',
    name: 'WeCash',
  },
  {
    id: 'wecoin',
    symbol: 'weco',
    name: 'WECOIN',
  },
  {
    id: 'wecoown',
    symbol: 'wcx',
    name: 'WeCoOwn',
  },
  {
    id: 'wednesday-inu',
    symbol: 'wed',
    name: 'Wednesday Inu',
  },
  {
    id: 'wefi-finance',
    symbol: 'wefi',
    name: 'Wefi Finance',
  },
  {
    id: 'weft-finance',
    symbol: 'weft',
    name: 'Weft Finance',
  },
  {
    id: 'wegro',
    symbol: 'wegro',
    name: 'WeGro',
  },
  {
    id: 'weld',
    symbol: 'weld',
    name: 'WELD',
  },
  {
    id: 'wellnode',
    symbol: 'wend',
    name: 'Wellnode',
  },
  {
    id: 'welsh-corgi-coin',
    symbol: 'welsh',
    name: 'WELSH CORGI COIN',
  },
  {
    id: 'welups-blockchain',
    symbol: 'welups',
    name: 'Welups Blockchain',
  },
  {
    id: 'wemergetoken',
    symbol: 'mrg',
    name: 'WemergeToken',
  },
  {
    id: 'wemix-token',
    symbol: 'wemix',
    name: 'WEMIX',
  },
  {
    id: 'wen',
    symbol: '$wen',
    name: 'WEN',
  },
  {
    id: 'wen-2',
    symbol: '$wen',
    name: 'WEN',
  },
  {
    id: 'wen-3',
    symbol: 'wen',
    name: 'WEN',
  },
  {
    id: 'wen-4',
    symbol: '$wen',
    name: 'Wen',
  },
  {
    id: 'wen-token',
    symbol: 'wen',
    name: 'WEN Token',
  },
  {
    id: 'wepiggy-coin',
    symbol: 'wpc',
    name: 'WePiggy Coin',
  },
  {
    id: 'wepower',
    symbol: 'wpr',
    name: 'WePower',
  },
  {
    id: 'wesendit',
    symbol: 'wsi',
    name: 'WeSendit',
  },
  {
    id: 'westarter',
    symbol: 'war',
    name: 'WeStarter',
  },
  {
    id: 'wetc-hebeswap',
    symbol: 'wetc',
    name: 'Wrapped ETC',
  },
  {
    id: 'weth',
    symbol: 'weth',
    name: 'WETH',
  },
  {
    id: 'weth-plenty-bridge',
    symbol: 'weth.p',
    name: 'Polygon WETH (Plenty Bridge)',
  },
  {
    id: 'weth-plenty-bridge-65aa5342-507c-4f67-8634-1f4376ffdf9a',
    symbol: 'weth.e',
    name: 'WETH (Plenty Bridge)',
  },
  {
    id: 'weth-yvault',
    symbol: 'yvweth',
    name: 'WETH yVault',
  },
  {
    id: 'weway',
    symbol: 'wwy',
    name: 'WeWay',
  },
  {
    id: 'wewe',
    symbol: 'wewe',
    name: 'WEWE',
  },
  {
    id: 'wexo',
    symbol: 'wexo',
    name: 'Wexo',
  },
  {
    id: 'weyu',
    symbol: 'weyu',
    name: 'WEYU',
  },
  {
    id: 'wfca',
    symbol: 'wfca',
    name: 'WFCA',
  },
  {
    id: 'wfdp',
    symbol: 'wfdp',
    name: 'WFDP',
  },
  {
    id: 'whale',
    symbol: 'whale',
    name: 'WHALE',
  },
  {
    id: 'whaleroom',
    symbol: 'whl',
    name: 'WhaleRoom',
  },
  {
    id: 'whalescandypls-com',
    symbol: 'wc',
    name: 'WhalesCandyPLS.com',
  },
  {
    id: 'whale-sei',
    symbol: 'whale',
    name: 'Whale (SEI)',
  },
  {
    id: 'whales-market',
    symbol: 'whales',
    name: 'Whales Market',
  },
  {
    id: 'whalewatch',
    symbol: 'wbot',
    name: 'WhaleWatch',
  },
  {
    id: 'whatbot',
    symbol: 'what',
    name: 'WhatBot',
  },
  {
    id: 'what-do-you-meme',
    symbol: 'wdym',
    name: 'What Do You Meme',
  },
  {
    id: 'wheat',
    symbol: 'wheat',
    name: 'Wheat',
  },
  {
    id: 'whee',
    symbol: 'whee',
    name: 'WHEE',
  },
  {
    id: 'when',
    symbol: 'when',
    name: 'when',
  },
  {
    id: 'where-did-the-eth-go',
    symbol: 'wheth',
    name: 'Where Did The ETH Go?',
  },
  {
    id: 'where-did-the-eth-go-pulsechain',
    symbol: 'wheth',
    name: 'Where Did The ETH Go? (Pulsechain)',
  },
  {
    id: 'whey-token',
    symbol: 'whey',
    name: 'Shredded Apes Whey',
  },
  {
    id: 'whisper',
    symbol: 'wisp',
    name: 'Whisper',
  },
  {
    id: 'whisperbot',
    symbol: 'wsp',
    name: 'WhisperBot',
  },
  {
    id: 'whitebit',
    symbol: 'wbt',
    name: 'WhiteBIT Coin',
  },
  {
    id: 'whitecoin',
    symbol: 'xwc',
    name: 'Whitecoin',
  },
  {
    id: 'whiteheart',
    symbol: 'white',
    name: 'Whiteheart',
  },
  {
    id: 'white-lotus',
    symbol: 'lotus',
    name: 'White Lotus',
  },
  {
    id: 'white-rhinoceros',
    symbol: 'whrh',
    name: 'White Rhinoceros',
  },
  {
    id: 'white-whale',
    symbol: 'whale',
    name: 'White Whale',
  },
  {
    id: 'whole-earth-coin',
    symbol: 'wec',
    name: 'Whole Earth Coin',
  },
  {
    id: 'wibx',
    symbol: 'wbx',
    name: 'WiBX',
  },
  {
    id: 'wickedbet-casino',
    symbol: 'wik',
    name: 'WickedBet Casino',
  },
  {
    id: 'wicked-moai',
    symbol: 'moai',
    name: 'Wicked Moai',
  },
  {
    id: 'wicrypt',
    symbol: 'wnt',
    name: 'Wicrypt',
  },
  {
    id: 'widecoin',
    symbol: 'wcn',
    name: 'Widecoin',
  },
  {
    id: 'wife-changing-money',
    symbol: 'wife',
    name: 'Wife Changing Money',
  },
  {
    id: 'wifedoge',
    symbol: 'wifedoge',
    name: 'Wifedoge',
  },
  {
    id: 'wifi',
    symbol: 'wifi',
    name: 'WiFi Map',
  },
  {
    id: 'wigger',
    symbol: 'wigger',
    name: 'Wigger',
  },
  {
    id: 'wigoswap',
    symbol: 'wigo',
    name: 'WigoSwap',
  },
  {
    id: 'wiki-cat',
    symbol: 'wkc',
    name: 'Wiki Cat',
  },
  {
    id: 'wild-base',
    symbol: 'bwild',
    name: 'Wild Base',
  },
  {
    id: 'wilder-world',
    symbol: 'wild',
    name: 'Wilder World',
  },
  {
    id: 'wild-goat-coin',
    symbol: 'wgc',
    name: 'Wild Goat Coin',
  },
  {
    id: 'wild-island-game',
    symbol: 'wild',
    name: 'Wild Island Game',
  },
  {
    id: 'wildx',
    symbol: 'wild',
    name: 'WILDx',
  },
  {
    id: 'winamp',
    symbol: 'winamp',
    name: 'WINAMP',
  },
  {
    id: 'windfall-token',
    symbol: 'wft',
    name: 'Windfall',
  },
  {
    id: 'windoge98',
    symbol: 'exe',
    name: 'Windoge98',
  },
  {
    id: 'winerz',
    symbol: '$wnz',
    name: 'Winerz',
  },
  {
    id: 'wine-shares',
    symbol: 'wine',
    name: 'Wine Shares',
  },
  {
    id: 'wing-finance',
    symbol: 'wing',
    name: 'Wing Finance',
  },
  {
    id: 'wingriders',
    symbol: 'wrt',
    name: 'WingRiders',
  },
  {
    id: 'wingswap',
    symbol: 'wis',
    name: 'WingSwap',
  },
  {
    id: 'wink',
    symbol: 'win',
    name: 'WINkLink',
  },
  {
    id: 'winkhub',
    symbol: 'wink',
    name: 'WinkHub',
  },
  {
    id: 'winklink-bsc',
    symbol: 'win',
    name: 'WINkLink BSC',
  },
  {
    id: 'winnerz',
    symbol: 'wnz',
    name: 'Winnerz',
  },
  {
    id: 'winr-protocol',
    symbol: 'winr',
    name: 'WINR Protocol',
  },
  {
    id: 'wins',
    symbol: 'wins',
    name: 'Trophy',
  },
  {
    id: 'winter',
    symbol: 'winter',
    name: 'Winter',
  },
  {
    id: 'winterdog',
    symbol: 'wdog',
    name: 'Winterdog',
  },
  {
    id: 'wipemyass',
    symbol: 'wipe',
    name: 'WipeMyAss',
  },
  {
    id: 'wirex',
    symbol: 'wxt',
    name: 'WXT Token',
  },
  {
    id: 'wirtual',
    symbol: 'wirtual',
    name: 'Wirtual',
  },
  {
    id: 'wise-token11',
    symbol: 'wise',
    name: 'Wise',
  },
  {
    id: 'wish-me-luck',
    symbol: 'wml',
    name: 'Wish Me Luck',
  },
  {
    id: 'wiskers',
    symbol: 'wskr',
    name: 'Wiskers',
  },
  {
    id: 'wispswap',
    symbol: 'wisp',
    name: 'WispSwap',
  },
  {
    id: 'wistaverse',
    symbol: 'wista',
    name: 'Wistaverse',
  },
  {
    id: 'witch-token',
    symbol: 'witch',
    name: 'Witch Token',
  },
  {
    id: 'witnet',
    symbol: 'wit',
    name: 'Witnet',
  },
  {
    id: 'wizardia',
    symbol: 'wzrd',
    name: 'Wizardia',
  },
  {
    id: 'wizard-token-8fc587d7-4b79-4f5a-89c9-475f528c6d47',
    symbol: 'wizt',
    name: 'Wizard Token',
  },
  {
    id: 'wizard-vault-nftx',
    symbol: 'wizard',
    name: 'WIZARD Vault (NFTX)',
  },
  {
    id: 'wizarre-scroll',
    symbol: 'scrl',
    name: 'Wizarre Scroll',
  },
  {
    id: 'wjewel',
    symbol: 'wjewel',
    name: 'WJEWEL',
  },
  {
    id: 'wliti',
    symbol: 'wliti',
    name: 'wLITI',
  },
  {
    id: 'wlitidao',
    symbol: 'wwd',
    name: 'WolfWorksDAO',
  },
  {
    id: 'wmatic',
    symbol: 'wmatic',
    name: 'Wrapped Matic',
  },
  {
    id: 'wmatic-plenty-bridge',
    symbol: 'wmatic.p',
    name: 'WMATIC (Plenty Bridge)',
  },
  {
    id: 'wmetis',
    symbol: 'wmetis',
    name: 'Wrapped Metis',
  },
  {
    id: 'wodo',
    symbol: 'wodo',
    name: 'Wodo',
  },
  {
    id: 'wodo-gaming',
    symbol: 'xwgt',
    name: 'Wodo Gaming',
  },
  {
    id: 'wojak',
    symbol: 'wojak',
    name: 'Wojak',
  },
  {
    id: 'wojak-2-0-coin',
    symbol: 'wojak 2.0',
    name: 'Wojak 2.0 Coin',
  },
  {
    id: 'wojak-2-69',
    symbol: 'wojak2.69',
    name: 'Wojak 2.69',
  },
  {
    id: 'wojak-finance',
    symbol: 'woj',
    name: 'Wojak Finance',
  },
  {
    id: 'woke-frens',
    symbol: 'woke',
    name: 'Woke Frens',
  },
  {
    id: 'wolfcoin',
    symbol: 'wolf',
    name: 'WOLFCOIN',
  },
  {
    id: 'wolf-game-wool',
    symbol: 'wool',
    name: 'Wolf Game Wool',
  },
  {
    id: 'wolf-of-wall-street',
    symbol: '$wolf',
    name: 'Wolf of Wall Street',
  },
  {
    id: 'wolf-on-solana',
    symbol: 'wolf',
    name: 'Wolf On Solana',
  },
  {
    id: 'wolf-pups-2',
    symbol: 'wolfies',
    name: 'WOLF PUPS',
  },
  {
    id: 'wolfsafepoorpeople',
    symbol: 'wspp',
    name: 'WolfSafePoorPeople',
  },
  {
    id: 'wolfsafepoorpeople-polygon',
    symbol: 'wspp',
    name: 'WolfSafePoorPeople Polygon',
  },
  {
    id: 'wolf-solana',
    symbol: 'wolf',
    name: 'WOLF SOLANA',
  },
  {
    id: 'wolf-ventures',
    symbol: '$wv',
    name: 'Wolf Ventures',
  },
  {
    id: 'wolv',
    symbol: 'wolv',
    name: 'WOLV',
  },
  {
    id: 'wolverinu-2',
    symbol: 'wolverinu',
    name: 'Wolverinu',
  },
  {
    id: 'wombat',
    symbol: 'wombat',
    name: 'Wombat',
  },
  {
    id: 'wombat-exchange',
    symbol: 'wom',
    name: 'Wombat Exchange',
  },
  {
    id: 'wombex',
    symbol: 'wmx',
    name: 'Wombex',
  },
  {
    id: 'wombex-wom',
    symbol: 'wmxwom',
    name: 'Wombex WOM',
  },
  {
    id: 'wom-token',
    symbol: 'wom',
    name: 'WOM Protocol',
  },
  {
    id: 'wonderland',
    symbol: 'time',
    name: 'Wonderland TIME',
  },
  {
    id: 'wonderland-capital',
    symbol: 'alice',
    name: 'Wonderland Capital',
  },
  {
    id: 'wonderly-finance',
    symbol: 'afx',
    name: 'Wonderly Finance',
  },
  {
    id: 'wonderman-nation',
    symbol: 'wndr',
    name: 'Wonderman Nation',
  },
  {
    id: 'wonderverse',
    symbol: 'wonder',
    name: 'Wonderverse',
  },
  {
    id: 'woodcoin',
    symbol: 'log',
    name: 'Woodcoin',
  },
  {
    id: 'woofswap-woof',
    symbol: 'woof',
    name: 'WOOF',
  },
  {
    id: 'woof-token',
    symbol: 'woof',
    name: 'WOOF',
  },
  {
    id: 'woofwork-io',
    symbol: 'woof',
    name: 'WoofWork.io',
  },
  {
    id: 'woo-network',
    symbol: 'woo',
    name: 'WOO',
  },
  {
    id: 'woonkly-power',
    symbol: 'woop',
    name: 'Woonkly Power',
  },
  {
    id: 'wooonen',
    symbol: 'wooo',
    name: 'Wooonen',
  },
  {
    id: 'woop',
    symbol: 'woop',
    name: 'WOOP',
  },
  {
    id: 'woosh',
    symbol: 'woosh',
    name: 'woosh',
  },
  {
    id: 'woozoo-music',
    symbol: 'wzm',
    name: 'Woozoo Music',
  },
  {
    id: 'work-quest-2',
    symbol: 'wqt',
    name: 'Work Quest',
  },
  {
    id: 'work-x',
    symbol: 'work',
    name: 'Work X',
  },
  {
    id: 'world-ai',
    symbol: 'worldai',
    name: 'World AI',
  },
  {
    id: 'world-cause-coin',
    symbol: 'cause',
    name: 'World Cause Coin',
  },
  {
    id: 'worldcoin',
    symbol: 'wdc',
    name: 'WorldCoin',
  },
  {
    id: 'worldcoin-wld',
    symbol: 'wld',
    name: 'Worldcoin',
  },
  {
    id: 'worldcore',
    symbol: 'wrc',
    name: 'Worldcore',
  },
  {
    id: 'world-football1',
    symbol: 'wofo1',
    name: 'WORLD FOOTBALL1',
  },
  {
    id: 'world-id',
    symbol: 'woid',
    name: 'WORLD ID',
  },
  {
    id: 'world-mobile-token',
    symbol: 'wmt',
    name: 'World Mobile Token',
  },
  {
    id: 'world-of-defish',
    symbol: 'wod',
    name: 'World of Defish',
  },
  {
    id: 'world-of-legends',
    symbol: 'wol',
    name: 'World of Legends',
  },
  {
    id: 'world-pay-token',
    symbol: 'wpay',
    name: 'World Pay Token',
  },
  {
    id: 'world-peace-coin',
    symbol: 'wpc',
    name: 'WORLD PEACE COIN',
  },
  {
    id: 'worldtoken',
    symbol: 'world',
    name: 'WorldToken',
  },
  {
    id: 'worldwide',
    symbol: 'world',
    name: 'WORLDWIDE',
  },
  {
    id: 'wortheum',
    symbol: 'worth',
    name: 'Wortheum',
  },
  {
    id: 'wow',
    symbol: '!',
    name: 'WOW',
  },
  {
    id: 'wownero',
    symbol: 'wow',
    name: 'Wownero',
  },
  {
    id: 'wowswap',
    symbol: 'wow',
    name: 'WOWswap',
  },
  {
    id: 'wozx',
    symbol: 'wozx',
    name: 'Efforce',
  },
  {
    id: 'wpt-investing-corp',
    symbol: 'wpt',
    name: 'WPT Investing Corp',
  },
  {
    id: 'wrap-governance-token',
    symbol: 'wrap',
    name: 'WRAP Governance',
  },
  {
    id: 'wrapped-accumulate',
    symbol: 'wacme',
    name: 'Wrapped Accumulate',
  },
  {
    id: 'wrapped-ada',
    symbol: 'wada',
    name: 'Wrapped ADA',
  },
  {
    id: 'wrapped-ada-21-co',
    symbol: '21ada',
    name: 'Wrapped ADA (21.co)',
  },
  {
    id: 'wrapped-algo',
    symbol: 'xalgo',
    name: 'Wrapped ALGO',
  },
  {
    id: 'wrapped-ampleforth',
    symbol: 'wampl',
    name: 'Wrapped Ampleforth',
  },
  {
    id: 'wrappedarc',
    symbol: 'warc',
    name: 'WrappedARC',
  },
  {
    id: 'wrapped-astar',
    symbol: 'wastr',
    name: 'Wrapped Astar',
  },
  {
    id: 'wrapped-avax',
    symbol: 'wavax',
    name: 'Wrapped AVAX',
  },
  {
    id: 'wrapped-axelar',
    symbol: 'waxl',
    name: 'Wrapped Axelar',
  },
  {
    id: 'wrapped-banano',
    symbol: 'wban',
    name: 'Wrapped Banano',
  },
  {
    id: 'wrapped-bch',
    symbol: 'wbch',
    name: 'Wrapped BCH',
  },
  {
    id: 'wrapped-bch-21-co',
    symbol: '21bch',
    name: 'Wrapped BCH (21.co)',
  },
  {
    id: 'wrapped-beacon-eth',
    symbol: 'wbeth',
    name: 'Wrapped Beacon ETH',
  },
  {
    id: 'wrapped-bitcoin',
    symbol: 'wbtc',
    name: 'Wrapped Bitcoin',
  },
  {
    id: 'wrapped-bitcoin-celer',
    symbol: 'cewbtc',
    name: 'Wrapped Bitcoin - Celer',
  },
  {
    id: 'wrapped-bitcoin-sollet',
    symbol: 'sobtc',
    name: 'Wrapped Bitcoin (Sollet)',
  },
  {
    id: 'wrapped-bitcoin-stacks',
    symbol: 'xbtc',
    name: 'Wrapped Bitcoin-Stacks',
  },
  {
    id: 'wrapped-bitrock',
    symbol: 'wbrock',
    name: 'Wrapped Bitrock',
  },
  {
    id: 'wrapped-bmx-liquidity-token',
    symbol: 'wblt',
    name: 'Wrapped BMX Liquidity Token',
  },
  {
    id: 'wrapped-bnb-21-co',
    symbol: '21bnb',
    name: 'Wrapped BNB (21.co)',
  },
  {
    id: 'wrapped-bnb-celer',
    symbol: 'cewbnb',
    name: 'Wrapped BNB - Celer',
  },
  {
    id: 'wrapped-bones',
    symbol: 'wbones',
    name: 'Wrapped BONES',
  },
  {
    id: 'wrapped-brise',
    symbol: 'wbrise',
    name: 'Wrapped Brise',
  },
  {
    id: 'wrapped-btc-21-co',
    symbol: '21btc',
    name: 'Wrapped BTC (21.co)',
  },
  {
    id: 'wrapped-btc-caviarnine',
    symbol: 'xwbtc',
    name: 'Instabridge Wrapped BTC (Radix)',
  },
  {
    id: 'wrapped-btc-wormhole',
    symbol: 'wbtc',
    name: 'Wrapped BTC (Wormhole)',
  },
  {
    id: 'wrapped-btt',
    symbol: 'wbtt',
    name: 'Wrapped BTT',
  },
  {
    id: 'wrapped-busd',
    symbol: 'wbusd',
    name: 'Wrapped BUSD',
  },
  {
    id: 'wrapped-busd-allbridge-from-bsc',
    symbol: 'abbusd',
    name: 'Wrapped BUSD (Allbridge from BSC)',
  },
  {
    id: 'wrapped-centrifuge',
    symbol: 'wcfg',
    name: 'Wrapped Centrifuge',
  },
  {
    id: 'wrapped-ckb',
    symbol: 'wckb',
    name: 'Wrapped CKB',
  },
  {
    id: 'wrapped-conflux',
    symbol: 'wcfx',
    name: 'Wrapped Conflux',
  },
  {
    id: 'wrapped-core',
    symbol: 'wcore',
    name: 'Wrapped CORE',
  },
  {
    id: 'wrapped-cro',
    symbol: 'wcro',
    name: 'Wrapped CRO',
  },
  {
    id: 'wrapped-cusd-allbridge-from-celo',
    symbol: 'acusd',
    name: 'Wrapped CUSD (Allbridge from Celo)',
  },
  {
    id: 'wrapped-doge-21-co',
    symbol: '21doge',
    name: 'Wrapped DOGE (21.co)',
  },
  {
    id: 'wrapped-dot-21-co',
    symbol: '21dot',
    name: 'Wrapped DOT (21.co)',
  },
  {
    id: 'wrapped-ecomi',
    symbol: 'womi',
    name: 'Wrapped ECOMI',
  },
  {
    id: 'wrapped-eeth',
    symbol: 'weeth',
    name: 'Wrapped eETH',
  },
  {
    id: 'wrapped-ehmnd',
    symbol: 'wehmnd',
    name: 'Wrapped eHMND',
  },
  {
    id: 'wrapped-elastos',
    symbol: 'wela',
    name: 'Wrapped Elastos',
  },
  {
    id: 'wrapped-elrond',
    symbol: 'wegld',
    name: 'Wrapped EGLD',
  },
  {
    id: 'wrapped-energi',
    symbol: 'wnrg',
    name: 'Wrapped Energi',
  },
  {
    id: 'wrapped-eos',
    symbol: 'weos',
    name: 'Wrapped EOS',
  },
  {
    id: 'wrapped-ether-celer',
    symbol: 'ceweth',
    name: 'Wrapped Ether - Celer',
  },
  {
    id: 'wrapped-ethereum-sollet',
    symbol: 'soeth',
    name: 'Wrapped Ethereum (Sollet)',
  },
  {
    id: 'wrapped-ether-linea',
    symbol: 'weth',
    name: 'Bridged Wrapped Ether (Linea)',
  },
  {
    id: 'wrapped-ether-mantle-bridge',
    symbol: 'weth',
    name: 'Wrapped Ether (Mantle Bridge)',
  },
  {
    id: 'wrapped-ethw',
    symbol: 'wethw',
    name: 'Wrapped ETHW',
  },
  {
    id: 'wrapped-ever',
    symbol: 'wever',
    name: 'Wrapped Ever',
  },
  {
    id: 'wrapped-fantom',
    symbol: 'wftm',
    name: 'Wrapped Fantom',
  },
  {
    id: 'wrapped-fio',
    symbol: 'wfio',
    name: 'Wrapped FIO',
  },
  {
    id: 'wrapped-flare',
    symbol: 'wflr',
    name: 'Wrapped Flare',
  },
  {
    id: 'wrapped-flow',
    symbol: 'wflow',
    name: 'Wrapped Flow',
  },
  {
    id: 'wrapped-fuse',
    symbol: 'wfuse',
    name: 'Wrapped FUSE',
  },
  {
    id: 'wrapped-hbar',
    symbol: 'whbar',
    name: 'Wrapped HBAR (SaucerSwap)',
  },
  {
    id: 'wrapped-hec',
    symbol: 'wshec',
    name: 'Wrapped HEC',
  },
  {
    id: 'wrapped-huobi-token',
    symbol: 'wht',
    name: 'Wrapped Huobi',
  },
  {
    id: 'wrapped-icp',
    symbol: 'wicp',
    name: 'Wrapped ICP',
  },
  {
    id: 'wrapped-iotex',
    symbol: 'wiotx',
    name: 'Wrapped IoTex',
  },
  {
    id: 'wrapped-jones-aura',
    symbol: 'wjaura',
    name: 'Wrapped Jones AURA',
  },
  {
    id: 'wrapped-kaspa',
    symbol: 'kas',
    name: 'Wrapped Kaspa',
  },
  {
    id: 'wrapped-kava',
    symbol: 'wkava',
    name: 'Wrapped Kava',
  },
  {
    id: 'wrapped-kcs',
    symbol: 'wkcs',
    name: 'Wrapped KCS',
  },
  {
    id: 'wrapped-klay',
    symbol: 'wklay',
    name: 'Wrapped KLAY',
  },
  {
    id: 'wrapped-ltc-21-co',
    symbol: '21ltc',
    name: 'Wrapped LTC (21.co)',
  },
  {
    id: 'wrapped-lunagens',
    symbol: 'wlung',
    name: 'Wrapped LunaGens',
  },
  {
    id: 'wrapped-lyx-sigmaswap',
    symbol: 'wlyx',
    name: 'Wrapped LYX (SigmaSwap)',
  },
  {
    id: 'wrapped-mantle',
    symbol: 'wmnt',
    name: 'Wrapped Mantle',
  },
  {
    id: 'wrapped-memory',
    symbol: 'wmemo',
    name: 'Wonderful Memories',
  },
  {
    id: 'wrapped-merit-circle',
    symbol: 'wbeam',
    name: 'Wrapped BEAM',
  },
  {
    id: 'wrapped-metrix',
    symbol: 'mrxb',
    name: 'Wrapped Metrix',
  },
  {
    id: 'wrapped-millix',
    symbol: 'wmlx',
    name: 'Wrapped Millix',
  },
  {
    id: 'wrapped-minima',
    symbol: 'wminima',
    name: 'Wrapped Minima',
  },
  {
    id: 'wrapped-mistcoin',
    symbol: 'wmc',
    name: 'Wrapped MistCoin',
  },
  {
    id: 'wrapped-moonbeam',
    symbol: 'wglmr',
    name: 'Wrapped Moonbeam',
  },
  {
    id: 'wrapped-moxy',
    symbol: 'wmoxy',
    name: 'Wrapped MOXY',
  },
  {
    id: 'wrapped-ncg',
    symbol: 'wncg',
    name: 'Wrapped NCG',
  },
  {
    id: 'wrapped-near',
    symbol: 'wnear',
    name: 'Wrapped Near',
  },
  {
    id: 'wrapped-neon',
    symbol: 'wneon',
    name: 'Wrapped Neon',
  },
  {
    id: 'wrapped-newyorkcoin',
    symbol: 'wnyc',
    name: 'Wrapped NewYorkCoin',
  },
  {
    id: 'wrapped-nxm',
    symbol: 'wnxm',
    name: 'Wrapped NXM',
  },
  {
    id: 'wrapped-oas',
    symbol: 'woas',
    name: 'Wrapped OAS',
  },
  {
    id: 'wrapped-oeth',
    symbol: 'woeth',
    name: 'Wrapped OETH',
  },
  {
    id: 'wrapped-okt',
    symbol: 'wokt',
    name: 'Wrapped OKT',
  },
  {
    id: 'wrapped-omax',
    symbol: 'womax',
    name: 'Wrapped OMAX',
  },
  {
    id: 'wrapped-one',
    symbol: 'wone',
    name: 'Wrapped One',
  },
  {
    id: 'wrapped-optidoge',
    symbol: 'woptidoge',
    name: 'Wrapped OptiDoge',
  },
  {
    id: 'wrapped-ousd',
    symbol: 'wousd',
    name: 'Wrapped OUSD',
  },
  {
    id: 'wrapped-paycoin',
    symbol: 'wpci',
    name: 'Wrapped Paycoin',
  },
  {
    id: 'wrapped-pepe',
    symbol: 'wpepe',
    name: 'Wrapped Pepe',
  },
  {
    id: 'wrapped-pkt',
    symbol: 'wpkt',
    name: 'Wrapped PKT',
  },
  {
    id: 'wrapped-platform',
    symbol: 'wrap',
    name: 'Wrapped Platform',
  },
  {
    id: 'wrapped-pokt',
    symbol: 'wpokt',
    name: 'Wrapped POKT',
  },
  {
    id: 'wrapped-pom',
    symbol: 'wpom',
    name: 'Wrapped POM',
  },
  {
    id: 'wrapped-pulse-wpls',
    symbol: 'wpls',
    name: 'Wrapped Pulse',
  },
  {
    id: 'wrapped-rose',
    symbol: 'wrose',
    name: 'Wrapped Rose',
  },
  {
    id: 'wrapped-sftw',
    symbol: 'wsftw',
    name: 'Wrapped sFTW',
  },
  {
    id: 'wrapped-shiden-network',
    symbol: 'sdn',
    name: 'Wrapped Shiden Network',
  },
  {
    id: 'wrapped-sol-21-co',
    symbol: '21sol',
    name: 'Wrapped SOL (21.co)',
  },
  {
    id: 'wrapped-solana',
    symbol: 'sol',
    name: 'Wrapped Solana',
  },
  {
    id: 'wrapped-songbird',
    symbol: 'wsgb',
    name: 'Wrapped Songbird',
  },
  {
    id: 'wrapped-staked-usdt',
    symbol: 'wstusdt',
    name: 'Wrapped Staked Tether',
  },
  {
    id: 'wrapped-statera',
    symbol: 'wsta',
    name: 'Wrapped Statera',
  },
  {
    id: 'wrapped-steth',
    symbol: 'wsteth',
    name: 'Wrapped stETH',
  },
  {
    id: 'wrapped-syscoin',
    symbol: 'wsys',
    name: 'Wrapped Syscoin',
  },
  {
    id: 'wrapped-tao',
    symbol: 'wtao',
    name: 'Wrapped TAO',
  },
  {
    id: 'wrapped-telos',
    symbol: 'wtlos',
    name: 'Wrapped Telos',
  },
  {
    id: 'wrapped-terra',
    symbol: 'lunc',
    name: 'Wrapped Terra Classic',
  },
  {
    id: 'wrapped-tezos',
    symbol: 'wxtz',
    name: 'StakerDAO Wrapped Tezos',
  },
  {
    id: 'wrapped-thunderpokt',
    symbol: 'wtpokt',
    name: 'Wrapped ThunderPOKT',
  },
  {
    id: 'wrapped-thunder-token',
    symbol: 'wtt',
    name: 'Wrapped Thunder Token',
  },
  {
    id: 'wrapped-tomo',
    symbol: 'wtomo',
    name: 'Wrapped TOMO',
  },
  {
    id: 'wrapped-tron',
    symbol: 'wtrx',
    name: 'Wrapped Tron',
  },
  {
    id: 'wrapped-turtlecoin',
    symbol: 'wtrtl',
    name: 'Wrapped TurtleCoin',
  },
  {
    id: 'wrapped-usdc',
    symbol: 'xusd',
    name: 'Bridged USD Coin (Wrapped)',
  },
  {
    id: 'wrapped-usdc-caviarnine',
    symbol: 'xusdc',
    name: 'Instabridge Wrapped USDC (Radix)',
  },
  {
    id: 'wrapped-usdm',
    symbol: 'wusdm',
    name: 'Wrapped USDM',
  },
  {
    id: 'wrapped-usdr',
    symbol: 'wusdr',
    name: 'Wrapped USDR',
  },
  {
    id: 'wrapped-usdt-allbridge-from-polygon',
    symbol: 'apusdt',
    name: 'Bridged Tether (Allbridge)',
  },
  {
    id: 'wrapped-ust',
    symbol: 'ustc',
    name: 'Wrapped USTC',
  },
  {
    id: 'wrapped-velas',
    symbol: 'wvlx',
    name: 'Wrapped Velas',
  },
  {
    id: 'wrapped-virgin-gen-0-cryptokitties',
    symbol: 'wvg0',
    name: 'Wrapped Virgin Gen-0 CryptoKittties',
  },
  {
    id: 'wrapped-vtru',
    symbol: 'wvtru',
    name: 'Wrapped VTRU',
  },
  {
    id: 'wrapped-wan',
    symbol: 'wwan',
    name: 'Wrapped Wan',
  },
  {
    id: 'wrapped-wdoge',
    symbol: 'wwdoge',
    name: 'Wrapped WDOGE',
  },
  {
    id: 'wrapped-xdai',
    symbol: 'wxdai',
    name: 'Wrapped XDAI',
  },
  {
    id: 'wrapped-xdc',
    symbol: 'wxdc',
    name: 'Wrapped XDC',
  },
  {
    id: 'wrapped-xrp',
    symbol: 'wxrp',
    name: 'Wrapped XRP',
  },
  {
    id: 'wrapped-xrp-21-co',
    symbol: '21xrp',
    name: 'Wrapped XRP (21.co)',
  },
  {
    id: 'wrestling-shiba',
    symbol: 'wwe',
    name: 'Wrestling Shiba',
  },
  {
    id: 'wsb-classic',
    symbol: 'wsbc',
    name: 'WSB Classic',
  },
  {
    id: 'wsb-coin',
    symbol: 'wsb',
    name: 'WSB Coin',
  },
  {
    id: 'wtbt',
    symbol: 'wtbt',
    name: 'wTBT',
  },
  {
    id: 'wusd',
    symbol: 'wusd',
    name: 'Wrapped USD',
  },
  {
    id: 'wut',
    symbol: 'wut',
    name: 'WUT',
  },
  {
    id: 'wynd',
    symbol: 'wynd',
    name: 'WYND',
  },
  {
    id: 'x0',
    symbol: 'x0',
    name: 'X0',
  },
  {
    id: 'x-2',
    symbol: 'x',
    name: 'X',
  },
  {
    id: 'x2y2',
    symbol: 'x2y2',
    name: 'X2Y2',
  },
  {
    id: 'x42-protocol',
    symbol: 'x42',
    name: 'X42 Protocol',
  },
  {
    id: 'x7101',
    symbol: 'x7101',
    name: 'X7101',
  },
  {
    id: 'x7102',
    symbol: 'x7102',
    name: 'X7102',
  },
  {
    id: 'x7103',
    symbol: 'x7103',
    name: 'X7103',
  },
  {
    id: 'x7104',
    symbol: 'x7104',
    name: 'X7104',
  },
  {
    id: 'x7105',
    symbol: 'x7105',
    name: 'X7105',
  },
  {
    id: 'x7-coin',
    symbol: 'x7c',
    name: 'X7 Coin',
  },
  {
    id: 'x7dao',
    symbol: 'x7dao',
    name: 'X7DAO',
  },
  {
    id: 'x7r',
    symbol: 'x7r',
    name: 'X7R',
  },
  {
    id: 'x8-project',
    symbol: 'x8x',
    name: 'X8X',
  },
  {
    id: 'xactrewards',
    symbol: 'xact',
    name: 'XActRewards',
  },
  {
    id: 'xahau',
    symbol: 'xah',
    name: 'Xahau',
  },
  {
    id: 'xai',
    symbol: 'xai',
    name: 'XAI Stablecoin',
  },
  {
    id: 'x-ai',
    symbol: 'x',
    name: 'X AI',
  },
  {
    id: 'xai-2',
    symbol: 'x',
    name: 'XAI',
  },
  {
    id: 'xai-3',
    symbol: 'xai',
    name: 'xAI',
  },
  {
    id: 'xai-blockchain',
    symbol: 'xai',
    name: 'Xai',
  },
  {
    id: 'xai-corp',
    symbol: 'xai',
    name: 'XAI Corp',
  },
  {
    id: 'xaigrok',
    symbol: 'xaigrok',
    name: 'XAIGROK',
  },
  {
    id: 'x-akamaru-inu',
    symbol: 'aka',
    name: 'X Akamaru Inu',
  },
  {
    id: 'xalpha-ai',
    symbol: 'xalpha',
    name: 'XALPHA.AI',
  },
  {
    id: 'xana',
    symbol: 'xeta',
    name: 'XANA',
  },
  {
    id: 'xapis',
    symbol: 'wax',
    name: 'XApis',
  },
  {
    id: 'xaurum',
    symbol: 'xaur',
    name: 'Xaurum',
  },
  {
    id: 'xave-coin',
    symbol: 'xvc',
    name: 'Xave Coin',
  },
  {
    id: 'xave-token',
    symbol: 'xav',
    name: 'Xave',
  },
  {
    id: 'xbanking',
    symbol: 'xb',
    name: 'XBANKING',
  },
  {
    id: 'xbcna_astrovault',
    symbol: 'xbcna',
    name: 'xBCNA_Astrovault',
  },
  {
    id: 'xbid',
    symbol: 'xbid',
    name: 'xBid',
  },
  {
    id: 'xbit',
    symbol: 'xbt',
    name: 'Xbit',
  },
  {
    id: 'xblue-finance',
    symbol: 'xb',
    name: 'XBlue Finance',
  },
  {
    id: 'xbomb',
    symbol: 'xbomb',
    name: 'xbomb',
  },
  {
    id: 'xbot',
    symbol: 'xbot',
    name: 'XBot',
  },
  {
    id: 'x-bridge-bot',
    symbol: 'xfer',
    name: 'X Bridge Bot',
  },
  {
    id: 'xbullion_silver',
    symbol: 'silv',
    name: 'XBullion Silver',
  },
  {
    id: 'xcad-network',
    symbol: 'xcad',
    name: 'XCAD Network',
  },
  {
    id: 'xcad-network-play',
    symbol: 'play',
    name: 'Play Token',
  },
  {
    id: 'xcarnival',
    symbol: 'xcv',
    name: 'XCarnival',
  },
  {
    id: 'x-cash',
    symbol: 'xcash',
    name: 'X-CASH',
  },
  {
    id: 'xcatge',
    symbol: 'xcatge',
    name: 'XCatge',
  },
  {
    id: 'xccelerate',
    symbol: 'xlrt',
    name: 'Xccelerate',
  },
  {
    id: 'xcdot',
    symbol: 'dot',
    name: 'xcDOT',
  },
  {
    id: 'xcel-swap',
    symbol: 'xld',
    name: 'Xcel Defi',
  },
  {
    id: 'xceltoken-plus',
    symbol: 'xlab',
    name: 'XCELTOKEN PLUS',
  },
  {
    id: 'xception',
    symbol: 'xcept',
    name: 'XCeption',
  },
  {
    id: 'x-chain',
    symbol: 'xchain',
    name: 'X-Chain',
  },
  {
    id: 'xcksm',
    symbol: 'xcksm',
    name: 'xcKSM',
  },
  {
    id: 'xcoinmeme',
    symbol: 'x',
    name: 'Xcoinmeme',
  },
  {
    id: 'x-com',
    symbol: 'x',
    name: 'CruxDecussata',
  },
  {
    id: 'xcrx',
    symbol: 'xcrx',
    name: 'xCRX',
  },
  {
    id: 'xcusdt',
    symbol: 'xcusdt',
    name: 'xcUSDT',
  },
  {
    id: 'xd',
    symbol: 'xd',
    name: 'XD',
  },
  {
    id: 'xdai',
    symbol: 'xdai',
    name: 'XDAI',
  },
  {
    id: 'xdai-native-comb',
    symbol: 'xcomb',
    name: 'xDai Native Comb',
  },
  {
    id: 'xdai-stake',
    symbol: 'stake',
    name: 'STAKE',
  },
  {
    id: 'xdao',
    symbol: 'xdao',
    name: 'XDAO',
  },
  {
    id: 'xdce-crowd-sale',
    symbol: 'xdc',
    name: 'XDC Network',
  },
  {
    id: 'xdec-astrovault',
    symbol: 'xdec',
    name: 'xDEC_Astrovault',
  },
  {
    id: 'xdefi',
    symbol: 'xdefi',
    name: 'XDEFI',
  },
  {
    id: 'xdefi-governance-token',
    symbol: 'xdex',
    name: 'XDEFI Governance',
  },
  {
    id: 'xdoge',
    symbol: 'xdoge',
    name: 'Xdoge',
  },
  {
    id: 'xdoge-2',
    symbol: 'xdoge',
    name: 'XDOGE',
  },
  {
    id: 'xdoge-3',
    symbol: 'xdoge',
    name: 'XDOGE',
  },
  {
    id: 'xdoge-4',
    symbol: 'xd',
    name: 'XDoge',
  },
  {
    id: 'x-dog-finance',
    symbol: 'xdog',
    name: 'X-Dog Finance',
  },
  {
    id: 'xdollar-stablecoin',
    symbol: 'xusd',
    name: 'xDollar Stablecoin',
  },
  {
    id: 'xels',
    symbol: 'xels',
    name: 'XELS',
  },
  {
    id: 'xena-finance',
    symbol: 'xen',
    name: 'Xena Finance',
  },
  {
    id: 'xenbitcoin',
    symbol: 'xbtc',
    name: 'XenBitcoin',
  },
  {
    id: 'xen-crypto',
    symbol: 'xen',
    name: 'XEN Crypto',
  },
  {
    id: 'xen-crypto-bsc',
    symbol: 'bxen',
    name: 'XEN Crypto (BSC)',
  },
  {
    id: 'xen-crypto-evmos',
    symbol: 'coxen',
    name: 'Xen Crypto (EVMOS)',
  },
  {
    id: 'xen-crypto-fantom',
    symbol: 'fmxen',
    name: 'Xen Crypto (Fantom)',
  },
  {
    id: 'xen-crypto-matic',
    symbol: 'mxen',
    name: 'Xen Crypto (MATIC)',
  },
  {
    id: 'xen-crypto-pulsechain',
    symbol: 'pxen',
    name: 'XEN Crypto (PulseChain)',
  },
  {
    id: 'xend-finance',
    symbol: 'xend',
    name: 'Xend Finance',
  },
  {
    id: 'xenify-bxnf-bnb-chain',
    symbol: 'bxnf',
    name: 'bXNF',
  },
  {
    id: 'xenify-bysl',
    symbol: 'bysl',
    name: 'bYSL',
  },
  {
    id: 'xenify-ysl',
    symbol: 'ysl',
    name: 'YSL',
  },
  {
    id: 'xenios',
    symbol: 'xnc',
    name: 'Xenios',
  },
  {
    id: 'xenlon-mars',
    symbol: 'xlon',
    name: 'Xenlon Mars',
  },
  {
    id: 'xeno',
    symbol: 'xeno',
    name: 'Xeno',
  },
  {
    id: 'xeno-token',
    symbol: 'xno',
    name: 'Xeno',
  },
  {
    id: 'xerc20-pro',
    symbol: 'x',
    name: 'X',
  },
  {
    id: 'xertinet',
    symbol: 'xert',
    name: 'XertiNet',
  },
  {
    id: 'xfarmer',
    symbol: 'xf',
    name: 'xFarmer',
  },
  {
    id: 'xfather-bot',
    symbol: 'xfbot',
    name: 'XFather Bot',
  },
  {
    id: 'xfinance',
    symbol: 'xfi',
    name: 'Xfinance',
  },
  {
    id: 'xfinite-entertainment-token',
    symbol: 'xet',
    name: 'Xfinite Entertainment',
  },
  {
    id: 'xfit',
    symbol: 'xfit',
    name: 'XFai',
  },
  {
    id: 'xfuel',
    symbol: 'xfuel',
    name: 'XFUEL',
  },
  {
    id: 'xfund',
    symbol: 'xfund',
    name: 'xFUND',
  },
  {
    id: 'x-gf',
    symbol: 'xgf',
    name: 'X GF',
  },
  {
    id: 'xgold-coin',
    symbol: 'xgold',
    name: 'Xgold Coin',
  },
  {
    id: 'x-gpt',
    symbol: 'xgpt',
    name: 'X-GPT',
  },
  {
    id: 'xhashtag',
    symbol: 'xtag',
    name: 'xHashtag',
  },
  {
    id: 'xidar',
    symbol: 'ida',
    name: 'Xidar',
  },
  {
    id: 'xiden',
    symbol: 'xden',
    name: 'Xiden',
  },
  {
    id: 'xido-finance',
    symbol: 'xido',
    name: 'Xido Finance',
  },
  {
    id: 'xidol-tech',
    symbol: 'xid',
    name: 'Xidol.tech',
  },
  {
    id: 'xing',
    symbol: 'xing',
    name: 'XING',
  },
  {
    id: 'xinu-eth',
    symbol: 'xinu',
    name: 'XINU (ETH)',
  },
  {
    id: 'xio',
    symbol: 'xio',
    name: 'Blockzero Labs',
  },
  {
    id: 'xion-finance',
    symbol: 'xgt',
    name: 'Xion Finance',
  },
  {
    id: 'xi-token',
    symbol: 'xi',
    name: 'Xi',
  },
  {
    id: 'xjewel',
    symbol: 'xjewel',
    name: 'xJEWEL',
  },
  {
    id: 'xlauncher',
    symbol: 'xlh',
    name: 'xLauncher',
  },
  {
    id: 'xl-bully',
    symbol: 'xlbully',
    name: 'XL BULLY',
  },
  {
    id: 'xlink-bridged-btc-stacks',
    symbol: 'abtc',
    name: 'XLink Bridged BTC (Stacks)',
  },
  {
    id: 'xlist',
    symbol: 'xlist',
    name: 'XList',
  },
  {
    id: 'xlotto',
    symbol: 'xlotto',
    name: 'XLOTTO',
  },
  {
    id: 'xlsd-coin',
    symbol: 'xlsd',
    name: 'XLSD Coin',
  },
  {
    id: 'xmas2023',
    symbol: 'xmas',
    name: 'XMAS2023',
  },
  {
    id: 'x-mask',
    symbol: 'xmc',
    name: 'X-MASK',
  },
  {
    id: 'x-mass',
    symbol: 'x-mass',
    name: 'X-Mass',
  },
  {
    id: 'xmas-santa-rally',
    symbol: 'xmry',
    name: 'XMas Santa Rally',
  },
  {
    id: 'xmatic',
    symbol: 'xmatic',
    name: 'xMATIC',
  },
  {
    id: 'xmax',
    symbol: 'xmx',
    name: 'XMax',
  },
  {
    id: 'x-metapol',
    symbol: 'xmp',
    name: 'X-MetaPol',
  },
  {
    id: 'xmon',
    symbol: 'xmon',
    name: 'XMON',
  },
  {
    id: 'xnet-mobile',
    symbol: 'xnet',
    name: 'XNET Mobile',
  },
  {
    id: 'xnf',
    symbol: 'xnf',
    name: 'XNF',
  },
  {
    id: 'xnft',
    symbol: 'xnft',
    name: 'xNFT Protocol',
  },
  {
    id: 'xnova',
    symbol: '$xnova',
    name: 'XNOVA',
  },
  {
    id: 'xodex',
    symbol: 'xodex',
    name: 'Xodex',
  },
  {
    id: 'xover',
    symbol: 'xvr',
    name: 'Xover',
  },
  {
    id: 'xox-labs',
    symbol: 'xox',
    name: 'XOX Labs',
  },
  {
    id: 'xoycoin',
    symbol: 'xoy',
    name: 'XOYCoin',
  },
  {
    id: 'xp',
    symbol: 'xp',
    name: 'XP',
  },
  {
    id: 'xpad-network-beta',
    symbol: 'beta',
    name: 'Xpad Network BETA',
  },
  {
    id: 'xpad-pro',
    symbol: 'xpp',
    name: 'Xpad.pro',
  },
  {
    id: 'xpansion-game',
    symbol: 'xps',
    name: 'Xpansion Game',
  },
  {
    id: 'xpaypro-tech',
    symbol: 'xppt',
    name: 'XPayPro.Tech',
  },
  {
    id: 'xpendium',
    symbol: 'xpnd',
    name: 'Xpendium',
  },
  {
    id: 'xpense-2',
    symbol: 'xpe',
    name: 'Xpense',
  },
  {
    id: 'x-pepe',
    symbol: 'xpep',
    name: 'X-Pepe',
  },
  {
    id: 'xperp',
    symbol: 'xperp',
    name: 'xperp',
  },
  {
    id: 'xpet-tech',
    symbol: 'xpet',
    name: 'xPet.tech',
  },
  {
    id: 'xpet-tech-bpet',
    symbol: 'bpet',
    name: 'xPet.tech BPET',
  },
  {
    id: 'xpla',
    symbol: 'xpla',
    name: 'XPLA',
  },
  {
    id: 'xp-network',
    symbol: 'xpnet',
    name: 'XP Network',
  },
  {
    id: 'xpolar',
    symbol: 'xpolar',
    name: 'XPOLAR',
  },
  {
    id: 'x-protocol',
    symbol: 'pot',
    name: 'X Protocol',
  },
  {
    id: 'xptp',
    symbol: 'xptp',
    name: 'xPTP',
  },
  {
    id: 'xraid',
    symbol: 'xraid',
    name: 'XRAID',
  },
  {
    id: 'xrdoge',
    symbol: 'xrdoge',
    name: 'XRdoge',
  },
  {
    id: 'xreators',
    symbol: 'ort',
    name: 'XREATORS',
  },
  {
    id: 'xrender',
    symbol: 'xrai',
    name: 'XRender',
  },
  {
    id: 'x-rise',
    symbol: 'xrise',
    name: 'X Rise',
  },
  {
    id: 'xrow',
    symbol: 'xrow',
    name: 'XROW',
  },
  {
    id: 'xrp20',
    symbol: 'xrp20',
    name: 'XRP20',
  },
  {
    id: 'xrpaynet',
    symbol: 'xrpaynet',
    name: 'XRPayNet',
  },
  {
    id: 'xrpcashone',
    symbol: 'xce',
    name: 'Xrpcashone',
  },
  {
    id: 'xrp-classic-new',
    symbol: 'xrpc',
    name: 'XRP Classic',
  },
  {
    id: 'xrp-healthcare',
    symbol: 'xrph',
    name: 'XRP Healthcare',
  },
  {
    id: 'xrun',
    symbol: 'xrun',
    name: 'XRun',
  },
  {
    id: 'xsale',
    symbol: 'xs',
    name: 'XSale',
  },
  {
    id: 'xsauce',
    symbol: 'xsauce',
    name: 'xSAUCE',
  },
  {
    id: 'xsgd',
    symbol: 'xsgd',
    name: 'XSGD',
  },
  {
    id: 'xshib',
    symbol: 'xshib',
    name: 'XSHIB',
  },
  {
    id: 'xshrap',
    symbol: 'xshrap',
    name: 'xShrap',
  },
  {
    id: 'xsl-labs',
    symbol: 'syl',
    name: 'myDid',
  },
  {
    id: 'x-social-network',
    symbol: 'x-ai',
    name: 'X Social Network',
  },
  {
    id: 'xspace',
    symbol: 'xsp',
    name: 'XSPACE',
  },
  {
    id: 'xspectar',
    symbol: 'xspectar',
    name: 'xSPECTAR',
  },
  {
    id: 'xsushi',
    symbol: 'xsushi',
    name: 'xSUSHI',
  },
  {
    id: 'xswap-protocol',
    symbol: 'xsp',
    name: 'XSwap Protocol',
  },
  {
    id: 'xswap-treasure',
    symbol: 'xtt',
    name: 'XSwap Treasure',
  },
  {
    id: 'xtblock-token',
    symbol: 'xtt-b20',
    name: 'XTblock',
  },
  {
    id: 'xtcom-token',
    symbol: 'xt',
    name: 'XT.com',
  },
  {
    id: 'xthebot',
    symbol: 'xtb',
    name: 'XTheBot',
  },
  {
    id: 'xtoken',
    symbol: 'xtk',
    name: 'xToken',
  },
  {
    id: 'xtrabytes',
    symbol: 'xby',
    name: 'XTRABYTES',
  },
  {
    id: 'x-travel-space',
    symbol: 'xts',
    name: 'X-Travel Space',
  },
  {
    id: 'xtusd',
    symbol: 'xtusd',
    name: 'XT Stablecoin XTUSD',
  },
  {
    id: 'xudo',
    symbol: 'xudo',
    name: 'Xudo',
  },
  {
    id: 'xusd',
    symbol: 'xusd',
    name: 'xUSD',
  },
  {
    id: 'xusd-babelfish',
    symbol: 'xusd',
    name: 'XUSD (BabelFish)',
  },
  {
    id: 'xv',
    symbol: 'xv',
    name: 'XV',
  },
  {
    id: 'xwin-finance',
    symbol: 'xwin',
    name: 'xWIN Finance',
  },
  {
    id: 'x-wizard',
    symbol: 'xwizard',
    name: 'X Wizard',
  },
  {
    id: 'x-world-games',
    symbol: 'xwg',
    name: 'X World Games',
  },
  {
    id: 'xxcoin',
    symbol: 'xx',
    name: 'XX Network',
  },
  {
    id: 'xxcoin-2',
    symbol: 'xx',
    name: 'XX',
  },
  {
    id: 'x-xrc-20',
    symbol: 'x',
    name: 'x (XRC-20)',
  },
  {
    id: 'xy-finance',
    symbol: 'xy',
    name: 'XY Finance',
  },
  {
    id: 'xym-finance',
    symbol: 'xym',
    name: 'XYM Token',
  },
  {
    id: 'xyo-network',
    symbol: 'xyo',
    name: 'XYO Network',
  },
  {
    id: 'y2k',
    symbol: 'y2k',
    name: 'Y2K',
  },
  {
    id: 'yachtingverse',
    symbol: 'yacht',
    name: 'YachtingVerse [OLD]',
  },
  {
    id: 'yachtingverse-old',
    symbol: 'yacht',
    name: 'YachtingVerse',
  },
  {
    id: 'yadacoin',
    symbol: 'yda',
    name: 'YadaCoin',
  },
  {
    id: 'yaku',
    symbol: 'yaku',
    name: 'Yaku',
  },
  {
    id: 'yam-2',
    symbol: 'yam',
    name: 'YAM',
  },
  {
    id: 'yama-inu',
    symbol: 'yama',
    name: 'YAMA Inu',
  },
  {
    id: 'yamfore',
    symbol: 'cblp',
    name: 'Yamfore',
  },
  {
    id: 'yamp-finance',
    symbol: 'yamp',
    name: 'Yamp Finance',
  },
  {
    id: 'yasha-dao',
    symbol: 'yasha',
    name: 'YASHA',
  },
  {
    id: 'yawww',
    symbol: 'yaw',
    name: 'Yawww',
  },
  {
    id: 'yaya-coin',
    symbol: 'yaya',
    name: 'YaYa Coin',
  },
  {
    id: 'yay-games',
    symbol: 'yay',
    name: 'YAY Network',
  },
  {
    id: 'ycash',
    symbol: 'yec',
    name: 'Ycash',
  },
  {
    id: 'y-coin',
    symbol: 'yco',
    name: 'Y Coin',
  },
  {
    id: 'ydragon',
    symbol: 'ydr',
    name: 'YDragon',
  },
  {
    id: 'yearn-crv',
    symbol: 'ycrv',
    name: 'Yearn CRV',
  },
  {
    id: 'yearn-ether',
    symbol: 'yeth',
    name: 'Yearn Ether',
  },
  {
    id: 'yearn-finance',
    symbol: 'yfi',
    name: 'yearn.finance',
  },
  {
    id: 'yearntogether',
    symbol: 'yearn',
    name: 'YearnTogether',
  },
  {
    id: 'yearn-yprisma',
    symbol: 'yprisma',
    name: 'Yearn yPRISMA',
  },
  {
    id: 'year-of-the-dragon',
    symbol: 'yod',
    name: 'Year of the Dragon',
  },
  {
    id: 'yel-finance',
    symbol: 'yel',
    name: 'Yel.Finance',
  },
  {
    id: 'yellow-road',
    symbol: 'road',
    name: 'Yellow Road',
  },
  {
    id: 'yellow-team',
    symbol: 'yellow',
    name: 'Yellow Team',
  },
  {
    id: 'yelo-cat',
    symbol: 'yelo',
    name: 'Yelo Cat',
  },
  {
    id: 'yenten',
    symbol: 'ytn',
    name: 'YENTEN',
  },
  {
    id: 'yertle-the-turtle',
    symbol: 'yertle',
    name: 'Yertle The Turtle',
  },
  {
    id: 'yes-2',
    symbol: 'yesgo',
    name: 'Yes',
  },
  {
    id: 'yes-3',
    symbol: 'yes',
    name: 'YES',
  },
  {
    id: 'yesorno',
    symbol: 'yon',
    name: 'YESorNO',
  },
  {
    id: 'yesports',
    symbol: 'yesp',
    name: 'Yesports',
  },
  {
    id: 'yes-token',
    symbol: 'yes',
    name: 'YES Token',
  },
  {
    id: 'yes-world',
    symbol: 'yes',
    name: 'Yes World',
  },
  {
    id: 'yeti',
    symbol: 'yeti',
    name: 'Yeti',
  },
  {
    id: 'yeti-finance',
    symbol: 'yeti',
    name: 'Yeti Finance',
  },
  {
    id: 'yfdai-finance',
    symbol: 'yf-dai',
    name: 'YfDAI.finance',
  },
  {
    id: 'yfii-finance',
    symbol: 'yfii',
    name: 'DFI.money',
  },
  {
    id: 'yfione',
    symbol: 'yfo',
    name: 'YFIONE',
  },
  {
    id: 'yfi-yvault',
    symbol: 'yvyfi',
    name: 'YFI yVault',
  },
  {
    id: 'yflink',
    symbol: 'yfl',
    name: 'YF Link',
  },
  {
    id: 'yfx',
    symbol: 'yfx',
    name: 'Your Futures Exchange',
  },
  {
    id: 'yield-24',
    symbol: 'y24',
    name: 'Yield 24',
  },
  {
    id: 'yield-app',
    symbol: 'yld',
    name: 'Yield App',
  },
  {
    id: 'yieldblox',
    symbol: 'ybx',
    name: 'YieldBlox',
  },
  {
    id: 'yieldeth-sommelier',
    symbol: 'yieldeth',
    name: 'YieldETH (Sommelier)',
  },
  {
    id: 'yieldfarming-index',
    symbol: 'yfx',
    name: 'YieldFarming Index',
  },
  {
    id: 'yield-finance',
    symbol: 'yieldx',
    name: 'Yield Finance',
  },
  {
    id: 'yield-guild-games',
    symbol: 'ygg',
    name: 'Yield Guild Games',
  },
  {
    id: 'yieldification',
    symbol: 'ydf',
    name: 'Yieldification',
  },
  {
    id: 'yielding-protocol',
    symbol: 'yield',
    name: 'Yielding Protocol',
  },
  {
    id: 'yieldly',
    symbol: 'yldy',
    name: 'Yieldly',
  },
  {
    id: 'yield-magnet',
    symbol: 'magnet',
    name: 'Yield Magnet',
  },
  {
    id: 'yield-protocol',
    symbol: 'yield',
    name: 'Yield Protocol',
  },
  {
    id: 'yieldwatch',
    symbol: 'watch',
    name: 'Yieldwatch',
  },
  {
    id: 'yield-yak',
    symbol: 'yak',
    name: 'Yield Yak',
  },
  {
    id: 'yield-yak-avax',
    symbol: 'yyavax',
    name: 'Yield Yak AVAX',
  },
  {
    id: 'yin-finance',
    symbol: 'yin',
    name: 'YIN Finance',
  },
  {
    id: 'yisu-ordinals',
    symbol: 'yisu',
    name: 'Yisu (Ordinals)',
  },
  {
    id: 'yocash',
    symbol: 'ych',
    name: 'YoCash',
  },
  {
    id: 'yocoin',
    symbol: 'yoc',
    name: 'Yocoin',
  },
  {
    id: 'yocoinyoco',
    symbol: 'yoco',
    name: 'YocoinYOCO',
  },
  {
    id: 'yoda-coin-swap',
    symbol: 'jedals',
    name: 'Yoda Coin Swap',
  },
  {
    id: 'yodeswap',
    symbol: 'yode',
    name: 'YodeSwap',
  },
  {
    id: 'yoj',
    symbol: 'yoj',
    name: 'YOJ',
  },
  {
    id: 'yokaiswap',
    symbol: 'yok',
    name: 'YokaiSwap',
  },
  {
    id: 'yolo-cash',
    symbol: 'ylc',
    name: 'YOLOCash',
  },
  {
    id: 'yolonolo',
    symbol: 'nolo',
    name: 'YoloNolo',
  },
  {
    id: 'yooldo',
    symbol: 'yool',
    name: 'Yooldo',
  },
  {
    id: 'yooshi',
    symbol: 'yooshi',
    name: 'YooShi',
  },
  {
    id: 'yooshiape',
    symbol: 'yooshiape',
    name: 'YooshiApe',
  },
  {
    id: 'yoshi-exchange',
    symbol: 'yoshi',
    name: 'Yoshi.exchange',
  },
  {
    id: 'yotoshi',
    symbol: 'yoto',
    name: 'Yotoshi',
  },
  {
    id: 'youcash',
    symbol: 'youc',
    name: 'YOUcash',
  },
  {
    id: 'youclout',
    symbol: 'yct',
    name: 'Youclout',
  },
  {
    id: 'youcoin-2',
    symbol: 'you',
    name: 'Youcoin',
  },
  {
    id: 'you-looked',
    symbol: 'circle',
    name: 'You Looked',
  },
  {
    id: 'young-boys-fan-token',
    symbol: 'ybo',
    name: 'Young Boys Fan Token',
  },
  {
    id: 'young-mids-inspired',
    symbol: 'ymii',
    name: 'Young Mids Inspired',
  },
  {
    id: 'youngparrot',
    symbol: 'ypc',
    name: 'YoungParrot',
  },
  {
    id: 'yourkiss',
    symbol: 'yks',
    name: 'YourKiss',
  },
  {
    id: 'yourmom',
    symbol: 'yourmom',
    name: 'YourMom',
  },
  {
    id: 'your-open-metaverse',
    symbol: 'yom',
    name: 'YOM',
  },
  {
    id: 'yourwallet',
    symbol: 'yourwallet',
    name: 'YourWallet',
  },
  {
    id: 'yousui',
    symbol: 'xui',
    name: 'YouSUI',
  },
  {
    id: 'youves-uusd',
    symbol: 'uusd',
    name: 'Youves uUSD',
  },
  {
    id: 'youves-you-governance',
    symbol: 'you',
    name: 'Youves YOU Governance',
  },
  {
    id: 'youwho',
    symbol: 'you',
    name: 'Youwho',
  },
  {
    id: 'yoyo-market',
    symbol: 'yoyo',
    name: 'Yoyo Market',
  },
  {
    id: 'yuan-chain-coin',
    symbol: 'ycc',
    name: 'Yuan Chain Coin',
  },
  {
    id: 'yuge-meme',
    symbol: 'yuge',
    name: 'Yuge Meme',
  },
  {
    id: 'yuki',
    symbol: 'yuki',
    name: 'YUKI',
  },
  {
    id: 'yukky',
    symbol: 'yukky',
    name: 'YUKKY',
  },
  {
    id: 'yummi-universe',
    symbol: 'yummi',
    name: 'Yummi Universe',
  },
  {
    id: 'yummy',
    symbol: 'yummy',
    name: 'Yummy',
  },
  {
    id: 'yuna',
    symbol: 'yuna',
    name: 'Yuna',
  },
  {
    id: 'yunki',
    symbol: 'yunki',
    name: 'Yunki',
  },
  {
    id: 'yup',
    symbol: 'yup',
    name: 'Yup',
  },
  {
    id: 'yuri',
    symbol: 'yuri',
    name: 'Yuri',
  },
  {
    id: 'yusd-stablecoin',
    symbol: 'yusd',
    name: 'YUSD Stablecoin',
  },
  {
    id: 'yvboost',
    symbol: 'yvboost',
    name: 'Yearn Compounding veCRV yVault',
  },
  {
    id: 'yvdai',
    symbol: 'yvdai',
    name: 'yvDAI',
  },
  {
    id: 'yvs-finance',
    symbol: 'yvs',
    name: 'YVS Finance',
  },
  {
    id: 'zab',
    symbol: 'zab',
    name: 'Zab',
  },
  {
    id: 'zada',
    symbol: 'zada',
    name: 'Zada',
  },
  {
    id: 'zahnymous',
    symbol: 'zah',
    name: 'Zahnymous',
  },
  {
    id: 'zaif-token',
    symbol: 'zaif',
    name: 'Zaif',
  },
  {
    id: 'zaiho',
    symbol: 'zai',
    name: 'ZAIHO',
  },
  {
    id: 'zakumifi',
    symbol: 'zafi',
    name: 'ZakumiFi',
  },
  {
    id: 'zambesigold',
    symbol: 'zgd',
    name: 'ZambesiGold',
  },
  {
    id: 'zam-io',
    symbol: 'zam',
    name: 'Zam.io',
  },
  {
    id: 'zamzam',
    symbol: 'zamzam',
    name: 'ZAMZAM',
  },
  {
    id: 'zano',
    symbol: 'zano',
    name: 'Zano',
  },
  {
    id: 'zap',
    symbol: 'zap',
    name: 'Zap',
  },
  {
    id: 'zapexchange',
    symbol: 'zapex',
    name: 'ZapExchange',
  },
  {
    id: 'zapicorn',
    symbol: 'zapi',
    name: 'Zapicorn',
  },
  {
    id: 'zarp-stablecoin',
    symbol: 'zarp',
    name: 'ZARP Stablecoin',
  },
  {
    id: 'zasset-zusd',
    symbol: 'zusd',
    name: 'Zasset zUSD',
  },
  {
    id: 'zatcoin-2',
    symbol: 'zpro',
    name: 'ZAT Project',
  },
  {
    id: 'zbit-ordinals',
    symbol: 'zbit',
    name: 'ZBIT (Ordinals)',
  },
  {
    id: 'zbyte',
    symbol: 'dplat',
    name: 'zbyte',
  },
  {
    id: 'zcash',
    symbol: 'zec',
    name: 'Zcash',
  },
  {
    id: 'zclassic',
    symbol: 'zcl',
    name: 'Zclassic',
  },
  {
    id: 'zcoin',
    symbol: 'firo',
    name: 'Firo',
  },
  {
    id: 'zcore-2',
    symbol: 'zcr',
    name: 'ZCore',
  },
  {
    id: 'zcore-finance',
    symbol: 'zefi',
    name: 'ZCore Finance',
  },
  {
    id: 'z-cubed',
    symbol: 'z3',
    name: 'Z-Cubed',
  },
  {
    id: 'zebec-protocol',
    symbol: 'zbc',
    name: 'Zebec Protocol',
  },
  {
    id: 'zebi',
    symbol: 'zco',
    name: 'Zebi',
  },
  {
    id: 'zed-run',
    symbol: 'zed',
    name: 'ZED RUN',
  },
  {
    id: 'zedxion',
    symbol: 'zedxion',
    name: 'Zedxion',
  },
  {
    id: 'zedxion-usdz',
    symbol: 'usdz',
    name: 'Zedxion USDZ',
  },
  {
    id: 'zeebu',
    symbol: 'zbu',
    name: 'Zeebu',
  },
  {
    id: 'zeedex',
    symbol: 'zdex',
    name: 'Zeedex',
  },
  {
    id: 'zeek-coin',
    symbol: '$meow',
    name: 'Zeek Coin',
  },
  {
    id: 'zeemcoin',
    symbol: 'zeem',
    name: 'Zeemcoin',
  },
  {
    id: 'zeepin',
    symbol: 'zpt',
    name: 'Zeepin',
  },
  {
    id: 'zeeverse',
    symbol: 'vee',
    name: 'Zeeverse',
  },
  {
    id: 'zeitgeist',
    symbol: 'ztg',
    name: 'Zeitgeist',
  },
  {
    id: 'zelaapayae',
    symbol: 'zpae',
    name: 'ZelaaPayAE',
  },
  {
    id: 'zelcash',
    symbol: 'flux',
    name: 'Flux',
  },
  {
    id: 'zelix',
    symbol: 'zelix',
    name: 'ZELIX',
  },
  {
    id: 'zeloop-eco-reward',
    symbol: 'erw',
    name: 'ZeLoop Eco Reward',
  },
  {
    id: 'zelwin',
    symbol: 'zlw',
    name: 'Zelwin',
  },
  {
    id: 'zencash',
    symbol: 'zen',
    name: 'Horizen',
  },
  {
    id: 'zenc-coin',
    symbol: 'zenc',
    name: 'Zenc Coin',
  },
  {
    id: 'zenex',
    symbol: 'znx',
    name: 'ZENEX',
  },
  {
    id: 'zenfuse',
    symbol: 'zefu',
    name: 'Zenfuse',
  },
  {
    id: 'zeniq',
    symbol: 'zeniq',
    name: 'ZENIQ',
  },
  {
    id: 'zenith-chain',
    symbol: 'zenith',
    name: 'Zenith Chain',
  },
  {
    id: 'zenithereum',
    symbol: 'zen-ai',
    name: 'Zenithereum',
  },
  {
    id: 'zenithswap',
    symbol: 'zsp',
    name: 'ZenithSwap',
  },
  {
    id: 'zenith-wallet',
    symbol: 'zw',
    name: 'Zenith Wallet',
  },
  {
    id: 'zenland',
    symbol: 'zenf',
    name: 'Zenland',
  },
  {
    id: 'zenlink-network-token',
    symbol: 'zlk',
    name: 'Zenlink Network',
  },
  {
    id: 'zenon-2',
    symbol: 'znn',
    name: 'Zenon',
  },
  {
    id: 'zenpandacoin',
    symbol: '$zpc',
    name: 'ZenPandaCoin',
  },
  {
    id: 'zensports',
    symbol: 'sports',
    name: 'ZenSports',
  },
  {
    id: 'zent-cash',
    symbol: 'ztc',
    name: 'Zent Cash',
  },
  {
    id: 'zenzo',
    symbol: 'znz',
    name: 'ZENZO',
  },
  {
    id: 'zeon',
    symbol: 'zeon',
    name: 'ZEON Network',
  },
  {
    id: 'zephyr-protocol',
    symbol: 'zeph',
    name: 'Zephyr Protocol',
  },
  {
    id: 'zephyr-protocol-reserve-share',
    symbol: 'zrs',
    name: 'Zephyr Protocol Reserve Share',
  },
  {
    id: 'zephyr-protocol-stable-dollar',
    symbol: 'zsd',
    name: 'Zephyr Protocol Stable Dollar',
  },
  {
    id: 'zer0zer0',
    symbol: '00',
    name: '00 Token',
  },
  {
    id: 'zero',
    symbol: 'zer',
    name: 'Zero',
  },
  {
    id: 'zeroclassic',
    symbol: 'zerc',
    name: 'ZeroClassic',
  },
  {
    id: 'zero-exchange',
    symbol: 'zero',
    name: '0.exchange',
  },
  {
    id: 'zeroliquid',
    symbol: 'zero',
    name: 'ZeroLiquid',
  },
  {
    id: 'zeroliquid-eth',
    symbol: 'zeth',
    name: 'ZeroLiquid ETH',
  },
  {
    id: 'zerosum',
    symbol: 'zsum',
    name: 'ZeroSum',
  },
  {
    id: 'zeroswap',
    symbol: 'zee',
    name: 'ZeroSwap',
  },
  {
    id: 'zeroswapnft',
    symbol: 'zero',
    name: 'ZeroSwapNFT',
  },
  {
    id: 'zero-tech',
    symbol: 'meow',
    name: 'ZERO',
  },
  {
    id: 'zerpaay',
    symbol: 'zrpy',
    name: 'Zerpaay',
  },
  {
    id: 'zetachain',
    symbol: 'zeta',
    name: 'ZetaChain',
  },
  {
    id: 'zetacoin',
    symbol: 'zet',
    name: 'Zetacoin',
  },
  {
    id: 'zethan',
    symbol: 'zeth',
    name: 'Zethan',
  },
  {
    id: 'zetrix',
    symbol: 'zetrix',
    name: 'Zetrix',
  },
  {
    id: 'zeus',
    symbol: 'zeus',
    name: 'Zeus',
  },
  {
    id: 'zeus-ai',
    symbol: 'zeus',
    name: 'Zeus AI',
  },
  {
    id: 'zeus-ai-2',
    symbol: 'zeus',
    name: 'ZEUS AI',
  },
  {
    id: 'zeusaitrading',
    symbol: 'zat',
    name: 'ZeusAiTrading',
  },
  {
    id: 'zeus-finance',
    symbol: 'zeus',
    name: 'Zeus Finance',
  },
  {
    id: 'zeusshield',
    symbol: 'zsc',
    name: 'Zeusshield',
  },
  {
    id: 'zexicon',
    symbol: 'zexi',
    name: 'Zexicon',
  },
  {
    id: 'zfmcoin',
    symbol: 'zfm',
    name: 'ZFMCOIN',
  },
  {
    id: 'zhaodavinci',
    symbol: 'vini',
    name: 'ZhaoDaVinci',
  },
  {
    id: 'zhc-zero-hour-cash',
    symbol: 'zhc',
    name: 'ZHC : Zero Hour Cash',
  },
  {
    id: 'zibu',
    symbol: 'zibu',
    name: 'Zibu',
  },
  {
    id: 'ziesha',
    symbol: 'zsh',
    name: 'Ziesha',
  },
  {
    id: 'zignaly',
    symbol: 'zig',
    name: 'Zignaly',
  },
  {
    id: 'zigzag-2',
    symbol: 'zz',
    name: 'ZigZag',
  },
  {
    id: 'zik-token',
    symbol: 'zik',
    name: 'Ziktalk',
  },
  {
    id: 'zillion-aakar-xo',
    symbol: 'zillionxo',
    name: 'Zillion Aakar XO',
  },
  {
    id: 'zilliqa',
    symbol: 'zil',
    name: 'Zilliqa',
  },
  {
    id: 'zilpay-wallet',
    symbol: 'zlp',
    name: 'ZilPay Wallet',
  },
  {
    id: 'zilpepe',
    symbol: 'zilpepe',
    name: 'ZilPepe',
  },
  {
    id: 'zilstream',
    symbol: 'stream',
    name: 'ZilStream',
  },
  {
    id: 'zilswap',
    symbol: 'zwap',
    name: 'ZilSwap',
  },
  {
    id: 'zin',
    symbol: 'zin',
    name: 'Zin',
  },
  {
    id: 'zino-pet',
    symbol: 'zpet',
    name: 'Zino Pet',
  },
  {
    id: 'zion',
    symbol: 'zion',
    name: 'Zion',
  },
  {
    id: 'zion-token',
    symbol: 'zion',
    name: 'Zion Token',
  },
  {
    id: 'ziontopia',
    symbol: 'zion',
    name: 'ZionTopia',
  },
  {
    id: 'zipmex-token',
    symbol: 'zmt',
    name: 'Zipmex',
  },
  {
    id: 'zipswap',
    symbol: 'zip',
    name: 'ZipSwap',
  },
  {
    id: 'ziv4-labs',
    symbol: 'ziv4',
    name: 'Ziv4 Labs',
  },
  {
    id: 'zizy',
    symbol: 'zizy',
    name: 'Zizy',
  },
  {
    id: 'zjoe',
    symbol: 'zjoe',
    name: 'zJOE',
  },
  {
    id: 'zkapes-token',
    symbol: 'zat',
    name: 'zkApes Token',
  },
  {
    id: 'zkcult',
    symbol: 'zcult',
    name: 'zkCULT',
  },
  {
    id: 'zkdoge',
    symbol: 'zkdoge',
    name: 'zkDoge',
  },
  {
    id: 'zkdx',
    symbol: 'zkdx',
    name: 'ZKDX',
  },
  {
    id: 'zkera-finance',
    symbol: 'zke',
    name: 'zkEra Finance',
  },
  {
    id: 'zkevmchain-bsc',
    symbol: 'zkevm',
    name: 'zkEVMChain (BSC)',
  },
  {
    id: 'zkfair',
    symbol: 'zkf',
    name: 'ZKFair',
  },
  {
    id: 'zkfarmer-io-zkbud',
    symbol: 'zkb farm',
    name: 'zkFarmer.io zkBud',
  },
  {
    id: 'zkgap',
    symbol: 'zkgap',
    name: 'ZKGAP',
  },
  {
    id: 'zkgrok',
    symbol: 'zkgrok',
    name: 'ZKGROK',
  },
  {
    id: 'zk-inu',
    symbol: '$zkinu',
    name: 'ZK inu',
  },
  {
    id: 'zkitty-bot',
    symbol: '$zkitty',
    name: 'ZKitty Bot',
  },
  {
    id: 'zklaunchpad',
    symbol: 'zkpad',
    name: 'zkLaunchpad',
  },
  {
    id: 'zklotto',
    symbol: 'zklotto',
    name: 'zkLotto',
  },
  {
    id: 'zkpepe',
    symbol: 'zkpepe',
    name: 'ZKPepe',
  },
  {
    id: 'zkpepe-2',
    symbol: 'zkpepe',
    name: 'ZKPEPE',
  },
  {
    id: 'zkproof',
    symbol: 'zkp',
    name: 'zkProof',
  },
  {
    id: 'zkshib',
    symbol: 'zkshib',
    name: 'zkShib',
  },
  {
    id: 'zkshield',
    symbol: 'zkshield',
    name: 'zkSHIELD',
  },
  {
    id: 'zkspace',
    symbol: 'zkb',
    name: 'ZKBase',
  },
  {
    id: 'zksvm',
    symbol: 'zksvm',
    name: 'zkSVM',
  },
  {
    id: 'zkswap-92fc4897-ea4c-4692-afc9-a9840a85b4f2',
    symbol: 'zksp',
    name: 'zkSwap',
  },
  {
    id: 'zkswap-finance',
    symbol: 'zf',
    name: 'zkSwap Finance',
  },
  {
    id: 'zksync-id',
    symbol: 'zkid',
    name: 'zkSync id',
  },
  {
    id: 'zktsunami',
    symbol: ':zkt:',
    name: 'ZkTsunami',
  },
  {
    id: 'zkzone',
    symbol: 'zkz',
    name: 'Zkzone',
  },
  {
    id: 'zmine',
    symbol: 'zmn',
    name: 'ZMINE',
  },
  {
    id: 'zoci',
    symbol: 'zoci',
    name: 'Zoci',
  },
  {
    id: 'zodiacsv2',
    symbol: 'zdcv2',
    name: 'ZodiacsV2',
  },
  {
    id: 'zodium',
    symbol: 'zodi',
    name: 'Zodium',
  },
  {
    id: 'zoid-pay',
    symbol: 'zpay',
    name: 'ZoidPay',
  },
  {
    id: 'zombie-inu-2',
    symbol: 'zinu',
    name: 'Zombie Inu',
  },
  {
    id: 'zone',
    symbol: 'zone',
    name: 'Zone',
  },
  {
    id: 'zone-of-avoidance',
    symbol: 'zoa',
    name: 'Zone of Avoidance',
  },
  {
    id: 'zoobit-finance',
    symbol: 'zb',
    name: 'Zoobit Finance',
  },
  {
    id: 'zoocoin',
    symbol: 'zoo',
    name: 'ZooCoin',
  },
  {
    id: 'zoo-coin',
    symbol: 'zoo',
    name: 'ZooCoin (OLD)',
  },
  {
    id: 'zoo-crypto-world',
    symbol: 'zoo',
    name: 'ZOO Crypto World',
  },
  {
    id: 'zoodao',
    symbol: 'zoo',
    name: 'ZooDAO',
  },
  {
    id: 'zookeeper',
    symbol: 'zoo',
    name: 'ZooKeeper',
  },
  {
    id: 'zook-protocol',
    symbol: 'zook',
    name: 'Zook Protocol',
  },
  {
    id: 'zoomer',
    symbol: 'zoomer',
    name: 'Zoomer',
  },
  {
    id: 'zoomswap',
    symbol: 'zm',
    name: 'ZoomSwap',
  },
  {
    id: 'zoopia',
    symbol: 'zooa',
    name: 'Zoopia',
  },
  {
    id: 'zoo-token',
    symbol: 'zoot',
    name: 'Zoo',
  },
  {
    id: 'zorro',
    symbol: 'zorro',
    name: 'Zorro',
  },
  {
    id: 'zpunk',
    symbol: 'zpt',
    name: 'Zpunk',
  },
  {
    id: 'zro',
    symbol: 'zro',
    name: 'Carb0n.fi',
  },
  {
    id: 'zsol',
    symbol: 'zsol',
    name: 'zSOL',
  },
  {
    id: 'ztx',
    symbol: 'ztx',
    name: 'ZTX',
  },
  {
    id: 'zum-token',
    symbol: 'zum',
    name: 'ZUM',
  },
  {
    id: 'zunami-eth',
    symbol: 'zeth',
    name: 'Zunami Ether',
  },
  {
    id: 'zurf',
    symbol: 'zrf',
    name: 'ZURF',
  },
  {
    id: 'zurrency',
    symbol: 'zurr',
    name: 'ZURRENCY',
  },
  {
    id: 'zusd',
    symbol: 'zusd',
    name: 'ZUSD',
  },
  {
    id: 'zuzalu-inu',
    symbol: 'zuzalu',
    name: 'Zuzalu Inu',
  },
  {
    id: 'zuzuai',
    symbol: 'zuzuai',
    name: 'ZUZUAI',
  },
  {
    id: 'zyberswap',
    symbol: 'zyb',
    name: 'Zyberswap',
  },
  {
    id: 'zynecoin',
    symbol: 'zyn',
    name: 'Zynecoin',
  },
  {
    id: 'zynergy',
    symbol: 'zyn',
    name: 'Zynergy',
  },
  {
    id: 'zyrri',
    symbol: 'zyr',
    name: 'Zyrri',
  },
  {
    id: 'zyx',
    symbol: 'zyx',
    name: 'ZYX',
  },
  {
    id: 'zzz',
    symbol: 'zzz',
    name: 'GoSleep ZZZ',
  },
];
