import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { NativeCurrencyFormatWrapper } from '../utility/NativeCurrencyFormatWrapper';
import { ColumnChart } from '../utility/ColumnChart';
import { useGetBotsProfitData } from '../../hooks/http-requests/useGetBotsProfitData';
import { Alert, CircularProgress, Snackbar } from '@mui/material';
import { PieChart } from '../utility/PieChart';

export const BotTradeSummaryWidget = ({ bots, prices, test, isPortfolio }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getBotsProfitData } = !isPortfolio ? useGetBotsProfitData(test) : '';

  const ordersBalance = useMemo(
    () =>
      bots?.reduce((acc, curr) => {
        const price = prices[curr.symbol.toLowerCase()];
        if (!price || isNaN(price)) return acc;
        const b = curr.volume_coins * prices[curr.symbol.toLowerCase()];
        return acc + b;
      }, 0),
    [bots, prices]
  );

  const isLoadingPrice = useMemo(() => {
    let loading = false;
    bots.forEach((e) => {
      if (!prices[e.symbol.toLowerCase()]) loading = true;
    });
    return loading;
  }, [bots, prices]);

  const startBalance = useMemo(() => bots?.reduce((acc, curr) => acc + curr.fund, 0), [bots]);
  const reservedBalance = useMemo(() => bots?.reduce((acc, curr) => acc + curr.fund - curr.total + (curr.is_reinvest ? curr.profit_bot : 0), 0), [bots]);
  const accountBalance = useMemo(() => bots?.reduce((acc, curr) => acc + curr.fund - curr.total, 0), [bots]);
  const closedCount = useMemo(() => bots?.reduce((acc, curr) => acc + +curr.closed_count, 0), [bots]);
  const investedInOrders = useMemo(() => bots?.reduce((acc, curr) => acc + curr.total, 0), [bots]);
  const profit = useMemo(() => bots?.reduce((acc, curr) => acc + curr.profit_bot, 0), [bots]);

  const profitPercent = useMemo(() => {
    if (!startBalance || !profit) return 0;
    return ((startBalance + profit) / startBalance - 1) * 100;
  }, [startBalance, profit]);

  const currentBalance = useMemo(() => accountBalance + ordersBalance + profit, [accountBalance, ordersBalance, profit]);

  const series = useMemo(() => {
    return bots
      .map((b) => {
        return {
          value: (100 / startBalance) * b.fund,
          name: b.symbol,
          fund: b.total,
          coins: b.volume_coins,
        };
      })
      .reduce((acc, item) => {
        const found = acc.find((i) => i.name === item.name);
        if (found) {
          found.value += item.value;
          found.fund += item.fund;
          found.coins += item.coins;
        } else {
          acc.push({ ...item });
        }
        return acc;
      }, [])
      .sort((a, b) => b.value - a.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bots]);

  return (
    <div className="bots-trade-widget__content">
      <Snackbar open={isLoadingPrice} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert sx={{ boxShadow: '0 0 15px rgba(0,0,0,0.37)' }} icon={<CircularProgress color="primary" size={20} />} severity="info">
          {t('bots.loading-price')}
        </Alert>
      </Snackbar>
      <div className="bots-trade-widget__title">
        <div className="flex">
          <div>
            <div>{t(`bots.summary-widget.${!test ? 'summary' : 'summary-test'}`)}</div>
            <div className="bots-trade-widget__desc">
              {t('bots.summary-widget.active-bots')} {bots.length}
            </div>
          </div>
        </div>
      </div>
      {!isPortfolio ? (
        <div className="bots-trade-widget__chart">
          <ColumnChart data={getBotsProfitData.data} withDetails={false} />
        </div>
      ) : (
        <div className="bots-trade-widget__pie">
          <PieChart series={series.map((e) => +e.value.toFixed(2))} legend={series.map((e) => e.name)} />
        </div>
      )}
      <div className="bots-trade-widget__label">
        <div className="-text">
          {t('bots.summary-widget.balance-bots')}
          <div className="f-small c-base-600">{t('bots.summary-widget.assets-sum')}</div>
        </div>
        <div className={`-value ${currentBalance > startBalance ? 'c-green' : 'c-red'}`}>
          {isLoadingPrice ? (
            <CircularProgress sx={{ mt: 1 }} color="primary" size={20} />
          ) : (
            <>
              <div className="-price">
                $<NativeCurrencyFormatWrapper value={currentBalance} isUsd={true} />
              </div>
              <div className={`f-small`}>
                $<NativeCurrencyFormatWrapper value={currentBalance - startBalance} isUsd={true} /> ({((currentBalance / startBalance - 1) * 100).toFixed(2)}%)
              </div>
            </>
          )}
        </div>
      </div>

      {isPortfolio && (
        <div className="bots-trade-widget__label">
          <div className="-text">
            {series.map((b) => (
              <div className="f-small c-base-600" key={b.name}>
                {b.name}
              </div>
            ))}
          </div>
          <div className={`-value`}>
            {isLoadingPrice ? (
              <CircularProgress sx={{ mt: 1 }} color="primary" size={20} />
            ) : (
              <>
                {series.map((b) => (
                  <div className={`f-small ${b.coins * prices[b.name.toLowerCase()] > b.fund ? 'c-green' : 'c-red'}`} key={b.coins}>
                    $<NativeCurrencyFormatWrapper value={b.coins * prices[b.name.toLowerCase()] - b.fund} isUsd={true} /> (
                    {(((b.coins * prices[b.name.toLowerCase()]) / b.fund - 1) * 100).toFixed(2)}
                    %)
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}

      {/* вложено в сделкиио */}
      {!isPortfolio && (
        <div className="bots-trade-widget__label">
          <div className="-text">
            {t('bots.summary-widget.expenses')}
            <div className="f-small c-base-600">{t('bots.summary-widget.exchange-rate')}</div>
          </div>
          <div className="-value">
            {isLoadingPrice ? (
              <CircularProgress sx={{ mt: 1 }} color="primary" size={20} />
            ) : (
              <>
                <div>
                  $<NativeCurrencyFormatWrapper value={investedInOrders} isUsd={true} />
                </div>
                <div className={`f-small ${ordersBalance > investedInOrders ? 'c-green' : 'c-red'}`}>
                  $<NativeCurrencyFormatWrapper value={ordersBalance - investedInOrders} isUsd={true} /> (
                  {startBalance ? ((ordersBalance / investedInOrders - 1) * 100).toFixed(2) : 0}%)
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {/* прибыль от сделок */}
      {!isPortfolio && (
        <div className="bots-trade-widget__label">
          <div className="-text">{t('bots.summary-widget.profit')}</div>
          <div className={`-value ${profitPercent > 0 ? 'c-green' : 'c-red'}`}>
            $<NativeCurrencyFormatWrapper value={profit} isUsd={true} /> ({profitPercent.toFixed(2)}%)
          </div>
        </div>
      )}
      {/* зарезервировано */}
      {!isPortfolio && (
        <div className="bots-trade-widget__label">
          <div className="-text">{t('bots.summary-widget.reserved')}</div>
          <div className={`-value`}>
            $<NativeCurrencyFormatWrapper value={reservedBalance} isUsd={true} />
          </div>
        </div>
      )}
      {/* разделитель */}
      {!isPortfolio && (
        <div className="bots-trade-widget__label">
          <div className="-text w-fill">
            <hr />
          </div>
        </div>
      )}
      {/* кол-во сделок */}
      {!isPortfolio && (
        <div className="bots-trade-widget__label">
          <div className="-text">{t('bots.summary-widget.closed-deals')}</div>
          <div className={`-value`}>
            {closedCount}
            {t('bots.widget.pieces')}
          </div>
        </div>
      )}
      {/* нач кап. */}
      <div className="bots-trade-widget__label">
        <div className="-text">{t('bots.summary-widget.start-balance')}</div>
        <div className={`-value`}>
          $<NativeCurrencyFormatWrapper value={startBalance} isUsd={true} />
        </div>
      </div>
    </div>
  );
};
