import * as React from 'react';
import { TradingViewWidget } from './utility/TradingViewWidget';
import CustomModal from './CustomModal';
import './AnalyticsWidgetModal.scss';
import PercentPriceBlock from './PercentPriceBlock';
import { t } from 'i18next';

export const AnalyticsWidgetModal = ({ open, onClose, trendingViewSymbol, symbol, wallet }) => {
  return (
    <CustomModal header={t('bots.add-bot.tab.inform')} className="big" show={open} onClose={() => onClose()}>
      {/* история цены */}
      <div className="mb-10 fs-14 fw-500">{t('bots.add-bot.draw-price')}</div>
      <PercentPriceBlock symbol={symbol} wallet={wallet} />

      {/* график */}
      {trendingViewSymbol && (
        <div className="pt-24">
          <div className="mb-10 fs-14 fw-500">{t('bots.add-bot.tab.graph')}</div>
          <div className="analitycs-modal__chart">
            <TradingViewWidget symbolProp={trendingViewSymbol} showDetails={true} range={'3M'} />
          </div>
        </div>
      )}
    </CustomModal>
  );
};
