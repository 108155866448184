import { useEffect, useState } from 'react';
import { useGetBybitKlinesHistoricData } from '../../hooks/http-requests/useGetBybitKlinesHistoricData';

export const BybitHistoricData = ({ symbols, onSymbolData, active, interval = '120', limit = 84 }) => {
  const [storedSymbols, setStoredSymbols] = useState();
  const { bybitKlinesHistoricData } = useGetBybitKlinesHistoricData();

  useEffect(() => {
    if (!active) {
      setStoredSymbols(undefined);
      return;
    }
    if (JSON.stringify(symbols) === JSON.stringify(storedSymbols) || !symbols.length) return;
    const requests = symbols.map((s) =>
      bybitKlinesHistoricData.mutateAsync({
        symbol: s.toUpperCase(),
        interval,
        limit,
      })
    );
    Promise.allSettled(requests).then((results) => {
      results.forEach((r) => {
        if (r.status === 'rejected') return;
        onSymbolData({
          symbol: r.value.symbol.toLowerCase(),
          data: r.value.klines,
        });
      });
    });
    setStoredSymbols(symbols);
  }, [symbols, active, storedSymbols]);
};
