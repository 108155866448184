import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useCreateBonusPayment = () => {
  const queryClient = useQueryClient();

  const createBonusPayment = useMutation(ApiService.createBonusPayment, {
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['user'] });
    },
  });

  return { createBonusPayment };
};
