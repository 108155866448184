import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useUsersStatisticsAdmin = () => {
  const statisticsInfo = useQuery({
    queryKey: ['adminUsersStatistics'],
    queryFn: () => ApiService.getUsersStatisticsAdmin(),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
  });

  return { statisticsInfo };
};
