const getNormDate = (date) => {
  const d = new Date(date);
  return `${d.getMonth() + 1}/01/${d.getFullYear()}`;
};

export default function NomolizeColimnChartData(data = []) {
  data = data.sort((a, b) => a[0] - b[0]);

  const res = [];

  let lastE = [];
  data.forEach((e, i) => {
    if (i > 0) {
      if (new Date(lastE[0]).getMonth() !== new Date(e[0]).getMonth()) {
        res.push([getNormDate(e[0]), e[1]]);
      } else {
        res[res.length - 1][1] += e[1];
      }
    } else {
      res.push([getNormDate(e[0]), e[1]]);
    }
    lastE = e;
  });
  return res;
}
