import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import './TelegramModal.scss';
import CustomModal from './CustomModal';

export const TelegramModal = ({ open, onClose, id }) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      show={open}
      onClose={() => onClose()}
      header={t('profile.telegram.title')}
    >
      <Link
        href={`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_NAME}?start=${id}`}
        target="_blank"
        rel="noopener"
        className="telegram-link"
      >
        {t('profile.telegram.subscribe.link')}
      </Link>
    </CustomModal>
  );
};
