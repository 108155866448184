import { useQueryClient, useMutation } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useDeletePortfolioBot = () => {
  const queryClient = useQueryClient();

  const deletePortfolioBot = useMutation(ApiService.deleteTradeBot, {
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['bots/portfolio'] });
    },
  });
  return { deletePortfolioBot };
};
