import { useMemo, useState } from 'react';
import { Collapse } from '@mui/material';
import { AddWalletModal } from '../wallet/AddWalletModal';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useUserWallets } from '../../hooks/http-requests/useUserWallets';
import { useAvailableWallets } from '../../hooks/http-requests/useAvailableWallets';
import { useAuth } from '../../hooks/utility/useAuth';
import { useTranslation } from 'react-i18next';
import './NavBar.scss';
import Icon from '../Icons';
import { useGetTariffs } from '../../hooks/http-requests/useGetTariffs';
import { useGetCountTradeBots } from '../../hooks/http-requests/useGetCountTradeBots';
import { NativeDateLocaleWrapper } from '../utility/NativeDateLocaleWrapper';
import { useGetUser } from '../../hooks/http-requests/useGetUser';
import { useGetCountMultiBots } from '../../hooks/http-requests/useGetCountMultiBots';
import { useUpdateUser } from '../../hooks/http-requests/useUpdateUser';

export const NavBar = ({ navOpen, toggleNavOpen, menuItems, rate }) => {
  const [open, setOpen] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(true);
  const [showTariff, setShowTariff] = useState(false);
  const { availableWallets } = useAvailableWallets();
  const { userWallets } = useUserWallets();
  const { user, logout } = useAuth();
  const { i18n, t } = useTranslation();
  const { tariffs } = useGetTariffs();
  const { result: countTradeBots } = useGetCountTradeBots();
  const { result: countMultiBots } = useGetCountMultiBots();
  const { user: userInfo } = useGetUser();
  const { updateUser } = useUpdateUser();

  const walletOptions = useMemo(() => {
    return userWallets.data?.map((w) => {
      const wallet = availableWallets.data?.find((aw) => aw.id === w.exchange_id);
      return {
        ...w,
        name: wallet?.name,
        type: wallet?.type,
        tag: wallet?.tag,
      };
    });
  }, [availableWallets.data, userWallets.data]);

  const changeLanguage = async () => {
    const language = i18n.language === 'ru' ? 'en' : 'ru';
    localStorage.setItem('application_language', language);
    await i18n.changeLanguage(language);
    await updateUser.mutateAsync({ locale: language });
  };

  const openDialog = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  function onclickLink(bool) {
    bool ? setCollapseOpen(!collapseOpen) : toggleNavOpen();
  }

  const userTariff = useMemo(() => {
    if (new Date(userInfo?.data?.tariff_end_at) < new Date()) {
      return tariffs?.data?.tariffs?.find((t) => t.price === 0);
    }
    return tariffs?.data?.tariffs?.find((t) => t.id === userInfo?.data?.tariff_id);
  }, [tariffs.data, userInfo.data]);

  const resellerCount = +countTradeBots?.data?.reseller || 0;
  const portfolioCount = +countTradeBots?.data?.portfolio || 0;
  const investmentCount = +countTradeBots?.data?.investment || 0;
  const rebalancerCount = +countMultiBots?.data;

  const menuItem = (item) => (
    <NavLink
      className={({ isActive }) => (isActive && !item.sublinks ? 'active' : '')}
      to={item.url || '#'}
      end={item.end}
      onClick={() => onclickLink(!!item.sublinks)}
    >
      <div className="icon-wrap">
        <Icon icon={item.icon} />
      </div>
      <span>
        {item.title} {item?.sublinks && <span className="count">{walletOptions?.length}</span>}
      </span>
      {item.sublinks && (
        <div className="plus icon" onClick={openDialog}>
          <Icon icon="plus" />
        </div>
      )}
    </NavLink>
  );

  const dropdownMenu = (
    <div className="dropmenu">
      <Collapse in={collapseOpen}>
        {walletOptions?.map((sub, i) => (
          <NavLink key={i + 'a'} to={`dashboard/wallets/${sub.id}`}>
            <img className="wallet-icon" src={`/img/wallets/${sub.tag}.svg`} alt="" />
            <span>{sub.name}</span>
          </NavLink>
        ))}
      </Collapse>
    </div>
  );

  const menu = (
    <div className="menu">
      {menuItems.map((item, i) => (
        <span key={i + 'b'}>
          {menuItem(item)}
          {!!item.sublinks && dropdownMenu}
        </span>
      ))}
    </div>
  );

  const tarif = (
    <>
      {userTariff?.id && (
        <div className="tarif">
          <div className="tarif-title" onClick={() => setShowTariff(!showTariff)}>
            {t('profile.current-tariff')} "{userTariff?.title}"
            <Icon icon={`arrow-${showTariff ? 'down' : 'up'}`} />
          </div>
          <div className="tarif-content">
            <Collapse in={showTariff}>
              <div className="tarif-item">
                <span>{t('bots.widget.type.reseller')}</span>
                <span>
                  {resellerCount}/{userTariff?.reseller_count}
                </span>
              </div>
              <div className="tarif-item">
                <span>{t('bots.widget.type.portfolio')}</span>
                <span>
                  {portfolioCount}/{userTariff?.portfolio_count}
                </span>
              </div>
              <div className="tarif-item">
                <span>{t('bots.widget.type.investment')}</span>
                <span>
                  {investmentCount}/{userTariff?.investment_count}
                </span>
              </div>
              <div className="tarif-item">
                <span>{t('bots.widget.type.rebalancer')}</span>
                <span>
                  {rebalancerCount}/{userTariff?.rebalancer_count}
                </span>
              </div>
              <div className="tarif-item">
                <span>{t('profile.tariffEndAt')}</span>
                <span>
                  {userTariff?.id !== 1 ? (
                    <NativeDateLocaleWrapper
                      date={userInfo?.data?.tariff_end_at}
                      options={{
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      }}
                    />
                  ) : (
                    '-'
                  )}
                </span>
              </div>
            </Collapse>
          </div>
        </div>
      )}
    </>
  );

  const lang = i18n.language === 'ru' ? 'en' : 'ru';

  const navBarBottom = (
    <div className="bottom">
      <span className="btn icon link" onClick={logout}>
        <Icon icon="exit" />
        <span>{t('header.logout')}</span>
      </span>
      <span className="btn icon link lang" onClick={changeLanguage}>
        <span>{lang}</span>

        <img src={`/img/lang/${lang}.svg`} alt="" />
      </span>
    </div>
  );

  return (
    <>
      <nav className={navOpen ? 'show' : ''}>
        <div className="nav-bg" onClick={() => toggleNavOpen()}></div>

        <NavLink className="account" to="/dashboard/profile" onClick={() => toggleNavOpen(false)}>
          <Icon icon="avatar" />
          <div className="account__name">{user.name || user.email}</div>
        </NavLink>
        {menu}
        {rate ? tarif : ''}
        {navBarBottom}
      </nav>
      <AddWalletModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};
