import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import CustomModal from '../CustomModal';

export const DisableBotModal = ({ open, onClose, type }) => {
  const { t } = useTranslation();
  const isPortfolio = type === 'portfolio';
  return (
    <CustomModal
      show={open}
      onClose={() => onClose()}
      header={t(`confirm.bot.disable.title`)}
      footer={
        <>
          <Button onClick={() => onClose()} variant="contained" color="warning">
            {t('dialog.cancel')}
          </Button>
          <Button onClick={() => onClose('now')} variant="contained">
            {t('confirm.bot.disable.now')}
          </Button>
          {!isPortfolio && (
            <Button onClick={() => onClose('after-sale')} variant="contained">
              {t('confirm.bot.disable.after-sale')}
            </Button>
          )}
        </>
      }
    >
      {t(`confirm.bot.disable.${isPortfolio ? 'portfolio.' : ''}text`)}
    </CustomModal>
  );
};
