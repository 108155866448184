import { useEffect, useState } from 'react';
import Icon from './Icons';
import './CustomModal.scss';
import { IconButton } from '@mui/material';

const CustomModal = ({
  show,
  onClose,
  header,
  footer,
  children,
  className = '',
}) => {
  const [padding, setPadding] = useState();

  if (show) {
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = '17px';
  }

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = '17px';
      setPadding(0);
    }

    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '0';
      setPadding(17);
    };
  }, [show]);

  return (
    <>
      {show && (
        <div className="custom-modal__bg" style={{ paddingLeft: padding }}>
          <div className={`custom-modal ${className}`}>
            <h3 className="custom-modal__header">
              {header}
              <div className="custom-modal__close">
                <IconButton aria-label="close dialog" onClick={() => onClose()}>
                  <Icon icon="close" />
                </IconButton>
              </div>
            </h3>

            <div className="custom-modal__content">{children}</div>

            {!!footer && <div className="custom-modal__footer">{footer}</div>}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomModal;
