import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useCurrencyCalculator = () => {
  const currencyList = useQuery({
    queryKey: ['currencyList'],
    queryFn: ApiService.getCurrencyList,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
  });

  return { currencyList };
};
