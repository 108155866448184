import { Box, Tooltip } from '@mui/material';
import { ReloadButton } from './utility/ReloadButton';
import { useBalanceInfo } from '../hooks/http-requests/useBalanceInfo';
import { DonutChart } from './utility/DonutChart';
import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import { NativeCurrencyFormatWrapper } from './utility/NativeCurrencyFormatWrapper';
import Preloader from './Preloader';
import './BalanceWidget.scss';
import { NotDataCard } from './utility/NotDataCard';
import { useGetTradeBots } from '../hooks/http-requests/useGetTradeBots';
import { useGetMultiBots } from '../hooks/http-requests/useGetMultiBots';
import Icon from './Icons';
import { useGetPortfolioBots } from '../hooks/http-requests/useGetPortfolioBots';

export const BalanceMultiWidget = ({ wallet }) => {
  const { t } = useTranslation();
  const { userId, tag } = wallet;
  const { balanceInfo } = useBalanceInfo(userId);
  const queryClient = useQueryClient();
  const tradeBots = useGetTradeBots().bots.data;
  const portfolioBots = useGetPortfolioBots().bots.data;
  const multiBots = useGetMultiBots().result.data;
  const bots = tradeBots?.concat(portfolioBots);
  // const bots = [...tradeBots, ...portfolioBots];

  const reservedCoins = useMemo(() => {
    const result = {};
    const mBots = multiBots?.filter((e) => e?.status === 'ready' && !e.emulate_operations && tag === e.tag);
    const tBots = bots?.filter((e) => e?.status === 'active' && !e.emulate_operations && tag === e.tag);

    if (mBots?.length || tBots?.length) result.USDT = 0;

    if (multiBots?.length)
      mBots.forEach((m) => {
        m.data.data.forEach((e) => {
          const symbol = e.symbol.replace('USDT', '');
          if (result[symbol]) result[symbol] += +e.amount;
          else result[symbol] = +e.amount;
        });
      });

    if (bots?.length)
      tBots.forEach((e) => {
        result.USDT += +e.fund - +e.total + (e.is_reinvest ? +e.profit_bot : 0);

        const symbol = e.symbol.replace('USDT', '');
        if (result[symbol]) result[symbol] += +e.volume_coins;
        else result[symbol] = +e.volume_coins;
      });

    return result;
  }, [multiBots, bots, tag]);

  const reloadBalance = () => {
    queryClient.refetchQueries({ queryKey: ['balance'] });
  };

  const getReservUsdt = (symbol, free, moneyAmount) => {
    const price = moneyAmount / Number(free || 0);
    return price * reservedCoins[symbol] || 0;
  };

  const _balanceData = useMemo(
    () =>
      balanceInfo.data
        ?.slice()
        ?.map((e) => {
          return {
            ...e,
            moneyAmount: e.moneyAmount - getReservUsdt(e.coin, e.free || e.equity, e.moneyAmount),
            amount: e.amount - (reservedCoins[e.coin] || 0),
          };
        })
        ?.sort((a, b) => b.moneyAmount - a.moneyAmount)
        ?.filter((e) => e.moneyAmount > 0),
    [balanceInfo.data]
  );

  //reserved block
  const reservedData = useMemo(() => {
    let result = [];
    for (const key in reservedCoins) {
      if (Object.hasOwnProperty.call(reservedCoins, key)) {
        const amount = reservedCoins[key];
        const coinData = _balanceData?.find((e) => e.coin === key) || {};
        const price = coinData.moneyAmount / +coinData.amount;
        result.push({ coin: key, amount, moneyAmount: price * amount, price, color: coinData.color });
      }
    }

    const summ = result?.reduce((acc, current) => {
      return acc + current.moneyAmount;
    }, 0);

    result = result
      ?.map((e) => {
        return {
          ...e,
          percent: (100 / summ) * e.moneyAmount,
        };
      })
      ?.sort((a, b) => b.moneyAmount - a.moneyAmount);

    return result;
  }, [_balanceData, reservedCoins]);

  const reservedDonutSeries = useMemo(() => reservedData?.map((c) => c.percent) || [], [reservedData]);

  const reservedDonutColors = useMemo(() => reservedData?.map((c) => (c.color === 'black' ? '#000' : c.color)) || [], [reservedData]);

  const reservedSummary = useMemo(
    () =>
      reservedData?.reduce((acc, current) => {
        return acc + current.moneyAmount;
      }, 0),
    [reservedData]
  );

  const summary = useMemo(
    () =>
      _balanceData?.reduce((acc, current) => {
        return acc + current.moneyAmount;
      }, 0),
    [_balanceData]
  );

  const balanceData = useMemo(
    () =>
      _balanceData?.map((e) => {
        return {
          ...e,
          percent: +((100 / summary) * e.moneyAmount).toFixed(2),
        };
      }),
    [_balanceData, summary]
  );

  const donutSeries = useMemo(() => balanceData?.map((c) => c.percent) || [], [balanceData]);

  const donutColors = useMemo(() => balanceData?.map((c) => c.color) || [], [balanceData]);

  return (
    <>
      <div className="card">
        <div className="card__header">
          <div className="card__title">
            <div className="flex">
              <div className="mr-8">{t('wallet.free-balance')}</div>
              <Tooltip arrow title={t('wallet.free-info')}>
                <div>
                  <Icon icon="info" className="--small --info" />
                </div>
              </Tooltip>
            </div>
            <ReloadButton loading={balanceInfo.isLoading || balanceInfo.isFetching} onClick={reloadBalance} />
          </div>
          {balanceData?.length === 0 && !balanceInfo.isLoading && !balanceInfo.isFetching && <NotDataCard text={t('common.no-data')} className="no-card" />}
          <div className="widget-wallets__statistics">
            {balanceData?.length !== 0 && !balanceInfo.isLoading && !balanceInfo.isFetching && (
              <>
                <div>
                  <h3>
                    $
                    <NativeCurrencyFormatWrapper value={summary} isUsd={true} />
                  </h3>
                  <span>{t('wallet.free-sum')}</span>
                </div>
                <Box sx={{ height: '80px', width: '80px' }}>
                  <DonutChart series={donutSeries} colors={donutColors} />
                </Box>
              </>
            )}
            {(balanceInfo.isLoading || balanceInfo.isFetching) && <Preloader loading={true} />}
          </div>
        </div>
        <div className="card__body">
          <Scrollbars style={{ height: 370 }} autoHide>
            <table className="widget-wallets__table">
              {balanceData?.length !== 0 && !balanceInfo.isLoading && !balanceInfo.isFetching && (
                <tbody>
                  {balanceData?.map((c, i) => (
                    <tr v-for="coin in wallet.coins" key={i}>
                      <td>
                        <img className="card__coin-icon" src={process.env.REACT_APP_ICONS_SERVER + c.coin?.toLowerCase()} alt="" />
                      </td>
                      <td>
                        <div className="bold">
                          <NativeCurrencyFormatWrapper value={c.amount} isUsd={false} maximumFractionDigits="6" />
                        </div>
                        <div className="f-small">{c.coin === 'other' ? t('balance.widget.other') : c.coin}</div>
                      </td>
                      <td className="bold a-center w-fill">{c.percent}%</td>
                      <td className="bold a-right">
                        <div>
                          $
                          <NativeCurrencyFormatWrapper value={c.moneyAmount} isUsd={true} />
                        </div>
                        <div className="widget-wallets__line">
                          <div
                            style={{
                              width: c.percent + '%',
                              backgroundColor: c.color,
                            }}
                          ></div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </Scrollbars>
        </div>
      </div>

      <div className="card">
        <div className="card__header">
          <div className="card__title">
            <div className="flex">
              <div className="mr-8">{t('wallet.reserved-balance')}</div>
              <Tooltip arrow title={t('wallet.reserved-info')}>
                <div>
                  <Icon icon="info" className="--small --info" />
                </div>
              </Tooltip>
            </div>
            <ReloadButton loading={balanceInfo.isLoading || balanceInfo.isFetching} onClick={reloadBalance} />
          </div>
          {reservedData?.length === 0 && !balanceInfo.isLoading && !balanceInfo.isFetching && <NotDataCard text={t('common.no-data')} className="no-card" />}
          <div className="widget-wallets__statistics">
            {reservedData?.length !== 0 && !balanceInfo.isLoading && !balanceInfo.isFetching && (
              <>
                <div>
                  <h3>
                    $
                    <NativeCurrencyFormatWrapper value={reservedSummary} isUsd={true} />
                  </h3>
                  <span>{t('wallet.reserved-sum')}</span>
                </div>
                <Box sx={{ height: '80px', width: '80px' }}>
                  <DonutChart series={reservedDonutSeries} colors={reservedDonutColors} />
                </Box>
              </>
            )}
            {(balanceInfo.isLoading || balanceInfo.isFetching) && <Preloader loading={true} />}
          </div>
        </div>
        <div className="card__body">
          <Scrollbars style={{ height: 370 }} autoHide>
            <table className="widget-wallets__table">
              {reservedData?.length !== 0 && !balanceInfo.isLoading && !balanceInfo.isFetching && (
                <tbody>
                  {reservedData?.map((c, i) => (
                    <tr v-for="coin in wallet.coins" key={i}>
                      <td>
                        <img className="card__coin-icon" src={process.env.REACT_APP_ICONS_SERVER + c.coin?.toLowerCase()} alt="" />
                      </td>
                      <td>
                        <div className="bold">
                          <NativeCurrencyFormatWrapper value={c.amount} isUsd={false} maximumFractionDigits="6" />
                        </div>
                        <div className="f-small">{c.coin === 'other' ? t('balance.widget.other') : c.coin}</div>
                      </td>
                      <td className="bold a-center w-fill">{c.percent.toFixed(2)}%</td>
                      <td className="bold a-right">
                        <div>
                          $
                          <NativeCurrencyFormatWrapper value={c.moneyAmount} isUsd={true} />
                        </div>
                        <div className="widget-wallets__line">
                          <div
                            style={{
                              width: c.percent + '%',
                              backgroundColor: c.color,
                            }}
                          ></div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </Scrollbars>
        </div>
      </div>
    </>
  );
};
