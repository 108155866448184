import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useBalanceInfoAdmin = () => {
  const balanceInfo = useQuery({
    queryKey: ['adminbalance'],
    queryFn: () => ApiService.getBalanceInfoAdmin(),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
  });

  return { balanceInfo };
};
