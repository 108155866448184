import { number, object, string } from 'yup';
import i18n from '../i18n';
import { BOT_REBALANCER_TYPE_VALUES, getBotRebalancerMinFund } from './bot-rebalancer.types';
import { BOT_TYPE_VALUES, BOT_TYPES } from './bot-types';

export const addBotValidation = object({
  name: string().max(255, i18n.t('validation.max-length', { max: 255 })),
  type: string().oneOf(BOT_TYPE_VALUES),
  user_data_id: string().required(i18n.t('validation.required')),
  symbol: string().when('type', {
    is: (c) => c !== BOT_TYPES.rebalancer,
    then: (schema) => schema.required(i18n.t('validation.required')),
  }),
  drawdown: string().when('type', {
    is: (c) => c !== BOT_TYPES.rebalancer,
    then: (schema) => schema.required(i18n.t('validation.required')),
  }),
  fund: number()
    .required(i18n.t('validation.required'))
    .when(
      ['type', 'rebalancerType', 'usdtAmount', 'freeTariff', 'emulateOperations'],
      ([type, rebalancerType, usdtAmount, freeTariff, emulateOperations], schema) =>
        type !== BOT_TYPES.rebalancer
          ? schema
              .min(
                type === BOT_TYPES.reseller ? 100 : 100,
                i18n.t('validation.greater-than', {
                  min: type === BOT_TYPES.reseller ? 100 : 100,
                })
              )
              .max(
                usdtAmount < 2000 && !emulateOperations ? usdtAmount : 2000,
                i18n.t(
                  usdtAmount < 2000 && !emulateOperations
                    ? freeTariff
                      ? 'validation.less-than.amount.tariff'
                      : 'validation.less-than.amount'
                    : 'validation.less-than',
                  {
                    max: usdtAmount < 2000 && !emulateOperations ? usdtAmount : 2000,
                  }
                )
              )
          : schema
              .min(
                getBotRebalancerMinFund(rebalancerType),
                i18n.t('validation.greater-than', {
                  min: getBotRebalancerMinFund(rebalancerType),
                })
              )
              .max(
                usdtAmount < 10000 && !emulateOperations ? usdtAmount : 10000,
                i18n.t(usdtAmount < 10000 && !emulateOperations ? 'validation.less-than.amount' : 'validation.less-than', {
                  max: usdtAmount < 10000 && !emulateOperations ? usdtAmount : 10000,
                })
              )
    ),
  stopLoss: number()
    .when('isStopLoss', {
      is: true,
      then: (schema) =>
        schema
          .required(i18n.t('validation.required'))
          .moreThan(0, i18n.t('validation.greater-than', { min: 0 }))
          .max(100, i18n.t('validation.less-than', { max: 100 })),
    })
    .when(['drawdown', 'isStopLoss'], ([drawdown, isStopLoss], schema) =>
      isStopLoss ? schema.moreThan(drawdown, i18n.t('validation.greater-than', { min: drawdown })) : schema
    ),
  profit: number().when('isExitAfterProfit', {
    is: true,
    then: (schema) =>
      schema
        .required(i18n.t('validation.required'))
        .moreThan(0, i18n.t('validation.greater-than', { min: 0 }))
        .max(100, i18n.t('validation.less-than', { max: 100 })),
  }),
  rebalancerType: string().when('type', {
    is: BOT_TYPES.rebalancer,
    then: (schema) => schema.required(i18n.t('validation.required')).oneOf(BOT_REBALANCER_TYPE_VALUES),
  }),
});

export const editBotValidation = object({
  name: string().max(255, i18n.t('validation.max-length', { max: 255 })),
  stopLoss: number()
    .when('isStopLoss', {
      is: true,
      then: (schema) =>
        schema
          .required(i18n.t('validation.required'))
          .moreThan(0, i18n.t('validation.greater-than', { min: 0 }))
          .max(100, i18n.t('validation.less-than', { max: 100 })),
    })
    .when(['drawdown', 'isStopLoss'], ([drawdown, isStopLoss], schema) =>
      isStopLoss ? schema.moreThan(drawdown, i18n.t('validation.greater-than', { min: drawdown })) : schema
    ),
  profit: number().when('isExitAfterProfit', {
    is: true,
    then: (schema) =>
      schema
        .required(i18n.t('validation.required'))
        .moreThan(0, i18n.t('validation.greater-than', { min: 0 }))
        .max(100, i18n.t('validation.less-than', { max: 100 })),
  }),
});

export const profileValidation = object({
  name: string().max(255, i18n.t('validation.max-length', { max: 255 })),
  email: string().email(i18n.t('validation.email')),
});

export const withdrawBonusesValidation = object({
  amount: number()
    .required(i18n.t('validation.required'))
    .min(15, i18n.t('validation.greater-than', { min: 15 })),
  wallet: string().required(i18n.t('validation.required')),
});

export const calculatorValidation = object({
  summ: number()
    .required(i18n.t('validation.required'))
    .min(100, i18n.t('validation.greater-than', { min: 100 })),
});
