import { useTranslation } from 'react-i18next';
import './WellcomeBlock.scss';
import { Alert } from '@mui/material';
import { useUserWallets } from '../hooks/http-requests/useUserWallets';
import { useAuth } from '../hooks/utility/useAuth';

export const NoWalletsBlock = () => {
  const { userWallets } = useUserWallets();
  const { t } = useTranslation();
  const { isAdmin } = useAuth();

  return (
    <>
      {userWallets?.data?.length === 0 && !isAdmin() && (
        <Alert className="mb-16" variant="filled" severity="info">
          {t('welcome-block.no-wallets')}
        </Alert>
      )}
    </>
  );
};
