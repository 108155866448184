import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import { useDeleteWallet } from '../../hooks/http-requests/useDeleteWallet';
import CustomModal from '../CustomModal';

export const DeleteWalletModal = ({ open, onClose, id }) => {
  const { t } = useTranslation();
  const { deleteWallet } = useDeleteWallet();

  const onDeleteWalletClick = () => {
    deleteWallet.mutateAsync(id).then(() => {
      onClose(true);
      navigate(`/dashboard`);
    });
  };

  const navigate = useNavigate();

  return (
    <CustomModal
      show={open}
      onClose={onClose}
      header={t('delete-wallet.title')}
      footer={
        <>
          <Button
            onClick={onClose}
            variant="contained"
            color="warning"
            disabled={deleteWallet.isLoading}
          >
            {t('dialog.cancel')}
          </Button>
          <Button
            onClick={onDeleteWalletClick}
            variant="contained"
            disabled={deleteWallet.isLoading}
          >
            {deleteWallet.isLoading && (
              <CircularProgress sx={{ mr: 1 }} color="error" size={20} />
            )}
            {t('dialog.ok')}
          </Button>
        </>
      }
    >
      {t('delete-wallet.description')}
    </CustomModal>
  );
};
