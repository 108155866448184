import {
  Box,
  IconButton,
  Button,
  InputAdornment,
  Snackbar,
  Alert,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../Icons';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { WALLETS_REFERAL } from '../../constants/wallets';

export const InsertWalletDataStep = ({ inputs, onSubmit, name, disabled }) => {
  const { t } = useTranslation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const copyIp = () => {
    const ips = process.env.REACT_APP_TRUSTED_IDS?.replaceAll(
      '&',
      name === 'binance' || name === 'testbinance' ? ' ' : ','
    );
    navigator.clipboard?.writeText(ips).then(() => setSnackbarOpen(true));
  };

  return (
    <>
      <Box component="form" onSubmit={onSubmit}>
        {inputs.map(({ name, label }) => (
          <TextField
            key={name}
            margin="normal"
            required
            fullWidth
            label={label}
            name={name}
          />
        ))}
        <p className="mt-16 a-center">
          {(name === 'binance' || name === 'testbinance') && (
            <NavLink target="_blank" to={t('link.wallets.how-add-binance')}>
              {t('bots.add-bot.how-connect')}
            </NavLink>
          )}
          {(name === 'bybit' || name === 'testbybit') && (
            <NavLink target="_blank" to={t('link.wallets.how-add-bybit')}>
              {t('bots.add-bot.how-connect')}
            </NavLink>
          )}
        </p>
        <p className="mt-16 description">{t('bots.add-bot.ip-warning')}</p>
        <TextField
          fullWidth
          disabled
          sx={{ mt: 2 }}
          value={process.env.REACT_APP_TRUSTED_IDS?.replaceAll(
            '&',
            name === 'binance' || name === 'testbinance' ? ' ' : ','
          )}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={copyIp}>
                  <Icon icon="copy" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <p className="mt-16 description">{t('bots.add-bot.buy-warning')}</p>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ my: 2 }}
          disabled={disabled}
        >
          {t('add-wallet.import')}
        </Button>
      </Box>
      <p className="mt-16 a-center description">
        <span>{t('bots.add-bot.not-account')}</span>{' '}
        <NavLink target="_blank" to={WALLETS_REFERAL[name]}>
          {t('bots.add-bot.create-add')}
        </NavLink>
      </p>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success">{t('bots.add-bot.ip-copied')}</Alert>
      </Snackbar>
    </>
  );
};
