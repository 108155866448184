import { Tooltip } from '@mui/material';
import React, { memo } from 'react';

export const NativeCurrencyFormatWrapper = memo(({ value, isUsd, maximumFractionDigits }) => {
  const options =
    value >= 1
      ? {
          notation: 'compact',
          maximumFractionDigits: 2,
        }
      : {
          notation: 'standard',
          maximumFractionDigits: maximumFractionDigits ?? (isUsd ? 2 : 8),
        };
  return (
    <Tooltip arrow title={<React.Fragment>{+(+value || 0).toFixed(value > 1000 ? 2 : 9) || 0}</React.Fragment>} followCursor>
      <span>{isNaN(value) ? 0 : Intl.NumberFormat('en-US', options).format(value)}</span>
    </Tooltip>
  );

  // <span title={+(+value || 0).toFixed(9) || 0}>{isNaN(value) ? 0 : Intl.NumberFormat('en-US', options).format(value)}</span>;
});
