import { useEffect, useMemo } from 'react';
import useWebSocket from 'react-use-websocket';

export const BinanceCurrentData = ({ symbols, binanceUpdate, active }) => {
  const socketUrl = useMemo(() => {
    const streams = symbols?.map((symbol) => `${symbol.toLowerCase()}@kline_2h`).join('/') || '';
    return streams ? `wss://stream.binance.com:9443/stream?streams=${streams}` : null;
  }, [symbols]);

  const { lastJsonMessage } = useWebSocket(
    socketUrl,
    {
      shouldReconnect: () => true,
    },
    active
  );

  useEffect(() => {
    if (lastJsonMessage) {
      binanceUpdate(lastJsonMessage);
    }
  }, [lastJsonMessage, binanceUpdate]);

  return <></>;
};
