import { useTranslation } from 'react-i18next';
import { NativeCurrencyFormatWrapper } from '../utility/NativeCurrencyFormatWrapper';
import { useMemo, useState } from 'react';
import { Alert, CircularProgress, Snackbar } from '@mui/material';

export const BotRebalancerSummaryWidget = ({ bots, prices, test }) => {
  const { t } = useTranslation();
  const [isLoadingPrice, setIsLoadingPrice] = useState(false);
  const [isLoadingAlert, setIsLoadingAlert] = useState(true);
  const fund = useMemo(
    () =>
      bots.reduce(
        (acc, b) =>
          acc + b?.data?.data?.reduce((ac, s) => ac + s.amount * s.price, 0),
        0
      ),
    [bots]
  );

  const profit = useMemo(
    () =>
      bots.reduce((acc, b) => {
        if (b.status !== 'ready') {
          return acc + (b?.profit || 0);
        }
        const previous =
          b?.data?.data?.reduce(
            (acc, s) => s.amount * (s.price || 0) + acc,
            0
          ) || 0;
        setIsLoadingPrice(false);
        const current =
          b?.data?.data?.reduce(
            (acc, s) =>
              s.amount *
                (prices[s.symbol.toLowerCase()] || setIsLoadingPrice(true)) +
              acc,
            0
          ) || 0;
        return acc + current + (b?.profit || 0) - previous;
      }, 0),
    [bots, prices]
  );

  return (
    <div className="bots-trade-widget__content">
      <Snackbar
        open={isLoadingPrice ? isLoadingAlert : false}
        autoHideDuration={3000}
        onClose={() => setIsLoadingAlert(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          sx={{ boxShadow: '0 0 15px rgba(0,0,0,0.37)' }}
          icon={<CircularProgress color="primary" size={20} />}
          severity="info"
        >
          {t('bots.loading-price')}
        </Alert>
      </Snackbar>
      <div className="bots-trade-widget__title">
        <div className="flex">
          <div>
            <div>
              {t(`bots.summary-widget.${!test ? 'summary' : 'summary-test'}`)}
            </div>
          </div>
        </div>
      </div>

      <div className="bots-trade-widget__label">
        <div className="-text">
          {t('bots.summary-widget.balance-bots')}
          <div className="f-small c-base-600">
            {t('bots.summary-widget.assets-sum')}
          </div>
        </div>
        <div className={`-value ${profit > 0 ? 'c-green' : 'c-red'}`}>
          {isLoadingPrice ? (
            <CircularProgress sx={{ mt: 1 }} color="primary" size={20} />
          ) : (
            <>
              <div className="-price">
                $
                <NativeCurrencyFormatWrapper
                  value={fund + profit}
                  isUsd={true}
                />
              </div>
              <div className={`f-small`}>
                $
                <NativeCurrencyFormatWrapper value={profit} isUsd={true} /> (
                {((profit / fund) * 100).toFixed(3)}%)
              </div>
            </>
          )}
        </div>
      </div>

      <div className="bots-trade-widget__label">
        <div className="-text">{t('bots.summary-widget.bots-count')}</div>
        <div className={`-value`}>
          {bots?.length}
          {t('bots.widget.pieces')}
        </div>
      </div>

      <div className="bots-trade-widget__label">
        <div className="-text">{t('bots.summary-widget.start-balance')}</div>
        <div className={`-value`}>
          $<NativeCurrencyFormatWrapper value={fund} isUsd={true} />
        </div>
      </div>
    </div>
  );
};
