import { useParams } from 'react-router-dom';
import { Typography, IconButton, MenuItem, Menu } from '@mui/material';
// import { BalanceWidget } from '../components/BalanceWidget';
// import { OperationsWidget } from '../components/OperationsWidget';
import { useAvailableWallets } from '../hooks/http-requests/useAvailableWallets';
import { useUserWallets } from '../hooks/http-requests/useUserWallets';
import { useMemo, useState } from 'react';
import * as React from 'react';
import { MoreVert } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DeleteWalletModal } from '../components/wallet/DeleteWalletModal';
import './WalletInfo.scss';
// import { BalanceAviableWidget } from '../components/BalanceAviableWidget';
import { ProfitWidget } from '../components/ProfitWidget';
import { BalanceMultiWidget } from '../components/BalanceMultiWidget';

export const WalletInfo = () => {
  let { id } = useParams();
  const { availableWallets } = useAvailableWallets();
  const { userWallets } = useUserWallets();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  const wallet = useMemo(() => {
    const userWallet = userWallets.data?.find((uw) => uw.id === +id);
    const wallet = availableWallets.data?.find((aw) => aw.id === userWallet?.exchange_id);
    return {
      ...wallet,
      userId: +id,
    };
  }, [availableWallets.data, id, userWallets.data]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onModalClose = (isSuccess) => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="wallet-page__top">
        <img className="logo" src={wallet?.icon} alt="" />
        <h3>{wallet?.name}</h3>
        <div className="wallet-page__buttons">
          <IconButton onClick={handleOpenMenu} sx={{ p: 0, ml: 'auto' }}>
            <MoreVert sx={{ width: 32, height: 32 }} />
          </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClick={handleCloseMenu}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={() => setModalOpen(true)}>
              <Typography textAlign="center">{t('bots.info.delete')}</Typography>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="content wallet-page__content">
        {wallet?.id && (
          <>
            <BalanceMultiWidget wallet={wallet} current="true" />

            <div className="grid-fill">
              <ProfitWidget exchangeId={wallet?.id} portfolio={true} />
            </div>
            <div className="grid-fill">
              <ProfitWidget test={true} exchangeId={wallet?.id} title="title-demo" portfolio={true} />
            </div>
          </>
        )}
      </div>

      <DeleteWalletModal open={modalOpen} onClose={onModalClose} id={id} />
    </>
  );
};
