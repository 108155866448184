import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, InputAdornment, TableSortLabel, TextField, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { ApiService } from '../../utils/api-service';
import { NativeDateLocaleWrapper } from '../../components/utility/NativeDateLocaleWrapper';
import { NativeCurrencyFormatWrapper } from '../../components/utility/NativeCurrencyFormatWrapper';
import Icon from '../../components/Icons';
import './Admin.scss';
import { useIsMobile } from '../../components/utility/DetectDevice';

const limit = 1000;

const headerCells = [
  { name: 'email', label: 'admin.users.email' },
  { name: 'users.created_at', label: 'admin.users.regdate' },
  { name: 'lastenter', label: 'admin.users.lastenter' },
  { name: 'botcount', label: 'admin.users.botcount' },
  { name: 'cntsdbots', label: 'admin.users.demobotcount' },
  { name: 'profit', label: 'admin.users.profit' },
  { name: 'fund', label: 'admin.users.capital' },
  { name: 'tariffs.title', label: 'admin.users.tariff' },
  { name: 'tariff_end_at', label: 'profile.tariffEndAt' },
  { name: 'referrer', label: 'admin.users.referrer' },
];

export const AdminUsers = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useState({
    tariff_id: '',
    email: '',
    sortBy: 'email',
    sortDir: 'asc',
  });
  const { ref, inView } = useInView();
  const [search, setSearch] = useState('');

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['adminUsersInfoInfinite', query],
    queryFn: ({ pageParam = 1 }) =>
      ApiService.getUsersInfo({
        page: pageParam,
        limit,
        search,
        sortBy: query.sortBy,
        sortDir: query.sortDir,
      }),
    getNextPageParam: (lastPage, allPages) => (lastPage?.length === limit ? allPages.length + 1 : undefined),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
    enabled: true,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const totalCountUsers = useMemo(() => data?.pages[0]?.meta?.total, [data]);
  const userList = useMemo(() => data?.pages?.reduce((acc, current) => !!current?.message && acc.concat(current?.message), []) || [], [data?.pages]);

  const changeQuery = (query) => {
    setQuery((prevState) => ({ ...prevState, ...query }));
  };

  const makeSearch = (s = search) => {
    changeQuery({ symbol: s });
  };

  const gotoBots = (id, name, email) => {
    localStorage.setItem('showUserId', id);
    localStorage.setItem('showUserName', name || '');
    localStorage.setItem('showUserEmail', email);
    // navigate(`/admin/bots/trade?user_id=${id}`);
    window.open(`/admin/bots/trade?user_id=${id}`);
  };

  const isMobile = useIsMobile();

  const clearSerach = () => {
    setSearch('');
    makeSearch('');
  };

  return (
    <div className="admin-page">
      <div className="admin-page__header">
        <h3 className="admin-page__title">
          {t('nav.users')}: {totalCountUsers || 0}
        </h3>
        <div className="admin-page__filters">
          <div className="input__search">
            <TextField
              label={t('admin.users.email')}
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => e.keyCode === 13 && makeSearch()}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment style={{ opacity: search ? 1 : 0 }} position="end">
                      <Icon icon="close" className="medium pointer hover" onClick={() => clearSerach()} />
                    </InputAdornment>
                    <InputAdornment position="end">
                      <Icon icon="search" className="medium pointer hover" onClick={() => makeSearch()} />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </div>
        </div>
      </div>
      <div className="content admin-page__content">
        {!isMobile ? (
          <table className="table-shadow mobile-hidden">
            <thead>
              <tr>
                {headerCells.map((c) => (
                  <th key={c.name}>
                    <TableSortLabel
                      active={query.sortBy === c.name}
                      direction={query.sortBy === c.name ? query.sortDir : 'desc'}
                      onClick={() =>
                        changeQuery({
                          sortBy: c.name,
                          sortDir: query.sortBy === c.name && query.sortDir === 'desc' ? 'asc' : 'desc',
                        })
                      }
                    >
                      {t(c.label)}
                    </TableSortLabel>
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {userList?.map((s, i) => (
                <tr key={s.id} ref={i === userList?.length - 5 ? ref : undefined}>
                  <td title={s.email} style={{ maxWidth: 200 }}>
                    {s.email}
                    <br />
                    {s.name}
                  </td>
                  <td>
                    <NativeDateLocaleWrapper
                      date={s.created_at}
                      options={{
                        day: '2-digit',
                        month: 'numeric',
                        year: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                      }}
                    />
                  </td>
                  <td>
                    {s.lastenter ? (
                      <NativeDateLocaleWrapper
                        date={s.lastenter}
                        options={{
                          day: '2-digit',
                          month: 'numeric',
                          year: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                        }}
                      />
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    <Tooltip
                      arrow
                      title={
                        <React.Fragment>
                          <div>
                            {t('bots.tabs.trade')}: {s.cntsbots}
                          </div>
                          <div>
                            {t('bots.tabs.rebalancer')}: {s.cntmbots}
                          </div>
                        </React.Fragment>
                      }
                      followCursor
                    >
                      <div>{Number(s.botcount || 0)}</div>
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip
                      arrow
                      title={
                        <React.Fragment>
                          <div>
                            {t('bots.tabs.trade')}: {s.cntsdbots}
                          </div>
                          <div>
                            {t('bots.tabs.rebalancer')}: {s.cntmdbots}
                          </div>
                        </React.Fragment>
                      }
                      followCursor
                    >
                      <div>{Number(s.dbotcount || 0)}</div>
                    </Tooltip>
                  </td>
                  <td>
                    $<NativeCurrencyFormatWrapper value={Number(s.profit).toFixed(2)} isUsd={false} /> <br />
                    $<NativeCurrencyFormatWrapper value={Number(s.profitd).toFixed(2)} isUsd={false} />
                  </td>
                  <td>
                    $
                    <NativeCurrencyFormatWrapper value={Number(s.fund).toFixed(2)} isUsd={false} />
                  </td>
                  <td>{s.title}</td>
                  <td>
                    {s.tariff_end_at ? (
                      <NativeDateLocaleWrapper
                        date={s.tariff_end_at}
                        options={{
                          day: '2-digit',
                          month: 'numeric',
                          year: '2-digit',
                        }}
                      />
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>{s.referrer}</td>
                  <td>
                    <IconButton onClick={() => gotoBots(s.id, s.name, s.email)} sx={{ textTransform: 'capitalize' }}>
                      <Icon icon="bot" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <table className="pc-hidden tablet-hidden table-shadow">
            <thead>
              <tr>
                <th>{t('admin.users.email')}</th>
                <th>{t('admin.users.botcount')}</th>
                <th>{t('admin.users.profit')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {userList?.map((s, i) => (
                <tr key={s.id} ref={i === userList?.length - 5 ? ref : undefined}>
                  <td title={s.email} style={{ maxWidth: 160 }}>
                    {s.email}
                  </td>
                  <td>
                    {Number(s.cntsbots || 0) + Number(s.cntmbots || 0)} ({Number(s.cntsdbots || 0)})
                  </td>
                  <td>
                    $
                    <NativeCurrencyFormatWrapper value={Number(s.profit).toFixed(2)} isUsd={false} />
                  </td>
                  {/* <td>
                  $
                  <NativeCurrencyFormatWrapper
                    value={Number(s.fund).toFixed(2)}
                    isUsd={false}
                  />
                </td>
                <td>
                {s.title}
                </td> */}
                  <td>
                    <IconButton onClick={() => gotoBots(s.id)} sx={{ textTransform: 'capitalize' }}>
                      <Icon icon="bot" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
