import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useAvailableWallets = () => {
  const availableWallets = useQuery({
    queryKey: ['availableWallets'],
    queryFn: ApiService.getAvailableWallets,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
  });

  return { availableWallets };
};
