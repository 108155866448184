import { useMutation } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useFromTo = () => {
    const orderList = useMutation(ApiService.getFromTo)
  
    return { orderList };
  };
  
  export const useToFrom = () => {
    const orderList2 = useMutation(ApiService.getToFrom)
  
    return { orderList2 };
  };