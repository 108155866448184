import { Alert, Box, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Snackbar, Tab, Tabs, TextField } from '@mui/material';
import { useGetUser } from '../hooks/http-requests/useGetUser';
import * as React from 'react';
import { setNestedObjectValues, useFormik } from 'formik';
import { profileValidation } from '../constants/validation';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useUpdateUser } from '../hooks/http-requests/useUpdateUser';
import { useGetPayments } from '../hooks/http-requests/useGetPayments';
import { NativeDateLocaleWrapper } from '../components/utility/NativeDateLocaleWrapper';
import { WithdrawBonusesModal } from '../components/WithdrawBonusesModal';
import { TelegramModal } from '../components/TelegramModal';
import { useTelegramUnsubscribe } from '../hooks/http-requests/useTelegramUnsubscribe';
import { useToggleShowUnimportant } from '../hooks/http-requests/useToggleShowUnimportant';
import './Profile.scss';
import { ChangePasswordModal } from '../components/ChangePasswordModal';
import Icon from '../components/Icons';
import { Link, useNavigate } from 'react-router-dom';
import { useGetTariffs } from '../hooks/http-requests/useGetTariffs';
import { useGetBonusOperations } from '../hooks/http-requests/useGetBonusOperations';
import { RefferalTable } from '../components/RefferalTable';
import { useIsMobile } from '../components/utility/DetectDevice';

export const ProfilePage = () => {
  const { user } = useGetUser();
  const { updateUser } = useUpdateUser();
  const { payments } = useGetPayments();
  const { bonusOperations } = useGetBonusOperations();
  const { t, i18n } = useTranslation();
  const [nameChange, setNameChange] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [withdrawBonusesModalOpen, setWithdrawBonusesModalOpen] = useState(false);
  const [telegramModalOpen, setTelegramModalOpen] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [tab, setTab] = useState('payments');
  const { telegramUnsubscribe } = useTelegramUnsubscribe();
  const { toggleShowUnimportant } = useToggleShowUnimportant();
  const { tariffs } = useGetTariffs();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      referralCode: '',
      bonusBalance: '',
    },
    onSubmit: () => {},
    validationSchema: profileValidation,
  });

  const userTariff = useMemo(() => {
    if (new Date(user?.data?.tariff_end_at) < new Date()) {
      return tariffs?.data?.tariffs?.find((t) => t.price === 0);
    }
    return tariffs?.data?.tariffs?.find((t) => t.id === user?.data?.tariff_id);
  }, [tariffs.data, user.data]);

  const tariffEndAt = useMemo(
    () =>
      user.data?.tariff_end_at
        ? new Intl.DateTimeFormat(i18n.language === 'ru' ? 'ru' : 'en-US', {
            dateStyle: 'medium',
          }).format(new Date(user.data?.tariff_end_at))
        : '',
    [i18n.language, user.data?.tariff_end_at]
  );

  useEffect(() => {
    formik.setFieldValue('name', user.data?.name || '');
    formik.setFieldValue('email', user.data?.email || '');
    formik.setFieldValue('referralCode', user.data?.referral_code || '');
    formik.setFieldValue('bonusBalance', user.data?.bonus_balance || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.data]);

  const getTariffName = (tariff_id) => tariffs.data?.tariffs?.find((t) => tariff_id === t.id)?.title;

  const onUpdateUser = () => {
    setNameChange(false);
    formik.validateForm().then((values) => {
      if (Object.keys(values).length > 0) {
        formik.setTouched(setNestedObjectValues(values, true));
        return;
      }
      updateUser.mutateAsync({ name: formik.values.name }).then();
    });
  };

  const copyReferral = () => {
    const url = `${process.env.REACT_APP_FRONTEND_URL}/register?ref=${formik.values.referralCode}`;
    navigator.clipboard?.writeText(url).then(() => setSnackbarOpen(true));
  };

  const withdrawBonuses = () => {
    setWithdrawBonusesModalOpen(true);
  };

  const showChangePassword = () => {
    setChangePasswordModalOpen(true);
  };

  const unsubscribeFromTelegram = () => {
    telegramUnsubscribe.mutateAsync(undefined);
  };

  const handleToggleShowUnimportant = (event) => {
    toggleShowUnimportant.mutateAsync(event.target.checked);
  };

  const redirectToPayment = () => {
    navigate('/dashboard/payments');
  };

  const isMobile = useIsMobile();

  if (!user) return null;

  return (
    <>
      <WithdrawBonusesModal open={withdrawBonusesModalOpen} onClose={() => setWithdrawBonusesModalOpen(false)} balance={formik.values.bonusBalance} />
      <ChangePasswordModal open={changePasswordModalOpen} onClose={() => setChangePasswordModalOpen(false)} />
      <TelegramModal open={telegramModalOpen} onClose={() => setTelegramModalOpen(false)} id={user.data?.telegram_code} />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="success">{t('profile.copy-referral-link-success')}</Alert>
      </Snackbar>
      <div className="profile">
        <h3>{t('profile.title')}</h3>
        <div className="card">
          <div className="card__body profile-data">
            <TextField
              name="name"
              label={t('header.profile.name')}
              fullWidth
              value={formik.values.name}
              onChange={(e) => {
                formik.setFieldValue('name', e.target.value);
                setNameChange(true);
              }}
              error={formik.touched.name ? !!formik.errors.name : false}
              helperText={formik.touched.name ? formik.errors.name : undefined}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="text" color={nameChange ? 'primary' : 'secondary'} onClick={onUpdateUser}>
                      {t('button.save')}
                    </Button>
                  </InputAdornment>
                ),
              }}
              onBlur={formik.handleBlur}
            />
            <div className="profile-email">
              <TextField
                disabled
                name="email"
                label="Email"
                sx={{ width: '100%', flexGrow: 1 }}
                value={formik.values.email}
                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                error={formik.touched.email ? !!formik.errors.email : false}
                helperText={formik.touched.email ? formik.errors.email : undefined}
                onBlur={formik.handleBlur}
              />
              <Button variant="outlined" onClick={showChangePassword} size="large">
                {t('profile.change-password')}
              </Button>
            </div>
            <TextField
              disabled
              label={t('profile.tariff')}
              sx={{ width: '100%' }}
              value={userTariff?.title || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="text" color="secondary" onClick={redirectToPayment}>
                      {t('profile.all-tariffs')}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            <TextField disabled label={t('profile.tariffEndAt')} sx={{ width: '100%' }} value={userTariff?.price === 0 ? '-' : tariffEndAt} />
          </div>
        </div>
      </div>

      {/* referral */}
      <div className="profile mt-24">
        <h3>{t('profile.tabs.refferals-program')}</h3>
        <div className="card">
          <div className="card__body profile-data">
            <TextField
              disabled
              label={t('profile.bonus-balance')}
              sx={{ width: '100%' }}
              value={formik.values.bonusBalance}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="text" color="secondary" onClick={withdrawBonuses} disabled={user.data?.bonus_balance < 15}>
                      {t('profile.withdraw-bonuses')}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              label={t('profile.referral')}
              sx={{ width: '100%' }}
              value={formik.values.referralCode}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={copyReferral} disabled={!formik.values?.referralCode}>
                      <Icon icon="copy" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div>
              <Link target="_blank" to={t('link.referral-program')}>
                {t('profile.tabs.refferals-program-rules')}
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* telegram */}
      <div className="profile mt-24">
        <h3>{t('profile.telegram.title')}</h3>
        <div className="card">
          <div className="card__body profile-data">
            <div className="grid-fill">
              {!user.data?.telegram_id && (
                <div className="telegram">
                  <Button variant="outlined" size="large" className="telegram-subscribe" sx={{ flexShrink: 0 }} onClick={() => setTelegramModalOpen(true)}>
                    {t('profile.telegram.subscribe.button')}
                  </Button>
                  <div className="helper">
                    {t('profile.telegram.subscribe.text1')}
                    <br /> {t('profile.telegram.subscribe.text2')}
                  </div>
                </div>
              )}
              {!!user.data?.telegram_id && (
                <div className="telegram-unsubscribe">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={user.data?.send_unimportant_notifications}
                        onChange={handleToggleShowUnimportant}
                        disabled={toggleShowUnimportant.isLoading || user.isLoading || user.isFetching}
                      />
                    }
                    label={t('profile.telegram.send-important')}
                  />
                  <Button variant="text" color="secondary" onClick={unsubscribeFromTelegram}>
                    {t('profile.telegram.unsubscribe.button')}
                  </Button>
                </div>
              )}
              <div className="telegram-chanel">
                <Icon className="-icon" icon={'telegram'} />
                <span>
                  {t('profile.telegram.subscribe.group')}{' '}
                  <a href="https://t.me/Crypto_bot_2023" target="_blank" rel="noreferrer">
                    @Crypto_bot_2023
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Box sx={{ borderBottom: 2, borderColor: 'divider', mt: '24px', mb: '16px' }}>
        <Tabs value={tab} onChange={(_, value) => setTab(value)} sx={{ mb: '-2px' }} variant="scrollable" scrollButtons="auto">
          <Tab sx={{ textTransform: 'inherit', fontSize: 16 }} label={t('profile.tabs.payments')} value="payments" />
          <Tab sx={{ textTransform: 'inherit', fontSize: 16 }} label={t('profile.tabs.bonuses')} value="bonuses" />
          <Tab sx={{ textTransform: 'inherit', fontSize: 16 }} label={t('profile.tabs.refferals')} value="refferals" />
        </Tabs>
      </Box>
      {tab === 'payments' && (
        <>
          {!isMobile ? (
            <table className="table-shadow">
              <thead>
                <tr>
                  <th>{t('payments-list.title')}</th>
                  <th>{t('payments-list.invoice')}</th>
                  <th>{t('payments-list.tariff')}</th>
                  <th>{t('payments-list.created-at')}</th>
                  <th>{t('payments-list.paid-at')}</th>
                  <th className="a-center">{t('bots.list.status')}</th>
                </tr>
              </thead>
              <tbody>
                {payments?.data?.map((p) => (
                  <tr key={p.id}>
                    <td>
                      {p.amount}
                      {p.currency}
                    </td>
                    <td>{p.invoice_id}</td>
                    <td>{getTariffName(p.tariff_id)}</td>
                    <td>
                      <NativeDateLocaleWrapper
                        date={p.created_at}
                        options={{
                          day: '2-digit',
                          month: 'short',
                          hour: '2-digit',
                          minute: '2-digit',
                        }}
                      />
                    </td>
                    <td>
                      {p.paid_at ? (
                        <NativeDateLocaleWrapper
                          date={p.paid_at}
                          options={{
                            day: '2-digit',
                            month: 'short',
                            hour: '2-digit',
                            minute: '2-digit',
                          }}
                        />
                      ) : (
                        '-'
                      )}
                    </td>
                    <td className="a-center">
                      {p.status === 'paid' ? <Icon icon="check" className="c-green" /> : <Icon icon="alert" className="c-base-600" />}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <table className="table-shadow">
              <tbody>
                {payments?.data?.map((p) => (
                  <tr key={p.id}>
                    <td>
                      <div className="info-line">
                        <b>
                          {p.amount}
                          {p.currency}
                        </b>
                        <b>{p.status === 'paid' ? <Icon icon="check" className="c-green" /> : <Icon icon="alert" className="c-base-600" />}</b>
                      </div>
                      <hr />
                      <div className="info-line">
                        <p>{t('payments-list.invoice')}</p>
                        <p>{p.invoice_id}</p>
                      </div>
                      <div className="info-line">
                        <p>{t('payments-list.tariff')}</p>
                        <p>{getTariffName(p.tariff_id)}</p>
                      </div>
                      <div className="info-line">
                        <p>{t('payments-list.created-at')}</p>
                        <p>
                          <NativeDateLocaleWrapper
                            date={p.created_at}
                            options={{
                              day: '2-digit',
                              month: 'short',
                              hour: '2-digit',
                              minute: '2-digit',
                            }}
                          />
                        </p>
                      </div>
                      <div className="info-line">
                        <p>{t('payments-list.paid-at')}</p>
                        <p>
                          {p.paid_at ? (
                            <NativeDateLocaleWrapper
                              date={p.paid_at}
                              options={{
                                day: '2-digit',
                                month: 'short',
                                hour: '2-digit',
                                minute: '2-digit',
                              }}
                            />
                          ) : (
                            '-'
                          )}
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
      {tab === 'bonuses' && (
        <>
          {!isMobile ? (
            <table className="table-shadow">
              <thead>
                <tr>
                  <th>{t('bonus-operations-list.operation')}</th>
                  <th>{t('bonus-operations-list.date')}</th>
                  <th className="a-right">{t('bonus-operations-list.amount')}</th>
                </tr>
              </thead>
              <tbody>
                {bonusOperations?.data?.map((o) => (
                  <tr key={o.id}>
                    <td>{t(`bonus-operations.type.${o.type}`)}</td>
                    <td>
                      <NativeDateLocaleWrapper
                        date={o.created_at}
                        options={{
                          day: '2-digit',
                          month: 'short',
                          hour: '2-digit',
                          minute: '2-digit',
                        }}
                      />
                    </td>
                    <td className={`bold a-right ${o.type === 'income' ? 'c-green' : 'c-base-600'}`}>
                      {o.type === 'income' ? '+' : '-'} {o.amount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <table className="table-shadow">
              <thead>
                <tr>
                  <th>{t('bonus-operations-list.operation')}</th>
                  <th className="a-right">{t('bonus-operations-list.amount')}</th>
                </tr>
              </thead>
              <tbody>
                {bonusOperations?.data?.map((o) => (
                  <tr key={o.id}>
                    <td>
                      <h5>{t(`bonus-operations.type.${o.type}`)}</h5>
                      <h5 className="f-small">
                        <NativeDateLocaleWrapper
                          date={o.created_at}
                          options={{
                            day: '2-digit',
                            month: 'short',
                            hour: '2-digit',
                            minute: '2-digit',
                          }}
                        />
                      </h5>
                    </td>
                    <td className={`bold a-right ${o.type === 'income' ? 'c-green' : 'c-base-600'}`}>
                      {o.type === 'income' ? '+' : '-'} {o.amount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
      {tab === 'refferals' && <RefferalTable />}
    </>
  );
};
