import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useInView } from 'react-intersection-observer';
import { useGetUserWithdrawRequests } from '../../hooks/http-requests/useGetUserWithdrawRequests';
import { useTranslation } from 'react-i18next';
import { useFulfillWithdrawRequest } from '../../hooks/http-requests/useFulfillWithdrawRequest';
import Scrollbars from 'react-custom-scrollbars-2';
import { NativeDateLocaleWrapper } from '../utility/NativeDateLocaleWrapper';
import * as React from 'react';
import "./WithdrawRequestsModal.scss";

export const WithdrawRequestsModal = ({ open, onClose, userId }) => {
  const { t } = useTranslation();
  const { withdrawRequests } = useGetUserWithdrawRequests(userId);
  const { fulfillWithdrawRequest } = useFulfillWithdrawRequest();
  const { ref } = useInView();

  const fulfillRequest = (id) => {
    fulfillWithdrawRequest
      .mutateAsync(id)
      .then(() => withdrawRequests.refetch());
  };

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="md">
      {open && (
        <>
          <DialogTitle>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6">
                {t('admin.referral.show-withdraw-requests.title')}
              </Typography>
              <IconButton aria-label="close dialog" onClick={() => onClose()}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Scrollbars autoHide autoHeightMax={'70vh'} autoHeight={true}>
              <div className="content admin-page__content">
                <table className="table-shadow mobile-hidden">
                  <thead>
                    <tr>
                      <th>{t('bots.operations.amount')}</th>
                      <th>{t('operations.filters.wallet')}</th>
                      <th>{t('admin.users.actions')}</th>
                      <th>{t('payments-list.created-at')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {withdrawRequests.data?.map((r, i) => (
                      <tr
                        key={r.id}
                        ref={
                          i === withdrawRequests?.length - 5 ? ref : undefined
                        }
                      >
                        <td>{r.amount}</td>
                        <td>{r.wallet}</td>
                        <td>
                          {r.status === 'created' ? (
                            <Button
                              onClick={() => fulfillRequest(r.id)}
                              size="small"
                            >
                              {t('admin.referral.fulfill-withdraw-request')}
                            </Button>
                          ) : (
                            t('admin.referral.fulfilled')
                          )}
                        </td>
                        <td>
                          <NativeDateLocaleWrapper
                            date={r.created_at}
                            options={{
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table className="pc-hidden tablet-hidden table-shadow">
                  <thead>
                    <tr>
                      <th>{t('admin.users.actions')}</th>
                      <th>{t('bots.operations.amount')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {withdrawRequests.data?.map((r, i) => (
                      <tr
                        key={r.id}
                        ref={
                          i === withdrawRequests?.length - 5 ? ref : undefined
                        }
                      >
                        <td>
                          {r.status === 'created' ? (
                            <Button
                              onClick={() => fulfillRequest(r.id)}
                              size="small"
                            >
                              {t('admin.referral.fulfill-withdraw-request')}
                            </Button>
                          ) : (
                            t('admin.referral.fulfilled')
                          )}
                        </td>
                        <td>{r.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Scrollbars>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
