import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAvailableWallets } from '../hooks/http-requests/useAvailableWallets';
import { useUserWallets } from '../hooks/http-requests/useUserWallets';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ApiService } from '../utils/api-service';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TableSortLabel, TextField } from '@mui/material';
import * as React from 'react';
import { NativeCurrencyFormatWrapper } from '../components/utility/NativeCurrencyFormatWrapper';
// import { useNavigate } from 'react-router-dom';
import { AddBotModal } from '../components/bots/AddBotModal';
import { AnalyticsWidgetModal } from '../components/AnalyticsWidgetModal';
import { useDebouncedCallback } from 'use-debounce';
import './Analytics.scss';
import Icon from '../components/Icons';
import MenuPopup from '../components/utility/MenuPopup';
import { AddBotFormModal } from '../components/bots/AddBotFormModal';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Preloader from '../components/Preloader';
import { NotDataCard } from '../components/utility/NotDataCard';
import { useIsMobile } from '../components/utility/DetectDevice';
import { COINS } from '../constants/coins';
import { NoWalletsBlock } from '../components/NoWalletsBlock';
import { useGetUser } from '../hooks/http-requests/useGetUser';

const limit = 50;

const headerCells = [
  { name: 'symbol', label: 'analytics.header.symbol' },
  { name: 'low_price', label: 'analytics.header.low-price', align: 'right' },
  {
    name: 'price_change_percent',
    label: 'analytics.header.price-change-percent',
    align: 'right',
  },
  {
    name: 'quote_volume',
    label: 'analytics.header.quote-volume',
    align: 'right',
  },
];

export const Analytics = () => {
  const [query, setQuery] = useState({
    exchange: '',
    symbol: '',
    sortBy: 'quote_volume',
    sortDir: 'desc',
  });
  const [search, setSearch] = useState('');
  const [volumeFrom, setVolumeFrom] = useState();
  const [volumeTo, setVolumeTo] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAddBotOpen, setModalAddBotOpen] = useState('');
  const [widgetModalOpen, setWidgetModalOpen] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState();
  const { t } = useTranslation();
  const { availableWallets } = useAvailableWallets();
  const { userWallets } = useUserWallets();
  const { ref, inView } = useInView();
  const { user } = useGetUser();
  // const navigate = useNavigate();

  const freeTariff = useMemo(() => {
    if (user.data) {
      if (user.data.tariff_id === 1) {
        return true;
      }
      return !!(user.data.tariff_end_at && new Date(user?.data?.tariff_end_at) < new Date());
    } else {
      return false;
    }
  }, [user.data]);

  const walletOptions = useMemo(() => {
    if (freeTariff) {
      return [
        {
          id: +process.env.REACT_APP_BINANCE_USER_ID,
          name: 'Demo Binance',
          status: 'active',
          tag: 'binance',
          type: 'BINANCE',
          exchange_id: +process.env.REACT_APP_BINANCE_ID,
        },
        {
          id: +process.env.REACT_APP_BYBIT_USER_ID,
          name: 'Demo Bybit',
          status: 'active',
          tag: 'bybit',
          type: 'bybit',
          exchange_id: +process.env.REACT_APP_BYBIT_ID,
        },
      ];
    }
    return userWallets.data?.map((w) => {
      const wallet = availableWallets.data?.find((aw) => aw.id === w.exchange_id);
      return {
        ...w,
        name: wallet?.name,
        type: wallet?.type,
      };
    });
  }, [availableWallets.data, freeTariff, userWallets.data]);

  const countFilter = useMemo(() => !!volumeTo || !!volumeFrom, [volumeTo, volumeFrom]);

  useEffect(() => {
    if (walletOptions?.length && !query.exchange) {
      changeQuery({ exchange: walletOptions[0].id });
    }
  }, [walletOptions, query.exchange]);
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: ['exchangeSymbolsInfoInfinite', query],
    queryFn: ({ pageParam = 1 }) =>
      ApiService.getExchangeSymbolsInfo({
        page: pageParam,
        limit,
        exchange: query.exchange,
        symbol: query.symbol,
        sortBy: query.sortBy,
        sortDir: query.sortDir,
        volumeFrom: query.volumeFrom,
        volumeTo: query.volumeTo,
      }),
    getNextPageParam: (lastPage, allPages) => (lastPage?.length === limit ? allPages.length + 1 : undefined),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
    enabled: !!query.exchange,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const selectedWallet = useMemo(() => walletOptions?.find((o) => o.id === query.exchange), [query.exchange, walletOptions]);

  const symbolsList = useMemo(() => data?.pages?.reduce((acc, current) => !!current && acc.concat(current), []) || [], [data?.pages]);

  const trendingViewSymbol = useMemo(() => {
    if (!selectedSymbol || !selectedWallet) return null;
    return `${selectedWallet.type.toUpperCase()}:${selectedSymbol}`;
  }, [selectedSymbol, selectedWallet]);

  const changeQuery = (query) => {
    setQuery((prevState) => ({ ...prevState, ...query }));
  };

  const createBot = (symbol) => {
    setSelectedSymbol(symbol);
    setModalOpen(true);
  };

  const showWidget = (symbol) => {
    setSelectedSymbol(symbol);
    setWidgetModalOpen(true);
  };

  const handleModalClose = (type) => {
    setModalOpen(false);
    setModalAddBotOpen(type);
  };

  const makeSearch = (s = search) => {
    changeQuery({ symbol: s });
  };

  const makeVolumeFrom = useDebouncedCallback(() => {
    changeQuery({ volumeFrom });
  }, 500);

  const makeVolumeTo = useDebouncedCallback(() => {
    changeQuery({ volumeTo });
  }, 500);

  const onVolumeFromChange = (value) => {
    setVolumeFrom(value);
    makeVolumeFrom();
  };

  const onVolumeToChange = (value) => {
    setVolumeTo(value);
    makeVolumeTo();
  };
  const nodeRef = React.useRef(null);

  const isMobile = useIsMobile();

  const clearSerach = () => {
    setSearch('');
    makeSearch('');
  };

  const openTab = (link) => {
    window.open(link);
  };

  const informButton = (symbol, url, icon) => {
    const name = COINS.find((c) => c.symbol === symbol.toLowerCase())?.id.toLowerCase();

    if (!name) {
      return '';
    }

    const link = `https://${url}/${name.toLowerCase()}`;
    return (
      <>
        {!isMobile ? (
          <IconButton title={`${t('analytics.button.info')} ${icon}`} onClick={() => openTab(link)}>
            <Icon icon={icon.toLowerCase()} />
          </IconButton>
        ) : (
          <Button variant="outlined" onClick={() => openTab(link)} endIcon={<Icon icon={icon.toLowerCase()} />}>
            <span>{`${t('analytics.button.info')} ${icon}`}</span>
          </Button>
        )}
      </>
    );
  };

  const coinGecoButton = (symbol) => {
    return informButton(symbol, t('link.coingeko'), 'CoinGecko');
  };

  // const coinMarketCapButton = (symbol) => {
  //   return informButton(symbol, t('link.coinmarketcap'), 'CoinMarketCap');
  // };

  return (
    <div className="analytics-page">
      <AddBotFormModal
        open={modalAddBotOpen !== ''}
        type={modalAddBotOpen}
        onClose={() => setModalAddBotOpen('')}
        symbol={selectedSymbol}
        exchange={query.exchange}
      />
      <NoWalletsBlock />
      <div className="analytics-page__header">
        <h3 className="analytics-page__title">
          <FormControl fullWidth size="small" sx={{ minWidth: 100 }}>
            <InputLabel id="wallet-select-label">{t('operations.filters.wallet')}</InputLabel>
            <Select
              disabled={!walletOptions?.length}
              labelId="wallet-select-label"
              label={t('operations.filters.wallet')}
              value={query.exchange}
              onChange={(e) => changeQuery({ exchange: e.target.value })}
            >
              {walletOptions?.map((w) => (
                <MenuItem key={w.id} value={w.id}>
                  {w.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </h3>
        <div className="analytics-page__filters">
          <div className="input__search">
            <TextField
              disabled={!walletOptions?.length}
              label={t('add-wallet.search')}
              size="small"
              value={search || ''}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => e.keyCode === 13 && makeSearch()}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment style={{ opacity: search ? 1 : 0 }} position="end">
                      <Icon icon="close" className="medium pointer hover" onClick={() => clearSerach()} />
                    </InputAdornment>
                    <InputAdornment position="end">
                      <Icon icon="search" className="medium pointer hover" onClick={() => makeSearch()} />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </div>
          {!!walletOptions?.length && (
            <MenuPopup icon="filter" count={countFilter}>
              <TextField
                type="number"
                label={t('analytics.filter.volume-from')}
                sx={{ width: '100%' }}
                value={volumeFrom || ''}
                onChange={(e) => onVolumeFromChange(e.target.value)}
              />
              <TextField
                type="number"
                label={t('analytics.filter.volume-to')}
                sx={{ width: '100%' }}
                value={volumeTo || ''}
                onChange={(e) => onVolumeToChange(e.target.value)}
              />
            </MenuPopup>
          )}
        </div>
      </div>
      <CSSTransition in={symbolsList?.length > 0} timeout={500} classNames="fade" unmountOnExit nodeRef={nodeRef}>
        <div className="content analytics-page__content">
          {!isMobile ? (
            <table className="table-shadow">
              <thead>
                <tr>
                  {headerCells.map((c) => (
                    <th
                      style={{ textAlign: c.align }}
                      key={c.name}
                      onClick={() =>
                        changeQuery({
                          sortBy: c.name,
                          sortDir: query.sortBy === c.name && query.sortDir === 'desc' ? 'asc' : 'desc',
                        })
                      }
                    >
                      <TableSortLabel
                        sx={{ flexDirection: c.align ? 'row-reverse' : '' }}
                        active={query.sortBy === c.name}
                        direction={query.sortBy === c.name ? query.sortDir : 'desc'}
                        onClick={() =>
                          changeQuery({
                            sortBy: c.name,
                            sortDir: query.sortBy === c.name && query.sortDir === 'asc' ? 'desc' : 'asc',
                          })
                        }
                      >
                        {t(c.label)}
                      </TableSortLabel>
                    </th>
                  ))}
                  <th />
                  <th />
                </tr>
              </thead>
              <TransitionGroup component="tbody">
                {symbolsList?.map((s, i) => (
                  <CSSTransition key={s.id} timeout={700} classNames="item" nodeRef={nodeRef}>
                    <tr ref={i === symbolsList?.length - 5 ? ref : undefined} className={s.symbol === selectedSymbol ? '-hover' : ''}>
                      <td>
                        <div className="name">
                          <img src={process.env.REACT_APP_ICONS_SERVER + s.symbol?.toLowerCase()?.split('usdt')?.[0]} alt="" />
                          <span className="pointer" onClick={() => showWidget(s.symbol)}>
                            {s?.symbol?.replace('USDT', ' / USDT')}
                          </span>
                        </div>
                      </td>
                      <td className="a-right">
                        $
                        <NativeCurrencyFormatWrapper value={s.low_price} isUsd={false} />
                      </td>
                      <td className="a-right">
                        <span className={`arrow-percent ${s.price_change_percent < 0 ? 'c-red' : 'c-green'}`}>
                          <NativeCurrencyFormatWrapper value={Math.abs(s.price_change_percent)} isUsd={true} />%
                        </span>
                      </td>
                      <td className="a-right">
                        $
                        <NativeCurrencyFormatWrapper value={s.quote_volume} isUsd={true} />
                      </td>
                      <td>
                        <IconButton onClick={() => showWidget(s.symbol)}>
                          <Icon icon="info" />
                        </IconButton>
                        {coinGecoButton(s.base)}
                        {/* {coinMarketCapButton(s.base)} */}
                      </td>
                      <td align="right">
                        <Button disabled={s.status !== 'TRADING'} sx={{ textTransform: 'capitalize' }} onClick={() => createBot(s.symbol)}>
                          {t('bots.add-bot')}
                        </Button>
                      </td>
                    </tr>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </table>
          ) : (
            <table className="table-shadow">
              <thead>
                <tr>
                  <th>
                    <TableSortLabel
                      active={query.sortBy === 'symbol'}
                      direction={query.sortBy === 'symbol' ? query.sortDir : 'asc'}
                      onClick={() =>
                        changeQuery({
                          sortBy: 'symbol',
                          sortDir: query.sortBy === 'symbol' && query.sortDir === 'asc' ? 'desc' : 'asc',
                        })
                      }
                    >
                      {t('analytics.header.symbol')}
                    </TableSortLabel>
                  </th>
                  <th className="a-right">
                    <TableSortLabel
                      sx={{ flexDirection: 'row-reverse' }}
                      active={query.sortBy === 'price_change_percent'}
                      direction={query.sortBy === 'price_change_percent' ? query.sortDir : 'asc'}
                      onClick={() =>
                        changeQuery({
                          sortBy: 'price_change_percent',
                          sortDir: query.sortBy === 'price_change_percent' && query.sortDir === 'desc' ? 'asc' : 'desc',
                        })
                      }
                    >
                      {t('analytics.header.low-price')}
                    </TableSortLabel>
                  </th>
                  <th className="a-right">
                    <TableSortLabel
                      sx={{ flexDirection: 'row-reverse' }}
                      active={query.sortBy === 'quote_volume'}
                      direction={query.sortBy === 'quote_volume' ? query.sortDir : 'asc'}
                      onClick={() =>
                        changeQuery({
                          sortBy: 'quote_volume',
                          sortDir: query.sortBy === 'quote_volume' && query.sortDir === 'desc' ? 'asc' : 'desc',
                        })
                      }
                    >
                      {t('analytics.header.volume')}
                    </TableSortLabel>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <TransitionGroup component="tbody">
                {symbolsList?.map((s, i) => (
                  <CSSTransition key={s.id} timeout={700} classNames="item" nodeRef={nodeRef}>
                    <tr ref={i === symbolsList?.length - 5 ? ref : undefined}>
                      <td>
                        <div className="name">
                          <img src={process.env.REACT_APP_ICONS_SERVER + s.symbol?.toLowerCase()?.split('usdt')?.[0]} alt="" />
                          {s?.symbol?.replace('USDT', ' / USDT')}
                        </div>
                      </td>
                      <td className="a-right">
                        <p>
                          $
                          <NativeCurrencyFormatWrapper value={s.low_price} isUsd={false} />
                        </p>
                        <p className={`arrow-percent ${s.price_change_percent < 0 ? 'c-red' : 'c-green'}`}>
                          <NativeCurrencyFormatWrapper value={Math.abs(s.price_change_percent)} isUsd={true} />%
                        </p>
                      </td>
                      <td className="a-right">
                        $
                        <NativeCurrencyFormatWrapper value={s.quote_volume} isUsd={false} />
                      </td>
                      <td>
                        <MenuPopup>
                          <Button variant="outlined" onClick={() => showWidget(s.symbol)} endIcon={<Icon icon="chart-btn" />}>
                            {t('bots.add-bot.tab.graph')}
                          </Button>
                          {coinGecoButton(s.base)}
                          {/* {coinMarketCapButton(s.base)} */}
                          <Button
                            disabled={s.status !== 'TRADING'}
                            size="big"
                            variant="contained"
                            onClick={() => createBot(s.symbol)}
                            endIcon={<Icon icon="bot" />}
                          >
                            {t('bots.add-bot')}
                          </Button>
                        </MenuPopup>
                      </td>
                    </tr>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </table>
          )}
        </div>
      </CSSTransition>
      {isLoading && <Preloader loading={true} />}
      {symbolsList?.length === 0 && !isLoading && <NotDataCard text={t('common.no-data')} />}
      <AddBotModal open={modalOpen} onClose={() => setModalOpen(false)} onOpen={handleModalClose} showType="trade" />
      <AnalyticsWidgetModal
        open={widgetModalOpen}
        onClose={() => setWidgetModalOpen(false)}
        trendingViewSymbol={trendingViewSymbol}
        symbol={selectedSymbol}
        wallet={selectedWallet?.type}
      />
    </div>
  );
};
