import { Navigate, useOutlet, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/utility/useAuth';

export const HomeLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
  const { pathname } = useLocation();
  const { state } = useLocation();
  const { email, femail } = state || {};

  if (user) {
    return <Navigate to="/dashboard" replace />;
  }

  if (!email && !femail && pathname === '/auth') {
    return <Navigate to="/login" replace />;
  }

  return <>{outlet}</>;
};
