import { Alert, Button, CircularProgress, IconButton } from '@mui/material';
import { TradingViewWidget } from '../../components/utility/TradingViewWidget';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useGetBotById } from '../../hooks/http-requests/useGetBotById';
import { NavLink, useParams } from 'react-router-dom';
import { BotTradeWidget } from '../../components/bots/BotTradeWidget';
import { useTranslation } from 'react-i18next';
import { useActiveTab } from '../../hooks/utility/useActiveTab';
import { BotOperations } from '../../components/bots/BotOperations';
import { useChangeTradeBotStatus } from '../../hooks/http-requests/useChangeTradeBotStatus';
import { useDeleteTradeBot } from '../../hooks/http-requests/useDeleteTradeBot';
import { useNavigate } from 'react-router-dom';
import { EditBotModal } from '../../components/bots/EditBotModal';
import { useBalanceInfo } from '../../hooks/http-requests/useBalanceInfo';
import { useGetTradeBots } from '../../hooks/http-requests/useGetTradeBots';
import { BinanceCurrentData } from '../../components/utility/BinanceCurrentData';
import { BybitCurrentData } from '../../components/utility/BybitCurrentData';
import Icon from '../../components/Icons';
import MenuPopup from '../../components/utility/MenuPopup';
import './BotTradeInfo.scss';
import { ConfirmModal } from '../../components/ConfirmModal';
import { useAuth } from '../../hooks/utility/useAuth';
import { useGetUser } from '../../hooks/http-requests/useGetUser';
import { DifferenceBetweenDates } from '../../components/utility/DifferenceBetweenDates';
import { Share } from '@mui/icons-material';
import { DisableBotModal } from '../../components/bots/DisableBotModal';
import { ShareBotBlock, ShareMobile, SharePc } from '../../components/ShareBotBlock';
import { BOT_TYPES } from '../../constants/bot-types';

export const BotTradeInfo = () => {
  const { t } = useTranslation();
  const { active } = useActiveTab();
  const [price, setPrice] = useState();
  const [shareShow, setShareShow] = useState(false);
  const { bot, setId } = useGetBotById('trade');
  const { changeBotStatus } = useChangeTradeBotStatus();
  const { deleteTradeBot } = useDeleteTradeBot();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [modalConfirmDelOpen, setModalConfirmDelOpen] = useState(false);
  const { user } = useGetUser();
  const { isAdmin } = useAuth();
  let { id } = useParams();
  let showUserId = '';

  const { bot_type, fund, profit_bot, symbol, closed_count, type, created_at } = bot?.data || [];

  const { balanceInfo } = useBalanceInfo(bot.data?.user_data_id);
  const { bots } = useGetTradeBots();

  const referralCode = useMemo(() => user?.data?.referral_code, [user]);

  const botsFund = useMemo(
    () =>
      bots.data
        ?.filter((b) => b.status === 'active' && !b.emulate_operations && b.user_data_id === bot.data?.user_data_id)
        ?.reduce((sum, b) => sum + b.fund + b.profit_bot, 0),
    [bots.data, bot.data]
  );

  const insufficientFunds = useMemo(() => {
    if (bot.data?.emulate_operations) return false;
    const amount = balanceInfo.data?.find((d) => d.coin === 'USDT')?.amount;
    if (amount !== undefined && botsFund != null && bot.data?.fund) {
      return amount - botsFund - bot.data?.fund < 0;
    } else {
      return true;
    }
  }, [balanceInfo.data, botsFund, bot.data]);

  useEffect(() => {
    setId(id);
  }, [id, setId]);

  useEffect(() => {
    !active && setPrice(undefined);
  }, [active]);

  const symbols = useMemo(() => (bot.data?.symbol ? [bot.data.symbol.toLowerCase()] : []), [bot.data]);

  const trendingViewSymbol = useMemo(() => {
    if (!bot.data?.type || !bot.data?.symbol) return null;
    return `${bot.data?.type.toUpperCase()}:${bot.data?.symbol}`;
  }, [bot.data]);

  const changeStatus = (status, disableAfterClosingDeal) => {
    changeBotStatus.mutateAsync({ botId: bot.data?.id, status, disableAfterClosingDeal }).then(() => bot.refetch());
  };

  const deleteBotClick = () => {
    setModalConfirmDelOpen(true);
  };

  const deleteBotConfirm = () => {
    deleteTradeBot.mutateAsync(bot.data?.id).then(() => navigate(`/dashboard/bots/${bot.data?.bot_type === 'portfolio' ? 'portfolio' : 'trade'}`));
  };

  if (isAdmin()) showUserId = localStorage.getItem('showUserId');

  const sharePc = async () => {
    setShareShow(true);
    await SharePc();
    setShareShow(false);
  };

  const shareMobile = async () => {
    setShareShow(true);
    await ShareMobile();
    setShareShow(false);
  };

  const onDisableBotModalClose = (closeTime) => {
    if (closeTime === 'now') {
      changeStatus('disabled');
    }
    if (closeTime === 'after-sale') {
      changeStatus('active', true);
    }
    setModalConfirmOpen(false);
  };

  const userName = () => localStorage.getItem('showUserName');
  const userEmail = () => localStorage.getItem('showUserEmail');
  const isPortfolio = bot_type === BOT_TYPES.portfolio;

  const shareContent = () => {
    const content = [
      {
        name: t('operations.filters.wallet'),
        value: `${t(`wallet.${type.toUpperCase()}`)}`,
      },
      {
        name: t('bots.widget.closed-deals'),
        value: `${closed_count} ${t('bots.widget.pieces')}`,
      },
      {
        name: t('bots.widget.created-bot'),
        value: <DifferenceBetweenDates date={created_at} lastDate={bot?.data?.status !== 'active' ? bot?.data?.updated_at : undefined} />,
      },
      {
        name: t('bots.summary-widget.start-balance'),
        value: `$${fund}`,
      },
    ];

    if (isPortfolio) {
      content.splice(1, 1);
    }

    return content;
  };

  if (!bot.data?.id) return null;

  return (
    <div className="bot-info-page__wrap">
      {bot.data.status === 'active' && bot.data.bot_type === 'portfolio' && (
        <Alert className="mb-16" variant="filled" severity="info">
          {t('bots.rebalancer.info-message')}
        </Alert>
      )}
      <DisableBotModal open={modalConfirmOpen} onClose={onDisableBotModalClose} type={bot_type} />
      <ConfirmModal
        open={modalConfirmDelOpen}
        onCancel={() => setModalConfirmDelOpen(false)}
        title={t('confirm.bot.delete.title')}
        onOK={() => {
          deleteBotConfirm();
          setModalConfirmDelOpen(false);
        }}
        text={t('confirm.bot.delete.text')}
      />
      <div className="bot-info-page__header no-print">
        <h3 className="bot-info-page__title">
          <NavLink
            className="btn icon -no-hover"
            to={
              isAdmin() ? `/admin/bots/${isPortfolio ? 'portfolio' : 'trade'}?user_id=${showUserId}` : `/dashboard/bots/${isPortfolio ? 'portfolio' : 'trade'}`
            }
          >
            <Icon icon="arrow-left" />
          </NavLink>
          <div className="flex">
            <img
              className="-icon-symbol"
              style={{ minWidth: 47, marginRight: 5 }}
              src={process.env.REACT_APP_ICONS_SERVER + bot.data?.symbol.toLowerCase().replace('usdt', '')}
              alt=""
            />
            <div>
              <div>
                {bot.data?.symbol?.replace('USDT', ' / USDT')} {bot.data.name ? `"${bot.data.name}"` : ''}
              </div>
              <div className="bots-trade-widget__desc">
                {t(`bots.widget.type.${bot.data?.bot_type}`)}
                {bot.data?.emulate_operations ? ` (${t('bots.summary-widget.summary-test')})` : ''}
              </div>
            </div>
          </div>
          <div className="pc-hidden tablet-hidden">
            <MenuPopup>
              {bot.data.status === 'disabled' && (
                <Button variant="contained" color="warning" onClick={deleteBotClick} endIcon={<Icon icon="delete" className="medium" />}>
                  {t('bots.info.delete')}
                </Button>
              )}
              {bot.data.status === 'active' && (
                <Button variant="contained" color="warning" onClick={() => setModalConfirmOpen(true)} endIcon={<Icon icon="stop" className="medium" />}>
                  {t('bots.info.switch-off')}
                </Button>
              )}
              {bot.data.status === 'disabled' && (
                <Button
                  variant="contained"
                  onClick={() => changeStatus('active')}
                  endIcon={<Icon icon="play" className="medium" />}
                  disabled={(bot.data?.disable_reason && bot.data?.disable_reason !== 'user') || insufficientFunds}
                >
                  {t('bots.info.switch-on')}
                </Button>
              )}
              <Button variant="outlined" onClick={() => setModalOpen(true)} endIcon={<Icon icon="edit" className="medium" />}>
                {t('bots.info.change')}
              </Button>
              <Button variant="contained" onClick={() => shareMobile()} endIcon={shareShow ? <CircularProgress color="inherit" size={20} /> : <Share />}>
                {t('bots.info.share')}
              </Button>
            </MenuPopup>
          </div>
        </h3>
        <div className="mobile-hidden">
          <div className="bot-info-page__buttons">
            {bot.data.status === 'disabled' && (
              <Button variant="contained" color="warning" onClick={deleteBotClick} endIcon={<Icon icon="delete" className="medium" />}>
                {t('bots.info.delete')}
              </Button>
            )}
            {bot.data.status === 'active' && (
              <Button variant="contained" color="warning" onClick={() => setModalConfirmOpen(true)} endIcon={<Icon icon="stop" className="medium" />}>
                {t('bots.info.switch-off')}
              </Button>
            )}
            {bot.data.status === 'disabled' && (
              <Button
                variant="contained"
                onClick={() => changeStatus('active')}
                endIcon={<Icon icon="play" className="medium" />}
                disabled={(bot.data?.disable_reason && bot.data?.disable_reason !== 'user') || insufficientFunds}
              >
                {t('bots.info.switch-on')}
              </Button>
            )}
            <Button variant="outlined" onClick={() => setModalOpen(true)} endIcon={<Icon icon="edit" className="medium" />}>
              {t('bots.info.change')}
            </Button>
            <IconButton color="primary" onClick={() => sharePc()}>
              {shareShow ? <CircularProgress size={20} /> : <Share />}
            </IconButton>
          </div>
        </div>
      </div>
      <div className="card no-print">
        <div className="bot-info-page__content">
          <div className="bot-info-page__info">
            <BotTradeWidget bot={bot.data} lastPrice={price} withoutChart={true} />
          </div>
          <div className="bot-info-page__chart">{trendingViewSymbol && <TradingViewWidget symbolProp={trendingViewSymbol} showDetails={false} />}</div>
        </div>
      </div>
      <div className="bot-info-page__operations">
        <h4 className="no-print">
          {t('bots.info.history')}{' '}
          <IconButton onClick={() => window.print()}>
            <Icon icon="download" />
          </IconButton>
        </h4>
        <BotOperations id={bot.data?.id} type="trade" />
        <EditBotModal open={modalOpen} onClose={() => setModalOpen(false)} refetch={bot.refetch} bot={bot.data} />
        {bot.data?.type === 'BINANCE' && <BinanceCurrentData symbols={symbols} binanceUpdate={(update) => setPrice(update.data.k.c)} active={active} />}
        {bot.data?.type === 'bybit' && <BybitCurrentData symbols={symbols} bybitUpdate={(update) => setPrice(update.data.c)} active={active} />}
      </div>

      <ShareBotBlock
        referralCode={referralCode}
        title={`Бот ${t(`bots.widget.type.${bot_type}`)} ${symbol.replace('USDT', ' / USDT')}`}
        primaryIndicator={`${+(((+profit_bot || 0) / fund) * 100).toFixed(2)}%`}
        secondaryIndicator={`${+(+profit_bot || 0).toFixed(2)} USDT`}
        descriptions={shareContent()}
      />
      {isAdmin() && (
        <div className="admin-show-user">
          {userEmail()} {userName()}
        </div>
      )}
    </div>
  );
};
