import useWebSocket from 'react-use-websocket';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

export const BinanceHistoricData = ({ symbols, onSymbolData, active, limit = 84, interval = '2h' }) => {
  const [ids, setIds] = useState({});
  const [storedSymbols, setStoredSymbols] = useState();

  const { lastJsonMessage, sendJsonMessage } = useWebSocket(
    'wss://ws-api.binance.com:443/ws-api/v3',
    {
      shouldReconnect: () => true,
    },
    active
  );

  useEffect(() => {
    if (!lastJsonMessage) return;
    onSymbolData({
      symbol: ids[lastJsonMessage.id],
      data: lastJsonMessage.result.map((p) => ({
        timestamp: p[6],
        value: +p[4],
        low: +p[3],
        high: +p[2],
      })),
    });
  }, [lastJsonMessage, ids, onSymbolData]);

  useEffect(() => {
    if (!active) {
      setStoredSymbols(undefined);
      return;
    }
    if (JSON.stringify(symbols) === JSON.stringify(storedSymbols)) return;
    symbols.forEach((s) => {
      const id = uuid();
      setIds((prev) => ({ ...prev, [id]: s }));
      sendJsonMessage({
        id,
        method: 'klines',
        params: { symbol: s.toUpperCase(), interval, limit },
      });
    });
    setStoredSymbols(symbols);
  }, [symbols, active, sendJsonMessage, storedSymbols, interval, limit]);

  return <></>;
};
