import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useGetBotsProfitData = (test, exchangeId, portfolio) => {
  const getBotsProfitData = useQuery({
    queryKey: ['getBotsProfitData', test, exchangeId],
    queryFn: () => ApiService.getBotsProfitData(test, exchangeId, portfolio),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
  });

  return { getBotsProfitData };
};
