import { useAuth } from '../../hooks/utility/useAuth';
import { useState } from 'react';

export const VerifyPage = () => {
  const { verify } = useAuth();
  const [message, setMessage] = useState('');

  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  verify(params.get('token')).then((mes) => setMessage(mes));

  return (
    <div className="left">
      <h4>{message}</h4>
    </div>
  );
};
