import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import { NativeDateLocaleWrapper } from './utility/NativeDateLocaleWrapper';
import { NativeCurrencyFormatWrapper } from './utility/NativeCurrencyFormatWrapper';
import { TableSortLabel } from '@mui/material';
import { ApiService } from '../utils/api-service';
import { useIsMobile } from './utility/DetectDevice';

const headerCells = [
  { name: 'email', label: 'referral.table.email' },
  { name: 'created_at', label: 'referral.table.date' },
  { name: 'percent', label: 'referral.table.percent' },
  { name: 'referrer', label: 'referral.table.referrer' },
  { name: 'amount', label: 'referral.table.amount' },
];

const limit = 10;

export const RefferalTable = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useState({
    sortBy: 'created_at',
    sortDir: 'desc',
  });
  const { ref, inView } = useInView();

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['referralList', query],
    queryFn: ({ pageParam = 1 }) =>
      ApiService.getReferralList({
        page: pageParam,
        ...query,
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage?.length === limit ? allPages.length + 1 : undefined,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
    enabled: true,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const referralList = useMemo(
    () =>
      data?.pages?.reduce(
        (acc, current) => !!current && acc.concat(current),
        []
      ) || [],
    [data?.pages]
  );

  const changeQuery = (query) => {
    setQuery((prevState) => ({ ...prevState, ...query }));
  };

  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile ? (
        <table className="table-shadow">
          <thead>
            <tr>
              {headerCells?.map((c) => (
                <th
                  sortDirection={
                    query.sortBy === 'symbol' ? query.sortDir : false
                  }
                  key={c.name}
                >
                  <TableSortLabel
                    active={query.sortBy === c.name}
                    direction={query.sortBy === c.name ? query.sortDir : 'asc'}
                    onClick={() =>
                      changeQuery({
                        sortBy: c.name,
                        sortDir:
                          query.sortBy === c.name && query.sortDir === 'asc'
                            ? 'desc'
                            : 'asc',
                      })
                    }
                  >
                    {t(c.label)}
                  </TableSortLabel>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {referralList?.map((o, i) => (
              <tr
                key={o.email}
                ref={i === referralList?.length - 5 ? ref : undefined}
              >
                <td>{o.email}</td>
                <td>
                  <NativeDateLocaleWrapper
                    date={o.created_at}
                    options={{
                      day: '2-digit',
                      month: 'short',
                      hour: '2-digit',
                      minute: '2-digit',
                    }}
                  />
                </td>
                <td>{o.percent}%</td>
                <td>
                  {o.referrer === 'you' ? t('referral.table.you') : o.referrer}
                </td>
                <td>
                  <NativeCurrencyFormatWrapper value={o.amount} isUsd={false} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className="table-shadow">
          <thead>
            <tr>
              <th>
                <div className="info-line">
                  <span>
                    {t('referral.table.email')}/{t('referral.table.date')}
                  </span>
                  <span>{t('referral.table.amount')}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {referralList?.map((o, i) => (
              <tr
                key={o.email}
                ref={i === referralList?.length - 5 ? ref : undefined}
              >
                <td>
                  <div className="info-line">
                    <span>
                      <b>{o.email}</b>
                      <p className="description">
                        <NativeDateLocaleWrapper
                          date={o.created_at}
                          options={{
                            day: '2-digit',
                            month: 'short',
                            hour: '2-digit',
                            minute: '2-digit',
                          }}
                        />
                      </p>
                    </span>
                    <spanb>
                      <NativeCurrencyFormatWrapper
                        value={o.amount}
                        isUsd={false}
                      />
                    </spanb>
                  </div>
                  <hr />
                  <div className="info-line">
                    <span>{t('referral.table.percent')}</span>
                    <span>{o.percent}%</span>
                  </div>
                  <div className="info-line">
                    <span>{t('referral.table.referrer')}</span>
                    <span>
                      {o.referrer === 'you'
                        ? t('referral.table.you')
                        : o.referrer}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};
