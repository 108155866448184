import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';
import { useEffect, useMemo } from 'react';
import { NativeDateLocaleWrapper } from '../utility/NativeDateLocaleWrapper';
import { NativeCurrencyFormatWrapper } from '../utility/NativeCurrencyFormatWrapper';
import * as React from 'react';
import Big from 'big.js';
import { useIsMobile } from '../utility/DetectDevice';

const limit = 50;

export const BotOperations = ({ id, type }) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();

  const queryFn = type === 'rebalancer' ? ApiService.getBotRebalancerOperationsById : ApiService.getBotTradeOperationsById;

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['botOperationsInfinite'],
    queryFn: ({ pageParam = 1 }) =>
      queryFn({
        id,
        page: pageParam,
        limit,
      }),
    getNextPageParam: (lastPage, allPages) => (lastPage?.length === limit ? allPages.length + 1 : undefined),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
    refetchInterval: 10000,
    enabled: !!id,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const operationsList = useMemo(() => data?.pages?.reduce((acc, current) => !!current && acc.concat(current), []) || [], [data?.pages]);

  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile ? (
        <table className="table-shadow">
          <thead>
            <tr>
              <th>{t('bots.operations.date')}</th>
              {type === 'rebalancer' && <th>{t('analytics.header.symbol')}</th>}
              <th className="a-right">{t('bots.operations.price')}</th>
              <th className="a-right">{t('bots.operations.amount')}</th>
              <th className="a-right">{t('bots.operations.money-amount')}</th>
              <th>{t('bots.operations.type')}</th>
              <th className="a-right">{t('bots.widget.commission')}</th>
              <th className="a-right">{t('bots.operations.profit')}</th>
            </tr>
          </thead>
          <tbody>
            {operationsList?.map((o, i) => (
              <tr key={o.id} ref={i === operationsList?.length - 5 ? ref : undefined} className={o.type === 'sell' ? '-hover' : ''}>
                <td>
                  <NativeDateLocaleWrapper
                    date={o.updated_at}
                    options={{
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                    }}
                  />
                </td>
                {type === 'rebalancer' && <td>{o.data?.symbol?.replace('USDT', ' / USDT') || ''}</td>}
                <td className="a-right">
                  <NativeCurrencyFormatWrapper value={o.price} isUsd={false} />
                </td>
                <td className="a-right">
                  <NativeCurrencyFormatWrapper value={o.qty} isUsd={false} />
                </td>
                <td className="a-right">
                  <NativeCurrencyFormatWrapper value={o.price * o.qty} isUsd={false} maximumFractionDigits={3} />
                </td>
                <td>{t(`bots.operations.type.${o.type}`)}</td>
                <td className="a-right">
                  <NativeCurrencyFormatWrapper
                    value={o.data?.fills?.reduce((acc, curr) => acc + (curr.commissionAsset === 'USDT' ? +curr.commission : +curr.commission * +curr.price), 0)}
                    isUsd={false}
                    maximumFractionDigits={3}
                  />
                </td>
                <td className="a-right">{o.type === 'sell' ? <NativeCurrencyFormatWrapper value={Big(o.amount).minus(Big(o.spent))} isUsd={false} /> : '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className="table-shadow">
          <thead>
            <tr>
              <th>
                {t('bots.operations.type')}/{t('bots.operations.date')}
              </th>
              <th className="a-right">
                {t('bots.operations.price')}/{t('bots.operations.amount')}
              </th>
            </tr>
          </thead>
          <tbody>
            {operationsList?.map((o, i) => (
              <tr key={i} className={o.type === 'sell' ? '-hover' : ''}>
                <td>
                  <b>{t(`bots.operations.type.${o.type}`)}</b>
                  <p>
                    {' '}
                    <NativeDateLocaleWrapper
                      date={o.updated_at}
                      options={{
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                      }}
                    />
                  </p>
                  <hr />
                  {type === 'rebalancer' && <div>{t('analytics.header.symbol')}</div>}
                  <p>{t('bots.operations.money-amount')}</p>
                  <p>{t('bots.widget.commission')}</p>
                  <p>{t('bots.operations.profit')}</p>
                </td>
                <td className="a-right">
                  <p>
                    <NativeCurrencyFormatWrapper value={o.price} isUsd={false} />
                  </p>
                  <p>
                    <NativeCurrencyFormatWrapper value={o.qty} isUsd={false} />
                  </p>
                  <hr />
                  {type === 'rebalancer' && <div>{o.data?.symbol?.replace('USDT', ' / USDT') || ''}</div>}
                  <p>
                    <NativeCurrencyFormatWrapper value={o.price * o.qty} isUsd={false} maximumFractionDigits={3} />
                  </p>
                  <p>
                    {' '}
                    <NativeCurrencyFormatWrapper
                      value={o.data?.fills?.reduce(
                        (acc, curr) => acc + (curr.commissionAsset === 'USDT' ? +curr.commission : +curr.commission * +curr.price),
                        0
                      )}
                      isUsd={false}
                      maximumFractionDigits={3}
                    />
                  </p>
                  <p> {o.type === 'sell' ? <NativeCurrencyFormatWrapper value={Big(o.amount).minus(Big(o.spent))} isUsd={false} /> : '-'}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};
