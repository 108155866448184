import * as types from '../constants/auth';
import callApi from '../utils/call-api';

export function signup(email, password, referrer, locale, telegramId) {
  return callApi(
    '/signup',
    undefined,
    { method: 'POST' },
    {
      email,
      password,
      referrer,
      locale,
      telegramId,
    }
  )
    .then((json) => {
      if (!json?.message) {
        return false;
      }
      return json;
    })
    .catch((errorMessage) => {
      console.log(errorMessage);
      return { success: false, message: errorMessage.message };
    });
}

export function _login(email, password, iniData, sendMethod) {
  return callApi(
    '/login',
    undefined,
    { method: 'POST' },
    {
      email,
      password,
      iniData,
      sendMethod,
    }
  )
    .then((json) => {
      if (json.success && json.email) {
        return json;
      }
      return false;
    })
    .catch((reason) => {
      console.log(reason);
      return reason;
    });
}

export function _auth(code, email) {
  return callApi(
    '/auth',
    undefined,
    { method: 'POST' },
    {
      code,
      email,
    }
  )
    .then((json) => {
      if (!json.token) {
        throw new Error('Wrong token!');
      }
      localStorage.setItem('token', json.token);
      return json.user;
    })
    .catch((reason) => {
      console.log(reason);
      return reason;
    });
}

export function auth_pass(code, email) {
  return callApi(
    '/auth_pass',
    undefined,
    { method: 'POST' },
    {
      code,
      email,
    }
  )
    .then((json) => {
      if (!json.token) {
        throw new Error('Wrong token!');
      }
      localStorage.setItem('token', json.token);
      return json.user;
    })
    .catch((reason) => {
      return { reason, error: true };
    });
}

export function logout() {
  return (dispatch) => {
    dispatch({
      type: types.LOGOUT_REQUEST,
    });
  };
}

export function _verify(token) {
  return callApi(
    '/verify',
    undefined,
    { method: 'POST' },
    {
      token,
    }
  )
    .then((json) => {
      if (!json.message) {
        throw new Error('Wrong token!');
      }
      return json;
    })
    .catch((reason) => {
      console.log(reason);
      return reason;
    });
}

export function _forget(email) {
  return callApi(
    '/forget',
    undefined,
    { method: 'POST' },
    {
      email,
    }
  )
    .then((json) => {
      if (json.success && json.email) {
        return json;
      }
      return false;
    })
    .catch((reason) => {
      console.log(reason);
      return reason;
    });
}

export function recieveAuth() {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    if (!token) {
      dispatch({
        type: types.RECIEVE_AUTH_FAILURE,
      });
    }
    return callApi('/userauth', token)
      .then((json) => {
        dispatch({
          type: types.RECIEVE_AUTH_SUCCESS,
          payload: json,
        });
      })
      .catch((reason) =>
        dispatch({
          type: types.RECIEVE_AUTH_FAILURE,
          payload: reason,
        })
      );
  };
}

export function changeUser(password, newPassword) {
  const token = window.localStorage.getItem('token');
  return callApi(
    '/change_password',
    token,
    { method: 'POST' },
    {
      password,
      newPassword,
    }
  )
    .then((json) => {
      if (json.success) {
        return json;
      }
      return { message: 'password.wrong' };
    })
    .catch((reason) => {
      console.log(reason);
      return { message: 'password.wrong', success: false };
    });
}
