import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { NavLink, Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/utility/useAuth';
import { Checkbox, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const RegisterPage = () => {
  const { register, login } = useAuth();
  const [checked, setChecked] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [verifyPasswordError, setVerifyPasswordError] = useState(false);

  React.useEffect(() => {
    if (verifyPassword && verifyPassword !== '' && verifyPassword === newPassword && checked) {
      setBtnDisabled(!checked);
    } else {
      setBtnDisabled(true);
    }
  }, [checked, newPassword, verifyPassword]);

  React.useEffect(() => {
    if (verifyPassword && verifyPassword !== '' && verifyPassword === newPassword) {
      setVerifyPasswordError(false);
    } else if (verifyPassword && verifyPassword !== '' && verifyPassword !== newPassword) {
      setVerifyPasswordError(true);
    } else {
      setVerifyPasswordError(false);
    }
  }, [newPassword, verifyPassword]);

  React.useEffect(() => {
    if (!confirm) document.getElementById('referrer').value = searchParams.get('ref') || '';
  }, [confirm, searchParams]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const telegramId = window.Telegram?.WebApp?.initDataUnsafe?.user?.id || undefined;
    const iniData = window.Telegram?.WebApp?.initData || undefined;

    setIsLoading(true);
    register({
      email: data.get('email'),
      password: data.get('password'),
      referrer: data.get('referrer') || telegramId ? 'tg' : undefined,
      locale: navigator.language === 'ru-RU' || navigator.language === 'ru' ? 'ru' : 'en',
      telegramId,
    }).then((mes) => {
      if (mes.success) {
        login({
          email: data.get('email'),
          password: data.get('password'),
          iniData,
        }).then((mes) => {
          setMessage(mes);
          setIsLoading(false);
        });
      } else {
        setMessage(t(`auth.registration.error.${mes.message}`));
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      {!confirm ? (
        <form className="left" onSubmit={handleSubmit}>
          <h3>{t('auth.sign-up')}</h3>
          <TextField margin="normal" required fullWidth id="email" label="Email" name="email" type="email" autoComplete="email" autoFocus />
          <TextField
            required
            fullWidth
            name="password"
            label={t('auth.password')}
            type="password"
            id="password"
            placeholder={t('auth.password.min')}
            autoComplete="current-password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            required
            fullWidth
            name="verifyPassword"
            label={t('auth.password.verify')}
            type="password"
            id="verifyPassword"
            onChange={(e) => setVerifyPassword(e.target.value)}
            error={verifyPasswordError}
            helperText={verifyPasswordError ? t('auth.password.verify.error') : ''}
          />
          <TextField fullWidth name="referrer" label={t('profile.referral')} id="referrer" />
          <div className="w-fill flex">
            <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />
            <span>
              {' '}
              {t('auth.consent')}
              <NavLink target="_blank" to={t('link.policy')}>
                {' '}
                {t('auth.policy-terms')}
              </NavLink>
            </span>
          </div>
          {message && <div className="error-validator">{message}</div>}
          <Button type="submit" disabled={btnDisabled || isLoading} fullWidth variant="contained" size="large">
            {isLoading && <CircularProgress sx={{ mr: 1 }} color="primary" size={20} />}
            {t('auth.sign-up')}
          </Button>
          <div className="w-fill">
            {t('auth.have-account')}
            <RouterLink to="/login"> {t('header.login')}</RouterLink>
          </div>
        </form>
      ) : (
        <div className="left">
          <span>{t('auth.registration.confirm.text')}</span>
        </div>
      )}
    </>
  );
};
