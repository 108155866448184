import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { NativeCurrencyFormatWrapper } from './utility/NativeCurrencyFormatWrapper';
import './PercentPriceBlock.scss';
import { t } from 'i18next';
import { useMemo, useState } from 'react';
import { BybitHistoricData } from './utility/BybitHistoricData';
import { BinanceHistoricData } from './utility/BinanceHistoricData';

const PercentPriceBlock = ({ symbol, wallet }) => {
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [historyData, setHistoryData] = useState([]);
  const [pricePercentInterval, setPricePercentInterval] = useState('90');

  const setSymbolData = (data) => {
    if (data?.data?.length) {
      setHistoryData(data.data);
      setLoadingHistory(false);
    }
  };

  useMemo(() => {
    setLoadingHistory(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricePercentInterval, symbol]);

  const VolatilityKline = (klins) => {
    const priceChanges = klins.map((candle) => {
      const vol = ((+candle.high - +candle.low) / +candle.low) * 100;
      return vol;
    });

    const average = priceChanges.reduce((acc, cur) => acc + cur, 0) / priceChanges.length;

    return +average.toFixed(2);
  };

  const percentCurrentPriceData = useMemo(() => {
    const numToKeep = pricePercentInterval;

    if (historyData.length < numToKeep) return false;

    const data = historyData.slice(-numToKeep);
    const minPrice = Math.min(...data.map((e) => e.low));
    const maxPrice = Math.max(...data.map((e) => e.high));
    const currentPrice = data[data.length - 1].value;

    const percent = +((100 / (maxPrice - minPrice)) * (currentPrice - minPrice)).toFixed(2);

    const dataMA = data.map((e) => (e.low + e.high) / 2);
    const summ = dataMA.reduce((summ, val) => summ + val, 0);
    const MA = summ / numToKeep;
    const MApercent = (((currentPrice - MA) / MA) * 100).toFixed(2);

    const difMinCurrentPercent = (((currentPrice - minPrice) / currentPrice) * 100).toFixed(2);

    const volatility = VolatilityKline(data);

    return {
      minPrice,
      maxPrice,
      percent,
      difMinCurrentPercent,
      currentPrice,
      MApercent,
      volatility,
    };
  }, [historyData, pricePercentInterval]);

  return (
    <div className="percent-price-block-wrap">
      {/* получение свечей для блока с историей цены */}
      {!!symbol && (
        <>
          {wallet === 'BINANCE' && (
            <BinanceHistoricData symbols={[symbol]} onSymbolData={(data) => setSymbolData(data)} active={loadingHistory} limit={90} interval="1d" />
          )}
          {wallet === 'bybit' && (
            <BybitHistoricData symbols={[symbol]} onSymbolData={(data) => setSymbolData(data)} active={loadingHistory} limit={90} interval="D" />
          )}
        </>
      )}

      <div style={{ padding: 10, border: '1px solid #ddd', borderRadius: 8 }}>
        <p className="f-small bold">{t('bots.add-bot.min-max')}</p>
        <div className="percent-price-block">
          <div className="percent-price-block__price --min">
            $
            <NativeCurrencyFormatWrapper value={percentCurrentPriceData.minPrice} isUsd={false} />
          </div>
          <div className="percent-price-block__price --max">
            $
            <NativeCurrencyFormatWrapper value={percentCurrentPriceData.maxPrice} isUsd={false} />
          </div>
          <div className="percent-price-block__center c-primary-500">
            +{percentCurrentPriceData.difMinCurrentPercent}% {t('bots.add-bot.from-min')}
          </div>
          <div className="percent-price-block__interval">
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={pricePercentInterval}
              exclusive
              onChange={(e) => setPricePercentInterval(e.target.value)}
              aria-label="Platform"
            >
              <ToggleButton value="1">1{t('bots.widget.d')}</ToggleButton>
              <ToggleButton value="7">7{t('bots.widget.d')}</ToggleButton>
              <ToggleButton value="30">30{t('bots.widget.d')}</ToggleButton>
              <ToggleButton value="90">90{t('bots.widget.d')}</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="percent-price-block__line" style={{ width: `${percentCurrentPriceData.percent}%` }}>
            {/* <div
          style={{
            fontSize: 11,
            fontWeight: 600,
            right: 0,
            bottom: 8,
            transform: 'translate(50%, 0)',
            position: 'absolute',
          }}
        >
          <NativeCurrencyFormatWrapper
            value={percentCurrentPriceData.percent}
            isUsd={true}
          />
          %
        </div> */}
          </div>
        </div>
        <div className="percent-price-block__information">
          <div>
            {t('bots.add-bot.volatility')} {pricePercentInterval}
            {t('bots.widget.d')}: <b>{percentCurrentPriceData.volatility}%</b>
          </div>
          {/* <div>
          {t('bots.add-bot.dif-min-current-price')}{' '}
          {pricePercentInterval}
          {t('bots.widget.d')}:{' '}
          <b>+{percentCurrentPriceData.difMinCurrentPercent}%</b>
        </div> */}
          <div>
            {t('bots.add-bot.middle-ma-percent')} {pricePercentInterval}
            {t('bots.widget.d')}:{' '}
            <b>
              {(percentCurrentPriceData.MApercent || '-') > 0 ? '+' : ''}
              {percentCurrentPriceData.MApercent || '-'}%
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PercentPriceBlock;
