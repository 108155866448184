import * as React from 'react';
import { forwardRef } from 'react';
import { NativeDateLocaleWrapper } from './NativeDateLocaleWrapper';
import { NativeCurrencyFormatWrapper } from './NativeCurrencyFormatWrapper';
import Icon from '../Icons';

export const OperationsListItem = forwardRef(({ item, index, length }, ref) => {
  return (
    <tr key={index} ref={ref}>
      <td>
        <div className="widget-last-orders__valute">
          <img
            src={
              process.env.REACT_APP_ICONS_SERVER + item.coinFrom?.toLowerCase()
            }
            alt=""
          />
          <div>
            <div className="bold">
              <NativeCurrencyFormatWrapper
                value={+item.amountFrom}
                isUsd={false}
              />{' '}
              <span className="show-big">{item.coinFrom}</span>
            </div>
            <div className="f-small">
              {item.symbol} ={' '}
              <NativeCurrencyFormatWrapper value={+item.price} isUsd={false} />
            </div>
          </div>
        </div>
      </td>
      <td className="a-center w-fill">
        <Icon icon="arrow-right" />
        <div className="f-small">
          <NativeDateLocaleWrapper
            date={item.date}
            options={{
              day: '2-digit',
              month: 'short',
              hour: '2-digit',
              minute: '2-digit',
            }}
          />
        </div>
      </td>
      <td className="bold a-right">
        <div className="widget-last-orders__valute">
          <img
            src={
              process.env.REACT_APP_ICONS_SERVER + item.coinTo?.toLowerCase()
            }
            alt=""
          />
          <div className="bold">
            <NativeCurrencyFormatWrapper value={+item.amountTo} isUsd={false} />
          </div>
          <span className="show-big">{item.coinTo}</span>
        </div>
      </td>
    </tr>
  );
});
