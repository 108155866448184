import { AdminBalanceWidget } from '../../components/admin/BalanceWidget';
import { AdminUserStatisticsWidget } from '../../components/admin/UsersStatisticWidget';

export const AdminDashboard = () => {
  return (
    <div className="content">
      <AdminBalanceWidget />
      <AdminUserStatisticsWidget />
    </div>
  );
};
