import Icon from '../Icons';
import './NotDataCard.scss';
import { t } from 'i18next';

export const NotDataCard = ({
  text = t('not-data.card.default'),
  className = 'card',
}) => {
  return (
    <div className={`${className} not-data mt-16`}>
      <Icon icon="alert" className="c-base-600" /> {text}
    </div>
  );
};
