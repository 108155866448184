import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useDeleteWallet = () => {
  const queryClient = useQueryClient();

  const deleteWallet = useMutation(ApiService.deleteWallet, {
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['userWallets'] });
      await queryClient.refetchQueries({ queryKey: ['operationsWidget'] });
      await queryClient.refetchQueries({ queryKey: ['balance'] });
    },
  });

  return { deleteWallet };
};
