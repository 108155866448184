import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useAuth } from '../../hooks/utility/useAuth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';

export const LoginCodePage = () => {
  const { auth, forget, login } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowButtons, setIsShowButtons] = useState(true);
  const { t } = useTranslation();
  const { state } = useLocation();
  const { email, femail, password, telegram, sendMessage } = state || {};
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setIsLoading(true);
    auth({
      code: data.get('code'),
      femail,
      email,
    }).then((mes) => {
      setIsLoading(false);
      setErrorMessage(mes);
    });
  };

  const sendCodeAuth = (sendMethod) => {
    setIsLoading(true);
    setErrorMessage('');
    setIsShowButtons(false);
    login({
      email,
      password,
      sendMethod,
    }).then((mes) => {
      console.log(mes);
      setErrorMessage(mes);
      setIsLoading(false);
    });
  };

  const resentCode = () => {
    setIsLoading(true);
    setErrorMessage('');

    forget({
      email: femail,
    }).then(() => {
      setIsLoading(false);
    });
  };

  const isForget = !!femail;
  return (
    <form className="left" onSubmit={handleSubmit}>
      {isForget ? (
        <>
          <span>
            {t('header.forgotten.auth', {
              context: t('auth.password.new'),
            })}
          </span>
          <TextField margin="normal" required fullWidth type="tel" id="code" label={t('auth.code')} name="code" autoComplete="code" autoFocus />
          <Button type="submit" fullWidth variant="contained" size="large" disabled={isLoading}>
            {isLoading && <CircularProgress sx={{ mr: 1 }} color="primary" size={20} />}
            {t('header.login')}
          </Button>

          {!!errorMessage && (
            <>
              <div className="error-validator">{t(`auth.error.${errorMessage}`)}</div>
              <Button
                variant="text"
                onClick={() => {
                  resentCode();
                }}
              >
                {t('auth.send-new-code')}
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          {telegram && isShowButtons && (
            <>
              <span>{t('auth.how-send-code')}</span>
              <div className="flex">
                <Button variant="text" onClick={() => sendCodeAuth('telegram')}>
                  Telegram
                </Button>
                <Button variant="text" onClick={() => sendCodeAuth('email')}>
                  Email
                </Button>
              </div>
            </>
          )}

          {!!sendMessage && !isShowButtons && telegram && (
            <>
              {t('auth.' + sendMessage)}
              <br />
              <TextField margin="normal" required fullWidth type="tel" id="code" label={t('auth.code')} name="code" autoComplete="code" autoFocus />
              <Button type="submit" fullWidth variant="contained" size="large" disabled={isLoading}>
                {isLoading && <CircularProgress sx={{ mr: 1 }} color="primary" size={20} />}
                {t('header.login')}
              </Button>
              {!!errorMessage && (
                <>
                  <div className="error-validator">{t(`auth.error.${errorMessage}`)}</div>
                  <Button
                    variant="text"
                    onClick={() => {
                      setIsShowButtons(true);
                      setErrorMessage('');
                    }}
                  >
                    {t('auth.send-new-code')}
                  </Button>
                </>
              )}
            </>
          )}

          {!!sendMessage && !telegram && (
            <>
              <span>
                {t('header.forgotten.auth', {
                  context: t('auth.code'),
                })}
              </span>
              <TextField margin="normal" required fullWidth type="tel" id="code" label={t('auth.code')} name="code" autoComplete="code" autoFocus />
              <Button type="submit" fullWidth variant="contained" size="large" disabled={isLoading}>
                {isLoading && <CircularProgress sx={{ mr: 1 }} color="primary" size={20} />}
                {t('header.login')}
              </Button>
              {!!errorMessage && (
                <>
                  <div className="error-validator">{t(`auth.error.${errorMessage}`)}</div>
                  <Button variant="text" onClick={() => sendCodeAuth('email')}>
                    {t('auth.send-new-code')}
                  </Button>
                </>
              )}
            </>
          )}
        </>
      )}
    </form>
  );
};
