import { MenuItem, Select, FormControl, InputLabel, IconButton } from '@mui/material';
import { useAvailableWallets } from '../hooks/http-requests/useAvailableWallets';
import { useCoinsList } from '../hooks/http-requests/useCoinsList';
import { DatePicker } from '@mui/x-date-pickers';
import { OperationsListItem } from '../components/utility/OperationsListItem';
import * as React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ApiService } from '../utils/api-service';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { Clear } from '@mui/icons-material';
import { ReloadButton } from '../components/utility/ReloadButton';
import { useReloadOperations } from '../hooks/http-requests/useReloadOperations';
import { useUserWallets } from '../hooks/http-requests/useUserWallets';
import { NativeDateLocaleWrapper } from '../components/utility/NativeDateLocaleWrapper';
import './Operations.scss';
import MenuPopup from '../components/utility/MenuPopup';
import Preloader from '../components/Preloader';
import { NotDataCard } from '../components/utility/NotDataCard';
import { NoWalletsBlock } from '../components/NoWalletsBlock';

const limit = 20;

export const Operations = () => {
  const [query, setQuery] = useState({
    wallet: '',
    coin: '',
    from: undefined,
    to: undefined,
  });
  const { reloadOperations } = useReloadOperations();
  const { t } = useTranslation();
  const { availableWallets } = useAvailableWallets();
  const { userWallets } = useUserWallets();
  const { coinsList } = useCoinsList();
  const { ref, inView } = useInView();

  const walletOptions = useMemo(() => {
    return userWallets.data?.map((w) => {
      const wallet = availableWallets.data?.find((aw) => aw.id === w.exchange_id);
      return {
        ...w,
        name: wallet?.name,
      };
    });
  }, [availableWallets.data, userWallets.data]);

  const { data, fetchNextPage, hasNextPage, refetch, isFetching } = useInfiniteQuery({
    queryKey: ['operationsInfinite', query],
    queryFn: ({ pageParam = 1 }) =>
      ApiService.getOperations({
        page: pageParam,
        limit,
        force: false,
        userdataid: query.wallet,
        coin: query.coin,
        from: query.from,
        to: query.to,
      }),
    getNextPageParam: (lastPage, allPages) => (lastPage?.data?.length === limit ? allPages.length + 1 : undefined),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
    refetchInterval: (data) => {
      if (!data?.pages?.length) return false;
      return data.pages[data.pages.length - 1].loading ? 10000 : false;
    },
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const operationsList = useMemo(() => data?.pages?.reduce((acc, current) => !!current.data && acc.concat(current.data), []) || [], [data?.pages]);

  const lastUpdate = useMemo(() => {
    return data?.pages[0]?.lastUpdate || false;
  }, [data?.pages]);

  const loadingStatus = useMemo(() => {
    if (!data?.pages?.length) return false;
    return data.pages[data.pages.length - 1].loading;
  }, [data?.pages]);

  const changeQuery = (query) => {
    setQuery((prevState) => ({ ...prevState, ...query }));
  };

  const countFilter = useMemo(() => !!query.to || !!query.from || !!query.coin || !!query.wallet, [query]);

  const reloadOperationsClick = () => {
    reloadOperations.mutateAsync(undefined).then(() => refetch());
  };

  return (
    <>
      <div>
        <NoWalletsBlock />
        <div className="operations-page__header">
          <h3 className="operations-page__title">
            Операции
            <span>
              {lastUpdate && (
                <>
                  {t('operations.widget.updated')}{' '}
                  <NativeDateLocaleWrapper
                    date={lastUpdate}
                    options={{
                      day: '2-digit',
                      month: 'short',
                      hour: '2-digit',
                      minute: '2-digit',
                    }}
                  />
                </>
              )}
            </span>
          </h3>
          <div className="operations-page__filters">
            <MenuPopup icon="filter" count={countFilter}>
              <FormControl fullWidth>
                <InputLabel id="wallet-select-label">{t('operations.filters.wallet')}</InputLabel>
                <Select
                  labelId="wallet-select-label"
                  label={t('operations.filters.wallet')}
                  value={query.wallet}
                  onChange={(e) => changeQuery({ wallet: e.target.value })}
                  endAdornment={
                    <IconButton sx={{ display: query.wallet ? '' : 'none' }} onClick={() => changeQuery({ wallet: '' })}>
                      <Clear />
                    </IconButton>
                  }
                >
                  {walletOptions?.map((w) => (
                    <MenuItem key={w.id} value={w.id}>
                      {w.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="coin-select-label">{t('operations.filters.coin')}</InputLabel>
                <Select
                  labelId="coin-select-label"
                  label={t('operations.filters.coin')}
                  value={query.coin}
                  onChange={(e) => changeQuery({ coin: e.target.value })}
                  endAdornment={
                    <IconButton sx={{ display: query.coin ? '' : 'none' }} onClick={() => changeQuery({ coin: '' })}>
                      <Clear />
                    </IconButton>
                  }
                >
                  {coinsList.data?.map((c) => (
                    <MenuItem key={c.symbol} value={c.symbol}>
                      {c.symbol}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <DatePicker
                label={t('operations.filters.from')}
                sx={{ width: '100%' }}
                views={['year', 'month', 'day']}
                value={query.from}
                onChange={(from) => changeQuery({ from })}
              />
              <DatePicker
                label={t('operations.filters.to')}
                sx={{ width: '100%' }}
                views={['year', 'month', 'day']}
                value={query.to}
                onChange={(to) => changeQuery({ to })}
              />
            </MenuPopup>
            <ReloadButton onClick={reloadOperationsClick} loading={reloadOperations.isLoading || isFetching || loadingStatus} />
          </div>
        </div>
      </div>
      {walletOptions?.length === 0 && !reloadOperations.isLoading && !isFetching && !loadingStatus && <NotDataCard text={t('common.no-data')} />}

      <div className="content operations-page__content">
        <div className="card">
          <table className={`widget-last-orders__table big`}>
            <tbody>
              {operationsList?.map((o, i) =>
                i === operationsList?.length - 3 ? (
                  <OperationsListItem ref={ref} key={o.id} item={o} index={i} length={operationsList?.length} />
                ) : (
                  <OperationsListItem key={o.id} item={o} index={i} length={operationsList?.length} />
                )
              )}
            </tbody>
          </table>
          {(reloadOperations.isLoading || isFetching || loadingStatus) && <Preloader loading={true} />}
        </div>
      </div>
    </>
  );
};
