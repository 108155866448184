import fetch from 'isomorphic-fetch';

export default function callApi(endpoint, token, options, payload) {
  const authHeaders = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};
  return fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      'Access-Control-Request-Headers': 'X-Requested-With',
      ...authHeaders,
    },
    body: JSON.stringify(payload),
    ...options,
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.success) {
        return json;
      }
      throw new Error(json.message);
    });
}
