import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { NavLink } from 'react-router-dom';
import { Badge } from '@mui/material';
import { useHasNewNotifications } from '../../hooks/http-requests/useHasNewNotifications';
import Icon from '../Icons';
import './Header.scss';
import { DonateButton } from '../DonateButton';

export const Header = ({ toggleNavOpen, navOpen }) => {
  const { hasNewNotifications } = useHasNewNotifications();

  return (
    <div>
      <header>
        <NavLink className="-no-hover" to="/dashboard">
          <img src="/img/logo.svg" alt="logo smart crypto service" />
        </NavLink>
        <div className="user-block">
          <DonateButton color="var(--base-600)" />
          <NavLink
            className="user-block__notifications"
            to="/dashboard/notifications"
          >
            <Badge
              variant="dot"
              invisible={!hasNewNotifications.data}
              color="error"
            >
              <Icon className="-notifications-icon" icon="notifications" />
            </Badge>
          </NavLink>
          <NavLink className="user-block__profile" to="/dashboard/profile">
            <Icon className="-avatar-icon" icon="avatar" />
          </NavLink>
          <div className="mobile-menu">
            <IconButton
              aria-label="open nav"
              edge="start"
              onClick={toggleNavOpen}
            >
              <Icon className="" icon={navOpen ? 'close' : 'menu'} />
            </IconButton>
          </div>
        </div>
      </header>
    </div>
  );
};
