import FaceIcon from '@mui/icons-material/Face';
import { useBalanceInfoAdmin } from '../../hooks/http-requests/useBalanceInfoAdmin';
import { useMemo } from 'react';
import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import { NativeCurrencyFormatWrapper } from '../utility/NativeCurrencyFormatWrapper';
import Preloader from '../Preloader';
import Icon from '../Icons';
import { IconButton } from '@mui/material';

export const AdminBalanceWidget = () => {
  const { t } = useTranslation();
  const { balanceInfo } = useBalanceInfoAdmin();

  const balanceData = useMemo(() => balanceInfo.data?.slice()?.sort((a, b) => b.moneyAmount - a.moneyAmount), [balanceInfo.data]);
  const summary = useMemo(() => balanceData?.reduce((acc, current) => acc + current.moneyAmount, 0), [balanceData]);

  const gotoBots = (id, name, email) => {
    localStorage.setItem('showUserId', id);
    localStorage.setItem('showUserName', name || '');
    localStorage.setItem('showUserEmail', email);
    window.open(`/admin/bots/trade?user_id=${id}`);
  };

  return (
    <>
      <div className="card">
        <div className="card__header">
          <div className="card__title">
            <div>
              <div>{t('admin.balance.widget.state')}</div>
            </div>
          </div>
          <div className="widget-wallets__statistics">
            {balanceData?.length !== 0 && !balanceInfo.isLoading && !balanceInfo.isFetching && (
              <div>
                <h3>
                  $<NativeCurrencyFormatWrapper value={summary || 0} isUsd={true} />
                </h3>
                <span>{t('admin.balance.widget.sum')}</span>
              </div>
            )}
            {(balanceInfo.isLoading || balanceInfo.isFetching) && <Preloader loading={true} />}
          </div>
        </div>
        <div className="card__body">
          <Scrollbars style={{ height: 370 }} autoHide>
            <table className="widget-wallets__table">
              {balanceData?.length !== 0 && !balanceInfo.isLoading && !balanceInfo.isFetching && (
                <tbody>
                  {balanceData?.map((c, i) => (
                    <tr v-for="coin in wallet.coins" key={i}>
                      <td>
                        <FaceIcon sx={{ color: c.color }} />
                      </td>
                      <td>
                        <div>{c.name || 'noname'}</div>
                        <div className="f-small">{c.coin === 'other' ? t('balance.widget.other') : c.coin}</div>
                      </td>
                      <td className="bold w-fill a-right">
                        $<NativeCurrencyFormatWrapper value={c.moneyAmount} isUsd={true} />
                      </td>
                      <td>
                        <IconButton onClick={() => gotoBots(c.id, c.name, c.email)} sx={{ textTransform: 'capitalize' }}>
                          <Icon icon="bot" />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </Scrollbars>
        </div>
      </div>
    </>
  );
};
