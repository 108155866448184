import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../hooks/utility/useAuth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

export const LoginPage = () => {
  const { login } = useAuth();
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const iniData = window.Telegram?.WebApp?.initData || undefined;
    setIsLoading(true);

    login({
      email: data.get('email'),
      password: data.get('password'),
      iniData,
    }).then((mes) => {
      setMessage(mes);
      setIsLoading(false);
    });
  };

  return (
    <form className="left" onSubmit={handleSubmit}>
      <h3>{t('auth.hello')}</h3>
      <TextField margin="normal" required fullWidth id="email" label="Email" name="email" type="email" autoComplete="email" autoFocus />
      <TextField required fullWidth name="password" label={t('auth.password')} type="password" id="password" autoComplete="current-password" />
      {message && <div className="error-validator">{t(`auth.error.${message}`)}</div>}
      <div className="w-fill">
        {t('auth.forgot-password')}
        <RouterLink to="/forgotten"> {t('auth.restore')}</RouterLink>
      </div>
      <Button type="submit" fullWidth variant="contained" size="large" disabled={isLoading}>
        {isLoading && <CircularProgress sx={{ mr: 1 }} color="primary" size={20} />}
        {t('header.login')}
      </Button>
      <div className="w-fill">
        {t('auth.dont-have-account')}
        <RouterLink to="/register"> {t('auth.sign-up')}</RouterLink>
      </div>
    </form>
  );
};
