import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TableSortLabel, TextField } from '@mui/material';
import * as React from 'react';
import Icon from '../../components/Icons';
import { useAvailableWallets } from '../../hooks/http-requests/useAvailableWallets';
import { ApiService } from '../../utils/api-service';
import { NativeCurrencyFormatWrapper } from '../../components/utility/NativeCurrencyFormatWrapper';
import { AnalyticsWidgetModal } from '../../components/AnalyticsWidgetModal';
import { useToggleSymbolBlocked } from '../../hooks/http-requests/useToggleSymbolBlocked';
import { useDebouncedCallback } from 'use-debounce';
import './Admin.scss';
import MenuPopup from '../../components/utility/MenuPopup';
import { useIsMobile } from '../../components/utility/DetectDevice';

const limit = 50;

const headerCells = [
  { name: 'symbol', label: 'analytics.header.symbol', align: 'left' },
  { name: 'low_price', label: 'analytics.header.low-price', align: 'right' },
  {
    name: 'price_change_percent',
    label: 'analytics.header.price-change-percent',
    align: 'right',
  },
  {
    name: 'quote_volume',
    label: 'analytics.header.quote-volume',
    align: 'right',
  },
];

export const AdminAnalytics = () => {
  const [query, setQuery] = useState({
    exchange: '',
    symbol: '',
    sortBy: 'quote_volume',
    sortDir: 'desc',
  });
  const [search, setSearch] = useState('');
  const [volumeFrom, setVolumeFrom] = useState();
  const [volumeTo, setVolumeTo] = useState();
  const [widgetModalOpen, setWidgetModalOpen] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState();
  const { t } = useTranslation();
  const { availableWallets } = useAvailableWallets();
  const { toggleSymbolBlocked } = useToggleSymbolBlocked();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (availableWallets.data?.length && !query.exchange) {
      changeQuery({ exchange: availableWallets.data[0].id });
    }
  }, [availableWallets.data, query.exchange]);

  const { data, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery({
    queryKey: ['adminExchangeSymbolsInfoInfinite', query],
    queryFn: ({ pageParam = 1 }) =>
      ApiService.getAdminExchangeSymbolsInfo({
        page: pageParam,
        limit,
        exchange: query.exchange,
        symbol: query.symbol,
        sortBy: query.sortBy,
        sortDir: query.sortDir,
        volumeFrom: query.volumeFrom,
        volumeTo: query.volumeTo,
      }),
    getNextPageParam: (lastPage, allPages) => (lastPage?.length === limit ? allPages.length + 1 : undefined),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
    enabled: !!query.exchange,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const selectedWallet = useMemo(() => availableWallets.data?.find((o) => o.id === query.exchange), [query.exchange, availableWallets.data]);

  const symbolsList = useMemo(() => data?.pages?.reduce((acc, current) => !!current && acc.concat(current), []) || [], [data?.pages]);

  const trendingViewSymbol = useMemo(() => {
    if (!selectedSymbol || !selectedWallet) return null;
    return `${selectedWallet.type.toUpperCase()}:${selectedSymbol}`;
  }, [selectedSymbol, selectedWallet]);

  const changeQuery = (query) => {
    setQuery((prevState) => ({ ...prevState, ...query }));
  };

  const showWidget = (symbol) => {
    setSelectedSymbol(symbol);
    setWidgetModalOpen(true);
  };

  const makeSearch = (s = search) => {
    changeQuery({ symbol: s });
  };

  const toggleSymbol = (symbol, blocked) => {
    toggleSymbolBlocked.mutateAsync({ exchange: query.exchange, symbol, blocked }).then(() => refetch());
  };

  const makeVolumeFrom = useDebouncedCallback(() => {
    changeQuery({ volumeFrom });
  }, 500);

  const makeVolumeTo = useDebouncedCallback(() => {
    changeQuery({ volumeTo });
  }, 500);

  const onVolumeFromChange = (value) => {
    setVolumeFrom(value);
    makeVolumeFrom();
  };

  const onVolumeToChange = (value) => {
    setVolumeTo(value);
    makeVolumeTo();
  };

  const isMobile = useIsMobile();

  const clearSerach = () => {
    setSearch('');
    makeSearch('');
  };

  return (
    <div className="admin-page">
      <div className="admin-page__header">
        <h3 className="admin-page__title">
          <FormControl fullWidth>
            <InputLabel id="wallet-select-label">{t('operations.filters.wallet')}</InputLabel>
            <Select
              labelId="wallet-select-label"
              label={t('operations.filters.wallet')}
              sx={{ width: '100%' }}
              value={query.exchange}
              onChange={(e) => changeQuery({ exchange: e.target.value })}
            >
              {availableWallets.data?.map((w) => (
                <MenuItem key={w.id} value={w.id}>
                  {w.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </h3>
        <div className="admin-page__filters">
          <div className="input__search">
            <TextField
              label={t('add-wallet.search')}
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => e.keyCode === 13 && makeSearch()}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment style={{ opacity: search ? 1 : 0 }} position="end">
                      <Icon icon="close" className="medium pointer hover" onClick={() => clearSerach()} />
                    </InputAdornment>
                    <InputAdornment position="end">
                      <Icon icon="search" className="medium pointer hover" onClick={() => makeSearch()} />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </div>
          {!!availableWallets.data?.length && (
            <MenuPopup icon="filter">
              <TextField
                type="number"
                label={t('analytics.filter.volume-from')}
                sx={{ width: '100%' }}
                value={volumeFrom}
                onChange={(e) => onVolumeFromChange(e.target.value)}
              />
              <TextField
                type="number"
                label={t('analytics.filter.volume-to')}
                sx={{ width: '100%' }}
                value={volumeTo}
                onChange={(e) => onVolumeToChange(e.target.value)}
              />
            </MenuPopup>
          )}
        </div>
      </div>
      <div className="content analytics-page__content">
        {!isMobile ? (
          <table className="table-shadow">
            <thead>
              <tr>
                {headerCells.map((c) => (
                  <th key={c.name}>
                    <TableSortLabel
                      active={query.sortBy === c.name}
                      direction={query.sortBy === c.name ? query.sortDir : 'asc'}
                      onClick={() =>
                        changeQuery({
                          sortBy: c.name,
                          sortDir: query.sortBy === c.name && query.sortDir === 'asc' ? 'desc' : 'asc',
                        })
                      }
                    >
                      {t(c.label)}
                    </TableSortLabel>
                  </th>
                ))}
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {symbolsList?.map((s, i) => (
                <tr key={s.id} ref={i === symbolsList?.length - 5 ? ref : undefined}>
                  <td>
                    {JSON.stringify(ref)}
                    <div className="name">
                      <img src={process.env.REACT_APP_ICONS_SERVER + s.symbol?.toLowerCase()?.split('usdt')?.[0]} alt="" />
                      {s.symbol}
                    </div>
                  </td>
                  <td>
                    $
                    <NativeCurrencyFormatWrapper value={s.low_price} isUsd={false} />
                  </td>
                  <td className={`${s.price_change_percent < 0 ? 'c-red' : 'c-green'}`}>
                    <NativeCurrencyFormatWrapper value={s.price_change_percent} isUsd={true} />%
                  </td>
                  <td>
                    $
                    <NativeCurrencyFormatWrapper value={s.quote_volume} isUsd={false} />
                  </td>
                  <td>
                    <IconButton onClick={() => showWidget(s.symbol)}>
                      <Icon icon="chart-btn" />
                    </IconButton>
                  </td>
                  <td align="right">
                    <Button
                      sx={{ textTransform: 'capitalize' }}
                      onClick={() => toggleSymbol(s.symbol, !s.blocked)}
                      disabled={s.status !== 'TRADING' || !s.is_spot_trading_allowed}
                    >
                      {s.blocked || s.status !== 'TRADING' || !s.is_spot_trading_allowed ? t('admin.analytics.unblock') : t('admin.analytics.block')}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <table className="table-shadow">
            <thead>
              <tr>
                <th>{t('analytics.header.symbol')}</th>
                <th>{t('analytics.header.low-price')}</th>
                <th>{t('analytics.header.quote-volume')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {symbolsList?.map((s, i) => (
                <tr key={s.id} ref={i === symbolsList?.length - 5 ? ref : undefined}>
                  <td>
                    <div className="name">
                      <img src={process.env.REACT_APP_ICONS_SERVER + s.symbol?.toLowerCase()?.split('usdt')?.[0]} alt="" />
                      {s.symbol}
                    </div>
                  </td>
                  <td>
                    <p>
                      $
                      <NativeCurrencyFormatWrapper value={s.low_price} isUsd={false} />
                    </p>
                    <p className={`${s.price_change_percent < 0 ? 'c-red' : 'c-green'}`}>
                      {' '}
                      <NativeCurrencyFormatWrapper value={s.price_change_percent} isUsd={true} />%
                    </p>
                  </td>
                  <td>
                    $
                    <NativeCurrencyFormatWrapper value={s.quote_volume} isUsd={false} />
                  </td>
                  <td>
                    <MenuPopup>
                      <Button variant="outlined" onClick={() => showWidget(s.symbol)} endIcon={<Icon icon="chart-btn" />}>
                        {t('bots.add-bot.tab.graph')}
                      </Button>
                      <Button
                        variant="contained"
                        color={s.blocked || s.status !== 'TRADING' || !s.is_spot_trading_allowed ? 'primary' : 'warning'}
                        onClick={() => toggleSymbol(s.symbol, !s.blocked)}
                        disabled={s.status !== 'TRADING' || !s.is_spot_trading_allowed}
                      >
                        {s.blocked || s.status !== 'TRADING' || !s.is_spot_trading_allowed ? t('admin.analytics.unblock') : t('admin.analytics.block')}
                      </Button>
                    </MenuPopup>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <AnalyticsWidgetModal
        open={widgetModalOpen}
        onClose={() => setWidgetModalOpen(false)}
        trendingViewSymbol={trendingViewSymbol}
        symbol={selectedSymbol}
        wallet={selectedWallet?.type}
      />
    </div>
  );
};
