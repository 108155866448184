import { useEffect, useState } from 'react';

export const useActiveTab = () => {
  const [active, setActive] = useState(true);

  const setState = () => {
    setActive(!document.hidden);
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', setState);
    return () => document.removeEventListener('visibilitychange', setState);
  }, []);

  return { active };
};
