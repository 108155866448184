import { useIsMobile } from '../components/utility/DetectDevice';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { BOT_REBALANCER_TYPE_OPTIONS } from '../constants/bot-rebalancer.types';
import { NativeDateLocaleWrapper } from '../components/utility/NativeDateLocaleWrapper';
import Big from 'big.js';
import { NativeCurrencyFormatWrapper } from '../components/utility/NativeCurrencyFormatWrapper';
import { FormControl, IconButton, InputLabel, MenuItem, Select, TableSortLabel, TextField } from '@mui/material';
import MenuPopup from '../components/utility/MenuPopup';
import { useGetTradeBotsStatistics } from '../hooks/http-requests/useGetTradeBotsStatistics';
import { useGetRebalancerBotsStatistics } from '../hooks/http-requests/useGetRebalancerBotsStatistics';
import Icon from '../components/Icons';
import { DifferenceBetweenDates } from '../components/utility/DifferenceBetweenDates';
import { botOptions, botStatusOptions, botTypeOptions, filterBots, getSortFunction } from '../utils/bot-statistic-helpers';
import { useAvailableWallets } from '../hooks/http-requests/useAvailableWallets';
import { useUserWallets } from '../hooks/http-requests/useUserWallets';
import { Clear } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';

export const BotStatistics = () => {
  const [filter, setFilter] = useState({
    isDemo: 'all',
    tag: '',
    type: '',
    coin: '',
    status: '',
  });
  const [sortBy, setSortBy] = useState('profit');
  const [sortDir, setSortDir] = useState('desc');
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { tradeBotsStatistics } = useGetTradeBotsStatistics();
  const { rebalancerBotsStatistics } = useGetRebalancerBotsStatistics();
  const { availableWallets } = useAvailableWallets();
  const { userWallets } = useUserWallets();

  const walletOptions = useMemo(() => {
    return userWallets.data?.map((w) => {
      const wallet = availableWallets.data?.find((aw) => aw.id === w.exchange_id);
      return {
        ...w,
        name: wallet?.name,
        tag: wallet?.tag,
      };
    });
  }, [availableWallets.data, userWallets.data]);

  const getBotType = (bot) => {
    return bot.key.includes('multi')
      ? t(BOT_REBALANCER_TYPE_OPTIONS.find((o) => o.value === bot?.type)?.label)
      : t(`bots.widget.type.${bot.bot_type === 'investment' ? 'invest' : bot.type}`);
  };

  const allBots = useMemo(() => {
    if (!tradeBotsStatistics.data || !rebalancerBotsStatistics.data) return;
    const tradeBotsList = tradeBotsStatistics.data.map((b) => ({
      ...b,
      key: `${b.id}-bot`,
    }));
    const multiBotsList = rebalancerBotsStatistics.data.map((b) => ({
      ...b,
      key: `${b.id}-multi-bot`,
    }));
    return tradeBotsList.concat(multiBotsList).map((b) => ({
      id: b.id,
      key: b.key,
      profit: b.key.includes('multi') ? b.profit : b.profit_bot,
      fund: b.fund,
      tag: b.tag,
      type: b.bot_type,
      coin: b.key.includes('multi') ? '-' : b.symbol,
      name: b.name,
      created_at: b.created_at,
      updated_at: b.updated_at,
      deleted_at: b.deleted_at,
      emulate_operations: b.emulate_operations,
      status: b.status,
    }));
  }, [tradeBotsStatistics.data, rebalancerBotsStatistics.data]);

  const allBotsFiltered = useMemo(() => {
    if (!allBots) return;
    const filteredBots = filterBots(allBots, filter);
    const sortFn = getSortFunction(sortBy);
    return filteredBots.slice().sort((a, b) => {
      const result = sortFn(a, b);
      return sortDir === 'asc' ? result * -1 : result;
    });
  }, [allBots, sortBy, sortDir, filter]);

  const symbolOptions = useMemo(() => [...new Set(allBots?.filter((b) => b.coin !== '-')?.map((b) => b.coin))], [allBots]);

  const botsProfit = useMemo(() => {
    if (!allBotsFiltered) return 0;
    return allBotsFiltered.reduce((acc, b) => acc.plus(Big(b.profit || 0)), Big(0)).toNumber();
  }, [allBotsFiltered]);

  const botsFund = useMemo(() => allBotsFiltered?.reduce((acc, b) => acc.plus(b.fund), Big(0))?.toNumber() || 0, [allBotsFiltered]);

  const countFilter = useMemo(
    () =>
      filter.isDemo !== 'all' ||
      !!filter.tag ||
      !!filter.type ||
      !!filter.coin ||
      !!filter.min_created_at ||
      !!filter.max_created_at ||
      !!filter.min_updated_at ||
      !!filter.max_updated_at ||
      !!filter.status ||
      !!filter.min_fund ||
      !!filter.max_fund ||
      !!filter.min_profit ||
      !!filter.max_profit,
    [filter]
  );

  const changeSort = (sort_by) => {
    setSortDir(sort_by === sortBy && sortDir === 'desc' ? 'asc' : 'desc');
    setSortBy(sort_by);
  };

  return (
    <>
      <div className="operations-page__header no-print">
        <h3 className="operations-page__title">{t('nav.statistics')}</h3>
        <div className="operations-page__filters">
          <MenuPopup icon="filter" count={countFilter}>
            <FormControl fullWidth>
              <InputLabel id="demo-filter-label">{t('statistics.filter.mode')}</InputLabel>
              <Select
                labelId="demo-filter-label"
                label={t('statistics.filter.mode')}
                value={filter.isDemo}
                onChange={(e) => setFilter({ ...filter, isDemo: e.target.value })}
              >
                {botOptions.map((b) => (
                  <MenuItem key={b.value} value={b.value}>
                    {t(b.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="status-filter-label">{t('bots.list.status')}</InputLabel>
              <Select
                labelId="status-filter-label"
                label={t('bots.list.status')}
                value={filter.status}
                onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                endAdornment={
                  <IconButton sx={{ display: filter.status ? '' : 'none' }} onClick={() => setFilter({ ...filter, status: '' })}>
                    <Clear />
                  </IconButton>
                }
              >
                {botStatusOptions?.map((w) => (
                  <MenuItem key={w.value} value={w.value}>
                    {t(w.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="exchange-filter-label">{t('operations.filters.wallet')}</InputLabel>
              <Select
                labelId="exchange-filter-label"
                label={t('operations.filters.wallet')}
                value={filter.tag}
                onChange={(e) => setFilter({ ...filter, tag: e.target.value })}
                endAdornment={
                  <IconButton sx={{ display: filter.tag ? '' : 'none' }} onClick={() => setFilter({ ...filter, tag: '' })}>
                    <Clear />
                  </IconButton>
                }
              >
                {walletOptions?.map((w) => (
                  <MenuItem key={w.id} value={w.tag}>
                    {w.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="type-filter-label">{t('statistics.bot-type')}</InputLabel>
              <Select
                labelId="type-filter-label"
                label={t('statistics.bot-type')}
                value={filter.type}
                onChange={(e) => setFilter({ ...filter, type: e.target.value })}
                endAdornment={
                  <IconButton sx={{ display: filter.type ? '' : 'none' }} onClick={() => setFilter({ ...filter, type: '' })}>
                    <Clear />
                  </IconButton>
                }
              >
                {botTypeOptions?.map((o) => (
                  <MenuItem key={o.value} value={o.value}>
                    {t(o.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="symbol-filter-label">{t('operations.filters.coin')}</InputLabel>
              <Select
                labelId="symbol-filter-label"
                label={t('operations.filters.coin')}
                value={filter.coin}
                onChange={(e) => setFilter({ ...filter, coin: e.target.value })}
                endAdornment={
                  <IconButton sx={{ display: filter.coin ? '' : 'none' }} onClick={() => setFilter({ ...filter, coin: '' })}>
                    <Clear />
                  </IconButton>
                }
              >
                {symbolOptions?.map((o) => (
                  <MenuItem key={o} value={o}>
                    {o}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DatePicker
              label={t('statistics.filter.min-started-at')}
              sx={{ width: '100%' }}
              views={['year', 'month', 'day']}
              value={filter.min_created_at}
              slotProps={{
                field: {
                  clearable: true,
                  onClear: () => setFilter({ ...filter, min_created_at: undefined }),
                },
              }}
              onChange={(from) => setFilter({ ...filter, min_created_at: from })}
            />
            <DatePicker
              label={t('statistics.filter.max-started-at')}
              sx={{ width: '100%' }}
              views={['year', 'month', 'day']}
              value={filter.max_created_at}
              slotProps={{
                field: {
                  clearable: true,
                  onClear: () => setFilter({ ...filter, max_created_at: undefined }),
                },
              }}
              onChange={(to) => setFilter({ ...filter, max_created_at: to })}
            />
            <DatePicker
              label={t('statistics.filter.min-closed-at')}
              sx={{ width: '100%' }}
              views={['year', 'month', 'day']}
              value={filter.min_updated_at}
              slotProps={{
                field: {
                  clearable: true,
                  onClear: () => setFilter({ ...filter, min_updated_at: undefined }),
                },
              }}
              onChange={(from) => setFilter({ ...filter, min_updated_at: from })}
            />
            <DatePicker
              label={t('statistics.filter.max-closed-at')}
              sx={{ width: '100%' }}
              views={['year', 'month', 'day']}
              value={filter.max_updated_at}
              slotProps={{
                field: {
                  clearable: true,
                  onClear: () => setFilter({ ...filter, max_updated_at: undefined }),
                },
              }}
              onChange={(to) => setFilter({ ...filter, max_updated_at: to })}
            />
            <TextField
              type="number"
              label={t('statistics.filter.min-fund')}
              sx={{ width: '100%' }}
              value={filter.min_fund || ''}
              onChange={(e) => setFilter({ ...filter, min_fund: e.target.value })}
            />
            <TextField
              type="number"
              label={t('statistics.filter.max-fund')}
              sx={{ width: '100%' }}
              value={filter.max_fund || ''}
              onChange={(e) => setFilter({ ...filter, max_fund: e.target.value })}
            />
            <TextField
              type="number"
              label={t('statistics.filter.min_profit')}
              sx={{ width: '100%' }}
              value={filter.min_profit || ''}
              onChange={(e) => setFilter({ ...filter, min_profit: e.target.value })}
            />
            <TextField
              type="number"
              label={t('statistics.filter.max_profit')}
              sx={{ width: '100%' }}
              value={filter.max_profit || ''}
              onChange={(e) => setFilter({ ...filter, max_profit: e.target.value })}
            />
          </MenuPopup>
          <IconButton onClick={() => window.print()}>
            <Icon icon="download" />
          </IconButton>
        </div>
      </div>
      {!isMobile ? (
        <table className="table-shadow table-small">
          <thead>
            <tr>
              <th>
                <TableSortLabel active={sortBy === 'tag'} direction={sortBy === 'tag' ? sortDir : 'desc'} onClick={() => changeSort('tag')}>
                  {t('operations.filters.wallet')}
                </TableSortLabel>
              </th>
              <th>
                <TableSortLabel active={sortBy === 'type'} direction={sortBy === 'type' ? sortDir : 'desc'} onClick={() => changeSort('type')}>
                  {t('statistics.bot-type')}
                </TableSortLabel>
              </th>
              <th>
                <TableSortLabel active={sortBy === 'symbol'} direction={sortBy === 'symbol' ? sortDir : 'symbol'} onClick={() => changeSort('symbol')}>
                  {t('operations.filters.coin')}
                </TableSortLabel>
              </th>
              <th>
                <TableSortLabel active={sortBy === 'name'} direction={sortBy === 'name' ? sortDir : 'name'} onClick={() => changeSort('name')}>
                  {t('bots.add-bot.name')}
                </TableSortLabel>
              </th>
              <th>
                <TableSortLabel
                  active={sortBy === 'created_at'}
                  direction={sortBy === 'created_at' ? sortDir : 'desc'}
                  onClick={() => changeSort('created_at')}
                >
                  {t('bots.summary-widget.start-date')}
                </TableSortLabel>
              </th>
              <th>
                <TableSortLabel active={sortBy === 'end_date'} direction={sortBy === 'end_date' ? sortDir : 'desc'} onClick={() => changeSort('end_date')}>
                  {t('statistics.end-date')}
                </TableSortLabel>
              </th>
              <th>{t('bots.widget.created-bot')}</th>
              <th>{t('bots.list.status')}</th>
              <th className="a-right">
                <TableSortLabel
                  sx={{ flexDirection: 'row-reverse' }}
                  active={sortBy === 'fund'}
                  direction={sortBy === 'fund' ? sortDir : 'desc'}
                  onClick={() => changeSort('fund')}
                >
                  {t('admin.users.capital')}
                </TableSortLabel>
              </th>
              <th className="a-right">
                <TableSortLabel
                  sx={{ flexDirection: 'row-reverse' }}
                  active={sortBy === 'prof_perc'}
                  direction={sortBy === 'prof_perc' ? sortDir : 'prof_perc'}
                  onClick={() => changeSort('prof_perc')}
                >
                  {t('admin.users.profit')}
                </TableSortLabel>
              </th>
              <th className="a-right" onClick={() => changeSort('profit')}>
                <TableSortLabel
                  sx={{ flexDirection: 'row-reverse' }}
                  active={sortBy === 'profit'}
                  direction={sortBy === 'profit' ? sortDir : 'desc'}
                  onClick={() => changeSort('profit')}
                >
                  {t('admin.users.profit')}
                </TableSortLabel>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="-hover bold">
              <td>{t('common.total')}</td>
              <td colSpan={9} />
              {/* <td className="a-right">$<NativeCurrencyFormatWrapper value={botsFund} isUsd={true} /></td>
               <td className="a-right">{botsProfit && botsFund ? ((botsProfit / botsFund) * 100).toFixed(3) : 0}%</td> */}
              <td className="a-right">
                $
                <NativeCurrencyFormatWrapper value={botsProfit} isUsd={true} />
              </td>
            </tr>
            {allBotsFiltered?.map((b) => (
              <tr className={`${b.emulate_operations ? '-test' : ''} ${b.deleted_at ? 'opacity-05' : ''}`} key={b.key}>
                <td style={{ textTransform: 'capitalize' }}>{b.tag}</td>
                <td style={{ maxWidth: 80 }}>{getBotType(b)}</td>
                <td>{b?.coin?.replace('USDT', ' / USDT')}</td>
                <td>{b.name}</td>
                <td>
                  <NativeDateLocaleWrapper
                    date={b.created_at}
                    options={{
                      day: '2-digit',
                      month: 'numeric',
                      year: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    }}
                  />
                </td>
                <td>
                  {b.status === 'disabled' || b.status === 'cancelled' ? (
                    <NativeDateLocaleWrapper
                      date={b.updated_at}
                      options={{
                        day: '2-digit',
                        month: 'numeric',
                        year: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                      }}
                    />
                  ) : (
                    '-'
                  )}
                </td>
                <td>{b.created_at ? <DifferenceBetweenDates date={b.created_at} lastDate={b.status !== 'active' ? b.updated_at : undefined} /> : '-'}</td>
                <td>{t(`bots.list.status.${!!b?.deleted_at ? 'deleted' : b?.status}`)}</td>
                <td className="a-right">
                  $<NativeCurrencyFormatWrapper value={b.fund} isUsd={false} />
                </td>
                <td className="a-right">{((b.profit / b.fund) * 100).toFixed(3)}%</td>
                <td className="a-right">
                  $
                  <NativeCurrencyFormatWrapper value={b.profit} isUsd={true} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className="table-shadow">
          <tbody>
            <tr>
              <td>
                <div className="info-line">
                  <p>{t('common.total')}</p>
                </div>
                {/* <div className="info-line">
                  <p>{t('admin.users.capital')}</p>
                  <p>
                    $
                    <NativeCurrencyFormatWrapper value={botsFund} isUsd={true} />
                  </p>
                </div> */}
                {/* <div className="info-line">
                  <p>{t('admin.users.profit')}</p>
                  <p>{botsProfit && botsFund ? ((botsProfit / botsFund) * 100).toFixed(3) : 0}%</p>
                </div> */}
                <div className="info-line">
                  <p>{t('admin.users.profit')}</p>
                  <p>
                    $
                    <NativeCurrencyFormatWrapper value={botsProfit} isUsd={true} />
                  </p>
                </div>
              </td>
            </tr>
            {allBotsFiltered?.map((b) => (
              <tr key={b.key} className={`${b.emulate_operations ? '-test' : ''} ${b.deleted_at ? 'opacity-05' : ''}`}>
                <td>
                  <div className="info-line">
                    <p>{t('operations.filters.wallet')}</p>
                    <p style={{ textTransform: 'capitalize' }}>{b.tag}</p>
                  </div>
                  <div className="info-line">
                    <p>{t('statistics.bot-type')}</p>
                    <p>{getBotType(b)}</p>
                  </div>
                  <div className="info-line">
                    <p>{t('operations.filters.coin')}</p>
                    <p>{b.coin}</p>
                  </div>
                  <div className="info-line">
                    <p>{t('bots.summary-widget.start-date')}</p>
                    <p>
                      <NativeDateLocaleWrapper
                        date={b.created_at}
                        options={{
                          day: '2-digit',
                          month: 'short',
                          hour: '2-digit',
                          minute: '2-digit',
                        }}
                      />
                    </p>
                  </div>
                  <div className="info-line">
                    <p>{t('statistics.end-date')}</p>
                    <p>
                      {b.status === 'disabled' || b.status === 'cancelled' ? (
                        <NativeDateLocaleWrapper
                          date={b.updated_at}
                          options={{
                            day: '2-digit',
                            month: 'short',
                            hour: '2-digit',
                            minute: '2-digit',
                          }}
                        />
                      ) : (
                        '-'
                      )}
                    </p>
                  </div>

                  <div className="info-line">
                    <p>{t('bots.widget.created-bot')}</p>
                    <p>{b.created_at ? <DifferenceBetweenDates date={b.created_at} lastDate={b.status !== 'active' ? b.updated_at : undefined} /> : '-'}</p>
                  </div>
                  <div className="info-line">
                    <p>{t('bots.list.status')}</p>
                    <p>{t(`bots.list.status.${!!b?.deleted_at ? 'deleted' : b?.status}`)}</p>
                  </div>
                  <div className="info-line">
                    <p>{t('admin.users.capital')}</p>
                    <p>
                      $
                      <NativeCurrencyFormatWrapper value={b.fund} isUsd={true} />
                    </p>
                  </div>
                  <div className="info-line">
                    <p>{t('admin.users.profit')}</p>
                    <p className={b.profit > 0 ? 'c-green' : 'c-red'}>{((b.profit / b.fund) * 100).toFixed(3)}%</p>
                  </div>
                  <div className="info-line">
                    <p>{t('admin.users.profit')}</p>
                    <p className={b.profit > 0 ? 'c-green' : 'c-red'}>
                      $
                      <NativeCurrencyFormatWrapper value={b.profit} isUsd={true} />
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};
