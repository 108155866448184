import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';
import { useState } from 'react';

export const useGetMultiBots = () => {
  const [filter, setFilter] = useState({ search: '', wallet: '' });

  const result = useQuery({
    queryKey: ['multibots/list', filter],
    queryFn: () => ApiService.getMultiBots(filter),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: 'always',
    staleTime: Infinity,
    cacheTime: 0,
    refetchInterval: 30000,
  });

  return { result, filter, setFilter };
};
