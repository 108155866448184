import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  MenuItem,
  Skeleton,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { TradingViewWidget } from '../../components/utility/TradingViewWidget';
import { useAvailableWallets } from '../../hooks/http-requests/useAvailableWallets';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setNestedObjectValues, useFormik } from 'formik';
import { useWalletCoinsList } from '../../hooks/http-requests/useWalletCoinsList';
import { useCreateBot } from '../../hooks/http-requests/useCreateBot';
import { addBotValidation } from '../../constants/validation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUserWallets } from '../../hooks/http-requests/useUserWallets';
import { BOT_TYPES, isBotTypeValid } from '../../constants/bot-types';
import { BOT_REBALANCER_TYPE_OPTIONS, getBotRebalancerMinFund } from '../../constants/bot-rebalancer.types';
import { useCreateMultiBot } from '../../hooks/http-requests/useCreateMultiBot';
import { useBalanceInfo } from '../../hooks/http-requests/useBalanceInfo';
import { useGetTradeBots } from '../../hooks/http-requests/useGetTradeBots';
import { ConfirmModal } from '../../components/ConfirmModal';
import { useGetUser } from '../../hooks/http-requests/useGetUser';
import PercentPriceBlock from '../../components/PercentPriceBlock';
import { useCreateEmulations } from '../../hooks/http-requests/useCreateEmulations';
import { useCreateBotPortfolio } from '../../hooks/http-requests/useCreateBotPortfolio';

export const AddBot = ({ onClose, type, exchange, symbol }) => {
  const { availableWallets } = useAvailableWallets();
  const { userWallets } = useUserWallets();
  const { coinsList, setId } = useWalletCoinsList();
  const { emulation, setEmulationData } = useCreateEmulations();
  const { createBot } = useCreateBot();
  const { createBotPortfolio } = useCreateBotPortfolio();
  const { createMultiBot } = useCreateMultiBot();
  const { user } = useGetUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isError, setIsError] = useState();
  const [amount, setAmount] = useState();
  const [formAmount, setFormAmount] = useState();
  const [placeholderFund, setPlaceholderFund] = useState();
  const [modalConfirmDelOpen, setModalConfirmDelOpen] = useState(false);

  const tabs = [
    { name: '1-step', title: t('bots.add-bot.tab.settings') },
    { name: '2-step', title: t('bots.add-bot.tab.other.settings') },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]?.name);

  const formik = useFormik({
    initialValues: {
      emulateOperations: false,
      name: '',
      type: type || searchParams.get('type') || 'reseller',
      user_data_id: exchange ? +exchange : searchParams.get('exchange') ? +searchParams.get('exchange') : '',
      symbol: symbol || searchParams.get('symbol') || undefined,
      fund: undefined,
      drawdown: (type || searchParams.get('type')) !== 'reseller' ? '0' : '',
      isStopLoss: false,
      stopLoss: undefined,
      profit: undefined,
      isExitAfterProfit: false,
      isReinvest: false,
      rebalancerType: '',
      useRsi: false,
      usdtAmount: 0,
      freeTariff: false,
    },
    onSubmit: () => {},
    validationSchema: addBotValidation,
  });

  const { balanceInfo } = useBalanceInfo(formik.values.user_data_id);
  const { bots } = useGetTradeBots();
  const botsFund = useMemo(
    () =>
      bots.data
        ?.filter((b) => b.status === 'active' && b.status === 'ready' && !b.emulate_operations && b.user_data_id === formik.values.user_data_id)
        ?.reduce((sum, b) => sum + b.fund + b.profit_bot, 0),
    [bots.data, formik.values.user_data_id]
  );

  const freeTariff = useMemo(() => {
    if (user.data) {
      if (user.data.tariff_id === 1) {
        formik.setFieldValue('emulateOperations', true);
        return true;
      }
      return !!(user.data.tariff_end_at && new Date(user?.data?.tariff_end_at) < new Date());
    } else {
      return false;
    }
  }, [user.data]);

  useEffect(() => {
    setAmount(balanceInfo.data?.find((d) => d.coin === 'USDT')?.amount);

    if (amount !== undefined && botsFund != null && user.data) {
      // const formAmount = freeTariff ? (amount > 300 ? 300 : amount) - botsFund : Math.floor(amount - botsFund);
      setFormAmount(Math.floor(amount - botsFund));

      formik.setFieldValue('usdtAmount', formAmount);
      // formik.setFieldValue('freeTariff', !!freeTariff && amount > 300 ? true : false);
    } else setFormAmount(0);
    formik.setFieldValue('usdtAmount', formAmount);

    let min = 100;
    let max = 2000;

    // if (BOT_TYPES.investment === formik.values.type) {
    //   min = 100;
    // }

    if (BOT_TYPES.rebalancer === formik.values.type) {
      min = getBotRebalancerMinFund(formik.values.rebalancerType);
      max = 10000;
    }

    setPlaceholderFund(
      `${Intl.NumberFormat('ru-RU', {
        notation: 'standard',
        maximumFractionDigits: 2,
      }).format(min)} - ${Intl.NumberFormat('ru-RU', {
        notation: 'standard',
        maximumFractionDigits: 2,
      }).format(max)} USDT`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceInfo.data, botsFund, user.data, formik.values, freeTariff]);

  const walletOptions = useMemo(() => {
    if (freeTariff) {
      return [
        {
          id: +process.env.REACT_APP_BINANCE_USER_ID,
          name: 'Demo Binance',
          status: 'active',
          tag: 'binance',
          type: 'BINANCE',
          exchange_id: +process.env.REACT_APP_BINANCE_ID,
        },
        {
          id: +process.env.REACT_APP_BYBIT_USER_ID,
          name: 'Demo Bybit',
          status: 'active',
          tag: 'bybit',
          type: 'bybit',
          exchange_id: +process.env.REACT_APP_BYBIT_ID,
        },
      ];
    }
    return userWallets.data?.map((w) => {
      const wallet = availableWallets.data?.find((aw) => aw.id === w.exchange_id);
      return {
        ...w,
        name: wallet?.name,
        type: wallet?.type,
      };
    });
  }, [availableWallets.data, userWallets.data, freeTariff]);

  const selectedWallet = useMemo(() => walletOptions?.find((o) => o.id === formik.values.user_data_id), [formik.values.user_data_id, walletOptions]);

  const drawdownOptions = useMemo(() => {
    // let maxDrawdown = (Math.floor(Math.log2(formik.values.fund / 25)) + 5) * 5;
    let maxDrawdown = 30;
    let options = [];
    while (maxDrawdown > 0) {
      options.push(maxDrawdown);
      maxDrawdown -= 2.5;
    }
    return options
      .slice()
      .reverse()
      .map((o) => o.toString());
  }, [formik.values.fund]);

  useEffect(() => {
    if (formik.values.user_data_id && selectedWallet?.exchange_id) {
      setId(selectedWallet?.exchange_id);
    }
  }, [formik.values.user_data_id, setId, selectedWallet]);

  useEffect(() => {
    if ((type || searchParams.get('type')) === 'investment') return;
    if ((type || searchParams.get('type')) === 'portfolio') return;
    formik.setValues({ ...formik.values, drawdown: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.fund, searchParams, type]);

  const submitOnClick = () => {
    if (formik.values.emulateOperations) {
      submit();
    } else {
      setModalConfirmDelOpen(true);
    }
  };

  const nextStep = () => {
    formik.validateForm().then((values) => {
      if (Object.keys(values).length > 0) {
        formik.setTouched(setNestedObjectValues(values, true));
        return;
      }
      setActiveTab('2-step');
    });
  };

  const submit = () => {
    formik.validateForm().then((values) => {
      if (Object.keys(values).length > 0) {
        formik.setTouched(setNestedObjectValues(values, true));
        return;
      }

      const payload = {
        ...formik.values,
        drawdown: +formik.values.drawdown,
        fund: +formik.values.fund,
        stopLoss: +formik.values.stopLoss,
        profit: +formik.values.profit,
        exchangeId: selectedWallet?.exchange_id,
      };

      switch (formik.values.type) {
        case BOT_TYPES.rebalancer:
          createMultiBot
            .mutateAsync(payload)
            .then(() => {
              navigate('/dashboard/bots/rebalancer');
              onClose();
            })
            .catch(() => setIsError(true));
          break;

        case BOT_TYPES.portfolio:
          createBotPortfolio
            .mutateAsync(payload)
            .then(() => {
              navigate('/dashboard/bots/portfolio');
              onClose();
            })
            .catch(() => setIsError(true));
          break;

        default:
          createBot
            .mutateAsync(payload)
            .then(() => {
              navigate('/dashboard/bots/trade');
              onClose();
            })
            .catch(() => setIsError(true));
          break;
      }
    });
  };

  const trendingViewSymbol = useMemo(() => {
    if (!formik.values.user_data_id || !formik.values.symbol || !selectedWallet) return null;
    return `${selectedWallet.type.toUpperCase()}:${formik.values.symbol}`;
  }, [formik.values.user_data_id, formik.values.symbol, selectedWallet]);

  const isPortfolio = formik.values.type === BOT_TYPES.portfolio;

  const showCoinsList = !!coinsList.data?.length && formik.values.type !== BOT_TYPES.rebalancer;

  const isDrawDownOptionAvailable =
    formik.values.type !== BOT_TYPES.rebalancer && formik.values.type !== BOT_TYPES.investment && !!drawdownOptions?.length && !isPortfolio;

  const isShowEmulationsBlock = !!formik.values.drawdown;

  const isStopLossOptionAvailable = formik.values.type !== BOT_TYPES.rebalancer && formik.values.type !== BOT_TYPES.investment;

  const isExitAfterProfitOptionAvailable = formik.values.type !== BOT_TYPES.rebalancer;

  const isReinvestmentOptionAvailable = formik.values.type !== BOT_TYPES.rebalancer;

  const isRebalancerOptionsAvailable = formik.values.type === BOT_TYPES.rebalancer;

  useEffect(() => {
    if (isShowEmulationsBlock) {
      const exchenge = walletOptions?.find((w) => w.id === formik.values.user_data_id)?.type;
      const symbol = formik.values.symbol;
      const start_capital = +formik.values.fund;
      const prosadka = +formik.values.drawdown;

      if (!exchenge || !symbol || !start_capital || !prosadka) return;

      setEmulationData({
        exchenge,
        symbol,
        start_capital,
        prosadka,
        reinvest: formik.values.isReinvest ? 1 : 0,
        use_rsi: formik.values.useRsi ? 1 : 0,
        luft_percent: 0.2,
        strategy: 'reseller',
      });
    }
  }, [
    setEmulationData,
    isShowEmulationsBlock,
    walletOptions,
    formik.values.user_data_id,
    formik.values.symbol,
    formik.values.fund,
    formik.values.drawdown,
    formik.values.isReinvest,
    formik.values.useRsi,
    formik.values.type,
  ]);

  useEffect(() => {
    if (!isBotTypeValid(formik.values.type)) {
      navigate('/dashboard/bots/trade');
    }
  }, [formik.values.type, navigate]);

  const navigateTabs = (_, value) => {
    setActiveTab(value);
  };

  return (
    <>
      <ConfirmModal
        open={modalConfirmDelOpen}
        onCancel={() => setModalConfirmDelOpen(false)}
        title={t('bots.add.confirm.title')}
        text={
          <>
            {t('bots.add.confirm.text')}
            <p className="description mt-16">{t('bots.add.notice')}</p>
          </>
        }
        onOK={() => {
          submit();
          setModalConfirmDelOpen(false);
        }}
      />
      <Grid container spacing={3}>
        <Tabs value={activeTab} onChange={navigateTabs} sx={{ margin: '0 24px' }} variant="scrollable" scrollButtons="auto">
          {tabs.map((t, i) => (
            <Tab key={t.name} label={t.title} value={t.name} sx={{ textTransform: 'inherit', fontSize: 16 }} disabled={!trendingViewSymbol && i !== 0} />
          ))}
        </Tabs>

        {/* tab 1 */}
        {activeTab === tabs[0].name && (
          <Grid item xs={12} sm={12}>
            <Box component="form" onSubmit={formik.submitForm}>
              {/* Демо бот */}
              <FormControlLabel
                sx={{ display: 'flex', mb: 3 }}
                control={
                  <Checkbox
                    disabled={freeTariff}
                    checked={formik.values.emulateOperations}
                    onChange={(e) => formik.setFieldValue('emulateOperations', e.target.checked)}
                  />
                }
                label={
                  <>
                    <Typography variant="body1">{t('bots.add-bot.emulate-operations')}</Typography>
                    <Typography variant="subtitle2">{t('bots.add-bot.emulate-operations.description')}</Typography>
                  </>
                }
              />

              {/* Название бота */}
              <TextField
                name="name"
                label={t('bots.add-bot.name')}
                sx={{ width: '100%' }}
                value={formik.values.name}
                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                error={formik.touched.name ? !!formik.errors.name : false}
                helperText={formik.touched.name ? formik.errors.name : undefined}
                onBlur={formik.handleBlur}
              />

              {/* Биржа */}
              <TextField
                select
                name="user_data_id"
                label={t('operations.filters.wallet')}
                sx={{ width: '100%', mt: 3 }}
                value={formik.values.user_data_id}
                onChange={(e) => formik.setFieldValue('user_data_id', e.target.value)}
                error={formik.touched.user_data_id ? !!formik.errors.user_data_id : false}
                helperText={formik.touched.user_data_id ? formik.errors.user_data_id : undefined}
                onBlur={formik.handleBlur}
              >
                {walletOptions?.map((w) => (
                  <MenuItem key={w.id} value={w.id}>
                    {w.name}
                  </MenuItem>
                ))}
              </TextField>

              {/* прелоадер бирж */}
              {coinsList.isInitialLoading && <Skeleton variant="rectangular" style={{ marginTop: 24 }} height={48} />}

              {/* спсиок монет */}
              <Fade in={!!showCoinsList} unmountOnExit>
                <Autocomplete
                  sx={{ width: '100%', mt: 3 }}
                  value={formik.values.symbol}
                  onChange={(_, value) => formik.setFieldValue('symbol', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="symbol"
                      label={t('operations.filters.coin')}
                      error={formik.touched.symbol ? !!formik.errors.symbol : false}
                      helperText={formik.touched.symbol ? formik.errors.symbol : undefined}
                      onBlur={formik.handleBlur}
                    />
                  )}
                  options={coinsList?.data?.map((c) => c.symbol).sort() || []}
                />
              </Fade>

              {/* график и история */}
              <Fade in={!!trendingViewSymbol} unmountOnExit>
                <div>
                  {/* история цены */}
                  <div className="mt-16">
                    <div className="mb-10 fs-14 fw-500">{t('bots.add-bot.draw-price')}</div>
                    <PercentPriceBlock symbol={formik.values.symbol} wallet={selectedWallet?.type} />
                  </div>

                  {/* график */}
                  <div className="mt-16">
                    <div className="mb-10 fs-14 fw-500">{t('bots.add-bot.tab.graph')}</div>
                    <div className="chart-wrap">
                      <TradingViewWidget range={'3M'} symbolProp={trendingViewSymbol} showDetails={false} height={500} />
                    </div>
                  </div>
                </div>
              </Fade>

              {/* топ для ребалансировщика */}
              {!!isRebalancerOptionsAvailable && !!selectedWallet && !coinsList.isInitialLoading && (
                <TextField
                  select
                  name="rebalancerType"
                  label={t('bots.add-bot.rebalancer-type')}
                  sx={{ width: '100%', mt: 3 }}
                  value={formik.values.rebalancerType}
                  onChange={(e) => formik.setFieldValue('rebalancerType', e.target.value)}
                  error={formik.touched.rebalancerType ? !!formik.errors.rebalancerType : false}
                  helperText={formik.touched.rebalancerType ? formik.errors.rebalancerType : undefined}
                  onBlur={formik.handleBlur}
                >
                  {BOT_REBALANCER_TYPE_OPTIONS.map((opt) => (
                    <MenuItem key={opt.label} value={opt.value}>
                      {t(opt.label)}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {/* капитал */}
              <Fade
                in={
                  (!!isRebalancerOptionsAvailable && !!formik.values.rebalancerType && !!selectedWallet) ||
                  (!isRebalancerOptionsAvailable && !!showCoinsList && !!formik.values.symbol)
                }
                unmountOnExit
              >
                <div>
                  <TextField
                    type="number"
                    name="fund"
                    label={t('bots.add-bot.fund')}
                    sx={{ width: '100%', mt: 3 }}
                    value={formik.values.fund}
                    onChange={(e) => formik.setFieldValue('fund', e.target.value)}
                    error={formik.touched.fund ? !!formik.errors.fund : false}
                    helperText={formik.touched.fund ? formik.errors.fund : undefined}
                    onBlur={formik.handleBlur}
                    placeholder={placeholderFund}
                  />
                  <Typography variant="subtitle2">{t(`bots.add-bot.fund.text${isPortfolio ? '.portfolio' : ''}`)}</Typography>
                </div>
              </Fade>

              {/* процент просадки */}
              <Fade in={!!isDrawDownOptionAvailable && !!trendingViewSymbol && !!formik.values.fund} unmountOnExit>
                <div>
                  <TextField
                    select
                    name="drawdown"
                    label={t('bots.add-bot.draw-down')}
                    sx={{ width: '100%', mt: 3 }}
                    value={formik.values.drawdown}
                    onChange={(e) => formik.setFieldValue('drawdown', e.target.value)}
                    error={formik.touched.drawdown ? !!formik.errors.drawdown : false}
                    helperText={formik.touched.drawdown ? formik.errors.drawdown : undefined}
                    onBlur={formik.handleBlur}
                  >
                    {drawdownOptions.map((o) => (
                      <MenuItem key={o} value={o}>
                        {o}%
                      </MenuItem>
                    ))}
                  </TextField>
                  <Typography variant="subtitle2">{t('bots.add-bot.draw-down.text')}</Typography>
                </div>
              </Fade>

              {/* вход по rsi */}
              <Fade in={!!formik.values.drawdown && !!formik.values.fund && formik.values.type === 'reseller' && !isPortfolio} unmountOnExit>
                <FormControlLabel
                  sx={{ mt: 2, display: 'flex' }}
                  control={<Checkbox checked={formik.values.useRsi} onChange={(e) => formik.setFieldValue('useRsi', e.target.checked)} />}
                  label={
                    <>
                      <Typography variant="body1">{t('bots.add-bot.use-rsi')}</Typography>
                      <Typography variant="subtitle2">{t('bots.add-bot.use-rsi.text')}</Typography>
                    </>
                  }
                />
              </Fade>

              {/* эмуляция торгов */}
              <Fade in={!!isShowEmulationsBlock && formik.values.type === 'reseller' && !!trendingViewSymbol && !isPortfolio} unmountOnExit>
                <div className="mt-16">
                  <div className="fs-14 fw-500 mb-10" style={{ color: '#333740' }}>
                    {t('bots.add-bot.emulate.title')}
                  </div>

                  <div style={{ padding: 10, border: '1px solid #ddd', borderRadius: 8 }}>
                    <Typography variant="subtitle2">
                      {t('bots.add-bot.emulate.description')}
                      <br />
                      <b>{t('bots.add-bot.emulate.warning')}</b>
                    </Typography>
                    {/* прелоадер бирж */}
                    {emulation.isInitialLoading && <Skeleton variant="rectangular" style={{ marginTop: 16 }} height={30} />}
                    {!emulation.isInitialLoading && (
                      <div className="fs-14 fw-500" style={{ marginTop: 10 }}>
                        <div>
                          {t('bots.add-bot.emulate.profit')}{' '}
                          <span className={`fw-600 ${emulation?.data?.profit_percent > 0 ? 'c-green' : 'c-red'}`}>
                            ${emulation?.data?.profit?.toFixed(2)} ({emulation?.data?.profit_percent?.toFixed(2)}%)
                          </span>
                        </div>
                        <div>
                          {t('bots.add-bot.emulate.orders')} <span className="fw-600">{emulation?.data?.order_count_close}</span>
                        </div>

                        {/* <div className="mt-16">
                          <TradingViewChart klines={emulation?.data?.klines || []} history={emulation?.data?.history || []} />
                        </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </Fade>

              <Button fullWidth variant="contained" sx={{ mt: 3 }} onClick={() => nextStep()}>
                {t('dialog.ok')}
              </Button>
            </Box>
          </Grid>
        )}

        {/* tab 2 */}
        {activeTab === tabs[1].name && (
          <Grid item xs={12} sm={12}>
            {/* стоп лосс */}
            <Fade in={!!isStopLossOptionAvailable} unmountOnExit>
              <FormControlLabel
                sx={{ display: 'flex', mt: 2 }}
                control={<Checkbox checked={formik.values.isStopLoss} onChange={(e) => formik.setFieldValue('isStopLoss', e.target.checked)} />}
                label={
                  <>
                    <Typography variant="body1">{t(`bots.add-bot.is-stop-loss${isPortfolio ? '.portfolio' : ''}`)}</Typography>
                    <Typography variant="subtitle2">{t(`bots.add-bot.is-stop-loss.text`)}</Typography>
                  </>
                }
              />
            </Fade>
            <Fade in={isStopLossOptionAvailable && formik.values.isStopLoss} unmountOnExit>
              <div>
                <TextField
                  type="number"
                  name="stopLoss"
                  label={t('bots.add-bot.stop-loss')}
                  sx={{ width: '100%', mt: 1.5 }}
                  value={formik.values.stopLoss}
                  onChange={(e) => formik.setFieldValue('stopLoss', e.target.value)}
                  error={formik.touched.stopLoss ? !!formik.errors.stopLoss : false}
                  helperText={formik.touched.stopLoss ? formik.errors.stopLoss : undefined}
                  onBlur={formik.handleBlur}
                />
                <Typography variant="subtitle2">{t(`bots.add-bot.stop-loss.text${isPortfolio ? '.portfolio' : ''}`)}</Typography>
              </div>
            </Fade>

            {/* тейк профит */}
            <Fade in={!!isExitAfterProfitOptionAvailable} unmountOnExit>
              <FormControlLabel
                sx={{ display: 'flex', mt: 2 }}
                control={<Checkbox checked={formik.values.isExitAfterProfit} onChange={(e) => formik.setFieldValue('isExitAfterProfit', e.target.checked)} />}
                label={
                  <>
                    <Typography variant="body1">{t('bots.add-bot.is-exit-after-profit')}</Typography>
                    <Typography variant="subtitle2">{t('bots.add-bot.is-exit-after-profit.text')}</Typography>
                  </>
                }
              />
            </Fade>
            <Fade in={!!isExitAfterProfitOptionAvailable && !!formik.values.isExitAfterProfit} unmountOnExit>
              <div>
                <TextField
                  type="number"
                  name="profit"
                  label={t('bots.add-bot.profit')}
                  sx={{ width: '100%', mt: 1.5 }}
                  value={formik.values.profit}
                  onChange={(e) => formik.setFieldValue('profit', e.target.value)}
                  error={formik.touched.profit ? !!formik.errors.profit : false}
                  helperText={formik.touched.profit ? formik.errors.profit : undefined}
                  onBlur={formik.handleBlur}
                />
                <Typography variant="subtitle2">{t('bots.add-bot.profit.text')}</Typography>
              </div>
            </Fade>

            {/* реинвест */}
            <Fade in={!!isReinvestmentOptionAvailable && !isPortfolio} unmountOnExit>
              <FormControlLabel
                sx={{ mt: 2, display: 'flex' }}
                control={<Checkbox checked={formik.values.isReinvest} onChange={(e) => formik.setFieldValue('isReinvest', e.target.checked)} />}
                label={
                  <>
                    <Typography variant="body1">{t('bots.add-bot.reinvest')}</Typography>
                    <Typography variant="subtitle2">{t('bots.add-bot.reinvest.text')}</Typography>
                  </>
                }
              />
            </Fade>

            <p className="description mt-24">{t('bots.add.notice')}</p>
            <div className="flex" style={{ gap: 15 }}>
              <Button fullWidth variant="outlined" sx={{ mt: 3 }} onClick={() => setActiveTab('1-step')}>
                {t('common.back')}
              </Button>
              <Button fullWidth variant="contained" sx={{ mt: 3 }} onClick={submitOnClick} disabled={createBot.isLoading}>
                {t('bots.start')}
              </Button>
            </div>
          </Grid>
        )}
      </Grid>

      <Snackbar open={isError} autoHideDuration={6000} onClose={() => setIsError(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{t('bots.add-bot.error')}</Alert>
      </Snackbar>
    </>
  );
};
