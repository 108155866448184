import { Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { useEffect, useMemo, useState } from 'react';
import { useGetTradeBots } from '../../hooks/http-requests/useGetTradeBots';
import { useGetPortfolioBots } from '../../hooks/http-requests/useGetPortfolioBots';
import { useGetTradeBotsAdmin } from '../../hooks/http-requests/useGetTradeBotsAdmin';
import { useGetPortfolioBotsAdmin } from '../../hooks/http-requests/useGetPortfolioBotsAdmin';
import { BinanceHistoricData } from '../../components/utility/BinanceHistoricData';
import { BotTradeWidget } from '../../components/bots/BotTradeWidget';
import { useActiveTab } from '../../hooks/utility/useActiveTab';
import { BotTradeSummaryWidget } from '../../components/bots/BotTradeSummaryWidget';
import { AddBotModal } from '../../components/bots/AddBotModal';
import { Clear } from '@mui/icons-material';
import * as React from 'react';
import { useChangeTradeBotStatus } from '../../hooks/http-requests/useChangeTradeBotStatus';
import { useDisableAllBots } from '../../hooks/http-requests/useDisableAllBots';
import { useAuth } from '../../hooks/utility/useAuth';
import { useGetMultiBotsAdmin } from '../../hooks/http-requests/useGetMultiBotsAdmin';
import { useGetMultiBots } from '../../hooks/http-requests/useGetMultiBots';
import { BybitHistoricData } from '../../components/utility/BybitHistoricData';
import { BinanceCurrentData } from '../../components/utility/BinanceCurrentData';
import { BybitCurrentData } from '../../components/utility/BybitCurrentData';
import { useAvailableWallets } from '../../hooks/http-requests/useAvailableWallets';
import { useUserWallets } from '../../hooks/http-requests/useUserWallets';
import MenuPopup from '../../components/utility/MenuPopup';
import Icon from '../../components/Icons';
import Preloader from '../../components/Preloader';
import { ConfirmModal } from '../../components/ConfirmModal';
import { AddBotFormModal } from '../../components/bots/AddBotFormModal';
import { NotDataCard } from '../../components/utility/NotDataCard';
import './BotsTrade.scss';

export const BotsTrade = (props) => {
  const { t } = useTranslation();
  const { active } = useActiveTab();
  const navigate = useNavigate();
  const location = useLocation();
  const botType = props.type;
  //portfolio bots
  const isPortfolioBots = useMemo(() => botType === 'portfolio', [botType]);

  const { isAdmin } = useAuth();
  const [searchParams] = useSearchParams();

  /* eslint-disable */
  const { bots, query, setQuery } = isAdmin()
    ? isPortfolioBots
      ? useGetPortfolioBotsAdmin()
      : useGetTradeBotsAdmin(searchParams.get('user_id'))
    : isPortfolioBots
    ? useGetPortfolioBots()
    : useGetTradeBots();

  const botQuery = isAdmin() ? useGetMultiBotsAdmin(searchParams.get('user_id')) : useGetMultiBots();
  /* eslint-enable */

  const { changeBotStatus } = useChangeTradeBotStatus();
  const { disableAllBots } = useDisableAllBots();
  const { availableWallets } = useAvailableWallets();
  const { userWallets } = useUserWallets();
  const [binancePrices, setBinancePrices] = useState({});
  const [selectMode, setSelectMode] = useState('');
  const [binanceHistoricPrices, setBinanceHistoricPrices] = useState({});
  const [binanceLastPrice, setBinanceLastPrice] = useState({});
  const [lastBinanceUpdate, setLastBinanceUpdate] = useState();
  const [bybitPrices, setBybitPrices] = useState({});
  const [bybitHistoricPrices, setBybitHistoricPrices] = useState({});
  const [bybitLastPrice, setBybitLastPrice] = useState({});
  const [lastBybitUpdate, setLastBybitUpdate] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [modalAddBotOpen, setModalAddBotOpen] = useState('');
  const [search, setSearch] = useState('');
  const [isFiltersActive, setIsFiltersActive] = useState(false);

  const typesList = [
    { value: 'reseller', label: t('bots.add-bot.type.reseller') },
    { value: 'investment', label: t('bots.add-bot.type.investment') },
  ];

  const statusesList = [
    { value: 'active', label: t('bots.list.status.active') },
    { value: 'disabled', label: t('bots.list.status.disabled') },
  ];

  const walletOptions = useMemo(() => {
    return userWallets.data?.map((w) => {
      const wallet = availableWallets.data?.find((aw) => aw.id === w.exchange_id);
      return {
        ...w,
        name: wallet?.name,
      };
    });
  }, [availableWallets.data, userWallets.data]);

  const binanceBots = useMemo(() => bots?.data?.filter((b) => b.type === 'BINANCE'), [bots?.data]);

  const binanceSymbols = useMemo(
    () => [
      ...new Set(
        binanceBots
          ?.filter((b) => b.status === 'active')
          .map((b) => b.symbol.toLowerCase())
          .sort()
      ),
    ],
    [binanceBots]
  );

  const bybitBots = useMemo(() => bots?.data?.filter((b) => b.type === 'bybit'), [bots?.data]);

  const bybitSymbols = useMemo(
    () => [
      ...new Set(
        bybitBots
          ?.filter((b) => b.status === 'active')
          .map((b) => b.symbol.toLowerCase())
          .sort()
      ),
    ],
    [bybitBots]
  );

  const nonTestBots = useMemo(
    () => (!selectMode || selectMode === 1 ? bots?.data?.filter((b) => !b.emulate_operations && b.status === 'active') : []),
    [selectMode, bots?.data]
  );

  const testBots = useMemo(
    () => (!selectMode || selectMode === 2 ? bots?.data?.filter((b) => b.emulate_operations && b.status === 'active') : []),
    [bots?.data, selectMode]
  );

  const allBots = useMemo(
    () => bots?.data?.filter((b) => (!selectMode ? true : b.emulate_operations === (selectMode === 1 ? false : true))),
    [bots?.data, selectMode]
  );

  const hasActiveBots = useMemo(
    () => bots?.data?.filter((b) => b.status === 'active')?.length || botQuery.result?.data?.filter((b) => b.status === 'ready')?.length,
    [bots?.data, botQuery.result?.data]
  );

  const mergedPrices = useMemo(() => {
    const merged = {};
    for (var key in binanceHistoricPrices) {
      if (!binancePrices[key]) merged[key] = binanceHistoricPrices[key][binanceHistoricPrices[key].length - 1].value;
      else merged[key] = binancePrices[key];
    }

    for (var k in bybitHistoricPrices) {
      if (!binancePrices[k]) merged[k] = bybitHistoricPrices[k][bybitHistoricPrices[k].length - 1].value;
      else merged[key] = binancePrices[key];
    }

    Object.keys(bybitPrices).forEach((s) => {
      if (binancePrices[s]) {
        merged[s] = ((+merged[s] + +bybitPrices[s]) / 2).toFixed(8);
      } else {
        merged[s] = bybitPrices[s];
      }
    });
    return merged;
  }, [binanceHistoricPrices, binancePrices, bybitHistoricPrices, bybitPrices]);

  useEffect(() => {
    setQuery(Object.fromEntries(searchParams.entries()));
    setSearch(searchParams.get('search') || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (!lastBinanceUpdate) return;
    const symbol = lastBinanceUpdate.stream.split('@')[0];
    setBinancePrices((price) => ({
      ...price,
      [symbol]: lastBinanceUpdate.data.k.c,
    }));
    if (binanceHistoricPrices[symbol]?.length) {
      const lastEl = binanceHistoricPrices[symbol][binanceHistoricPrices[symbol].length - 1];
      if (lastEl.timestamp < lastBinanceUpdate.data.k.T && binanceLastPrice[symbol]) {
        const newPrices = [...binanceHistoricPrices[symbol].slice(1), binanceLastPrice[symbol]];
        setBinanceHistoricPrices((prev) => ({ ...prev, [symbol]: newPrices }));
      }
      if (binanceLastPrice[symbol]?.timestamp === lastBinanceUpdate.data.k.T && binanceLastPrice[symbol]?.value === lastBinanceUpdate.data.k.c) return;
      setBinanceLastPrice((prev) => ({
        ...prev,
        [symbol]: {
          timestamp: lastBinanceUpdate.data.k.T,
          value: lastBinanceUpdate.data.k.c,
        },
      }));
    }
  }, [lastBinanceUpdate, setBinanceLastPrice, setBinanceHistoricPrices, binanceHistoricPrices, binanceLastPrice]);

  useEffect(() => {
    if (!lastBybitUpdate) return;
    const symbol = lastBybitUpdate.data.s.toLowerCase();
    setBybitPrices((price) => ({
      ...price,
      [symbol]: lastBybitUpdate.data.c,
    }));
    if (bybitHistoricPrices[symbol]?.length) {
      const lastEl = bybitHistoricPrices[symbol][bybitHistoricPrices[symbol].length - 1];
      if (lastEl.timestamp < lastBybitUpdate.data.t && bybitLastPrice[symbol]) {
        const newPrices = [...bybitHistoricPrices[symbol].slice(1), bybitLastPrice[symbol]];
        setBybitHistoricPrices((prev) => ({ ...prev, [symbol]: newPrices }));
      }
      if (bybitLastPrice[symbol]?.timestamp === lastBybitUpdate.data.t && bybitLastPrice[symbol]?.value === lastBybitUpdate.data.c) return;
      setBybitLastPrice((prev) => ({
        ...prev,
        [symbol]: {
          timestamp: lastBybitUpdate.data.t,
          value: lastBybitUpdate.data.c,
        },
      }));
    }
  }, [lastBybitUpdate, setBybitLastPrice, setBybitHistoricPrices, bybitHistoricPrices, bybitLastPrice]);

  useEffect(() => {
    setIsFiltersActive(!!search || !!query.wallet || !!query.type || !!query.status);
  }, [isFiltersActive, query.status, query.type, query.wallet, search, searchParams]);

  const setBinanceSymbolData = (data) => {
    if (!binanceHistoricPrices[data.symbol]) {
      setBinanceHistoricPrices((prev) => ({
        ...prev,
        [data.symbol]: data.data,
      }));
    }
  };

  const setBybitSymbolData = (data) => {
    if (!bybitHistoricPrices[data.symbol]) {
      setBybitHistoricPrices((prev) => ({ ...prev, [data.symbol]: data.data }));
    }
  };

  const handleModalClose = (type) => {
    setModalOpen(false);
    setModalAddBotOpen(type);
    // if (type) navigate(`/dashboard/add-bot?${createSearchParams({ type })}`);
  };

  const navigateTabs = (_, value) => {
    if (isAdmin()) {
      navigate(`/admin/bots/${value}?user_id=${searchParams.get('user_id')}`);
    } else {
      navigate(`/dashboard/bots/${value}`);
    }
  };

  const changeStatus = (botId, status, disableAfterClosingDeal) => {
    changeBotStatus.mutateAsync({ botId, status, disableAfterClosingDeal }).then(() => bots.refetch());
  };

  const onDisableAll = () => {
    setModalConfirmOpen(true);
  };

  const onDisableAllConfirmed = () => {
    disableAllBots.mutateAsync(undefined).then(() => {
      bots.refetch();
      botQuery.result.refetch();
    });
  };

  const setQueryParams = (newQuery) => {
    const currentQuery = Object.fromEntries(searchParams.entries());
    const query = { ...currentQuery, ...newQuery };
    const params = createSearchParams(query);
    navigate(`${location.pathname}?${params}`);
  };

  const clearSerach = () => {
    setSearch('');
    setQueryParams({ search: '' });
  };

  const userName = () => localStorage.getItem('showUserName');
  const userEmail = () => localStorage.getItem('showUserEmail');

  const modeList = [
    {
      label: t('statistics.filter.real'),
      value: 1,
    },
    {
      label: t('statistics.filter.demo'),
      value: 2,
    },
  ];

  const countFilter = useMemo(() => !!query.status || !!query.type || !!query.wallet || !!selectMode, [query.status, query.type, query.wallet, selectMode]);

  const tabsList = ['trade', 'portfolio', 'rebalancer'];

  return (
    <>
      <ConfirmModal
        open={modalConfirmOpen}
        onCancel={() => setModalConfirmOpen(false)}
        title={t('confirm.bot.disable-all.title')}
        onOK={() => {
          onDisableAllConfirmed();
          setModalConfirmOpen(false);
        }}
        text={t('confirm.bot.disable-all.text')}
      />
      <AddBotFormModal open={modalAddBotOpen !== ''} type={modalAddBotOpen} onClose={() => setModalAddBotOpen('')} />

      {/* <WellcomeBlock /> */}

      <div className="bots-page__top">
        <Tabs value={botType} onChange={navigateTabs} variant="scrollable" scrollButtons="auto">
          {tabsList.map((name) => (
            <Tab sx={{ textTransform: 'inherit', fontSize: 16 }} label={t(`bots.tabs.${name}`)} value={name} key={name} />
          ))}
        </Tabs>

        <div className="bots-page__buttons mobile-hidden">
          {/* <Button sx={{ mr: 2 }} variant="contained" color="warning" onClick={onDisableAll} disabled={!hasActiveBots} endIcon={<DoDisturbIcon />}>
            {t('bots.list.disable-all')}
          </Button> */}
          <Button variant="contained" onClick={() => setModalOpen(true)} endIcon={<Icon icon="bot" />}>
            {t('bots.add-bot')}
          </Button>
        </div>
      </div>
      <div className="bots-page__header">
        <h3 className="bots-page__title">
          {t(`bots.list.added-${isPortfolioBots ? 'portfolios' : 'trading'}-bots`)}
          <div className="pc-hidden tablet-hidden">
            <MenuPopup icon="menu-dot">
              {/* <Button sx={{ mr: 2 }} variant="contained" color="warning" onClick={onDisableAll} disabled={!hasActiveBots} endIcon={<DoDisturbIcon />}>
                {t('bots.list.disable-all')}
              </Button>  */}
              <Button variant="contained" onClick={() => setModalOpen(true)} endIcon={<AddIcon />}>
                {t('bots.add-bot')}
              </Button>
            </MenuPopup>
          </div>
        </h3>
        <div className="bots-page__filters">
          <div className="input__search w-fill">
            <TextField
              label={t('add-wallet.search')}
              fullWidth
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => e.keyCode === 13 && setQueryParams({ search })}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment style={{ opacity: search ? 1 : 0 }} position="end">
                      <Icon icon="close" className="medium pointer hover" onClick={() => clearSerach()} />
                    </InputAdornment>
                    <InputAdornment position="end">
                      <Icon icon="search" className="medium pointer hover" onClick={() => setQueryParams({ search })} />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </div>

          <MenuPopup icon="filter" count={countFilter}>
            <FormControl fullWidth>
              <InputLabel id="wallet-select-label">{t('operations.filters.wallet')}</InputLabel>
              <Select
                labelId="wallet-select-label"
                label={t('operations.filters.wallet')}
                fullWidth
                value={query.wallet || ''}
                onChange={(e) => setQueryParams({ wallet: e.target.value })}
                endAdornment={
                  <IconButton sx={{ display: query.wallet ? '' : 'none' }} onClick={() => setQueryParams({ wallet: '' })}>
                    <Clear />
                  </IconButton>
                }
              >
                {walletOptions?.map((w) => (
                  <MenuItem key={w.id} value={w.id}>
                    {w.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* portfolio bots */}
            {!isPortfolioBots && (
              <FormControl fullWidth>
                <InputLabel id="type-select-label">{t('bots.operations.type')}</InputLabel>
                <Select
                  labelId="type-select-label"
                  label={t('bots.operations.type')}
                  fullWidth
                  value={query.type || ''}
                  onChange={(e) => setQueryParams({ type: e.target.value })}
                  endAdornment={
                    <IconButton sx={{ display: query.type ? '' : 'none' }} onClick={() => setQueryParams({ type: '' })}>
                      <Clear />
                    </IconButton>
                  }
                >
                  {typesList.map((t) => (
                    <MenuItem key={t.value} value={t.value}>
                      {t.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl fullWidth>
              <InputLabel id="status-select-label">{t('bots.list.status')}</InputLabel>
              <Select
                labelId="status-select-label"
                label={t('bots.list.status')}
                fullWidth
                value={query.status || ''}
                onChange={(e) => setQueryParams({ status: e.target.value })}
                endAdornment={
                  <IconButton sx={{ display: query.status ? '' : 'none' }} onClick={() => setQueryParams({ status: '' })}>
                    <Clear />
                  </IconButton>
                }
              >
                {statusesList.map((t) => (
                  <MenuItem key={t.value} value={t.value}>
                    {t.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="mode-select-label">{t('statistics.filter.mode')}</InputLabel>
              <Select
                labelId="mode-select-label"
                label={t('bots.list.status')}
                fullWidth
                value={selectMode}
                onChange={(e) => setSelectMode(e.target.value)}
                endAdornment={
                  <IconButton sx={{ display: selectMode ? '' : 'none' }} onClick={() => setSelectMode('')}>
                    <Clear />
                  </IconButton>
                }
              >
                {modeList.map((t) => (
                  <MenuItem key={t.value} value={t.value}>
                    {t.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MenuPopup>
        </div>
      </div>
      {!!allBots?.length && (
        <div className="content bots-page__content">
          {nonTestBots?.length > 0 && (
            <div className="card bots-trade-widget">
              <BotTradeSummaryWidget bots={nonTestBots} prices={mergedPrices} isPortfolio={isPortfolioBots} />
            </div>
          )}
          {testBots?.length > 0 && (
            <div className="card bots-trade-widget -test-bot">
              <BotTradeSummaryWidget bots={testBots} prices={mergedPrices} test={true} isPortfolio={isPortfolioBots} />
            </div>
          )}
          {allBots?.map((b) => (
            <BotTradeWidget
              key={b.id}
              bot={b}
              lastPrice={b.type === 'bybit' ? bybitPrices[b.symbol.toLowerCase()] : binancePrices[b.symbol.toLowerCase()]}
              historicPrices={b.type === 'bybit' ? bybitHistoricPrices[b.symbol.toLowerCase()] : binanceHistoricPrices[b.symbol.toLowerCase()]}
              changeBotStatus={changeStatus}
            />
          ))}
        </div>
      )}

      {allBots?.length === 0 ? (
        <NotDataCard text={isFiltersActive ? undefined : t('bots.list.not-bot')} />
      ) : !allBots?.length ? (
        <Preloader locading={true} />
      ) : (
        ''
      )}

      {isAdmin() && (
        <div className="admin-show-user">
          {userEmail()} {userName()}
        </div>
      )}

      <BinanceCurrentData symbols={binanceSymbols} binanceUpdate={setLastBinanceUpdate} active={active} />
      <BybitCurrentData symbols={bybitSymbols} bybitUpdate={setLastBybitUpdate} active={active} />
      <BinanceHistoricData symbols={binanceSymbols} onSymbolData={setBinanceSymbolData} active={active} />
      <BybitHistoricData symbols={bybitSymbols} onSymbolData={setBybitSymbolData} active={active} />
      <AddBotModal open={modalOpen} onClose={() => setModalOpen(false)} onOpen={handleModalClose} showType={isPortfolioBots ? 'portfolio' : 'trade'} />
    </>
  );
};
