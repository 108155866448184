import { useMutation } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useGetBybitKlinesHistoricData = () => {
  const bybitKlinesHistoricData = useMutation(
    ApiService.getBybitKlinesHistoricData
  );

  return { bybitKlinesHistoricData };
};
