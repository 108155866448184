import { useMemo } from 'react';
// import Chart from 'react-apexcharts';

export const PriceChart = ({ series }) => {
  const data = useMemo(() => series || [], [series]);

  const generateSVG = (data) => {
    // Начальные параметры SVG
    var width = 400;
    var height = 100;
    var marginX = 0;
    var marginY = 5;

    // Вычисляем максимальное и минимальное значение данных
    var min = Math.min(...data);
    var max = Math.max(...data);

    // Создаем SVG элемент
    var svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" preserveAspectRatio="none">`;

    // Генерируем линию
    var line = data.map((d, i) => {
      var x = ((width - 2 * marginX) / (data.length - 1)) * i + marginX;
      var y = height - marginY - ((height - 2 * marginY) * (d - min)) / (max - min);
      return `${x},${y}`;
    });

    const color = data[0] < data[data.length - 1] ? '#57b214' : '#E4565A';

    // Создаем градиент
    svg += `<defs>
      <linearGradient id="Gradient" x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stop-color="${color}" stop-opacity="0.5" />
        <stop offset="100%" stop-color="${color}" stop-opacity="0" />
      </linearGradient>
    </defs>`;

    // Создаем многоугольник для заливки под линией
    var polygonPoints = [`${marginX},${height}`, ...line, `${width - marginX},${height}`].join(' ');

    // Добавляем многоугольник в SVG
    svg += `<polygon points="${polygonPoints}" fill="url(#Gradient)" />`;

    // Добавляем линию в SVG
    svg += `<polyline points="${line.join(' ')}" stroke-width="2" stroke="${color}" fill="none" />`;

    // Закрываем SVG элемент
    svg += '</svg>';

    let url = 'data:image/svg+xml,' + encodeURIComponent(svg);

    return <img loading="lazy" src={url} alt="" />;
  };

  return generateSVG(data);
};
