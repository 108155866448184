import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useGetRebalancerBotsStatistics = () => {
  const rebalancerBotsStatistics = useQuery({
    queryKey: ['multibots/statistics'],
    queryFn: () => ApiService.getMultiBotsStatistics(),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: 'always',
    staleTime: Infinity,
    cacheTime: 0,
  });

  return { rebalancerBotsStatistics };
};
