import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';
import { useState } from 'react';

export const useGetPortfolioBotsAdmin = (user_id) => {
  const [query, setQuery] = useState({
    search: '',
    status: '',
    wallet: '',
    user_id,
  });

  const bots = useQuery({
    queryKey: ['admin/bots/portfolio', query],
    queryFn: () => ApiService.getPortfolioBotsAdmin(query),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: 'always',
    staleTime: Infinity,
    cacheTime: 0,
    refetchInterval: 30000,
  });

  return { bots, query, setQuery };
};
