import { Alert, Button, CircularProgress, IconButton } from '@mui/material';
import { BotRebalancerWidget } from '../../components/bots/BotRebalancerWidget';
import * as React from 'react';
import { BotOperations } from '../../components/bots/BotOperations';
import { useTranslation } from 'react-i18next';
import { useActiveTab } from '../../hooks/utility/useActiveTab';
import { useGetBotById } from '../../hooks/http-requests/useGetBotById';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { PieChart } from '../../components/utility/PieChart';
import { useDeleteRebalancerBot } from '../../hooks/http-requests/useDeleteRebalancerBot';
import { useChangeRebalancerBotStatus } from '../../hooks/http-requests/useChangeRebalancerBotStatus';
import Icon from '../../components/Icons';
import MenuPopup from '../../components/utility/MenuPopup';
import { BOT_REBALANCER_TYPE_OPTIONS } from '../../constants/bot-rebalancer.types';
import Scrollbars from 'react-custom-scrollbars-2';
import './BotRebalancerInfo.scss';
import { ConfirmModal } from '../../components/ConfirmModal';
import { ShareBotBlock, ShareMobile, SharePc } from '../../components/ShareBotBlock';
import { DifferenceBetweenDates } from '../../components/utility/DifferenceBetweenDates';
import { useGetUser } from '../../hooks/http-requests/useGetUser';
import { Share } from '@mui/icons-material';
import { BinanceCurrentData } from '../../components/utility/BinanceCurrentData';
import { BybitCurrentData } from '../../components/utility/BybitCurrentData';
import { useAuth } from '../../hooks/utility/useAuth';

export const BotRebalancerInfo = () => {
  const { t } = useTranslation();
  const { active } = useActiveTab();
  const { bot, setId } = useGetBotById('rebalancer');
  const { deleteBot } = useDeleteRebalancerBot();
  const { changeBotStatus } = useChangeRebalancerBotStatus();
  const [prices, setPrices] = useState({});
  const [profit, setProfit] = useState({});
  const [modalConfirmDelOpen, setModalConfirmDelOpen] = useState(false);
  const [modalConfirmDisableOpen, setModalConfirmDisableOpen] = useState(false);
  let { id } = useParams();
  const navigate = useNavigate();
  const { user } = useGetUser();
  const [shareShow, setShareShow] = useState(false);
  const { isAdmin } = useAuth();
  const { fund, profit: profit_bot, created_at, updated_at } = bot?.data || [];
  const referralCode = useMemo(() => user?.data?.referral_code, [user]);

  const sharePc = async () => {
    setShareShow(true);
    await SharePc();
    setShareShow(false);
  };

  const shareMobile = async () => {
    setShareShow(true);
    await ShareMobile();
    setShareShow(false);
  };

  useEffect(() => {
    setId(id);
  }, [id, setId]);

  const fullServies = useMemo(
    () => bot.data?.data?.data?.sort((a, b) => b.ratio - a.ratio).map((s) => ({ symbol: s.symbol, ratio: +(s.ratio * 100).toFixed(4) })),
    [bot.data?.data?.data]
  );

  const series = useMemo(() => {
    const full = fullServies?.map((s) => s.ratio);
    if (!full) return [];
    if (full?.length > 5) {
      const first5 = full.slice(0, 5);
      const other = full.slice(5);
      return first5.concat(+other.reduce((acc, curr) => acc + curr, 0).toFixed(4));
    }
    return full;
  }, [fullServies]);

  const legend = useMemo(() => fullServies?.sort((a, b) => b.ratio - a.ratio).map((s) => s.symbol), [fullServies]);

  const legendShort = useMemo(() => {
    const full = fullServies?.map((s) => s.symbol);
    if (!full) return [];
    if (full?.length > 5) {
      const first5 = full.slice(0, 5);
      return first5.concat(t('balance.widget.other'));
    }
    return full;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullServies]);

  const botType = useMemo(() => BOT_REBALANCER_TYPE_OPTIONS.find((o) => o.value === bot?.data?.bot_type), [bot]);
  const updatePrices = (lastJsonMessage) => {
    if (!lastJsonMessage) return;
    const symbol = bot.data?.type === 'BINANCE' ? lastJsonMessage.stream.split('@')[0] : lastJsonMessage.data.s.toLowerCase();
    setPrices((price) => ({
      ...price,
      [symbol]: bot.data?.type === 'BINANCE' ? lastJsonMessage.data.k.c : lastJsonMessage.data.c,
    }));

    const profit = {};

    bot?.data?.data?.data?.forEach((e) => {
      const cPrice = prices[e.symbol.toLowerCase()];
      const perc = ((cPrice - e.price) / e.price) * 100;
      profit[e.symbol] = { perc, usdt: (e.amount / 100) * perc * cPrice };
    });

    setProfit(profit);
  };

  const changeStatus = (status) => {
    changeBotStatus.mutateAsync({ botId: bot.data.id, status }).then(() => bot.refetch());
  };

  const deleteBotClick = () => {
    deleteBot.mutateAsync(bot.data.id).then(() => navigate('/dashboard/bots/rebalancer'));
  };

  const userName = () => localStorage.getItem('showUserName');
  const userEmail = () => localStorage.getItem('showUserEmail');

  if (!bot.data?.id) return null;

  return (
    <div className="bot-info-page__wrap">
      {bot.data.status === 'ready' && (
        <Alert className="mb-16" variant="filled" severity="info">
          {t('bots.rebalancer.info-message')}
        </Alert>
      )}
      <div className="bot-info-page__header">
        <h3 className="bot-info-page__title">
          <IconButton className="btn icon -no-hover" onClick={() => window.history.go(-1)}>
            <Icon icon="arrow-left" />
          </IconButton>
          <div>
            <div>{`${t('bots.widget.type.rebalancer')} ${t(botType?.label)} ${bot?.data?.name ? ' "' + bot?.data?.name + '"' : ''}`}</div>
            <div className="bots-trade-widget__desc">{bot.data?.emulate_operations ? ` (${t('bots.summary-widget.summary-test')})` : ''}</div>
          </div>
          <div className="pc-hidden tablet-hidden">
            <MenuPopup>
              {bot.data.status === 'ready' && (
                <Button
                  variant="contained"
                  color="warning"
                  fullWidth
                  onClick={() => setModalConfirmDisableOpen(true)}
                  endIcon={<Icon icon="stop" className="medium" />}
                >
                  {t('bots.info.switch-off')}
                </Button>
              )}
              {bot.data.status === 'cancelled' && (
                <Button
                  fullWidth
                  variant="contained"
                  color="warning"
                  onClick={() => setModalConfirmDelOpen(true)}
                  endIcon={<Icon icon="delete" className="medium" />}
                >
                  {t('bots.info.delete')}
                </Button>
              )}
              <Button variant="contained" onClick={() => shareMobile()} endIcon={shareShow ? <CircularProgress color="inherit" size={20} /> : <Share />}>
                {t('bots.info.share')}
              </Button>
            </MenuPopup>
          </div>
        </h3>
        <div className="mobile-hidden">
          <div className="bot-info-page__buttons">
            {bot.data.status === 'ready' && (
              <Button
                variant="contained"
                color="warning"
                fullWidth
                onClick={() => setModalConfirmDisableOpen(true)}
                endIcon={<Icon icon="stop" className="medium" />}
              >
                {t('bots.info.switch-off')}
              </Button>
            )}
            {bot.data.status === 'cancelled' && (
              <Button
                fullWidth
                variant="contained"
                color="warning"
                onClick={() => setModalConfirmDelOpen(true)}
                endIcon={<Icon icon="delete" className="medium" />}
              >
                {t('bots.info.delete')}
              </Button>
            )}
            <IconButton color="primary" onClick={() => sharePc()}>
              {shareShow ? <CircularProgress size={20} /> : <Share />}
            </IconButton>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="bot-info-page__content ">
          <div className="bot-info-page__info">
            <BotRebalancerWidget bot={bot?.data} lastPrices={prices} withoutChart={true} height="400px" />
          </div>
          <div className="bot-info-page__chart -reb">
            <div className="w-fill">
              {bot?.status === 'configured' || bot?.status === 'cancelling' || bot?.status === 'new' ? (
                <div style={{ display: 'flex', margin: '50px auto', width: 20 }}>
                  <CircularProgress sx={{ mt: 1 }} color="primary" size={18} />
                </div>
              ) : (
                <PieChart series={series} legend={legendShort} />
              )}
            </div>
            <div className="w-fill">
              <Scrollbars style={{ height: 250 }} autoHide>
                <table className="f-small">
                  <tbody>
                    {fullServies.map((d) => (
                      <tr key={d.symbol}>
                        <td>{d?.symbol?.replace('USDT', ' / USDT')}</td>
                        {bot?.data?.status === 'ready' && (
                          <>
                            <td
                              className={`
                        ${profit[d.symbol]?.perc > 0 ? 'c-green' : 'c-red'} a-right
                      `}
                            >
                              {profit[d.symbol]?.perc ? <>${profit[d.symbol]?.usdt.toFixed(2)}</> : '-'}
                            </td>
                            <td
                              className={`
                      ${profit[d.symbol]?.perc > 0 ? 'c-green' : 'c-red'} a-right
                    `}
                            >
                              {profit[d.symbol]?.perc ? (
                                <>
                                  {profit[d.symbol]?.perc > 0 && '+'}
                                  {profit[d.symbol]?.perc.toFixed(2)}%
                                </>
                              ) : (
                                '-'
                              )}
                            </td>
                          </>
                        )}

                        <td className="a-right">{d.ratio.toFixed(2)}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
      <div className="bot-info-page__operations">
        <h4>{t('bots.info.history')}</h4>
        <BotOperations id={bot?.data?.id} type="rebalancer" />
        {bot.data?.type === 'BINANCE' && <BinanceCurrentData symbols={legend} binanceUpdate={updatePrices} active={active} />}
        {bot.data?.type === 'bybit' && <BybitCurrentData symbols={legend} bybitUpdate={updatePrices} active={active} />}
      </div>

      {isAdmin() && (
        <div className="admin-show-user">
          {userEmail()} {userName()}
        </div>
      )}

      <ConfirmModal
        open={modalConfirmDelOpen}
        onCancel={() => setModalConfirmDelOpen(false)}
        title={t('confirm.bot.delete.title')}
        onOK={() => {
          deleteBotClick();
          setModalConfirmDelOpen(false);
        }}
        text={t('confirm.bot.delete.text')}
      />

      <ConfirmModal
        open={modalConfirmDisableOpen}
        onCancel={() => setModalConfirmDisableOpen(false)}
        title={t('confirm.bot.disable.title')}
        onOK={() => {
          changeStatus('disabled');
          setModalConfirmDisableOpen(false);
        }}
        text={t('confirm.bot.disable.text-rebalanser')}
      />

      <ShareBotBlock
        referralCode={referralCode}
        title={`Бот ${t('bots.widget.type.rebalancer')} ${t(botType?.label)}`}
        primaryIndicator={`${+(((+profit_bot || 0) / fund) * 100).toFixed(2)}%`}
        secondaryIndicator={`${+(+profit_bot || 0).toFixed(2)} USDT`}
        descriptions={[
          {
            name: t('operations.filters.wallet'),
            value: `${t(`wallet.${bot.data?.type.toUpperCase()}`)}`,
          },
          {
            name: t('bots.widget.created-bot'),
            value: <DifferenceBetweenDates date={created_at} lastDate={bot?.data.status === 'cancelled' ? updated_at : undefined} />,
          },
        ]}
      />
    </div>
  );
};
