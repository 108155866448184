import { Box, CircularProgress, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ChooseWalletStep = ({ availableWallets, onChooseWallet }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const filteredWallets = useMemo(
    () =>
      availableWallets.data?.filter((w) =>
        w.name?.toLowerCase().includes(search.toLowerCase())
      ),
    [availableWallets.data, search]
  );

  return (
    <>
      <div className="add-wallets__step1">
        <TextField
          label={t('add-wallet.search')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ width: '100%', mt: 2, mb: 1 }}
        />
        <div className="add-wallets">
          {availableWallets.isLoading ? (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {filteredWallets?.map((wallet) => (
                <div
                  onClick={() => onChooseWallet(wallet)}
                  className="add-wallets__item"
                  key={wallet.id}
                >
                  <img src={`/img/wallets/${wallet.tag}.svg`} alt="" />
                  <div className="add-wallets__name">{wallet.name}</div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};
