import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { timeZonesTV } from '../../constants/timezones';
import { useIsMobile } from '../utility/DetectDevice';

let tvScriptLoadingPromise;

export const TradingViewWidget = ({ symbolProp, height = 'auto', width = '', showDetails, range }) => {
  const onLoadScriptRef = useRef();
  const { i18n } = useTranslation();
  const isMobile = useIsMobile();
  const offsetTimeZone = () => new Date().getTimezoneOffset() / -60;

  const getTimeZone = timeZonesTV.find((t) => +t.offset === offsetTimeZone())?.name || 'UTC';

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script');
        script.id = 'tradingview-widget-loading-script';
        script.src = 'https://s3.tradingview.com/tv.js';
        script.type = 'text/javascript';
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current(symbolProp, i18n.language, showDetails));

    return () => (onLoadScriptRef.current = null);

    function createWidget(symbol, locale, showDetails) {
      if (document.getElementById('tradingview-chart') && 'TradingView' in window) {
        new window.TradingView.widget({
          width: '100%',
          autosize: false,
          symbol,
          timezone: getTimeZone,
          theme: 'light',
          style: '10',
          locale,
          enable_publishing: false,
          backgroundColor: showDetails ? 'rgba(255, 255, 255, 1)' : undefined,
          withdateranges: true,
          range: range ? range : '1D',
          hide_side_toolbar: isMobile,
          save_image: false,
          details: showDetails,
          hotlist: showDetails,
          calendar: showDetails,
          container_id: 'tradingview-chart',
        });
      }
    }
  }, [symbolProp, i18n.language, showDetails, getTimeZone, range, isMobile]);

  useEffect(() => {
    onLoadScriptRef.current && onLoadScriptRef.current(symbolProp, i18n.language);
  }, [symbolProp, i18n.language]);

  return <div id="tradingview-chart" style={{ height, width }} />;
};
