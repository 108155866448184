export const getSortFunction = (sortBy) => {
  // eslint-disable-next-line default-case
  switch (sortBy) {
    case 'end_date':
      return (a, b) => {
        const aUpdatedAt = a.status === 'disabled' || a.status === 'cancelled' ? +new Date(a.updated_at) : 0;
        const bUpdatedAt = b.status === 'disabled' || b.status === 'cancelled' ? +new Date(b.updated_at) : 0;
        return bUpdatedAt - aUpdatedAt;
      };
    case 'profit':
      return (a, b) => {
        return b.profit - a.profit;
      };
    case 'created_at':
      return (a, b) => {
        return +new Date(b.created_at) - +new Date(a.created_at);
      };
    case 'fund':
      return (a, b) => {
        return b.fund - a.fund;
      };
    case 'prof_perc':
      return (a, b) => {
        return b.profit / b.fund - a.profit / a.fund;
      };
    case 'tag':
      return (a, b) => {
        return ('' + b.tag).localeCompare(a.tag);
      };
    case 'type':
      return (a, b) => {
        return ('' + b.type).localeCompare(a.type);
      };
    case 'symbol':
      return (a, b) => {
        return ('' + b.coin).localeCompare(a.coin);
      };
    case 'name':
      return (a, b) => {
        return ('' + b.name).localeCompare(a.name);
      };
  }
};

export const filterBots = (bots, filter) => {
  const filters = {
    tag: filter.tag,
    type: filter.type,
    coin: filter.coin,
  };
  return bots.filter((b) => {
    const filtersValue = Object.keys(filters).reduce((acc, k) => {
      if (filters[k]) {
        return filters[k] === b[k] && acc;
      } else {
        return acc;
      }
    }, true);
    return (
      filtersValue &&
      demoBotFilter(b, filter.isDemo) &&
      statusFilter(b, filter.status) &&
      (!filter.min_fund || b.fund >= filter.min_fund) &&
      (!filter.max_fund || b.fund <= filter.max_fund) &&
      (!filter.min_profit || b.profit >= filter.min_profit) &&
      (!filter.max_profit || b.profit <= filter.max_profit) &&
      (!filter.min_created_at || +new Date(b.created_at) >= +new Date(filter.min_created_at)) &&
      (!filter.max_created_at || +new Date(b.created_at) <= +new Date(filter.max_created_at)) &&
      (!filter.min_updated_at || +new Date(b.updated_at) >= +new Date(filter.min_updated_at)) &&
      (!filter.max_updated_at || +new Date(b.updated_at) <= +new Date(filter.max_updated_at))
    );
  });
};

const demoBotFilter = (bot, filter) => {
  switch (filter) {
    case 'real':
      return !bot.emulate_operations;
    case 'demo':
      return bot.emulate_operations;
    default:
      return true;
  }
};

const statusFilter = (bot, filter) => {
  switch (filter) {
    case 'active':
      return bot.status !== 'disabled' && bot.status !== 'cancelled';
    case 'disabled':
      return (bot.status === 'disabled' || bot.status === 'cancelled') && !bot.deleted_at;
    case 'deleted':
      return !!bot.deleted_at;
    default:
      return true;
  }
};

export const botOptions = [
  { value: 'all', label: 'common.all' },
  { value: 'real', label: 'statistics.filter.real' },
  { value: 'demo', label: 'statistics.filter.demo' },
];

export const botTypeOptions = [
  { value: 'reseller', label: 'bots.widget.type.reseller' },
  { value: 'invest', label: 'bots.widget.type.invest' },
  { value: 'rebalancerTop10', label: 'enums.bot-type.rebalancer.top10' },
  { value: 'rebalancerTop15', label: 'enums.bot-type.rebalancer.top15' },
  { value: 'rebalancerTop35', label: 'enums.bot-type.rebalancer.top35' },
];

export const botStatusOptions = [
  { value: 'active', label: 'bots.list.status.active' },
  { value: 'disabled', label: 'bots.list.status.disabled' },
  { value: 'deleted', label: 'statistics.filter.status.deleted' },
];
