import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useTelegramUnsubscribe = () => {
  const queryClient = useQueryClient();

  const telegramUnsubscribe = useMutation(ApiService.telegramUnsubscribe, {
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['user'] });
    },
  });

  return { telegramUnsubscribe };
};
