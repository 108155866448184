import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from 'react-router-dom';
import { LoginPage } from './pages/auth/Login';
import { RegisterPage } from './pages/auth/Register';
import { HomePage } from './pages/Home';
import { ProfilePage } from './pages/Profile';
import { VerifyPage } from './pages/auth/Verify';
import { ProtectedLayout } from './components/layout/ProtectedLayout.js';
import { HomeLayout } from './components/layout/HomeLayout';
import { AuthLayout } from './components/layout/AuthLayout';
import { Dashboard } from './pages/Dashboard';
import { Operations } from './pages/Operations';
import { Servers } from './pages/Servers';
import { BotsTrade } from './pages/bots/BotsTrade';
// import { AddBot } from './pages/bots/AddBot';
import { WalletInfo } from './pages/WalletInfo';
import { Payments } from './pages/Payments';
import { Analytics } from './pages/Analytics';
import { BotTradeInfo } from './pages/bots/BotTradeInfo';
import { AdminDashboard } from './pages/admin/Dashboard';
import { AdminUsers } from './pages/admin/Users';
import { AdminProtectedLayout } from './components/admin/ProtectedLayout';
import { BotsRebalancer } from './pages/bots/BotsRebalancer';
import { BotRebalancerInfo } from './pages/bots/BotRebalancerInfo';
import { LoginCodePage } from './pages/auth/LoginCode';
import { ForgottenPage } from './pages/auth/Forgotten';
import { AdminAnalytics } from './pages/admin/Analytics';
import { AdminReferral } from './pages/admin/Referral';
import { Notifications } from './pages/Notifications';
import { СurrencyCalculator } from './pages/Calculator';
import { BotStatistics } from './pages/BotStatistics';

// ideally this would be an API call to server to get logged in user data

const getUserData = () =>
  new Promise((resolve) => {
    const user = window.localStorage.getItem('user');
    resolve(user);
  });

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() => defer({ userPromise: getUserData() })}
    >
      <Route element={<HomeLayout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/verify" element={<VerifyPage />} />
        <Route path="/auth" element={<LoginCodePage />} />
        <Route path="/forgotten" element={<ForgottenPage />} />
      </Route>

      <Route path="/dashboard" element={<ProtectedLayout />}>
        <Route path="" element={<Dashboard />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="operations" element={<Operations />} />
        <Route path="servers" element={<Servers />} />
        <Route path="payments" element={<Payments />} />
        <Route path="payments/success" element={<Payments />} />
        <Route path="payments/fail" element={<Payments />} />
        <Route path="bots/trade" element={<BotsTrade type="trade" />} />
        <Route path="bots/portfolio" element={<BotsTrade type="portfolio" />} />
        <Route path="bots/rebalancer" element={<BotsRebalancer type="rebalancer" />} />
        {/* <Route path="add-bot" element={<AddBot />} /> */}
        <Route path="wallets/:id" element={<WalletInfo />} />
        <Route path="analytics" element={<Analytics />} />
        <Route path="bots/trade/:id" element={<BotTradeInfo />} />
        <Route path="bots/rebalancer/:id" element={<BotRebalancerInfo />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="calculator" element={<СurrencyCalculator />} />
        <Route path="statistics" element={<BotStatistics />} />
      </Route>
      <Route path="/admin" element={<AdminProtectedLayout />}>
        <Route path="" element={<AdminDashboard />} />
        <Route path="users" element={<AdminUsers />} />
        <Route path="payments" element={<AdminDashboard />} />
        <Route path="analytics" element={<AdminAnalytics />} />
        <Route path="bots/trade" element={<BotsTrade type="trade" />} />
        <Route path="bots/portfolio" element={<BotsTrade type="portfolio" />} />
        <Route path="bots/rebalancer" element={<BotsRebalancer type="rebalancer" />} />
        <Route path="bots/trade/:id" element={<BotTradeInfo />} />
        <Route path="bots/rebalancer/:id" element={<BotRebalancerInfo />} />
        <Route path="referral" element={<AdminReferral />} />
      </Route>
    </Route>
  )
);

export default router;
