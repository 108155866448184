import { useUsersStatisticsAdmin } from '../../hooks/http-requests/useUsersStatisticsAdmin';
import { useMemo } from 'react';
import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';
import { NativeCurrencyFormatWrapper } from '../utility/NativeCurrencyFormatWrapper';
import Preloader from '../Preloader';

export const AdminUserStatisticsWidget = () => {
  const { t } = useTranslation();
  const { statisticsInfo } = useUsersStatisticsAdmin();

  const allUsersPaids = useMemo(() => {
    if (statisticsInfo?.data) return Object.entries(statisticsInfo?.data?.paidsForUsers).reduce((sum, value) => sum + value[1], 0);
    return 0;
  }, [statisticsInfo?.data]);

  console.log(allUsersPaids);
  return (
    <>
      <div className="card">
        <div className="card__header">
          <div className="card__title">
            <div>
              <div>{t('nav.statistics')}</div>
            </div>
          </div>
          <div className="widget-wallets__statistics">
            {statisticsInfo?.data && !statisticsInfo.isLoading && !statisticsInfo.isFetching && (
              <div>
                <h3>
                  $<NativeCurrencyFormatWrapper value={allUsersPaids || 0} isUsd={true} />
                </h3>
                <span>{t('admin.widget.usersPaid')}</span>
              </div>
            )}
            {(statisticsInfo.isLoading || statisticsInfo.isFetching) && <Preloader loading={true} />}
          </div>
        </div>
        <div className="card__body">
          <Scrollbars style={{ height: 370 }} autoHide>
            <table className="widget-wallets__table">
              {statisticsInfo?.data && !statisticsInfo.isLoading && !statisticsInfo.isFetching && (
                <tbody>
                  <tr v-for="coin in wallet.coins">
                    <td>
                      <div>Активных пользователей</div>
                    </td>
                    <td className="bold w-fill a-right">{statisticsInfo?.data?.activeUsers.length}</td>
                  </tr>
                  <tr v-for="coin in wallet.coins">
                    <td>
                      <div>Оплативших активных пользователей</div>
                    </td>
                    <td className="bold w-fill a-right">{statisticsInfo?.data?.usersPaid.length}</td>
                  </tr>
                  <tr v-for="coin in wallet.coins">
                    <td>
                      <div>Кол-во активных торговых ботов</div>
                    </td>
                    <td className="bold w-fill a-right">{statisticsInfo?.data?.botsRealActiveCount}</td>
                  </tr>
                  <tr v-for="coin in wallet.coins">
                    <td>
                      <div>Кол-во активных ребалансов</div>
                    </td>
                    <td className="bold w-fill a-right">{statisticsInfo?.data?.mBotsRealActiveCount}</td>
                  </tr>
                  <tr v-for="coin in wallet.coins">
                    <td>
                      <div>Кол-во активных демо торговых ботов</div>
                    </td>
                    <td className="bold w-fill a-right">{statisticsInfo?.data?.botsDemoActiveCount}</td>
                  </tr>
                  <tr v-for="coin in wallet.coins">
                    <td>
                      <div>Кол-во активных демо ребалансов</div>
                    </td>
                    <td className="bold w-fill a-right">{statisticsInfo?.data?.mBotsDemoActiveCount}</td>
                  </tr>
                </tbody>
              )}
            </table>
          </Scrollbars>
        </div>
      </div>
    </>
  );
};
