import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useGetBotById = (type) => {
  const [id, setId] = useState();
  const navigate = useNavigate();
  const queryFn =
    type === 'rebalancer'
      ? ApiService.getBotRebalancerById
      : ApiService.getBotTradeById;

  const bot = useQuery({
    queryKey: ['bots', id],
    queryFn: () => queryFn(id),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
    refetchInterval: 10000,
    enabled: !!id,
    retry: false,
    onError: (error) => {
      if (error.response?.status === 404) {
        navigate(
          type === 'rebalancer'
            ? '/dashboard/bots/rebalancer'
            : '/dashboard/bots/trade'
        );
      }
    },
  });

  return { bot, setId };
};
