import useWebSocket from 'react-use-websocket';
import { useEffect, useState } from 'react';

export const BybitCurrentData = ({ symbols, bybitUpdate, active }) => {
  const [storedSymbols, setStoredSymbols] = useState();

  const { lastJsonMessage, sendJsonMessage } = useWebSocket(
    'wss://stream.bybit.com/v5/public/spot',
    {
      shouldReconnect: () => true,
    },
    active
  );

  useEffect(() => {
    if (!active) {
      setStoredSymbols(undefined);
      return;
    }
    if (JSON.stringify(symbols) === JSON.stringify(storedSymbols)) return;
    if (storedSymbols?.length) {
      sendJsonMessage({
        op: 'unsubscribe',
        args: storedSymbols.map((s) => `kline.120.${s.toUpperCase()}`),
      });
    }
    symbols.forEach((s) => {
      sendJsonMessage({
        op: 'subscribe',
        args: [`kline.120.${s.toUpperCase()}`],
      });
    });
    setStoredSymbols(symbols);
  }, [symbols, storedSymbols, setStoredSymbols, active]);

  useEffect(() => {
    if (lastJsonMessage && lastJsonMessage.type === 'snapshot') {
      bybitUpdate({
        data: {
          t: lastJsonMessage.data?.[0]?.start,
          c: lastJsonMessage.data?.[0]?.close,
          s: lastJsonMessage.topic?.split('.')?.[2],
        },
      });
    }
  }, [lastJsonMessage, bybitUpdate]);
};
