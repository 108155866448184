export const BOT_TYPES = {
  rebalancer: 'rebalancer',
  reseller: 'reseller',
  portfolio: 'portfolio',
  investment: 'investment',
};

export const BOT_TYPE_OPTIONS = [
  {
    value: BOT_TYPES.reseller,
    label: 'bots.add-bot.type.reseller',
    icon: 'perekup',
    text: 'bots.add-bot.type.reseller.text',
    tags: ['dca', 'short-periods'],
    type: 'trade',
  },
  {
    value: BOT_TYPES.portfolio,
    label: 'bots.add-bot.type.portfolio',
    icon: 'portfolio',
    text: 'bots.add-bot.type.portfolio.text',
    tags: ['risk-managment', 'any-periods'],
    type: 'portfolio',
  },
  {
    value: BOT_TYPES.investment,
    label: 'bots.add-bot.type.investment',
    icon: 'invest',
    text: 'bots.add-bot.type.investment.text',
    tags: ['dca', 'middle-periods'],
    type: 'trade',
  },
  {
    value: BOT_TYPES.rebalancer,
    label: 'bots.add-bot.type.rebalancer',
    icon: 'rebalance',
    text: 'bots.add-bot.type.rebalancer.text',
    tags: ['risk-managment', 'any-periods'],
    type: 'rebalancer',
  },
];

export const BOT_TYPE_VALUES = new Set(Object.values(BOT_TYPES));

export function isBotTypeValid(value) {
  return BOT_TYPE_VALUES.has(value);
}
