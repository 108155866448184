import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomModal from './CustomModal';

export const ConfirmModal = ({ open, onCancel, onOK, text, title }) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      show={open}
      onClose={() => onCancel()}
      header={title}
      footer={
        <>
          <Button
            onClick={() => onCancel()}
            variant="contained"
            color="warning"
          >
            {t('dialog.cancel')}
          </Button>
          <Button onClick={() => onOK()} variant="contained">
            {t('dialog.ok')}
          </Button>
        </>
      }
    >
      {text}
    </CustomModal>
  );
};
