import { useGetNotifications } from '../hooks/http-requests/useGetNotifications';
import { NativeDateLocaleWrapper } from '../components/utility/NativeDateLocaleWrapper';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const Notifications = () => {
  const { getNotifications } = useGetNotifications();

  return (
    <table className="table-shadow">
      <tbody>
        {getNotifications.data?.data?.map((n, i) => (
          <tr key={i}>
            <td>
              <div className="description">
                <NativeDateLocaleWrapper
                  date={n.created_at}
                  options={{
                    day: '2-digit',
                    month: 'short',
                    hour: '2-digit',
                    minute: '2-digit',
                  }}
                />
              </div>
              <div>
                <span dangerouslySetInnerHTML={{ __html: n.text }}></span>
                {!!n.bot_id && (
                  <>
                    {' '}
                    <RouterLink to={`/dashboard/bots/trade/${n.bot_id}`}>Страница бота</RouterLink>
                  </>
                )}
              </div>
            </td>
            <td className="c-green">{n.is_read ? '' : 'new'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
