import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';
import { useState } from 'react';

export const useWalletCoinsList = () => {
  const [id, setId] = useState();

  const coinsList = useQuery({
    queryKey: ['walletCoins', id],
    queryFn: () => ApiService.getWalletCoinsList(id),
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
  });

  return { coinsList, setId };
};
