import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useGetPayments = () => {
  const payments = useQuery({
    queryKey: ['payments'],
    queryFn: ApiService.getPayments,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
  });

  return { payments };
};
