export const timeZonesTV = [
  { offset: '-10', name: 'Pacific/Honolulu' },
  { offset: '-8', name: 'America/Juneau' },
  { offset: '-7', name: 'America/Vancouver' },
  { offset: '-6', name: 'America/Denver' },
  { offset: '-5', name: 'America/Lima' },
  { offset: '-4', name: 'America/Toronto' },
  { offset: '-3', name: 'America/Sao_Paulo' },
  { offset: '+1', name: 'Africa/Tunis' },
  { offset: '+2', name: 'Europe/Zurich' },
  { offset: '+3', name: 'Europe/Vilnius' },
  { offset: '+3:30', name: 'Asia/Tehran' },
  { offset: '+4', name: 'Asia/Muscat' },
  { offset: '+5', name: 'Asia/Karachi' },
  { offset: '+5:30', name: 'Asia/Kolkata' },
  { offset: '+5: 45', name: 'Asia/Kathmandu' },
  { offset: '+6', name: 'Asia/Dhaka' },
  { offset: '+6:30', name: 'Asia/Yangon' },
  { offset: '+7', name: 'Asia/Jakarta' },
  { offset: '+8', name: 'Asia/Taipei' },
  { offset: '+9', name: 'Asia/Tokyo' },
  { offset: '+10', name: 'Australia/Brisbane' },
  { offset: '+10:30', name: 'Australia/Adelaide' },
  { offset: '+11', name: 'Australia/Sydney' },
  { offset: '+12', name: 'Pacific/Norfolk' },
  { offset: '+13', name: 'Pacific/Fakaofo' },
  { offset: '+13:45', name: 'Pacific/Chatham' },
];
