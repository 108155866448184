import CustomModal from './CustomModal';
import { useTranslation } from 'react-i18next';
import { useGetTariffs } from '../hooks/http-requests/useGetTariffs';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useCreateDonationPayment } from '../hooks/http-requests/useCreateDonationPayment';

export const DonateButton = ({ color }) => {
  const { t } = useTranslation();
  const { tariffs } = useGetTariffs();
  const [currencyName, setCurrencyName] = useState('');
  const [amount, setAmount] = useState();
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { createDonationPayment } = useCreateDonationPayment();

  useEffect(() => {
    if (tariffs.data?.currencies?.length && !currencyName) {
      setCurrencyName(tariffs.data.currencies[0]);
    }
  }, [tariffs.data]);

  const onCreatePayment = () => {
    const currency_id = tariffs.data.currencies.findIndex(
      (item) => item === currencyName
    );
    createDonationPayment
      .mutateAsync({ currency_id, price: amount })
      .then(({ pay_url }) => {
        if (pay_url) {
          window.location.href = pay_url;
        } else {
          setSnackbarOpen(true);
        }
      })
      .catch(() => {
        setSnackbarOpen(true);
      });
  };

  return (
    <>
      <Button
        variant="text"
        onClick={() => setOpen(true)}
        sx={{ color: color || 'var(--base-900)' }}
      >
        {t('donate.button')}
      </Button>
      <CustomModal
        show={open}
        onClose={() => setOpen(false)}
        header={t('donate.title')}
      >
        {!!tariffs.data?.currencies && (
          <>
            <p className="description">{t('donate.description')}</p>
            <Autocomplete
              className="mt-24"
              value={currencyName}
              onChange={(_, value) => setCurrencyName(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="currency"
                  label={t('payments.currency.title')}
                />
              )}
              options={tariffs.data?.currencies?.map((c) => c)}
            />
            <TextField
              name="amount"
              type="number"
              label={t('bots.operations.amount')}
              sx={{ width: '100%', marginTop: '24px' }}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <Box
              className="ai-center mt-24"
              sx={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="caption">
                {!!amount && (
                  <>
                    {t('payments.dialog.forpay')}{' '}
                    <Typography variant="h8">{amount} USDT</Typography>
                  </>
                )}
              </Typography>
              <Button
                variant="contained"
                onClick={onCreatePayment}
                disabled={
                  createDonationPayment.isLoading || !currencyName || !amount
                }
              >
                {t('payments.pay')}
              </Button>
            </Box>
          </>
        )}
      </CustomModal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error">{t('payments.error')}</Alert>
      </Snackbar>
    </>
  );
};
