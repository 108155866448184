import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useMarkAsRead = () => {
  const queryClient = useQueryClient();

  const markAsRead = useMutation(ApiService.markAsRead, {
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['hasNewNotifications'] });
    },
  });

  return { markAsRead };
};
