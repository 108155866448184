import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';
import { useMarkAsRead } from './useMarkAsRead';

export const useGetNotifications = () => {
  const { markAsRead } = useMarkAsRead();

  const getNotifications = useQuery({
    queryKey: ['getNotifications'],
    queryFn: () => ApiService.getNotifications({ page: 1 }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 0,
    onSuccess: async () => {
      await markAsRead.mutateAsync(undefined);
    },
  });

  return { getNotifications };
};
