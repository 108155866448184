import Chart from 'react-apexcharts';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NomolizeColimnChartData from './NomolizeColimnChartData';

export const ColumnChart = ({ data, withDetails }) => {
  const { i18n } = useTranslation();

  const allOperations = useMemo(() => {
    if (!data) return [];

    let allOperations = new Map();

    Object.keys(data).forEach((s) => {
      data[s].forEach((t) => {
        const key = new Date(t.date).getTime();
        if (allOperations.has(key)) {
          allOperations.set(key, allOperations.get(key) + t.profit);
        } else {
          allOperations.set(key, t.profit);
        }
      });
    });

    return NomolizeColimnChartData(Array.from(allOperations));
  }, [data]);

  const isMonth = useMemo(
    () => (allOperations.length ? new Date(allOperations[0][0]).getMonth() !== new Date(allOperations[allOperations.length - 1][0]).getMonth() : false),
    [allOperations]
  );

  const series = useMemo(() => {
    if (data)
      if (withDetails) {
        let d = Object.keys(data).map((s) => ({
          name: s,
          data: data[s].map((d) => [new Date(d.date).getTime(), d.profit]),
        }));

        const res = d.map((e) => {
          const n = NomolizeColimnChartData(e.data);
          const all = allOperations.map((e) => e[0]);

          const data = all.map((al) => {
            const indx = n.findIndex((n) => n[0] === al);
            return indx !== -1 ? n[indx][1] : 0;
          });
          return { ...e, data };
        });
        return res;
      } else {
        return [
          {
            name: 'Прибыль',
            data: allOperations,
          },
        ];
      }
    else
      return [
        {
          name: 'Прибыль',
          data: [],
        },
      ];
  }, [allOperations, data, withDetails]);

  const dateFormater = (val) => {
    return new Intl.DateTimeFormat(i18n.language === 'ru' ? 'ru' : 'en-US', { month: 'short', year: '2-digit' }).format(new Date(val));
  };

  const options = useMemo(
    () => ({
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: { show: false },
        sparkline: { enabled: true },
        zoom: { enabled: false },
      },
      tooltip: {
        enabled: true,
        followCursor: false,
        onDatasetHover: {
          highlightDataSeries: true,
        },
      },
      fill: {
        colors: ['#45ac00'],
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          colors: {
            ranges: [
              {
                from: -999999,
                to: 0,
                color: 'var(--accent-red-500)',
              },
            ],
          },
        },
      },
      xaxis: {
        type: 'datetime',
        categories: allOperations.map((e) => e[0]),
        labels: {
          formatter: (val) => dateFormater(val),
        },
      },
      yaxis: {
        tooltip: {
          enabled: false,
        },
        opposite: true,
        labels: {
          formatter: function (val) {
            return '$' + val.toFixed(2);
          },
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allOperations]
  );

  const withDetailsOptions = useMemo(
    () => ({
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: { show: false },
        zoom: { enabled: false },
        fontFamily: 'Montserrat, Arial, sans-serif',
        defaultLocale: 'ru',
        locales: [
          {
            name: 'ru',
            options: {
              shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Нояб', 'Дек'],
            },
          },
        ],
      },
      yaxis: {
        tooltip: {
          enabled: false,
        },
        opposite: true,
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 500,
          },
          formatter: function (val) {
            return '$' + val.toFixed(2);
          },
        },
      },
      xaxis: {
        // type: 'datetime',
        categories: allOperations.map((e) => e[0]),
        tooltip: {
          enabled: false,
        },
        opposite: true,
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 500,
          },
          formatter: (val) => dateFormater(val),
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            total: {
              enabled: false,
              formatter: (a) => '$' + a.toFixed(2),
              style: {
                fontSize: '13px',
                fontWeight: 700,
              },
            },
          },
        },
      },
      dataLabels: { enabled: false },
      tooltip: {
        enabled: true,
        shared: false,
      },
      grid: {
        borderColor: '#ddd',
        strokeDashArray: '10',
        padding: { bottom: 15 },
      },
      legend: {
        fontSize: '14px',
        fontWeight: '600',
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allOperations]
  );

  return <Chart type="bar" options={withDetails ? withDetailsOptions : options} series={series} height={withDetails ? '420px' : '100px'} />;
};
