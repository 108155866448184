import Axios from 'axios';

const apiService = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
apiService.interceptors.request.use((config) => {
  const token = window.localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

class ApiServiceImpl {
  getUser() {
    return apiService.get('/user').then((response) => response.data?.message);
  }

  updateUser({ name, locale }) {
    return apiService.patch('/user', { name, locale }).then((response) => response.data?.message);
  }

  getBalanceInfo(id) {
    const limit = !id ? 5 : undefined;
    return apiService
      .get('/balanceinfo', {
        params: { id, limit },
      })
      .then((response) => response.data?.message);
  }

  reloadOperations(userdataid) {
    return apiService
      .get('/operations', {
        params: {
          limit: 1,
          page: 1,
          force: true,
          userdataid,
        },
      })
      .then((response) => response.data?.message);
  }

  getOperations({ page, limit, force, userdataid, coin, from, to }) {
    return apiService
      .get('/operations', {
        params: {
          limit,
          page,
          force,
          userdataid,
          coin,
          from: from ? +from : undefined,
          to: to ? +to : undefined,
        },
      })
      .then((response) => response.data?.message);
  }

  getAvailableWallets() {
    return apiService.get('/exchangelist').then((response) => response.data?.message);
  }

  addWallet(request) {
    return apiService.put('/addwallet', request).then((response) => response.data?.message);
  }

  deleteWallet(id) {
    return apiService.delete(`/wallet/${id}`).then((response) => response.data?.message);
  }

  getUserWallets() {
    return apiService.get('/mywallets').then((response) => response.data?.message);
  }

  getCountBots() {
    return apiService.get('/bot/count').then((response) => response.data?.message);
  }

  getBots({ search, type, status, wallet }) {
    if (!type) type = ['reseller', 'investment']; //not portfolio bots

    return apiService
      .get('/bot/list', {
        params: { search, type, status, wallet },
      })
      .then((response) => response.data?.message);
  }

  getPortfolioBots({ search, status, wallet }) {
    const type = 'portfolio';

    return apiService
      .get('/bot/list', {
        params: { search, type, status, wallet },
      })
      .then((response) => response.data?.message);
  }

  getPortfolioBotsAdmin({ search, status, user_id }) {
    const type = 'portfolio';

    return apiService
      .get('/admin/bot/list', {
        params: { search, type, status, user_id },
      })
      .then((response) => response.data?.message);
  }

  getBotsAdmin({ search, type, status, user_id }) {
    if (!type) type = ['reseller', 'investment']; //not portfolio bots

    return apiService
      .get('/admin/bot/list', {
        params: { search, type, status, user_id },
      })
      .then((response) => response.data?.message);
  }

  getBotsStatistics() {
    return apiService.get('/tradebots/statistics').then((response) => response.data?.message);
  }

  getMultiBots({ search, wallet }) {
    return apiService
      .get('/multibots/list', {
        params: { search, wallet },
      })
      .then((response) => response.data?.message);
  }

  getCountMultiBots() {
    return apiService.get('/multibots/count').then((response) => response.data?.message);
  }

  getMultiBotsAdmin({ search, user_id }) {
    return apiService
      .get('/admin/multibots/list', {
        params: { search, user_id },
      })
      .then((response) => response.data?.message);
  }

  getMultiBotsStatistics() {
    return apiService.get('/multibots/statistics').then((response) => response.data?.message);
  }

  getBotTradeById(id) {
    return apiService.get(`/bot/${id}`).then((response) => response.data?.message);
  }

  getBotRebalancerById(id) {
    return apiService.get(`/multibots/${id}`).then((response) => response.data?.message);
  }

  getBotTradeOperationsById({ id, page, limit }) {
    return apiService
      .get(`/bot/${id}/operations`, {
        params: { page, limit },
      })
      .then((response) => response.data?.message);
  }

  getBotRebalancerOperationsById({ id, page, limit }) {
    return apiService
      .get(`/multibots/${id}/operations`, {
        params: { page, limit },
      })
      .then((response) => response.data?.message);
  }

  changeTradeBotStatus({ botId, status, disableAfterClosingDeal }) {
    return apiService.post('/bot/toggle', { botId, status, disableAfterClosingDeal }).then((response) => response.data?.message);
  }

  changeRebalancerBotStatus({ botId, status }) {
    return apiService.post('/multibots/toggle', { botId, status }).then((response) => response.data?.message);
  }

  disableAllBots() {
    return apiService.post('/bot/disable-all').then((response) => response.data?.message);
  }

  createTradeBot(request) {
    return apiService.put('/bot/create', request).then((response) => response.data?.message);
  }

  createMultiBot(request) {
    return apiService.post('/multibots/create', request).then((response) => response.data?.message);
  }

  patchBot(request) {
    const { id, ...body } = request;
    return apiService.patch(`/bot/${id}`, body).then((response) => response.data?.message);
  }

  deleteTradeBot(id) {
    return apiService.delete(`/bot/${id}`).then((response) => response.data?.message);
  }

  deleteRebalancerBot(botId) {
    return apiService.post(`/multibots/delete/${botId}`).then((response) => response.data?.message);
  }

  getCoinsList() {
    return apiService.get('/operations/coins').then((response) => response.data?.message);
  }

  getWalletCoinsList(id) {
    return apiService.get('/bot/coins', { params: { id } }).then((response) => response.data?.message);
  }

  getPayments() {
    return apiService.get('/payment').then((response) => response.data?.message);
  }

  getReferralList(query) {
    return apiService.get('/referrals', { params: { ...query } }).then((response) => response.data?.message);
  }

  createPayment({ tariff_id, currency_id }) {
    return apiService.post('/payment/create', { tariff_id, currency_id }).then((response) => response.data?.message);
  }

  createDonationPayment({ currency_id, price }) {
    return apiService.post('/payment/donate/create', { currency_id, price }).then((response) => response.data?.message);
  }

  createBonusPayment({ tariff_id }) {
    return apiService.post('/payment/bonus/create', { tariff_id }).then((response) => response.data?.message);
  }

  getTariffs() {
    return apiService.get('/payment/tariffs').then((response) => response.data?.message);
  }

  withdrawBonuses({ amount, wallet }) {
    return apiService.post('/payment/withdraw-bonuses', { amount, wallet }).then((response) => response.data?.message);
  }

  getBonusOperations() {
    return apiService.get('/payment/bonus-operations').then((response) => response.data?.message);
  }

  getExchangeSymbolsInfo({ exchange, symbol, page, limit, sortBy, sortDir, volumeFrom, volumeTo }) {
    return apiService
      .get('/exchange/coins', {
        params: {
          exchange,
          symbol,
          page,
          limit,
          sortBy,
          sortDir,
          volumeFrom,
          volumeTo,
        },
      })
      .then((response) => response.data?.message);
  }

  getUsersInfo({ page, limit, sortBy, sortDir, search }) {
    return apiService
      .get('/admin/users', {
        params: { page, limit, sortBy, sortDir, search },
      })
      .then((response) => response.data);
  }

  getAdminExchangeSymbolsInfo({ exchange, symbol, page, limit, sortBy, sortDir, volumeFrom, volumeTo }) {
    return apiService
      .get('/admin/symbols/index', {
        params: {
          exchange,
          symbol,
          page,
          limit,
          sortBy,
          sortDir,
          volumeFrom,
          volumeTo,
        },
      })
      .then((response) => response.data?.message);
  }

  toggleSymbolBlocked({ exchange, symbol, blocked }) {
    return apiService.post('/admin/symbols/toggle', { exchange, symbol, blocked }).then((response) => response.data?.message);
  }

  getAdminReferralUsers({ search, page, limit }) {
    return apiService
      .get('/admin/users/referral', {
        params: { search, page, limit },
      })
      .then((response) => response.data?.message);
  }

  getUserWithdrawRequests(id) {
    return apiService.get(`/admin/user/${id}/withdraw-requests`).then((response) => response.data?.message);
  }

  fulfillWithdrawRequest(id) {
    return apiService.post(`/admin/withdraw-requests/${id}/fulfill`).then((response) => response.data?.message);
  }

  getBalanceInfoAdmin() {
    return apiService.get(`/admin/balanceInfo`).then((response) => response.data?.message);
  }

  getUsersStatisticsAdmin() {
    return apiService.get(`/admin/statistics`).then((response) => response.data?.message);
  }

  hasNewNotifications() {
    return apiService.get('/notifications/has-new').then((response) => response.data?.message);
  }

  getNotifications({ page, limit }) {
    return apiService
      .get('/notifications', {
        params: { page, limit },
      })
      .then((response) => response.data?.message);
  }

  markAsRead() {
    return apiService.post('/notifications/mark-as-read').then((response) => response.data?.message);
  }

  telegramUnsubscribe() {
    return apiService.post('/notifications/telegram/unsubscribe').then((response) => response.data?.message);
  }

  toggleShowUnimportant(showUnimportant) {
    return apiService.post('/notifications/toggle-unimportant', { showUnimportant }).then((response) => response.data?.message);
  }

  getBotsProfitData(test, exchangeId, portfolio) {
    return apiService
      .get(`/bot/profit`, {
        params: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC',
          test,
          exchangeId,
          portfolio,
        },
      })
      .then((response) => response.data?.message);
  }

  getCurrencyList() {
    return apiService.get('/p2p/currency').then((response) => response.data?.message);
  }

  getFromTo(request) {
    return apiService.put('/p2p/fromTo', request).then((response) => response.data?.message);
  }

  getToFrom(request) {
    return apiService.put('/p2p/toFrom', request).then((response) => response.data?.message);
  }

  getBybitKlinesHistoricData({ symbol, interval, limit }) {
    return apiService
      .get('/bybit/klines/history', {
        params: { symbol, interval, limit },
      })
      .then((response) => response.data?.message);
  }

  createEmulations(request) {
    return apiService.post('/emulations', request).then((response) => response.data?.message);
  }
}

export const ApiService = new ApiServiceImpl();
