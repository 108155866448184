import React from 'react';

const Preloader = ({ locading }) => {
  return (
    <div className="preloader">
      <img src="/img/preloader.svg" alt="" />
    </div>
  );
};

export default Preloader;
