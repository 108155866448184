import { Cached } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export const ReloadButton = ({ loading, onClick }) => {
  return (
    <IconButton aria-label="reload" onClick={onClick} disabled={loading}>
      <Cached
        sx={
          loading
            ? {
                width: 25,
                height: 25,
                animation: 'spin 2s linear infinite',
                '@keyframes spin': {
                  '0%': { transform: 'rotate(360deg)' },
                  '100%': { transform: 'rotate(0deg)' },
                },
              }
            : { width: 25, height: 25 }
        }
      />
    </IconButton>
  );
};
