import { useTranslation } from 'react-i18next';
import Icon from './Icons';
import './WellcomeBlock.scss';
import { Alert } from '@mui/material';
import { useUserWallets } from '../hooks/http-requests/useUserWallets';

export const WellcomeBlock = () => {
  const { userWallets } = useUserWallets();
  const { t } = useTranslation();

  return (
    <>
      {userWallets?.data?.length === 0 && (
        <div className="card">
          <div className="wellcome-block">
            <div>
              {t('auth.hello')} <br />
              <br />
              {t('welcome-block.no-wallets')}
              <br />
              <br />
              <Alert variant="filled" severity="info">
                {t('welcome-block.wallets-no-warn')}
              </Alert>
              <div className="telegram-chanel">
                <Icon className="-icon" icon={'telegram'} />
                <span>
                  {t('profile.telegram.subscribe.group')}{' '}
                  <a href="https://t.me/Crypto_bot_2023" target="_blank" rel="noreferrer">
                    @Crypto_bot_2023
                  </a>
                </span>
              </div>
            </div>
          </div>
          <br />
        </div>
      )}
    </>
  );
};
