import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useCreateBot = () => {
  const queryClient = useQueryClient();

  const createBot = useMutation(ApiService.createTradeBot, {
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['bots/list'] });
    },
  });

  return { createBot };
};
