import { PriceChart } from '../utility/PriceChart';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NativeDateLocaleWrapper } from '../utility/NativeDateLocaleWrapper';
import { NativeCurrencyFormatWrapper } from '../utility/NativeCurrencyFormatWrapper';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/utility/useAuth';
import './BotTradeWidget.scss';
import { Alert, CircularProgress, IconButton, Snackbar, Tooltip } from '@mui/material';
import { DifferenceBetweenDates } from '../utility/DifferenceBetweenDates';
import { DisableBotModal } from './DisableBotModal';
import * as React from 'react';
import { ConfirmModal } from '../ConfirmModal';
import { useDeleteTradeBot } from '../../hooks/http-requests/useDeleteTradeBot';
import Icon from '../Icons';
import { useDeletePortfolioBot } from '../../hooks/http-requests/useDeletePortfolioBot';

export const BotTradeWidget = ({ bot, lastPrice, historicPrices, withoutChart, changeBotStatus }) => {
  const { deleteTradeBot } = useDeleteTradeBot();
  const { deletePortfolioBot } = useDeletePortfolioBot();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAdmin } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmDelOpen, setModalConfirmDelOpen] = useState(false);
  const [deleteBotId, setDeleteBotId] = useState();

  const series = useMemo(() => historicPrices?.map((p) => p.value), [historicPrices]);

  const currentPrice = useMemo(() => lastPrice || (!!series ? series[series?.length - 1] : 0), [lastPrice, series]);

  const startPrice = useMemo(() => (!!bot.volume_coins ? bot.total / bot.volume_coins : null), [bot]);

  const color = useMemo(() => (currentPrice < startPrice ? 'c-red' : 'c-green'), [startPrice, currentPrice]);

  const difference = useMemo(
    () => (!!bot.volume_coins && !!bot.total && currentPrice ? `${((currentPrice / (bot.total / bot.volume_coins) - 1) * 100).toFixed(2)}%` : '-'),
    [bot, currentPrice]
  );

  const profitPercent = useMemo(() => (bot.fund ? ((bot.fund + bot.profit_bot) / bot.fund - 1) * 100 : 0), [bot]);

  const balance = useMemo(() => (currentPrice && !isNaN(currentPrice) ? bot.volume_coins * currentPrice : 0), [bot, currentPrice]);

  const currentBalance = useMemo(() => balance + bot.fund - bot.total + bot.profit_bot, [balance, bot]);

  const botStatus = useMemo(() => {
    if (bot.status === 'active') return t('bots.widget.status.active');
    switch (bot.disable_reason) {
      case 'user':
        return t('bots.widget.status.disabled-by-user');
      case 'stopLoss':
        return t('bots.widget.status.disabled-by-stop-loss');
      case 'profit':
        return t('bots.widget.status.disabled-by-profit');
      case 'system':
        return t('bots.widget.status.disabled-by-system');
      case 'insufficientFundsCoin':
        return t('bots.widget.status.disabled-by-insufficient-funds');
      default:
        return '';
    }
  }, [bot.disable_reason, bot.status, t]);

  const onChangeBotStatus = (e) => {
    e.stopPropagation();
    if (bot.status === 'disabled') {
      changeBotStatus?.(bot.id, 'active');
    } else setModalOpen(true);
  };

  const onDisableBotModalClose = (closeTime) => {
    if (closeTime === 'now') {
      changeBotStatus?.(bot.id, 'disabled');
    }
    if (closeTime === 'after-sale') {
      changeBotStatus?.(bot.id, 'active', true);
    }
    setModalOpen(false);
  };

  const gotoBot = (bot_id) => {
    if (isAdmin()) {
      navigate(`/admin/bots/trade/${bot_id}`);
    } else {
      navigate(`/dashboard/bots/trade/${bot_id}`);
    }
  };
  const deleteBotClick = (id) => {
    setModalConfirmDelOpen(true);
    setDeleteBotId(id);
  };

  const isPortfolio = bot.bot_type === 'portfolio';

  const deleteBotConfirm = () => {
    if (isPortfolio) {
      deletePortfolioBot.mutateAsync(deleteBotId).then(() => navigate(`/dashboard/bots/portfolio`));
    } else {
      deleteTradeBot.mutateAsync(deleteBotId).then(() => navigate(`/dashboard/bots/trade`));
    }
  };

  const botName = `${bot?.symbol?.replace('USDT', ' / USDT')}${bot.name ? ' "' + bot.name + '"' : ''}`;
  const slopLossPrc = useMemo(() => bot.stop_loss - profitPercent + (currentBalance / bot.fund - 1) * 100, [currentBalance, bot, profitPercent]);

  return (
    <>
      <ConfirmModal
        open={modalConfirmDelOpen}
        onCancel={() => setModalConfirmDelOpen(false)}
        title={t('confirm.bot.delete.title')}
        onOK={() => {
          deleteBotConfirm();
          setModalConfirmDelOpen(false);
        }}
        text={t('confirm.bot.delete.text')}
      />
      <DisableBotModal open={modalOpen} onClose={onDisableBotModalClose} />
      <div
        className={`${bot.emulate_operations ? '-test-bot' : ''} ${!withoutChart ? 'card bots-trade-widget' : ''} ${
          bot.status === 'active' ? `-active` : `-deactive`
        }`}
      >
        {!withoutChart ? (
          <>
            <div className="bots-trade-widget__title">
              <div className="flex">
                <img className="-icon-symbol" src={process.env.REACT_APP_ICONS_SERVER + bot.symbol.toLowerCase().replace('usdt', '')} alt="" />
                <div>
                  <div className="link -link" onClick={() => gotoBot(bot?.id)}>
                    {botName}
                  </div>
                  <div className="bots-trade-widget__desc">
                    {t(`bots.widget.type.${bot.bot_type}`)}
                    {bot.emulate_operations ? ` (${t('bots.summary-widget.summary-test')})` : ''}
                  </div>
                </div>
              </div>
              {bot.status === 'active' ? (
                <div onClick={(e) => onChangeBotStatus(e)} className={`toggle -active`}></div>
              ) : (
                <IconButton variant="contained" onClick={() => deleteBotClick(bot.id)}>
                  <Icon icon="delete" />
                </IconButton>
              )}
            </div>
            {bot.status === 'active' && (
              <Tooltip arrow title={t(`bots.widget.weekly-chart`)}>
                <div className="bots-trade-widget__chart">
                  <PriceChart series={series} />
                </div>
              </Tooltip>
            )}
          </>
        ) : (
          <Snackbar open={!currentPrice} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert sx={{ boxShadow: '0 0 15px rgba(0,0,0,0.37)' }} icon={<CircularProgress color="primary" size={20} />} severity="info">
              {t('bots.loading-price')}
            </Alert>
          </Snackbar>
        )}
        <div className="bots-trade-widget__content">
          {/* баланс */}
          <div className="bots-trade-widget__label">
            <div className="-text">
              {t('bots.summary-widget.balance-bot')}
              <div className="f-small c-base-600">{t('bots.summary-widget.assets-sum')}</div>
            </div>
            <div className={`-value ${currentBalance > bot.fund ? 'c-green' : 'c-red'}`}>
              {!currentPrice && bot.status === 'active' ? (
                <CircularProgress sx={{ mt: 1 }} color="primary" size={18} />
              ) : (
                <>
                  <div className="-price">
                    $
                    <NativeCurrencyFormatWrapper value={currentBalance} isUsd={true} />
                  </div>
                  <div className="f-small">
                    $
                    <NativeCurrencyFormatWrapper value={currentBalance - bot.fund} isUsd={true} /> ({((currentBalance / bot.fund - 1) * 100).toFixed(2)}%)
                  </div>
                </>
              )}
            </div>
          </div>

          {bot.status === 'active' && (
            <>
              {/* тек. цена */}
              <div className="bots-trade-widget__label">
                <div className="-text">
                  <div>{t('bots.widget.type.current-price')}</div>
                </div>
                <div className={`-value`}>
                  {!currentPrice ? (
                    <CircularProgress color="primary" size={18} />
                  ) : (
                    <div>
                      $
                      <NativeCurrencyFormatWrapper value={currentPrice || 0} isUsd={false} />
                    </div>
                  )}
                </div>
              </div>

              {/* блок куплено */}
              <div className="bots-trade-widget__label">
                <div className="-text">
                  <div>{t('bots.widget.volume')}</div>
                  <div>{t('calculator.middleresult.text')}</div>
                  {!isPortfolio && <div>{t('bots.summary-widget.reserved')}</div>}
                </div>
                {!currentPrice ? (
                  <CircularProgress sx={{ mt: 1 }} color="primary" size={18} />
                ) : (
                  <div className={`-value`}>
                    <div>
                      <NativeCurrencyFormatWrapper value={bot.volume_coins || 0} isUsd={false} /> / $
                      <NativeCurrencyFormatWrapper value={bot.total || 0} isUsd={true} />
                    </div>
                    <div className={!isPortfolio ? color : ''}>
                      {!!bot.volume_coins ? (
                        <>
                          $<NativeCurrencyFormatWrapper value={startPrice} isUsd={false} maximumFractionDigits={6} />
                        </>
                      ) : (
                        '-'
                      )}{' '}
                      {!isPortfolio && <span>({difference})</span>}
                    </div>
                    {!isPortfolio && (
                      <div>
                        $<NativeCurrencyFormatWrapper value={bot.fund + (bot.is_reinvest ? bot.profit_bot : 0) - bot.total || 0} isUsd={true} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}

          {/* блок закрытых сделок */}
          <div className="bots-trade-widget__label">
            <div className="-text">
              {!isPortfolio && <div>{t('bots.widget.closed-deals')}</div>}
              <div>{t('bots.widget.commission')}</div>
              {!isPortfolio && <div>{t('bots.widget.profit')}</div>}
              {/* <div>{t('bots.widget.profit-day')}</div> */}
            </div>
            <div className={`-value`}>
              {!isPortfolio && (
                <div>
                  {bot.closed_count}
                  {t('bots.widget.pieces')}
                </div>
              )}
              <div>
                $<NativeCurrencyFormatWrapper value={bot.comission} isUsd={true} maximumFractionDigits={2} />
              </div>
              {!isPortfolio && (
                <div className={profitPercent > 0 ? 'c-green' : 'c-red'}>
                  $<NativeCurrencyFormatWrapper value={bot.profit_bot || 0} isUsd={true} /> ({profitPercent.toFixed(2)}%)
                </div>
              )}

              {/* <div className={profitPercentDay > 0 ? 'c-green' : 'c-red'}>
                $
                <NativeCurrencyFormatWrapper value={profitDay || 0} isUsd={true} /> ({profitPercentDay.toFixed(2)}%)
              </div> */}
            </div>
          </div>

          {/* до стоп лосса */}
          {/* <div className="bots-trade-widget__label">
            <div className="-text">{t('bots.widget.stoploss-percent')}</div>
            <div className="-value">
              {!currentPrice ? <CircularProgress color="primary" size={18} /> : <div>{bot.is_stop_loss ? `${slopLossPrc.toFixed(2)}%` : '-'}</div>}
            </div>
          </div> */}

          {/* разделитель */}
          <div className="bots-trade-widget__label">
            <div className="-text w-fill">
              <hr />
            </div>
          </div>
          {/* нач. кап блок */}
          <div className="bots-trade-widget__label">
            <div className="-text f-small c-base-600">
              <div>{t('bots.summary-widget.start-balance')}</div>
              {!isPortfolio && <div>{t('bots.add-bot.draw-down')}</div>}
              <div>Stop loss</div>
              <div>Take profit</div>
              {!isPortfolio && <div>{t('bots.add-bot.reinvest')}</div>}
              {!isPortfolio && <div>{t('bots.widget.use-rsi')}</div>}
            </div>
            <div className={`-value f-small c-base-600`}>
              <div>
                $<NativeCurrencyFormatWrapper value={bot.fund} isUsd={false} />
              </div>
              {!isPortfolio && <div>{bot.drawdown ? `${bot.drawdown}%` : '-'}</div>}

              <div>{bot.is_stop_loss ? `${bot.stop_loss}% (${slopLossPrc.toFixed(2)}%)` : '-'}</div>
              <div>{bot.is_exit_after_profit ? `${bot.profit}%` : '-'}</div>
              {!isPortfolio && <div>{bot.is_reinvest ? t('payments.yes') : '-'}</div>}
              {!isPortfolio && <div>{bot.use_rsi ? t('bots.widget.use-rsi.yes') : t('bots.widget.use-rsi.no')}</div>}
            </div>
          </div>
        </div>
        {/* статус */}
        <div className="bots-trade-widget__label">
          <div className="-text f-small c-base-600">
            <div>{t('bots.list.status')}</div>
          </div>
          <div className={`-value f-small c-base-600`}>
            <div>
              {botStatus} {bot.disable_after_closing_deal && <>({t('bots.widget.shutdown-after-sale')})</>}
            </div>
          </div>
        </div>
        {/* даты блок */}
        <div className="bots-trade-widget__label">
          <div className="-text f-small c-base-600">
            <div>{t('bots.widget.open-date')}</div>
            {!isPortfolio && <div>{t('bots.widget.start-date')}</div>}
            <div>{t('bots.widget.created-bot')}</div>
            <div>{t('operations.filters.wallet')}</div>
          </div>
          <div className={`-value f-small c-base-600`}>
            <div>
              {bot.open_date && bot.status_trade !== 'sold' ? (
                <NativeDateLocaleWrapper
                  date={bot.open_date}
                  options={{
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  }}
                />
              ) : (
                '-'
              )}
            </div>
            {!isPortfolio && (
              <div>
                {bot.created_at ? (
                  <NativeDateLocaleWrapper
                    date={bot.created_at}
                    options={{
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                    }}
                  />
                ) : (
                  '-'
                )}
              </div>
            )}

            <div>{bot.created_at ? <DifferenceBetweenDates date={bot.created_at} lastDate={bot.status !== 'active' ? bot.updated_at : undefined} /> : '-'}</div>
            <div>{`${t(`wallet.${bot.type.toUpperCase()}`)}`}</div>
          </div>
        </div>
      </div>
    </>
  );
};
