import Chart from 'react-apexcharts';
import { useMemo } from 'react';

export const DonutChart = ({ series, colors }) => {
  const options = useMemo(
    () => ({
      series,
      colors,
      legend: false,
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      chart: {
        sparkline: {
          enabled: false,
        },
        parentHeightOffset: 0,
        width: '100%',
        height: '100%',
      },
      grid: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    }),
    [colors, series]
  );

  return <Chart type="donut" options={options} series={series} />;
};
