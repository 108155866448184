import { useQueryClient, useMutation } from '@tanstack/react-query';
import { ApiService } from '../../utils/api-service';

export const useDeleteTradeBot = () => {
  const queryClient = useQueryClient();

  const deleteTradeBot = useMutation(ApiService.deleteTradeBot, {
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['bots/list'] });
    },
  });
  return { deleteTradeBot };
};
