import * as React from 'react';
import { RouterProvider } from 'react-router-dom';
// import './input.scss';
import './App.scss';
import router from './Routes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { QueryClient } from '@tanstack/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import DoneIcon from '@mui/icons-material/Done';

const theme = createTheme({
  palette: {
    primary: {
      main: '#9800FF',
    },
    white: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#333740',
      secondary: '#000',
    },
    background: {
      default: '#F4F5FA',
      paper: '#ffffff',
    },
    error: {
      main: '#E4565A',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
  components: {
    MuiAlert: {
      variants: [
        {
          props: { severity: 'primary' },
          style: {
            color: '#fff',
            backgroundColor: 'var(--primary-500)',
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'body1' },
          style: {
            color: '#333740',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
          },
        },
        {
          props: { variant: 'subtitle2' },
          style: {
            color: '#888E9D',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '18px',
          },
        },
        {
          props: { variant: 'h6' },
          style: {
            color: '#000',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '36px',
          },
        },
        {
          props: { variant: 'h7' },
          style: {
            color: '#000',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '26px',
          },
        },
        {
          props: { variant: 'caption' },
          style: {
            color: '#333740',
            fontWeight: 400,
            fontSize: '16px',
          },
        },
        {
          props: { variant: 'h8' },
          style: {
            color: '#333740',
            fontWeight: 700,
            fontSize: '16px',
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          lineHeight: 1.35,
          color: '#6a6f7f',
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            fontSize: 14,
            height: 36,
            minWidth: 36,
            padding: '0 16',
            borderRadius: 8,
            color: '#FFF',
            textTransform: 'none',
            boxShadow: 'none',
            '&:hover': {
              background: 'var(--primary-700)',
            },
            '&:disabled': {
              background: '#DDE0E8',
              color: '#FFF',
            },
            '&:focus': {
              boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'warning' },
          style: {
            color: '#E4565A',
            boxShadow: '0px 0px 1px 1px #E4565A',
            background: '#FFF',
            '&:hover': {
              background: '#E4565A',
              color: '#FFF',
              border: 'none',
            },
            '&:disabled': {
              background: '#DDE0E8',
              color: '#FFF',
              border: 'none',
            },
            '&:focus': {
              boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            color: 'var(--primary-500)',
            boxShadow: '0px 0px 1px 1px var(--primary-500)',
            background: '#FFF',
            '&:hover': {
              background: 'var(--primary-500)',
              color: '#FFF',
              border: 'none',
            },
            '&:disabled': {
              background: '#DDE0E8',
              color: '#FFF',
              border: 'none',
            },
            '&:focus': {
              boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            fontSize: 14,
            height: 36,
            minWidth: 36,
            padding: '0 16',
            borderRadius: 8,
            border: 'none',
            textTransform: 'none',
            '&:hover': {
              boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
              color: '#fff',
            },
            '&:disabled': {
              border: '1px solid #DDE0E8',
              background:
                'linear-gradient(0deg, #F4F5FA, #F4F5FA), linear-gradient(0deg, #DDE0E8, #DDE0E8)',
              color: '#DDE0E8',
            },
            '&:focus': {
              boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
            },
          },
        },
        {
          props: { variant: 'text', color: 'secondary' },
          style: {
            color: 'var(--base-700)',
          },
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            color: 'var(--primary-500)',
          },
        },
        {
          props: { size: 'large' },
          style: {
            height: 48,
            minWidth: 48,
            padding: '12 24',
            fontSize: 16,
          },
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          '&:hover': {
            border: 'none',
          },
          '&:focus': {
            border: 'none',
          },
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            fontSize: 14,
            height: 36,
            minWidth: 36,
          },
        },
      ],
    },
    MuiToggleButton: {
      variants: [
        {
          props: { size: 'small' },
          style: {
            lineHeight: 0.75,
            textTransform: 'lowercase',
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.MuiAutocomplete-inputRoot.MuiOutlinedInput-root': {
            padding: '0 9px',
          },
          background: '#fff',
          borderRadius: 8,
          '&:hover': {
            border: 'none',
          },
          '&:focus': {
            border: 'none',
          },
        },
      },
      variants: [
        {
          props: { variants: 'small' },
          style: {
            fontSize: 14,
            height: 36,
            minWidth: 36,
          },
        },
        {
          props: { size: 'smaller' },
          style: {
            fontSize: 13,
            height: 26,
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          height: 48,
          border: 'none',
          borderColor: '#DDE0E8',
          '& input': {
            color: '#333740',
            padding: '12px 16px',
            borderRadius: '8px',
          },
          '& span': {
            fontSize: 12,
            color: '#6A6F7F',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D596FF !important',
          },
          '&:focus .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D596FF !important',
            borderWidth: 2,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D596FF !important',
            borderWidth: 2,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: '#DDE0E8',
          },
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            fontSize: 14,
            height: 36,
            minWidth: 36,
          },
        },
      ],
    },
    MuiSelect: {
      variants: [
        {
          props: { size: 'small' },
          style: {
            fontSize: 14,
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#888E9D',
          lineHeight: '24px',
          fontWeight: 400,
          top: '-4px',
          fontSize: '15px',
          '& [data-shrink="true"]': {
            fontSize: '12px',
          },
          '&.Mui-focused': {
            color: '#6A6F7F',
          },
          '&.Mui-disabled': {
            fontSize: '15px',
          },
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            fontSize: 14,
          },
        },
      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#333740',
          '&:hover': {
            textDecoration: 'underline',
            background: 'none',
          },
          '&.Mui-selected': {
            background: 'none',
            color: 'var(--primary-500)',
          },
          '&.Mui-selected:hover': {
            background: 'none',
          },
          '&.Mui-selected::after': {
            content: "' ✓'",
            paddingLeft: 5,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 20,
          height: 20,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#782BAC',
            background: 'none',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          border: '1px solid #DDE0E8',
          borderRadius: 8,
          padding: '12px 16px',
          marginLeft: 0,
          marginRight: 0,
          width: '100%',
          minHeight: 62,
          marginTop: 2,
          backgroundColor: '#FFF',
          paddingLeft: '5px',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '.Mui-checked': {
            '& + .MuiSwitch-track': {
              backgroundColor: '#F1DEFE !important',
              opacity: '1 !important',
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: '#9800FF',
              marginLeft: 0,
            },
          },
          '.MuiSwitch-track': {
            backgroundColor: '#EBEDF4',
            height: '20px !important',
            width: '40px !important',
            borderRadius: '100px',
            opacity: 1,
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: '#B4B8C4',
            height: '13px',
            width: '13px',
            borderRadius: '50%',
            marginTop: '6px',
            marginLeft: '7px',
            '&.Mui-active': {
              boxShadow: 'none',
            },
            '& .Mui-checked': {
              marginRight: '7px',
            },
          },
          '& .MuiSwitch-switchBase:hover, &.Mui-focusVisible': {
            boxShadow: 'none',
            background: 'none !important',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '32px 40px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF !important',
          paddingTop: '20px !important',
          paddingLeft: '40px',
          paddingRight: '40px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RouterProvider router={router} />
        </ThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
}

export default App;
