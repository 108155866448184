import { useState, useEffect } from 'react';

export const useIsPc = () => {
  const [isPc, setIsPc] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsPc(window.innerWidth > 1023);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // вызываем начальную проверку

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isPc;
};

export const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth > 679 && window.innerWidth < 1023);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // вызываем начальную проверку

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isTablet;
};

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 679);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // вызываем начальную проверку

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};
